import React from "react";

import { useDispatch } from 'react-redux';
import { physical_trade_deals_actions } from '../../../store/physical-trade-deals-slice';

import addIcon from "../../../assets/images/add.gif";
import supplier from "../../../assets/images/Supplier.gif";

//Importing Table:
import Table1 from "./Tables/Table1";
import Table2 from "./Tables/Table2";

//Import custom components:
import { toDatePickerString } from "../../../utils/DateUtils";
// import ExcelExport from "../../CustomComponents/ExcelExport";

//Import Modals:
// import FinancialTradeModal from "../Futures/NewTrade";
import NewSwapModal from "./Modals/NewSwapModal";



function Swaps() {

    const [openSwapModal, setOpenSwapModal] = React.useState(false);

    const openModal = () => {
        setOpenSwapModal(true);
    };

    const closeModal = () => {
        setOpenSwapModal(false);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 p-1">
                    <div className="card border-0">
                        <div className="card-header bg-primary text-white text-center">
                            <h4 className="card-title m-0">Swaps</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-4 p-1">
                                    {/* <Link to="/newFutureDeal"> */}
                                    <button className="btn btn-primary me-2" onClick={openModal} >
                                        <img src={addIcon} alt="Add new record" /> New</button>
                                    <button className="btn btn-primary me-2"><img src={supplier} alt="Financial Contract" /> Financial Contract</button>
                                </div>
                            </div>
                            {/* Fields and selectors */}
                            <div className="row bg-light-blue rounded mb-2 pb-1">
                                <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
                                        <label className='form-label m-0'>Period From:</label>
                                        <input
                                            defaultValue={toDatePickerString(new Date().toISOString().substr(0, 10))}
                                            name='STARTDATE'
                                            className="form-control form-control-sm"
                                            type="date"
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
                                        <label className='form-label m-0'>Period To:</label>
                                        <input
                                            defaultValue={toDatePickerString(new Date().toISOString().substr(0, 10))}
                                            name='ENDDATE'
                                            className="form-control form-control-sm"
                                            type="date"
                                        />
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 p-1 text-start">
                                        <label className='form-label m-0'>Trade Month:</label>
                                        <br />
                                        <input
                                            type="radio"
                                            defaultChecked={true}
                                        />
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 p-1 text-start">
                                        <label className='form-label m-0'>Dlv Month:</label>
                                        <br />
                                        <input
                                            type="radio"
                                        />
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 p-1 text-start">
                                        <label className='form-label m-0'>Filter:</label>
                                        <select className="form-select form-select-sm">
                                            <option defaultValue="all">All</option>
                                            <option defaultValue="open">Open</option>
                                            <option defaultValue="closed">Closed</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 p-1 text-center">
                                        <br />
                                        <button className="btn btn-sm btn-primary">MTM Positions</button>
                                    </div>
                                </div>
                                {/* End Row 1 */}
                                {/* Start Row 2 */}
                                <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
                                        <label className='form-label m-0'>Book:</label>
                                        <select className="form-select form-select-sm">
                                            <option defaultValue="bcfPool">2000 BCF Pool</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
                                        <label className='form-label m-0'>Broker:</label>
                                        <select className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
                                        <label className='form-label m-0'>Contract:</label>
                                        <select className="form-select form-select-sm">
                                            <option defaultValue="Contract1">NGAS:HH:FU</option>
                                            <option defaultValue="Contract1">NGAS:DPS:BAS</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
                                        <label className='form-label m-0'>Last MTM Date:</label>
                                        <input
                                            defaultValue={new Date().toISOString().substr(0, 10)}
                                            name='MTMDATE'
                                            className="form-control form-control-sm"
                                            type="date"
                                        />
                                    </div>

                                </div>
                            </div>
                            {/* Table section */}
                            <div className="row">
                                <div className="col-12">
                                    <Table1 />
                                    <br />
                                    <Table2 />
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {/* <FinancialTradeModal /> */}
            <NewSwapModal isOpen={openSwapModal} closeModal={closeModal} />
        </div >)
}

export default Swaps;