import NonPathNomUI from '../CustomComponents/NonPathNomUI';
import PoolNomsTableColumns from './PoolNomsTableColumns';

const options = {
	title: 'Pool Nominations',
	contractTypes: ['AA', 'AG', 'TF'],
	numberFormat: {
		shouldRound: true,
		decimalPlace: 0
	},
	buttons: [
		'AddNom'
	]
};

const PoolNominations = () => {
	return <NonPathNomUI options={options} CustomNonPathNomsTableColumns={PoolNomsTableColumns} />;
};

export default PoolNominations;