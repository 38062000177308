import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { customer_list_view_actions } from "../../../store/customer-list-view-slice";
import { toDatePickerString, toISOStringForDatabase } from "../../../utils/DateUtils";
import './DailyReadModalStyles.css';
import { displayError } from '../../../store/notification-slice';
import { useRef } from "react";
import DailyReadTable from './DailyReadTable';
import ExportExcel from "../../CustomComponents/ExportExcel";
import DailyReadTableColumns from "./DailyReadTableColumns";


// Modal styling
const customStyles = {
  overlay: {
    backgroundColor: "rgb(0,0,0,.60)",
  },
  overflow: "scroll",
};

function DailyReadModal({ componentState }) {
  // Redux
	const openDailyReadModal = useSelector(state => state.customer_list_view_slice.openDailyReadModal);
	const selectedCustomer = useSelector(state => state.customer_list_view_slice.selectedCustomer);
	const selectedSupplier = useSelector(state => state.business_selector_slice.selectedSupplier);
	const dispatch = useDispatch();

	// State
	const [dailyData, setDailyData] = useState([]);
	const [loading, setLoading] = useState(false);

	// Used to abort previous requests.
	const abortController = useRef(null);

	// API fetch for gas meters.
	useEffect(() => {
		const handleAsync = async () => {
			try {
				const { startDate, endDate } = componentState;

				console.log(selectedCustomer);

				if (!startDate || !endDate || !selectedCustomer?.SiteFacilityName) return;

				setLoading(true);

				// Abort previous request.
				if (abortController.current)
					abortController.current.abort();

				// Create signal for new request.
				abortController.current = new AbortController();
				const { signal } = abortController.current;

				// Create query parameters
				const searchParams = new URLSearchParams({
					ba_id : selectedSupplier?.BA_ID,
					site_fac_name: selectedCustomer?.SiteFacilityName,
					startDate: toISOStringForDatabase(startDate),
					endDate: toISOStringForDatabase(endDate)
				}).toString();

				// Handle fetch
				const response = await fetch(`GasMeter/GetGasMeters?${searchParams}`, { signal });

				if (!response.ok) {
					const message = await response.text();
					throw new Error(message);
				}

				// Deserialize response
				let gasMeters = await response.json();

				// Add totals row
				const totalsRow = {
					Company: 'Totals:',
					DAILY_VOL: gasMeters?.reduce((total, gasMeter) => total + gasMeter.DAILY_VOL, 0), // sum of daily vol
					ENERGY: gasMeters?.reduce((total, gasMeter) => total + gasMeter.ENERGY, 0) // sum of energy
				};

				gasMeters?.push(totalsRow);

				setDailyData(gasMeters);

				setLoading(false);
			}
			catch (error) {
				if (error.name) {
					console.log(error.message);
					return;
				}

				await dispatch(displayError(error.message));

				setLoading(false);
			}
		};
		handleAsync();
	}, [componentState, dispatch, selectedCustomer]);

	// Handlers
  const closeDailyReadModal = () => { dispatch(customer_list_view_actions.setOpenDailyReadModal(false)); };

	return (
		<Modal
			isOpen={openDailyReadModal}
			onRequestClose={closeDailyReadModal}
			className="new-daily-read-modal container-fluid bg-white text-black"
			style={customStyles}
		>
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header flex justify-content-space-between align-items-center mb-2">
						<h1 className="modal-title fs-5" id="DailyRead">
							Daily Read Measurement UI
						</h1>
						<div>
							<button
								type="button"
								className="btn-close fs-6"
								onClick={closeDailyReadModal}
							></button>
						</div>
					</div>
					<div className="modal-body">
						<div className="container-fluid mb-1 bg-light-blue rounded p-2 rounded text-start">
							<div className='row'>
								<h6>Display Filters</h6>
								<div className="col-lg-4 col-md-4 col-sm-12">
									<label className="form-label">Account #</label>
									<input
										className="form-control form-control-sm"
										aria-label="select public or private"
										defaultValue={
											selectedCustomer?.Account_PODID
										}
										disabled
									></input>
								</div>
								<div className="col-lg-2 col-md-4 col-sm-12">
									<label className="form-label">Begin Date</label>
									<input
										value={toDatePickerString(componentState?.startDate)}
										className="form-control form-control-sm"
										type="date"
										disabled
									/>
								</div>
								<div className="col-lg-2 col-md-4 col-sm-12">
									<label className="form-label">End Date</label>
									<input
										value={toDatePickerString(componentState?.endDate)}
										className="form-control form-control-sm"
										type="date"
										disabled
									/>
								</div>
								<div className='col d-flex justify-content-end'>
									<ExportExcel
										data={dailyData}
										columns={DailyReadTableColumns}
										fileName='DailyReadMeasurementData.xlsx'
										className='btn btn-primary align-self-end'
									/>
								</div>
							</div>
							<div className='row'>
								{/* Daily Pool Summary Table */}
								<DailyReadTable dailyData={dailyData} loading={loading} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default DailyReadModal;
