import { useState, useEffect, React, useMemo } from "react";
// import excel from '../../assets/images/excel.gif';
import { useSelector, useDispatch } from 'react-redux';
import { notification_actions } from '../../store/notification-slice';
import { toISOStringForDatabase } from '../../utils/DateUtils';

//Custom Base64 converter component to allow us to download the LOB data from the SQL server:
import Base64Downloader from '../CustomComponents/Base64Downloader';

//React-Table:
import DataTable from 'react-data-table-component';

// react modal
import Modal from 'react-modal';

//Excel Exporter:
import ExportExcel from "./ExportExcel";

//Styling for Table and Modal:
import CustomStyles from './Table-Styles';
import './modal-style.css';

Modal.setAppElement('#root');

function Table() {

    //Operational notice State:
    const [alertData, setAlertData] = useState([]);
    //Filter State for selector:
    const [filterType, setFilterType] = useState('P');
    // Set Modal data:
    const [modalData, setModalData] = useState([])
    // Read receipt state:
    // const [readReceipts, setReadReceipt] = useState([]);

    //Redux:
    const selectedSupplier = useSelector(state => state.business_selector_slice.selectedSupplier);
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.auth_slice.isLoggedIn);

    //React-Modal functions:
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal(row) {
        // console.log(row);
        setModalData(row)
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    // Handle Row Click Function:

    const handleRowClick = (row) => {
        openModal(row);

        row.isRead = true;
        // Send a read receipt to the backend
        // setAlertData(prevData =>
        //     prevData.map(item =>
        //         item.NOTICE_ID === row.NOTICE_ID ? { ...item, isRead: false } : item
        //     )
        // );
        // Update the isRead property in the state
        setAlertData((prevData) =>
            prevData.map((item) => {
                if (item.NOTICE_ID === row.NOTICE_ID) {
                    return { ...item, isRead: true };
                } else {
                    return item;
                }
            })
        );

        if (isLoggedIn) { createNewNoticeReadReceipt(row); }

        else {
            // Store the updated row data in sessionStorage
            const readNotices = sessionStorage.getItem("readNotices");
            const readNoticesArray = readNotices ? JSON.parse(readNotices) : [];
            if (!readNoticesArray.includes(row.NOTICE_ID)) {
                console.log(row.NOTICE_ID);
                readNoticesArray.push(row.NOTICE_ID);
            }
            sessionStorage.setItem("readNotices", JSON.stringify(readNoticesArray));
            setAlertData((prevData) =>
                prevData.map((item) => {
                    if (item.NOTICE_ID === row.NOTICE_ID) {
                        return { ...item, isRead: true };
                    } else {
                        return item;
                    }
                })
            );
        }
    };

    console.log(alertData);

    //Remove set item if you log in so that it won't show as "read":
    useEffect(() => {
        if (isLoggedIn) {
            sessionStorage.removeItem("readNotices");
        }
    }, [isLoggedIn])

    // Modal styling
    const customStyles = {
        overlay: {
            backgroundColor: 'rgb(0,0,0,.60'
        },
    };

    // Fetch Notices with Filter
    useEffect(() => {
        async function fetchData() {
            try {
                console.log('Fetching Notices...');

                let allNotices = [];

                // Fetch public notices
                const responsePublic = await fetch('Notices/GetPublicNotices');
                if (!responsePublic.ok) {
                    const message = await responsePublic.text();
                    throw new Error(message);
                }
                const noticesPublic = await responsePublic.json();
                allNotices = [...allNotices, ...noticesPublic];

                // Fetch private notices if user is authenticated
                if (isLoggedIn) {
                    const responsePrivate = await fetch(`Notices/GetPrivateNotices?baId=${selectedSupplier.BA_ID}`);
                    if (!responsePrivate.ok) {
                        const message = await responsePrivate.text();
                        throw new Error(message);
                    }
                    const noticesPrivate = await responsePrivate.json();
                    allNotices = [...allNotices, ...noticesPrivate];
                }

                // Filter notices by type (all, private, public)
                let filteredNotices = allNotices;
                if (filterType === 'private') {
                    filteredNotices = allNotices.filter(notice => notice.info_post_type === 'PR');
                } else if (filterType === 'public') {
                    filteredNotices = allNotices.filter(notice => notice.info_post_type === 'P');
                }
                // Get Read Receipts
                let newReadReceipts = [];
                if (isLoggedIn) {
                    const receiptResponse = await fetch(`Notices/GetNoticeReadReceipts`);
                    if (!receiptResponse.ok) {
                        const message = await receiptResponse.text();
                        throw new Error(message);
                    }
                    newReadReceipts = await receiptResponse.json();
                }

                // Check if notices have been read
                const readNotices = sessionStorage.getItem("readNotices");

                let readNoticesArray = [];
                if (readNotices) readNoticesArray = JSON.parse(readNotices);
                filteredNotices = filteredNotices.map((notice) => {
                    if (readNoticesArray.includes(notice.NOTICE_ID)) {
                        return { ...notice, isRead: true };
                    }
                    else if (newReadReceipts.findIndex((rr) => rr.NOTICE_ID === notice.NOTICE_ID) !== -1) {
                        return { ...notice, isRead: true };
                    }
                    else {
                        return notice;
                    }
                });


                setAlertData(filteredNotices);

                console.log('Notices fetched.');
            } catch (error) {
                dispatch(notification_actions.showModal({
                    header: 'Error',
                    message: error.message,
                }));
            }
        }
        fetchData();
    }, [selectedSupplier, dispatch, isLoggedIn, filterType]);


    //Set read state for sessionStorage:
    useEffect(() => {
        const readNotices = sessionStorage.getItem("readNotices");
        if (readNotices) {
            const readNoticesArray = JSON.parse(readNotices);
            setAlertData((prevData) =>
                prevData.map((item) => {
                    const isRead = readNoticesArray.includes(item.NOTICE_ID);
                    console.log(isRead);
                    return { ...item, isRead: isRead };
                })
            );
        }
    }, []);


    // Create a new Notice Read Receipt to send to the SQL Database:
    const createNewNoticeReadReceipt = async (row) => {
        console.log(toISOStringForDatabase(new Date()));
        const newNoticeReadReceipt = {
            NOTICE_ID: row.NOTICE_ID,
            BA_ID: selectedSupplier.BA_ID ? selectedSupplier.BA_ID : "",
            BU_ID: "00000001",
            CONTACT_NUM: row.CONTACT_NUM,
            READ_RECEIPT_DATE: toISOStringForDatabase(new Date()),
            NoticeReadreceiptKey: {
                BA_ID: selectedSupplier.BA_ID,
                BU_ID: "00000001",
                NOTICE_ID: row.NOTICE_ID,
                CONTACT_NUM: row.CONTACT_NUM
            }
        }
        const sendRequest = async () => {
            const response = await fetch('/Notices/CreateNoticeReadreceipts', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newNoticeReadReceipt)
            });

            if (!response.ok) {
                const message = await response.text();
                throw new Error(message);
            }
        }
        try { await sendRequest(); }
        catch (error) {
            dispatch(notification_actions.showModal({
                header: 'Error',
                message: error.message,
            }));
        }
    }

    // Conditional Row Styling for Critical or Non-Critical notice types:
    const conditionalRowStyles = [
        {
            when: row => row.INFO_POSTING_TYPE_TEXT === 'Critical',
            style: row => ({ color: row.isSpecial ? 'black' : 'red' })
            // style: row => ({ fontWeight: row.isRead ? 'normal' : 'bold' })
        }
    ]
    const data = filterType === 'A' ? alertData : alertData.filter((row) => row.INFO_POST_TYPE === filterType);
    const actionsMemo = useMemo(
        () => <ExportExcel data={data} />,
        [data]
    );

    //Table Columns for React-Table:
    const columns = [

        {
            name: 'Subject',
            selector: row => row.SUBJECT,
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
            isRead: false,
            cell: (row) => (
                <div style={{ fontWeight: row.isRead ? 'normal' : 'bold' }}>
                    {row.SUBJECT}
                </div>
            )
        },
        {
            name: 'Message',
            selector: row => row.MESSAGE,
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
            isRead: false,
            cell: (row) => (
                <div style={{ fontWeight: row.isRead ? 'normal' : 'bold' }}>
                    {row.MESSAGE}
                </div>
            )

        },
        {
            name: 'Category',
            selector: row => row.INFO_POSTING_TYPE_TEXT,
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
            isRead: false,
            cell: (row) => (
                <div style={{ fontWeight: row.isRead ? 'normal' : 'bold' }}>
                    {row.INFO_POSTING_TYPE_TEXT}
                </div>
            )
        },
        {
            name: 'Notice Type',
            selector: row => row.NoticeType,
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
            isRead: false,
            cell: (row) => (
                <div style={{ fontWeight: row.isRead ? 'normal' : 'bold' }}>
                    {row.NoticeType}
                </div>
            )
        },

        {
            name: 'Post Date',
            selector: row => {
                const date = new Date(row.EFFDATE);
                const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: true };
                return date.toLocaleString('en-US', options);
            },
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
            isRead: false,
            cell: (row) => (
                <div style={{ fontWeight: row.isRead ? 'normal' : 'bold' }}>
                    {row.EFFDATE}
                </div>
            )

        },
        {
            name: 'Effective Date',
            selector: row => {
                const date = new Date(row.EFFDATE);
                const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: true };
                return date.toLocaleString('en-US', options);
            },
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
            isRead: false,
            cell: (row) => (
                <div style={{ fontWeight: row.isRead ? 'normal' : 'bold' }}>
                    {row.EFFDATE}
                </div>
            )
        },
        {
            name: 'End Date',
            selector: row => {
                const date = new Date(row.ENDDATE);
                const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: true };
                return date.toLocaleString('en-US', options);
            },
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
            isRead: false,
            cell: (row) => (
                <div style={{ fontWeight: row.isRead ? 'normal' : 'bold' }}>
                    {row.ENDDATE}
                </div>
            )
        },
        {
            name: 'Doc Title',
            selector: row => row.DOC_TITLE,
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
            isRead: false,
            cell: (row) => (
                <div style={{ fontWeight: row.isRead ? 'normal' : 'bold' }}>
                    {row.DOC_TITLE}
                </div>
            )
        },
        {
            name: "Private/Public",
            selector: row => row.INFO_POST_TYPE,
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
            isRead: false,
            cell: (row) => (
                <div style={{ fontWeight: row.isRead ? "normal" : "bold" }}>
                    {row.INFO_POST_TYPE === "P" ? "Public" : row.INFO_POST_TYPE === "PR" ? "Private" : row.INFO_POST_TYPE}
                </div>
            )
        }
    ];

    return (

        <>
            <div>
                <div className='card mt-5'>
                    <div className='card-header text-center bg-primary text-white'><h4>Operational Notices and alerts</h4></div>
                    <div className='card-body'>
                        <div className='text-start mb-2 p-1 rounded'>

                            {/* if logged in return the selector below */}
                            {isLoggedIn && <>
                                <select value={filterType} onChange={(e) => setFilterType(e.target.value)} className='form-select form-select-sm border-2 border-dark' style={{ maxWidth: '150px' }}>
                                    <option value='A'>All Notices</option>
                                    <option value="P">Public Notices</option>
                                    <option value="PR">Private Notices</option>
                                </select>
                            </>}
                        </div>

                        <DataTable
                            title='Operational Notices and Alerts'
                            conditionalRowStyles={conditionalRowStyles}
                            columns={columns}
                            data={filterType === 'A' ? alertData : alertData.filter((row) => row.INFO_POST_TYPE === filterType)}
                            data-tag="allowRowEvents"
                            type='button'
                            onRowClicked={handleRowClick}
                            striped={true}
                            highlightOnHover={true}
                            customStyles={CustomStyles}
                            actions={actionsMemo}
                            dense={true}
                            className='pb-1'
                        />

                    </div>

                </div>

            </div >
            {/* This section below is for the React-Modal only */}
            <div className="container-fluid" style={{ zIndex: '1' }}>
                <Modal
                    isOpen={modalIsOpen}
                    contentLabel='change-password'
                    style={customStyles}
                    className='Modal bg-white'

                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header text-center">
                                <h4 className=" text-center" id="login-success">Notification Details</h4>
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={closeModal}
                                >
                                </button>
                            </div>
                            <div className="modal-body justify-content-center text-center">
                                <div className="card p-2 mb-2 text-start">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <p><b>Category:</b> {modalData.INFO_POSTING_TYPE_TEXT} </p>
                                            <p><b>Notice Types: </b>
                                                {modalData.NoticeType}</p>
                                            <p><b>Subject:</b> {modalData.SUBJECT}</p>
                                            <p><b>Message:</b> {modalData.MESSAGE}</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <p><b>Status:</b> {modalData.STATUS}</p>
                                            <p><b>Posting Date/Time:</b>  {new Date(modalData.EFFDATE).toLocaleDateString('en-US')} {new Date(modalData.EFFDATE).toLocaleTimeString('en-US')}</p>
                                            <p><b>Effective Date/Time:</b>  {new Date(modalData.EFFDATE).toLocaleDateString('en-US')} {new Date(modalData.EFFDATE).toLocaleTimeString('en-US')}</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <p><b>End Date/Time:</b> {new Date(modalData.ENDDATE).toLocaleDateString('en-US')} {new Date(modalData.ENDDATE).toLocaleTimeString('en-US')}</p>
                                            <p><b>Req Response:</b>  {modalData.REQURIEDRESPONSE === 'NRR' ? 'Not Required' : 'Required'}</p>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row"><p>Attached Documents</p></div>

                                <table className="bg-white table table-sm table-hover table-striped-columns table-bordered border-primary rounded">
                                    <thead>
                                        <tr>
                                            <th>Doc Title</th>
                                            <th>Download</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{modalData.DOC_TITLE}</td>
                                            <td>{modalData.DOC_TITLE ? < Base64Downloader
                                                base64Data={modalData.SCAN_DATA}
                                                fileName={modalData.DOC_TITLE}
                                                contentType="application/pdf"
                                            /> : 'No Document Attached'}</td>


                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type='button'
                                    className='btn btn-lg btn-primary'
                                    onClick={closeModal}
                                >
                                    Close
                                </button>
                            </div>

                        </div>
                    </div>

                </Modal >
            </div>
        </>
    )
}
export default Table;


