import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import Spinner from '../../../Spinner';

import customStyles from './Table-Styles';

function TableIndex1({
	selectedPriceRefIndex,
	setSelectedPriceRefIndex,
	loading
}) {

	// Redux
	const priceRefIndices = useSelector(state => state.physical_trade_deals_slice.priceRefIndices);

	const columns = [
		{
			cell: row => <input type="checkbox" checked={row.PRICE_REF_CDE === selectedPriceRefIndex.PRICE_REF_CDE} onChange={() => { handleRowClicked(row) }} />,
			allowOverflow: true,
			button: true,
		},
		{
			name: 'System ID',
			selector: row => row.PRICE_REF_CDE,
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'Price/Cost Index',
			selector: row => row.PRICE_REF_NAME,
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'Commodity',
			selector: row => row.ProductTypeCodeText,
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'Type',
			selector: row => row.IndexTypeCodeText,
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'Allow Neg #',
			selector: row => row.AllowNegText,
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'Period',
			selector: row => row.PeriodText,
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'Start Date',
			selector: row => new Date(row.EFFDATE).toLocaleDateString('en-US', {
				day: 'numeric',
				month: 'long',
				year: 'numeric'
			}),
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'End Date',
			selector: row => new Date(row.TERMDATE).toLocaleDateString('en-US', {
				day: 'numeric',
				month: 'long',
				year: 'numeric'
			}),
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'Updated By',
			selector: row => row.USERSTAMP,
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'Updated On',
			selector: row => new Date(row.DATESTAMP).toLocaleDateString('en-US', {
				day: 'numeric',
				month: 'long',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric'
			}),
			sortable: true,
			reorder: true,
			wrap: true
		},

	];

	// Handlers
	const handleRowClicked = (row) => {
		setSelectedPriceRefIndex(row);
	};

	return (
		<div>
			<DataTable
				highlightOnHover
				progressPending={loading}
				progressComponent={<Spinner status='loading' />}
				onRowClicked={handleRowClicked}
				columns={columns}
				data={priceRefIndices}
				customStyles={customStyles}
				dense={true}
				// fixedHeader={true}
				fixedHeaderScrollHeight='200px'
				className="pb-1"
			/>
		</div >
	)
}


export default TableIndex1