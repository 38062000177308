
export const columns = [
	{
		name: 'Business Unit',
		selector: row => row.CNTR_PARTY1?.BA_ID,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	},
	{
		name: 'Counter Party',
		selector: row => row.CNTR_PARTY2?.BA_ID,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	},
	{
		name: 'Contract Id',
		selector: row => row.CNTR_NUM,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true

	},
	{
		name: 'Contract Title',
		selector: row => row.CNTR_TITLE,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true

	},
	{
		name: 'Status',
		selector: row => row.CNTR_STATUS,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true

	},
	{
		name: 'Eff Date',
		selector: row => row.EFFDATE,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true

	},
	{
		name: 'Term Date',
		selector: row => row.CNTR_TERM_DATE,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true

	},
	{
		name: 'Type',
		selector: row => row.CNTR_TYPE,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true

	}
];

export default columns;