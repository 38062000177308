// hooks/useDateFilter.js

import { useState, useMemo } from 'react';

export const useDateFilter = (storageData, contracts) => {
    //Helper function to get first day of a month
    const getFirstDayOfMonth = (date) => {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        return firstDay.toISOString().substr(0, 10);
    }

    // Helper function to get the last day of a month
    const getLastDayOfMonth = (date) => {
        const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1); // First day of the next month
        nextMonth.setDate(0); // Subtract one day to get the last day of the desired month
        return nextMonth.toISOString().substr(0, 10);
    };


    // Date picker state for Period From and Period To:
    const [startDate, setStartDate] = useState(getFirstDayOfMonth(new Date()));
    const [endDate, setEndDate] = useState(getLastDayOfMonth(new Date()));


    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);

        const selectedDate = new Date(e.target.value + 'T00:00:00'); // Adjusted for potential timezone issues
        setEndDate(getLastDayOfMonth(selectedDate));
    };


    // Function to handle change of 'Period To' date:
    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    // State to keep track of the selected display option (day or month).
    const [displayBy, setDisplayBy] = useState("0");

    // Helper function to check if a date string falls within a given range
    const isDateInRange = (dateString, startDate, endDate) => {
        const date = new Date(dateString);
        const start = new Date(startDate);
        const end = new Date(endDate);
        end.setDate(end.getDate() + 1); // set end date is equal to the start date of next month

        return date >= start && date < end; // last date-of-month item should has date value < the the start date of next month
    }

    // combine point balance items and contract objects
    // retain cntr_alt_num1 value when combine
    const combinedStorageData = storageData.map(item => {
        if (item.CNTR_ALT_NUM1) {
            const cntr_alt_num1 = item.CNTR_ALT_NUM1
            return { ...item, ...contracts,  CNTR_ALT_NUM1: cntr_alt_num1}
        }
        else {
            return { ...item, ...contracts }
        }
    })

    const filteredStorageData = useMemo(() => {
        let filteredData = combinedStorageData.filter(row => isDateInRange(row.GASFLOW_DATE, startDate, endDate));
        if (displayBy === "1") { // Group by month
            let aggregatedData = {};

            // Calculate and set proper values
            filteredData.forEach((row) => {
                let month = row.GASFLOW_DATE.slice(0, 7);

                if (!aggregatedData[month]) {
                    aggregatedData[month] = { ...row };
                } else {
                    // Aggregate data here. Example: summing a 'quantity' property
                    aggregatedData[month].VOLUMEIN += row.VOLUMEIN;
                    aggregatedData[month].VOLUMEIN_VALUE += row.VOLUMEIN_VALUE;
                    aggregatedData[month].VOLUMEOUT += row.VOLUMEOUT;
                    aggregatedData[month].VOLUMEOUT_VALUE += row.VOLUMEOUT_VALUE;
                    aggregatedData[month].ADJ_QTY += row.ADJ_QTY;
                    aggregatedData[month].ADJ_VALUE += row.ADJ_VALUE;
                    aggregatedData[month].MSQ += row.MSQ;

                    // Set proper values for monthly records
                    aggregatedData[month].BEG_IMBAL = filteredData[0].BEG_IMBAL;
                    aggregatedData[month].BEG_VALUE = filteredData[0].BEG_VALUE;
                    aggregatedData[month].END_VALUE = filteredData[filteredData.length - 1].END_VALUE;
                    aggregatedData[month].END_IMBAL = filteredData[filteredData.length - 1].END_IMBAL;
                    aggregatedData[month].NET_END_IMBAL = filteredData[filteredData.length - 1].NET_END_IMBAL;
                    aggregatedData[month].NET_END_VALUE = filteredData[filteredData.length - 1].NET_END_VALUE;
                    aggregatedData[month].ADJ_VALUE = aggregatedData[month].NET_END_VALUE - aggregatedData[month].END_VALUE;
                }
            });
            
            return Object.values(aggregatedData);
        }

        return filteredData;
    }, [combinedStorageData, startDate, endDate, displayBy]);

    return {
        startDate,
        endDate,
        handleStartDateChange,
        handleEndDateChange,
        displayBy,
        setDisplayBy,
        filteredStorageData
    };
};
