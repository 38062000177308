import { useState, useEffect } from 'react';

// import whReport from '../../../assets/images/wh-report.png'
import emailRpt from '../../../assets/images/email-UI.gif'

import SupplierSelect from "../../BusinessSelector/SupplierSelect";
import { fetchWHContractStls } from '../../../store/whpurschase-thunk';
import { useSelector, useDispatch } from 'react-redux';
import { getMonthStart, getMonthEnd, toDatePickerMonthString } from '../../../utils/DateUtils';
import useBusinessSelector from '../../../hooks/useBusinessSelector';

import Spinner2 from '../../CustomComponents/Spinner2';
import DailyMeasurement from "../../DailyMeasurement";
import ExportExcel from '../../Exports/ExportExcel';

//Import table:
import WHPurchaseTable from "./Tables";


function WHPurchaseIndex() {
    // Redux
    const dispatch = useDispatch();
    
    const loading = useSelector(state => state.whPurschase.loading);
    const businessUnit = useSelector(state => state.business_selector_slice.businessUnit);
    const selectedSupplier = useSelector(state => state.business_selector_slice.selectedSupplier);
    const whContractStls = useSelector(state => state.whPurschase.whContractStls);
    const selectedWHContractStls = useSelector(state => state.whPurschase.selectedContractWHStl)

    // States
    const [filters, setFilters] = useState({
		startDate: getMonthStart(new Date()),
		endDate: getMonthEnd(new Date())
	});

	useBusinessSelector(setFilters, true);

    const [tableData, setTableData] = useState(whContractStls);
    const [priorPPAs, setPriorPPAs] = useState(false);
    const [laterPPAs, setLaterPPAs] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    
    // Handlers
    const handlePriorPPAs = () => {
        setPriorPPAs(!priorPPAs);
    };

    const handleLaterPPAs = () => {
        setLaterPPAs(!laterPPAs);
    };

    // Use Effects
    useEffect(() => {
        if (businessUnit && selectedSupplier && selectedSupplier.BA_ID)
        {
            dispatch(fetchWHContractStls({
                buId: businessUnit?.BA_ID,
                baId: selectedSupplier?.BA_ID,
                fromGasFlowDate: filters?.startDate,
                toGasFlowDate: filters?.endDate,
                withPriorPPA: priorPPAs,
                withLaterPPA: laterPPAs,
            }));
        }
    }, [dispatch, filters, priorPPAs, laterPPAs, selectedSupplier, businessUnit]);

    useEffect(() => {
        setTableData(whContractStls);
    }, [whContractStls]);

    return (
        <div className='container-fluid'>
            <div className="text-black table-responsive">
                <div className='card mt-2'>
                    <div className='card-header text-center bg-primary text-white p-0'><h4>WH Purchase</h4></div>
                    <div className='card-body mt-0'>
                        <div className="row ">
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-0">
                                <ExportExcel data={tableData} module={"WHPurchase"}  />
                                <button className='btn btn-md btn-primary me-2 mb-1'><img src={emailRpt} alt='New Trade' /> Send E-mail</button>
                                {/* <button className='btn btn-md btn-primary me-2 mb-1'><img src={newBtn} alt='Price Indices' /> Price Indices</button> */}
                            </div>
                        </div>
                        {/* FILTERS section */}
                        <div className='row bg-light-blue rounded'>
                            < div className='row p-1'>
                                <h6>Display Filters</h6>
                                {/* // Gas Flow Date */}
                                <div className="col-lg-2 col-md-2 col-sm-12 text-start">
                                    <label className='form-label pe-2'>Gasflow Date: </label>
                                    <input
                                        value={toDatePickerMonthString(filters?.startDate)}
                                        name='STARTDATE'
                                        className="form-control form-control-sm"
                                        type="month"
                                        onChange={(e) => { setFilters(prevFilters => { return { ...prevFilters, startDate: new Date(e.target.value), endDate: getMonthEnd(e.target.value) }; }); }}
                                        min="2023-11" // november 2023 devops 4666
                                    />
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 text-start">
                                    <label className='form-label pe-2'>To: </label>
                                    <input
                                        value={toDatePickerMonthString(filters?.endDate)}
                                        name='ENDDATE'
                                        className="form-control form-control-sm"
                                        type="month"
                                        onChange={(e) => { setFilters(prevFilters => { return { ...prevFilters, endDate: new Date(e.target.value) }; }); }}
                                        min="2023-11" // november 2023 devops 4666
                                    />
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 text-start">
                                        <div className='row' style={{height: '24pt'}}>PPAs:</div>
                                        <div className='row'>
                                            <div className='col-lg-1 col-md-1 col-sm-1'>
                                                <input
                                                    type="checkbox"
                                                    checked={priorPPAs}
                                                    onChange={handlePriorPPAs}
                                                />
                                            </div>
                                            <div className='col-lg-2 col-md-2 col-sm-8'>
                                                <label className='form-label pe-2'>Prior</label>
                                            </div>

                                            <div className='col-lg-1 col-md-1 col-sm-1'>
                                                <input
                                                    type="checkbox"
                                                    checked={laterPPAs}
                                                    onChange={handleLaterPPAs}
                                                />
                                            </div>
                                            <div className='col-lg-2 col-md-2 col-sm-8'>
                                                <label className='form-label pe-2'>Later</label>
                                            </div>
                                        </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 text-start">
                                    <label className='form-label pe-2'>Represented BA Selector: </label>
                                    <SupplierSelect />
                                </div>
                            </div>

                        </div>
                        {/* Table Section */}
                        <div className="row">
                            <h6><span>WH Purchase</span></h6>
                            <div className="col-sm-12 col-md-12 col-lg-12 p-2" id='report'>
                                {loading === true ? <Spinner2 /> : <WHPurchaseTable data={tableData} setOpenModal={setOpenModal}/>}
                            </div>
                            <DailyMeasurement 
                                openModal={openModal} 
                                setOpenModal={setOpenModal}
                                passingParams={{
                                    fromGasFlowDate: filters?.startDate,
                                    businessUnit: businessUnit,
                                    selectedSupplier: selectedSupplier,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default WHPurchaseIndex;