import { useEffect, useState } from 'react';
import { notification_actions } from '../../../store/notification-slice';

//Image imports
import Clear from '../../../assets/images/clear.png';
import Cancel from '../../../assets/images/cancel.png';

//Import Modules:
import Table from './Table';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { physical_trade_deals_actions } from '../../../store/physical-trade-deals-slice';

import './SelectTransactionModalStyling.css';
import SearchBar from '../../CustomComponents/SearchBar';

// Modal styling
const customStyles = {
	overlay: {
		backgroundColor: 'rgb(0,0,0,.60'
	},
};

function DealModal() {

	// Redux:
	const openTransactionModal = useSelector(state => state.physical_trade_deals_slice.openTransactionModal);
	const dispatch = useDispatch();

	// State
	const [transTypes, setTransTypes] = useState();
	const [loading, setLoading] = useState();
	const [filteredData, setFilteredData] = useState();

	// Fetch Transaction Types.
	useEffect(() => {
		const handleAsync = async () => {
			const requestData = async () => {
				const response = await fetch('PhysicalTradeDeals/GetTransTypes');
				if (!response.ok) {
					const message = await response.text();
					throw new Error(message);
				}
				return await response.json();
			};
			try {
				setLoading(true);
				const data = await requestData();
				setTransTypes(data);
			}
			catch (error) {
				dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
			}
			finally {
				setLoading(false);
			}
		};
		handleAsync();
	}, [dispatch]);

	// Modal State:
	const closeTransactionModal = () => {
		dispatch(physical_trade_deals_actions.setOpenTransactionModal(false));
	};

	return (
		<>
			<Modal
				isOpen={openTransactionModal}
				onRequestClose={closeTransactionModal}
				className="select-transaction-modal container-fluid bg-white text-black"
				style={customStyles}
			>
				<div id="SelectTransactionModal" tabIndex='-1'>
					<div className='modal-dialog '>
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title mb-2">Select Trans Type</h4>
								<button
									type="button"
									className="btn-close fs-6"
									onClick={closeTransactionModal}
								>
								</button>
							</div>
							<div className="modal-body">

								{/* // BUTTONS */}
								<div className="row text-start bg-light-blue rounded-top m-0 p-2">
									<div className="col-lg-3 col-md-12 col-md-12 col-sm-12">
										<button
											type="button"
											className="btn btn-primary m-1"
											title="New Deal"
											onClick={closeTransactionModal}
										>
											<img src={Clear} alt="Clear" /> Clear
										</button>
										<button
											type="button"
											className="btn btn-primary m-1"
											title="Cancel"
											onClick={closeTransactionModal}
										>
											<img src={Cancel} alt="AddImage" /> Cancel
										</button>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-12">
										{/* Search Bar */}
										<SearchBar
											data={transTypes}
											setFilteredData={setFilteredData}
										/>
									</div>
								</div>
								{/* SELECT A DEAL TABLE */}
								<div className='row text-start'>
									<Table
										transTypes={filteredData}
										loading={loading}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default DealModal