import ReactHookFormLookupModal from './ReactHookFormLookupModal';
import PointLookupColumns from './Columns/PointLookupColumns';
import { Fragment, useState } from 'react';

const PointLookupModal = ({ form, propertyName, url }) => {
	const [isOpen, setIsOpen] = useState(false);
	const openModal = () => { setIsOpen(true); };
	return (
		<Fragment>
			<button
				type='button'
				className='btn btn-ss btn-primary margin-flat'
				onClick={openModal}
			>
				Select Point
			</button>
			<ReactHookFormLookupModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				url={url}
				columns={PointLookupColumns}
				modalTitle='Point Lookup'
				form={form}
				propertyName={propertyName}
				propertyIdName='PT_NUM'
				usePagination={true}
			/>
		</Fragment>
	);
};

export default PointLookupModal;