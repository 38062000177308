import { useEffect, useState } from 'react';
import { fetchPortfolios} from '../../../store/physical-trade-deals-thunks'

//Image imports
import Clear from '../../../assets/images/clear.png';
import Cancel from '../../../assets/images/cancel.png';


//Import Modules:
import Table from './Table';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { physical_trade_deals_actions } from '../../../store/physical-trade-deals-slice';

import './SelectPortfolioModalStyling.css';
import SearchBar from '../../CustomComponents/SearchBar';

// Modal styling
const customStyles = {
	overlay: {
		backgroundColor: 'rgb(0,0,0,.60'
	},
};

function SelectPortfolio() {

	// Redux:
	const openPortfolioModal = useSelector(state => state.physical_trade_deals_slice.openPortfolioModal);
	const portfolios = useSelector(state => state.physical_trade_deals_slice.portfolios);
	const dispatch = useDispatch();

	// State
	const [loading, setLoading] = useState();
	const [filteredData, setFilteredData] = useState();

	useEffect(() => {
		const handleAsync = async () => {
			setLoading(true);
			await dispatch(fetchPortfolios());
			setLoading(false);
		};
		handleAsync();
	}, [dispatch]);

	// Modal State:
	const closePortfolioModal = () => {
		dispatch(physical_trade_deals_actions.setOpenPortfolioModal(false));
	};

	return (
		<>
			<Modal
				isOpen={openPortfolioModal}
				onRequestClose={closePortfolioModal}
				className="select-portfolio-modal container-fluid bg-white text-black"
				style={customStyles}
			>
				<div id="SelectPortfolioModal" tabIndex='-1'>
					<div className='modal-dialog '>
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title mb-2">Select Portfolio</h4>
								<button
									type="button"
									className="btn-close fs-6"
									onClick={closePortfolioModal}
								>
								</button>
							</div>
							<div className="modal-body">

								{/* // BUTTONS */}
								<div className="row text-start bg-light-blue rounded-top m-0 p-2">
									<div className="col-lg-3 col-md-12 col-md-12 col-sm-12">
										<button
											type="button"
											className="btn btn-primary m-1"
											title="New Portfolio"
											onClick={closePortfolioModal}
										>
											<img src={Clear} alt="Clear" /> Clear
										</button>
										<button
											type="button"
											className="btn btn-primary m-1"
											title="Cancel"
											onClick={closePortfolioModal}
										>
											<img src={Cancel} alt="AddImage" /> Cancel
										</button>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-12">
										{/* Search Bar */}
										<SearchBar
											data={portfolios}
											setFilteredData={setFilteredData}
										/>
									</div>
								</div>
								{/* SELECT A PORTFOLIO TABLE */}
								<div className='row text-start'>
									<Table
										portfolios={filteredData}
										loading={loading}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default SelectPortfolio