// redux
import { useSelector, useDispatch } from 'react-redux';
import { notification_actions } from '../../store/notification-slice';
import { Fragment, useMemo, useRef, useEffect } from 'react';

// images
import logo1 from '../../assets/images/gastar-logo.png'

//Import Modal styling CSS:
import './modal-style.css'

const NotificationModal = () => {

	// Redux
	const isOpen = useSelector(state => state.notification_slice.isOpen);
	const message = useSelector(state => state.notification_slice.message);
	const header = useSelector(state => state.notification_slice.header);
	const dispatch = useDispatch();

	// element refs
	const modalBodyRef = useRef();

	// set scroll to top on open
	useEffect(() => {
		const currentRef = modalBodyRef?.current
		if (isOpen && currentRef) {
			currentRef.scrollTop = 0;
		}
	}, [isOpen]);

	// format the message
	const formattedMessage = useMemo(() => {
		return message?.split('\n').map(
			(line, index) => {
				return (
					<Fragment key={index}>
						<span>
							{line}
						</span>
						<br />
					</Fragment>
				);
			}
		);
	}, [message]);

	// Handlers
	const closeModal = () => {
		dispatch(notification_actions.closeModal());
	};

	const contentClick = (e) => {
		e.stopPropagation(); // prevents the modal from closing when clicking inside to modal's content
	};

	// if message or header is not defined, do not return JSX
	if (!message || !header)
		return;

	// else return JSX
	return (
		<>
			<div
				className={`modal-backdrop ${isOpen ? 'show' : ''}`}
				style={{
					display: `${isOpen ? 'block' : 'none'}`,
					zIndex: 9999,
				}}
			>
			</div>
			<div
				className={`modal ${isOpen ? 'show' : ''}`}
				style={{
					display: `${isOpen ? 'block' : 'none'}`,
					zIndex: 9999
				}}
				id="EditContact"
				aria-hidden="true"
				aria-labelledby="AddCustomer Report"
				data-backdrop='true'
				tabIndex="-1"
				onClick={closeModal}
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content"
						onClick={contentClick}
					>
						<div className="modal-header text-center bg-primary">
							<h1 className="fs-5 text-center text-white" id="login-success">{header}</h1>
							<button
								type="button"
								className="btn-close"
								aria-label="Close"
								onClick={closeModal}
							>
							</button>
						</div>
						<form>
							<div
								className="modal-body justify-content-center text-center fs-5"
								style={{
									wordWrap: 'break-word',
									maxHeight: '600px',
									overflowY: 'auto'
								}}
								ref={modalBodyRef}
							>
								<img src={logo1} alt='Gastar Logo' className='img-fluid mb-4' style={{ maxWidth: '200px' }} />
								<br />
								<h2 className='fs-3'>{formattedMessage}</h2>
							</div>
							<div className="modal-footer">
								<button
									type='button'
									className='btn btn-lg btn-primary'
									onClick={closeModal}
								>
									Close
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);

};

export default NotificationModal;
