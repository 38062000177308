import DataTable from 'react-data-table-component'
import { formatDate } from '../../../../../utils/DateUtils';
import Spinner from '../../../../Spinner';
import customStyles from './Table-Styles';
import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { calcDlvAndFuel, calcRcvAndFuel, getFuelPercent } from '../../../../../utils/GeneralNomUtils';


function NomDetailsTable({ loading, tempPkgPaths, setTempPkgPaths }) {

	//Edit table functionality:
	const [editingId, setEditingId] = useState(null);
	const [editValue, setEditValue] = useState(0);
	const { viewDetailsModalState } = useSelector(state => state.non_path_noms_slice.modalStates);
	const { selectedDirection, canEditTable, selectedPkgPath } = viewDetailsModalState;
	const { selectedContract } = useSelector(state => state.non_path_noms_slice.NonPathNomsFilters);
	const { numberFormat } = useSelector(state => state.non_path_noms_slice.options);

	const volDtls = useMemo(() => {
		return tempPkgPaths?.find(pkgPath => pkgPath?.PATH_NUM === selectedPkgPath?.PATH_NUM)?.ENT_PKG_VOL_DTL || [];
	}, [selectedPkgPath, tempPkgPaths]);

	const handleRowClick = (row) => {
		setEditingId(row.GASFLOW_DATE); // Use date as editing id
		setEditValue(selectedDirection === "R" ? row.NOM_RCV_VOL : selectedDirection === "D" ? row.NOM_DLV_VOL : 0);
	}

	const handleBlur = () => {
		setEditingId(null);
	};

	const handleEditValueChange = (e) => {
		const newValue = e.target.value;
		setEditValue(newValue);

		//if (!tempVolDtls)
		//	return;

		const newPkgPaths = tempPkgPaths?.map(prevPkgPath => {
			const newPkgVolDtls = prevPkgPath.ENT_PKG_VOL_DTL.map(
				prevPkgVolDtl => {
					if (prevPkgVolDtl.GASFLOW_DATE === editingId) {
						// get fuel percent
						const fuelPercent = getFuelPercent(selectedContract);
						let newVolInfo;

						if (selectedDirection === "R") {
							newVolInfo = calcDlvAndFuel(parseFloat(newValue), fuelPercent, numberFormat);
						}
						else if (selectedDirection === "D") {
							newVolInfo = calcRcvAndFuel(parseFloat(newValue), fuelPercent, numberFormat)
						}
						if (newVolInfo) {
							const { fuel, rcvVol, dlvVol } = newVolInfo;

							return {
								...prevPkgVolDtl,
								NOM_RCV_VOL: rcvVol,
								NOM_DLV_VOL: dlvVol,
								ACT_RCV_VOL: rcvVol,
								ACT_DLV_VOL: dlvVol,
								FUEL: fuel,
							};
						}
					}
					return prevPkgVolDtl;
				}
			);

			return {
				...prevPkgPath,
				ENT_PKG_VOL_DTL: newPkgVolDtls
			}
		});

		setTempPkgPaths(newPkgPaths);

	}

	const columns = [
		{
			name: "Date",
			selector: row => {
				const date = new Date(row.GASFLOW_DATE);
				return date.toLocaleDateString();
			},
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},
		{
			name: "Day",
			selector: row => {
				const date = new Date(row.GASFLOW_DATE);
				return date.toLocaleDateString('en-US', { weekday: 'long' });
			},
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},
		{
			name: "Nom Rec Qty", // treat like regular input maybe don't need edit id, if throw columns into component then can probably handle state easy
			//selector: row => row?.NOM_RCV_VOL || 0,
			selector: row => (editingId === row.GASFLOW_DATE) && selectedDirection === "R" && canEditTable ? (
				<input
					className='form-control form-control-sm'
					type="text"
					value={editValue}
					onChange={handleEditValueChange}
					onBlur={handleBlur}
				/>
			) : (
				<div
					onClick={() => { handleRowClick(row) }}
				>
					{row.NOM_RCV_VOL || 0}
				</div>
			),
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},

		{
			name: "Nom Del Qty",
			//selector: row => row?.NOM_DLV_VOL || 0,
			selector: row => (editingId === row.GASFLOW_DATE) && selectedDirection === "D" && canEditTable ? (
				<input
					className='form-control form-control-sm'
					type="text"
					value={editValue}
					onChange={handleEditValueChange}
				//onBlur={handleBlur}
				/>
			) : (
				<div
					onClick={() => { handleRowClick(row) }}
				>
					{row.NOM_DLV_VOL || 0}
				</div>
			),
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},
		{
			name: "Act Rec Qty",
			selector: row => row?.ACT_RCV_VOL || 0,
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},
		{
			name: "Act Del Qty",
			selector: row => row?.ACT_DLV_VOL || 0,
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},
		{
			name: "Cycle #",
			selector: row => row?.CYCLE || 0,
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},
		/* DevOps 3963 hide columns
		{
			name: "Rank",
			selector: row => row?.RANK || "",
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},
		*/
		/* Not sure if we need these columns yet.
		{
			name: "Ovr Rec Qty",
			selector: row => row?.SCH_RCV_VOL || 0,
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},
		{
			name: "Ovr Del Qty",
			selector: row => row?.OVR_DLV_QTY || 0,
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},
		*/
		/* DevOps 3963 hide columns
		{
			name: "Fuel %",
			selector: row => {
				const fuelPercent = row?.FUEL_PERCENT_DB;
				return fuelPercent != null ? fuelPercent * 100 : null;
			},
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},
		{
			name: "Beg.Value",
			selector: row => row?.NOM_PRICE || 0,
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},
		{
			name: "Fee",
			selector: row => row?.FEE || 0,
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},
		{
			name: "Ovr Fee",
			selector: row => row?.OVR_FEE || 0,
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},
		{
			name: "Act Rcv Cumulative",
			selector: row => row?.NomRcvCumulative || 0,
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},
		{
			name: "Path Cost $",
			selector: row => row?.PATH_COST || 0,
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},
		{
			name: "Dlv Cost $",
			selector: row => row?.DeliveryCost || 0.0000,
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},
		*/
		{
			name: "Up Contract #",
			selector: row => row?.UP_CNTR || "N/A",
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},
		{
			name: "Updated By",
			selector: row => row?.USERSTAMP || null,
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},
		{
			name: "Updated On",
			selector: row => formatDate(row.DATESTAMP),
			sortable: true,
			compact: true,
			wrap: false,
			reorder: true
		},

	];

	return (
		<DataTable
			progressPending={loading}
			progressComponent={<Spinner status='loading' />}
			customStyles={customStyles}
			columns={columns}
			data={volDtls}
			noHeader={true}
			fixedHeader={true}
			fixedHeaderScrollHeight="500px"
			dense={true}
			highlightOnHover={true}
			pointerOnHover={true}
			striped={true}
			onRowClicked={handleRowClick}
		/>
	);
};

export default NomDetailsTable;
