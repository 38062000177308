import React, { useEffect, useState } from 'react';

import { fetchMeasurementContracts, fetchMatchingGasAllocation } from '../../../store/measurement-slice';
import { getMatchingContract } from '../../../store/general-data-slice';
import { getPoolBalance } from '../../../store/pool-balance-thunks';
import { useSelector, useDispatch } from 'react-redux';
import useBusinessSelector from '../../../hooks/useBusinessSelector';

import { getMonthStart, getMonthEnd, toDatePickerMonthString } from '../../../utils/DateUtils';
import Spinner2 from '../../CustomComponents/Spinner2';

//Import table:
import MeasurementTable from "./Tables";
import SupplierSelect from '../../BusinessSelector/SupplierSelect';
import ExportExcel from "./Reports/ExportExcel";
import ExportPDF from "./Reports/ExportPDF";
import DailyMeasurementModal from './DailyMeasurementModal/index';
import HourlyMeasurementModal from './HourlyMeasurementModal/index';

const getNextDay = (date) => {
	var originalDate = new Date(date);
	var nextDayDate = new Date(originalDate);
	nextDayDate.setDate(date.getDate() + 1);
	return nextDayDate;
}

function MeasurementIndex() {
	// Redux
	const dispatch = useDispatch();
	const matchingContractsData = useSelector(state => state.measurement.matchingContractsData);
	const status = useSelector(state => state.measurement.status);

	const ba = useSelector(state => state.business_selector_slice.selectedSupplier);
	const bu = useSelector(state => state.business_selector_slice.businessUnit);
	const contracts = useSelector(store => store.general_data_slice.filteredContracts);
	const gasAllocationData = useSelector(store => store.measurement.matchingGasAllocationData);
	const poolBalanceData = useSelector(store => store.poolBalance_slice.poolBalanceData);
	const userContact = useSelector(state => state.auth_slice.userContact);

	// States
	const [filters, setFilters] = useState({
		startDate: getMonthStart(new Date()),
		endDate: getMonthEnd(new Date()),
		withPriorPPA: false
	});

	useBusinessSelector(setFilters, true);

	const [measurementContracts, setMeasurementContracts] = useState(matchingContractsData);

	// Use Effect
	useEffect(() => {
		if (filters?.startDate && filters?.endDate) {
			if (bu && bu.BA_ID && ba && ba.BA_ID) {
				dispatch(fetchMeasurementContracts({
					buId: bu.BA_ID,
					baId: ba.BA_ID,
					withPriorPPA: filters?.withPriorPPA,
					fromGasFlowDate: filters?.startDate,
					toGasFlowDate: filters?.endDate,
				}));
			}

			if (ba && ba.BA_ID) 
			{
				dispatch(getMatchingContract({
					ba_id: ba.BA_ID,
					cntr_type: 'GA'
				}));
				dispatch(getPoolBalance({
					ba_id: ba.BA_ID,
					month: filters?.startDate,
				}));
			}
		}
	}, [filters, dispatch, bu, ba]);

	useEffect(() => {
		const cntr_nums = contracts.map(c => c.CNTR_NUM);

		if (cntr_nums.length > 0) {
			dispatch(fetchMatchingGasAllocation({
				ba_id: ba.BA_ID,
				month: filters?.startDate,
				cntr_nums: cntr_nums
			}));
		}
	}, [filters, dispatch, ba, contracts]);

	useEffect(() => {
		setMeasurementContracts(matchingContractsData);
	}, [matchingContractsData]);

	return (
		<div className='container-fluid'>

			<div className="text-black table-responsive">
				<div className='card mt-2'>
					<div className='card-header text-center bg-primary text-white p-0'><h4>Measurement</h4></div>
					<div className='card-body mt-0'>
						<div className="row ">
							<div className="col-sm-12 col-md-12 col-lg-12 mt-0">
								{/* <button className='btn btn-md btn-primary me-2 mb-1'><img src={whReport} alt='New Trade' />Production Report</button>
                                <button className='btn btn-md btn-primary me-2 mb-1'><img src={emailRpt} alt='New Trade' /> Send Email</button> */}

								<ExportExcel data={matchingContractsData} />
								<ExportPDF
									month={getNextDay(filters?.startDate)} // add 1 day because for some reason the backend sometimes returns the end of the previous month if we send the first of the month as start date
									contracts={contracts}
									ba={ba}
									bu={bu}
									gasAllocationData={gasAllocationData}
									poolBalanceData={poolBalanceData}
									user={userContact}
								/>
							</div>
						</div>
						{/* FILTERS section */}
						<div className='row bg-light-blue rounded'>

							<h6>Display Filters</h6>
							<div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
								<label className='form-label m-0'>Period From:</label>
								<input
									value={toDatePickerMonthString(filters?.startDate)}
									name='STARTDATE'
									className="form-control form-control-sm"
									type="month"
									onChange={(e) => { setFilters(prevFilters => { return { ...prevFilters, startDate: getMonthStart(e.target.value), endDate: getMonthEnd(e.target.value) }; }); }}
									min="2023-11" // november 2023 devops 4666
								/>
							</div>
							<div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
								<label className='form-label m-0'>Period To:</label>
								<input
									value={toDatePickerMonthString(filters?.endDate)}
									name='ENDDATE'
									className="form-control form-control-sm"
									type="month"
									onChange={(e) => { setFilters(prevFilters => { return { ...prevFilters, endDate: getMonthEnd(e.target.value) }; }); }}
									min="2023-11" // november 2023 devops 4666
								/>
							</div>
							<div className="col-lg-2 col-md-2 col-sm-12 p-1 text-start">
								<label className='form-label m-0'>Agent:</label>
								<SupplierSelect />
							</div>
							<div className="col-lg-2 col-md-3 col-sm-12 align-self-end">
								<div className='form-check'>
									<input
										className='form-check-input'
										type='checkbox'
										checked={filters?.withPriorPPA}
										onChange={(e) => { setFilters(prevFilters => { return { ...prevFilters, withPriorPPA: e.target.checked } }); }}
									/>
									<label className='form-check-label'>Include Prior PPAs</label>
								</div>
							</div>

						</div>
						{/* Table Section */}
						<div className="row">
							<h6 className='p-0'><span>Agent Marketer Details</span></h6>
							<div className="col-sm-12 col-md-12 col-lg-12 p-1">
								{status === 'loading' ? <Spinner2 /> : <MeasurementTable data={measurementContracts} />}
							</div>
							<DailyMeasurementModal
								passingParams={{
									fromGasFlowDate: filters?.startDate,
									toGasFlowDate: filters?.endDate,
									businessUnit: bu,
									selectedSupplier: ba,
								}}
							/>
							<HourlyMeasurementModal
								passingParams={{
									fromGasFlowDate: filters?.startDate,
									toGasFlowDate: filters?.endDate,
									businessUnit: bu,
									selectedSupplier: ba,
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MeasurementIndex;