import DataTable from 'react-data-table-component';

import CustomStyles from './Table-Styles';

const columns = [

    {
        name: 'Contract',
        selector: row => row.contract,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },
    {
        name: 'Trade ID',
        selector: row => row.tradeId,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },

    {
        name: 'Trade Date',
        selector: row => row.tradeDate,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },
    {
        name: 'P/C',
        selector: row => row.pc,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true

    },
    {
        name: 'Dir',
        selector: row => row.dir,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true

    },

    {
        name: '# Contracts',
        selector: row => row.contracts,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },
    {
        name: 'Quantity',
        selector: row => row.quantity,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },
    {
        name: 'Strike Price',
        selector: row => row.strikePrice,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },
    {
        name: 'Premium',
        selector: row => row.premium,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },

    {
        name: 'Settle Date',
        selector: row => row.settleDate,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },
    {
        name: 'Prem Settle Date',
        selector: row => row.prem_settleDate,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },
    {
        name: 'Book/Portfolio',
        selector: row => row.book,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },

];

const data = [
    {
        contract: 'NGAS:HH:OPTA',
        tradeId: 'OPTA-123',
        tradeDate: '6/15/2023',
        pc: 'Put/Cal',
        dir: 'Long/Short',
        contracts: '1',
        quantity: '10,000',
        strikePrice: '3.00',
        premium: '.25',
        settleDate: 'Oct-23',
        prem_settleDate: 'Jun-23',
        book: 'From Setup'

    }
]



function OptionsTable() {
    return (
        <DataTable
            // conditionalRowStyles={conditionalRowStyles}
            // className='tableFixHead'
            // fixedHeader={true}
            // fixedHeaderScrollHeight="700px"
            columns={columns}
            data={data}
            data-tag="allowRowEvents"
            type='button'
            // onRowClicked={handleRowClick}
            striped={true}
            highlightOnHover={true}
            customStyles={CustomStyles}
            dense={true}
            className="pb-1"
        />
    );
};

export default OptionsTable;