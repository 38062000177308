import React, { useEffect, useMemo } from 'react'

import DataTable from 'react-data-table-component'
import { useSelector } from 'react-redux';
import { formatDate } from '../../../../../utils/DateUtils';

// import Spinner from "../../../../Spinner";
// import { useDispatch } from "react-redux";

// import CustomStyles from './Table-Styles';



const columns = [
	{
		name: "Date",
		selector: row => {
			const date = new Date(row.GASFLOW_DATE);
			return date.toLocaleDateString();
		},
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	{
		name: "Day",
		selector: row => {
			const date = new Date(row.GASFLOW_DATE);
			return date.toLocaleDateString('en-US', { weekday: 'long' });
		},
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	{
		name: "Nom Rec Qty",
		selector: row => row?.NOM_RCV_VOL || 0,
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	{
		name: "Nom Del Qty",
		selector: row => row?.NOM_DLV_VOL || 0,
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	{
		name: "Act Rec Qty",
		selector: row => row?.ACT_RCV_VOL || 0,
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	{
		name: "Act Del Qty",
		selector: row => row?.ACT_DLV_VOL || 0,
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	{
		name: "Cycle #",
		selector: row => row?.CYCLE || 0,
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	{
		name: "Rank",
		selector: row => row?.RANK || "",
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	/* Not sure if we need these columns yet.
	{
		name: "Ovr Rec Qty",
		selector: row => row?.SCH_RCV_VOL || 0,
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	{
		name: "Ovr Del Qty",
		selector: row => row?.OVR_DLV_QTY || 0,
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	*/
	{
		name: "Fuel %",
		selector: row => {
			const fuelPercent = row?.FUEL_PERCENT_DB;
			return fuelPercent != null ? fuelPercent * 100 : null;
		},
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	{
		name: "Beg.Value",
		selector: row => row?.NOM_PRICE || 0,
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	{
		name: "Fee",
		selector: row => row?.FEE || 0,
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	{
		name: "Ovr Fee",
		selector: row => row?.OVR_FEE || 0,
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	{
		name: "Act Rcv Cumulative",
		selector: row => row?.NomRcvCumulative || 0,
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	{
		name: "Path Cost $",
		selector: row => row?.PATH_COST || 0,
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	{
		name: "Dlv Cost $",
		selector: row => row?.DeliveryCost || 0.0000,
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	{
		name: "Up Contract #",
		selector: row => row?.UP_CNTR || "N/A",
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	{
		name: "Updated By",
		selector: row => row?.USERSTAMP || null,
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},
	{
		name: "Updated On",
		selector: row => formatDate(row.DATESTAMP),
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	},

]


function NomDetailTable({ pkgVolDtls }) {

	return (
		<DataTable
			columns={columns}
			data={pkgVolDtls}
			// customStyles={customStyles}
			noHeader={true}
			fixedHeader={true}
			fixedHeaderScrollHeight="500px"
			dense={true}
			// progressPending={loading}
			// progressComponent={<Spinner status='loading' />}
			highlightOnHover={true}
			pointerOnHover={true}
			striped={true}
		/>
	);
};

export default React.memo(NomDetailTable);
