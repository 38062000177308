import { useSelector, useDispatch } from 'react-redux';
import { non_path_noms_actions } from '../../../../../store/non-path-noms-slice';
import DataTable from 'react-data-table-component';
import CustomStyles from './Table-Styles';
import { formatDateTime } from '../../../../../utils/DateUtils';
import { useWatch } from 'react-hook-form';
import Spinner from '../../../../Spinner';
import { useMemo } from 'react';

const LocationQuantitiesTable = ({ form, flowDirection, currentModalStateName }) => {

	// Redux
	const modalStates = useSelector(state => state.non_path_noms_slice.modalStates);
	const { specifyLocationQuantityModalState, viewDetailsModalState } = modalStates;
	const currentModalState = modalStates[currentModalStateName];
	const { loading } = currentModalState;
	const dispatch = useDispatch();
	const selectedPackage = useSelector(state => state.non_path_noms_slice.selectedPackage);
	const canEditPackage = useSelector(state => state.non_path_noms_slice.canEditPackage);
	const pkgPathsFetchLoading = useSelector(state => state.non_path_noms_slice.pkgPathsFetchLoading);

	// React Hook Form
	const pkgPathInfoObjs = useWatch({ control: form.control, name: 'pkgPathInfoObjs' });
	const filteredPkgPathInfoObjs = pkgPathInfoObjs?.filter(pkgPathInfoObj => pkgPathInfoObj.PT_DIR_FLOW === flowDirection);

	const canEditTable = useMemo(() => {
		if (pkgPathInfoObjs?.length === 2 && canEditPackage) {
			return true;
		}
		return false;
	}, [pkgPathInfoObjs]);

	const closeCurrentModal = async () => {
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: currentModalStateName,
			newModalState: { ...currentModalState, open: false }
		}));
	};

	const editClick = async (row) => {
		// Set selectedPkgPathInfoObj
		await dispatch(non_path_noms_actions.setSelectedPkgPathInfoObj(row));

		await closeCurrentModal();

		// Open specify location quantity modal and set selected package path info object,
		// and previous modal information in specify location quantity modal state.
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'specifyLocationQuantityModalState',
			newModalState: {
				...specifyLocationQuantityModalState,
				open: true,
				previousModalStateName: currentModalStateName,
				previousModalState: currentModalState
			}
		}));
	};

	const viewDetailsClick = async (row) => {
		// Get selected path.
		const selectedPkgPath = selectedPackage?.ENT_PKG_PATH?.find(pkgPath => pkgPath.PATH_NUM === row.PATH_NUM);

		await closeCurrentModal();

		// Set loading to false, set all state.
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'viewDetailsModalState',
			newModalState: {
				...viewDetailsModalState,
				open: true,
				selectedPkgPath: selectedPkgPath,
				previousModalStateName: currentModalStateName,
				previousModalState: currentModalState,
				selectedDirection: flowDirection,
				loading: false,
				canEditTable,
			}
		}));
	};

	const columns = [
		{
			cell: row => {
				return (
					<div className='btn-group'>
						<button
							style={{ whiteSpace: 'nowrap' }}
							className='btn btn-sm btn-primary'
							type='button'
							onClick={() => { editClick(row) }}
							disabled={!canEditPackage}
						>
							Edit
						</button>
						<button
							style={{ whiteSpace: 'nowrap' }}
							className='btn btn-sm btn-primary'
							type='button'
							onClick={() => { viewDetailsClick(row) }}
							disabled={!row.PATH_NUM}
						>
							View Details
						</button>
					</div>
				);
			},
			width: 'auto',
			center: true,
		},
		{
			name: 'Path #',
			selector: row => row.PATH_NUM,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Location',
			selector: row => row.PT_NUM_OBJ?.POINT_ID,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Dn K',
			cell: row => {
				const index = pkgPathInfoObjs?.findIndex(pkgPathInfoObj => pkgPathInfoObj.PT_NUM_OBJ?.POINT_ID === row.PT_NUM_OBJ?.POINT_ID);
				return (
					<input
						disabled
						type='text'
						className='text-start w-100 h-100 d-flex align-items-center bg-transparent form-control form-control-sm'
						{...form.register(`pkgPathInfoObjs.${index}.UP_CNTR`, { required: row.PT_DIR_FLOW === 'R' })}
					/>
				);
			}
		},
		{
			name: 'Nom Qty',
			selector: row => row.PT_DIR_FLOW === 'R' ? row.NOM_RCV_VOL : row.NOM_DLV_VOL,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Updated By',
			selector: row => row.USERSTAMP,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Updated On',
			selector: row => formatDateTime(row.DATESTAMP),
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		}
	];

	return (
		<DataTable
			progressPending={loading || pkgPathsFetchLoading}
			progressComponent={<Spinner status='loading' />}
			fixedHeader={true}
			fixedHeaderScrollHeight="700px"
			customStyles={CustomStyles}
			columns={columns}
			data={filteredPkgPathInfoObjs}
			striped={true}
			highlightOnHover={true}
			responsive={true}
			dense={true}
		/>
	);
};

export default LocationQuantitiesTable;