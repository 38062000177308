
const DailyReadTableStyles = {
  rows: {
    stripedStyle: {
      backgroundColor: '#e6e6e6'
    }
	},
  cells: {
    style: {
      border: '1px solid gray'
    },
  },
};

export default DailyReadTableStyles;