import { formatDate } from "../../../utils/DateUtils";

const DailyReadTableColumns = [
  {
    name: "Company",
    selector: (row) => row.PT_NAME,
    sortable: true,
    compact: false,
    wrap: false,
    reorder: true,
    width: '300px'
  },
  {
    name: "Account#",
    selector: (row) => row.CUST_ACCT_ID,
    sortable: true,
    compact: false,
    wrap: true,
    reorder: true,
    width: '100px'
  },
  {
    name: "Date",
    selector: (row) => formatDate(row.METERDATE),
    sortable: true,
    compact: false,
    wrap: true,
    reorder: true,
  },
  {
    name: "Mid No",
    selector: (row) => row.POINT_ID,
    sortable: true,
    compact: false,
    wrap: true,
    reorder: true,
  },
  {
    name: "MCF",
    selector: (row) => row.DAILY_VOL,
    sortable: true,
    compact: false,
    wrap: true,
    reorder: true,
    width: '80px'
  },
  {
    name: "DTH",
    selector: (row) => row.ENERGY,
    sortable: true,
    compact: false,
    wrap: true,
    reorder: true,
    width: '80px'
  },
  {
    name: "Pressure",
    selector: (row) => row.READ_INDEX,
    sortable: true,
    compact: false,
    wrap: true,
    reorder: true,
  },
  {
    name: "Temp",
    selector: (row) => row.TEMP,
    sortable: true,
    compact: false,
    wrap: true,
    reorder: true,
  },
  {
    name: "BTU",
    selector: (row) => row.BTU_FACTOR_raw,
    sortable: true,
    compact: false,
    wrap: true,
    reorder: true,
    width: '95px'
  },
];

export default DailyReadTableColumns;