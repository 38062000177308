import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import addIcon from "../../../assets/images/add.gif";
import supplier from "../../../assets/images/Supplier.gif";
import report from "../../../assets/images/efs-report.gif";
import FinPositionsTable from "./FinPositionsTable";
import { useDispatch, useSelector } from "react-redux";
import { financial_position_actions, getLatestMTMDate } from "../../../store/financial-position-slice";
import CodeSelector from "../../CustomComponents/Selectors/CodeSelector";
import BASelector from "../../CustomComponents/Selectors/BASelector";
import InstrumentSelector from "../../CustomComponents/Selectors/InstrumentSelector";
import ExportExcel from '../../CustomComponents/ExportExcel';
import FinPositionsTableColumns from './FinPositionsTable/FinPositionsTableColumns';
import useBusinessSelectorRedux from '../../../hooks/useBusinessSelectorRedux';
import { getDayStart, getMonthEnd, toDatePickerString } from '../../../utils/DateUtils';
import EOMReport from './EOMReport';


function PositionInventory() {

	// Redux
	const FinPositionFilters = useSelector(state => state.financial_position_slice.FinPositionFilters);
	const FinPositions = useSelector(state => state.financial_position_slice.FinPositions);
	const { startDate, endDate, MTMDate, dateFilterType } = FinPositionFilters;
	const dispatch = useDispatch();

	const [isEOMReportOpen, setIsEOMReportOpen] = useState(false);

	const handleEndOfMonthReport = () => {
		// Open the EOM Report modal
		setIsEOMReportOpen(true);
		// Dispatch any other relevant actions
		// dispatch(financial_position_actions.yourActionHere());
	};

	useBusinessSelectorRedux(FinPositionFilters, financial_position_actions.setFinPositionFilters);

	// Handlers
	const startDateChange = (event) => {
		const newStartDate = event.target.value;
		// Default end date to month end.
		const newEndDate = toDatePickerString(getMonthEnd(getDayStart(newStartDate)));
		dispatch(financial_position_actions.setFinPositionFilters({ ...FinPositionFilters, startDate: newStartDate, endDate: newEndDate }));
	};
	const endDateChange = (event) => {
		const { startDate } = FinPositionFilters;
		const newEndDate = event.target.value;
		const endIsBeforeStart = getDayStart(newEndDate).getTime() < getDayStart(startDate).getTime();
		// If new end date comes before start date, default start date to end date.
		const newStartDate = endIsBeforeStart ? newEndDate : startDate;
		dispatch(financial_position_actions.setFinPositionFilters({ ...FinPositionFilters, endDate: newEndDate, startDate: newStartDate }));
	};
	const MTMDateChange = (event) => {
		dispatch(financial_position_actions.setFinPositionFilters({ ...FinPositionFilters, MTMDate: event.target.value }));
	};
	const dateFilterTypeChange = (event) => {
		dispatch(financial_position_actions.setFinPositionFilters({ ...FinPositionFilters, dateFilterType: event.target.value }));
	};

	const newFinPositionClick = () => { dispatch(financial_position_actions.setSelectedFinPosition(null)); };

	// Get future price detail with the latest trade ref date. AKA "Last MTM Date".
	useEffect(() => { dispatch(getLatestMTMDate()); }, [dispatch]);


	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-sm-12 col-md-12 col-lg-12 p-1">
					<div className="card border-0">
						<div className="card-header bg-primary text-white text-center">
							<h4 className="card-title m-0">Position Inventory</h4>
						</div>
						<div className="card-body">
							{/* BUTTONS */}
							<div className="row">
								<div className="col-sm-12 col-md-12 col-lg-12 p-1 pb-2 d-flex">
									<Link to="/newFinancialPosition">
										<button className="btn btn-primary me-2 h-100" onClick={newFinPositionClick}>
											<img src={addIcon} alt="Add new record" /> New</button>
									</Link>
									<button className="btn btn-primary me-2"><img src={supplier} alt="Financial Contract" /> Financial Contract</button>
									<ExportExcel
										columns={FinPositionsTableColumns}
										fileName='Financial_Positions_Data.xlsx'
										data={FinPositions}
										className='btn btn-primary me-2 h-100'
									/>
									<button className="btn btn-primary h-100" onClick={handleEndOfMonthReport}><img src={report} alt="End of Month Report" /> End of Month Report</button>
								</div>
							</div>
							{/* Fields and selectors */}
							<div className="row bg-light-blue rounded mb-2 pb-1">
								<div className="row">
									<div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
										<label className='form-label m-0'>Period From:</label>
										<input
											className="form-control form-control-sm"
											type="date"
											value={toDatePickerString(startDate)}
											onChange={startDateChange}
										/>
									</div>
									<div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
										<label className='form-label m-0'>Period To:</label>
										<input
											className="form-control form-control-sm"
											type="date"
											value={toDatePickerString(endDate)}
											onChange={endDateChange}
										/>
									</div>
									<div className="col-lg-1 col-md-2 col-sm-3 p-1 text-start">
										<label className='form-label m-0'>Trade Period:</label>
										<br />
										<input
											type="radio"
											name='dateFilterType'
											value='TRADE_DATE'
											checked={dateFilterType === 'TRADE_DATE'}
											onChange={dateFilterTypeChange}
										/>
									</div>
									<div className="col-lg-1 col-md-2 col-sm-3 p-1 text-start">
										<label className='form-label m-0'>Del Month:</label>
										<br />
										<input
											type="radio"
											name='dateFilterType'
											value='DELIVERY_MONTH'
											checked={dateFilterType === 'DELIVERY_MONTH'}
											onChange={dateFilterTypeChange}
										/>
									</div>
									<div className="col-lg-1 col-md-2 col-sm-3 p-1 text-start">
										<label className='form-label m-0'>Filter:</label>
										<select className="form-select form-select-sm">
											<option defaultValue="all">All</option>
											<option defaultValue="open">Open</option>
											<option defaultValue="closed">Closed</option>
										</select>
									</div>
								</div>
								{/* End Row 1 */}
								{/* Start Row 2 */}
								<div className="row">
									<div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
										<label className='form-label m-0'>Portfolio:</label>
										<CodeSelector
											valueType='string'
											value={FinPositionFilters?.portfolioCDE}
											setValue={(newValue) => { dispatch(financial_position_actions.setFinPositionFilters({ ...FinPositionFilters, portfolioCDE: newValue })); }}
											url='Codes/GetCodes?cde_type=DSPC'
										/>
									</div>
									<div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
										<label className='form-label m-0'>Counter Party:</label>
										<BASelector
											valueType='string'
											value={FinPositionFilters?.counterPartyID}
											setValue={(newValue) => { dispatch(financial_position_actions.setFinPositionFilters({ ...FinPositionFilters, counterPartyID: newValue })); }}
											url='BusinessAssociate/GetBusinessAssociates?ba_role=BR'
										/>
									</div>
									<div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
										<label className='form-label m-0'>Instrument:</label>
										<InstrumentSelector
											valueType='string'
											value={FinPositionFilters?.instrumentID}
											setValue={(newValue) => { dispatch(financial_position_actions.setFinPositionFilters({ ...FinPositionFilters, instrumentID: newValue })); }}
											url='Hedging/RetrieveAllHedgeContracts'
										/>
									</div>
									<div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
										<label className='form-label m-0'>Last MTM Date:</label>
										<input
											value={MTMDate}
											onChange={MTMDateChange}
											className="form-control form-control-sm"
											type="date"
										/>
									</div>

								</div>
							</div>
							{/* Table section */}
							<div className="row">
								<FinPositionsTable />
							</div>
						</div>
					</div>
				</div>
			</div >
			<EOMReport isOpen={isEOMReportOpen} onClose={() => setIsEOMReportOpen(false)} />
		</div >)
}

export default PositionInventory;