function PostScript({ba}) {
    const customStyles = {
        fontSize: '9pt'
    };
    
    return (
        <>
            <div className="container-fluid" style={customStyles}>
                <div>Note for Imbalance (-) Owed to pipline: (+) Owed by pipeline</div>
                <div className="relaxHeight">For any questions, please feel free to contact at or Fax at</div>
            </div>
        </>
    )
}

export default PostScript;