import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { non_path_noms_actions, updatePackage } from '../../../../../store/non-path-noms-slice';
import NomDetailsTable from '../../Tables/NomDetailsTable';
import { useState, useEffect } from 'react';

//Modal Styling:
import './ViewDetailsModalStyles.css';
import Spinner from '../../../../Spinner';
const customStyles = { overlay: { backgroundColor: 'rgb(0,0,0,.60' } };


function ViewDetailsModal() {

	// Redux
	const { viewDetailsModalState } = useSelector(state => state.non_path_noms_slice.modalStates);
	const { open, previousModalState, previousModalStateName, loading } = viewDetailsModalState;
	const [tempPkgPaths, setTempPkgPaths] = useState([]);
	const dispatch = useDispatch();
	const selectedPackage = useSelector(state => state.non_path_noms_slice.selectedPackage);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setTempPkgPaths(selectedPackage?.ENT_PKG_PATH);
	}, [selectedPackage]);

	const closeModal = async () => {
		// Close ViewDetailsModal
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'viewDetailsModalState',
			newModalState: { ...viewDetailsModalState, open: false, selectedPkgPath: {} }
		}));

		// Open previous modal
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: previousModalStateName,
			newModalState: { ...previousModalState, open: true }
		}));
	};

	const handleSaveClick = async () => {
		setIsLoading(true);

		const newSelectedPackage = {
			...selectedPackage,
			ENT_PKG_PATH: tempPkgPaths
		};

		await dispatch(updatePackage(newSelectedPackage));

		setIsLoading(false);
	}

	return (
		<div>
			<Modal
				isOpen={open}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="Nomination Detail Modal"
				className='view-details-modal container-fluid'
			>
				<div>
					<div className='modal-header'>
						<h5>View Path Details</h5>
						<button className='btn-close' onClick={closeModal} />
					</div>
					{ /* buttons */}
					<div className="row pb-2">
						<div>
							<button
								className="btn btn-primary me-1"
								onClick={() => handleSaveClick()}
								disabled={ isLoading }
							>
								Save
							</button>
							{isLoading && <Spinner /> }
						</div>
					</div>
					<div className='modal-body bg-light-blue rounded pb-2 p-1'>
						{/* Nom details information */}
						<div className='row'>
							<div className='col-sm-12 col-md-12 col-lg-12'>
								<h6 className='mb-0'>Path Details</h6>
								<hr className='mt-0 mb-1' />
							</div>
						</div>
						<div className='row'>
							<NomDetailsTable loading={loading} tempPkgPaths={tempPkgPaths} setTempPkgPaths={setTempPkgPaths}  />
						</div>
					</div>
				</div>
			</Modal >
		</div >
	);
}

export default ViewDetailsModal;
