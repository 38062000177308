import { createAsyncThunk } from '@reduxjs/toolkit';

// In your business-selector-slice.js file
export const selectGasFlowDate = (state) => state.business_selector_slice.gasFlowDate;
export const selectBaRole = (state) => state.business_selector_slice.baRole;
// export const selectSelectedSupplier = (state) => state.business_selector_slice.selectedSupplier;
export const selectSelectedSupplier = (state) => state.business_selector_slice.selectedSupplier;
export const selectBARoles = (state) => state.business_selector_slice.baRoles;
export const selectBaId = (state) => state.business_selector_slice.baRole;
// export const cntr_num = (state) => state.store.general_data_slice.selectedContract.CNTR_NUM;


//Fetch everything from storage
export const getPointBalances = createAsyncThunk(
    "Storage/GetPointBalances",
  async ({ ba_id,
    //contracts
  }) => {
        const response = await fetch(`Storage/GetPointBalances?baId=${ba_id}`);
        if (!response.ok) {
            throw new Error("Error fetching storage data");
        }
        const storageData = await response.json();

        // Combine storageData and contracts data here. 
        const combinedData = storageData.map(data => ({
            ...data,
            //CNTR_NUM: contracts.CNTR_NUM,
        }));

        return combinedData;
    }
);


