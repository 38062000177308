import { useRef } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import excel from '../../assets/images/excel.gif';

// redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { weather_actions } from '../../store/weather-slice';
import {
    fetchWeatherData
} from '../../store/weather-thunks';



export default function Forecast() {

    //Redux:
    const weather = useSelector(state => state.weather_slice);

    // tableRef for Excel Export:
    const tableRef = useRef();

    //Handling the Excel Download:
    const { onDownload } = useDownloadExcel({
        // currentTableRef: tableRef.current,
        currentTableRef: tableRef.current,
        filename: 'Weather Gas Day List',
        sheet: 'Weather Gas Day List'
    });



    return (
        <>
            <div className="text-black table-responsive">
                <div className='card mt-2'>
                    <div className='card-header text-center'><h4>Weather Gas Day List</h4></div>
                    <div className='card-body'>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 p-2">
                                <button className='btn btn-md btn-primary m-2' onClick={onDownload}><img src={excel} alt='Export excel report' /> Excel Report</button>

                            </div>
                        </div>
                        <div className='row mb-3 bg-light-blue rounded p-2'>
                            <div className="col-lg-2 col-md-2 col-sm-12">
                                <label className='form-label pe-2'>Weather Area</label>
                                <select className='form-select form-select-sm' aria-label="select public or private" >
                                    <option>PSEG</option>
                                    <option>Select Weather Area</option>
                                </select>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12">
                                <label className='form-label'>Start Date</label>
                                <input className="form-control form-control-sm" type="month" value={`${new Date().getFullYear()}-${(new Date().getMonth() > 8 ? "" : "0") + (new Date().getMonth() + 1)}`} onChange={() => { }} />
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12">
                                <label className='form-label'>End Date</label>
                                <input className="form-control form-control-sm" type="month" value={`${new Date().getFullYear()}-${(new Date().getMonth() > 8 ? "" : "0") + (new Date().getMonth() + 1)}`} onChange={() => { }} />
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12">
                                <label className='form-label'>Weather Type</label>
                                <select className='form-select form-select-sm' aria-label="select public or private">
                                    <option>All</option>
                                </select>
                            </div>
                        </div>
                        <table className="bg-white table table-sm table-hover table-striped-columns table-bordered border-primary rounded pb-1"
                            ref={tableRef}
                            dense={true}
                        >
                            <thead>
                                <tr>
                                    {/* <th>View</th> */}
                                    <th>Forecast Date</th>
                                    <th>O/F</th>
                                    <th>Avg TMP</th>
                                    {/* <th>Max TMP</th>
                                    <th>Min TMP</th> */}
                                    <th>Avg HDD</th>
                                    <th>Eff HDD</th>
                                    {/* <th>Avg WSP</th> */}
                                    <th>Avg CDD</th>
                                    <th>Avg THI</th>
                                    <th>Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>2020/05/22</td>
                                    <td>Observed</td>
                                    <td></td>
                                    {/* <td></td>
                                    <td></td> */}
                                    <td>30</td>
                                    <td>25</td>
                                    <td>0</td>
                                    <td>38</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>2020/05/21</td>
                                    <td>Observed</td>
                                    <td>39.7</td>
                                    {/* <td></td>
                                    <td></td> */}
                                    <td>25</td>
                                    <td>600</td>
                                    <td>0</td>
                                    <td>48</td>
                                    <td></td>

                                </tr>
                                <tr>
                                    <td>2020/05/20</td>
                                    <td>Observed</td>
                                    <td>35.0</td>
                                    {/* <td></td>
                                    <td></td> */}
                                    <td>26</td>
                                    <td>25</td>
                                    <td>0</td>
                                    <td>38</td>
                                    <td></td>

                                </tr>
                                <tr>
                                    <td>2020/05/19</td>
                                    <td>Observed</td>
                                    <td>39.7</td>
                                    {/* <td></td>
                                    <td></td> */}
                                    <td>12</td>
                                    <td>39</td>
                                    <td>0</td>
                                    <td>48</td>
                                    <td></td>

                                </tr>

                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

