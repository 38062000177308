import { useState, useEffect } from 'react';
import { notification_actions } from '../../../store/notification-slice';

//Image imports
import Clear from '../../../assets/images/clear.png';
import Cancel from '../../../assets/images/cancel.png';

//Import Modules:
import Table from './Table';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { physical_trade_deals_actions } from '../../../store/physical-trade-deals-slice';

import './SelectPointModalStyling.css';
import SearchBar from '../../CustomComponents/SearchBar';

// Modal styling
const customStyles = {
	overlay: {
		backgroundColor: 'rgb(0,0,0,.60'
	},
};

function SelectPointModal() {

	// Redux:
	const openPointModal = useSelector(state => state.physical_trade_deals_slice.openPointModal);
	const selectedBidPkg = useSelector(state => state.physical_trade_deals_slice.selectedBidPkg);
	const dispatch = useDispatch();

	// State
	const [points, setPoints] = useState();
	const [allPoints, setAllPoints] = useState();
	const [totalRows, setTotalRows] = useState();
	const [loading, setLoading] = useState();
	const [filteredData, setFilteredData] = useState();
	const [showInactive, setShowInactive] = useState(false);

	// Pagination
	const [pageNumber, setPageNumber] = useState(1);
	const pageSize = 1000000000; // setting large number just to get all the points, not doing server side pagination so search bar can work with pagination

	const handlePageChange = (pageNumber) => {
		setPageNumber(pageNumber);
	};

	const handleShowInactive = () => {
		setShowInactive(!showInactive);
	};

	useEffect(() => {
		if (showInactive) {
			setPoints(allPoints);
		}
		else {
			setPoints(allPoints ? allPoints.filter(p => p.STATUS !== "IN") : []);
		}
	}, [showInactive]);

	// Fetch Points.
	useEffect(() => {
		const handleAsync = async () => {
			const pipeline_id = selectedBidPkg?.START_PT_NUM?.PIPELINE_OBJ?.BA_ID || selectedBidPkg?.PIPELINE_ID_OBJ?.BA_ID || selectedBidPkg?.START_PT_NUM_PIPELINE_ID;
			if (pipeline_id) {
				const requestData = async () => {
					const response = await fetch(`GeneralData/GetPoints?pipeline_id=${pipeline_id}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
					if (!response.ok) {
						const message = await response.text();
						throw new Error(message);
					}
					return await response.json();
				};
				try {
					setLoading(true);
					const { newPoints, totalRows } = await requestData();
					setPoints(newPoints.filter(p => p.STATUS !== "IN"));
					setAllPoints(newPoints);
					setTotalRows(totalRows);
				}
				catch (error) {
					dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
				}
				finally {
					setLoading(false);
				}
			}
		};
		handleAsync();
	},
		[
			pageNumber,
			selectedBidPkg?.PIPELINE_ID_OBJ,
			selectedBidPkg?.START_PT_NUM?.PIPELINE_OBJ,
			selectedBidPkg?.START_PT_NUM_PIPELINE_ID,
			dispatch
		]);

	// Modal State:
	const closePointModal = () => {
		dispatch(physical_trade_deals_actions.setOpenPointModal(false));
	};

	return (
		<>
			<Modal
				isOpen={openPointModal}
				onRequestClose={closePointModal}
				className="select-point-modal container-fluid bg-white text-black"
				style={customStyles}
			>
				<div id="SelectPointModal" tabIndex='-1'>
					<div className='modal-dialog '>
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title mb-2">Select Point</h4>
								<button
									type="button"
									className="btn-close fs-6"
									onClick={closePointModal}
								>
								</button>
							</div>
							<div className="modal-body">

								{/* // BUTTONS */}
								<div className="row text-start bg-light-blue rounded-top m-0 p-2">
									<div className="col-lg-3 col-md-12 col-md-12 col-sm-12">
										<button
											type="button"
											className="btn btn-primary m-1"
											title="New Deal"
											onClick={closePointModal}
										>
											<img src={Clear} alt="Clear" /> Clear
										</button>
										<button
											type="button"
											className="btn btn-primary m-1"
											title="Cancel"
											onClick={closePointModal}
										>
											<img src={Cancel} alt="AddImage" /> Cancel
										</button>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-12">
										{/* Search Bar */}
										<SearchBar
											data={points}
											setFilteredData={setFilteredData}
										/>
									</div>
									<div className="col-lg-2 col-md-4 col-sm-12 p-1 align-self-end">
										{/* Show Inactive Checkbox */}
										<div className="form-check ms-2">
											<input
												className="form-check-input"
												type="checkbox"
												id="showInactiveCheckbox"
												checked={showInactive}
												onChange={() => handleShowInactive()}
											/>
											<label class="form-check-label" for="showInactiveCheckbox">
												Show Inactive
											</label>
										</div>
									</div>
								</div>
								{/* SELECT A DEAL TABLE */}
								<div className='row text-start'>
									<Table
										points={filteredData}
										loading={loading}
										totalRows={totalRows}
										pageSize={pageSize}
										handlePageChange={handlePageChange}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default SelectPointModal