import Modal from 'react-modal';

// buttons
import Save from '../../../../assets/images/save.png';
import Delete from '../../../../assets/images/delete.png';
import Cancel from '../../../../assets/images/cancel.png';

// Utilities:
import { toDatePickerString } from "../../../../utils/DateUtils";
import BuyTable from '../Tables/BuyTable';
import SaleTable from '../Tables/SaleTable';

//Styling
import '../OptionStyling.css';


function NewOption({ isOpen, closeModal }) {
    return (

        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="New Option Modal"
            className="options-modal container-fluid bg-white"
            overlayClassName="Overlay"
        // customStyles={customStyles}
        >
            <div className='container-fluid'>
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <div className='col text-start mb-1'>
                            <h5 className="modal-title">Options Trade - New Deal</h5>
                        </div>
                        <div className='col text-end'>
                            <button
                                type="button"
                                className="btn-close btn btn-md mt-2"
                                onClick={closeModal}
                                aria-label="Close"
                            >
                            </button>
                        </div>
                    </div>
                    <div className="modal-body ">
                        {/* // BUTTONS */}
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-md-12 col-sm-12 text-start">

                                <button
                                    type="button"
                                    className="btn btn-primary mt-1 m-1"
                                    title="New Deal"

                                >
                                    <img src={Save} alt="SaveImage" /> Save
                                </button>


                                <button
                                    type="button"
                                    className="btn btn-primary mt-1 m-1"
                                    title="New Deal"

                                >
                                    <img src={Delete} alt="DeleteImage" /> Delete
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-primary mt-1 m-1"
                                    title="New Deal"
                                    onClick={closeModal}
                                >
                                    <img src={Cancel} alt="AddImage" /> Cancel
                                </button>
                            </div>
                        </div>
                        <div className='row bg-light-blue rounded text-start pb-2'>

                            <div className='row pb-2'>
                                <div className="col-lg-2 col-md-2 col-sm-2">
                                    <button className='btn btn-primary mt-1 btn-sm col-6' >New Trade</button>
                                    <br />
                                    <button className='btn btn-primary mt-1 btn-sm col-6'>Del Trade</button>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-3 text-start">

                                    <label className='form-label form-label text-start m-0'>Broker: </label>

                                    <select

                                        name='BIDTYPE'
                                        className="form-select form-select-sm col-lg-1 col-sm-3 text-start"
                                        aria-label="Default select example"
                                    >
                                        <option value='S'>Salomon Smith</option>
                                        <option value='B'>Barnes</option>
                                    </select>
                                    <label className='form-label m-0 text-start'>Trade ID: </label>
                                    <input
                                        disabled
                                        name='STATUS'
                                        className='form-control form-control-sm col-lg-1 col-md-2 col-sm-3 text-start'
                                        placeholder='10001'
                                    />
                                    <label className='form-label m-0 col-lg-auto col-md-auto col-sm-12 text-start'>Fee Paid: </label>
                                    <input
                                        disabled

                                        name='AMEND_NUM'
                                        className="form-control form-control-sm col-lg-1 col-md-2 col-sm-3 text-start"
                                        aria-label="Default select example"
                                    />

                                </div>
                                {/* End of Column 1 */}

                                {/* Column 2 Start */}
                                <div className="col-lg-2 col-md-2 col-sm-3 text-start">
                                    <label className='form-label m-0 text-start'>Contract ID: </label>
                                    <select disabled className="form-control form-control-sm col-lg-1 col-md-2 col-sm-3 text-start" aria-label="Default select example" placeholder='NGAS:HH:FU'>
                                        <option defaultValue="NGAS:HH:FU">NGAS:HH:FU</option>
                                    </select>
                                    <label className='form-label m-0 text-start'>Confirmation Time:</label>
                                    <input

                                        className="form-control form-control-sm text-start"
                                        aria-label="Default select example"
                                        placeholder='10:15'
                                        disabled
                                    />
                                    <div className='row'>
                                        <div className="col-lg-3 col-md-2 col-sm-3 p-1 ms-3">
                                            <label className='form-label m-0'>$/Unit:</label>
                                            <br />
                                            <input
                                                type="radio"
                                                defaultChecked={true}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-md-2 col-sm-3 p-1 ms-3">
                                            <label className='form-label m-0'>Total:</label>
                                            <br />
                                            <input
                                                type="radio"
                                            />
                                        </div>
                                    </div>

                                </div>
                                {/* End of Column 2 */}
                                {/* Column 3 Start */}

                                {/* End of Column 3 */}
                                {/* Column 4 Start */}
                                <div className="col-lg-2 col-md-2 col-sm-3 text-start">
                                    <label className='form-label text-start m-0'>Trade Date:</label>

                                    <input
                                        defaultValue={toDatePickerString(new Date())}
                                        // onChange={filterChange}
                                        name='startDate'
                                        className="form-control form-control-sm"
                                        type="date"
                                        readOnly={false}
                                    />

                                    <label className='form-label m-0 text-start'>Contact: </label>
                                    <input
                                        className="form-control form-control-sm col-lg-1 col-md-2 col-sm-3 text-start"
                                        aria-label="Default select example"
                                        placeholder='Manually input Contract '


                                    />
                                </div>
                                {/* End of Col 4 */}
                                {/* Begin Col 5 */}
                                <div className='col-lg-2 col-md-2 col-sm-2 text-start'>
                                    <label className='form-label m-0 text-start'>Order Time:</label>
                                    <input
                                        className="form-control form-control-sm col-lg-2 col-md-2 col-sm-2 text-start"
                                        aria-label="Default select example"
                                        placeholder='09:30'
                                        disabled
                                    />
                                </div>
                            </div>
                            {/* End of Row */}
                            {/* <div className="row bg-light-blue">
                                    <div className="col-12"> */}
                            <h6 className='text-start fw-bold'>Buy</h6>
                            <BuyTable />
                            <br />
                            <h6 className='text-start fw-bold mt-2'>Sell</h6>
                            <SaleTable />
                            {/* </div>
                                </div> */}
                        </div>

                    </div>



                </div>

            </div>
        </Modal >
    );
}

export default NewOption;