import { useSelector, useDispatch } from 'react-redux';
import { business_selector_actions } from '../../store/business-selector-slice';
import { toDatePickerMonthString } from '../../utils/DateUtils';
import BARoleSelect from './BARoleSelect';
import BusinessUnitSelect from './BusinessUnitSelect';
import SupplierSelect from './SupplierSelect';

const BusinessSelector = () => {
  // Redux
  const gasFlowDate = useSelector(
    (state) => state.business_selector_slice.gasFlowDate
  );
  const dispatch = useDispatch();

  // Handlers
  const gasFlowDateChangeHandler = (event) => {
    const gas_flow_date = new Date(event.target.value).toUTCString();
    dispatch(
      business_selector_actions.changeGasFlowDate(gas_flow_date.toString())
    );
  };

  return (
    <div>
      <form>
        <label className="ms-1 me-1">
          Gasflow Month:
          <input
            className="form-control form-control-sm"
            type="month"
            value={toDatePickerMonthString(gasFlowDate)}
            onChange={gasFlowDateChangeHandler}
          />
        </label>
        <BusinessUnitSelect />
        <label className="ms-1 me-1">
          Business Associate:
          <SupplierSelect
            selectedMonth={toDatePickerMonthString(gasFlowDate)}
          />
        </label>
        <BARoleSelect />
      </form>
    </div>
  );
};

export default BusinessSelector;
