// import axios from "axios";
import { createSlice /*, createAsyncThunk*/ } from "@reduxjs/toolkit";

// export const fetchDailyReadData = createAsyncThunk(
//   "CustList/GetDailyReadData",
//   async () => {
//     const response = await axios.get("CustList/GetDailyReadData", );
//   }
// )

const initialState = {
  customers: [],
  totalActEnergy: 0,
  selectedCustomer: {},
  openDailyReadModal: false,
  loading: {
    state: false,
    processes: 0,
  },
};

const customer_list_view_slice = createSlice({
  name: "customer_list_view_slice",
  initialState,
  reducers: {
    setLoading(state, action) {
      const newState = action.payload;
      if (newState) {
        state.processes++;
        state.loading = true;
      } else {
        state.processes--;
        if (state.processes < 1) state.loading = false;
      }
    },
    setCustomers(state, action) {
      state.customers = action.payload;
    },
    setTotalActEnergyRows(state, action) {
      state.totalActEnergy = action.payload;
    },
    // setFilters(state, action) {
    //   state.filters = action.payload;
    // },
    setSelectedCustomer(state, action) {
      state.selectedCustomer = action.payload;
    },
    setOpenDailyReadModal(state, action) {
      state.openDailyReadModal = action.payload;
    },
  },
});

export const customer_list_view_actions = customer_list_view_slice.actions;
export const {
  setLoading,
  setCustomers,
  setTotalActEnergyRows,
  setSelectedCustomer,
  setOpenDailyReadModal,
} = customer_list_view_slice.actions;
export default customer_list_view_slice.reducer;
