import DataTable from "react-data-table-component";

function Table({ GasMeters, isDTH }) {
    const columns = [
        {
            name: "Date",
            selector: row => new Date(row?.METERDATE).toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
            }),
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
        },
        {
            name: "Act Vol",
            selector: row => isDTH && row.DailyVolume_unt ? (row.DailyVolume_unt / 10).toLocaleString() : row.DailyVolume_unt?.toLocaleString(),
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
        },
        {
            name: "BTU Factor",
            selector: row => row.BTU_FACTOR,
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
        },
        {
            name: "Act Energy",
            selector: row => row.ActEnergy_unt?.toLocaleString(),
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
        },
        {
            name: "Cum Vol",
            selector: row => isDTH && row.CUMDAILYVOL ? (row.CUMDAILYVOL / 10).toLocaleString() : row.CUMDAILYVOL?.toLocaleString(),
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
        },
        {
            name: "Cum Energy",
            selector: row => row.CUMENERGY?.toLocaleString(),
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
        },
        {
            name: "Cum BTU Factor",
            selector: row => isDTH && row.BTUFactorCum.toLocaleString() ? (row.BTUFactorCum * 10).toLocaleString() : row.BTUFactorCum,
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
        },
        {
            name: "Updated By",
            selector: row => row.USERSTAMP,
            sortable: true,
            compact: true,
            wrap: true,
    
        },
        {
            name: "Updated On",
            selector: row => new Date(row?.DATESTAMP).toLocaleString('en-US', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            }),
            sortable: true,
            compact: true,
            wrap: true,
            width: "200px",
        },
    ];

    return (
        <DataTable
            className="pb-1"
            columns={columns}
            data={GasMeters}
            striped={true}
            fixedHeader={true}
            highlightOnHover={true}
            responsive={true}
            dense={true}
        />
    );
}

export default Table;