import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { dailyMeasurement_actions } from "../../../../store/daily-measurement-slice";
import { fetchHourlyPMMeasurement } from "../../../../store/daily-measurement-thunks";

function DailyMeasurementTable({ data, isDTH }) {

    const dispatch = useDispatch();

    const selectedContractsData = useSelector(state => state.measurement.selectedContractsData);

    const columns = [
        {
            name: "Date",
            selector: row => new Date(row?.METERDATE).toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
            }),
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
        },
        {
            name: "Act Vol",
            selector: row => isDTH && row.DailyVolume_unt ? (row.DailyVolume_unt / 10).toLocaleString() : row.DailyVolume_unt?.toLocaleString(),
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
        },
        {
            name: "BTU Factor",
            selector: row => row.BTU_FACTOR,
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
            omit: true,
        },
        {
            name: "Act Energy",
            selector: row => row.ActEnergy_unt?.toLocaleString(),
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
        },
        {
            name: "Cum Vol",
            selector: row => isDTH && row.CUMDAILYVOL ? (row.CUMDAILYVOL / 10).toLocaleString() : row.CUMDAILYVOL?.toLocaleString(),
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
        },
        {
            name: "Cum Energy",
            selector: row => row.CUMENERGY?.toLocaleString(),
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
        },
        {
            name: "Cum BTU Factor",
            selector: row => isDTH && row.BTUFactorCum.toLocaleString() ? (row.BTUFactorCum * 10).toLocaleString() : row.BTUFactorCum,
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
            omit: true,
        },
        {
            name: "PPA Date",
            selector: row => row.PPADATE == null ? "" : new Date(row.PPADATE).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
            }),
            sortable: true,
            compact: true,
            wrap: true,
        },
        {
            name: "Updated By",
            selector: row => row.USERSTAMP,
            sortable: true,
            compact: true,
            wrap: true,

        },
        {
            name: "Updated On",
            selector: row => new Date(row?.DATESTAMP).toLocaleString('en-US', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            }),
            sortable: true,
            compact: true,
            wrap: true,
            width: "200px",
        },
    ];

    const rowClick = (row) => {
        var selectedDateHourly = row?.METERDATE ? new Date(row?.METERDATE).toISOString() : null;
        dispatch(fetchHourlyPMMeasurement(
            {
                selectedDate: selectedDateHourly,
                pointID: selectedContractsData?.PointShortName
            }
        )); 
        dispatch(dailyMeasurement_actions.setSelectedDateHourly(selectedDateHourly));
        dispatch(dailyMeasurement_actions.setShowHourlyModal(true));
    };

    return (
        <DataTable
            className="pb-1"
            columns={columns}
            data={data}
            striped={true}
            fixedHeader={true}
            highlightOnHover={true}
            responsive={true}
            dense={true}
            onRowClicked={rowClick}
        />
    );
}

export default DailyMeasurementTable;