import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { fetchPackages, interstate_noms_actions, setPackageAndFetchPkgPaths } from '../../../../store/interstate-noms-slice';
import { useSelector, useDispatch } from 'react-redux';
import { toDatePickerString, getDayStart, formatDateTime } from '../../../../utils/DateUtils';
import { formValuesFromPackage } from '../../../../utils/GeneralNomUtils';

//Table:
import NomDetailTable from './Tables';

//Images:
import saveNom from '../../../../assets/images/save.png';
import cancelNom from '../../../../assets/images/cancel.png';

//Modal Styling:
import './detail-modal.css';
import { useForm } from 'react-hook-form';
import { notification_actions } from '../../../../store/notification-slice';
import { getFuelPercent, calcDlvAndFuel, calcRcvAndFuel } from '../../../../utils/GeneralNomUtils';
import Spinner from '../../../Spinner';
const customStyles = {
	overlay: {
		backgroundColor: 'rgb(0,0,0,.60'
	}
};

function NominationDetailModal() {

	// Redux
	const selectedPackage = useSelector(state => state.interstate_noms_slice.selectedPackage);
	const filters = useSelector(state => state.interstate_noms_slice.filters);
	const nomUiModalState = useSelector(state => state.interstate_noms_slice.modalStates?.nomDetailModalState);
	const dispatch = useDispatch();

	// Need to keep a separate state that is used to modify. When submitting changes to server,
	// that is when the edited package volume details will be set in the selected package.
	// The selected package will then be sent to the server to be saved to database.
	const [pkgVolDtls, setPkgVolDtls] = useState([]);

	// This will update the package volume details any time the selected package changes.
	useEffect(() => { setPkgVolDtls(selectedPackage?.ENT_PKG_PATH?.[0]?.ENT_PKG_VOL_DTL); }, [selectedPackage]);

	const [saving, setSaving] = useState(false);

	// React Hook Form
	const form = useForm();
	const { register } = form;

	// Resets the form with the newly selected package's information.
	useEffect(() => {
		form.reset({
			...formValuesFromPackage(selectedPackage),
			DATESTAMP: formatDateTime(selectedPackage?.DATESTAMP),
			filterStartDate: toDatePickerString(selectedPackage?.STARTDATE),
			filterEndDate: toDatePickerString(selectedPackage?.ENDDATE),
			dth: 0,
			flowDirection: 'R'
		});
	}, [selectedPackage, form]);

	const closeModal = () => {
		dispatch(interstate_noms_actions.changeModalState({
			modalStateName: 'nomDetailModalState',
			newModalState: { ...nomUiModalState, open: false }
		}));
	};

	// Perform changes to redux state.
	const updateVolDtls = (event) => {
		const { getValues } = form;
		const formValues = getValues();
		const { filterStartDate, filterEndDate } = formValues;
		const newVolumes = calcNewVolumes(formValues);

		const newPkgVolDtls = pkgVolDtls.map(prevPkgVolDtl => {
			const startDate = getDayStart(filterStartDate);
			const endDate = getDayStart(filterEndDate);
			const gasFlowDate = getDayStart(prevPkgVolDtl.GASFLOW_DATE);

			if (gasFlowDate >= startDate && gasFlowDate <= endDate) {
				// Event target name is needed to determine which properties to change. 
				return createUpdatedDtl(event, prevPkgVolDtl, newVolumes);
			}
			return prevPkgVolDtl;
		});

		setPkgVolDtls(newPkgVolDtls);

		// Update redux state.
		//dispatch(interstate_noms_actions.setSelectedPackage(newPackage));
	};

	// Uses form state to calculate new receive, deliver, and fuel values.
	const calcNewVolumes = (formValues) => {

		// Grab items from formState.
		const {
			dth,
			flowDirection,
			CNTR_NUM
		} = formValues;

		// Fuel percent for calculations.
		const fuelPercent = getFuelPercent(CNTR_NUM);

		// Return new volumes.
		if (flowDirection === 'R') {
			const { rcvVol, dlvVol, fuel } = calcDlvAndFuel(dth, fuelPercent);
			return {
				newRcvVol: rcvVol,
				newDlvVol: dlvVol,
				newFuel: fuel,
				newFuelPercent: fuelPercent
			};
		}
		else if (flowDirection === 'D') {
			const { dlvVol, rcvVol, fuel } = calcRcvAndFuel(dth, fuelPercent);
			return {
				newDlvVol: dlvVol,
				newRcvVol: rcvVol,
				newFuel: fuel,
				newFuelPercent: fuelPercent
			};
		}
		else {
			// Ask user to select receive or deliver.
			dispatch(notification_actions.showModal({ header: 'Error', message: 'Please select either receive(R) or deliver(D).' }));
			return;
		}
	};

	// Creates a shallow copy of the previous package volume detail with updated volume details.
	const createUpdatedDtl = (event, prevPkgVolDtl, newVolumes) => {

		// Grab new volumes.
		const { newRcvVol, newDlvVol, newFuel, newFuelPercent } = newVolumes;

		// Create new package volume detail.
		let newPkgVolDtl = { ...prevPkgVolDtl, FUEL: newFuel, FUEL_PERCENT_DB: newFuelPercent };

		// Change properties based on event.target.name.
		switch (event.target.name) {
			case 'NOM': {
				newPkgVolDtl.NOM_RCV_VOL = newRcvVol;
				newPkgVolDtl.NOM_DLV_VOL = newDlvVol;
				break;
			}
			case 'ACT': {
				newPkgVolDtl.ACT_RCV_VOL = newRcvVol;
				newPkgVolDtl.ACT_DLV_VOL = newDlvVol;
				break;
			}
			default: {
				dispatch(notification_actions.showModal({
					header: 'Error',
					message: `Switch case was not implemented for "${event.target.name}". Please make sure you add a name to your property name button.`
				}));
				break;
			}
		};

		return newPkgVolDtl;
	};

	// Submit data to server.
	const submitData = async () => {
		try {
			// Set modified package volume details into selected package.
			const prevPkgPath = selectedPackage?.ENT_PKG_PATH?.[0];
			const newPkgPath = { ...prevPkgPath, ENT_PKG_VOL_DTL: pkgVolDtls };
			const modifiedPackage = { ...selectedPackage, ENT_PKG_PATH: [newPkgPath] };

			setSaving(true);

			// Send new package to database to be saved.
			const response = await fetch('Package/UpdatePackage', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: JSON.stringify(modifiedPackage)
			});

			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			const newPackage = await response.json();
			await dispatch(setPackageAndFetchPkgPaths(newPackage));

			await dispatch(notification_actions.showModal({ header: 'Success!', message: 'Nomination Details saved successfully!' }));
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
		finally {
			setSaving(false);
			await dispatch(fetchPackages(filters));
		}
	};

	return (
		<div>
			<Modal
				isOpen={nomUiModalState?.open}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="Nomination Detail Modal"
				className='nom-detail-modal detail-modal container-fluid'
			>
				<div className=''>
					<div className='modal-header'>
						<h5>Nomination Detail UI</h5>
						<button className='btn-close' onClick={closeModal} />
					</div>
					<div className='modal-body bg-light-blue rounded pb-2 p-1'>
							<div className='row'>
								<div className='col-sm-12 col-md-12 col-lg-12'>
								<button type='button' className='btn btn-sm btn-primary me-2 mb-1' onClick={submitData} disabled={saving}>
									{saving ? <Spinner status='saving' /> : <><img src={saveNom} alt='Save Nomination' /> Save Nomination</> }
									</button>
									{
										/* Not needed.
										<button className='btn btn-sm btn-primary me-2 mb-1'>
											<img src={deleteNom} alt='Delete Nomination' /> Delete Nomination
										</button>
										*/
									}
									<button type='button' className='btn btn-sm btn-primary me-2 mb-1' onClick={closeModal}>
										<img src={cancelNom} alt='Cancel Nomination' /> Cancel Nomination
									</button>
								</div>
							</div>
							{/* Nom details information */}
							<div className='row'>
								<div className='col-sm-12 col-md-12 col-lg-12'>
									<h6 className='mb-0'>Nomination Details Information</h6>
									<hr className='mt-0 mb-1' />
								</div>
							</div>
							<div className='row'>
								<div className='col-sm-12 col-md-2 col-lg-2'>
									<label className='form-label mb-0'>Pkg Num: </label>
									<div className='form-group mb-0'>
										<input type='text' className='form-control form-control-sm' {...register('PACKAGE_NUM')} disabled />
									</div>
									<label className='form-label mb-0'>Rcv Location: </label>
									<div className='form-group mb-0'>
										<input type='text' className='form-control form-control-sm' {...register('START_PT_NUM.POINT_ID')} disabled />
									</div>
									<label className='form-label mb-0'>Del Loc: </label>
									<div className='form-group mb-0'>
										<input type='text' className='form-control form-control-sm' {...register('END_PT_NUM.POINT_ID')} disabled />
									</div>
								</div>
								<div className='col-sm-12 col-md-2 col-lg-3'>
									<label className='form-label mb-0'>Contract: </label>
									<div className='form-group mb-0'>
										<input type='text' className='form-control form-control-sm' {...register('CNTR_NUM.CNTR_ALT_NUM1')} disabled />
									</div>
									<label className='form-label mb-0'>Contract Level: </label>
									<div className='form-group mb-0'>
										<input type='text' className='form-control form-control-sm' defaultValue='-' disabled />

									</div>
									<label className='form-label mb-0'>Contract Level: </label>
									<div className='form-group mb-0'>
										<input type='text' className='form-control form-control-sm' defaultValue='-' disabled />
									</div>
								</div>
								<div className='col-sm-12 col-md-2 col-lg-2'>
									<label className='form-label mb-0'>Activity: </label>
									<div className='form-group mb-0'>
									<input
										type='text'
										className='form-control form-control-sm'
										{...register('ACTIV_NUM.ACTIV_ALT_NUM')}
										disabled
									/>
									</div>
									<label className='form-label mb-0'>Updated By: </label>
									<div className='form-group mb-0'>
										<input type='text' className='form-control form-control-sm' {...register('USERSTAMP')} disabled />
									</div>
									<label className='form-label mb-0'>Updated On: </label>
									<div className='form-group mb-0'>
										<input type='text' className='form-control form-control-sm' {...register('DATESTAMP')} disabled />
									</div>
								</div>
								<div className='col-sm-12 col-md-2 col-lg-2'>
									<label className='form-label mb-0'>Up Pkg#:</label>
									<div className='form-group mb-0'>
										<input type='text' className='form-control form-control-sm' defaultValue='-' disabled />
									</div>
									<label className='form-label mb-0'>Up K#:</label>
									<div className='form-group mb-0'>
										<input type='text' className='form-control form-control-sm' defaultValue='-' disabled />
									</div>
								</div>
							</div>
							{/* Update Quantities  */}
							<div className='row mt-1'>
								<div className='col-sm-12 col-md-12 col-lg-12'>
									<h6 className='mb-0'>Update Quantities</h6>
									<hr className='mt-0 mb-1' />
								</div>
							</div>
							<div className='row'>
								<div className='col-sm-12 col-md-2 col-lg-2'>
									<label className='form-label mb-0'>From: </label>
									<div className='form-group mb-0'>
										<input
											className="form-control form-control-sm"
											type="date"
											style={{ maxWidth: '180px' }}
											max={toDatePickerString(selectedPackage?.ENDDATE)}
											min={toDatePickerString(selectedPackage?.STARTDATE)}
											{...register('filterStartDate')}
										/>
									</div>
								</div>
								<div className='col-sm-12 col-md-2 col-lg-2'>
									<label className='form-label mb-0'>To: </label>
									<div className='form-group mb-0'>
										<input
											className="form-control form-control-sm"
											type="date"
											style={{ maxWidth: '180px' }}
											max={toDatePickerString(selectedPackage?.ENDDATE)}
											min={toDatePickerString(selectedPackage?.STARTDATE)}
											{...register('filterEndDate')}
										/>
									</div>
								</div>
								<div className='col-sm-12 col-md-2 col-lg-1'>
									<label className='form-label mb-0'>Dth: </label>
									<div className='form-group mb-0'>
										<input type='text' className='form-control form-control-sm' {...register('dth', { valueAsNumber: true })} />
									</div>
								</div>
								<div className='col-sm-12 col-md-1 col-lg-1'>
									<div className="form-check mt-3">
										<input className="form-check-input" type="radio" id="flexCheckDefault" {...register('flowDirection')} value='R' />
										<label className="form-check-label" htmlFor="flexCheckDefault">
											R
										</label>
									</div>
								</div>
								<div className='col-sm-12 col-md-1 col-lg-1'>
									<div className="form-check mt-3">
										<input className="form-check-input" type="radio" id="flexCheckDefault" {...register('flowDirection')} value='D' />
										<label className="form-check-label" htmlFor="flexCheckDefault">
											D
										</label>
									</div>
								</div>
								<div className='col-sm-12 col-md-2 col-lg-2'>
									<label className='form-label mb-0'>Selection: </label>
									<br />
									<div className='btn-group mb-0'>
										<button name='NOM' className='btn btn-sm btn-primary' onClick={updateVolDtls}>Nom Q</button>
										<button name='ACT' className='btn btn-sm btn-primary' onClick={updateVolDtls}>Act Q</button>
										{/* Not needed for now. <button className='btn btn-sm btn-primary'>Ovr Q</button>*/}
									</div>
								</div>
								{
									/* Not needed for now.
									<div className='col-sm-12 col-md-2 col-lg-2'>
										<label className='form-label mb-0'>Beg Value: </label>
										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' />
										</div>
									</div>
									<div className='col-sm-12 col-md-1 col-lg-1'>
										<label className='form-label mb-0'>Submit: </label>
										<button className='btn btn-sm btn-primary'>Submit</button>
									</div>
									*/
								}
							</div>
							{/* Nom Volume Details  */}
							<div className='row mt-1'>
								<div className='col-sm-12 col-md-12 col-lg-12'>
									<h6 className='mb-0'>Nomination Volume Details</h6>
									<hr className='mt-0 mb-1' />
								<NomDetailTable pkgVolDtls={pkgVolDtls} />
								</div>
							</div>
					</div>
				</div>
			</Modal >
		</div >
	);
}

export default React.memo(NominationDetailModal);

