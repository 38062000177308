// redux
import { useSelector, useDispatch } from 'react-redux';
import { auth_actions } from '../../store/auth-slice';

// react modal
import Modal from 'react-modal';

// images
import logo1 from '../../assets/images/gastar-logo.png'


Modal.setAppElement('#root');

const PassFailureModal = () => {

    // Redux
    const showPasswordFailure = useSelector(state => state.auth_slice.showPasswordFailure);
    const dispatch = useDispatch();

    // Handlers
    const closeModal = () => {
        dispatch(auth_actions.setShowPasswordFailure(false));
    };

    // Modal styling
    const customStyles = {
        overlay: {
            backgroundColor: 'rgb(0,0,0,.60'
        },
        //     content: {
        //         position: 'absolute',
        //         top: '25%',
        //         left: '40%',
        //         right: '40%',
        //         bottom: '25%',
        //     },
    };

    return (
        // <div className='Overlay'>
        <Modal
            isOpen={showPasswordFailure}
            contentLabel='change-password'
            style={customStyles}
            className='Modal'
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h1 className="fs-5 text-center" id="login-success">Validation Message</h1>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={closeModal}
                        >
                        </button>
                    </div>
                    <form>
                        <div className="modal-body justify-content-center text-center fs-5">
                            <img src={logo1} alt='Gastar Logo' className='img-fluid mb-4' style={{ maxWidth: '200px' }} />
                            <br />
                            <h2 className='fs-3'>Password and/or Username does not match</h2>
                        </div>
                        <div className="modal-footer">
                            <button
                                type='button'
                                className='btn btn-lg btn-primary'
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </Modal>
        // </div>
    );

};

export default PassFailureModal;