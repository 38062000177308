/*
 * Creator: Cameron
 * Date: 9/1/2023
 * Purpose: This is a general Nominations util file. Anything
 * not specific to Path or Non Path Noms go here.
 * 
 * 
 * 
 */
import { toDatePickerString, toDatePickerTimeString, formatDateTime, getMonthEnd, getNextDay } from '../utils/DateUtils';
import { roundToDecimalPlace } from '../utils/MathUtils';

export const formValuesFromPackage = (selectedPackage) => {
	const pkgPath = selectedPackage?.ENT_PKG_PATH?.[0];
	const path_num = pkgPath?.PATH_NUM; // This is needed for update functionality.
	const firstPkgVolDtl = pkgPath?.ENT_PKG_VOL_DTL?.[0];
	const nom_rcv_vol = firstPkgVolDtl?.NOM_RCV_VOL;
	const nom_dlv_vol = firstPkgVolDtl?.NOM_DLV_VOL;
	const fuel = firstPkgVolDtl?.FUEL;
	const fuel_percent = firstPkgVolDtl?.FUEL_PERCENT_DB;
	const rank = firstPkgVolDtl?.RANK;
	return {
		...selectedPackage,
		START_PT_NUM: pkgPath?.RCV_PT_NUM,
		END_PT_NUM: pkgPath?.DLV_PT_NUM,
		PATH_NUM: path_num,
		STARTDATE: toDatePickerString(selectedPackage?.STARTDATE),
		ENDDATE: toDatePickerString(selectedPackage?.ENDDATE),
		BEGINTIME: toDatePickerTimeString(selectedPackage?.STARTDATE),
		ENDTIME: toDatePickerTimeString(selectedPackage?.ENDDATE),
		DATESTAMP: formatDateTime(selectedPackage?.DATESTAMP),
		FUEL: fuel,
		FUEL_PERCENT: fuel_percent,
		NOM_RCV_VOL: nom_rcv_vol,
		NOM_DLV_VOL: nom_dlv_vol,
		RANK: rank
	};
};

export const formValuesFromContract = (selectedContract) => {
	// Note: never set blank objects as default values for React Hook Form.
	// If you decide to reset the form, the values that were originally in
	// the object will remain. You must null out the objects when resetting the form
	// In order for the registered values to disappear.
	return {
		PKGTYPE: 'PL',
		CNTR_NUM: selectedContract,
		ACTIV_NUM: null,
		TT: 'NM',
		PACKAGE_NUM: null,
		STATUS: 'NM',
		STARTDATE: toDatePickerString(getNextDay(new Date())),
		ENDDATE: toDatePickerString(getMonthEnd(new Date())),
		BEGINTIME: '09:00',
		ENDTIME: '09:00',
		VOLUME_TYPE: 'FM',
		START_PT_NUM: null,
		END_PT_NUM: null,
		IsQtyApplyFullDuration: false,
		NOM_RCV_VOL: 0,
		NOM_DLV_VOL: 0,
		CYCLE: '',
		RANK: '',
		COMMENTS: '',
		USERSTAMP: null,
		DATESTAMP: null,
		FUEL_PERCENT: getFuelPercent(selectedContract),
		FUEL: 0
	};
};

export const getFuelPercent = (selectedContract) => {
	const rate = selectedContract?.RateSchObj;
	const rateType = rate?.ENT_RATE_TYPES?.[0]; // I believe that rate to rateType should actually be one to one. - Cameron
	const fuelRateDetail = rateType?.ENT_RATE_DETAILS?.find(rateDtl => rateDtl?.CDE === 'FU');
	const fuelPercent = fuelRateDetail?.VALUE;
	return fuelPercent || 0;
};

/**
 * Calculates the deliver and fuel quantities from a given un-rounded receive volume.
 * Returns the rounded receive volume, deliver volume, and fuel.
 * @param {any} unroundedRcvVol
 * @param {any} fuelPercent
 * @param {any} numberFormat - Should be an object that has two properties: shouldRound(bool) and decimalPlace(integer).
 */
export const calcDlvAndFuel = (unroundedRcvVol, fuelPercent, numberFormat) => {
	// Rounding Options
	const shouldRound = numberFormat ? numberFormat.shouldRound : false;
	const decimalPlace = numberFormat ? numberFormat.decimalPlace : 4;

	// Calculate fuel and dlv vol.
	const rcvVol = shouldRound ? roundToDecimalPlace(unroundedRcvVol, decimalPlace) : unroundedRcvVol;
	const unroundedFuel = rcvVol * fuelPercent;
	const fuel = shouldRound ? roundToDecimalPlace(unroundedFuel, decimalPlace) : unroundedFuel;
	const dlvVol = rcvVol - fuel;

	return { fuel, rcvVol, dlvVol };
};

/**
 * Calculates the receive and fuel quantities from a given un-rounded deliver volume.
 * Returns the rounded receive volume, deliver volume, and fuel.
 * @param {any} unroundedDlvVol
 * @param {any} fuelPercent
 * @param {any} numberFormat - Should be an object that has two properties: shouldRound(bool) and decimalPlace(integer).
 */
export const calcRcvAndFuel = (unroundedDlvVol, fuelPercent, numberFormat) => {
	// Rounding Options
	const shouldRound = numberFormat ? numberFormat.shouldRound : false;
	const decimalPlace = numberFormat ? numberFormat.decimalPlace : 4;

	// Calculate fuel and rcv vol.
	const dlvVol = shouldRound ? roundToDecimalPlace(unroundedDlvVol, decimalPlace) : unroundedDlvVol;
	const unroundedFuel = dlvVol * fuelPercent / (1 - fuelPercent);
	const fuel = shouldRound ? roundToDecimalPlace(unroundedFuel, decimalPlace) : unroundedFuel;
	const rcvVol = dlvVol + fuel;

	return { fuel, dlvVol, rcvVol };
};

export const fetchPkgPaths = async (package_num) => {
	try {
		// If there is a previous abort controller, abort it.
		let prevAbortController = fetchPkgPaths.prevAbortController;
		if (prevAbortController) prevAbortController.abort();

		if (!package_num) throw new Error('Package number was not defined.');

		// Create a new abort controller and fetch the data.
		const newAbortController = new AbortController();
		const response = await fetch(`PackagePath/GetPackagePaths?package_num=${package_num}`, { signal: newAbortController.signal });

		// Handle bad responses.
		if (!response.ok) {
			const message = await response.text();
			throw new Error(message);
		}

		// Deserialize package paths.
		const pkgPaths = await response.json();

		// Set previous abort controller.
		prevAbortController = newAbortController;

		return pkgPaths;
	}
	catch (error) {
		// If this error is from a request being aborted, just return null.
		if (error.name === 'AbortError') {
			console.log(error.message);
			return null;
		}
		throw error;
	}
};