import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { physical_trade_deals_actions } from '../../../store/physical-trade-deals-slice';
import Spinner from '../../Spinner';
import CustomStyles from './SSM-Table-Style';

function Table({ counterParties, loading }) {

	const columns = [
		{
			cell: row => <input type="checkbox" checked={selectedBidPkg?.[property]?.BA_ID === row?.BA_ID} onChange={() => { handleRowClicked(row) }} />,
			allowOverflow: true,
			button: true,
		},
		{
			name: 'Name',
			selector: row => row.NAME,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Duns #',
			selector: row => row.BA_USER_ID,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'BA ID',
			selector: row => row.BA_ID,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true

		}
	];

	// Redux
	const dispatch = useDispatch();
	const selectedBidPkg = useSelector(state => state.physical_trade_deals_slice.selectedBidPkg);

	// Property based on Bid Type.
	const property = useMemo(() => {
		return selectedBidPkg?.BIDTYPE === 'B' ? 'SUPPLIER_BAID_OBJ' : 'BUYERID_OBJ';
	}, [selectedBidPkg?.BIDTYPE]);

	const handleRowClicked = (row) => {
		console.log(row);
		if (selectedBidPkg?.[property]?.BA_ID === row?.BA_ID) {
			dispatch(physical_trade_deals_actions.setOpenSupplierModal(false));
			return;
		}

		// update property in selected bid package
		dispatch(physical_trade_deals_actions.setSelectedBidPkg({
			...selectedBidPkg,
			[property]: row,
			CNTR_NUM: {} // when counter party is changed empty contract selection
		}));
		
		// hide modal after row is finished selecting
		dispatch(physical_trade_deals_actions.setOpenSupplierModal(false));
	};

	return (
		<DataTable
			progressPending={loading}
			progressComponent={<Spinner status='loading' />}
			className='table-striped pb-1'
			fixedHeader={true}
			fixedHeaderScrollHeight="700px"
			columns={columns}
			data={counterParties}
			striped={true}
			highlightOnHover={true}
			customStyles={CustomStyles}
			onRowClicked={handleRowClicked}
			selectableRowsHighlight
			selectableRowsNoSelectAll
			dense={true}
		/>
	);
};

export default Table;