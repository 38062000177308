// import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { physical_trade_deals_actions } from '../../../store/physical-trade-deals-slice';
import Spinner from '../../Spinner';
import CustomStyles from './SSM-Table-Style';

function Table({ counterPartyContracts, loading }) {

	// Redux
	const selectedBidPkg = useSelector(state => state.physical_trade_deals_slice.selectedBidPkg);
	const dispatch = useDispatch();

	const columns = [
		{
			cell: row => <input type="checkbox" checked={row.CNTR_NUM === selectedBidPkg?.CNTR_NUM?.CNTR_NUM} onChange={() => { handleRowClicked(row) }} />,
			allowOverflow: true,
			button: true,
		},
		{
			name: 'Business Unit',
			selector: row => row.CNTR_PARTY1?.BA_ID,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Counter Party',
			selector: row => row.CNTR_PARTY2?.BA_ID,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Contract Id',
			selector: row => row.CNTR_NUM,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true

		},
		{
			name: 'Contract Title',
			selector: row => row.CNTR_TITLE,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true

		},
		{
			name: 'Status',
			selector: row => row.CNTR_STATUS,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true

		},
		{
			name: 'Eff Date',
			selector: row => row.EFFDATE,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true

		},
		{
			name: 'Term Date',
			selector: row => row.CNTR_TERM_DATE,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true

		},
		{
			name: 'Type',
			selector: row => row.CNTR_TYPE,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true

		}
	];

	const handleRowClicked = (row) => {
		if (selectedBidPkg?.CNTR_NUM?.CNTR_NUM === row?.CNTR_NUM) {
			// hide modal after row is finished selecting
			dispatch(physical_trade_deals_actions.setOpenContractModal(false));
			return;
		}
		// update CNTR_NUM in selected bid package
		dispatch(physical_trade_deals_actions.setSelectedBidPkg({
			...selectedBidPkg,
			CNTR_NUM: row
		}));
		// hide modal after row is finished selecting
		dispatch(physical_trade_deals_actions.setOpenContractModal(false));
	};

	return (
		<DataTable
			progressPending={loading}
			progressComponent={<Spinner status='loading' />}
			className='table-striped pb-1'
			fixedHeader={true}
			fixedHeaderScrollHeight="700px"
			columns={columns}
			data={counterPartyContracts}
			striped={true}
			highlightOnHover={true}
			customStyles={CustomStyles}
			onRowClicked={handleRowClicked}
			dense={true}
		/>
	);
};

export default Table;