const CustomStyles = {
    table: {
        style: {
            top: '0px',
            right: '0px',
            border: '1px solid blue',
        },
    },
    headRow: {
        style: {
            fontSize: ".8rem",
            // paddingLeft: '1px', // override the cell padding for head cells
            // paddingRight: '1px',
        },
    },

    cells: {
        style: {
            fontSize: '.8rem',
            border: '1px solid blue',
            // paddingLeft: '1px', // override the cell padding for head cells
            // paddingRight: '1px',
        }
    },
    critical: {
        style: {
            color: 'red',
        }
    },
    nonCritical: {
        style: {
            color: 'black',
        }
    }
}

export default CustomStyles