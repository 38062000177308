/*
 * Creator: Cameron
 * Date: 9/6/2023
 * Purpose: To provide helper methods to the "Restricted" component.
 * 
 */

import { cloneElement } from 'react';


// Define what permission each user roles gets.
const roles = [
	{
		title: 'EBB',
		permissions: [
			'READ-ACCESS',
			'WRITE-ACCESS',
			'RETAIL_CHOICE-ACCESS'
		]
	},
	{
		title: 'EBA',
		permissions: [
			'READ-ACCESS',
			'WRITE-ACCESS',
			'ADMIN-ACCESS'
		]
	},
	{
		title: 'ERU',
		permissions: [
			'READ-ACCESS'
		]
	},
	{
		title: 'SA',
		permissions: []
	},
	{
		title: 'GSE',
		permissions: [
			'READ-ACCESS',
			'WRITE-ACCESS',
			'GAS_SUPPLY-ACCESS'
		]
	},
	{
		title: 'EPO',
		permissions: [
			'READ-ACCESS',
			'WRITE-ACCESS',
			'POOL_OPERATOR-ACCESS'
		]
	}
];

/**
 * Disables an input, a button, etc...
 * @param {any} object
 */
export const Disable = (children) => {

	if (!children)
		return;

	if (Array.isArray(children)) {

		const newChildren = children.map(
			child => {

				return Disable(child);

			}
		);

		return newChildren;

	}
	if (children.type === 'button'
		|| children.type === 'select'
		|| children.type === 'input') {
		return cloneElement(children, {
			disabled: true
		});
	}
	if (children.type === 'div') {
		const newChildren = Disable(children.props.children);
		return cloneElement(children, {}, newChildren);
	}
	return children;

};

/**
 * Check if a list has an item.
 * @param {any} list
 * @param {any} item
 */
const Contains = (list, item) => {
	return list.indexOf(item) !== -1;
};

/**
 * Gets user permissions from user roles.
 * @param {any} userRoles
 */
const GetUserPermissions = (userRoles) => {
	let userPermissions = [];
	userRoles.forEach(
		userRole => {
			const role = roles.find(role => role.title === userRole);
			if (role) {
				role.permissions.forEach(
					permission => {
						if (!Contains(userPermissions, permission))
							userPermissions.push(permission);
					}
				);
			}
		}
	);
	return userPermissions;
};

/**
 * Checks if the user is permitted to access the material.
 * @param {any} userPermissions
 * @param {any} restrictedTo
 */
export const HasPermission = (userRoles, restrictedTo) => {

	// Do not allow read only users to access write components.
	if (Contains(userRoles, 'ERU') && Contains(restrictedTo, 'WRITE-ACCESS'))
		return false;

	// Else, check the user permissions to see if the user has permission.
	const userPermissions = GetUserPermissions(userRoles, roles);
	const hasPermission = restrictedTo.find(permission => Contains(userPermissions, permission));
	return hasPermission;

};
