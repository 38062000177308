import Selector from "../../CustomComponents/Selectors/Selector";

const InstrumentSelector = ({ value, setValue, url, valueType }) => {
	return (
		<Selector
			className='form-select form-select-sm'
			value={value}
			setValue={setValue}
			url={url}
			optionKey='CntrNum'
			optionText='ContractName'
			valueType={valueType || 'object'}
		/>
	);
};

export default InstrumentSelector;