/*
 * Utility functions for nominations/packages. (Nominations and packages are the same thing.) 
 * 
 * Date: 2/13/2023
 * Creator: Cameron
 * 
 */
import { toISOStringForDatabase, getDay, getDayStart } from './DateUtils';
import { getData } from './APIUtils';

/**
 * Updates the package path detail for a given property with a new value.
 * @param {any} prevPkgPathDtl The package path detail to update.
 * @param {any} newValue The new value.
 * @param {any} property The property to update, must be either NOM_RCV_VOL or NOM_DLV_VOL.
 * @returns {any} The updated package path detail.
 */
export const updateVolForPkgPathDtl = (prevPkgPathDtl, newValue, propertyName, fuelPercent) => {

	// calculate values
	let newRecVolume;
	let newFuel;
	let newDelVolume;

	// parse float value of string
	newValue = parseFloat(newValue);

	// if path is receive, calculate the deliver volume from the fuel percent
	if (propertyName === 'NOM_RCV_VOL') {

		newRecVolume = newValue;
		newFuel = newRecVolume * fuelPercent;
		newDelVolume = newRecVolume - newFuel;

	}
	else {

		// else it is deliver, reverse calculate the fuel and receive volume from the fuel percent
		newDelVolume = newValue;
		newRecVolume = newDelVolume / (1 - fuelPercent);
		newFuel = fuelPercent * newRecVolume;

	}

	// update package volume detail
	const newPkgVolumeDtl = {
		...prevPkgPathDtl.PackageVolumeDetail,
		NOM_RCV_VOL: newRecVolume,
		NOM_DLV_VOL: newDelVolume,
		SCH_RCV_VOL: newRecVolume,
		SCH_DLV_VOL: newDelVolume,
		FUEL: newFuel,
		FUEL_PERCENT: fuelPercent,
	};

	// update package path detail
	const newPkgPathDtl = {
		...prevPkgPathDtl,
		NOM_RCV_VOL: newRecVolume,
		NOM_DLV_VOL: newDelVolume,
		SCH_RCV_VOL: newRecVolume,
		SCH_DLV_VOL: newDelVolume,
		PackageVolumeDetail: newPkgVolumeDtl,
		Fuel: newFuel
	};

	return newPkgPathDtl;

};


export const checkIfSelected = (pkgPathDtl, selectedPkgPathDtl) => {

	const recLocMatches = selectedPkgPathDtl.RecLoc && selectedPkgPathDtl.RecLoc === pkgPathDtl.RecLoc;
	const delLocMatches = selectedPkgPathDtl.DelLoc && selectedPkgPathDtl.DelLoc === pkgPathDtl.DelLoc;

	if (recLocMatches || delLocMatches)
		return true;

	return false;

};

export const updatePkgPathDtl = (prevPkgPathDtl, newValue, propertyName, fuelPercent) => {

	if (propertyName === 'NOM_RCV_VOL' || propertyName === 'NOM_DLV_VOL') {

		const newPkgPathDtl = updateVolForPkgPathDtl(prevPkgPathDtl, newValue, propertyName, fuelPercent);

		return newPkgPathDtl;

	}

	let newPkgPathDtl = { ...prevPkgPathDtl };

	newPkgPathDtl[propertyName] = newValue;

	return newPkgPathDtl;

};

/**
	 * Creates a new blank package path detail and package volume detail
	 * for each day in the date span specified. These blank package path details
	 * have no receive or deliver location or flow direction assigned to them.
	 * They are meant to be used as templates that can be assigned those values.
	 * @param {any} start_date
	 * @param {any} end_date
	 */
const createBlankPkgPathDtls = (start_date, end_date, activity, fuelPercent, selectedContract) => {

	// Change to date object just in case.
	const endDate = getDayStart(end_date);
	const startDate = getDayStart(start_date);

	/* Number of days the new package spans, for creating package path 
		and package volume details. */
	const no_of_days = endDate.getDate() - startDate.getDate() + 1;

	let pkgPathDetails = [];
	let pkgVolDetails = [];

	for (let i = 0; i < no_of_days; i++) {

		const pkgVolumeDtl = {
			PackageVolumeDetailKey: {
				PACKAGE_NUMSTR: '',
				PATH_NUM: '',
				GASFLOW_DATE: toISOStringForDatabase(getDay(startDate, i))
			},
			PACKAGE_NUMSTR: "",
			PATH_NUM: "",
			GASFLOW_DATE: toISOStringForDatabase(getDay(startDate, i)),
			BEST_VOL_CODE: "NM",
			NOM_RCV_VOL: 0,
			NOM_DLV_VOL: 0,
			SCH_RCV_VOL: 0,
			SCH_DLV_VOL: 0,
			STATUS: "NM",
			FUEL_PERCENT: fuelPercent,
			FUEL: 0,
			NOM_PRICE: 0,
			PATH_COST: 0,
			CUM_COST: 0,
			FEE: 0,
			GASFLOW_ENDDATE: toISOStringForDatabase(endDate),
			SEQ_NUM: 1,
			UP_SHIPPER: activity?.UP_SHIPPER_DUNS,
			UP_CNTR: activity?.UP_CNTR_NUM
		};

		const pkgPathDtl = {
			PackagePathDetailKey: {
				PACKAGE_NUM: '',
				PATH_NUM: '',
				EFFDATE: toISOStringForDatabase(getDay(startDate, i))
			},
			PACKAGE_NUM: "",
			PATH_NUM: "",
			EFFDATE: toISOStringForDatabase(getDay(startDate, i)),
			ENDDATE: toISOStringForDatabase(getDay(startDate, i)),
			SEQ_NUM: 1,
			PIPELINE_ID: activity?.UP_PIPELINE_ID,
			ACTIV_NUM: activity,
			ACTIV_ALT_NUM: activity?.ACTIV_ALT_NUM,
			FUEL_PERCENT_DB: fuelPercent,
			NOM_RCV_VOL: 0,
			NOM_DLV_VOL: 0,
			SCH_RCV_VOL: 0,
			SCH_DLV_VOL: 0,
			DN_CNTR: selectedContract?.CNTR_ALT_NUM1,
			DN_RANK: '1',
			UP_CNTR: activity?.UP_CNTR_NUM,
			UP_SHIPPER_DUNS: activity?.UP_SHIPPER_DUNS,
			DN_SHIPPER_DUNS: activity?.DN_SHIPPER_DUNS,
			UP_PIPELINE_IDBAOBJ: activity?.UP_PIPELINE_IDBAOBJ,
			PackageVolumeDetail: pkgVolumeDtl,
			PackageStartDate: toISOStringForDatabase(startDate),
			PackageEndDate: toISOStringForDatabase(endDate)
		};

		pkgVolDetails.push(pkgVolumeDtl);
		pkgPathDetails.push(pkgPathDtl);

	};

	return pkgPathDetails;

};

const createPkgPath = (selectedContract, dlv_pt_num, rcv_pt_num, blankPkgPathDtls, flow_direction) => {
	const newPkgPathDtls = assignFlowInformation(rcv_pt_num, dlv_pt_num, flow_direction, blankPkgPathDtls);
	return {
		PackagePathKey: {
			PACKAGE_NUM: '',
			PATH_NUM: ''
		},
		PACKAGE_NUM: "",
		PATH_NUM: "",
		CNTR_NUM: selectedContract?.CNTR_NUM,
		DLV_PT_NUM: dlv_pt_num,
		RCV_PT_NUM: rcv_pt_num,
		DLV_POINT_ID: dlv_pt_num?.POINT_ID,
		RCV_POINT_ID: rcv_pt_num?.POINT_ID,
		SEQ_NUM: 1,
		Contract: selectedContract,
		ENT_PKG_PATH_DTL: newPkgPathDtls,
		FlowDirection: flow_direction
	};
};

const assignFlowInformation = (rcv_pt_num, dlv_pt_num, flowDirection, blankPkgPathDtls) => {
	const newPkgPathDtls = blankPkgPathDtls.map(
		blankPkgPathDtl => {
			const newPkgPathDtl = {
				...blankPkgPathDtl,
				FlowDirection: flowDirection,
				RecLoc: rcv_pt_num?.PT_NAME,
				DelLoc: dlv_pt_num?.PT_NAME
			};
			return newPkgPathDtl;
		}
	);
	return newPkgPathDtls;
};

const createPkgPaths = (activity, selectedContract, blankPkgPathDtls) => {
	// Maps through the activity's "non path activities".
	//For every non path activity, a new package path is created.
	return activity?.ENT_ACTIVITY_NONPATH?.map(
		activityNonPath => {

			const pt_dir_flow = activityNonPath?.PT_DIR_FLOW;
			const flowDirection = pt_dir_flow === 'R' ? 'Receive' : (pt_dir_flow === 'D' ? 'Deliver' : null);
			if (!flowDirection) throw new Error('Flow Direction was not defined.');

			const pointObj = activityNonPath?.PT_NUM_OBJ;
			const rcv_pt_num = flowDirection === 'Receive' ? pointObj : null;
			const dlv_pt_num = flowDirection === 'Deliver' ? pointObj : null;

			// Create a new package path with the new package path details
			// and the receive and deliver points and flow direction added.
			const newPkgPath = createPkgPath(selectedContract, dlv_pt_num, rcv_pt_num, blankPkgPathDtls, flowDirection);
			return newPkgPath;
		}
	);
};

/**
 * This was the original function used to create packages, I have moved to using "createPackage".
 * I supply that method with react-hook-form "useForm" values.
 * @param {any} param0
 */
export const createNewPackage = ({
	activity,
	buID,
	fuelPercent,
	selectedContract,
	start_date,
	end_date
}) => {
	const blankPkgPathDtls = createBlankPkgPathDtls(start_date, end_date, activity, fuelPercent, selectedContract);

	const newPkgPaths = createPkgPaths(activity, selectedContract, blankPkgPathDtls);

	const newPackage = {
		PACKAGE_NUM: "",
		CNTR_NUM: selectedContract,
		SUPPLIER_BAID: selectedContract?.CNTR_PARTY1?.BA_ID,
		INTER_SHIPPER_BAID: buID,
		STATUS: "NM",
		STARTDATE: toISOStringForDatabase(start_date),
		ENDDATE: toISOStringForDatabase(end_date),
		ENT_PKG_PATH: newPkgPaths,
		FUEL: 0,
		DAILY_COMM_FLAG: "N",
		MD_FLAG: "D",
		TT: activity.ACTIV_CODE,
		ACCT_PROCESS_FLAG: "Y",
		CYCLE: ""
	};

	// Get Package Paths.
	let newRecPkgPaths = [];
	let newDelPkgPaths = [];

	newPackage?.ENT_PKG_PATH?.forEach(pkgPath => {
		if (pkgPath?.RCV_PT_NUM)
			newRecPkgPaths.push(pkgPath);
		else if (pkgPath?.DLV_PT_NUM)
			newDelPkgPaths.push(pkgPath);
	});

	// Get Rec and Del Package Path Details.
	const newRecPkgPathDtls = newRecPkgPaths?.map(pkgPath => {
		return pkgPath?.ENT_PKG_PATH_DTL?.[0];
	});
	const newDelPkgPathDtls = newDelPkgPaths?.map(pkgPath => {
		return pkgPath?.ENT_PKG_PATH_DTL?.[0];
	});

	// Set default selected package path detail.
	const newSelectedPkgPathDtl = newRecPkgPathDtls?.[0];

	return {
		newPackage,
		newSelectedPkgPathDtl,
		newRecPkgPathDtls,
		newDelPkgPathDtls
	};
};

export const updatePackage = ({
	newValue,
	propertyName,
	prevPackage,
	selectedPkgPathDtl,
	fuelPercent
}) => {

	const newPkgPaths = prevPackage?.ENT_PKG_PATH.map(
		prevPkgPath => {

			const newPkgPathDtls = prevPkgPath.ENT_PKG_PATH_DTL.map(
				prevPkgPathDtl => {

					if (propertyName === 'NOM_DLV_VOL' || propertyName === 'NOM_RCV_VOL') {

						// If there is a selected package path detail, update only it. Else, update all of the package path details.
						if (selectedPkgPathDtl) {
							const selected = checkIfSelected(prevPkgPathDtl, selectedPkgPathDtl);

							if (!selected)
								return prevPkgPathDtl;
						}

						const newPkgPathDtl = updateVolForPkgPathDtl(prevPkgPathDtl, newValue, propertyName, fuelPercent);

						return newPkgPathDtl;

					}

					// new package volume detail
					const prevPkgVolumeDtl = prevPkgPathDtl.PackageVolumeDetail;

					let newPkgVolumeDtl = {
						...prevPkgVolumeDtl
					};

					newPkgVolumeDtl[propertyName] = newValue;

					// new package path detail
					let newPkgPathDtl = {
						...prevPkgPathDtl,
						PackageVolumeDetail: newPkgVolumeDtl
					};

					newPkgPathDtl[propertyName] = newValue;

					return newPkgPathDtl;

				}
			);

			const newPkgPath = {
				...prevPkgPath,
				ENT_PKG_PATH_DTL: newPkgPathDtls
			};

			return newPkgPath;

		}
	);

	const newPackage = {
		...prevPackage,
		ENT_PKG_PATH: newPkgPaths
	};

	return newPackage;
};

export const updateSelectedPkgPathDtl = ({ newValue, propertyName, prevSelectedPkgPathDtl, fuelPercent }) => {

	let newSelectedPkgPathDtl;

	if (propertyName === 'NOM_DLV_VOL' || propertyName === 'NOM_RCV_VOL') {
		newSelectedPkgPathDtl = updateVolForPkgPathDtl(
			prevSelectedPkgPathDtl,
			newValue,
			propertyName,
			fuelPercent);
	}
	else {

		// new volume detail
		const prevPkgVolumeDtl = prevSelectedPkgPathDtl.PackageVolumeDetail;

		let newPkgVolumeDtl = {
			...prevPkgVolumeDtl
		};

		newPkgVolumeDtl[propertyName] = newValue;

		// new path detail
		newSelectedPkgPathDtl = {
			...prevSelectedPkgPathDtl,
			PackageVolumeDetail: newPkgVolumeDtl
		};

		newSelectedPkgPathDtl[propertyName] = newValue;

	}

	return newSelectedPkgPathDtl;
};

/**
 * Dirty quick fix... need to clean up retail nominations. - Cameron
 * @param {any} selectedPkg
 * @param {any} newPackage
 */
export const mapInformation = (selectedPkg, newPackage) => {
	const prevSelectedPkg = [ ...selectedPkg ];
	const prevRecPkgPathDtl = prevSelectedPkg?.filter(pkgPathDtl => pkgPathDtl?.FlowDirection === 'Receive')?.[0];
	const prevDelPkgPathDtl = prevSelectedPkg?.filter(pkgPathDtl => pkgPathDtl?.FlowDirection === 'Deliver')?.[0];
	const updatedPkgPaths = newPackage?.ENT_PKG_PATH?.map(newPkgPath => {
		const updatedPkgPathDtls = newPkgPath?.ENT_PKG_PATH_DTL?.map(newPkgPathDtl => {
			if (newPkgPathDtl?.FlowDirection === 'Receive') {
				return {
					...newPkgPathDtl,
					UP_CNTR: prevRecPkgPathDtl?.UP_CNTR,
					NOM_DLV_VOL: prevRecPkgPathDtl?.NOM_DLV_VOL,
					NOM_RCV_VOL: prevRecPkgPathDtl?.NOM_RCV_VOL,
					SCH_DLV_VOL: prevRecPkgPathDtl?.SCH_DLV_VOL,
					SCH_RCV_VOL: prevRecPkgPathDtl?.SCH_RCV_VOL,
					PackageVolumeDetail: {
						...newPkgPathDtl?.PackageVolumeDetail,
						FUEL: prevRecPkgPathDtl?.PackageVolumeDetail?.FUEL,
						FUEL_PERCENT: prevRecPkgPathDtl?.PackageVolumeDetail?.FUEL_PERCENT,
						NOM_RCV_VOL: prevRecPkgPathDtl?.PackageVolumeDetail?.NOM_RCV_VOL,
						NOM_DLV_VOL: prevRecPkgPathDtl?.PackageVolumeDetail?.NOM_DLV_VOL,
						SCH_DLV_VOL: prevRecPkgPathDtl?.PackageVolumeDetail?.SCH_DLV_VOL,
						SCH_RCV_VOL: prevRecPkgPathDtl?.PackageVolumeDetail?.SCH_RCV_VOL,
						UP_CNTR: prevRecPkgPathDtl?.PackageVolumeDetail?.UP_CNTR
					}
				}
			}
			else if (newPkgPathDtl?.FlowDirection === 'Deliver') {
				return {
					...newPkgPathDtl,
					UP_CNTR: prevDelPkgPathDtl?.UP_CNTR,
					NOM_DLV_VOL: prevDelPkgPathDtl?.NOM_DLV_VOL,
					NOM_RCV_VOL: prevDelPkgPathDtl?.NOM_RCV_VOL,
					SCH_DLV_VOL: prevDelPkgPathDtl?.SCH_DLV_VOL,
					SCH_RCV_VOL: prevDelPkgPathDtl?.SCH_RCV_VOL,
					PackageVolumeDetail: {
						...newPkgPathDtl?.PackageVolumeDetail,
						FUEL: prevDelPkgPathDtl?.PackageVolumeDetail?.FUEL,
						FUEL_PERCENT: prevDelPkgPathDtl?.PackageVolumeDetail?.FUEL_PERCENT,
						NOM_RCV_VOL: prevDelPkgPathDtl?.PackageVolumeDetail?.NOM_RCV_VOL,
						NOM_DLV_VOL: prevDelPkgPathDtl?.PackageVolumeDetail?.NOM_DLV_VOL,
						SCH_DLV_VOL: prevDelPkgPathDtl?.PackageVolumeDetail?.SCH_DLV_VOL,
						SCH_RCV_VOL: prevDelPkgPathDtl?.PackageVolumeDetail?.SCH_RCV_VOL,
						UP_CNTR: prevDelPkgPathDtl?.PackageVolumeDetail?.UP_CNTR
					}
				}
			}
			return newPkgPathDtl;
		});
		return {
			...newPkgPath,
			ENT_PKG_PATH_DTL: updatedPkgPathDtls
		}
	});
	const updatedPackage = {
		...newPackage,
		ENT_PKG_PATH: updatedPkgPaths
	}
	const updatedSelectedPkg = updatedPkgPaths?.map(pkgPath => {
		return pkgPath?.ENT_PKG_PATH_DTL?.[0];
	});
	const updatedSelectedPkgPathDtl = updatedSelectedPkg?.filter(pkgPathDtl => pkgPathDtl?.FlowDirection === 'Receive')?.[0];

	return {
		updatedPackage,
		updatedSelectedPkg,
		updatedSelectedPkgPathDtl
	};
};

export const fetchFuelPercent = async () => {
	const url = `Rate/GetRates?rate_sch_id=Pool&pipeline_id=00000001`;
	const rates = await getData(url);
	const poolRate = rates?.find(rate => rate?.RATE_SCH_ID === 'Pool' && rate?.PIPELINE_ID === '00000001');
	const poolRateType = poolRate?.ENT_RATE_TYPES?.find(rateType => rateType?.PIPELINE_ID === '00000001' && rateType?.RATE_SCH_ID === 'Pool');
	const fuelRateDetail = poolRateType?.ENT_RATE_DETAILS?.find(rateDetail => rateDetail?.CDE === 'FU');
	const fuelPercent = fuelRateDetail?.VALUE;
	return fuelPercent;
};