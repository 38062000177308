import ReactHookFormSelector from './ReactHookFormSelector';

const BASelector = ({
	disabled,
	form,
	url,
	className,
	propertyName,
	afterSetValue,
	required
}) => {
	return (
		<ReactHookFormSelector
			disabled={disabled}
			form={form}
			valueType='object'
			className={className || "form-select form-select-sm"}
			url={url}
			propertyName={propertyName}
			optionKey='BA_ID'
			optionText='NAME'
			afterSetValue={afterSetValue}
			required={required}
		/>
	);
};

export default BASelector;