import { createSlice } from '@reduxjs/toolkit';
import { getNextMonthEnd, getNextMonthStart, toDatePickerString } from '../utils/DateUtils';
import { displayError, displaySuccess } from './notification-slice';

const initialState = {
	FinPositionFilters: {
		startDate: toDatePickerString(getNextMonthStart(new Date())),
		endDate: toDatePickerString(getNextMonthEnd(new Date())),
		portfolioCDE: '',
		counterPartyID: '',
		instrumentID: '',
		MTMDate: toDatePickerString(new Date()),
		dateFilterType: 'DELIVERY_MONTH'
	},
	FinPositionsFetchLoading: false,
	FinPositions: [],
	selectedFinPosition: null
};

const financial_position_slice = createSlice({
	name: 'financial_position_slice',
	initialState,
	reducers: {
		setFinPositionFilters(state, action) {
			state.FinPositionFilters = action.payload;
		},
		setFinPositionsFetchLoading(state, action) {
			state.FinPositionsFetchLoading = action.payload;
		},
		setFinPositions(state, action) {
			state.FinPositions = action.payload;
		},
		setSelectedFinPosition(state, action) {
			state.selectedFinPosition = action.payload;
		},
		setLatestMTMDate(state, action) {
			state.FinPositionFilters.MTMDate = action.payload;
		}
	}
});

export const getLatestMTMDate = () => {
	return async (dispatch) => {
		try {
			const response = await fetch('FuturePriceDetail/GetFuturePriceDetails?order_by=TRADE_REF_DATE:D&maxResults=1&not.FUTURE_PRICE_ID=NYMMNNG');
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}
			const futurePriceDtls = await response.json();
			const latestMTMDate = toDatePickerString(futurePriceDtls?.[0]?.TRADE_REF_DATE);
			await dispatch(financial_position_actions.setLatestMTMDate(latestMTMDate));
		}
		catch (error) {
			await dispatch(displayError(error.message));
		}
	};
};

export const fetchFinPositions = (FinPositionFilters) => {
	return async (dispatch) => {
		try {
			// Create url
			const {
				startDate,
				endDate,
				portfolioCDE,
				counterPartyID,
				instrumentID,
				MTMDate,
				dateFilterType
			} = FinPositionFilters;

			if (!startDate || !endDate) return;

			let url = `FinancialPosition/GetFinancialPositions?startdate=${startDate}&enddate=${endDate}&order_by=FIN_POSITION_NUM:D`;

			if (portfolioCDE)
				url += `&portfolio=${portfolioCDE}`;

			if (counterPartyID)
				url += `&counterPartyID=${counterPartyID}`;

			if (instrumentID)
				url += `&instrumentCntrNum=${instrumentID}`;

			if (MTMDate)
				url += `&MTMDate=${MTMDate}`;

			if (dateFilterType)
				url += `&dateFilterType=${dateFilterType}`;

			// Set loading to true
			await dispatch(financial_position_actions.setFinPositionsFetchLoading(true));

			// Handle previous abortController
			if (fetchFinPositions.abortController)
				fetchFinPositions.abortController.abort();

			// Create new abortController
			fetchFinPositions.abortController = new AbortController();
			const { signal } = fetchFinPositions.abortController;

			// Handle fetch
			const response = await fetch(url, { signal });

			// Handle server error
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			// Handle success
			const newFinPositions = await response.json();
			// console.log("newFinPositions", newFinPositions);
			await dispatch(financial_position_actions.setFinPositions(newFinPositions));
			await dispatch(financial_position_actions.setFinPositionsFetchLoading(false));
		}
		catch (error) {
			if (error.name === 'AbortError') return;

			await dispatch(displayError(error.message));
			await dispatch(financial_position_actions.setFinPositionsFetchLoading(false));
		}
	};
};

export const createFinPosition = (formData) => {
	return async (dispatch) => {
		try {
			// Handle fetch
			const response = await fetch('FinancialPosition/CreateFinancialPosition', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: JSON.stringify(formData)
			});

			// Handle server errors
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			// Handle success
			const newFinancialPosition = await response.json();
			await dispatch(financial_position_actions.setSelectedFinPosition(newFinancialPosition));
			await dispatch(displaySuccess('Financial Position created successfully!'));
		}
		catch (error) {
			await dispatch(displayError(error.message));
		}
	};
};

export const updateFinPosition = (formData) => {
	return async (dispatch) => {
		try {
			// Handle fetch
			const response = await fetch('FinancialPosition/UpdateFinancialPosition', {
				method: 'PATCH',
				headers: {
					'Content-type': 'application/json'
				},
				body: JSON.stringify(formData)
			});

			// Handle server errors
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			// Handle success
			const newFinancialPosition = await response.json();
			await dispatch(financial_position_actions.setSelectedFinPosition(newFinancialPosition));
			await dispatch(displaySuccess('Financial Position updated successfully!'));
		}
		catch (error) {
			await dispatch(displayError(error.message));
		}
	};
};

export const deleteFinPosition = (fin_position_num) => {
	return async (dispatch) => {
		try {
			const response = await fetch(`FinancialPosition/DeleteFinancialPosition?fin_position_num=${fin_position_num}`, { method: 'DELETE' });

			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			await dispatch(displaySuccess('Financial position was deleted successfully!'));
		}
		catch (error) {
			await dispatch(displayError(error.message));
		}
	};
};

export const fetchContract = (newCounterParty) => {
	return async (dispatch) => {
		try {
			// Get ba ID.
			const ba_id = newCounterParty?.BA_ID;
			if (!ba_id) return;

			// Handle abort controller.
			fetchContract.abortController?.abort();
			fetchContract.abortController = new AbortController();
			const { signal } = fetchContract.abortController;

			// Handle fetch.
			const response = await fetch(`Contract/GetContracts?cntr_party1=${ba_id}&cntr_type=FI`, { signal });
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			// Handle response.
			const contracts = await response.json();
			if (contracts?.length !== 1)
				throw new Error(`There is more than one contract of type FI for counter party ${newCounterParty.NAME}.`);
			const newContract = contracts?.[0];
			return newContract;
		}
		catch (error) {
			if (error.name === 'AbortError') return;

			await dispatch(displayError(error.message));
		}
	}
};

export const financial_position_actions = financial_position_slice.actions;
export default financial_position_slice.reducer;