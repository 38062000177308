import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import Table from "./Table";
// import custom date functions
import {
  getMonthStart,
  getDayStart,
  toDatePickerString,
  toISOStringForDatabase,
  getMonthEnd,
} from "../../utils/DateUtils";

// import custom hooks
import useBusinessSelector from "../../hooks/useBusinessSelector";
import DailyReadModal from "./DailyReadModal";

const CustomerListView = () => {
  //Searchbar input change handler
  const searchbarChangeHandler = (event) => {
    let newValue = event.target.value;
    let newCustomers = componentState.customers.filter((customer) => {
      let contains = false;
      Object.keys(customer).forEach((key) => {
        if (newValue === "") {
          contains = true;
        } else if (
          customer[key]
            .toString()
            .toLowerCase()
            .includes(newValue.toLowerCase())
        ) {
          contains = true;
        }
      });
      if (contains) {
        return customer;
      }
      return null;
    });
    setComponentState((previousState) => {
      return {
        ...previousState,
        filteredCustomers: newCustomers,
      };
    });
  };

  //Refresh Button Logic:
  /*const refreshPage = () => {
		window.location.reload(false);
	};*/

  // local state variable
  const [componentState, setComponentState] = useState({
    customers: [],
    filteredCustomers: [],
    tableLoading: false,
    totalEnergy: 0,
    selectedPoint: {
      PT_NUM: "",
      POOL_ID: "",
    },
    startDate: getMonthStart(new Date()),
    endDate: getMonthEnd(new Date()),
    selectedSupplier: {},
    points: [],
    displayUnits: "CCF/TH",
    contracts: [],
    selectedContract: {},
  });

  // business selector hook
  useBusinessSelector(setComponentState);

  // update customers any time any of the filters change
  useEffect(() => {
    // below code for cust list
    let url =
      "CustList/GetCustomerListData" +
      "?baId=" +
      componentState.selectedSupplier.BA_ID +
      "&poolId=" +
      componentState.selectedPoint +
      "&startDate=" +
      toISOStringForDatabase(componentState.startDate) +
      "&thruDate=" +
      toISOStringForDatabase(componentState.endDate) +
      "&buUnit=" +
      componentState.selectedSupplier.BUSINESS_UNIT +
      "&displayUnits=" +
      componentState.displayUnits;

    const baRoles = componentState?.selectedSupplier?.ENT_BA_ROLES?.map(role => role?.CDE);

    console.log(baRoles);

    if (baRoles?.includes("NC"))
      url += "&directCustomer=" + componentState.selectedSupplier.BA_ID;

    const getCustomerList = async () => {
      setComponentState((prevState) => {
        return {
          ...prevState,
          tableLoading: true,
        };
      });
    };
    //console.log("Fetching customers...");
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setComponentState((prevState) => {
          // Only update customers if response is different
          if (
            JSON.stringify(prevState.customers) !== JSON.stringify(response)
          ) {
            return {
              ...prevState,
              customers: response,
              filteredCustomers: response,
              tableLoading: false,
            };
          }
          // Else return prevState
          return prevState;
        });
        //console.log(response);
      });
    //console.log("Customers fetched.");
    // below code for points
    let urlForPoints =
      "CustList/PopulatePools?baId=" +
      componentState.selectedSupplier.BA_ID +
      "&buUnit=" +
      componentState.selectedSupplier.BUSINESS_UNIT +
      "&displayAllShipperPools=true";
    const getPoints = async () => {
      setComponentState((prevState) => {
        return {
          ...prevState,
          tableLoading: true,
        };
      });
    };
    fetch(urlForPoints)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        let key = Object.keys(response)[0];
        let value = response[key];
        setComponentState((prevState) => {
          // Only update selectedPoint if response is different
          if (
            JSON.stringify(prevState.selectedPoint) !== JSON.stringify(response)
          ) {
            return {
              ...prevState,
              selectedPoint: {
                PT_NUM: key,
                POOL_ID: value,
              },
              tableLoading: false,
            };
          }
          // Else return prevState
          return prevState;
        });
        //console.log("points", response);
      });
    getPoints();
    getCustomerList();
  }, [
    componentState.selectedSupplier,
    componentState.startDate,
    componentState.endDate,
    componentState.selectedSupplier.BA_ID,
    componentState.selectedSupplier.BUSINESS_UNIT,
    componentState.displayUnits,
  ]);

  // handlers
  const pointChange = (event) => {
    const newSelectedPointID = event.target.value;
    const newSelectedPoint = componentState.points.find(
      (point) => point.PT_NUM === newSelectedPointID
    );
    setComponentState((prevState) => {
      return {
        ...prevState,
        selectedPoint: newSelectedPoint,
      };
    });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="text-black">
          <div className="card mt-1">
            <div className="card-header text-center">
              <h4>Customer List View</h4>
            </div>
            <div className="card-body">
              {/* // BUTTONS */}
              {/*<div className="row">*/}
              {/*	<div className="col-sm-12 col-md-12 col-lg-12 text-start p-1">*/}
              {/*		 <button className='btn btn-sm btn-primary m-1' data-bs-toggle="modal" data-bs-target="#AddCustomer"><img src={add} alt='Add Customer' /> Add Customer</button> */}
              {/*		<button className='btn btn-sm btn-primary m-1' data-bs-toggle="modal" data-bs-target="#AdcqCustModal"><img src={nomDtl} alt='ADCQ Report' /> ADCQ</button>*/}
              {/*		<button className='btn btn-sm btn-primary m-1' data-bs-toggle="modal" data-bs-target="#CustomerDailyUsageModal"><img src={dailyUsg} alt='Customer Daily Usage Report' /> Customer Daily Usage</button>*/}
              {/*		<button className='btn btn-sm btn-primary m-1' data-bs-toggle="modal" data-bs-target="#SummaryCustomer"><img src={custSum} alt='Customer Report Summary' /> Customer Report Summary</button>*/}
              {/*		<button*/}
              {/*			className="btn btn-primary btn-sm m-1" onClick={refreshPage}>*/}
              {/*			<img*/}
              {/*				src={refresh}*/}
              {/*				alt="Refresh"*/}
              {/*			/> Refresh*/}
              {/*		</button>*/}
              {/*	</div>*/}
              {/*</div>*/}

              {/* // FILTERS */}
              <div className="row mb-3 bg-light-blue rounded p-2 rounded">
                <h6>Display Filters</h6>

                {/* // Pool ID */}
                <div className="col-lg-2 col-md-2 col-sm-12 text-start">
                  <label className="form-label pe-2">Pool ID</label>
                  <input
                    className="form-control form-control-sm"
                    defaultValue={componentState.selectedPoint.POOL_ID}
                    onChange={pointChange}
                    disabled
                  ></input>
                </div>

                {/* // Start Date */}
                <div className="col-lg-2 col-md-2 col-sm-12 text-start">
                  <label className="form-label ps-4 pe-2">Start Date</label>
                  <input
                    className="form-control form-control-sm"
                    type="date"
                    value={toDatePickerString(componentState.startDate)}
                    onChange={(event) => {
                      setComponentState((prevState) => {
                        return {
                          ...prevState,
                          startDate: getDayStart(event.target.value),
                          endDate: getDayStart(getMonthEnd(event.target.value)),
                        };
                      });
                    }}
                    style={{ maxWidth: "180px" }}
                  />
                </div>

                {/* // End Date */}
                <div className="col-lg-2 col-md-2 col-sm-12 text-start">
                  <label className="form-label">Thru Date</label>
                  <input
                    className="form-control form-control-sm"
                    type="date"
                    value={toDatePickerString(componentState.endDate)}
                    onChange={(event) => {
                      setComponentState((prevState) => {
                        return {
                          ...prevState,
                          endDate: getDayStart(event.target.value),
                        };
                      });
                    }}
                    style={{ maxWidth: "180px" }}
                  />
                </div>

                {/* // Customer Count */}
                <div className="col-lg-2 col-md-2 col-sm-12 text-start">
                  <label className="form-label">Customer Count</label>
                  <select
                    className="form-select form-select-sm"
                    aria-label="select public or private"
                    style={{ maxWidth: "150px" }}
                  >
                    <option>{componentState.customers.length}</option>
                  </select>
                </div>

                {/* // Display Units */}
                <div className="col-lg-2 col-md-2 col-sm-12 text-start">
                  <label className="form-label">Display Units</label>
                  <select
                    className="form-select form-select-sm mb-2"
                    aria-label="select public or private"
                    style={{ maxWidth: "150px" }}
                    value={componentState.displayUnits}
                    onChange={(event) => {
                      setComponentState((prevState) => {
                        return {
                          ...prevState,
                          displayUnits: event.target.value,
                        };
                      });
                    }}
                  >
                    <option>CCF/TH</option>
                    <option>MCF/DTH</option>
                  </select>
                </div>

                {/* Search Bar */}
                <div className="col-lg-2 col-md-2 col-sm-12 text-start">
                  <label className="form-label">Search</label>
                  <input
                    className="form-control form-control-sm"
                    type="text"
                    placeholder="Search by city..."
                    aria-label="Search"
                    onChange={searchbarChangeHandler}
                  />
                </div>
              </div>
              {/* // CUSTOMER LIST TABLE */}
              <div className="bg-light-blue rounded">
                <Table componentState={componentState} />
              </div>
            </div>
          </div>
        </div>
        <DailyReadModal componentState={componentState} />
      </div>

      {/* // MODALS */}
      {/*<div>
			//	<CustAdcq
			//		componentState={componentState}
			//		setComponentState={setComponentState}
			//	/>
			//	<CustomerDailyUsage
			//		componentState={componentState}
			//		setComponentState={setComponentState}
			//	/>
			//	<SummaryCustomerDailyUsage />
			//	<DailyRead />
			//</div>*/}
    </>
  );
};

export default CustomerListView;
