import "./Styles/Summary.css";

function Summary({ month, cntr_num, gasAllocation, poolBalanceData }) {
    const curMonthGasAllocation = gasAllocation?.filter( e => {
        return (e.cntr_num === cntr_num)
    });

    const poolsBalance = poolBalanceData.filter( e => {
        return (e.CNTR_NUM === cntr_num)
    })

    const accountMonth = month.toLocaleDateString('en-GB', { month: 'long', year: 'numeric' });
    
    const curMonthTotalReceive = curMonthGasAllocation.find(e => { return (e.isTotalRow === true && e.isReceipt === true && e.isFuel === false)})?.alloc;
    const curMonthTotalFuel = curMonthGasAllocation.find(e => { return (e.isTotalRow === true && e.isReceipt === true && e.isFuel === true)})?.alloc;
    const curMonthTotalDelivery = curMonthGasAllocation.find(e => { return (e.isTotalRow === true && e.isReceipt === false)})?.alloc;
    const curMonthImbalances = (curMonthTotalReceive ? curMonthTotalReceive : 0)
                                - (curMonthTotalFuel ? curMonthTotalFuel : 0)
                                - (curMonthTotalDelivery ? curMonthTotalDelivery : 0);
    const beginningImbalance = Array.isArray(poolsBalance) && poolsBalance.length > 0 && poolsBalance[0].BEG_VALUE != null ? poolsBalance[0].BEG_VALUE : 0;
    const ppa = Array.isArray(poolsBalance) && poolsBalance.length > 0 && poolsBalance[0].DeltaPPAQty != null ? poolsBalance[0].DeltaPPAQty : 0;
    const adjustments = Array.isArray(poolsBalance) && poolsBalance.length > 0 && poolsBalance[0].ADJ_QTY != null ? poolsBalance[0].ADJ_QTY : 0;
    const netEndingImbalance = curMonthImbalances + beginningImbalance + adjustments + ppa;

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="circleChoiceFrontWidth">&nbsp;</div>
                    <div className="circleChoice">Circle One</div>
                </div>
                
                <div className="row">
                    <div className="subject">Month Imbalances for {accountMonth}</div>
                    <div className="quantity">{Math.round(curMonthImbalances).toLocaleString()}</div>
                    <div className="agreeCol">Agree</div>
                    <div className="disagreeCol">Disagree</div>
                </div>
                <div className="row">
                    <div className="subject">Beginning Imbalance</div>
                    <div className="quantity">{Math.round(beginningImbalance).toLocaleString()}</div>
                </div>
                <div className="row">
                    <div className="subject">Adjustments</div>
                    <div className="quantity">{Math.round(adjustments).toLocaleString()}</div>
                </div>
                <div className="row">
                    <div className="subject">PPA</div>
                    <div className="quantity">{Math.round(ppa).toLocaleString()}</div>
                </div>
                <div className="row largeHeight">
                    <div className="subject">Net Ending Imbalance</div>
                    <div className="quantity">{Math.round(netEndingImbalance).toLocaleString()}</div>
                    <div className="agreeCol">Agree</div>
                    <div className="disagreeCol">Disagree</div>
                </div>
            </div>
                
        </>
    )
}

export default Summary;