import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchBusinessUnit } from "../../store/business-selector-slice";

const BusinessUnitSelect = () => {
	// Redux
	const businessUnit = useSelector(state => state.business_selector_slice.businessUnit);
	const dispatch = useDispatch();

	// State
	const [loading, setLoading] = useState(false);

	// API fetch
	useEffect(() => {
		const handleAsync = async () => {
			setLoading(true);
			await dispatch(fetchBusinessUnit());
			setLoading(false);
		};
		handleAsync();
	}, [dispatch]);

	return (
		<label className='ms-1 me-1'>
			Business Unit:
			<select
				disabled
				className="form-select form-select-sm"
				type="form-select"
				style={{ maxWidth: "200px" }}
			>
				{
					loading ?
						<option>...Loading</option>
						:
						<option>{businessUnit?.NAME}</option>
				}
			</select>
		</label>
	);
};

export default BusinessUnitSelect;