import React, {useEffect, useState } from "react";
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import generatePDF, { Resolution, Margin } from "react-to-pdf";

import "./Styles/ReportViewer.css";
import BusinessInfo from "./Sections/BusinessInfo"
import DataView from "./Sections/DataView";
import Summary from "./Sections/Summary";
import Signature from "./Sections/Signature";
import PostScript from "./Sections/PostScript";
import FootNote from "./Sections/Footnote";

import Spinner2 from '../../../CustomComponents/Spinner2';
import { measurement_actions } from "../../../../store/measurement-slice";

const customStyles = {
	overlay: {
		backgroundColor: 'rgb(0,0,0,.60)'
	},
};

function ReportViewer({month, contracts, ba, bu, gasAllocationData, poolBalanceData, user}) {    
    const status = useSelector(state => state.measurement.status);
    const reportViewerOpen = useSelector(state => state.measurement.reportViewerOpen);
    const dispatch = useDispatch();
    const closeReportViewer = () => {
        dispatch(measurement_actions.setOpenReportViewer(false))
    }
    const [selectedContract, setSelectedContract] = useState(contracts)

    useEffect(() => {
        if (contracts.length > 0) {
            setSelectedContract(contracts[0])
        }
    }, [contracts])

    const selectedContractChange = (event) => {
        if (!event.target.value) {
			return;
		}
        const cntr_alt_num1 = event.target.value
        const selectedContract = contracts.find(c => c.CNTR_ALT_NUM1 === cntr_alt_num1)
        setSelectedContract(selectedContract)
    }

    const options = {
        filename: `AgreementBalanceReport.pdf`,
        method: "save",
        resolution: Resolution.MEDIUM,
        page: {
            margin: Margin.SMALL,
            format: "A4",
            orientation: "portrait"
        },
        options: {
            FitWindow: 'true'
        },
        canvas: {
            mimeType: "image/jpeg",
            qualityRatio: 1
        },
        overrides: {
            pdf: {
                compress: true
            },
            canvas: {
                useCORS: true
            }
        }
    };
    
    const getTargetElement = () => document.getElementById("report");
    const downloadPdf = () => generatePDF(getTargetElement, options);    
    
    const ContractSelection = (contracts) => {
        return (
            <div className="row text-start bg-light-blue rounded m-0 p-2">
                <div>Contract No:</div>
                <div>
                    <select
                        className="form-select form-select-sm"
                        type="form-select"
                        style={{ maxWidth: "300px" }}
                        onChange={selectedContractChange}
                        value={selectedContract.CNTR_ALT_NUM1}
                    >
                        {
                            status === 'loading' ? <option>...Loading</option> :
                            <>
                                <option value="">--Select a Contract--</option>
                                {
                                    contracts?.map(contract => {
                                            return (
                                                <option
                                                    key={contract.CNTR_ALT_NUM1}
                                                    value={contract.CNTR_ALT_NUM1}
                                                >
                                                    {contract.CNTR_ALT_NUM1}
                                                </option>
                                            );
                                        }
                                    )
                                }
                            </>
                        }
                    </select>
                </div>
            </div>
        )
    }

    const ReportPreview = ({month, contract, ba, bu, gasAllocationData, poolBalanceData}) => {
        if (JSON.stringify(gasAllocationData) === "{}" || JSON.stringify(poolBalanceData) === "{}") return (<></>);

        return (<>
            <BusinessInfo 
                month={month}
                contract={contract}
                ba={ba}
                bu={bu}
            />
            <DataView isReceipt={true} cntr_num={contract?.CNTR_NUM} subject={"Receipts:"} gasAllocationData={gasAllocationData}/>
            <DataView isReceipt={false} cntr_num={contract?.CNTR_NUM} subject={"Deliveries:"} gasAllocationData={gasAllocationData}/>
            <Summary 
                month={month}
                cntr_num={contract?.CNTR_NUM}
                gasAllocation={gasAllocationData}
                poolBalanceData={poolBalanceData}
            />
            <Signature/>
            <PostScript/>
            <FootNote user={user}/>
        </>)
    };
    
    return (
        <>
            <Modal
                isOpen={reportViewerOpen}
                onRequestClose={closeReportViewer}
                className="report-viewer-modal container-fluid bg-white text-black"
                style={customStyles}
            >
                <div id="ReportViewerModal" tabIndex='-1'>
                    <div className='modal-dialog '>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title mb-2">Report Viewer UI</h4>
                                <button
                                    type="button"
                                    className="btn-close fs-6"
                                    onClick={closeReportViewer}
                                />
                            </div>
                            <div className="modal-toolbar">
                                <button className="btn btn-md btn-primary me-2 mb-1" onClick={downloadPdf}>Save PDF</button>
                                {ContractSelection(contracts)}
                            </div>
                            <div className="modal-body">
                                <div id="report">
                                    {
                                        status === 'loading' ? <Spinner2 /> : ReportPreview({
                                            month: month,
                                            contract: selectedContract,
                                            ba: ba,
                                            bu: bu,
                                            gasAllocationData: gasAllocationData,
                                            poolBalanceData: poolBalanceData
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ReportViewer;