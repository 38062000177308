import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { determineCycle } from '../store/non-path-noms-slice';

/**
 * React Hook Form nom cycle update based on package "STARTDATE" property.
 * @param {any} form
 */
const useNomCycleUpdateRHF = (form) => {
	// Redux
	const dispatch = useDispatch();

	// React Hook Form
	const { watch, setValue, formState } = form;
	const { isSubmitted } = formState;
	const startdateWatch = watch('STARTDATE');

	// Update cycle based on package start date.
	useEffect(() => {
		const handleAsync = async () => {
			const nomCycle = await dispatch(determineCycle(startdateWatch));
			setValue('CYCLE', nomCycle, { shouldValidate: isSubmitted, shouldTouch: true, shouldDirty: true });
		};
		handleAsync();
	}, [startdateWatch, setValue, dispatch, isSubmitted]);
};

export default useNomCycleUpdateRHF;