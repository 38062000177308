import { useCallback } from "react";
import { Controller } from "react-hook-form";
import useCreateOptions from "../Selectors/useCreateOptions";

const ReactHookFormSelector = ({
	disabled, // Disables the selector
	className, // Class name for selector input
	form, // React hook form "form" property
	valueType, // Lets the Selector component know how to set the value.
	url, // Url for API fetch
	propertyName, // Name of the property that this selector tracks
	optionKey, // Name of the ID or key for the object
	optionText, // Property of the object that you wish to be displayed for each option
	shouldLog,
	afterSetValue, // Function that runs after the value is changed.
	required, // Is the field required?
}) => {

	if (!propertyName)
		throw new Error('Please provide a property name.');
	if (!form)
		throw new Error(`Please provide an instance of useForm to the ReactHookFormSelector. Property name: ${propertyName}.`);
	if (!valueType)
		throw new Error('Please provide a value type to ReactHookFormSelector. Ex: "object" or "string".');
	if (valueType !== 'object' && valueType !== 'string')
		throw new Error(`Value type ${valueType} has no implementation.`);
	if (propertyName?.includes('.')) {
		throw new Error(`Please do not use nested properties while using ReactHookFormSelector.`
			+ `This creates issues with resetting values. Property name: ${propertyName}.`);
	}
	if (!url) throw new Error('Please provide a url to the Selector component.');

	// Hook for options creation.
	const { options, data, loading } = useCreateOptions({ url, optionKey, optionText });

	// React Hook Form.
	const { formState, watch, control } = form;
	const { errors } = formState;

	// React Hook Form Watch.
	const value = watch(propertyName);

	if (value && valueType !== typeof (value)) {
		throw new Error('\nSupplied value was not of the same type as supplied value type.\n\n' +
			`Supplied value type: ${valueType}\nReal value type: ${typeof (value)}\n\n`);
	}

	// Custom onChange event handler.
	const changeHandler = (event, onChange) => {
		const newValueID = event.target.value
		const newValue = data?.find(item => item[optionKey] === newValueID);

		onChange(valueType === 'object' ? newValue : newValueID);

		// Run "afterSetValue" function.
		if (afterSetValue && typeof (afterSetValue) === 'function')
			afterSetValue(newValue);
	};

	// Used for validating selector input.
	const validate = useCallback((value) => {
		// If not required, validation is not necessary.
		if (!required)
			return true;

		if (valueType === 'object')
			return value && Object.keys(value)?.length > 0;

		// Else, assume value type is string.
		return value;
	}, [valueType, required]);

	// If loading, return spinner.
	if (loading)
		return <select disabled={disabled} className={className} defaultValue=''><option>Loading...</option></select>;

	// Else return React Hook Form selector.
	return (
		<Controller
			control={control}
			name={propertyName}
			rules={{validate}}
			disabled={disabled}
			render={({ field: { onChange, onBlur, value, ref } }) => {
				if (shouldLog) {
					console.log('Property name: ', propertyName);
					console.log('New rendered value: ', value);
				}
				return (
					<select
						ref={ref}
						onBlur={onBlur}
						value={(valueType === 'object' ? value?.[optionKey] : value) || ''}
						onChange={(event) => changeHandler(event, onChange)}
						className={`${className} ${errors?.[propertyName] && 'is-invalid'}`} // Add error styling
					>
						{options}
					</select>
				);
			}}
		/>
	);
};

export default ReactHookFormSelector;