import { createAsyncThunk } from '@reduxjs/toolkit';
import { poolBalanceActions } from './pool-balance-slice';
import { isDateType, isValidDateString, getMonthEnd } from '../utils/DateUtils';

// In your business-selector-slice.js file
export const selectGasFlowDate = (state) => state.business_selector_slice.gasFlowDate;
export const selectBaRole = (state) => state.business_selector_slice.baRole;
export const selectSelectedSupplier = (state) => state.business_selector_slice.selectedSupplier;
export const selectBARoles = (state) => state.business_selector_slice.baRoles;
export const selectBaId = (state) => state.business_selector_slice.baRole;

//Fetch everything from storage
export const getPoolBalance = createAsyncThunk(
    "PoolBalance/GetPoolBalance",
    async ({ ba_id, month }, thunkAPI) => {
        let monthStr = isDateType(month) ? month.toISOString() : isValidDateString(month) ? month : getMonthEnd(new Date()).toISOString();
        
        thunkAPI.dispatch(poolBalanceActions.resetPoolBalanceData());

        const response = await fetch(`PoolBalance/GetPoolBalance?baId=${ba_id}&month=${monthStr}`);
        if (!response.ok) {
            throw new Error("Error fetching pool balance data");
        }
        const poolData = await response.json();
        return poolData;
    }
);


