import { useState } from 'react';

export const useDateFilter = (poolBalanceData) => {
    //Helper function to get first day of a month
    const getFirstDayOfMonth = (date) => {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        return firstDay.toISOString().substr(0, 10);
    }

    // Helper function to get the last day of a month
    const getLastDayOfMonth = (date) => {
        const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1); // First day of the next month
        nextMonth.setDate(0); // Subtract one day to get the last day of the desired month
        return nextMonth.toISOString().substr(0, 10);
    };

    // Date picker state for Period From and Period To:
    const [startDate, setStartDate] = useState(getFirstDayOfMonth(new Date()));
    const [endDate, setEndDate] = useState(getLastDayOfMonth(new Date()));

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);

        const selectedDate = new Date(e.target.value + 'T00:00:00'); // Adjusted for potential timezone issues
        setEndDate(getLastDayOfMonth(selectedDate));
    };

    // Function to handle change of 'Period To' date:
    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    /**
     * currently, no filter needed.
     * filtered by date is not suitable for this type of data
     * the backend should handle the generation of data even when there is no data in the database
     * It means table will always have at least one record
     * 
     * keep filter variable for future extension
     */
    const filteredPoolBalanceData = poolBalanceData

    return {
        startDate,
        endDate,
        handleStartDateChange,
        handleEndDateChange,
        filteredPoolBalanceData
    };
};
