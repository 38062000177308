import axios from 'axios';
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';



// Fetch all of the Hedge Contracts:
export const fetchAllHedgeContracts = createAsyncThunk(
    'Hedging/RetrieveAllHedgeContracts',
    async () => {
        const response = await fetch(`Hedging/RetrieveAllHedgeContracts`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const contractsData = await response.json();
        // console.log('HedgeContracts from Thunk:', contractsData);
        return contractsData;
    }
);

// AsyncThunk for POST request
export const createHedgingContract = createAsyncThunk(
    'Hedging/CreateContract',
    async (newContract, { rejectWithValue }) => {
        const url = 'Hedging/CreateContract';
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newContract),
            });

            if (!response.ok) {
                throw new Error(`Failed to create the contract. Status: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const deleteByCntrNum = createAsyncThunk(
    'Hedging/DeleteByCntrNum',
    async (cntrNum, { rejectWithValue }) => {
        try {
            const response = await fetch(`/Hedging/DeleteByCntrNum?cntrNum=${cntrNum}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                throw new Error(`Failed to delete the contract with CntrNum ${cntrNum}. Status: ${response.status}`);
            }

            return cntrNum;  // This will be passed as action.payload in extraReducers
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


// AsyncThunk for PUT request
export const updateHedgingContract = createAsyncThunk(
    'Hedging/UpdateContract',
    async (updatedContract, { rejectWithValue }) => {
        const url = `Hedging/UpdateContract?cntrNum=${updatedContract.CntrNum}`;
        try {
            const response = await fetch(url, {
                method: 'PUT', // Changed to PUT
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedContract),
            });

            if (!response.ok) {
                throw new Error(`Failed to update the contract. Status: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


//Section for getting codes from the ENT_CODES SQL table:

// export const fetchAllCodes = createAsyncThunk(
//     'Hedging/RetrieveAllCodes',
//     async () => {
//         const response = await fetch(`Hedging/RetrieveAllCodes`);
//         if (!response.ok) {
//             throw new Error('Network response was not ok');
//         }
//         const codesData = await response.json();
//         console.log('Code data from fetchAllCodes thunk:', codesData);
//         return codesData;
//     }
// );

export const fetchAllCodes = createAsyncThunk(
    'Hedging/RetrieveAllCodes',
    async () => {
        const response = await axios.get('Hedging/RetrieveAllCodes');
        const data = response.data;

        // Organizing data by CDE_TYPE
        const organizedData = data.reduce((acc, item) => {
            const type = item.CDE_TYPE;
            if (!acc[type]) {
                acc[type] = [];
            }
            acc[type].push(item);
            return acc;
        }, {});

        return organizedData;
    }
);



const initialState = {
    codesData: [],
    hedgeContracts: [],
    contracts: [],
    newContract: {
        CntrNum: "00000001", // Mapped from CNTR_NUM
        ContractName: "", // Mapped from CNTR_NAME
        Description: "", // Mapped from DESCRIPTION
        ContractType: "", // Mapped from CNTR_TYPE
        Exchange: '', // Mapped from EXCHANGE
        PriceExpression: '', // Mapped from CPRICE_EXPR
        Asset1: "", // Mapped from ASSET1
        Location1: '', // Mapped from LOCATION1
        UnitsAmount1: 10000, // Mapped from UNITS_AMOUNT1
        Units1: '', // Mapped from UNITS1
        Asset1ForwardPriceExpression: "", // Mapped from AL1_FPRICE_EXPR
        Asset1SpotPriceExpression: '', // Mapped from AL1_SPRICE_EXPR
        Asset2: "", // Mapped from ASSET2
        Location2: '', // Mapped from LOCATION2
        UnitsAmount2: 0, // Mapped from UNITS_AMOUNT2
        Units2: '', // Mapped from UNITS2
        Asset2ForwardPriceExpression: '', // Mapped from AL2_FPRICE_EXPR
        Asset2SpotPriceExpression: '', // Mapped from AL2_SPRICE_EXPR
        DateStamp: new Date().toISOString(), // Mapped from DATESTAMP
        UserStamp: '', // Mapped from USERSTAMP
        Status: '', // Mapped from STATUS

    },
    filteredContracts: [],
    filters: {
        ContractType: '',
        Asset1: '',
        Location1: '',
        PriceExpression: '',
        CntrNum: '',
        ContractName: '',
        Description: '',
        Asset1ForwardPriceExpression: "",
        Status: '',

        // ...other filters
    },
    modalStates: {
        newContractModal: {
            loading: false,
            open: false,
        },
    }
};

export const updateNewContract = createAction(
    'hedging/updateNewContract',
    (update) => {
        // console.log("Inside updateNewContract, payload is:", update);
        return {
            payload: update,
        };
    }
);

export const updateFilter = createAction(
    'hedging/updateFilter',
    (update) => {
        return {
            payload: update,
        };
    }
);

// ...

// Add this selector to get filtered contracts
export const selectFilteredContracts = (state) => {
    const filters = state.hedging.filters;
    return state.hedging.contracts.filter((contract) => {
        return Object.keys(filters).every((key) => {
            return String(contract[key]).includes(filters[key]);
        });
    });
};


const hedgingSlice = createSlice({
    name: 'hedging',
    initialState,
    reducers: {
        setSelectedContract: (state, action) => {
            state.selectedContract = action.payload;
        },
        setHedgeCodes: (state, action) => {
            state.hedgeCodes = action.payload;
        },
        setTableData: (state, action) => {
            state.tableData = action.payload;
        },
        setSelectedContractType: (state, action) => {
            state.selectedContractType = action.payload;
        },
        setSelectedAsset: (state, action) => {
            console.log('Setting asset', action.payload);
            state.selectedAsset = action.payload;
        },
        setAvailableCDEs: (state, action) => {
            state.availableCDEs = action.payload;
        },
        setFilteredHedgeCodes: (state, action) => {
            state.filteredHedgeCodes = action.payload;
        },
        setFullFilteredData: (state, action) => {
            state.fullFilteredData = action.payload;
        },

        setCreatingContractField: (state, action) => {
            const { field, value } = action.payload;
            state.creatingContract[field] = value;
        },

        updateNewContract: (state, action) => {
            // console.log('Payload received in reducer:', action.payload);
            Object.keys(action.payload).forEach(fieldName => {
                state.newContract[fieldName] = action.payload[fieldName];
            });
        },


        setContractName: (state, action) => {
            if (state.contracts) {
                const contract = state.contracts.find((contract) => contract.CntrNum === action.payload.CntrNum);
                if (contract) {
                    contract.CntrName = action.payload.CntrName;
                }
            }
        },
        resetNewContract: (state) => {
            state.newContract = initialState.newContract;
        },
        openNewContractModal: (state) => {
            state.modalStates.newContractModal.open = true;
        },
        closeNewContractModal: (state) => {
            state.modalStates.newContractModal.open = false;
        },
        resetFilters: (state) => {
            state.filters = initialState.filters;
        },
        setFilter: (state, action) => {
            const { field, value } = action.payload;
            state.filters[field] = value;
        }

    },


    extraReducers: {

        [fetchAllHedgeContracts.pending]: (state) => {
            state.status = 'loading';
        },
        [fetchAllHedgeContracts.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.contracts = action.payload;
        },
        [createHedgingContract.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.contracts.push(action.payload);
        },
        [createHedgingContract.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        [deleteByCntrNum.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            // Remove the deleted contract from the state
            state.contracts = state.contracts.filter(contract => contract.CntrNum !== action.payload);
        },
        [deleteByCntrNum.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        [updateHedgingContract.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            // Update the contract within state.contracts
            const index = state.contracts.findIndex(contract => contract.CntrNum === action.payload.CntrNum);
            if (index !== -1) {
                state.contracts[index] = action.payload;
            }
        },
        [updateHedgingContract.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        //Codes from code table:
        [fetchAllCodes.pending]: (state) => {
            state.status = 'loading';
        },
        [fetchAllCodes.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.codesData = action.payload;
        },
        [fetchAllCodes.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
    },
});

// Selectors in hedgingSlice.js or another file where you keep selectors

export const selectUniqueCDETypes = (state) => state.hedging.uniqueCDETypes;


export const {
    setSelectedContract, setHedgeCodes, setTableData, setSelectedContractType,
    setSelectedAsset, setAvailableCDEs, setFilteredHedgeCodes, setFullFilteredData, setContractTitle, setComments, setDefaultPvol, setSortBy, setRuleCDE, setModule, setCode, setUserstamp, setDatestamp, setCreatingContractCDEType, setCreatingContractField,
    updateContractName, setContractName, resetNewContract, openNewContractModal,
    closeNewContractModal, resetFilters,
} = hedgingSlice.actions;

export default hedgingSlice.reducer;
