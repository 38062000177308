import { useEffect, useState } from 'react';
import { notification_actions } from '../../../store/notification-slice';

//Image imports
import Clear from '../../../assets/images/clear.png';
import Cancel from '../../../assets/images/cancel.png';


//Import Modules:
import Table from './Table';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { physical_trade_deals_actions } from '../../../store/physical-trade-deals-slice';

import './SelectChargeTypeModalStyling.css';
import SearchBar from '../../CustomComponents/SearchBar';

// Modal styling
const customStyles = {
	overlay: {
		backgroundColor: 'rgb(0,0,0,.60'
	},
};

function SelectChargeType() {

	// Redux:
	const openChargeTypeModal = useSelector(state => state.physical_trade_deals_slice.openChargeTypeModal);
	const dispatch = useDispatch();

	// State:
	const [loading, setLoading] = useState();
	const [chargeTypes, setChargeTypes] = useState();
	const [filteredData, setFilteredData] = useState();

	useEffect(() => {
		const handleAsync = async () => {
			const requestData = async () => {
				const response = await fetch('GeneralData/GetCodes?cde_type=CREF&sort_by=0');
				if (!response.ok) {
					const message = await response.text();
					throw new Error(message);
				}
				return await response.json();
			};
			try {
				setLoading(true);
				const data = await requestData();
				setChargeTypes(data);
			}
			catch (error) {
				dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
			}
			finally {
				setLoading(false);
			}
		};
		handleAsync();
	}, [dispatch]);

	// Modal State:
	const closeChargeTypeModal = () => {
		dispatch(physical_trade_deals_actions.setOpenChargeTypeModal(false));
	};

	return (
		<>
			<Modal
				isOpen={openChargeTypeModal}
				onRequestClose={closeChargeTypeModal}
				className="select-charge-type-modal container-fluid bg-white text-black"
				style={customStyles}
			>
				<div id="SelectChargeTypeModal" tabIndex='-1'>
					<div className='modal-dialog '>
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title mb-2">Select Charge Type</h4>
								<button
									type="button"
									className="btn-close fs-6"
									onClick={closeChargeTypeModal}
								>
								</button>
							</div>
							<div className="modal-body">

								{/* // BUTTONS */}
								<div className="row text-start bg-light-blue rounded-top m-0 p-2">
									<div className="col-lg-3 col-md-12 col-md-12 col-sm-12">
										<button
											type="button"
											className="btn btn-primary m-1"
											title="New Deal"
											onClick={closeChargeTypeModal}
										>
											<img src={Clear} alt="Clear" /> Clear
										</button>
										<button
											type="button"
											className="btn btn-primary m-1"
											title="Cancel"
											onClick={closeChargeTypeModal}
										>
											<img src={Cancel} alt="AddImage" /> Cancel
										</button>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-12">
										{/* Search Bar */}
										<SearchBar
											data={chargeTypes}
											setFilteredData={setFilteredData}
										/>
									</div>
								</div>
								{/* SELECT A DEAL TABLE */}
								<div className='row text-start'>
									<Table
										chargeTypes={filteredData}
										loading={loading}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default SelectChargeType