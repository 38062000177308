
const CustomStyles = {
	table: {
		style: {
			position: 'sticky',
			top: '0px',
			width: "100%",
		},
	},
	headRow: {
		style: {
			position: 'sticky',
			top: '0px',
			fontSize: ".9rem",
			paddingLeft: '0px',
		},
	},
	cells: {
		style: {
			fontSize: '.9rem',

			paddingLeft: '1px'
		}
	}
}

export default CustomStyles