import { useRef } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';

import cancel from '../../../assets/images/cancel.png';
import excel from '../../../assets/images/excel.gif'

function MarketerSupplierImbalance() {

    // tableRef for Excel Export:
    const tableRef = useRef(null);

    //Handling the Excel Download:
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Imbalance-Trades-Report',
        sheet: 'Imbalance-Trades-Report'
    });

    return (
        <div className="modal modal-xl " id="MarketerSupplierImbalance" aria-hidden="true" aria-labelledby="MarketerSupplierImbalance Report" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content text-center">
                    <div className="modal-header text-center bg-primary text-white justify-content-center">
                        <h1 className="fs-5" id="MarketerSupplierImbalance">Market/Supplier Imbalance Position</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="row text-start">
                        <div className="col-sm-2 col-md-2 col-lg-2 p-2">
                            <button className='btn btn-md btn-primary m-1' onClick={onDownload}><img src={excel} alt='Excel' /> Excel Export</button>
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2 p-2">
                            <button className='btn btn-md btn-primary m-1' data-bs-dismiss="modal" aria-label="Close"><img src={cancel} alt='Cancel' /> Cancel</button>
                        </div>
                    </div>
                    {/* Filter Section */}
                    <div className="container-fluid p-3">
                        <div className='row mb-1 bg-light-blue rounded p-2 rounded'>
                            <h6>Display Filters</h6>
                            <div className="col-lg-2 col-md-2 col-sm-12 text-start">
                                <label className='form-label'>Gasflow Period</label>
                                <select className='form-select form-select-sm' aria-label="select public or private" style={{ maxWidth: '150px' }}>
                                    <option>Daily</option>
                                </select>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12 text-start">
                                <label className='form-label'>Display Date</label>
                                <input className="form-control form-control-sm" type="month" value={`${new Date().getFullYear()}-${(new Date().getMonth() > 8 ? "" : "0") + (new Date().getMonth() + 1)}`} onChange={() => { }}
                                    style={{ maxWidth: '180px' }} />
                            </div>
                        </div>
                    </div>

                    <p className='text-start m-1'>Imbalance Positions List</p>
                    <div className="table-responsive mt-1">

                        {/* Daily Pool Summary Table */}
                        <table className="bg-white table table-sm table-hover table-striped-columns table-bordered border-primary rounded pb-1"
                        >
                            <thead>
                                <tr>
                                    <th>Pool ID</th>
                                    <th>Company Name</th>
                                    <th>Company Contract</th>
                                    <th>Phone Number</th>
                                    <th>Email Account</th>
                                    <th>Imbalance Position</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>

                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default MarketerSupplierImbalance