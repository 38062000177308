import { useState, useEffect } from 'react'

// images
// import excel from '../../assets/images/excel.gif';
import dmbtui from '../../assets/images/alert-2.gif';
import brokerRpts from '../../assets/images/imb-list.jpg'

// grabbing modals from daily pool report, the modals are the exact same
import DailyImblTradeUi from '../DailyPoolReport/Modals/DailyImblTradeUi'
import NonDailyBrokerReport from './Modals/NonDailyBrokerReport';

// tables
import NonDailyDCQTable from './Tables/NonDailyDCQTable'
import NonDailyDailyPoolDetailsTable from './Tables/NonDailyDailyPoolDetailsTable'

// import custom date functions
import {
	getMonthStart,
	toDatePickerMonthString,
	toISOStringForDatabase
} from '../../utils/DateUtils'

// import custom hooks
import useBusinessSelector from '../../hooks/useBusinessSelector';

import Restricted from '../Restricted';



function NonDailyPoolReport() {

	// local state variable for component
	const [componentState, setComponentState] = useState(
		{
			gasFlowDate: getMonthStart(new Date()),
			selectedPoint: {},
			displayUnits: 'MCF/DTH',
			buID: '',
			selectedSupplier: {},
			monthlySummaryDetails: [],
			monthlyTableLoading: false,
			dailySummaryDetails: [],
			dailySummaryDtlTotals: {},
			dailyTableLoading: false,
			selectedContract: {},
			points: [],
			suppliers: []
		}
	)

	// business selector hook
	useBusinessSelector(setComponentState);

	// useEffect for getting summary details
	useEffect(() => {
		if (componentState.selectedPoint?.PT_NUM
			&& componentState.displayUnits
			&& componentState.selectedSupplier?.BA_ID
			&& componentState.selectedContract?.CNTR_NUM) {
			const monthlyUrl = 'NonDailyPool/GetPoolTargetDataTargetList' +
				'?ptnum=' + componentState.selectedPoint.PT_NUM +
				'&StartDate=' + toISOStringForDatabase(getMonthStart(componentState.gasFlowDate)) +
				'&baId=' + componentState.selectedSupplier.BA_ID +
				'&cntr_num=' + componentState.selectedContract.CNTR_NUM;

			const dailyUrl = 'NonDailyPool/GetDailyImbalanceSummaryList' +
				'?selectedBa=' + componentState.selectedSupplier.BA_ID +
				'&ptnum=' + componentState.selectedPoint.PT_NUM +
				'&StartDate=' + toISOStringForDatabase(getMonthStart(componentState.gasFlowDate)) +
				'&buBaId=' + componentState.buID +
				'&DisplayUnits=' + componentState.displayUnits;

			const getMonthlySummaryDetails = async () => {
				setComponentState(
					prevState => {
						return {
							...prevState,
							monthlyTableLoading: true
						}
					}
				)
				fetch(monthlyUrl).then(
					response => {
						return response.json()
					}
				).then(
					response => {
						setComponentState(
							prevState => {
								return {
									...prevState,
									monthlySummaryDetails: response,
									monthlyTableLoading: false
								}
							}
						)
					}
				)
			};
			const getDailySummaryDetails = async () => {
				setComponentState(
					prevState => {
						return {
							...prevState,
							dailyTableLoading: true
						}
					}
				)
				fetch(dailyUrl).then(
					response => {
						return response.json()
					}
				).then(
					response => {
						setComponentState(
							prevState => {
								return {
									...prevState,
									dailySummaryDetails: response,
									dailyTableLoading: false
								}
							}
						)
					}
				)
			}
			getMonthlySummaryDetails()
			getDailySummaryDetails()
		}
	},
		[
			componentState.gasFlowDate,
			componentState.selectedPoint,
			componentState.displayUnits,
			componentState.buID,
			componentState.selectedSupplier,
			componentState.selectedContract
		])

	// Gets totals and formats numbers anytime monthly summary details change
	useEffect(() => {
		let cumImbal = 0 // used to keep track of cumulative imbalance
		let newTotals = {}
		componentState.dailySummaryDetails.forEach(
			detail => {
				detail.cumImbal = 0
				if (detail.F_CG_IMBAL_NET > 0 || detail.F_CG_IMBAL_NET < 0) {
					cumImbal += detail.F_CG_IMBAL_NET
					detail.cumImbal = cumImbal
				}
				Object.keys(detail).forEach(
					key => {
						if (typeof (detail[key]) === 'number') {
							if (!newTotals[key]) {
								newTotals[key] = detail[key] // set to total if not defined
							}
							else { // else it is defined so just add to it
								if (key === "cumImbal")
									newTotals[key] = cumImbal // cumulative imbalance does not need to be added
								else
									newTotals[key] += detail[key]
							}
							detail[key] = detail[key].toLocaleString("en-US", { minimumFractionDigits: 2 }) // format number
						}
					}
				)
			}
		)

		// format totals
		Object.keys(newTotals).forEach(
			key => {
				newTotals[key] = newTotals[key].toLocaleString("en-US", { minimumFractionDigits: 2 })
			}
		)

		// set state
		setComponentState(
			prevState => {
				return {
					...prevState,
					dailySummaryDtlTotals: newTotals
				}
			}
		)
	}, [componentState.dailySummaryDetails])

	// handlers
	const pointChange = (event) => {

		const newSelectedPointID = event.target.value;
		const newSelectedPoint = componentState.points.find(point => point.PT_NUM === newSelectedPointID);
		setComponentState(
			prevState => {
				return {
					...prevState,
					selectedPoint: newSelectedPoint
				};
			}
		);

	};

	// return JSX
	return (
		<div className='container-fluid'>
			<div className="text-black table-responsive">
				<div className='card mt-2'>
					<div className='card-header text-center'><h4>Non-Daily Pool Report and Invoice</h4></div>
					<div className='card-body'>
						{
							// Buttons
							<div className="row">
								<div className="col-sm-12 col-md-12 col-lg-12 p-2">
									{/* <button
										className='btn btn-md btn-primary mb-2 me-2'
									>
										<img src={excel} alt='Export excel report' />
										Excel Report
									</button> */}
									<button
										className='btn btn-md btn-primary mb-2 me-2'
										data-bs-toggle="modal"
										data-bs-target="#DailyImblTradeUi"
									>
										<img src={dmbtui} alt='Daily Imbalance Tolerance' />
										Daily Imbalance Tolerance UI
									</button>
									<button
										className='btn btn-md btn-primary mb-2 me-2'
										data-bs-toggle="modal"
										data-bs-target="#BrokerReport"
									>
										<img src={brokerRpts} alt='Broker Report' />
										Broker Delivery Reports
									</button>
								</div>
							</div>
						}
						{
							// Filters
							<div className='row mb-3 bg-light-blue rounded p-1 rounded'>
								<h6>Display Filters</h6>
								{
									// Gas Flow Date
									<div className="col-lg-2 col-md-2 col-sm-12 text-start">
										<label className='form-label pe-2'>Gas Flow Month</label>
										<input
											className="form-control form-control-sm"
											type="month"
											value={toDatePickerMonthString(componentState.gasFlowDate)}
											onChange={
												(event) => {
													setComponentState(
														prevState => {
															console.log("setting state...")
															return {
																...prevState,
																gasFlowDate: event.target.value
															}
														}
													)
												}
											}
											style={{ maxWidth: '180px' }} />
									</div>
								}
								{
									// Business Associate
									<div className="col-lg-2 col-md-2 col-sm-12">
										<label className='form-label pe-2'>Supplier</label>
										<Restricted
											restrictedTo={['SYS_ADMIN-ACCESS']}
											action='disable'
										>
											<select
												className='form-select form-select-sm'
												aria-label="select public or private"
												value={componentState.selectedSupplier.BA_ID}
												onChange={
													(event) => {
														setComponentState(
															prevState => {

																const newSelectedSupplier = componentState.suppliers.find(supplier => supplier.BA_ID === event.target.value);

																return {
																	...prevState,
																	selectedSupplier: newSelectedSupplier
																}
															}
														)
													}
												}
											>
												{
													componentState.suppliers.map(
														supplier => {
															return (

																<option
																	key={supplier.BA_ID}
																	value={supplier.BA_ID}
																>
																	{supplier.NAME}
																</option>

															);
														}
													)
												}
											</select>
										</Restricted>
									</div>
								}
								{
									// Point
									<div className="col-lg-1 col-md-1 col-sm-12">
										<label className='form-label pe-2'>Pool ID</label>
										<select
											className='form-select form-select-sm'
											aria-label="select public or private"
											style={{ maxWidth: '150px' }}
											value={componentState.selectedPoint && componentState.selectedPoint.PT_NUM}
											onChange={pointChange}
										>
											{
												Array.isArray(componentState.points) ? componentState.points.map(
													point => {
														return (

															<option
																key={point.PT_NUM}
																value={point.PT_NUM}
															>
																{point.PT_NAME}
															</option>

														);
													}
												) : null
											}
										</select>
									</div>
								}
								{
									// Display Units
									<div className="col-lg-2 col-md-2 col-sm-12">
										<label className='form-label pe-2 text-start'>Display Units</label>
										<select
											className='form-select form-select-sm'
											aria-label="select public or private"
											style={{ maxWidth: '150px' }}
											value={componentState.displayUnits}
											onChange={
												(event) => {
													setComponentState(
														prevState => {
															return {
																...prevState,
																displayUnits: event.target.value
															}
														}
													)
												}
											}
										>
											<option>MCF/DTH</option>
										</select>
									</div>
								}
							</div>
						}
						{
							// Tables
							<div>
								<NonDailyDCQTable componentState={componentState} />
								<NonDailyDailyPoolDetailsTable componentState={componentState} />
							</div>
						}
					</div>
				</div>
				{
					// Modals
					<div>
						<DailyImblTradeUi
							componentState={componentState}
						/>
						<NonDailyBrokerReport
							componentState={componentState}
							setComponentState={setComponentState}
						/>
					</div>
				}
			</div >
		</div>
	)
}

export default NonDailyPoolReport