import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { notification_actions } from '../../../../../store/notification-slice';
import { toDatePickerString, getDayStart } from '../../../../../utils/DateUtils';
import saveNom from '../../../../../assets/images/save.png';
import cancelNom from '../../../../../assets/images/cancel.png';
import './detail-modal.css';
import { mapPkgPathInfoObjToVolDtl, mapPkgPathInfoObjToPathDtl } from '../../../../../utils/NonPathModelNomUtils';
import { non_path_noms_actions, setPackageAndFetchPkgPaths, fetchPackages, updatePackage } from '../../../../../store/non-path-noms-slice';
import Spinner from '../../../../Spinner';
import LocationQuantitiesTable from '../../Tables/LocationQuantitiesTable';
import useNonPathFormUpdate from '../../../../../hooks/useNonPathFormUpdate';
import useErrorStyling from '../../../../../hooks/useErrorStyling';

const customStyles = { overlay: { backgroundColor: 'rgb(0,0,0,.60' } };


function NonPathNomDtlModal() {

	// Redux
	const selectedPackage = useSelector(state => state.non_path_noms_slice.selectedPackage);
	const NonPathNomsFilters = useSelector(state => state.non_path_noms_slice.NonPathNomsFilters);
	const { nomDetailModalState } = useSelector(state => state.non_path_noms_slice.modalStates);;
	const { open, previousModalStateName, previousModalState, loading } = nomDetailModalState;
	const dispatch = useDispatch();

	// React Hook Form
	const form = useForm();
	const { register, setValue, getValues, formState, handleSubmit } = form;
	const { isSubmitted } = formState;

	// Updates the form when selected package, selected contract, or selectedPkgPathInfoObj changes.
	useNonPathFormUpdate(form);

	// Error styling.
	useErrorStyling('non-path-nom-dtl-form', form);

	const closeModal = async () => {
		// Close nom detail modal.
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'nomDetailModalState',
			newModalState: {
				...nomDetailModalState,
				open: false,
				previousModalState: {},
				previousModalStateName: ''
			}
		}));

		// If there was a modal open previously, open it back up.
		if (previousModalStateName) {
			await dispatch(non_path_noms_actions.changeModalState({
				modalStateName: previousModalStateName,
				newModalState: { ...previousModalState, open: true }
			}));
		}
		// Else, clear all information.
		else {
			// Clear selected package.
			await dispatch(non_path_noms_actions.setSelectedPackage(null));

			// Clear selectedPkgPathInfoObj
			await dispatch(non_path_noms_actions.setSelectedPkgPathInfoObj(null));

			// Allow the form to reset.
			await dispatch(non_path_noms_actions.setShouldResetForm(true));
		}
	};

	/** Submit data to server. */
	const submitData = async (formData) => {
		// Set loading to true.
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'nomDetailModalState',
			newModalState: { ...nomDetailModalState, loading: true }
		}));

		// Get values from form data.
		const { STARTDATE, ENDDATE, pkgPathInfoObjs } = formData;

		// Get start and end date to know which details to update.
		const startDate = getDayStart(STARTDATE);
		const endDate = getDayStart(ENDDATE);

		// Create updated package paths.
		const newPkgPaths = selectedPackage?.ENT_PKG_PATH?.map(prevPkgPath => {
			const pkgPathInfoObj = pkgPathInfoObjs?.find(pkgPathInfoObj => pkgPathInfoObj?.PATH_NUM === prevPkgPath?.PATH_NUM);

			// Create updated package volume details.
			const newPkgVolDtls = prevPkgPath?.ENT_PKG_VOL_DTL?.map(prevPkgVolDtl => {
				const gasflow_date = getDayStart(prevPkgVolDtl?.GASFLOW_DATE);
				if (gasflow_date >= startDate && gasflow_date <= endDate) {
					const newPkgVolDtl = { ...prevPkgVolDtl };
					mapPkgPathInfoObjToVolDtl(pkgPathInfoObj, newPkgVolDtl);
					return newPkgVolDtl;
				}
				return prevPkgVolDtl;
			});

			// Create updated package path details.
			const newPkgPathDtls = prevPkgPath?.ENT_PKG_PATH_DTL?.map(prevPkgPathDtl => {
				const effdate = getDayStart(prevPkgPathDtl?.EFFDATE);
				if (effdate >= startDate && effdate <= endDate) {
					const newPkgPathDtl = { ...prevPkgPathDtl };
					mapPkgPathInfoObjToPathDtl(pkgPathInfoObj, newPkgPathDtl);
					return newPkgPathDtl;
				}
				return prevPkgPathDtl;
			});

			return {
				...prevPkgPath,
				ENT_PKG_VOL_DTL: newPkgVolDtls,
				ENT_PKG_PATH_DTL: newPkgPathDtls
			};
		});

		// Set new package paths into selected package.
		const modifiedPackage = { ...selectedPackage, ENT_PKG_PATH: newPkgPaths };

		await dispatch(updatePackage(modifiedPackage));

		// Set loading to false.
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'nomDetailModalState',
			newModalState: { ...nomDetailModalState, loading: false }
		}));
		await dispatch(fetchPackages(NonPathNomsFilters));
	};

	/**
	 * Custom start date onChange event handler. This is needed so that the end date
	 * will automatically update if the start date goes past it.
	 * @param {any} e - onChange event
	 */
	const startDateChange = (e) => {
		const newValue = e.target.value;

		// Set start date value.
		setValue('filterStartDate', newValue, { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });

		// Set end date to same as start date if start date comes after end date.
		const filterEndDate = getValues('filterEndDate');
		if (getDayStart(newValue).getTime() > getDayStart(filterEndDate).getTime())
			setValue('filterEndDate', newValue);
	};

	return (
		<div>
			<Modal
				isOpen={open}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="Nomination Detail Modal"
				className='detail-modal container-fluid'
			>
				<div className=''>
					<div className='modal-header'>
						<h5>Non-Path Nomination Detail UI</h5>
						<button className='btn-close' onClick={closeModal} />
					</div>
					<form className='modal-body bg-light-blue rounded pb-2 p-1 non-path-nom-dtl-form' onSubmit={handleSubmit(submitData)}>
						<div className='row'>
							<div className='col-sm-12 col-md-12 col-lg-12'>
								<button className='btn btn-sm btn-primary me-2 mb-1' disabled={loading}>
									<img src={saveNom} alt='Save Nomination' />
									Save Nomination
								</button>
								<button type='button' className='btn btn-sm btn-primary me-2 mb-1' onClick={closeModal} disabled={loading}>
									<img src={cancelNom} alt='Cancel Nomination' /> Cancel Nomination
								</button>
								{loading && <Spinner status='loading' />}
							</div>
						</div>
						{/* Nom details information */}
						<div className='row'>
							<div className='col-12 col-sm-12 col-md-12 col-lg-12'>
								<h6 className='mb-0'>Nomination Details Information</h6>
								<hr className='mt-0 mb-1' />
							</div>
						</div>
						<div className='row'>
							<div className='col-6 col-sm-4 col-md-4 col-lg-4'>
								<label className='form-label mb-0'>Pkg Num: </label>
								<div className='form-group mb-0'>
									<input type='text' className='form-control form-control-sm' {...register('PACKAGE_NUM')} disabled />
								</div>
							</div>
							<div className='col-6 col-sm-4 col-md-4 col-lg-4'>
								<label className='form-label mb-0'>Contract: </label>
								<div className='form-group mb-0'>
									<input type='text' className='form-control form-control-sm' {...register('CNTR_NUM.CNTR_ALT_NUM1')} disabled />
								</div>
							</div>
							<div className='col-6 col-sm-4 col-md-4 col-lg-4'>
								<label className='form-label mb-0'>Activity: </label>
								<div className='form-group mb-0'>
									<input
										type='text'
										className='form-control form-control-sm'
										{...register('ACTIV_NUM.ACTIV_ALT_NUM')}
										disabled
									/>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-6 col-sm-4 col-md-4 col-lg-4'>
								<label className='form-label mb-0'>Updated By: </label>
								<div className='form-group mb-0'>
									<input type='text' className='form-control form-control-sm' {...register('USERSTAMP')} disabled />
								</div>
							</div>
							<div className='col-6 col-sm-4 col-md-4 col-lg-4'>
								<label className='form-label mb-0'>Updated On: </label>
								<div className='form-group mb-0'>
									<input type='text' className='form-control form-control-sm' {...register('DATESTAMP')} disabled />
								</div>
							</div>
						</div>
						{/* Update Quantities  */}
						<div className='row mt-1'>
							<div className='col-sm-12 col-md-12 col-lg-12'>
								<h6 className='mb-0'>Update Quantities</h6>
								<hr className='mt-0 mb-1' />
							</div>
						</div>
						<div className='row'>
							<div className='col-6 col-sm-3 col-md-3 col-lg-3'>
								<label className="form-label mb-0" htmlFor="RecQty">Rec Qty:</label>
								<div className='form-group mb-0'>
									<input disabled className="form-control form-control-sm" type="text" {...register('totalRcvQty')} />
								</div>
							</div>
							<div className='col-6 col-sm-3 col-md-3 col-lg-3'>
								<label className="form-label mb-0" htmlFor="FuelQty">Fuel Qty:</label>
								<div className='form-group mb-0'>
									<input disabled className="form-control form-control-sm" type="text" {...register('totalFuel')} />
								</div>
							</div>
							<div className='col-6 col-sm-3 col-md-3 col-lg-3'>
								<label className="form-label mb-0" htmlFor="DelQty">Del Qty:</label>
								<div className='form-group mb-0'>
									<input disabled className="form-control form-control-sm" type="text" {...register('totalDlvQty')} />
								</div>
							</div>
							<div className='col-6 col-sm-3 col-md-3 col-lg-3'>
								<label className="form-label mb-0" htmlFor="AvailableQty">Available Qty:</label>
								<div className="input-group">
									<input
										disabled
										className='form-control form-control-sm'
										type='text'
										{...register('availQty', { validate: value => value === 0 || 'Available Qty must be zero.' })}
									/>
								</div>
							</div>
						</div>
						<div className='row align-items-end'>
							<div className='col-6 col-sm-3 col-md-3 col-lg-3'>
								<label className='form-label mb-0'>From: </label>
								<div className='form-group mb-0'>
									<input
										className="form-control form-control-sm"
										type="date"
										style={{ maxWidth: '180px' }}
										max={toDatePickerString(selectedPackage?.ENDDATE)}
										min={toDatePickerString(selectedPackage?.STARTDATE)}
										{...register('STARTDATE', { onChange: startDateChange })}
									/>
								</div>
							</div>
							<div className='col-6 col-sm-3 col-md-3 col-lg-3'>
								<label className='form-label mb-0'>To: </label>
								<div className='form-group mb-0'>
									<input
										className="form-control form-control-sm"
										type="date"
										style={{ maxWidth: '180px' }}
										max={toDatePickerString(selectedPackage?.ENDDATE)}
										min={toDatePickerString(selectedPackage?.STARTDATE)}
										{...register('ENDDATE')}
									/>
								</div>
							</div>
						</div>
						{/* Nom Volume Details  */}
						<div className="row mt-1">
							<div className='col'>
								<h6 className="text-start border-bottom border-primary border-lg-2 col-md-12 col-sm-12">Package Path Detail and Volume</h6>
								<h6 className="text-start">Receive</h6>
								<LocationQuantitiesTable form={form} flowDirection='R' currentModalStateName='nomDetailModalState' />
								<h6 className="text-start">Deliver</h6>
								<LocationQuantitiesTable form={form} flowDirection='D' currentModalStateName='nomDetailModalState' />
							</div>
						</div>
					</form>
				</div>
			</Modal >
		</div >
	);
}

export default NonPathNomDtlModal;

