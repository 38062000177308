import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { physical_trade_deals_actions } from '../../../store/physical-trade-deals-slice';
import CustomStyles from './SSM-Table-Style';
import { useEffect } from 'react';
import Spinner from '../../Spinner';

function Table({ dealMakers, loading }) {

	// Redux
	const selectedBidPkg = useSelector(state => state.physical_trade_deals_slice.selectedBidPkg);
	const dispatch = useDispatch();

	const columns = [
		{
			cell: row => <input type="checkbox" checked={row.CONTACT_NUM === selectedBidPkg?.COMP_CONTACT_OBJ?.CONTACT_NUM} onChange={() => { handleRowClicked(row) }} />,
			allowOverflow: true,
			button: true,
		},
		{
			name: 'Contact Num',
			selector: row => row.CONTACT_NUM,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'First Name',
			selector: row => row.FIRST_NAME,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Last Name',
			selector: row => row.NAME,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Business Name',
			selector: row => row.BA_ID?.NAME,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Status',
			selector: row => row.STATUS,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		}
	];

	const handleRowClicked = (row) => {
		if (selectedBidPkg?.COMP_CONTACT_OBJ?.CONTACT_NUM === row?.CONTACT_NUM) {
			dispatch(physical_trade_deals_actions.setOpenDealModal(false));
			return;
		}
		// update COMP_CONTACT_OBJ in selected bid package
		dispatch(physical_trade_deals_actions.setSelectedBidPkg({
			...selectedBidPkg,
			COMP_CONTACT_OBJ: row
		}));
		// hide modal after row is finished selecting
		dispatch(physical_trade_deals_actions.setOpenDealModal(false));
	};

	return (
		<div className="tableFixHead">
			<DataTable
				progressPending={loading}
				progressComponent={<Spinner status='loading' />}
				className='table-striped pb-1'
				fixedHeader={true}
				fixedHeaderScrollHeight="700px"
				columns={columns}
				data={dealMakers}
				striped={true}
				highlightOnHover={true}
				customStyles={CustomStyles}
				onRowClicked={handleRowClicked}
				dense={true}
			/>
		</div>
	);
};

export default Table;