import { createSlice } from '@reduxjs/toolkit';
import { getNextMonthEnd } from '../utils/DateUtils';

const initialState = {
	selectedContact: {},
	ContactRoleCodes: [],
	EmailGroupCodes: [],
	loading: false,
	processCounter: 0,
	showEditContactModal: false,
	countries: [],
	states: [],
	cities: [],
	contacts:[],
	codedExpirationDays: 0,
};

const contact_slice = createSlice({
	name: 'contact_slice',
	initialState,
	reducers: {
		/**
	 * Make a property change to the contact. Provide the input tag with a prop named "property"
	 * which is the property that the input tag is meant to keep track of, then put the
	 * method as the onChange handler.
	 * @param {any} event
	 */
		propertyChange(state, action) {

			const {
				objectName,
				property,
				newValue
			} = action.payload;

			const newContact = { ...state.selectedContact };

			if (objectName) {
				let newObject = { ...newContact[objectName] };
				newObject[property] = newValue;
				newContact[objectName] = newObject;
			}
			else {
				newContact[property] = newValue;
			}

			state.selectedContact = newContact;

		},

		/**
		 * Make a role change to the contact. Provide the input tag with a prop named "role_code"
		 * which is the role CDE that the input tag is meant to keep track of, then put the
		 * method as the onChange handler.
		 * @param {any} event
		 */
		roleChange(state, action) {

			const ebbUserRoles = [...state.selectedContact.ENT_CONTACT_ROLES];

			// Get clicked role code
			const clickedRoleCode = action.payload;
			const index = ebbUserRoles.findIndex(role => role.CDE === clickedRoleCode);

			// Add or take away the role
			if (index === -1) {
				ebbUserRoles.push({
					CONTACT_NUM: state.selectedContact.CONTACT_NUM,
					CDE: clickedRoleCode,
					BAContactRoleKey: {
						CDE: clickedRoleCode,
						CONTACT_NUM: state.selectedContact.CONTACT_NUM
					}
				});
			}
			else {
				ebbUserRoles.splice(index, 1);
			}

			// Update selected contact in state
			state.selectedContact = {
				...state.selectedContact,
				ENT_CONTACT_ROLES: ebbUserRoles
			};

		},

		/**
		 * Make an email group change to the contact. Provide the input tag with a prop named "email_code"
		 * which is the email CDE that the input tag is meant to keep track of, then put the
		 * method as the onChange handler.
		 * @param {any} event
		 */
		emailGroupChange(state, action) {

			const ebbUserEmailGroups = [...state.selectedContact.ENT_BA_CONTACT_NOTIFICATION];

			// Get clicked role code
			const newEmailGroup = action.payload;
			const index = ebbUserEmailGroups.findIndex(emailGroup => emailGroup.CDE === newEmailGroup.CDE);

			// Add or take away the role
			if (index === -1) {
				ebbUserEmailGroups.push(newEmailGroup);
			}
			else {
				ebbUserEmailGroups.splice(index, 1);
			}

			// Update selected contact in state
			state.selectedContact = {
				...state.selectedContact,
				ENT_BA_CONTACT_NOTIFICATION: ebbUserEmailGroups
			};

		},
		setContactRoleCodes(state, action) {
			state.ContactRoleCodes = action.payload;
		},
		setLoading(state, action) {
			const newLoadingState = action.payload;

			// Keep track of number of running processes.
			if (newLoadingState === true) {
				state.processCounter++;
				state.loading = true;
			}
			else if (newLoadingState === false) {
				state.processCounter--;
				if (state.processCounter === 0)
					state.loading = false;
			}
		},
		setSelectedContact(state, action) {
			state.selectedContact = action.payload;
		},
		setBA(state, action) {
			state.selectedContact.BA_ID = action.payload;
		},
		setEmailGroupCodes(state, action) {
			state.EmailGroupCodes = action.payload;
		},
		showEditContactModal(state, action) {
			state.showEditContactModal = action.payload;
		},
		setCountries(state, action) {
			const newCountries = action.payload;
			state.countries = newCountries;
		},
		setStates(state, action) {
			const newStates = action.payload;
			state.states = newStates;
		},
		setContacts(state, action) {
			state.contacts = action.payload;
		},
		resetContact(state, action) {
			const codedExpirationDays = new Date()
			const { selectedSupplier, expirationDays } = action.payload;
			codedExpirationDays.setDate(codedExpirationDays.getDate() + expirationDays);
			
			state.selectedContact = {
				CONTACT_NUM: '',
				BA_ID: selectedSupplier,
				ADDR_NUM: {
					BAAddressKey: {
						BA_ID: selectedSupplier.BA_ID,
						ADDR_NUM: ''
					},
					ADDR_NUM: '',
					BA_ID: selectedSupplier.BA_ID,
					ADDR_LINE1: '',
					STATE: {
						STATE:''
					},
					CITY: '',
					POSTAL_CDE: '',
					COUNTRY_OBJ: {
						COUNTRY_ABBR:''
					}
				},
				STATUS: 'AC',
				START_DATE: new Date().toISOString(),
				END_DATE: '', // end date should be empty and require user to pick
				NAME: '',
				FIRST_NAME: '',
				SALUTATION: '',
				PHONE_NUM_raw: '',
				FAX_NUM_raw: '',
				TITLE: '',
				PHONE_NUM_EXT: '',
				EMAIL_ID: '',
				mobile_phone_raw: '',
				ENT_BA_CONTACT_NOTIFICATION: [],
				ENT_CONTACT_ROLES: [],
				EbbUserLogin: {
					CONTACT_NUM: '',
					RESET_PASSWORD: '1',
					USER_ID: '',
					EXPIRY_DATE: (codedExpirationDays).toISOString(),
					LOCKED: 'N'
				}
			}
		},
		setCodedExpirationDay(state, action) {
			state.codedExpirationDays = action.payload;
		}
	}
});

export const contact_actions = contact_slice.actions;
export default contact_slice.reducer;