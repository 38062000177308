import { /*useEffect,*/ useState /*useRef*/ } from "react";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import { customer_list_view_actions } from "../../store/customer-list-view-slice";
import CustomStyles from "./cust-table-style.css";
import Spinner from "../CustomComponents/Spinner";
//import DailyRead from "./DailyRead";

// import ReadOnlyRow from "./ReadOnlyRow";
// import EditableRow from "./EditableRow.js";
// import excel from '../../assets/images/excel.gif'
// import { useDownloadExcel } from "react-export-table-to-excel";
// import custom components

const Table = ({ componentState }) => {
  const columns = [
    {
      name: "View Details",
      button: true,
      cell: (row) => (
        <button
          onClick={() => openDailyReadModal(row)}
          className="btn btn-md btn-primary"
        >
          Daily
        </button>
      ),
    },
    {
      name: "Name",
      selector: (row) => row?.CNTR_PARTY1_NAME,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Premise Name",
      selector: (row) => row?.SiteFacilityName,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "City",
      selector: (row) => row?.City,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Account #",
      selector: (row) => row?.Account_PODID,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      name: "Act Energy",
      selector: (row) => row?.ACT_ENERGY,
      sortable: true,
      reorder: true,
      wrap: true,
    },
  ];
  // Redux
  const dispatch = useDispatch();
  const loading = useSelector(
    (state) => state.customer_list_view_slice.loading.state
  );
  const totalActEnergy = useSelector(
    (state) => state.customer_list_view_slice.totalActEnergy
  );

  // State
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 8;

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  // const [excelGenerated, setExcelGenerated] = useState(false);

  const openDailyReadModal = (row) => {
    dispatch(customer_list_view_actions.setOpenDailyReadModal(true));
    dispatch(customer_list_view_actions.setSelectedCustomer(row));
  };

  //Calculate energy total for table
  // useEffect(() => {
  //   let total = 0;
  //   componentState.customers.forEach((customer) => {
  //     total += customer.ACT_ENERGY;
  //   });
  //   setComponentState((prevState) => {
  //     return {
  //       ...prevState,
  //       totalEnergy: total,
  //     };
  //   });
  // }, [componentState.customers, setComponentState]);

  //Add Customer code for handling values and inputs from forms:
  // const handleAddFormChange = (event) => {
  // 	event.preventDefault();

  // 	const fieldName = event.target.getAttribute("name");
  // 	const fieldValue = event.target.value;

  // 	const newFormData = { ...componentState.addFormData };
  // 	newFormData[fieldName] = fieldValue;

  // 	setComponentState(
  // 		prevState => {
  // 			return {
  // 				...prevState,
  // 				addFormData: newFormData
  // 			}
  // 		}
  // 	)
  // };

  //Code for handling form submission from adding a customer:
  // const handleAddFormSubmit = (event) => {
  // 	event.preventDefault();

  // 	const newcustomer = {
  // 		CNTR_PARTY1_NAME: componentState.addFormData.CNTR_PARTY1_NAME,
  // 		SiteFacilityName: componentState.addFormData.SiteFacilityName,
  // 		City: componentState.addFormData.City,
  // 		Account_PODID: componentState.addFormData.Account_PODID,
  // 		ACT_ENERGY: componentState.addFormData.ACT_ENERGY
  // 	};

  // 	const newcustomers = [...componentState.customers, newcustomer];

  // 	setComponentState(
  // 		prevState => {
  // 			return {
  // 				...prevState,
  // 				customers: newcustomers
  // 			}
  // 		}
  // 	)
  // };

  //Code for handling form submission from editing a customer:
  // const handleEditFormSubmit = (event) => {

  // 	// prevent page from refreshing
  // 	event.preventDefault();

  // 	//New customer array and sorting methods:
  // 	const index = componentState.customers.findIndex(customer => customer.Account_PODID === componentState.editCustomerID);

  // 	let newCustomer = {
  // 		...componentState.customers[index]
  // 	}

  // 	console.log("Old customer is: ")
  // 	console.log(newCustomer)

  // 	Object.keys(componentState.editFormData).forEach(
  // 		key => {
  // 			newCustomer[key] = componentState.editFormData[key]
  // 		}
  // 	)

  // 	console.log("New Customer is: ");
  // 	console.log(newCustomer)

  // 	let newCustomers = [
  // 		...componentState.customers
  // 	]

  // 	newCustomers[index] = newCustomer

  // 	setComponentState(
  // 		prevState => {
  // 			return {
  // 				...prevState,
  // 				customers: newCustomers,
  // 				editCustomerID: ""
  // 			}
  // 		}
  // 	)
  // };

  // tableRef for Excel Export:
  // const tableRef = useRef(null);

  // //Handling the Excel Download:
  // const { onDownload } = useDownloadExcel({
  // 	currentTableRef: tableRef.current,
  // 	filename: 'Contact List Report',
  // 	sheet: 'Supplier/Marketer Contact List Table',
  // 	onExcelGenerated: () => setExcelGenerated(true),
  // });

  return (
    <>
      <DataTable
        progressPending={loading}
        progressComponent={<Spinner status="loading" />}
        className="pb-1"
        // fixedHeader={true}
        // fixedHeaderScrollHeight="700px"
        customStyles={CustomStyles}
        columns={columns}
        data={componentState.customers}
        striped={true}
        highlightOnHover={true}
        pagination
        paginationServer
        paginationTotalRows={totalActEnergy}
        paginationPerPage={pageSize}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        onChangePage={handlePageChange}
        responsive={true}
        dense={true}
      />
      {/*<DailyRead customer={componentState} />*/ }
    </>
  );
};

export default Table;
