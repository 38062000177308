const CustomStyles = {
	table: {
		style: {
			position: 'sticky',
			top: '0px',
			// fontSize: "1.2rem",
			border: '1px solid blue',
			// striped: 'default',
		},
	},
	//responsiveWrapper: {
	//	style: {
	//		width: 'auto'
	//	}
	//},
	headRow: {
		style: {
			position: 'sticky',
			top: '0px',
			fontSize: ".9rem",
			paddingLeft: '0px',
			// backgroundColor: 'blue'
		},
	},
	rows: {
		// style: {

		//     when: row => row.INFO_POSTING_TYPE === 'CT',
		//     style: row => ({ backgroundColor: row.isSpecial ? 'pink' : 'green' }),

		// }
	},
	cells: {
		style: {
			fontSize: '.9rem',
			border: '1px solid blue',
			paddingLeft: '1px'
		}
	},
	critical: {
		style: {
			color: 'red',
		}
	},
	nonCritical: {
		style: {
			color: 'black',
		}
	}
}

export default CustomStyles