import { React, Fragment, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
// import AppRoutes from './AppRoutes';
import Layout from './components/Layout';
import './custom.css';
import NotificationModal from './components/CustomComponents/NotificationModal';

// redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { refreshToken } from './store/auth-slice';

//App routes:
import PoolNominations from './components/PoolNominations';

/*New imports to integrate below*/
import Intro from './intro';
import Forecast from './components/Forecast';
import Notices from './components/Alerts';
import DailyPoolReport from './components/DailyPoolReport';
import CustomerListView from './components/CustomerList';
import NonDailyPoolReport from './components/NonDailyPoolReport';
import TradeImbalance from './components/Imbalance';
import InvoiceSummary from './components/InvoiceSummary';
import SupplierContactList from './components/SupplierContactList';
// import FetchData from './components/FetchData';

//Broker Activity Modules below:
import PhysicalDealsAndTrades from './components/PhysicalDealsModule';
import HedgeContract from './components/PhysicalDealsModule/HedgeContract';
import PositionInventory from './components/PhysicalDealsModule/PositionInventory'; //PositionInventory is the parent component for the PositionInventory module.
import NewFinancialPosition from './components/PhysicalDealsModule/PositionInventory/NewFinancialPosition';
import Swaps from './components/PhysicalDealsModule/Swaps';
import Options from './components/PhysicalDealsModule/Options';
import PriceIndicies from './components/PhysicalDealsModule/PriceIndicies';
import AddIntNom from './components/PhysicalDealsModule/InterstateNoms/AddIntNom';
// import NewOption from './components/PhysicalDealsModule/Options/NewOption';
import InterstateNoms from './components/PhysicalDealsModule/InterstateNoms';
import StorageInventory from './components/PhysicalDealsModule/StorageInventory';

// Shipper Activities Modules below:
import WHPurchaseIndex from './components/ShipperActivities/WHPurchase';
import MeasurementIndex from './components/ShipperActivities/Measurement';
import GANominations from './components/ShipperActivities/GANominations';
import GasAllocation from './components/ShipperActivities/GANominations/GasAllocation';
import PoolBalance from './components/ShipperActivities/PoolBalance';

// utils
import { history } from './utils/HistoryUtils';
import { HasPermission } from './utils/PermissionUtils';
import IdleTimeoutModal from './components/CustomComponents/IdleTimeoutModal';
import HttpWarning from './HttpWarning';
import RevenueInvoices from './components/ShipperActivities/RevenueInvoices/RevenueInvoices';
import ProductionReport from './components/ShipperActivities/ProductionReport/ProductionReport';

export default function App() {
  // React Router Dom - I am using a special object that stores
  // the navigate and location from the useNavigate and useLocation hooks.
  // This allows me to navigate without having to pass along the reference.
  history.navigate = useNavigate();
  history.location = useLocation();

  // Redux
  const isLoggedIn = useSelector((state) => state.auth_slice.isLoggedIn);
  const dispatch = useDispatch();
  const userRoles = useSelector((state) => state.auth_slice.userRoles);

  // Refresh the token on app start or when the page is refreshed.
  useEffect(() => {
    if (sessionStorage.getItem('session') === 'active')
      dispatch(refreshToken());
  }, [dispatch]);

  //const state = useSelector(state => state);
  //useEffect(() => { console.log(state); }, [state]);

  return (
    <Layout>
      <Routes>
        {/* Public Links */}
        <Route path="/" element={<Intro />} />
        <Route path="/httpWarning" element={<HttpWarning />} />
        <Route path="/notices" element={<Notices />} />
        <Route path="/forecast" element={<Forecast />} />
        {isLoggedIn && (
          <Fragment>
            {
              // Retail Choice Links
              HasPermission(userRoles, ['RETAIL_CHOICE-ACCESS']) && (
                <Fragment>
                  <Route
                    path="/customerlistview"
                    element={<CustomerListView />}
                  />
                  <Route
                    path="/dailyPoolReport"
                    element={<DailyPoolReport />}
                  />
                  <Route path="/nonDaily" element={<NonDailyPoolReport />} />
                  <Route path="/imbalance" element={<TradeImbalance />} />
                  <Route path="/invoice" element={<InvoiceSummary />} />
                  <Route
                    path="/poolNominations"
                    element={<PoolNominations />}
                                  />
                  <Route path="/revenue-invoices" element={<RevenueInvoices />} />
                </Fragment>
              )
            }
            {
              // Broker/Gas-Supply Links
              HasPermission(userRoles, ['GAS_SUPPLY-ACCESS']) && (
                <Fragment>
                  <Route
                    path="/physicaldeals"
                    element={<PhysicalDealsAndTrades />}
                  />
                  <Route
                    path="/positionContracts"
                    element={<HedgeContract />}
                  />
                  <Route
                    path="/PositionInventory"
                    element={<PositionInventory />}
                  />
                  <Route
                    path="/newFinancialPosition"
                    element={<NewFinancialPosition />}
                  />
                  <Route path="/swaps" element={<Swaps />} />
                  <Route path="/options" element={<Options />} />
                  <Route path="/priceIndicies" element={<PriceIndicies />} />
                  <Route path="/interstateNoms" element={<InterstateNoms />} />
                  <Route
                    path="/storageInventory"
                    element={<StorageInventory />}
                  />
                  <Route path="/addIntNom" element={<AddIntNom />} />
                  {/* <Route path='/newOptionDeal' element={<NewOption />} /> */}
                </Fragment>
              )
            }
            {
              // Administrator Links
              HasPermission(userRoles, ['POOL_OPERATOR-ACCESS']) && (
                <Fragment>
                  <Route path="/measurement" element={<MeasurementIndex />} />
                  <Route path="/whpurchase" element={<WHPurchaseIndex />} />
                  <Route path="/ganominations" element={<GANominations />} />
                  <Route path="/gasAllocation" element={<GasAllocation />} />
                <Route path="/poolbalance" element={<PoolBalance />} />
                <Route path="/production-report" element={<ProductionReport />} />
                </Fragment>
              )
            }
          </Fragment>
        )}
        {HasPermission(userRoles, ['ADMIN-ACCESS']) && (
          <Fragment>
            <Route path="/supplier" element={<SupplierContactList />} />
          </Fragment>
        )}

        {/* Route to fall back to */}
        <Route path="*" element={<Intro />} />
      </Routes>
      <NotificationModal />
      <IdleTimeoutModal />
    </Layout>
  );
}
