import DataTable from 'react-data-table-component';
import CustomStyles from './Table-Styles';
import Spinner from '../../../../Spinner';
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { non_path_noms_actions } from '../../../../../store/non-path-noms-slice';


function OBAStatementTable({ date }) { // oba inventory table

    const obaStatementModalState = useSelector(state => state.non_path_noms_slice.modalStates.obaStatementModalState);
    const selectedSupplier = useSelector(store => store.business_selector_slice.selectedSupplier);

    const columns = [

        {
            name: 'Beginning MCF', // not sure what value is MCF for PointsBalance
            sortable: false,
            compact: true,
            wrap: true,
            omit: true, // MCF not needed for now - DevOps 3994
            //width: '25%',
        },
        {
            name: 'Beginning DTH', // BEG_VALUE // Beg Balance (DTH)
            selector: row => row.BEG_VALUE?.toLocaleString(),
            sortable: false,
            compact: true,
            wrap: true,
            //width: '25%',
        },
        {
            name: 'Ending MCF', // not sure what value is MCF for PointsBalance
            sortable: false,
            compact: true,
            wrap: true,
            omit: true, // MCF not needed for now - DevOps 3994
            //width: '25%',
        },
        {
            name: 'Ending DTH', // END_VALUE // Net Ending Balance(DTH)
            selector: row => row.NET_END_IMBAL?.toLocaleString(),
            sortable: false,
            compact: true,
            //width: '25%',
        },
    ];
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchOBAInventoryData(obaStatementModalState, selectedSupplier));
    }, [date, obaStatementModalState.selectedContract, selectedSupplier]);

    const fetchOBAInventoryData = (obaStatementModalState, selectedSupplier) => {
        return async (dispatch) => {
            try {
                await dispatch(non_path_noms_actions.changeModalState({
                    modalStateName: 'obaStatementModalState',
                    newModalState: { ...obaStatementModalState, obaStatementData: [] }
                }));

                // URL variables
                var baID = selectedSupplier.BA_ID;
                var month = date;
                var cntr_num = obaStatementModalState?.selectedContract?.CNTR_NUM;

                if(!cntr_num || !month || !baID) {
                    return;
                }

                const url = `PoolBalance/GetPoolBalance` +
                    `?baId=${baID}` +
                    `&month=${month}`;

                // Fetch data.
                const response = await fetch(url);

                // Handle errors.
                if (!response.ok) {
                    const message = await response.text();
                    throw new Error(message);
                }

                // Update data.
                const poolData = await response.json();
                const newOBAData = poolData.filter((pool) => pool.CNTR_NUM === cntr_num);
                
                await dispatch(non_path_noms_actions.changeModalState({
                    modalStateName: 'obaStatementModalState',
                    newModalState: { ...obaStatementModalState, obaStatementData: newOBAData }
                }));
            }
            catch (error) {
                if (error.name === 'AbortError') {
                    // Do nothing if previous request was aborted. The second request might
                    // still be loading when the first request is aborted
                    console.log('Request was aborted.');
                }
                console.log(error.message);
            }
        }
    }

    const conditionalRowStyles = [
        {
            when: row => row.Boldness === "BOLD",
            style: { fontWeight: 'bold' }
        },
    ]

    return (
        <DataTable
            progressComponent={< Spinner status='loading' />}
            className=''
            fixedHeader={true}
            fixedHeaderScrollHeight="auto"
            customStyles={CustomStyles}
            columns={columns}
            data={obaStatementModalState.obaStatementData}
            striped={true}
            highlightOnHover={true}
            responsive={false}
            dense={true}
            conditionalRowStyles={conditionalRowStyles}
        />
    );
};

export default OBAStatementTable;