import { notification_actions } from './notification-slice';
import { dailyMeasurement_actions } from './daily-measurement-slice';

export const fetchDailyMeasurement = ({
        buId = '',
        baId = '',
        pointId = '',
        fromGasFlowDate = '',
        toGasFlowDate = '',
    }) => {
    return async (dispatch) => {
        const getDailyMeasurement = async () => {
            const url = 'DailyMeasurement/GetDailyMeasurement?'
            + `buId=${buId}`
            + `&baId=${baId}`
            + `&pointId=${pointId}`
            + `&fromGasFlowDate=${new Date(fromGasFlowDate).toISOString()}`
            + `&toGasFlowDate=${new Date(toGasFlowDate).toISOString()}`;

            const res = await fetch(url)
            if (!res.ok) {
                const message = await res.text();
                throw new Error(message);
            }

            const dailyMeasurement = await res.json();
            const {PT_NUM, GasMeters} = dailyMeasurement;
            await dispatch(dailyMeasurement_actions.setPtNum(PT_NUM));
            await dispatch(dailyMeasurement_actions.setDailyMeasurementDataTable(GasMeters));
        }

        try {
            await dispatch(dailyMeasurement_actions.setLoading(true));
            await getDailyMeasurement();
            
        }
        catch (error) {
            dispatch(notification_actions.showModal({ headers: 'Error', message: error.message }));
        }
        finally {
            await dispatch(dailyMeasurement_actions.setLoading(false));
        }
    }
}

export const fetchDailyPMMeasurement = ({
    fromGasFlowDate = '',
    toGasFlowDate = '',
    pointID = '',
}) => {
    return async (dispatch) => {
        const getDailyMeasurement = async () => {
            const url = 'DailyMeasurement/GetProducerMeterMeasurements?'
                + `&startDate=${new Date(fromGasFlowDate).toISOString()}`
                + `&endDate=${new Date(toGasFlowDate).toISOString()}`
                + `&pointID=${pointID}`;

            const res = await fetch(url)
            if (!res.ok) {
                const message = await res.text();
                throw new Error(message);
            }

            const dailyMeasurement = await res.json();
            const { PT_NUM, GasMeters } = dailyMeasurement;
            await dispatch(dailyMeasurement_actions.setPtNum(PT_NUM));
            await dispatch(dailyMeasurement_actions.setDailyPMMeasurementDataTable(GasMeters));
        }

        try {
            await dispatch(dailyMeasurement_actions.setLoading(true));
            await getDailyMeasurement();

        }
        catch (error) {
            dispatch(notification_actions.showModal({ headers: 'Error', message: error.message }));
        }
        finally {
            await dispatch(dailyMeasurement_actions.setLoading(false));
        }
    }
}

export const fetchHourlyPMMeasurement = ({
    selectedDate = '',
    pointID = '',
}) => {
    return async (dispatch) => {
        const getHourlyMeasurement = async () => {
            const url = 'DailyMeasurement/GetHourlyProducerMeterMeasurements?'
                + `&selectedDate=${selectedDate}`
                + `&pointID=${pointID}`;

            const res = await fetch(url)
            if (!res.ok) {
                const message = await res.text();
                throw new Error(message);
            }

            const hourlyMeasurement = await res.json();
            const { GasMeters } = hourlyMeasurement;
            await dispatch(dailyMeasurement_actions.setHourlyPMMeasurementDataTable(GasMeters));
        }

        try {
            await dispatch(dailyMeasurement_actions.setLoading(true));
            await getHourlyMeasurement();
        }
        catch (error) {
            dispatch(notification_actions.showModal({ headers: 'Error', message: error.message }));
        }
        finally {
            await dispatch(dailyMeasurement_actions.setLoading(false));
        }
    }
}

export const fetchDisplayUnits = ({ IsPMMeasurement = false }) => {
    return async (dispatch) => {
        const getDisplayUnits = async () => {
            const url = 'DailyMeasurement/GetDisplayUnits'

            const res = await fetch(url)
            if (!res.ok) {
                const message = await res.text();
                throw new Error(message);
            }
            const displayUnits = await res.json();
            if (IsPMMeasurement) {
                await dispatch(dailyMeasurement_actions.setPMDisplayUnits(displayUnits));
            } else {
                await dispatch(dailyMeasurement_actions.setDisplayUnits(displayUnits));
            }
        }
        
        try {
            await getDisplayUnits();
        }
        catch (error) {
            dispatch(notification_actions.showModal({ headers: 'Error', message: error.message }));
        }
    }
}

