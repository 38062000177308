import { Fragment, useEffect, useState } from 'react';

// redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { auth_actions } from '../../store/auth-slice';
import { getVersion } from '../../store/version-slice'; // DevOps 2742: Track login/logout - Created 05/17/23

import logo1 from '../../assets/images/logo.png';
import logo2 from '../../assets/images/gastar-logo.png';
import hopeLogo from '../../assets/images/HopeGas.png';

// you will need link and withRouter from react router dom
// import { Link } from 'react-router-dom';

import { Link, NavLink } from 'react-router-dom';
// import Auth from '../../utils/auth';

import BusinessSelector from '../BusinessSelector';

//Modal window:
import Login from '../Login';
import Logout from '../Logout';
import ResetPasswordModal from '../Login/Modals/ResetPasswordModal';

// Custom spinner
import Spinner from '../CustomComponents/Spinner';
import Restricted from '../Restricted';

import './nav-style.css';

export const Nav = () => {
  // redux stuff
  const isLoggedIn = useSelector((state) => state.auth_slice.isLoggedIn);
  const isLoading = useSelector((state) => state.auth_slice.isLoading);
  const versionNumber = useSelector(
    (state) => state.version_slice.versionNumber
  ); // DevOps 2742: Track login/logout - Created 05/17/23
  const [versionNum, setVersionNum] = useState(versionNumber);

  const dispatch = useDispatch();
  const openModal = () => {
    dispatch(auth_actions.showResetPasswordModal(true));
  };

  const fetchVersionNumber = async () => {
    await dispatch(getVersion());
  };
  fetchVersionNumber();

  // useEffects
  useEffect(() => {
    setVersionNum(versionNumber);
  }, [dispatch, versionNumber]);

  return (
    <>
      <nav className="navbar bg-light mb-2">
        <div className="container-fluid">
          <div className="row align-items-start">
            <div className="col-auto">
              {/* Hamburger Menu Icon: */}
              <button
                className="navbar-toggler p-1"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <img
                src={hopeLogo}
                alt="Hope Gas Logo"
                className="img-fluid ms-2"
                style={{ maxHeight: '2.2rem' }}
              />
            </div>
          </div>

          {/*Business Selector on Navbar, hidden if not logged in  */}
          {isLoggedIn && (
            <div className="col ms-5 text-start">
              <BusinessSelector />
            </div>
          )}

          {/* Login/Logout buttons */}
          <div className="row align-items-start">
            {isLoggedIn ? (
              <>
                <div className="col mt-1">
                  <Logout />
                </div>
                <div className="col-lg-5 col mt-1">
                  <button
                    className="btn btn-sm btn-primary fs-6"
                    type="button"
                    // data-bs-toggle="modal"
                    // data-bs-target="#reset-password"
                    aria-controls="reset-password"
                    onClick={openModal}
                  >
                    Reset Password
                  </button>
                </div>
              </>
            ) : (
              <div className="col-auto mt-1">
                {isLoading ? (
                  <Spinner status="loading" />
                ) : (
                  <button
                    className="btn btn-sm btn-primary fs-6"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalToggle"
                  >
                    Login
                  </button>
                )}
              </div>
            )}

            {/* Gastar Logo */}
            <div className="col">
              <img
                src={logo2}
                alt="Gastar Logo"
                className="img-fluid ms-4"
                style={{ maxHeight: '50px' }}
              />
            </div>
            {/* Version Number */}
            <div className="col">
              <h6 className="">Version {versionNum}</h6>
            </div>
          </div>

          {/* Navbar Pull-out */}
          <div
            className="offcanvas offcanvas-start fs-5"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header bg-light">
              <img
                src={logo1}
                alt="nav logo"
                className="img-fluid"
                style={{ maxHeight: '3rem' }}
              />
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                GASTAR Operations Module
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                {/* Weather and Operational Notices */}
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <NavLink
                    className="nav-link active"
                    aria-current="page"
                    to="/notices"
                  >
                    Operational Notices And Alerts
                  </NavLink>
                </li>
                {/* <li className="nav-item"><NavLink className="nav-link" to="/forecast">Weather Forecast & Observed</NavLink></li> */}
                {
                  // Pulldown Menus
                  isLoggedIn && (
                    <Fragment>
                      {/* Supplier Activities */}
                      <Restricted restrictedTo={['RETAIL_CHOICE-ACCESS']}>
                        <li className="nav-item dropdown">
                          <NavLink
                            className="nav-link dropdown-toggle"
                            to="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Supplier Activities
                          </NavLink>
                          <ul className="dropdown-menu">
                            <li data-bs-dismiss="offcanvas">
                              <NavLink
                                tag={Link}
                                className="dropdown-item"
                                to="/customerlistview"
                              >
                                Display Pool Customer Data
                              </NavLink>
                            </li>
                            <li data-bs-dismiss="offcanvas">
                                <NavLink
                                    tag={Link}
                                    className="dropdown-item"
                                    to="/revenue-invoices"
                                >
                                    Invoices
                                </NavLink>
                            </li>
                            {/* HIDDEN PER REQUEST, DEV OPS TICKET 3918, ROY MORAN, 8:25 AM, 1DEC23 */}
                            {/*<li data-bs-dismiss="offcanvas"><NavLink className="dropdown-item" to="/poolNominations">Pool Nominations</NavLink></li>*/}
                            {/*<li data-bs-dismiss="offcanvas"><NavLink className="dropdown-item" to="/dailyPoolReport">Daily Pool Report & Invoice</NavLink></li>*/}
                            {/*<li data-bs-dismiss="offcanvas"><NavLink className="dropdown-item" to="/nonDaily">Non Daily Pool Report & Invoice</NavLink></li>*/}
                            {/*<li data-bs-dismiss="offcanvas"><NavLink className="dropdown-item" to="/imbalance">Imbalance Trade</NavLink></li>*/}
                            {/*<li data-bs-dismiss="offcanvas"><NavLink className="dropdown-item" to="/invoice">Invoice Summary</NavLink></li>*/}
                          </ul>
                        </li>
                      </Restricted>

                      {/* NG Supply & Transportation pulldown menu */}
                      <Restricted restrictedTo={['GAS_SUPPLY-ACCESS']}>
                        <li className="nav-item dropdown">
                          <NavLink
                            className="nav-link dropdown-toggle"
                            to="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            NG Supply & Transportation
                          </NavLink>
                          <ul className="dropdown-menu">
                            {/* blank the below Trade Deals section for official HopeGas releases, place back for internal use and testing. */}
                            <li data-bs-dismiss="offcanvas">
                              <NavLink
                                tag={Link}
                                className="dropdown-item"
                                to="/physicaldeals"
                              >
                                Physical NG PU/SA Deals
                              </NavLink>
                            </li>
                            <li className="dropdown-item">
                              {/* <NavLink to="/financialrisk">Finanacial Trade Deals</NavLink> */}
                              Financial Deals
                              <ul className="circle">
                                <li data-bs-dismiss="offcanvas">
                                  <NavLink
                                    className="dropdown-item"
                                    to="/positionContracts"
                                  >
                                    Hedging Contracts
                                  </NavLink>
                                </li>
                                <li data-bs-dismiss="offcanvas">
                                  <NavLink
                                    className="dropdown-item"
                                    to="/positionInventory"
                                  >
                                    Position Inventory
                                  </NavLink>
                                </li>
                                {/* 
               												 Removed on 10/5/2023 after discussion with Michael, Pooja, and Jaime. - JAL 10/5/2023
               
															 <li data-bs-dismiss="offcanvas"><NavLink className="dropdown-item" to="/options">Options</NavLink></li>
															<li data-bs-dismiss="offcanvas"><NavLink className="dropdown-item" to="/swaps">Swaps</NavLink></li> */}
                                <li>Gain/Loss Report</li>
                              </ul>
                            </li>
                            <li data-bs-dismiss="offcanvas">
                              <NavLink
                                className="dropdown-item"
                                to="/interstateNoms"
                              >
                                Transportation
                              </NavLink>
                            </li>
                            <li data-bs-dismiss="offcanvas">
                              <NavLink
                                className="dropdown-item"
                                to="/storageInventory"
                              >
                                Storage Inventory
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                      </Restricted>

                      {/* GA Noms */}
                      <Restricted restrictedTo={['POOL_OPERATOR-ACCESS']}>
                        <li className="nav-item dropdown">
                          <NavLink
                            className="nav-link dropdown-toggle"
                            to="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Shipper Activities
                          </NavLink>
                          <ul className="dropdown-menu">
                            <li data-bs-dismiss="offcanvas">
                              <NavLink
                                className="dropdown-item"
                                to="/ganominations"
                              >
                                GA Nominations
                              </NavLink>
                            </li>
                            <li data-bs-dismiss="offcanvas">
                              <NavLink
                                className="dropdown-item"
                                to="/poolbalance"
                              >
                                Pool Balance
                              </NavLink>
                            </li>
                            <li data-bs-dismiss="offcanvas">
                              <NavLink
                                tag={Link}
                                className="dropdown-item"
                                to="/whpurchase "
                              >
                                WH Purchase
                              </NavLink>
                            </li>
                            <li data-bs-dismiss="offcanvas">
                              <NavLink
                                className="dropdown-item"
                                to="/measurement"
                              >
                                Measurement
                              </NavLink>
                            </li>
                            <li data-bs-dismiss="offcanvas">
                              <NavLink
                                className="dropdown-item"
                                to="/production-report"
                              >
                                Production Report
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                      </Restricted>

                      {/* Contact Setup */}
                      <Restricted restrictedTo={['ADMIN-ACCESS']}>
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                          <NavLink
                            className="nav-link active"
                            aria-current="page"
                            to="/supplier"
                          >
                            Supplier/Marketer Contact List
                          </NavLink>
                        </li>
                      </Restricted>
                    </Fragment>
                  )
                }
              </ul>
            </div>
          </div>
        </div>
        {/* Login Modals */}
        <Login />
        <ResetPasswordModal />
      </nav>
    </>
  );
};

export default Nav;
