// redux
import { useSelector, useDispatch } from 'react-redux';
import { auth_actions } from '../../../store/auth-slice';

// react modal
import Modal from 'react-modal';

// images
import logo1 from '../../../assets/images/gastar-logo.png'


Modal.setAppElement('#root');

const PasswordResetSuccessModal = () => {

	// Redux
	const showPasswordResetSuccess = useSelector(state => state.auth_slice.showPasswordResetSuccess);
	const dispatch = useDispatch();

	// Handlers
	const closeModal = () => {
		dispatch(auth_actions.setShowPasswordResetSuccess(false));
	};

	// Modal styling
	const customStyles = {
		overlay: {
			backgroundColor: 'rgb(0,0,0,.60'
		},
	};



	return (



		<Modal
			isOpen={showPasswordResetSuccess}
			contentLabel='change-password'
			style={customStyles}
			className="Modal"
			id="pass-change-complete"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header text-center">
						<h1 className="fs-5 text-center" >Password has been reset!</h1>
						<button
							type="button"
							className="btn-close"
							aria-label="Close"
							onClick={closeModal}
						>
						</button>
					</div>
					<form>
						<div className="modal-body justify-content-center text-center fs-5">
							<img src={logo1} alt='Gastar Logo' className='img-fluid mb-4' style={{ maxWidth: '200px' }} />
							<br />
							<h2 className='fs-3'>Password has been reset!</h2>
						</div>
						<div className="modal-footer">
							<button
								type='button'
								className='btn btn-lg btn-primary'
								onClick={closeModal}
							>
								Close
							</button>
						</div>
					</form>
				</div>
			</div>

		</Modal>

	);

};

export default PasswordResetSuccessModal;
