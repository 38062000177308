
import customerRpt from '../../../../assets/images/CustomerReports.png'
import { measurement_actions } from '../../../../store/measurement-slice';
import React from "react";
import ReportViewer from "./ReportViewer"
import { useSelector, useDispatch } from 'react-redux';

const customStyles = {
	overlay: {
		backgroundColor: 'rgb(0,0,0,.60)'
	},
};

const Export = ({ onExport }) => (
    <button className="btn btn-md btn-primary me-2 mb-1" onClick={() => onExport()}>
        <img src={customerRpt} alt="Export PDF Report" /> Agreement Balancing
    </button>
);

const ExportPDF = ({ month, contracts, ba, bu, gasAllocationData, poolBalanceData, user }) => {
    const dispatch = useDispatch();
    const reportViewerOpen = useSelector(state => state.measurement.reportViewerOpen)
    const openReportViewerModal = () => {
        dispatch(measurement_actions.setOpenReportViewer(true))
    }
    
    return (
        <>
            <Export onExport={() => openReportViewerModal()} />
            {reportViewerOpen && <ReportViewer style={customStyles} 
                month={month} 
                contracts={contracts} 
                ba={ba} 
                bu={bu} 
                gasAllocationData={gasAllocationData}
                poolBalanceData={poolBalanceData}
                user={user}
            />}
        </>
    );
};

export default ExportPDF;