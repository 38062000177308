
const LoginForm = ({
	loginState,
	setLoginState,
	passwordVisible,
}) => {

	// handlers
	const stateChangeHandler = (event) => {

		const newValue = event.target.value;
		const property = event.target.getAttribute('property');

		let newLoginState = { ...loginState };

		newLoginState[property] = newValue;

		setLoginState(newLoginState);

	};

	return (
		<div>
			{/* User ID */}
			<label htmlFor='userID'>User ID:</label>
			<input
				className="form-control"
				type='text'
				id='userID'
				property='user_id'
				required
				onChange={stateChangeHandler}
				value={loginState.user_id}
			/>
			{/* Password */}
			<label htmlFor='password'>Password:</label>
			<input
				className="form-control"
				type={passwordVisible ? "text" : "password"}
				id='password'
				property='password'
				required
				onChange={stateChangeHandler}
				value={loginState.password}
			/>
		</div>
	);

};

export default LoginForm;