/**
 * DevOps 2742: Track login/logout
 * Created: 05/17/23
 * New item to request EBB version number from backend
 */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    versionNumber: sessionStorage.getItem('versionNumber') ? sessionStorage.getItem('versionNumber') : ''
}

const version_slice = createSlice({
    name: 'version_slice',
    initialState,
    reducers: {
        setVersion(state, action) {
            state.versionNumber = action.payload;
            sessionStorage.setItem('versionNumber', action.payload)
        }
    }
});

export const getVersion = () => {
    return async (dispatch) => {
        if (sessionStorage.getItem('versionNumber')) {
            return;
        }

        const fetchVersion = async () => {
            const response = await fetch('Version', {
                method: 'GET'
            });
            if (!response.ok) {
                const message = await response.text();
				throw new Error(message);
            }
            
            const result = await response.json();
            const versionStr = result.version;

            return versionStr;
        }

        try {
            const versionStr = await fetchVersion();
            await dispatch(version_actions.setVersion(versionStr));

        }
        catch (error) {
            console.log(error.message);
        }
    }
}

export const version_actions = version_slice.actions;
export default version_slice.reducer;

