import React, {useEffect, useState } from "react";
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';

import "./style.css";

import Spinner2 from "../CustomComponents/Spinner2";
import Table from "./Table";

import { fetchDailyMeasurement, fetchDisplayUnits } from "../../store/daily-measurement-thunks";
import { toDatePickerString, getMonthEnd } from "../../utils/DateUtils";
import ExportExcel from "./ExportExcel";

const customStyles = {
	overlay: {
		backgroundColor: 'rgb(0,0,0,.60)'
	},
};

function DailyMeasurement({openModal, setOpenModal, passingParams}) {
    // Redux and States
    const dispatch = useDispatch();

    const selectedContractWHStl = useSelector(state => state.whPurschase.selectedContractWHStl);
    
    const displayUnits = useSelector(state => state.dailyMeasurement.displayUnits);
    const loading = useSelector(state => state.dailyMeasurement.loading);
    const ptNum = useSelector(state => state.dailyMeasurement.ptNum);
    const dailyMeasurementDataTable = useSelector(state => state.dailyMeasurement.dailyMeasurementDataTable);
    
    const [fromGasFlowDate, setFromGasFlowDate] = useState(new Date(passingParams?.fromGasFlowDate));
    const [toGasFlowDate, setToGasFlowDate] = useState(getMonthEnd(passingParams?.fromGasFlowDate));
    const [displayUnit, setDisplayUnit] = useState(displayUnits.length > 0 ? displayUnits[0] : {Key: '', Value: ''});
    const [lastDailyMeasurementItem, setLastDailyMeasurementItem] = useState({});
    const [isDTH, setIsDTH] = useState(true);

    // Use Effects
    useEffect( () => {
        dispatch(fetchDisplayUnits({ IsPMMeasurement: false }));
    }, [dispatch])

    useEffect( () => {
        setFromGasFlowDate(new Date(passingParams?.fromGasFlowDate));
        setToGasFlowDate(getMonthEnd(passingParams?.fromGasFlowDate));
    }, [passingParams])

    useEffect( () => {
        if (displayUnits.length > 0) setDisplayUnit(displayUnits[0]);
    }, [displayUnits])
    
    useEffect( () => {        
        dispatch(fetchDailyMeasurement({
            buId: passingParams.businessUnit?.BA_ID,
            baId: passingParams.selectedSupplier?.BA_ID,
            pointId: JSON.stringify(selectedContractWHStl) === "{}" ? '' : selectedContractWHStl?.PointShortName,
            fromGasFlowDate: fromGasFlowDate,
            toGasFlowDate: toGasFlowDate, 
        }));
    }, [dispatch, passingParams.businessUnit?.BA_ID, passingParams.selectedSupplier?.BA_ID, selectedContractWHStl, fromGasFlowDate, toGasFlowDate]);

    useEffect( () => {
        if (dailyMeasurementDataTable.length > 0) {
            setLastDailyMeasurementItem(dailyMeasurementDataTable[dailyMeasurementDataTable.length - 1]);
        }
        else {
            setLastDailyMeasurementItem({})
        }
    }, [dailyMeasurementDataTable]);

    // Handlers
    const selectedFromGasFlowDateChange = (event) => {
        if (!event.target.value) return;
        setFromGasFlowDate(event.target.value);
        setToGasFlowDate(getMonthEnd(event.target.value));
    }

    const selectedToGasFlowDateChange = (event) => {
        if (!event.target.value) return;
        setToGasFlowDate(event.target.value);
    }

    const selectedDisplayUnitChange = (event) => {
        if (!event.target.value) return;

        let selectedDisplayUnitValue = event.target.value;
        let selectedDisplayUnit = displayUnits.find(u =>  u.Value === selectedDisplayUnitValue);
        setDisplayUnit(selectedDisplayUnit);
        
        if (selectedDisplayUnit?.Key === "MC") setIsDTH(true);
        else setIsDTH(false);
    }

    return (
        <>
            <Modal
                isOpen={openModal}
                onRequestClose={() => setOpenModal(false)}
                className="daily-modal container-fluid bg-white text-black"
                style={customStyles}
            >
                <div id="DailyMeasurementModal" tabIndex='-1'>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title mb-2">Daily Measurement UI</h4>
                                <button
                                    type="button"
                                    className="btn-close fs-6"
                                    onClick={() => setOpenModal(false)}
                                />
                            </div>
                            <div className="row ">
                                <div className="col-sm-12 col-md-12 col-lg-12 mt-0">
                                    <ExportExcel data={dailyMeasurementDataTable} isDTH={isDTH} />
                                </div>
                            </div>
                            <div className="modal-toolbar">
                                <div className="text-start bg-light-blue rounded m-0 p-2">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">Pipeline</label>
                                            <input
                                                className="form-control form-control-sm"
                                                aria-label="select public or private"
                                                value={passingParams.selectedSupplier?.NAME}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">Business Unit</label>
                                            <input
                                                className="form-control form-control-sm"
                                                value={passingParams.businessUnit?.NAME}
                                                aria-label="select public or private"
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">Point Name</label>
                                            <input
                                                className="form-control form-control-sm"
                                                aria-label="select public or private"
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">Point Number</label>
                                            <input
                                                className="form-control form-control-sm"
                                                aria-label="select public or private"
                                                value={loading === true ? 'Loading...' : ptNum}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">MIDNO #</label>
                                            <input
                                                className="form-control form-control-sm"
                                                aria-label="select public or private"
                                                value={selectedContractWHStl?.PointShortName}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">Start Date</label>
                                            <input
                                                name="startDate"
                                                className="form-control form-control-sm"
                                                type="date"
                                                onChange={selectedFromGasFlowDateChange}
                                                value={toDatePickerString(fromGasFlowDate)}
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">End Date</label>
                                            <input
                                                name="endDate"
                                                className="form-control form-control-sm"
                                                type="date"
                                                onChange={selectedToGasFlowDateChange}
                                                value={toDatePickerString(toGasFlowDate)}
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">Account #</label>
                                            <input
                                                className="form-control form-control-sm"
                                                aria-label="select public or private"
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">Display Units</label>
                                            <select
                                                className="form-select form-select-sm"
                                                type="form-select"
                                                onChange={selectedDisplayUnitChange}
                                                value={displayUnit.Value}
                                            >
                                                {
                                                    displayUnits?.map(displayUnit => {
                                                        return (
                                                            <option
                                                                key={displayUnit.Key}
                                                                value={displayUnit.value}
                                                            >
                                                                {displayUnit.Value}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">Total Energy</label>
                                            <input
                                                className="form-control form-control-sm"
                                                aria-label="select public or private"
                                                value={loading === true ? "" : lastDailyMeasurementItem?.CUMENERGY?.toLocaleString()}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">Total Vol</label>
                                            <input
                                                className="form-control form-control-sm"
                                                aria-label="select public or private"
                                                value={
                                                    loading === true ? "" :
                                                        isDTH && lastDailyMeasurementItem.CUMDAILYVOL 
                                                        ? (lastDailyMeasurementItem.CUMDAILYVOL / 10).toLocaleString() 
                                                        : lastDailyMeasurementItem.CUMDAILYVOL?.toLocaleString()
                                                    }
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                {loading === true ? <Spinner2/> : <Table GasMeters={dailyMeasurementDataTable} isDTH={isDTH}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default DailyMeasurement;