const CustomStyles = {
    table: {
        style: {
            // fontSize: "1.2rem",
            border: '1px solid blue',
            striped: 'default',

        },
    },
    headRow: {
        style: {
            position: 'sticky',
            top: '0px',
            zIndex: 1,
            fontSize: ".9rem",
            paddingLeft: '0px',
        },
    },
    rows: {
        // style: {

        //     when: row => row.INFO_POSTING_TYPE === 'CT',
        //     style: row => ({ backgroundColor: row.isSpecial ? 'pink' : 'green' }),

        // }
    },
    cells: {
        style: {
            fontSize: '.9rem',
            border: '1px solid blue',
            paddingLeft: '1px'
        }
    },
    critical: {
        style: {
            color: 'red',
        }
    },
    nonCritical: {
        style: {
            color: 'black',
        }
    }
}

export default CustomStyles