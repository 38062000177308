import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { non_path_noms_actions } from '../store/non-path-noms-slice';
import { getDayStart, toISOStringForDatabase } from '../utils/DateUtils';
import { getBalanceInfo, getFormDataFromContract, getFormDataFromPackage, getLatestApprovalDate } from '../utils/NonPathModelNomUtils';

const useNonPathFormUpdate = (form) => {
	// Redux
	const selectedPackage = useSelector(state => state.non_path_noms_slice.selectedPackage);
	const { selectedContract, gasFlowDate } = useSelector(state => state.non_path_noms_slice.NonPathNomsFilters);
	const shouldResetForm = useSelector(state => state.non_path_noms_slice.shouldResetForm);
	const selectedPkgPathInfoObj = useSelector(state => state.non_path_noms_slice.selectedPkgPathInfoObj);
	const dispatch = useDispatch();

	// Initialize form values based on either selected package or selected contract.
	useEffect(() => {
		const resetForm = async () => {
			// Get and set latest approval date for controlling whether a user can edit a package or not.
			const newLatestApprovalDate = await getLatestApprovalDate();
			await dispatch(non_path_noms_actions.setLatestApprovalDate(toISOStringForDatabase(newLatestApprovalDate)));

			if ((selectedPackage?.PACKAGE_NUM || selectedPackage?.isCopy) && shouldResetForm) {
				// Determine if user can edit this package.
				const canEditPackage = getDayStart(selectedPackage?.STARTDATE).getTime() >= getDayStart(newLatestApprovalDate).getTime();
				await dispatch(non_path_noms_actions.setCanEditPackage(canEditPackage));

				// Set form based on selected package.
				form.reset(getFormDataFromPackage(selectedPackage, gasFlowDate));
				await dispatch(non_path_noms_actions.setShouldResetForm(false));
			}
			else if (selectedContract?.CNTR_NUM && shouldResetForm) {
				// Allow user to edit new package.
				await dispatch(non_path_noms_actions.setCanEditPackage(true));

				// Set form based on selected contract.
				form.reset(getFormDataFromContract(selectedContract));
				await dispatch(non_path_noms_actions.setShouldResetForm(false));
			}
		};
		resetForm();
	}, [form, selectedPackage, selectedContract, shouldResetForm, dispatch]);

	// Any time the selected selectedPkgPathInfoObj is updated, update the form as well.
	useEffect(() => {
		if (!selectedPkgPathInfoObj) return;

		const prevPkgPathInfoObjs = form.getValues('pkgPathInfoObjs');

		const rcvPaths = prevPkgPathInfoObjs?.filter(pkgPathInfoObj => pkgPathInfoObj.PT_DIR_FLOW === 'R');
		const dlvPaths = prevPkgPathInfoObjs?.filter(pkgPathInfoObj => pkgPathInfoObj.PT_DIR_FLOW === 'D');

		// We should auto populate if there is exactly one receive path and one deliver path.
		let shouldAutoPopulate = false;
		if (rcvPaths?.length === 1 && dlvPaths?.length === 1)
			shouldAutoPopulate = true;

		// Update package path info objects.
		const newPkgPathInfoObjs = prevPkgPathInfoObjs?.map(prevPkgPathInfoObj => {
			// If Point ID matches, return all updated info.
			if (prevPkgPathInfoObj?.PT_NUM_OBJ?.POINT_ID === selectedPkgPathInfoObj?.PT_NUM_OBJ?.POINT_ID)
				return selectedPkgPathInfoObj;

			// Else if we should auto populate the other path, return the path's previous info, 
			// but with the updated volume information, and updated shipper info.
			if (shouldAutoPopulate) {
				return {
					...prevPkgPathInfoObj,
					NOM_RCV_VOL: selectedPkgPathInfoObj?.NOM_RCV_VOL,
					NOM_DLV_VOL: selectedPkgPathInfoObj?.NOM_DLV_VOL,
					FUEL_PERCENT_DB: selectedPkgPathInfoObj?.FUEL_PERCENT_DB,
					FUEL: selectedPkgPathInfoObj?.FUEL,
					UP_SHIPPER_DUNS: selectedPkgPathInfoObj?.UP_SHIPPER_DUNS,
					DN_SHIPPER_DUNS: selectedPkgPathInfoObj?.DN_SHIPPER_DUNS
				};
			}

			// Else, return previous info, but with updated shipper info.
			return {
				...prevPkgPathInfoObj,
				UP_SHIPPER_DUNS: selectedPkgPathInfoObj?.UP_SHIPPER_DUNS,
				DN_SHIPPER_DUNS: selectedPkgPathInfoObj?.DN_SHIPPER_DUNS
			};
		});

		// Calculate balance info
		const { totalRcvQty, totalDlvQty, totalFuel, availQty } = getBalanceInfo(newPkgPathInfoObjs);

		// Set new values.
		form.setValue('totalRcvQty', totalRcvQty);
		form.setValue('totalDlvQty', totalDlvQty);
		form.setValue('totalFuel', totalFuel);
		form.setValue('availQty', availQty);
		form.setValue('pkgPathInfoObjs', newPkgPathInfoObjs, { shouldDirty: true, shouldValidate: form.formState?.isSubmitted, shouldTouch: true });
	}, [selectedPkgPathInfoObj, form]);
};

export default useNonPathFormUpdate;