import { useEffect, useState, useCallback } from "react";

import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
    createHedgingContract,
    fetchAllHedgeContracts, resetNewContract, updateHedgingContract,
    updateNewContract, deleteByCntrNum, resetFilters,
    fetchAllCodes
} from '../../../store/hedging-slice';

import { fetchAllFuturePrice } from '../../../store/physical-trade-deals-thunks';

import Spinner2 from '../../CustomComponents/Spinner2';
import Table from "./Table";
// import NewContractModal from "./NewContractModal";

import { formatDateTime } from '../../../utils/DateUtils';

//Images for buttons:
import newBtn from '../../../assets/images/add.gif';
import saveBtn from '../../../assets/images/save.png';
import deleteBtn from '../../../assets/images/delete.png';
import sweepBtn from '../../../assets/images/clear.png'


function HedgeCodes() {


    //State:
    const [toDeleteCntrNum, setToDeleteCntrNum] = useState(null);
    const [isNewContract, setIsNewContract] = useState(true);
    const [loading, setLoading] = useState(false);


    //Redux code:
    const dispatch = useDispatch();
    const newContract = useSelector((state) => state.hedging.newContract);
    const hedgeContracts = useSelector((state) => state.hedging.contracts);
    const codes = useSelector((state) => state.hedging.codesData);
    const futurePrice = useSelector((state) => state.physical_trade_deals_slice.futurePrice);


    // console.log('codes coming in on front end:', codes);

    // Code Text helper function:
    const getCodeText = useCallback((cde, cdeTxt) => {
        const typeCodes = codes[cde];
        if (!typeCodes) return null;

        const codeInfo = typeCodes.find(code =>
            code.CDE_TXT === cdeTxt && Number(code.SORT_BY) > 0
        );

        return codeInfo ? codeInfo.CDE : null;
    }, [codes]);



    // State to keep track of the ContractName and generate it based on selections:
    const [contractName, setContractName] = useState();

    useEffect(() => {
        const generateContractName = () => {
            // console logs to debug
            // console.log('newContract.Location1:', newContract.Location1);
            // console.log('codes[HTRAN]:', codes['HTRAN']);

            let contractType = getCodeText('HEDGE', newContract.ContractType) || newContract.ContractType;
            let contractAsset = getCodeText('FASET', newContract.Asset1) || newContract.Asset1;
            let locationReference = getCodeText('HLOC', newContract.Location1) || newContract.Location1;

            // debugging
            // console.log('locationReference after getCodeText:', locationReference);

            if (contractAsset && contractType && locationReference) {
                const generatedName = `${contractAsset}:${contractType}:${locationReference}`;
                setContractName(generatedName);
                dispatch(updateNewContract({ 'ContractName': generatedName }));
            }
        };

        // Do Not delete this code - JAL - 9/27/2021
        // const generateContractName = () => {

        //     let contractType = newContract.ContractType;
        //     let contractAsset = newContract.Asset1;
        //     let locationReference = newContract.Location1;

        //     // Use the mapping to replace specific entries
        //     locationReference = locationMappings[locationReference] || locationReference;
        //     contractType = contractTypeMappings[contractType] || contractType;
        //     contractAsset = contractAssetMappings[contractAsset] || contractAsset;

        //     // console.log("Inside useEffect: ", contractAsset, contractType, locationReference);

        //     if (contractAsset && contractType && locationReference) {
        //         const generatedName = `${contractAsset}:${contractType}:${locationReference}`;
        //         setContractName(generatedName);
        //         dispatch(updateNewContract({ 'ContractName': generatedName }));
        //     }
        // };

        generateContractName();
    }, [newContract.Asset1, newContract.ContractType, newContract.Location1, getCodeText, dispatch]);


    // Fetch all hedge contracts on page load
    useEffect(() => {
        setLoading(true); // Start loading
        dispatch(fetchAllHedgeContracts(filter))
            .unwrap()
            .then(() => {
                setLoading(false); // End loading
            })
            .catch((error) => {
                console.error("Failed to fetch the contracts", error);
                setLoading(false); // End loading
            });
    }, [dispatch]);

    // Fetch all codes and future pricing on page load
    useEffect(() => {
        setLoading(true); // Start loading
        dispatch(fetchAllCodes())
        dispatch(fetchAllFuturePrice())
    }, [dispatch]);

    // console.log('futurePrice coming in on front end:', futurePrice)



    //Version 2:
    //Handle form submit
    const handleSubmit = (e) => {
        e.preventDefault();

        // Create or update contract, then refetch all contracts applying the current filters
        const handleContractSuccess = (payload) => {
            console.log("Contract operation was successful", payload);

            // Update 'newContract' to have the new 'CntrNum' if a new contract was created
            if (isNewContract) {
                dispatch(updateNewContract(payload));
            }

            // Refresh all contracts based on current filters
            dispatch(fetchAllHedgeContracts());

            setIsNewContract(true);  // Reset back to "new contract" mode
            dispatch(resetNewContract());  // Reset new contract state
            setFilter(initialFilterState); // Resets to table showing all contracts
        };

        // Handle API operation failure
        const handleContractFailure = (error) => {
            console.error("Failed to perform the contract operation", error);
        };

        if (isNewContract) {
            // Create a new contract
            dispatch(createHedgingContract(newContract))
                .unwrap()
                .then(handleContractSuccess)
                .catch(handleContractFailure);

            // Reset filters if necessary
            dispatch(resetFilters());
        } else {
            // Update existing contract
            dispatch(updateHedgingContract(newContract))
                .unwrap()
                .then(handleContractSuccess)
                .catch(handleContractFailure);
        }
    };




    //Handle form field changes
    const handleChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        const selectedValue = e.target.value === "Select All" ? "Select All" : e.target.value;

        // Check if the changed field is Units1 and the selected value is DTH
        if (name === "Units1" && value === "DTH") {
            // Set the Asset1SpotPriceExpression with DEFAULT_PVOL
            const defaultPVol = codes && codes.HUOM && codes.HUOM[0]?.DEFAULT_PVOL;
            dispatch(updateNewContract({ Asset1SpotPriceExpression: defaultPVol }));
        }

        // If the changed field is UnitsAmount1, convert it to an integer
        if (name === "UnitsAmount1") {
            value = parseInt(value, 10);

            // Handling NaN in case the conversion fails
            if (isNaN(value)) {
                console.error('Please enter a valid number for UnitsAmount1');
                return;
            }
        }

        setFilter({
            ...filter,
            [name]: selectedValue,
        });
        // make sure to dispatch the action and pass in a payload.
        dispatch(updateNewContract({ [name]: selectedValue, value }));

    };


    // Set filter state when user clicks on table row:
    const initialFilterState = {

        ContractType: '',
        Asset1: '',
        Location1: '',
        PriceExpression: '',
        CntrNum: '',
        ContractName: '',
        Description: '',
        Units1: '',
        UnitsAmount1: '',
        Asset1ForwardPriceExpression: '',
        Asset1SpotPriceExpression: '',
        Status: '',

    };

    const [selectedRow, setSelectedRow] = useState();
    const [filter, setFilter] = useState(initialFilterState);  // initialFilterState could be your default state for filters
    // console.log("Current filter:", filter)
    const [showFields, setShowFields] = useState(false);


    // Fetch all hedge contracts based on filters
    useEffect(() => {
        setLoading(true);
        // Consider renaming this to fetchFilteredHedgeContracts and passing filters
        dispatch(fetchAllHedgeContracts(filter))
            .unwrap()
            .then(() => setLoading(false))
            .catch((error) => {
                console.error("Failed to fetch the contracts", error);
                setLoading(false);
            });
    }, [dispatch, filter]); // Include filter as a dependency


    // Handle row click for table
    const handleRowClick = (clickedRow) => {
        // console.log("Clicked Row:", clickedRow);
        const cntrNum = clickedRow.CntrNum;
        setShowFields(true);

        setIsNewContract(false);

        setFilter({
            ContractType: clickedRow.ContractType,
            Asset1: clickedRow.Asset1,
            Location1: clickedRow.Location1,
            ContractName: clickedRow.ContractName,
            PriceExpression: clickedRow.PriceExpression,
            Units1: clickedRow.Units1,
            UnitsAmount1: clickedRow.UnitsAmount1,
            CntrNum: clickedRow.CntrNum,
            Description: clickedRow.Description,
            Asset1ForwardPriceExpression: clickedRow.Asset1ForwardPriceExpression,
            Status: clickedRow.Status,

        });

        setSelectedRow(clickedRow);

        dispatch(updateNewContract({

            Asset1: clickedRow.Asset1,
            ContractName: clickedRow.ContractName,
            Description: clickedRow.Description,
            ContractType: clickedRow.ContractType,
            Location1: clickedRow.Location1,
            UnitsAmount1: clickedRow.UnitsAmount1,
            Units1: clickedRow.Units1,
            PriceExpression: clickedRow.PriceExpression,
            CntrNum: clickedRow.CntrNum,
            Asset1ForwardPriceExpression: clickedRow.Asset1ForwardPriceExpression,
            Asset1SpotPriceExpression: clickedRow.Asset1SpotPriceExpression,
            Status: clickedRow.Status,
            ...clickedRow,


        }));

        setSelectedRow(clickedRow);
        setToDeleteCntrNum(cntrNum);

    };


    //Sweep contract and return filters:
    const sweepContract = () => {
        setFilter(initialFilterState);
        setSelectedRow(null);
        setShowFields(false);
        dispatch(resetNewContract(true));
        setIsNewContract(true);
        dispatch(fetchAllHedgeContracts());  // This should fetch according to the reset filters
    };


    // Handle delete
    const handleDeleteSubmit = (e) => {
        e.preventDefault();
        if (toDeleteCntrNum == null) {
            console.error("No contract number set for deletion");
            return;
        }

        dispatch(deleteByCntrNum(toDeleteCntrNum))
            .unwrap()
            .then((payload) => {
                console.log("Deleted successfully", payload);
                // Optionally refresh all contracts after a successful delete

                setShowFields(false);
                dispatch(resetNewContract());
                setIsNewContract(true);
                dispatch(fetchAllHedgeContracts());
                setFilter(initialFilterState);
            })
            .catch((error) => {
                console.error("Failed to delete the contract", error);
                if (error.response) {
                    console.error('Status:', error.response.status);
                    console.error('Data:', error.response.data);
                    console.error('Headers:', error.response.headers);
                }

                dispatch(resetNewContract());
                setIsNewContract(true);
                dispatch(fetchAllHedgeContracts());
            });

    };

    return (

        <div className='container-fluid'>

            <form onSubmit={handleSubmit}>

                <div className="text-black">
                    <div className='card mt-3 border-0'>
                        <div className='card-header text-center bg-primary text-white m-0'><h4>Hedge Contract Setup and Maintenance</h4></div>
                        <div className='card-body'>

                            <div className="row">

                                <div className="col-sm-12 col-md-12 col-lg-12 p-1">

                                    <button type="button" className='btn btn-md btn-primary me-2' onClick={() => sweepContract()}>
                                        <img src={newBtn} alt='New Trade' /> New Contract
                                    </button>

                                    {/* <button type="button" className='btn btn-md btn-primary me-2' onClick={handleOpenNewContractModal}>
                                        <img src={newBtn} alt='New Trade' /> New Contract
                                    </button> */}

                                    <button className='btn btn-md btn-primary me-2'><img src={saveBtn} alt='New Trade' type="submit" onClick={handleSubmit}
                                    /> Save Contract</button>
                                    {/* Removed delete button - 10/09/2023 - JAL */}
                                    <button className='btn btn-md btn-primary me-2' onClick={handleDeleteSubmit}>
                                        <img src={deleteBtn} alt='Delete Contract' /> Delete Contract
                                    </button>
                                    <button className='btn btn-md btn-primary me-2' type="button" onClick={() => sweepContract()} ><img src={sweepBtn} alt='New Trade' /> Clear Contract</button>
                                </div>
                            </div>
                            {/* FILTERS section */}
                            <div className='row bg-light-blue rounded'>
                                < div className='row p-1'>
                                    <h6>Display Filters</h6>

                                    <div className="col-lg-2 col-md-2 col-sm-3 text-start">
                                        <label className='form-label m-0'>Contract ID: </label>
                                        <input
                                            className="form-control form-control-sm col-lg-1 col-sm-3 text-start"
                                            aria-label="Contract ID"
                                            type="text"
                                            name="ContractName"
                                            value={newContract.ContractName}
                                            onChange={handleChange}
                                            disabled
                                        >
                                        </input>
                                    </div>

                                    <div className="col-lg-2 col-md-2 col-sm-3 text-start">
                                        <label className='form-label m-0'>Contract Title: </label>
                                        <input className="form-control form-control-sm" aria-label="Contract Title"
                                            type="text"
                                            name="Description"
                                            value={newContract.Description}
                                            onChange={handleChange}

                                        >

                                        </input>

                                    </div>

                                    <div className="col-lg-2 col-sm-3 text-start">
                                        <label className='form-label m-0'>Status: </label>
                                        <select className="form-select form-select-sm col-lg-1 col-sm-3 text-start" aria-label="Default select example"
                                            name="Status"
                                            value={newContract.Status}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select Status</option>
                                            {codes && codes.HTRAN && codes.HTRAN.length > 0 && codes.HTRAN
                                                .map((code, index) => (
                                                    <option key={index} value={code.CDE}>
                                                        {code.CDE_TXT}
                                                    </option>
                                                ))
                                            }
                                            {/* <option value='Select Status'>Select All</option>
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option> */}
                                        </select>
                                    </div>
                                    {showFields && (
                                        <div className="col-lg-2 col-sm-3 text-start">
                                            <label className='form-label m-0'>Sys ID: </label>


                                            <input
                                                className="form-control form-control-sm col-lg-1 col-sm-3 text-start"
                                                aria-label="Default select example"
                                                value={newContract.CntrNum}
                                                disabled
                                            />

                                        </div>
                                    )}
                                </div>
                                <div className="row p-1">


                                    <div className="col-lg-2 col-md-2 col-sm-3">
                                        <label className="form-check-label text-start">Asset:</label>
                                        <select
                                            className="form-select form-select-sm col-lg-1 col-sm-3 text-start"
                                            aria-label="Asset1"
                                            name="Asset1"
                                            onChange={handleChange}
                                            value={newContract.Asset1}
                                        >
                                            <option value="">Select Asset</option>
                                            {codes && codes.FASET && codes.FASET.length > 0 && codes.FASET
                                                .filter(code => code.SORT_BY > 0)
                                                .map((code, index) => (
                                                    <option key={index} value={code.CDE}>
                                                        {code.CDE_TXT}
                                                    </option>
                                                ))
                                            }
                                        </select>

                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-3 text-start">
                                        <label className="form-check-label text-start">Contract Types:</label>
                                        <select
                                            className="form-select form-select-sm col-lg-1 col-sm-3 text-start"
                                            aria-label="Contract Types"

                                            name="ContractType"
                                            onChange={handleChange}
                                            value={newContract.ContractType}
                                        >
                                            <option value="">Select Type</option>
                                            {codes && codes.HEDGE && codes.HEDGE.length > 0 && codes.HEDGE
                                                .filter(code => code.SORT_BY !== 0)
                                                .map((code, index) => (
                                                    <option key={index} value={code.CDE}>
                                                        {code.CDE_TXT}
                                                    </option>
                                                ))
                                            }
                                            {/* <option value='Select All'>Select All</option>
                                            <option value="FU">Futures</option>
                                            <option value="OPT">Option-American Type</option> */}
                                        </select>

                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3">
                                        <label className="form-check-label text-start">Location Reference:</label>

                                        <select className="form-select form-select-sm col-lg-3 col-md-3 col-sm-3 text-start" aria-label="Default select example"
                                            name="Location1"
                                            value={newContract.Location1}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select Location</option>
                                            {codes && codes.HLOC && codes.HLOC.length > 0 && codes.HLOC
                                                .map((code, index) => (
                                                    <option key={index} value={code.CDE}>
                                                        {code.CDE_TXT}
                                                    </option>
                                                ))
                                            }
                                            {/* <option>Select All</option>
                                            <option value="HH">Henry Hub</option>
                                            <option value="DSP">Dominion South Point</option> */}

                                        </select>
                                    </div>

                                </div>
                                <div className="row p-1">
                                    <div className="col-lg-2 col-md-2 col-sm-3 text-start">
                                        <label className='form-label m-0'>Unit Type: </label>
                                        <select className="form-select form-select-sm col-lg-1 col-sm-3 text-start" aria-label="Unit Type"

                                            name="Units1"
                                            value={newContract.Units1
                                            }
                                            onChange={handleChange}
                                        >
                                            <option value="">Select Unit Type</option>
                                            {codes && codes.HUOM && codes.HUOM.length > 0 && codes.HUOM
                                                .map((code, index) => (
                                                    <option key={index} value={code.CDE}>
                                                        {code.CDE_TXT}
                                                    </option>
                                                ))
                                            }
                                            {/* <option value="">Select MTM Valuation</option>
                                            <option
                                                value="NYM"
                                            >NYMEX</option> */}

                                        </select>

                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-3 text-start">
                                        <label className='form-label m-0'>Quantity/Day: </label>
                                        <input className="form-control form-control-sm"
                                            aria-label="PriceUnits"

                                            type="text"
                                            name="Asset1SpotPriceExpression"
                                            value={newContract.Units1 === 'DTH' ? (codes && codes.HUOM && codes.HUOM[0]?.DEFAULT_PVOL) : ''}
                                            onChange={handleChange}
                                            disabled
                                        >
                                        </input>

                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-3 text-start">
                                        <label className='form-label m-0'>Units: </label>
                                        <input className="form-control form-control-sm"
                                            aria-label="Units"

                                            type="text"
                                            name="UnitsAmount1"
                                            value={newContract.UnitsAmount1}
                                            onChange={handleChange}
                                        >
                                        </input>

                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-3">
                                        <label className="form-check-label text-start">M2M Price Ref:</label>

                                        <select className="form-select form-select-sm col-lg-2 col-md-2 col-sm-3 text-start" aria-label="Default select example"
                                            type="text"
                                            value={newContract.PriceExpression}
                                            onChange={handleChange}
                                            name="PriceExpression"
                                        >
                                            <option value="">Forward Index</option>
                                            {futurePrice && futurePrice.length > 0 && futurePrice
                                                .map((priceObj, index) => (
                                                    <option key={index} value={priceObj.FUTURE_PRICE_ID}>
                                                        {priceObj.FUTURE_PRICE_NAME}
                                                    </option>
                                                ))
                                            }
                                        </select>



                                    </div>

                                    {/* <div className="col-lg-2 col-md-2 col-sm-3 text-start">
                                        <label className='form-label m-0'>Units/K: </label>
                                        {/* <input className="form-control form-control-sm" value={selectedContract?.unitsK || ''}></input> 
                                    </div> */}

                                    {/* <div className="col-lg-2 col-md-2 col-sm-3 text-start">
                                        <label className='form-label m-0'>MTM/Valuation: </label>
                                        <select className="form-select form-select-sm col-lg-1 col-sm-3 text-start" aria-label="MTM Formula/Valuation"

                                            name="Asset1ForwardPriceExpression"
                                            value={newContract.Asset1ForwardPriceExpression
                                            }
                                            onChange={handleChange}
                                        >
                                            <option value="">Select MTM Valuation</option>
                                            {codes && codes.XCHG && codes.XCHG.length > 0 && codes.XCHG
                                                .map((code, index) => (
                                                    <option key={index} value={code.CDE}>
                                                        {code.CDE_TXT}
                                                    </option>
                                                ))
                                            }

                                        </select>

                                    </div> */}
                                    {showFields && (
                                        <div className="col-lg-2 col-md-2 col-sm-3 text-start">
                                            <label className='form-label m-0'>Updated By: </label>
                                            <input className="form-control form-control-sm" value={newContract?.UserStamp || ''}></input>
                                        </div>
                                    )}
                                    <div className="col-lg-2 col-md-2 col-sm-3 text-start">
                                        <label className='form-label m-0'>Updated On: </label>
                                        <input className="form-control form-control-sm" value={
                                            newContract?.DateStamp
                                                ? formatDateTime(newContract.DateStamp)
                                                : ''
                                        }
                                            readOnly></input>
                                    </div>
                                </div>
                            </div>

                            {/* Table Section */}
                            {/* Hid section below, add back when ready */}
                            {loading ? (
                                <Spinner2 />
                            ) : (
                                <div className="row mt-1">
                                    <h6>Hedge Contract Table</h6>
                                    {/* <h6><span>Code Master Details</span></h6> */}
                                    <div className="col-sm-12 col-md-12 col-lg-12 p-1">
                                        <Table hedgeContracts={hedgeContracts} filter={filter} onRowClick={handleRowClick} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </form>

            {/* Import Modals Here*/}

        </div >

    );
};

export default HedgeCodes;