import { formatDate, formatDateTime, getDayStart } from "../../../../utils/DateUtils"

const FinPositionsTableColumns = [
	{
		name: 'Fin Pos ID',
		selector: row => row.FIN_POSITION_NUM,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true,
		//width: '115px'
	},
	{
		name: 'Instr ID',
		selector: row => row.Instrument?.ContractName,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true,
		//width: '120px'
	},
	{
		name: 'Counter Party',
		selector: row => row.CounterParty?.SHORTNAME,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true,
		//width: '140px'
	},
	{
		name: 'Portfolio',
		selector: row => row.PortfolioCode?.CDE_TXT,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true,
		//width: '140px'
	},
	{
		name: 'Trade Date & Time',
		selector: row => formatDateTime(row.TRADE_DATE),
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true,
		//width: '180px'
	},
	{
		name: 'Del Month',
		selector: row => getDayStart(row.DELIVERY_MONTH)?.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric' }),
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true,
		//width: '120px'
	},
	{
		name: 'Start Date',
		selector: row => formatDate(row.STARTDATE),
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
		//width: '120px'

	},
	{
		name: 'End Date',
		selector: row => formatDate(row.ENDDATE),
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
		//width: '120px'

	},
	{
		name: 'Instr Price',
		selector: row => row.INSTR_PRICE,
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
		//width: '120px'
	},
	{
		name: 'MTM Value',
		selector: row => row.MTM_VALUE?.toFixed(3),
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
		//width: '120px'
	},
	{
		name: 'MTM PnL',
		selector: row => row.MTM_PNL?.toFixed(2),
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
		//width: '120px'

	},
	{
		name: 'Direction',
		selector: row => row.DirectionCode?.CDE_TXT,
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
		//width: '115px'

	},

	{
		name: 'Positions #',
		selector: row => row.CONTRACT_BOUGHT,
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
		//width: '120px'
	},
	{
		name: 'Userstamp',
		selector: row => row.USERSTAMP,
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
		//width: '120px'

	},

	{
		name: 'Datestamp',
		selector: row => formatDateTime(row.DATESTAMP),
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
		//width: '175px'
	}
];

export default FinPositionsTableColumns;