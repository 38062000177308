import DataTable from 'react-data-table-component';
import CustomStyles from './Table-Styles';
import Spinner from '../../../../Spinner';
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { non_path_noms_actions } from '../../../../../store/non-path-noms-slice';


function OBAStatementTable({ date }) { // oba statement table

    const obaStatementModalState = useSelector(state => state.non_path_noms_slice.modalStates.obaStatementModalState);
    const selectedSupplier = useSelector(store => store.business_selector_slice.selectedSupplier);

    const columns = [

        {
            name: 'Date',
            selector: row => row.DisplayDate,
            sortable: false,
            compact: true,
            wrap: true,
        },
        {
            name: 'Meas. MCF',
            selector: row => row.MeasuredMCF,
            sortable: false,
            compact: true,
            wrap: true,
        },
        {
            name: 'Meas. DT',
            selector: row => row.MeasuredDT,
            sortable: false,
            compact: true,
            wrap: true,
        },
        {
            name: 'Allo. MCF',
            selector: row => row.AllocatedMCF,
            sortable: false,
            compact: true,
            wrap: true,
        },
        {
            name: 'Allo. DT',
            selector: row => row.AllocatedDT,
            sortable: false,
            compact: true,
            wrap: true,
        },
        {
            name: 'Imbal. MCF',
            selector: row => row.ImbalanceMCF,
            sortable: false,
            compact: true,
            wrap: true,
        },
        {
            name: 'Imbal. DT',
            selector: row => row.ImbalanceDT,
            sortable: false,
            compact: true,
            wrap: true,
            //reorder: true
        },
        {
            name: 'Prior M. MCF',
            selector: row => row.PriorMeasuredAdjMCF,
            sortable: false,
            compact: true,
            wrap: true,
        },
        {
            name: 'Prior M. DT',
            selector: row => row.PriorMeasuredAdjDT,
            sortable: false,
            compact: true,
            wrap: true,
        },
        /*{ // don't need'
            name: 'Prior A. MCF',
            selector: row => row.PriorAllocatedAdjMCF,
            sortable: false,
            compact: true,
            wrap: true,
        },
        {
            name: 'Prior A. DT',
            selector: row => row.PriorAllocatedAdjDT,
            sortable: false,
            compact: true,
            wrap: true,
        },*/
        {
            name: 'Total MCF',
            selector: row => row.TotalMCF,
            sortable: false,
            compact: true,
            wrap: true,
        },
        {
            name: 'Total DT',
            selector: row => row.TotalDT,
            sortable: false,
            compact: true,
            wrap: true,
        },

    ];
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchOBAStatementData(obaStatementModalState, selectedSupplier));
    }, [date, obaStatementModalState.selectedContract, selectedSupplier]);

    const fetchOBAStatementData = (obaStatementModalState, selectedSupplier) => {
        return async (dispatch) => {
            try {
                await dispatch(non_path_noms_actions.changeModalState({
                    modalStateName: 'obaStatementModalState',
                    newModalState: { ...obaStatementModalState, obaStatementData: [] }
                }));;
                //console.log(selectedSupplier);

                // URL variables
                var baID = selectedSupplier.BA_ID;
                var month = date;
                var cntr_num = obaStatementModalState?.selectedContract?.CNTR_NUM;
                //console.log("month:", month);
                if(!cntr_num || !month || !baID) {
                    return;
                }

                const url = `OBAStatement/GetOBAStatementData` +
                    `?baID=${baID}` +
                    `&cntrNum=${cntr_num}` +
                    `&month=${month}`;

                // Fetch data.
                const response = await fetch(url);
                //console.log("response:", response);

                // Handle errors.
                if (!response.ok) {
                    const message = await response.text();
                    throw new Error(message);
                }

                // Update data.
                const newOBAData = await response.json();
                await dispatch(non_path_noms_actions.changeModalState({
                    modalStateName: 'obaStatementModalState',
                    newModalState: { ...obaStatementModalState, obaStatementData: newOBAData }
                }));;
                //GasAllocationFetchCleanup(dispatch);
            }
            catch (error) {
                if (error.name === 'AbortError') {
                    // Do nothing if previous request was aborted. The second request might
                    // still be loading when the first request is aborted
                    console.log('Request was aborted.');
                }
                console.log(error.message);
            }
        }
    }

    const conditionalRowStyles = [
        {
            when: row => row.Boldness === "BOLD",
            style: { fontWeight: 'bold' }
        },
    ]

    return (
        <DataTable
            progressComponent={< Spinner status='loading' />}
            className=''
            fixedHeader={true}
            fixedHeaderScrollHeight="auto"
            customStyles={CustomStyles}
            columns={columns}
            data={obaStatementModalState.obaStatementData}
            striped={true}
            highlightOnHover={true}
            responsive={false}
            dense={true}
            conditionalRowStyles={conditionalRowStyles}
        />
    );
};

export default OBAStatementTable;