import save from '../../../assets/images/save.png';
import del from '../../../assets/images/delete.png';

function TradeDetailUi() {
    return (
        <>
            <div className="modal modal-xl" id="TradeDetailUi" data-bs-keyboard="false" data-bs-backdrop="static" aria-hidden="true" aria-labelledby="TradeDetailUi Report" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content text-center">
                        <div className="modal-header text-center bg-primary text-white justify-content-center">
                            <h1 className="fs-5" id="TradeDetailUi">Trade Detail UI</h1>
                            <button type="button" className="btn-close" data-bs-target="#MarketPoolImabalanceUi" data-bs-toggle="modal" aria-label="Close"></button>
                        </div>
                        <div className='modal-body'>
                            <div className="container-fluid">
                                {/* Buttons*/}
                                <div className="row text-start bg-light-blue rounded">
                                    <div className="col-sm-12 col-md-12 col-lg-12 p-2">
                                        <h6 className='text-start'>Imbalance Trade Details</h6>
                                        <button className='btn btn-sm btn-primary m-1'><img src={save} alt='Add New Customer' /> Save</button>
                                        <button className='btn btn-sm btn-primary m-1'><img src={del} alt='Add New Customer' /> Delete</button>
                                    </div>
                                </div>


                                {/* Trade Details Section*/}
                                <div className="row text-start mt-2 bg-light-blue rounded justify-content-center">
                                    <h6>Trade Details</h6>
                                    <div className="col-lg-6 col-md-4 p-1">
                                        <label>Trade Partners: </label>
                                        <input className="form-control form-control-sm" type="text" placeholder="Keinard" aria-label="Trade Partners" />
                                        <label>Trade Contacts: </label>
                                        <input className="form-control form-control-sm" type="text" placeholder="Keinard" aria-label="Trade Contacts" />
                                        <label>Trade ID: </label>
                                        <input className="form-control form-control-sm" type="text" placeholder="0000000008" aria-label="Imbalance" />
                                        <label>User ID: </label>
                                        <input className="form-control form-control-sm" type="text" placeholder="0000000008" aria-label="Imbalance" />
                                    </div>
                                    <div className="col-lg-6 col-md-4">
                                        <label>Pool ID: </label>
                                        <input className="form-control form-control-sm mb-1" type="text" aria-label="Total Traded:" />
                                        <label>Trade Amt</label>
                                        <input className="form-control form-control-sm" type="text" aria-label="Total Traded:" />
                                        <label>Display Units</label>
                                        <input className="form-control form-control-sm" type="text" aria-label="Total Traded:" />
                                        {/* Checkboxes and radio buttons */}
                                        <div className="form-check">
                                            {/* <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked /> */}
                                            <label className="form-check-label">
                                                Buy
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            {/* <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" /> */}
                                            <label className="form-check-label">
                                                Sell
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <label className="form-check-label">Confirmed</label>
                                            {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                            </input> */}
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TradeDetailUi