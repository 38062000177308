import { createSlice } from '@reduxjs/toolkit';

import {
	getDayStart,
	getNextDay
} from '../utils/DateUtils';

// nomination utils
import {
	updateVolForPkgPathDtl,
	checkIfSelected
} from '../utils/RetailNomsUtils';


const initialState = {
	modalStates: { // Modal states - Cameron
		addNomModalState: {
			loading: false,
			open: false
		},
		activityLookupModalState: {
			loading: false,
			open: false
		},
		specifyLocationQuantityModalState: {
			loading: false,
			open: false
		},
		nonPathDetailsModalState: {
			loading: false,
			open: false
		}
	},
	nomsTableLoading: false,
	nomSplitsTableLoading: false,
	editItemID: '',
	editFormData: {},
	selectedContract: {},
	selectedPkgPathDtl: {},
	selectedPkg: [],
	nomination: {},
	contracts: [],
	packages: [],
	pkgPathDtls: [],
	creatingPkg: false,
	status: '',
	gasFlowDate: getDayStart(getNextDay(new Date())).toISOString(),
	nomSplitsData: [],
	fuelPercent: 0,
	propertyInformation: [
		{
			headerName: "Package ID",
			propertyName: "PACKAGE_NUM",
			editable: false,
			displayOnce: true,
		},
		{
			headerName: "Contract",
			propertyName: "CntrAltNum1",
			editable: false,
			displayOnce: true
		},
		{
			headerName: "Cycle",
			propertyName: "Cycle",
			editable: false,
			displayOnce: true
		},
		{
			headerName: "Status",
			propertyName: "Status",
			editable: false,
			displayOnce: true,
		},
		{
			headerName: "Beg Date",
			propertyName: "PackageStartDate",
			editable: false,
			displayOnce: true,
			displayAs: 'date'
		},
		{
			headerName: "End Date",
			propertyName: "PackageEndDate",
			editable: false,
			displayOnce: true,
			displayAs: 'date'
		},
		{
			headerName: "ADDQ",
			propertyName: "ADCQ",
			editable: false,
			displayOnce: true
		},
		{
			headerName: "Activity #",
			propertyName: "ACTIV_ALT_NUM",
			editable: false,
			displayOnce: true
		},
		{
			headerName: "Svc Req K",
			propertyName: "UP_CNTR",
			editable: true,
			displayOnce: true
		},
		{
			headerName: "Path Num",
			propertyName: "PATH_NUM",
			editable: false,
			displayOnce: false
		},
		{
			headerName: "Location",
			propertyName: {
				Receive: 'RecLoc',
				Deliver: 'DelLoc'
			},
			editable: false,
			displayOnce: false
		},
		{
			headerName: "Nom Qty",
			propertyName: {
				Receive: "NOM_RCV_VOL",
				Deliver: "NOM_DLV_VOL"
			},
			editable: true,
			displayOnce: false,
		},
		{
			headerName: "PL CF Qty",
			propertyName: {
				Receive: "SCH_RCV_VOL",
				Deliver: "SCH_DLV_VOL"
			},
			editable: false,
			displayOnce: false
		},
		{
			headerName: "Fuel Qty",
			propertyName: "Fuel",
			editable: false,
			displayOnce: false
		},
		{
			headerName: "Net Qty",
			propertyName: "NetQty",
			editable: false,
			displayOnce: false
		},
		{
			headerName: "CF-NM DIFF",
			propertyName: "SchMinNom",
			editable: false,
			displayOnce: false
		},
		{
			headerName: "Nom Diff",
			propertyName: "NomDiff",
			editable: false,
			displayOnce: false
		},
		{
			headerName: "Reason Code",
			propertyName: "REASON_CODE",
			editable: false,
			displayOnce: true
		},
		{
			headerName: "Up Pipeline",
			propertyName: "InterShipperBaNameRetail",
			editable: false,
			displayOnce: false
		},
		{
			headerName: "Up Pkg#",
			propertyName: "UP_PKG",
			editable: false,
			displayOnce: true
		},
		{
			headerName: "Updated By",
			propertyName: "USERSTAMP",
			editable: false,
			displayOnce: false
		},
		{
			headerName: "Updated On",
			propertyName: "DATESTAMP",
			editable: false,
			displayOnce: false,
			displayAs: 'date'
		}
	]
};

const nominations_slice = createSlice({
	name: 'nominations_slice',
	initialState,
	reducers: {
		changeModalState(state, action) {
			const {
				modalStateName,
				newModalState
			} = action.payload;
			state.modalStates = {
				...state.modalStates,
				[modalStateName]: newModalState
			};
		},
		businessSelectorChange(state, action) {
			let newGasDate = getDayStart(getNextDay(action.payload.gasFlowDate)).toISOString();
			state.gasFlowDate = newGasDate;
			state.selectedContract = action.payload.selectedContract;
		},
		updateStatus(state, action) {
			state.status = action.payload;
		},
		updatePkgPathDtls(state, action) {
			/* The structure of the data needs to be changed
			 * so that the Non Path Noms Table can display the
			 * data correctly. The package path details come back
			 * from the server as a single array of package path 
			 * details. The new structure will be an 
			 * object that contains arrays of package path details.
			 * Each array of package path details has a key,
			 * which is the package number. - Cameron */

			const pkgPathDtls = action.payload;
			let packages = {};

			pkgPathDtls.forEach(
				pkgPathDtl => {

					const package_num = pkgPathDtl.PACKAGE_NUM;
					const isDefined = packages[package_num];

					if (!isDefined) {
						packages[package_num] = [pkgPathDtl];
					}
					else {
						packages[package_num].push(pkgPathDtl);
					}

				}
			);

			state.packages = packages;
			state.pkgPathDtls = pkgPathDtls;
		},
		setCreatingPkg(state, action) {
			state.creatingPkg = action.payload;
		},
		updateGasFlowDate(state, action) {
			state.gasFlowDate = action.payload;
		},
		updateNomSplitsData(state, action) {
			state.nomSplitsData = action.payload;
		},
		editFormChange(state, action) {
			state.editItemID = action.payload.editItemID;
			state.editFormData = action.payload.editFormData;
		},
		setUpEditDataAndID(state, action) {

			const {
				newEditFormData,
				newEditItemID
			} = action.payload;

			state.editFormData = newEditFormData;
			state.selectedPkg = newEditFormData;
			state.editItemID = newEditItemID;

		},
		updateSelectedPkg(state, action) {

			const {
				newValue,
				propertyName
			} = action.payload;

			const prevSelectedPkg = state.selectedPkg;

			const newSelectedPkg = prevSelectedPkg.map(
				prevPkgPathDtl => {

					if (propertyName === 'NOM_DLV_VOL' || propertyName === 'NOM_RCV_VOL') {

						const selected = checkIfSelected(prevPkgPathDtl, state.selectedPkgPathDtl);

						if (!selected)
							return prevPkgPathDtl;

						const fuelPercent = state.fuelPercent;

						const newPkgPathDtl = updateVolForPkgPathDtl(prevPkgPathDtl, newValue, propertyName, fuelPercent);

						return newPkgPathDtl;

					}

					// new volume detail
					const prevPkgVolumeDtl = prevPkgPathDtl.PackageVolumeDetail;

					let newPkgVolumeDtl = {
						...prevPkgVolumeDtl
					};

					newPkgVolumeDtl[propertyName] = newValue;

					// new path detail
					let newPkgPathDtl = {
						...prevPkgPathDtl,
						PackageVolumeDetail: newPkgVolumeDtl
					};

					newPkgPathDtl[propertyName] = newValue;

					return newPkgPathDtl;

				}
			);

			state.selectedPkg = newSelectedPkg;

		},
		updateSelectedPkgPathDtl(state, action) {

			const {
				newValue,
				propertyName
			} = action.payload;

			const prevSelectedPkgPathDtl = state.selectedPkgPathDtl;
			let newSelectedPkgPathDtl;

			if (propertyName === 'NOM_DLV_VOL' || propertyName === 'NOM_RCV_VOL') {

				const fuelPercent = state.fuelPercent;

				newSelectedPkgPathDtl = updateVolForPkgPathDtl(prevSelectedPkgPathDtl, newValue, propertyName, fuelPercent);

			}
			else {

				// new volume detail
				const prevPkgVolumeDtl = prevSelectedPkgPathDtl.PackageVolumeDetail;

				let newPkgVolumeDtl = {
					...prevPkgVolumeDtl
				};

				newPkgVolumeDtl[propertyName] = newValue;

				// new path detail
				newSelectedPkgPathDtl = {
					...prevSelectedPkgPathDtl,
					PackageVolumeDetail: newPkgVolumeDtl
				};

				newSelectedPkgPathDtl[propertyName] = newValue;

			}

			state.selectedPkgPathDtl = newSelectedPkgPathDtl;

		},
		updateNomination(state, action) {

			const {
				newValue,
				propertyName
			} = action.payload;

			const prevNomination = state.nomination;

			const newPkgPaths = prevNomination.ENT_PKG_PATH.map(
				prevPkgPath => {

					const newPkgPathDtls = prevPkgPath.ENT_PKG_PATH_DTL.map(
						prevPkgPathDtl => {

							if (propertyName === 'NOM_DLV_VOL' || propertyName === 'NOM_RCV_VOL') {

								const selected = checkIfSelected(prevPkgPathDtl, state.selectedPkgPathDtl);

								if (!selected)
									return prevPkgPathDtl;

								const fuelPercent = state.fuelPercent;

								const newPkgPathDtl = updateVolForPkgPathDtl(prevPkgPathDtl, newValue, propertyName, fuelPercent);

								return newPkgPathDtl;

							}

							// new package volume detail
							const prevPkgVolumeDtl = prevPkgPathDtl.PackageVolumeDetail;

							let newPkgVolumeDtl = {
								...prevPkgVolumeDtl
							};

							newPkgVolumeDtl[propertyName] = newValue;

							// new package path detail
							let newPkgPathDtl = {
								...prevPkgPathDtl,
								PackageVolumeDetail: newPkgVolumeDtl
							};

							newPkgPathDtl[propertyName] = newValue;

							return newPkgPathDtl;

						}
					);

					const newPkgPath = {
						...prevPkgPath,
						ENT_PKG_PATH_DTL: newPkgPathDtls
					};

					return newPkgPath;

				}
			);

			const newNomination = {
				...state.nomination,
				ENT_PKG_PATH: newPkgPaths
			};

			state.nomination = newNomination;

		},
		setSelectedPkgPathDtl(state, action) {
			state.selectedPkgPathDtl = action.payload;
		},
		setSelectedPkg(state, action) {
			state.selectedPkg = action.payload;
		},
		setNomination(state, action) {
			state.nomination = action.payload;
		},
		setSelectedContract(state, action) {
			state.selectedContract = action.payload;
		},
		setContracts(state, action) {
			state.contracts = action.payload;
		},
		setNomsTableLoading(state, action) {
			state.nomsTableLoading = action.payload;
		},
		setNomSplitsTableLoading(state, action) {
			state.nomSplitsTableLoading = action.payload;
		},
		setFuelPercent(state, action) {
			state.fuelPercent = action.payload;
		}
	}
});

export const nominations_actions = nominations_slice.actions;
export default nominations_slice.reducer;