import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import SupplierSelect from "../../../../BusinessSelector/SupplierSelect";
import useBusinessSelectorRedux from '../../../../../hooks/useBusinessSelectorRedux';
import { fetchEfsReport } from '../../../../../store/efsReportSlice';
import { non_path_noms_actions } from '../../../../../store/non-path-noms-slice';

import { getDayStart, toDatePickerString } from '../../../../../utils/DateUtils';

import EstimatedFlowTable from '../../Tables/EstimatedFlowTable';
import { Margin, Resolution, usePDF } from 'react-to-pdf';

//Modal Styling:
import './EstModalStyle.css';
import NPNomsContractSelector from '../../NPNomsContractSelector';

const customStyles = { overlay: { backgroundColor: 'rgb(0,0,0,.60' } };


function EstimatedFlowModal() {

	// Redux
	const dispatch = useDispatch();
	const NonPathNomsFilters = useSelector(state => state.non_path_noms_slice.NonPathNomsFilters);
	const efsReportData = useSelector(state => state.efsReport.data);
	const efsReportStatus = useSelector(state => state.efsReport.status);
	// Business Selector logic
	useBusinessSelectorRedux(NonPathNomsFilters, non_path_noms_actions.setNonPathNomsFilters, true);

	const EstimatedFlowModalState = useSelector(state => state.non_path_noms_slice.modalStates.estimatedFlowModalState);
	const { open } = EstimatedFlowModalState;

	const ba = useSelector(state => state.business_selector_slice.businessUnit);
	const selectedContract = useSelector(state => state.non_path_noms_slice.NonPathNomsFilters.selectedContract);
	const cntr_party1 = useSelector(state => state.business_selector_slice.businessUnit.BA_ID);
	const cntr_party2 = useSelector(state => state.business_selector_slice.selectedSupplier.BA_ID);
	const cntr_type = useSelector(state => state.business_selector_slice.baRole?.CDE);
	// const status = useSelector(state => state.business_selector_slice.selectedContract?.STATUS === "AC");


	const [tableData, setTableData] = useState([]);
	const [localGasFlowDate, setLocalGasFlowDate] = useState(NonPathNomsFilters?.gasFlowDate);

	// Export to PDF
	const { toPDF, targetRef } = usePDF({
		filename: 'EstimatedFlowingSupply_' + (new Date().toJSON().slice(0, 10)) + '.pdf',
		page: {
			margin: Margin.SMALL,
			format: "A4",
			orientation: "landscape"
		},
		resolution: Resolution.MEDIUM,
		options: {
			FitWindow: 'true'
		},
		canvas: {
			mimeType: "image/jpeg",
			qualityRatio: 1
		},
		overrides: {
			pdf: {
				compress: true
			},
			canvas: {
				useCORS: true
			}
		}
	});

	useEffect(() => {
		if (open) {
			setLocalGasFlowDate(NonPathNomsFilters?.gasFlowDate);
		}
	}, [open, NonPathNomsFilters?.gasFlowDate]);


	const setGasFlowDate = (event) => {
		const newGasFlowDate = getDayStart(event.target.value).toISOString();
		setLocalGasFlowDate(newGasFlowDate);
	};


	const closeModal = () => {
		dispatch(non_path_noms_actions.setNonPathNomsFilters({ ...NonPathNomsFilters, gasFlowDate: localGasFlowDate }));
		dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'estimatedFlowModalState',
			newModalState: { ...EstimatedFlowModalState, open: false }
		}));
	};


	useEffect(() => {
		if (ba && ba.BA_ID) {
			dispatch(fetchEfsReport({
				displayDate: toDatePickerString(localGasFlowDate),
				cntr_type: cntr_type,
				cntrNum: selectedContract?.CNTR_NUM,
				cntr_party1: cntr_party1,
				cntr_party2: cntr_party2,
				status: "AC"
			}));
		}
	}, [dispatch, localGasFlowDate, ba, selectedContract, cntr_party1, cntr_party2, cntr_type]);



	useEffect(() => {
		if (efsReportStatus === 'success') {
			if (efsReportData && efsReportData.length > 0) {
				setTableData(efsReportData);
			} else {

				setTableData([]);
			}
		}
	}, [efsReportData, efsReportStatus]);



	return (
		<div>
			<Modal
				isOpen={open}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="Estimated Flow Modal"
				className='view-details-modal container-fluid'
			>
				<div>
					<div className='modal-header '>
						<h5>Estimated Flowing Supply</h5>
						<button className='btn-close' onClick={closeModal} />
					</div>
					<div className='modal-body'>
						{/* Filters section */}
						<div className="row bg-light-blue text-dark p-2">
							<h5>Display Filters:</h5>
							{/* // FILTERS */}
							<div className="col-lg-2 col-md-2 col-sm-12 me-5">
								<form>
									<label>
										Gas Flow Date:
										<br />
										<input
											className="form-control form-control-sm"
											type="date"
											value={toDatePickerString(localGasFlowDate)}
											onChange={setGasFlowDate}
											name='gasFlowDate'
											min="2023-11-01" // november 2023 devops 4666
										/>

										{/* <input
                       className="form-control form-control-sm"
                       type="date"
                       value={toDatePickerString(NonPathNomsFilters?.gasFlowDate)}
                       onChange={setGasFlowDate}
                       name='gasFlowDate'
												/> */}
									</label>
								</form>
							</div>
							<div className="col-lg-3 col-md-2 col-sm-12 me-5">
								<form>
									<label>
										Requestor:
										<br />
										<SupplierSelect />
									</label>
								</form>
							</div>
							<div className="col-lg-3 col-md-2 col-sm-12 me-5">
								<form>
									<label>
										Contract:
										<br />
										<NPNomsContractSelector />
									</label>
								</form>
							</div>
							<div className="text-start bg-light-blue rounded m-0 p-2">
								<button className="btn btn-md btn-primary me-2 mb-1" onClick={() => toPDF()}>Save PDF</button>
							</div>
						</div>

						<div className='pb-2 p-1'>
							<div className='row'>
								<div className='col-sm-12 col-md-12 col-lg-12'>
									<h6 className='mb-0'>Path Details</h6>
									<hr className='mt-0 mb-1' />
								</div>
							</div>
							<div className='row' ref={targetRef}>
								<EstimatedFlowTable tableData={efsReportData} />
							</div>
						</div>
					</div>
				</div>
			</Modal >
		</div >
	);
}

export default EstimatedFlowModal;
