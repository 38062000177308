import { createSlice } from "@reduxjs/toolkit";
import { getPointBalances } from './point-balance-thunks';


const pointBalance_slice = createSlice({
    name: "storage",
    initialState: {
        storageData: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        setStorageData: (state, action) => {
            state.storageData = action.payload;
        },
        resetStorageData: (state) => { // Adding a reducer to reset the state
            state.storageData = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPointBalances.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPointBalances.fulfilled, (state, action) => {
                // Here we're directly setting the storageData with the fetched data
                state.storageData = action.payload;
            })
            .addCase(getPointBalances.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});


export const pointBalanceActions = pointBalance_slice.actions;
export default pointBalance_slice.reducer;