import DataTable from "react-data-table-component";
import { useDispatch } from 'react-redux';

import CustomStyles from "./Table-Styles";

import { whpurschase_actions } from "../../../../store/whpurschase-slice";

function WHPurchaseTable({ data, setOpenModal }) {
    // Redux
	const dispatch = useDispatch();
    
    const isHidden = true
    
    const rowClick = (row) => {
        setOpenModal(false);
        dispatch(whpurschase_actions.setSelectedContractWHStl(row));
        setOpenModal(true);
    }

    const columns = [{
        name: "Producer",
        selector: row => row.Producer,
        sortable: true,
        compact: true,
        wrap: true,
        width: '200px',
        cell: row => row?.isTotalRow
            ? <div style={{ fontWeight: 'bold', align: 'right'}}>{row.Producer}</div>
            : row.Producer
    },
    {
        name: "Production Date",
        selector: row => (new Date(row.TransDate)).toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        }),
        sortable: true,
        compact: true,
        wrap: true,
    },
    {
        name: "Agr#",
        selector: row => row.AgreementKey,
        sortable: true,
        compact: true,
        wrap: true,
        omit: isHidden,
    },
    {
        name: "PODID",
        selector: row => row.pod,
        sortable: true,
        compact: true,
        wrap: true,
        omit: isHidden,
    },
    {
        name: "Point ID",
        selector: row => row.PointShortName,
        sortable: true,
        compact: true,
        wrap: true,

    },
    {
        name: "Partic",
        selector: row => row.Partic,
        sortable: true,
        compact: true,
        wrap: true,
    },

    {
        name: "Mcf",
        selector: row => row.Volume?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
    },
    {
        name: "Dth",
        selector: row => row.GR_ENERGY?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
    },
    {
        name: "Price",
        selector: row => row.PriceFee?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
    },
    {
        name: "Index",
        selector: row => row.Index,
        sortable: true,
        compact: true,
        wrap: true,
        omit: isHidden
    },
    {
        name: "GR Rev",
        selector: row => row.GrRev?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
    },
    {
        name: "Deducts",
        selector: row => row.TotalDeducts?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
    },
    {
        // name: "Capac",
        name: "Demand",
        selector: row => row.CAPAC?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
        // omit: isHidden,
    },
    {
        name: "Compr",
        selector: row => row.COMPR?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
        omit: isHidden,
    },
    {
        name: "Paioga",
        selector: row => row.PAIOGA?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
        omit: isHidden,
    },
    {
        name: "Retain",
        selector: row => row.RETAIN?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
        omit: isHidden,
    },
    {
        name: "Tier",
        selector: row => row.TIER?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
        omit: isHidden,
    },
    {
        name: "Low Vol",
        selector: row => row.LOWVOL?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,

    },
    {
        name: "M&M",
        selector: row => row.MM?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
        omit: isHidden,
    },
    {
        name: "Pes Refund",
        selector: row => row.PESREFUND?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
        omit: isHidden,
    },
    {
        name: "Extract",
        selector: row => row.EXTRACT?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
        omit: isHidden,
    },
    {
        name: "Gather",
        selector: row => row.GATHER?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
        omit: isHidden,
    },
    {
        name: "D+C",
        selector: row => row.DEMAND?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
        omit: isHidden,
    },
    {
        name: "IT Comm",
        selector: row => row.ITCOMM?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
        omit: isHidden,
    },
    {
        name: "Gather",
        selector: row => row.GATHER?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
    },
    {
        name: "Extract",
        selector: row => row.TREAT?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
    },
    {
        name: "G/PREP",
        selector: row => row.DEHY?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,

    },
    {
        name: "G/PGAS",
        selector: row => row.DEHYW?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
    },
    {
        name: "G+C/CDP",
        selector: row => row.GANDC?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
        omit: isHidden,
    },
    {
        name: "$Net Rev",
        selector: row => row.NetRev?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,

    },
    {
        name: "Updated By",
        selector: row => row.USERSTAMP,
        sortable: true,
        compact: true,
        wrap: true,

    },
    {
        name: "Updated On",
        selector: row => new Date(row?.DATESTAMP).toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }),
        sortable: true,
        compact: true,
        wrap: true,
        width: "200px",
    },
    ]

    return (
        <div className="container-fluid">
            <DataTable
                // title="WH Purchase"
                columns={columns}
                data={data}
                dense={true}
                striped={true}
                // fixedHeader={true}
                fixedHeaderScrollHeight="398px"
                customStyles={CustomStyles}
                highlightOnHover={true}
                className="pb-1"
                onRowClicked={rowClick}
            />
        </div>
    )
}

export default WHPurchaseTable