import DataTable from "react-data-table-component";
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { fetchGasAllocationData } from "../../../../../store/non-path-noms-slice";
import CustomStyles from "./Table-Styles";
import columns from './GasAllocationTableColumns';
import Spinner from '../../../../Spinner';

function GasAllocationTable() {
    // 12 columns
    // "SvcReqK" "PPA" "SvcReqName" "ProductionMonth" "FlowDirection" "LocationID" "LocationName" "PackageID" "AllocDth" "SchedDth" "NomDth" DiffQtyDth
    const GAData = useSelector(state => state.non_path_noms_slice.GasAllocationData);
    const GasAllocationFilters = useSelector(state => state.non_path_noms_slice.GasAllocationFilters);
    const selectedSupplier = useSelector(store => store.business_selector_slice.selectedSupplier);
    const loading = useSelector(store => store.non_path_noms_slice.GasAllocationDataFetchLoading);

    const dispatch = useDispatch();
    useEffect(() => {
        //console.log("useEffect")
        dispatch(fetchGasAllocationData(GasAllocationFilters, selectedSupplier));
    }, [GasAllocationFilters, selectedSupplier, dispatch]);
    //console.log(GAData);

    const conditionalRowStyles = [
        {
            when: row => row.Boldness === "BOLD",
            style: { fontWeight: 'bold' }
        },
    ]

    return (
      <div className="container-fluid">
            <DataTable
                progressPending={loading}
                progressComponent={<Spinner status='loading' />}
                title="Gas Allocation"
                columns={columns}
                data={GAData}
                dense={true}
                //striped={true}
                fixedHeader={true}
                fixedHeaderScrollHeight="600px"
                customStyles={CustomStyles}
                highlightOnHover={true}
                className="pb-1"
                conditionalRowStyles={conditionalRowStyles}
            />
        </div>
    )
}

export default GasAllocationTable