import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the async thunk
export const fetchEfsReport = createAsyncThunk(
    'efsReport/fetchEfsReportStatus',
    async ({ displayDate, cntr_type, cntr_party1, cntr_party2, cntrNum, status }) => {
        const response = await fetch(`EfsReport/EfsReport?displayDate=${displayDate}&cntr_type=${cntr_type}&cntr_party1=${cntr_party1}&cntr_party2=${cntr_party2}&cntrNum=${cntrNum}&status=${status}`);
        if (!response.ok) {
            throw new Error('Failed to fetch report');
        }
        const data = await response.json();
        return data;
    }
);


const efsReportSlice = createSlice({
    name: 'efsReport',
    initialState: {
        data: [],
        status: 'idle',
        error: null
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEfsReport.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEfsReport.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(fetchEfsReport.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default efsReportSlice.reducer;
