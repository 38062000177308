import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPoolBalance } from "../../../store/pool-balance-thunks";

//import components:
import Spinner2 from "../../CustomComponents/Spinner2";
import PoolBalanceTable from "./Tables/PoolBalanceTable";
import { getMonthEnd, getMonthStart, toDatePickerString } from "../../../utils/DateUtils";
import SupplierSelect from "../../BusinessSelector/SupplierSelect";

//Hooks:
import { useDateFilter } from "./Hooks/dateHook";
import useBusinessSelector from "../../../hooks/useBusinessSelector";

function PoolBalance() {

	// Redux
	const dispatch = useDispatch();
	const selectedSupplier = useSelector(store => store.business_selector_slice.selectedSupplier.BA_ID);
	const poolBalanceData = useSelector(store => store.poolBalance_slice.poolBalanceData);
	const status = useSelector(store => store.poolBalance_slice.status);

	// State
	const [filters, setFilters] = useState({
		startDate: getMonthStart(new Date()).toDateString(),
		endDate: getMonthEnd(new Date()).toDateString()
	});

	useBusinessSelector(setFilters, true);

	//Import custom hook for Date management and filtering:
	const {
		filteredPoolBalanceData
	} = useDateFilter(poolBalanceData);

	//Get all Pool Balance for given supplier
	useEffect(() => {
 		dispatch(getPoolBalance({
			ba_id: selectedSupplier,
			month: filters?.startDate,
		}));
	}, [dispatch, selectedSupplier, filters]);

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-sm-12 col-md-12 col-lg-12 p-1">

					{/* Card */}
					<div className="card border-0 pb-1 p-0 pt-0">

						{/* Card Header */}
						<div className="card-header bg-primary text-white text-center p-1">
							<h4 className="card-title m-0 pt-0 pb-0">Shipper Pool Balance</h4>
						</div>

						{/* Card Body */}
						<div className="card-body">
							<div className="row p-0">
								<h6>Filters</h6>
							</div>

							{/* Fields and selectors */}
							<div className="row bg-light-blue rounded pb-1">
								<div className="row">
									<div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
										<label className='form-label m-0'>Period From:</label>
										<input
											value={toDatePickerString(filters?.startDate)}
											name='STARTDATE'
											className="form-control form-control-sm"
											type="date"
											onChange={(e) => { setFilters(prevFilters => { return { ...prevFilters, startDate: e.target.value }; }); }}
											min="2023-11-01" // november 2023 devops 4666
										/>

									</div>
									<div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
										<label className='form-label m-0'>Period To:</label>
										<input
											value={toDatePickerString(filters?.endDate)}
											name='ENDDATE'
											className="form-control form-control-sm"
											type="date"
											onChange={(e) => { setFilters(prevFilters => { return { ...prevFilters, endDate: e.target.value }; }); }}
											min="2023-11-01" // november 2023 devops 4666
										/>
									</div>
									<div className="col-lg-5 col-md-4 col-sm-12 p-1 text-start">
										<label className='form-label m-0'>Shipper:</label>
										<SupplierSelect />
									</div>
								</div>
							</div>

							{/* Table section: */}
							<div className="row">
								<div className="col-sm-12 col-md-12 col-lg-12">
									{status === 'loading' ? <Spinner2 /> : <PoolBalanceTable filteredData={filteredPoolBalanceData}/>}
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

	);
}

export default PoolBalance;