import React from 'react';

function Base64Downloader(props) {
    const downloadFile = () => {
        const base64Data = props.base64Data;
        const fileName = props.fileName;
        const contentType = props.contentType;

        // Convert base64 to blob
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const fileBlob = new Blob([byteArray], { type: contentType });

        // Create download link and click it
        const downloadLink = window.document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(fileBlob);
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <button onClick={downloadFile} className='btn btn-sm btn-primary rounded'>Download File</button>
    );
}

export default Base64Downloader;
