import { notification_actions } from './notification-slice';
import { contact_actions } from './contact-slice';
import { getData } from '../utils/APIUtils';

/**
 * Saves the contact to the database
 */
export const createContact = () => {

	return async (dispatch, getState) => {

		const selectedContact = getState().contact_slice.selectedContact;

		const createRequest = async () => {

			const response = await fetch('ContactList/CreateContact', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(selectedContact)
			});

			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			const newSelectedContact = await response.json();

			await dispatch(contact_actions.setSelectedContact(newSelectedContact));
			await dispatch(fetchContacts());
		};

		try {
			dispatch(contact_actions.setLoading(true));
			console.log('Saving contact...');
			await createRequest();
			console.log('Contact saved successfully.');
			dispatch(contact_actions.setLoading(false));
			dispatch(notification_actions.showModal({
				message: 'Contact created successfully.',
				header: 'Success!'
			}));
		}
		catch (error) {
			//dispatch(contact_actions.showEditContactModal(false));
			dispatch(notification_actions.showModal({
				message: error.message,
				header: 'Validation Message'
			}));
			dispatch(contact_actions.setLoading(false));
		}

	}

};

/**
 * Updates the contact in the database.
 */
export const updateContact = () => {

	return async (dispatch, getState) => {

		const selectedContact = getState().contact_slice.selectedContact;

		const requestUpdate = async () => {

			const response = await fetch('ContactList/UpdateContact', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(selectedContact)
			});

			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			const newSelectedContact = await response.json();

			await dispatch(contact_actions.setSelectedContact(newSelectedContact));
			await dispatch(fetchContacts());
		};

		try {
			dispatch(contact_actions.setLoading(true));
			console.log("Updating contact...");
			await requestUpdate();
			console.log("Contact updated successfully.");
			dispatch(contact_actions.setLoading(false));
			dispatch(notification_actions.showModal({
				message: 'Contact updated successfully.',
				header: 'Success!'
			}));
		}
		catch (error) {
			dispatch(notification_actions.showModal({
				message: error.message,
				header: 'Validation Message'
			}));
			dispatch(contact_actions.setLoading(false));
		}

	};

};

/**
 * Fetches contact roles.
 */
export const fetchContactRoleCodes = () => {

	return async (dispatch) => {

		const requestRoles = async () => {

			const response = await fetch('ContactList/GetContactRoleCodes');

			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			var roles = await response.json();

			await dispatch(contact_actions.setContactRoleCodes(roles));

		};

		try {
			console.log('Fetching contact role codes...');
			await requestRoles();
			console.log('Contact role codes have been fetched.');
		}
		catch (error) {
			console.log(error.message);
		}

	};

};

/**
 * Fetches email group codes for contact creation.
 */
export const fetchEmailGroupCodes = () => {
	return async (dispatch) => {
		const requestEmailGroupCodes = async () => {
			const response = await fetch('ContactList/GetEmailGroupCodes');

			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			const emailGroupCodes = await response.json();
			await dispatch(contact_actions.setEmailGroupCodes(emailGroupCodes));
		};

		try {
			console.log('Fetching email group codes...');
			await requestEmailGroupCodes();
			console.log('Email group codes fetched.');
		}
		catch (error) {
			window.alert(error.message);
		}
	};
};

/**
 * Fetches Countries for contact creation.
 */
export const fetchCountries = () => {
	return async (dispatch) => {
		const requestCountries = async () => {
			const response = await fetch('Home/GetCountries');
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}
			const countries = await response.json();
			await dispatch(contact_actions.setCountries(countries));
		};
		try {
			console.log('Fetching Countries...');
			await requestCountries();
			console.log('Countries fetched.');
		}
		catch (error) {
			dispatch(notification_actions.showModal({
				header: 'Error',
				message: error.message
			}));
		}
	};
};

/**
 * Fetches States for contact creation.
 */
export const fetchStates = (country_abbr) => {
	return async (dispatch) => {
		const requestStates = async () => {
			const response = await fetch('Home/GetStates?country_abbr=' + country_abbr);
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}
			const states = await response.json();
			await dispatch(contact_actions.setStates(states));
		};
		try {
			console.log('Fetching states...');
			await requestStates();
			console.log('States fetched.');
		}
		catch (error) {
			dispatch(notification_actions.showModal({
				header: 'Error',
				message: error.message
			}));
		}
	};
};

/**
 * Fetches contacts.
 */
export const fetchContacts = () => {

	return async (dispatch, getState) => {

		const ba_id = getState().business_selector_slice.selectedSupplier.BA_ID;

		const getContacts = async () => {
			const response = await fetch(`ContactList/GetContactListData?ba_id=${ba_id}`);

			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			const newContacts = await response.json();
			await dispatch(contact_actions.setContacts(newContacts));
		}

		try {
			await dispatch(contact_actions.setLoading(true));
			await getContacts();
		}
		catch (error) {
			console.log(error.message);
		}
		finally {
			await dispatch(contact_actions.setLoading(false));
		}

	}

};

export const fetchPasswordExpirationDays = () => {
	return async (dispatch) => {
		try {
			const url = `GeneralData/GetCodes?module=SETUP&cde_type=EXPR&cde=PWDE`;
			await dispatch(contact_actions.setLoading(true))
			const codes = await getData(url);
			if (codes.length > 0) {
				const code = codes[0];
				const days = parseInt(code.DEFAULT_PVOL);	
				await dispatch(contact_actions.setCodedExpirationDay(days));
			}
		}
		catch (error) {
			dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
		finally {
			await dispatch(contact_actions.setLoading(false));
		}
	};
};