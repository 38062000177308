//import addNew from '../../assets/images/add.gif';
import save from '../../assets/images/save.png';
//import del from '../../assets/images/delete.png'
import cancel from '../../assets/images/cancel.png';
import { contact_actions } from '../../store/contact-slice';
import {
	createContact,
	updateContact,
	fetchStates
} from '../../store/contact-thunks';
import { resetPassword } from '../../store/auth-slice';
import { toDatePickerString, toDatePickerMonthString } from '../../utils/DateUtils';
import { useSelector, useDispatch } from 'react-redux';
import Restricted from '../Restricted';
import Spinner from '../CustomComponents/Spinner';


function EditContact() {

	const dispatch = useDispatch();
	const selectedContact = useSelector(state => state.contact_slice.selectedContact);
	const ContactRoleCodes = useSelector(state => state.contact_slice.ContactRoleCodes);
	const suppliers = useSelector(state => state.business_selector_slice.suppliers);
	const EmailGroupCodes = useSelector(state => state.contact_slice.EmailGroupCodes);
	const showEditContactModal = useSelector(state => state.contact_slice.showEditContactModal);
	const loading = useSelector(state => state.contact_slice.loading);
	const states = useSelector(state => state.contact_slice.states);
	const countries = useSelector(state => state.contact_slice.countries);
	const selectedSupplier = useSelector(state => state.business_selector_slice.selectedSupplier);
	const isAdmin = useSelector(state => state.auth_slice.isAdmin);
	const showSpinner = useSelector(state => state.auth_slice.showSpinner);


	/**
	 * Function for updating a property.
	 * @param {any} event
	 */
	const propertyChange = (event) => {

		// event properties
		let property = event.target.getAttribute('property');
		let newValue = event.target.value;

		// check if this is an object
		let objectName;
		const indexOfDot = property.indexOf('.');
		if (indexOfDot !== -1) {
			objectName = property.slice(0, indexOfDot);
			property = property.slice(indexOfDot + 1, property.length);
		}

		// Special cases
		switch (property) {
			case 'BA_ID': {
				newValue = suppliers.find(supplier => supplier.BA_ID === newValue);
				break;
			}
			case 'COUNTRY_OBJ': {
				if (newValue) {
					dispatch(fetchStates(newValue));
					newValue = countries.find(country => country.COUNTRY_ABBR === newValue);
				}
				dispatch(contact_actions.setStates([]));
				break;
			}
			case 'STATE': {
				newValue = states.find(state => state.STANAME === newValue);
				break;
			}
			default: {
				break;
			}
		}

		// dispatch the change
		dispatch(contact_actions.propertyChange({
			objectName: objectName,
			property: property,
			newValue: newValue
		}));

	};

	/**
	 * Function made for date changes. Redux is picky about dates.
	 * @param {any} event
	 */
	const dateChange = (event) => {
		// event properties
		let property = event.target.getAttribute('property');
		let newValue = new Date(event.target.value).toISOString();

		// check if this is an object
		let objectName;
		const indexOfDot = property.indexOf('.');
		if (indexOfDot !== -1) {
			objectName = property.slice(0, indexOfDot);
			property = property.slice(indexOfDot + 1, property.length);
		}

		dispatch(contact_actions.propertyChange({
			objectName: objectName,
			property: property,
			newValue: newValue
		}));

	};

	const roleChange = (event) => {
		const clickedRoleCode = event.target.getAttribute('role_code');
		dispatch(contact_actions.roleChange(clickedRoleCode));
	};

	const emailGroupChange = (event) => {
		const clickedEmailCode = event.target.getAttribute('email_code');
		const newEmailGroup = {
			CONTACT_NUM: selectedContact?.CONTACT_NUM,
			CDE: clickedEmailCode,
			ContactNotificationKey: {
				CDE: clickedEmailCode,
				CONTACT_NUM: selectedContact?.CONTACT_NUM,
				BA_ID: selectedSupplier?.BA_ID
			}
		};
		dispatch(contact_actions.emailGroupChange(newEmailGroup));
	};

	const cancelClick = () => {
		const expirationDays = 0
		dispatch(contact_actions.resetContact({ selectedSupplier, expirationDays }));
		dispatch(contact_actions.showEditContactModal(false));
	};

	const saveClick = () => {
		if (selectedContact.CONTACT_NUM) {
			dispatch(updateContact());
			return;
		}
		dispatch(createContact());
	};

	return (
		<>
			<div
				className={`modal-backdrop ${showEditContactModal ? 'show' : ''}`}
				style={{ display: `${showEditContactModal ? 'block' : 'none'}` }}
				onClick={cancelClick}
			></div>
			<div
				className={`modal modal-xl ${showEditContactModal ? 'show' : ''}`}
				style={{ display: `${showEditContactModal ? 'block' : 'none'}` }}
				id="EditContact"
				aria-hidden="true"
				aria-labelledby="AddCustomer Report"
				data-backdrop='true'
				tabIndex="-1"
			>
				<div className="modal-dialog modal-dialog-centered ">
					<div className="modal-content text-center">
						<div className="modal-header text-center bg-primary text-white justify-content-center">
							<h1 className="fs-5" id="AddCustomer">Edit Business Associate Contact</h1>
							<button
								type="button"
								className="btn-close"
								onClick={cancelClick}
								aria-label="Close"
							></button>
						</div>
						<div className='modal-body'>
							<div className="row text-start bg-light-blue rounded">
								{/* BUTTONS */}
								{/* 
								 
								 <button
									className='btn btn-sm btn-primary m-1'
									type='button'
									onClick={saveClick}
								>
									<img src={addNew} alt='Add New Customer' />
									Add Contact
								</button>
								 
								 */}
								<div className='col-auto p-0'>
									{
										loading
											?
											<Spinner status='loading' />
											:
											<Restricted
												restrictedTo={['WRITE-ACCESS']}
											>
												<button
													className='btn btn-sm btn-primary m-1'
													type='button'
													onClick={saveClick}
												>
													<img src={save} alt='Save' />
													Save
												</button>
											</Restricted>
									}
								</div>
								{/* <button className='btn btn-sm btn-primary m-1'><img src={del} alt='Delete Customer' /> Delete Customer</button> */}
								<div className='col-auto p-0'>
									<button
										className='btn btn-sm btn-primary m-1'
										onClick={cancelClick}
										type='button'
									>
										<img src={cancel} alt='Cancel' />
										Cancel
									</button>
								</div>
							</div>
							<div className='row text-start'>
								<h6>Additional Contact Details</h6>
								<Restricted
									restrictedTo={['WRITE-ACCESS']}
									action='disable'
								>
									{/* Column 1 */}
									<div className='col-lg-3 col-md-3 col-sm-12'>
										<label className='form-label'>Shipper:</label>

										<select
											disabled={!isAdmin}
											className='form-select form-select-sm'
											value={selectedContact?.BA_ID?.BA_ID || ''}
											onChange={propertyChange}
											property='BA_ID'
										>
											<option value="">-- Select a supplier --</option>
											{
												suppliers.map(
													supplier => {

														return (
															<option
																key={supplier.BA_ID}
																value={supplier.BA_ID}
															>
																{supplier.NAME}
															</option>
														);

													}
												)
											}
										</select>

										<label className='form-label'>STATUS:</label>
										<select
											className='form-select form-select-sm'
											value={selectedContact.STATUS || ''}
											onChange={propertyChange}
											property='STATUS'
										>
											<option value='AC'>Active</option>
											<option value='IN'>Inactive</option>
										</select>

										<label className='form-label'>Start Date</label>
										<input
											className="form-control form-control-sm"
											type="month"
											value={
												selectedContact.START_DATE
													?
													toDatePickerMonthString(selectedContact.START_DATE)
													:
													new Date()
											}
											onChange={dateChange}
											property='START_DATE'
											style={{ maxWidth: '180px' }}
										/>

										<label className='form-label'>End Date:</label>
										<input
											className="form-control form-control-sm"
											type="date"
											value={
												selectedContact.END_DATE
													?
													toDatePickerString(selectedContact.END_DATE)
													:
													new Date()
											}
											onChange={dateChange}
											property='END_DATE'
											style={{ maxWidth: '180px' }}
										/>

										<label className='form-label'>Last Name:</label>
										<input
											className='form-control form-control-sm'
											type='text'
											value={selectedContact.NAME || ''}
											onChange={propertyChange}
											property='NAME'
										/>

										<label className='form-label'>First Name:</label>
										<input
											className='form form-control form-control-sm'
											type='text'
											value={selectedContact.FIRST_NAME || ''}
											onChange={propertyChange}
											property='FIRST_NAME'
										/>

										<label className='form-label'>Saluation:</label>
										<input
											className='form-control form-control-sm'
											type='text'
											value={selectedContact.SALUTATION || ''}
											onChange={propertyChange}
											property='SALUTATION'
										/>

										<label className='form-label'>Street Address:</label>
										<input
											className='form-control form-control-sm'
											type='text'
											value={
												selectedContact.ADDR_NUM && selectedContact.ADDR_NUM.ADDR_LINE1
													?
													selectedContact.ADDR_NUM.ADDR_LINE1
													:
													''
											}
											onChange={propertyChange}
											property='ADDR_NUM.ADDR_LINE1'
										/>
										<label className='form-label'>City:</label>
										<input
											className='form-control form-control-sm'
											type='text'
											value={
												selectedContact.ADDR_NUM && selectedContact.ADDR_NUM.CITY
													?
													selectedContact.ADDR_NUM.CITY
													:
													''
											}
											onChange={propertyChange}
											property='ADDR_NUM.CITY'
										/>
										<label className='form-label'>State:</label>
										<select
											className='form-select form-select-sm'
											value={
												selectedContact.ADDR_NUM &&
													selectedContact.ADDR_NUM.STATE &&
													selectedContact.ADDR_NUM.STATE.STANAME
													?
													selectedContact.ADDR_NUM.STATE.STANAME
													:
													''
											}
											onChange={propertyChange}
											property='ADDR_NUM.STATE'
										>
											<option value="">-- Select a state --</option>
											{
												states.map(
													state => {

														return (
															<option
																key={state.STATE}
																value={state.STANAME}
															>
																{state.STANAME}
															</option>
														);

													}
												)
											}
										</select>
										<label className='form-label'>Postal Code:</label>
										<input
											className='form-control form-control-sm'
											type='text'
											value={
												(selectedContact.ADDR_NUM &&
													selectedContact.ADDR_NUM.POSTAL_CDE)
													?
													selectedContact.ADDR_NUM.POSTAL_CDE
													:
													''
											}
											onChange={propertyChange}
											property='ADDR_NUM.POSTAL_CDE'
										/>
										<label className='form-label'>Country:</label>
										<select
											className='form-select form-select-sm'
											value={
												selectedContact.ADDR_NUM &&
													selectedContact.ADDR_NUM.COUNTRY_OBJ &&
													selectedContact.ADDR_NUM.COUNTRY_OBJ.COUNTRY_ABBR
													?
													selectedContact.ADDR_NUM.COUNTRY_OBJ.COUNTRY_ABBR
													:
													''
											}
											onChange={propertyChange}
											property='ADDR_NUM.COUNTRY_OBJ'
										>
											<option value="">-- Select a country --</option>
											{
												countries.map(
													country => {

														return (
															<option
																key={country.COUNTRY_ABBR}
																value={country.COUNTRY_ABBR}
															>
																{country.COUNTRY_NAME}
															</option>
														);

													}
												)
											}
										</select>

									</div>
									{/* Column 2 */}
									<div className='col-lg-3 col-md-3 col-sm-12'>

										<label className='form-label'>Phone:</label>
										<input
											className='form-control form-control-sm'
											type='tel'
											value={selectedContact.PHONE_NUM_raw || ''}
											onChange={propertyChange}
											property='PHONE_NUM_raw'
										/>

										<label className='form-label'>Fax:</label>
										<input
											className='form-control form-control-sm'
											type='tel'
											value={selectedContact.FAX_NUM_raw || ''}
											onChange={propertyChange}
											property='FAX_NUM_raw'
										/>

										{/*
										<label className='form-label'>Number:</label>
										<input
											className='form form-control form-control-sm'
											type='text'
											value={selectedContact.Number || ''}
											onChange={propertyChange}
											property='Number'
										/>
										*/}

										<label className='form-label'>Title:</label>
										<input
											className='form form-control form-control-sm'
											type='text'
											value={selectedContact.TITLE || ''}
											onChange={propertyChange}
											property='TITLE'
										/>

										<label className='form-label'>Ext:</label>
										<input
											className='form form-control form-control-sm'
											type='text'
											value={selectedContact.PHONE_NUM_EXT || ''}
											onChange={propertyChange}
											property='PHONE_NUM_EXT'
										/>

										<label className='form-label'>Email:</label>
										<input
											className='form form-control form-control-sm'
											type='email'
											value={selectedContact.EMAIL_ID || ''}
											onChange={propertyChange}
											property='EMAIL_ID'
										/>

										<label className='form-label'>Carrier:</label>
										<input
											className='form form-control form-control-sm'
											type='text'
											value={selectedContact.CARRIER || ''}
											onChange={propertyChange}
											property='CARRIER'
										/>

										<label className='form-label'>Mobile:</label>
										<input
											className='form form-control form-control-sm'
											type='tel'
											value={selectedContact.mobile_phone_raw || ''}
											onChange={propertyChange}
											property='mobile_phone_raw'
										/>

										{/*
										 <label className='form-label mt-2'>Pref. Method of contact:</label>
										<input
											className='form form-control form-control-sm'
											type='text'
											value={selectedContact.PrefContactMethod || ''}
											onChange={propertyChange}
											property='PrefContactMethod'
										/>
										*/}

									</div>
									{/* Column 3 */}
									<div className='col-lg-3 col-md-3 col-sm-12'>

										<label className='form-label'>User ID:</label>
										<input
											className='form form-control form-control-sm'
											type='text'
											value={
												selectedContact.EbbUserLogin
													?
													selectedContact.EbbUserLogin.USER_ID
													:
													''
											}
											onChange={propertyChange}
											property='EbbUserLogin.USER_ID'
										/>

										<label className='form-label'>Expiration Date</label>
										<input
											className="form-control form-control-sm"
											type="date"
											value={
												selectedContact.EbbUserLogin && selectedContact.EbbUserLogin.EXPIRY_DATE
													?
													toDatePickerString(selectedContact.EbbUserLogin.EXPIRY_DATE)
													:
													new Date()
											}
											onChange={dateChange}
											property='EbbUserLogin.EXPIRY_DATE'
											style={{ maxWidth: '180px' }}
										/>

										<label className='form-label'>Locked</label>
										<select
											className="form-select form-select-sm"
											value={
												selectedContact.EbbUserLogin && selectedContact.EbbUserLogin.LOCKED
													?
													selectedContact.EbbUserLogin.LOCKED
													:
													'N'
											}
											onChange={propertyChange}
											property='EbbUserLogin.LOCKED'
										>
											<option value='Y'>Yes</option>
											<option value='N'>No</option>
										</select>

										<label className='form-label'>Is Admin</label>
										<select
											disabled={!isAdmin}
											className="form-select form-select-sm"
											value={selectedContact?.EbbUserLogin?.ADMIN_IND || 'N'
											}
											onChange={propertyChange}
											property='EbbUserLogin.ADMIN_IND'
										>
											<option value='Y'>Yes</option>
											<option value='N'>No</option>
										</select>

										<br />
										{
											showSpinner
												?
												<Spinner status='loading' />
												:
												<button
													type='button'
													className='btn btn-danger btn-sm mt-3'
													onClick={() => {
														dispatch(resetPassword({
															user_id: selectedContact.EbbUserLogin.USER_ID,
															email: selectedContact.EMAIL_ID
														}));
													}}
												>
													Reset Password
												</button>
										}

									</div>
									{/* Column 4 */}
									<div className='col-lg-3 col-md-3 col-sm-12'>

										<h6 className='text-center mt-3'>Email Groups</h6>
										{
											EmailGroupCodes.map(
												code => {
													return (
														<div
															key={code.CDE}
															className="form-check"
														>
															<input
																className="form-check-input"
																type="checkbox"
																email_code={code.CDE}
																checked={
																	selectedContact.ENT_BA_CONTACT_NOTIFICATION
																		?
																		selectedContact.ENT_BA_CONTACT_NOTIFICATION.findIndex(emailGroup => emailGroup.CDE === code.CDE) !== -1
																		:
																		false
																}
																onChange={emailGroupChange}
																id="flexCheckDefault"
															/>
															<label className="form-check-label">
																{code.CDE_TXT}
															</label>
														</div>);
												}
											)
										}

										<h6 className='text-center mt-5'>Roles</h6>

										{
											ContactRoleCodes.map(
												codeObj => {

													return (

														<div className="form-check" key={codeObj.CDE}>
															<input
																className="form-check-input"
																type="checkbox"
																role_code={codeObj.CDE}
																id="flexCheckDefault"
																onChange={roleChange}
																checked={
																	selectedContact.ENT_CONTACT_ROLES
																		?
																		selectedContact.ENT_CONTACT_ROLES.findIndex(contactRole => contactRole.CDE === codeObj.CDE) !== -1
																		:
																		false
																}
															/>
															<label className="form-check-label">
																{codeObj.CDE_TXT}
															</label>
														</div>

													);

												}
											)
										}

									</div>
								</Restricted>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
};


export default EditContact;