import { createSlice } from "@reduxjs/toolkit";

const dailyMeasurement_slice = createSlice({
    name: "DailyMeasurement",
    initialState: {
        showDailyModal: false,
        showHourlyModal: false,
        ptNum: '',
        displayUnits: [],
        pmDisplayUnits: [],
        dailyMeasurementDataTable: [],
        dailyPMMeasurementDataTable: [],
        hourlyPMMeasurementDataTable: [],
        selectedDateHourly: null,
        loading: true,
    },
    reducers: {
        setShowDailyModal: (state, action) => {
            state.showDailyModal = action.payload;
        },
        setShowHourlyModal: (state, action) => {
            state.showHourlyModal = action.payload;
        },
        setPtNum: (state, action) => {
            state.ptNum = action.payload;
        },
        setDisplayUnits: (state, action) => {
            state.displayUnits = action.payload;
        },
        setPMDisplayUnits: (state, action) => {
            state.pmDisplayUnits = action.payload;
        },
        setDailyMeasurementDataTable: (state, action) => {
            state.dailyMeasurementDataTable = action.payload;
        },
        setDailyPMMeasurementDataTable: (state, action) => {
            state.dailyPMMeasurementDataTable = action.payload;
        },
        setHourlyPMMeasurementDataTable: (state, action) => {
            state.hourlyPMMeasurementDataTable = action.payload;
        },
        setSelectedDateHourly: (state, action) => {
            state.selectedDateHourly = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        }
    },
});

export const dailyMeasurement_actions = dailyMeasurement_slice.actions;
export default dailyMeasurement_slice.reducer;