import Table from './Table';

function Notices() {

    return (
        <div className='container-fluid'>
            <Table />
        </div>
    )
}

export default Notices;