import { useState } from 'react';

// images
import logo1 from '../../../assets/images/gastar-logo.png'
import PasswordResetSuccessModal from './PasswordResetSuccessModal';

const ChangePasswordModal = () => {

	// React state
	const [changePasswordState, setChangePasswordState] = useState({
		NewPassword: '',
		ConfirmNewPassword: ''
	});

	// Handlers
	const resetPassword = async (event) => {

		event.preventDefault();

		const requestResetPassword = async () => {

			const response = await fetch('Auth/ResetPassword', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(changePasswordState)
			});

			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

		};

		try {
			console.log('Changing password...');
			await requestResetPassword();
			console.log('Password changed successfully.');
		}
		catch (error) {
			console.log(error.message);
		}

	};

	const changeState = (event) => {

		const newValue = event.target.value;
		const property = event.target.getAttribute('property');
		const newChangePasswordState = { ...changePasswordState };

		newChangePasswordState[property] = newValue;

		setChangePasswordState(newChangePasswordState);

	};

	return (
		<>
			<div className="modal" id="change-password" aria-hidden="true" aria-labelledby="change-password" tabIndex="-1">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header text-center">
							<h1 className="fs-5 text-center">GASTAR Change Password</h1>
							<button type="button" className="btn-close" aria-label="Close"></button>
						</div>
						<form onSubmit={resetPassword}>
							<div className="modal-body justify-content-center text-center fs-5">
								<img src={logo1} alt='Gastar Login' className='img-fluid mb-4' style={{ maxWidth: '200px' }} />
								<br />
								<label htmlFor='text'>Your new password</label>
								<input
									className="form-control mb-3"
									type='text'
									id='newpass'
									required
									value={changePasswordState.NewPassword}
									onChange={changeState}
									property={'NewPassword'}
								/>
								<label htmlFor='text'>Confirm new password</label>
								<input
									className="form-control mb-3"
									type='text'
									id='newconfirmpass'
									required
									value={changePasswordState.ConfirmNewPassword}
									onChange={changeState}
									property={'ConfirmNewPassword'}
								/>
							</div>
							<div className="modal-footer">
								<button
									className='btn btn-lg btn-primary'
									data-bs-toggle='modal'
									data-bs-target="#pass-change-complete"
								>
									Reset Password
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<PasswordResetSuccessModal />
		</>

	);

};

export default ChangePasswordModal;