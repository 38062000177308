import { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import CustomStyles from './Table-Styles';
import { useDispatch, useSelector } from "react-redux";
import { fetchFinPositions, financial_position_actions } from "../../../../store/financial-position-slice";
import Spinner from '../../../Spinner';
import { useNavigate } from 'react-router-dom';
import FinPositionsTableColumns from './FinPositionsTableColumns';


function FinPositionsTable() {

	// Redux
	const FinPositionFilters = useSelector(state => state.financial_position_slice.FinPositionFilters);
	const FinPositions = useSelector(state => state.financial_position_slice.FinPositions);
	const FinPositionsFetchLoading = useSelector(state => state.financial_position_slice.FinPositionsFetchLoading);
	const dispatch = useDispatch();

	// Navigation
	const navigate = useNavigate();

	// API fetch financial positions any time filters change.
	useEffect(() => { dispatch(fetchFinPositions(FinPositionFilters)); }, [FinPositionFilters, dispatch]);

	// Handlers
	const handleRowClick = async (row) => {
		await dispatch(financial_position_actions.setSelectedFinPosition(row));
		navigate('/newFinancialPosition');
	};

	console.log('FinPositionsTable render', FinPositions);

	return (
		<DataTable
			progressPending={FinPositionsFetchLoading}
			progressComponent={<Spinner />}
			// conditionalRowStyles={conditionalRowStyles}
			fixedHeader={true}
			fixedHeaderScrollHeight="700px"
			columns={FinPositionsTableColumns}
			data={FinPositions}
			data-tag="allowRowEvents"
			type='button'
			onRowClicked={handleRowClick}
			striped={true}
			highlightOnHover={true}
			customStyles={CustomStyles}
			dense={true}
			className="pb-1"
		/>
	);
};

export default FinPositionsTable;