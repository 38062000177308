import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Fetch matching contracts from API
export const fetchMatchingContracts = createAsyncThunk(
    'Measurement/getMatchingContracts',
    async ({ startDate, endDate, page = 10, limit = 500, ba_id }) => {
        const queryString = new URLSearchParams({
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            ContractBAID: ba_id,
            page: page,
            limit: limit,

        }).toString();

        const response = await fetch(`Measurement/GetMatchingContracts?${queryString}`);

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    }
);

export const fetchMeasurementContracts = createAsyncThunk(
    'Measurement/GetMeasurementContracts',
    async({ 
        buId = '', 
        baId = '',
        withPriorPPA = false, 
        withLaterPPA = false,
        fromGasFlowDate = '',
        toGasFlowDate = '',
    }) => {
        const queryString = new URLSearchParams({
            buId: buId,
            baId: baId,
            withPriorPPA: withPriorPPA,
            withLaterPPA: withLaterPPA,
            fromGasFlowDate: fromGasFlowDate?.toISOString(),
            toGasFlowDate: toGasFlowDate?.toISOString(),
        }).toString();
        const url = `Measurement/GetMeasurementContracts?${queryString}`
        const res = await fetch(url);

        if (!res.ok) {
            const message = await res.text();
            throw new Error(message);
        }

        const data = await res.json();
        return data;
    }
);

export const fetchMatchingGasAllocation = createAsyncThunk(
    'Measurement/GetMatchingGasAllocation',
    async ({ ba_id, month, cntr_nums }) => {
        const queryString = new URLSearchParams({
            ba_id: ba_id,
            month: month.toISOString(),
            cntr_nums: cntr_nums
        }).toString();

        const response = await fetch(`Measurement/GetMatchingGasAllocation?${queryString}`);
        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    }
);

const measurement = createSlice({
    name: 'measurement',
    initialState: {
        matchingContractsData: [],
        matchingGasAllocationData: {}, // both current month and prior month
        selectedContractsData: {},
        currentPage: 1,
        totalItems: 0,
        hasMore: true,
        status: 'idle',
        error: null,
        loading: false,
        reportViewerOpen: false,
    },
    reducers: {
        setOpenReportViewer(state, action) {
            state.reportViewerOpen = action.payload;
        },
        setSelectedContractsData(state, action) {
            state.selectedContractsData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMatchingContracts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMatchingContracts.fulfilled, (state, action) => {
                // console.log("Reducer received data:", action.payload);
                state.matchingContractsData = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchMatchingContracts.rejected, (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            })
            .addCase(fetchMatchingGasAllocation.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMatchingGasAllocation.fulfilled, (state, action) => {
                state.matchingGasAllocationData = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchMatchingGasAllocation.rejected, (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            })
            .addCase(fetchMeasurementContracts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMeasurementContracts.fulfilled, (state, action) => {
                state.matchingContractsData = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchMeasurementContracts.rejected, (state, action) => {
                state.error = action.error.message;
                state.status = 'failed';
            });
    }
});

export const { filterContractsByDate } = measurement.actions;
export const measurement_actions = measurement.actions;
export default measurement.reducer;

