import { useState, useEffect, useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';

//Image imports
import Clear from '../../../../assets/images/clear.png';
import Cancel from '../../../../assets/images/cancel.png';

import './LookupModalStyling.css';
import { notification_actions } from '../../../../store/notification-slice';
import SearchBar from '../../SearchBar';
import DataTable from 'react-data-table-component';
import Spinner from '../../../Spinner';
import CustomStyles from './SSM-Table-Style';
import { useRef } from 'react';

// Modal styling
const customStyles = {
	overlay: {
		backgroundColor: 'rgb(0,0,0,.60'
	},
};

/**
 * Common component that can be used to create lookup modals. This lookup modal uses
 * the "useForm" hook from react-hook-form.
 * @param {any} param0
 */
function ReactHookFormLookupModal({
	isOpen,
	setIsOpen,
	url,
	columns,
	modalTitle,
	form,
	propertyName,
	propertyIdName,
	usePagination,
	afterSetValue // Function to be called after new value is set.
}) {

	// Redux
	const dispatch = useDispatch();

	// React Hook Form
	const { getValues, formState, setValue } = useMemo(() => { return form; }, [form]);
	const { isSubmitted } = useMemo(() => { return formState; }, [formState]);

	// State
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();
	const [filteredData, setFilteredData] = useState();
	const [pageNumber, setPageNumber] = useState(1);
	const pageSize = 50;
	const [totalRows, setTotalRows] = useState();

	// API fetch
	const abortControllerRef = useRef();
	useEffect(() => {
		const handleAsync = async () => {
			try {
				setLoading(true);

				// Abort previous request
				if (abortControllerRef.current) {
					console.log('aborting previous request...');
					abortControllerRef.current.abort();
				}

				// Create new abort controller
				abortControllerRef.current = new AbortController();
				const { signal } = abortControllerRef.current;

				// Send request.
				const newUrl = usePagination ? `${url}&pageNumber=${pageNumber}&pageSize=${pageSize}` : url;
				const response = await fetch(newUrl, { signal });

				// Handle error.
				if (!response.ok) {
					const message = await response.text();
					throw new Error(message);
				}

				// Handle response data.
				if (usePagination) {
					const { newData, newTotalRows } = await response.json();
					setData(newData);
					setTotalRows(newTotalRows);
				}
				else {
					const newData = await response.json();
					setData(newData);
				}

				// Set loading to false
				setLoading(false);
			}
			catch (error) {
				if (error.name === 'AbortError') {
					console.log(error.message);
					return;
				}

				// Else, display error.
				await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
				setLoading(false);
			}
		};
		handleAsync();
	}, [url, dispatch, pageNumber, usePagination]);

	const closeModal = useCallback(() => { setIsOpen(false); }, [setIsOpen]);

	// Also used as onChange event handler for checkbox column.
	const handleRowClicked = useCallback((row) => {
		setValue(propertyName, row, { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true }); // Only validate the field if the form is submitted.

		if (afterSetValue && typeof (afterSetValue) === 'function')
			afterSetValue(row);

		closeModal();
	},[closeModal, setValue, isSubmitted, propertyName]);

	const handlePageChange = (pageNumber) => {
		setPageNumber(pageNumber);
	};

	// Need to add a column with a checkbox.
	const columnsWithCheckbox = useMemo(() => {
		const selectedValue = getValues(propertyName);
		return [
			{
				cell: row => <input type="checkbox" checked={selectedValue?.[propertyIdName] === row?.[propertyIdName]} onChange={() => { handleRowClicked(row) }} />,
				allowOverflow: true,
				button: true,
			},
			...columns
		];
	}, [columns, getValues, handleRowClicked, propertyIdName, propertyName, formState]);

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={closeModal}
			className="lookup-modal container-fluid bg-white text-black"
			style={customStyles}
		>
			<div id="LookupModal" tabIndex='-1'>
				<div className='modal-dialog '>
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title mb-2">{modalTitle || 'Lookup Modal'}</h4>
							<button
								type="button"
								className="btn-close fs-6"
								onClick={closeModal}
							>
							</button>
						</div>
						<div className="modal-body">

							{/* // BUTTONS */}
							<div className="row text-start bg-light-blue rounded-top m-0 p-2">
								<div className="col-lg-3 col-md-12 col-md-12 col-sm-12">
									<button
										type="button"
										className="btn btn-primary m-1"
										title="New Deal"
										onClick={closeModal}
									>
										<img src={Clear} alt="Clear" /> Clear
									</button>
									<button
										type="button"
										className="btn btn-primary m-1"
										title="Cancel"
										onClick={closeModal}
									>
										<img src={Cancel} alt="AddImage" /> Cancel
									</button>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-12">
									<SearchBar
										data={data}
										setFilteredData={setFilteredData}
									/>
								</div>
							</div>
							<div className='row text-start'>
								<DataTable
									progressPending={loading}
									progressComponent={<Spinner />}
									className='table-striped pb-1'
									fixedHeader={true}
									fixedHeaderScrollHeight="700px"
									columns={columnsWithCheckbox}
									data={filteredData}
									striped={true}
									highlightOnHover={true}
									customStyles={CustomStyles}
									onRowClicked={handleRowClicked}
									selectableRowsHighlight
									selectableRowsNoSelectAll
									dense={true}
									pagination={usePagination}
									paginationServer
									paginationTotalRows={totalRows}
									paginationPerPage={pageSize}
									paginationComponentOptions={{ noRowsPerPage: true }}
									onChangePage={handlePageChange}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ReactHookFormLookupModal;