import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import CustomStyles from './Table-Styles';

// columns data:
const columns = [

    {
        name: 'Dir',
        selector: row => row.dir,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },
    {
        name: '# Contracts',
        selector: row => row.contracts,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },

    {
        name: 'Quantity',
        selector: row => row.quantity,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },
    {
        name: 'Strike Price',
        selector: row => row.strikePrice,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true

    },
    {
        name: 'Premium',
        selector: row => row.premium,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true

    },

    {
        name: 'Settle Date',
        selector: row => row.settleDate,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },
    {
        name: 'Premium Settle Date',
        selector: row => row.prem_settleDate,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },
    {
        name: 'Book/Portfolio',
        selector: row => row.book,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },


];



function SaleTable() {

    //Code for handling the initial data
    const initialData = [
        {
            id: '1',
            dir: 'Long/Short',
            contracts: '1',
            quantity: '10,000',
            strikePrice: '3.00',
            premium: '.25',
            settleDate: 'Oct-23',
            prem_settleDate: 'Jun-23',
            book: ''

        }
    ];

    //Code for handling the initial data
    useEffect(() => {
        setTableData(initialData.map((row, index) => ({ ...row, key: String(index) })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [tableData, setTableData] = useState([]);
    const [initialTableData] = useState(initialData);
    const [newRow, setNewRow] = useState({

        dir: '',
        contracts: '',
        quantity: '',
        strikePrice: '',
        premium: '',
        settleDate: '',
        prem_settleDate: '',
        book: '',
        editable: true
    });

    //Code for handling the Row Click functionality
    const handleRowClick = (row) => {
        const updatedData = tableData.map((item) => {
            if (item === row) {
                return {
                    ...item,
                    editable: true
                };
            }
            return item;
        });
        setTableData(updatedData);
    };

    //Code for handling the Cell Update functionality
    const handleCellUpdate = (newValue, rowKey, columnKey) => {
        const updatedData = [...tableData];
        const rowIndex = updatedData.findIndex((row) => row.key === rowKey);
        updatedData[rowIndex][columnKey] = newValue;
        setTableData(updatedData);
    };

    //Code for handling the Reset Data functionality
    const resetData = () => {
        setTableData(initialTableData);
    };

    //Code for handling the Add Row functionality
    const addRow = () => {
        const newRowData = { ...newRow };
        setTableData([...tableData, newRowData]);
        setNewRow({
            dir: '',
            contracts: '',
            quantity: '',
            strikePrice: '',
            premium: '',
            settleDate: '',
            prem_settleDate: '',
            book: '',
            editable: true
        });
    };

    //Code for handling the Edit functionality
    const editableColumns = columns.map((column) => {
        if (column.name === 'Dir') {
            return {
                ...column,
                cell: (row) =>
                    row.editable ? (
                        <select
                            value={row.dir}
                            onChange={(e) => handleCellUpdate(e.target.value, row.key, column.selector(row))}
                        >
                            <option value="1">Long</option>
                            <option value="2">Short</option>
                        </select>
                    ) : (
                        column.selector(row)
                    ),
            };
        } else {
            return {
                ...column,
                cell: (row) =>
                    row.editable ? (
                        <input
                            type="text"
                            value={row[column.selector(row)]}
                            onChange={(e) => handleCellUpdate(e.target.value, row.key, column.selector(row))}
                        />
                    ) : (
                        column.selector(row)
                    ),
            };
        }
    });


    return (
        <div>
            <button onClick={addRow} className="btn btn-sm btn-primary me-1 mb-1">
                Add Row
            </button>
            <button onClick={resetData} className="btn btn-sm btn-primary me-1 mb-1">
                Reset Data
            </button>
            <DataTable
                columns={editableColumns}
                data={tableData}
                onRowClicked={handleRowClick}
                striped={true}
                highlightOnHover={true}
                customStyles={CustomStyles}
                dense={true}
                className="pb-1"
            />
        </div>
    );
}

export default SaleTable;