import React, { useState, useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { physical_trade_deals_actions } from '../../../../store/physical-trade-deals-slice';
import { fetchAllFuturePrice, fetchFuturePriceDetails } from '../../../../store/physical-trade-deals-thunks';

//Import custom components:
import Spinner2 from '../../../CustomComponents/Spinner';
import ExportExcel from "./ExportExcel";

//Images:
import newBtn from '../../../../assets/images/add.gif';


import './NymexModalStyling.css'
import NymexTable from './NymexTable';

// import ExcelUpload from '../../../CustomComponents/ExcelUpload';


// Modal styling
const customStyles = {
    overlay: {
        backgroundColor: 'rgb(0,0,0,.60'
    },
};

// Move this line outside the component
Modal.setAppElement('#root');

function NymexModal() {

    const todayMonthYear = new Date().toISOString().slice(0, 7);
    const today = new Date().toISOString().slice(0, 7); // get today's date in YYYY-MM-DD format

    // State:
    const [selectedPriceId, setSelectedPriceId] = useState('');
    const [showAllData, setShowAllData] = useState(false);
    // const [uploadedExcelData, setUploadedExcelData] = useState([excelData]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(todayMonthYear);
    //Reudx:
    const dispatch = useDispatch();
    const futurePrice = useSelector((state) => state.physical_trade_deals_slice.futurePrice);
    const openNymexModal = useSelector((state) => state.physical_trade_deals_slice.openNymexModal);
    const futurePriceDetails = useSelector(state => state.physical_trade_deals_slice.futurePriceDetails);

    const memoizedFuturePriceDetails = useMemo(() => futurePriceDetails, [JSON.stringify(futurePriceDetails)]);

    //useEffects:
    useEffect(() => {
        dispatch(fetchAllFuturePrice());
    }, [dispatch]);


    // This function now only sets the selectedPriceId.
    const handlePriceRefIndexChange = (e) => {
        const selectedId = e.target.value;
        setSelectedPriceId(selectedId);
    };


    useEffect(() => {
        if (selectedPriceId) {
            setIsLoading(true);
            dispatch(fetchFuturePriceDetails(selectedPriceId)).then(() => {
                setIsLoading(false);
                if (memoizedFuturePriceDetails) {
                    const formattedDetails = memoizedFuturePriceDetails.map(detail => {
                        if (detail.TRADE_REF_DATE) {
                            // Format the date to YYYY-MM-DD
                            const tradeRefDate = new Date(detail.TRADE_REF_DATE).toISOString().split('T')[0];
                            return {
                                ...detail,
                                formattedDate: tradeRefDate, // This is now in YYYY-MM-DD
                            };
                        }
                        return detail;
                    });

                    // Find the first available date for the selected price ID
                    const firstAvailableDetail = formattedDetails.find(detail => detail.FUTURE_PRICE_ID === selectedPriceId);
                    if (firstAvailableDetail) {
                        // Format the earliest date to YYYY-MM for the month input
                        const earliestDate = new Date(firstAvailableDetail.TRADE_REF_DATE).toISOString().slice(0, 7);
                        setSelectedDate(earliestDate);
                    } else {
                        // If no date is available, clear the selection or set a default
                        setSelectedDate('');
                    }

                    // Filter details based on the selectedDate or show all data
                    let filteredDetails = showAllData ? formattedDetails : formattedDetails.filter(detail => {
                        return detail.formattedDate.slice(0, 7) === selectedDate;
                    });

                    setFilteredData(filteredDetails);
                }
            });
        }
    }, [selectedPriceId, dispatch, showAllData, memoizedFuturePriceDetails, selectedDate]);


    function closeModal() {
        dispatch(physical_trade_deals_actions.setOpenNymexModal(false));
        setSelectedPriceId(''); // Resets the selected price id field to blank.
        setShowAllData(false);
        setFilteredData([]);
    }

    function resetFields() {
        setSelectedPriceId('');
        setShowAllData(false);
        setFilteredData([]);
        setSelectedDate(todayMonthYear);


    }


    return (
        <div>
            <Modal
                isOpen={openNymexModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Nymex Modal"
                className='nymex-modal modal-dialog modal-dialog-centered p-0'
            >

                <div className="modal-content ">
                    <div className="modal-header mt-0">
                        <h5 className="modal-title p-1" id="nymexModalLabel">Forward Pricing UI</h5>
                        <button type="button" className="btn-close text-end p-1 m-1" onClick={closeModal} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row m-0">
                            <div className="col-lg-12 col-sm-12 col-md-12 ">
                                <button className='btn btn-sm btn-primary me-2 mb-1' onClick={resetFields}><img src={newBtn} alt='New Trade' /> New</button>
                                <ExportExcel data={filteredData} />
                            </div>

                        </div>
                        <div className="row text-start bg-light-blue  m-0 p-0">
                            <label>Price Reference</label>
                            <hr className='p-0' />
                            {/* <div className="col-lg-3 col-md-12 col-md-12 col-sm-12">
                                <label>
                                    Type:
                                </label>
                                <select
                                    className="form-select form-select-sm"
                                    aria-label="Default select example"
                                    name='Type'
                                // onChange={handlePriceRefIndexChange}
                                >
                                    <option value=''>Commodity</option>
                                    <option value=''>Basis</option>
                                </select>
                            </div> */}
                            {/* <div className="col-lg-3 col-md-12 col-md-12 col-sm-12">
                                <label>
                                    Symbol:
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    aria-label="Default select example"
                                    name='Type'
                                // onChange={handlePriceRefIndexChange}
                                >
                                </input>
                            </div> */}
                            <div className="col-lg-3 col-md-12 col-md-12 col-sm-12">
                                <label>
                                    Name:
                                </label>
                                <select
                                    className="form-select form-select-sm"
                                    aria-label="Name Select"
                                    name='Type'
                                    value={selectedPriceId}
                                    onChange={handlePriceRefIndexChange}
                                >
                                    <option value="">Select Name</option>
                                    {futurePrice.map((price, index) => (
                                        <option key={index} value={price.FUTURE_PRICE_ID}>{price.FUTURE_PRICE_NAME}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-3 col-md-12 col-md-12 col-sm-12">
                                <label>
                                    Future Price ID:
                                </label>
                                <input
                                    className="form-control form-control-sm"
                                    aria-label="Default select example"
                                    name='Type'
                                    value={selectedPriceId}

                                >
                                </input>
                            </div>
                        </div>
                        <div className="row text-start bg-light-blue  m-0 p-1">
                            <label>Price Detail</label>
                            <hr />
                            <div className="col-lg-3 col-md-12 col-md-12 col-sm-12">
                                <label>
                                    Date Reference:
                                </label>

                                <input
                                    value={selectedDate || today}
                                    name='REFDATE'
                                    className="form-control form-control-sm"
                                    type="month"
                                    onChange={(e) => setSelectedDate(e.target.value)}
                                    disabled={showAllData}
                                />

                            </div>
                            <div className="col-lg-3 col-md-12 col-md-12 col-sm-12">
                                <label>
                                    Number of months:
                                </label>
                                <input className='form-control form-control-sm' readOnly>
                                </input>
                            </div>
                            {/* <div className='col-lg-2 col-md-12 col-sm-12'>
                                <label className='label'>Show All Data:</label>
                                <br />
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={showAllData}
                                    onChange={(e) => setShowAllData(e.target.checked)}
                                />
                            </div> */}
                        </div>
                        {/* Data Table section */}
                        <div className="row text-start bg-light-blue  m-0 pb-2">
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <label>
                                    Price Details
                                </label>
                                {isLoading ? <Spinner2 /> :
                                    <NymexTable
                                        data={filteredData}
                                    />
                                }
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                    </div>
                </div>

            </Modal >
        </div >
    );
}

export default NymexModal;
