import { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBARoles, business_selector_actions } from '../../store/business-selector-slice';

const BARoleSelect = () => {
	// Redux
	const baRoles = useSelector(state => state.business_selector_slice.baRoles);
	const baRole = useSelector(state => state.business_selector_slice.baRole);
	const dispatch = useDispatch();
	const isAdmin = useSelector(state => state.auth_slice.isAdmin);
	const userBARoles = useSelector(state => state.auth_slice.userBARoles);

	// State
	const [loading, setLoading] = useState(false);

	// API fetch
	useEffect(() => {
		const handleAsync = async () => {
			setLoading(true);
			await dispatch(fetchBARoles());
			setLoading(false);
		};
		handleAsync();
	}, [dispatch]);

	// Handlers
	const baRoleChange = (event) => {
		const newBARoleCDE = event.target.value;
		const newBARole = baRoles.find(role => role.CDE === newBARoleCDE);
		dispatch(business_selector_actions.changeBARole(newBARole));
	};

	return (
		<label className='ms-1 me-1'>
			BA Role:
			<select
				disabled={!isAdmin && userBARoles?.length === 1}
				className="form-select form-select-sm"
				type="form-select"
				style={{ maxWidth: "300px" }}
				onChange={baRoleChange}
				value={baRole?.CDE || ''}
			>
				{
					loading
						?
						<option>Loading...</option>
						:
						<Fragment>
							<option value="">--Select a BA Role--</option>
							{
								Array.isArray(baRoles) && baRoles.map(
									role => {
										if (isAdmin || userBARoles?.find(userBARole => userBARole.CDE === role.CDE)) {
											return (
												<option
													key={role.CDE}
													value={role.CDE}
												>
													{role.CDE_TXT}
												</option>
											);
										}
										return null;
									}
								)
							}
						</Fragment>
				}
			</select>
		</label>
	);
};

export default BARoleSelect;