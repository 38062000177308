import { useEffect, useState } from 'react'


/* DCQ Table */
export default function DCQTable({ componentState }) {

	const [totals, setTotals] = useState({})

	// Gets totals and formats numbers anytime monthly summary details change
	useEffect(() => {
		let newTotals = {}
		componentState.monthlySummaryDetails.forEach(
			detail => {
				Object.keys(detail).forEach(
					key => {
						if (typeof (detail[key]) === 'number') {
							if (!newTotals[key]) {
								newTotals[key] = detail[key] // set to total if not defined
							}
							else {
								newTotals[key] += detail[key] // else it is defined so just add to it
							}
							detail[key] = detail[key].toLocaleString("en-US", { minimumFractionDigits: 2 }) // format number
						}
					}
				)
			}
		)

		// format totals
		Object.keys(newTotals).forEach(
			key => {
				newTotals[key] = newTotals[key].toLocaleString("en-US", { minimumFractionDigits: 2 })
			}
		)

		// set componentState
		setTotals(newTotals)
	}, [componentState.monthlySummaryDetails])



	return (
		<div>
			<div className="d-flex"><p className='pe-5 fw-bold'>DCQ Monthly Summary Details</p><p className="ps-5 fw-bold">Pool usage quanities are not final until Pool Status shows 'YES'</p></div>
			{
				componentState.monthlyTableLoading
					?
					<div className="container-fluid">
						<div className="row justify-content-center">
							<div className="col-auto">
								<p>Loading...</p>
							</div>
							<div className="col-auto">
								<div
									className="spinner-border spinner-border-sm"
									role="status"
									aria-hidden="true">
								</div>
							</div>
						</div>
					</div>
					:
					componentState.monthlySummaryDetails.length > 0
						?
						<div className='table-responsive'>
							<table className="bg-white table table-sm table-hover table-striped-columns table-bordered border-primary rounded pb-1"
								id='dcqTable'>
								<thead>
									{
										// Header
										<tr>
											<th>Pool ID</th>
											<th>Supplier</th>
											<th>Pool Status</th>
											<th>Nom/Sch</th>
											<th>Pool Energy</th>
											<th>Imbalance</th>
											<th>Imbal Trade</th>
											<th>Net Imbalance</th>
											<th>Imbalance %</th>
											<th>Tier 1 QTY</th>
											<th>Tier 2 QTY</th>
											<th>Tier 3 QTY</th>
											<th>Tier 4 QTY</th>
											<th>CASH OUT</th>
											<th>Updated By</th>
											<th>Updated On</th>
										</tr>
									}
								</thead>
								<tbody>
									{
										// Data
										componentState.monthlySummaryDetails.map(
											detail => {
												return (
													<tr key={detail.PT_MKPOOL_IG_KEY}>
														<td>{detail.PointObj ? detail.PointObj.POINT_ID : ""}</td>
														<td>{detail.MarketerObj ? detail.MarketerObj.SHORTNAME : ""}</td>
														<td>{detail.APPROVE_FLAG === "Y" ? "YES" : "NO"}</td>
														<td>{detail.PL_NOM_QTY}</td>
														<td>{detail.ENERGY}</td>
														<td>{detail.USAGE_IMBAL}</td>
														<td>{detail.TRADE_QTY_NULL}</td>
														<td>{detail.USAGE_IMBAL}</td>
														<td>{detail.PCT_USAGE_IMBAL}</td>
														<td>{detail.TIER1_IMBAL}</td>
														<td>{detail.TIER2_IMBAL}</td>
														<td>{detail.TIER3_IMBAL}</td>
														<td>{detail.TIER4_IMBAL}</td>
														<td>{detail.IMBAL_AMT}</td>
														<td>{detail.USERSTAMP}</td>
														<td>{new Date(detail.DATESTAMP).toDateString()}</td>
													</tr>
												)
											}
										)
									}
									{
										// Totals row
										<tr className="border-top border-2 border-primary">
											<td></td>
											<td></td>
											<td>Totals:</td>
											<td>{totals.PL_NOM_QTY ? totals.PL_NOM_QTY : ""}</td>
											<td>{totals.ENERGY ? totals.ENERGY : ""}</td>
											<td>{totals.USAGE_IMBAL ? totals.USAGE_IMBAL : ""}</td>
											<td>{totals.TRADE_QTY_NULL ? totals.TRADE_QTY_NULL : ""}</td>
											<td>{totals.USAGE_IMBAL ? totals.USAGE_IMBAL : ""}</td>
											<td>{totals.PCT_USAGE_IMBAL ? totals.PCT_USAGE_IMBAL : ""}</td>
											<td>{totals.TIER1_IMBAL ? totals.TIER1_IMBAL : ""}</td>
											<td>{totals.TIER2_IMBAL ? totals.TIER2_IMBAL : ""}</td>
											<td>{totals.TIER3_IMBAL ? totals.TIER3_IMBAL : ""}</td>
											<td>{totals.TIER4_IMBAL ? totals.TIER4_IMBAL : ""}</td>
											<td>{totals.IMBAL_AMT ? totals.IMBAL_AMT : ""}</td>
											<td></td>
											<td></td>
										</tr>
									}
								</tbody>
							</table>
						</div>
						:
						<p className="text-center">No Records Found.</p>
			}
		</div>
	)
}