import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { physical_trade_deals_actions } from '../../../store/physical-trade-deals-slice';

import CustomStyles from './SSM-Table-Style';
import Spinner from '../../Spinner';

function Table({ pipelines, loading }) {

	// Redux
	const selectedBidPkg = useSelector(state => state.physical_trade_deals_slice.selectedBidPkg);
	const dispatch = useDispatch();

	const columns = [
		{
			cell: row => <input type="checkbox" checked={row.BA_ID === selectedBidPkg?.PIPELINE_ID_OBJ?.BA_ID} onChange={() => { handleRowClicked(row) }} />,
			allowOverflow: true,
			button: true,
		},
		{
			name: 'Name',
			selector: row => row.NAME,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Duns #',
			selector: row => row.BA_USER_ID,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'BA Id',
			selector: row => row.BA_ID,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true

		}
	];

	//#region Row Selection
	const rowSelect = ({ selectedRows }) => {
		if (selectedRows?.length < 1)
			return;
		const selectedRow = selectedRows[0];
		handleRowClicked(selectedRow);
	};

	const handleRowClicked = (row) => {
		if (row.BA_ID === selectedBidPkg?.PIPELINE_ID_OBJ?.BA_ID) {
			dispatch(physical_trade_deals_actions.setOpenTransportModal(false));
			return;
		}
		dispatch(physical_trade_deals_actions.setSelectedBidPkg({
			...selectedBidPkg,
			PIPELINE_ID_OBJ: row,
			START_PT_NUM: null,
			END_PT_NUM: null,
			DeliveryPoint: null
		}));

		// hide modal after row is finished selecting
		dispatch(physical_trade_deals_actions.setOpenTransportModal(false));
	};
	//#endregion

	return (
		<DataTable
			progressPending={loading}
			progressComponent={<Spinner status='loading' />}
			className='table-striped pb-1'
			fixedHeader={true}
			fixedHeaderScrollHeight="700px"
			columns={columns}
			data={pipelines}
			striped={true}
			highlightOnHover={true}
			customStyles={CustomStyles}
			onRowClicked={handleRowClicked}
			dense={true}
		/>
	);
};

export default Table;