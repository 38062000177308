import { createSlice } from '@reduxjs/toolkit';
import { getMonthStart } from '../utils/DateUtils';
import { notification_actions } from './notification-slice';

const initialState = {
	gasFlowDate: getMonthStart(new Date()).toISOString(),
	baRole: sessionStorage.getItem('baRole') && sessionStorage.getItem('baRole') !== 'undefined' ? JSON.parse(sessionStorage.getItem('baRole')) : {},
	selectedSupplier: sessionStorage.getItem('selectedSupplier') ? JSON.parse(sessionStorage.getItem('selectedSupplier')) : {},
	baRoles: sessionStorage.getItem('baRoles') ? JSON.parse(sessionStorage.getItem('baRoles')) : [],
	loading: false,
	processes: 0,
	suppliers: [],
	businessUnit: sessionStorage.getItem('businessUnit') || {}
};


const business_selector_slice = createSlice({
	name: "business_selector_slice",
	initialState,
	reducers: {
		changeGasFlowDate(state, action) {
			state.gasFlowDate = action.payload;
		},
		changeBuID(state, action) {
			state.buID = action.payload;
		},
		changeBARole(state, action) {
			state.baRole = action.payload;
			sessionStorage.setItem('baRole', JSON.stringify(action.payload));
		},
		setSelectedSupplier(state, action) {
			const selectedSupplier = action.payload;
			state.selectedSupplier = selectedSupplier;
			sessionStorage.setItem('selectedSupplier', JSON.stringify(selectedSupplier));
		},
		setBARoles(state, action) {
			const baRoles = action.payload;
			state.baRoles = baRoles;
			sessionStorage.setItem('baRoles', JSON.stringify(baRoles));
		},
		setLoading(state, action) {
			const newState = action.payload;
			if (newState) {
				state.processes++;
				state.loading = true;
			}
			else {
				state.processes--;
				if (state.processes < 1)
					state.loading = false;
			}
		},
		setSuppliers(state, action) {
			state.suppliers = action.payload;
		},
		setBusinessUnit(state, action) {
			state.businessUnit = action.payload;
		}
	}
});

export const fetchBARoles = () => {
	return async (dispatch) => {
		const handleFetch = async () => {
			const response = await fetch('GeneralData/GetCodes?cde_type=ROLE&hasBA=true');
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}
			return await response.json();
		};
		try {
			await dispatch(business_selector_actions.setLoading(true));
			const baRoles = await handleFetch();
			await dispatch(business_selector_actions.setBARoles(baRoles));
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
		finally {
			await dispatch(business_selector_actions.setLoading(false));
		}
	};
};
export const fetchSuppliers = (baRole) => {

	return async (dispatch) => {

		const requestSuppliers = async () => {

			if (!baRole?.CDE)
				return;

			const url = `GeneralData/GetBAs?ba_role=${baRole.CDE}`;

			const response = await fetch(url);

			if (!response.ok) {

				const message = await response.text();
				throw new Error(message);

			}

			const newSuppliers = await response.json();

			dispatch(business_selector_actions.setSuppliers(newSuppliers));

		};

		try {
			await dispatch(business_selector_actions.setLoading(true));
			await requestSuppliers();
		}
		catch (error) {
			console.log(error.message);
		}
		finally {
			await dispatch(business_selector_actions.setLoading(false));
		}
	};

};
export const fetchBusinessUnit = () => {
	return async (dispatch) => {
		const requestData = async () => {
			const response = await fetch('GeneralData/GetBAs?ba_id=00000001');
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}
			return await response.json();
		};
		try {
			await dispatch(business_selector_actions.setLoading(true));
			const data = await requestData();
			await dispatch(business_selector_actions.setBusinessUnit(data?.[0]));
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
		finally {
			await dispatch(business_selector_actions.setLoading(false));
		}
	};
};

export const business_selector_actions = business_selector_slice.actions;
export default business_selector_slice.reducer;