import { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch } from 'react-redux';
import { notification_actions } from '../../../store/notification-slice';


/**
 * Used in Selector and ReactHookFormSelector components. Creates options for the selector input.
 * 
 * Properties:
 * url - API data fetch URL
 * optionKey - Key property for each option in the array.
 * optionText - Text property for each option in the array.
 * shouldLog - Should logging be turned on?
 */
const useCreateOptions = ({url, optionKey, optionText, shouldLog}) => {
	// Used for notifying user.
	const dispatch = useDispatch();

	// State
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	// Options to be displayed in the selector.
	const options = useMemo(() => {
		// Create new options based on new data.
		let newOptions = data?.map(
			item => {
				return (
					<option
						key={item[optionKey]}
						value={item[optionKey]}
					>
						{
							Array.isArray(optionText)
								?
								optionText.map(propertyName => item[propertyName]).join(' ')
								:
								item[optionText]
						}
					</option>
				);
			}
		);

		// Put blank option at top of list.
		newOptions.unshift(<option key='blank' value=''>--Select a value--</option>);

		return newOptions;
	}, [data, optionKey, optionText]);

	// Used to abort a request if another request is made.
	const abortController = useRef();

	// API request for options data.
	useEffect(() => {

		if (shouldLog)
			console.log('Starting API fetch useEffect...');

		const handleAsync = async () => {
			try {
				setLoading(true);

				// Abort previous request.
				if (abortController.current)
					abortController.current.abort();

				// Create a new abort controller and set it in state.
				abortController.current = new AbortController();

				// Set abortController's signal in fetch and send request to server.
				const { signal } = abortController.current;
				const response = await fetch(url, { signal: signal });

				// Throw response error.
				if (!response.ok) {
					const message = await response.text();
					throw new Error(message);
				}

				const newData = await response.json();

				if (shouldLog)
					console.log('Setting data to:', newData);

				setData(newData);
				setLoading(false);
			}
			catch (error) {
				if (error.name === 'AbortError')
					return;
				await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));

				if (shouldLog)
					console.log('Setting loading to false');

				setLoading(false);
			}
		};
		handleAsync();
	}, [dispatch, url, shouldLog]);

	return {
		options,
		data,
		loading
	};
};

export default useCreateOptions;