import React from 'react';
import excel from '../../assets/images/excel.gif';
import * as XLSX from 'xlsx';

function createExcelFile(data) {
    // Truncate the cell contents if they exceed the maximum allowed length
    const truncatedData = data.map(row =>
        Object.fromEntries(
            Object.entries(row).map(([key, value]) => [key, value && value.toString().substring(0, 32767)])
        )
    );

    const worksheet = XLSX.utils.json_to_sheet(truncatedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
}

function downloadExcelFile(data, module = "") {
    const link = document.createElement('a');
    const blob = createExcelFile(data);
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = `${module}_Report.xlsx`;
    link.click();
    setTimeout(() => {
        URL.revokeObjectURL(url);
    }, 100);
}

const Export = ({ onExport, module = "" }) => (
    <button className="btn btn-md btn-primary me-2 mb-1" onClick={() => onExport()}>
        <img src={excel} alt="Export Excel Report" /> {module} Export
    </button>
);

const ExportExcel = ({ data, module = "" }) => {
    const actionsMemo = React.useMemo(() => <Export onExport={() => downloadExcelFile(data, module)} module={module} />, [data, module]);

    return actionsMemo;
};

export default ExportExcel;
