import React from 'react';
import excel from '../../assets/images/excel.gif';
import * as XLSX from 'xlsx';

/*
function transformData(data) {
    return data.map(row => ({
        // ... Add all the Table's"Column" fields that you want to include in the export
        Agmt: row?.AgreementKey,
        Supplier: row?.ContractWhSettlementObj?.BusAssociate?.NAME,
        Producer: row?.TRProducerBAName,
        ProdDate: row?.TransDateDate,
        PointID: row?.PointShortName,
        OnDate: row?.OnDateString,
        OffDate: row?.OffDateString,
        GrMcfs: row?.GR_VOLUME,
        NtMcfs: row?.NT_VOLUME,
        GrDth: row?.GR_ENERGY_StringFormat,
        NtDth: row?.NT_ENERGY,
        Partic: row?.Partic,
        Amount: row?.TempGrRev,
        Gather: row?.TempCAPAC,
        Extract: row?.TempEXTRACT,
        TotalDed: row?.TempTotalDeducts,
        UpdatedBy: row?.USERSTAMP,
        UpdatedOn: row?.DATESTAMP,
    }));
}
*/

const transformData = (data, columns) => {
    return data.map(row => {
        let newRow = {};
        columns.forEach(column => {
            // Only add columns with a selector function
            if (column.selector && typeof column.selector === 'function') {
                newRow[column.name] = column.selector(row);
            }
        });
        return newRow;
    });
};


function createExcelFile(data) {
    // Truncate the cell contents if they exceed the maximum allowed length
    const truncatedData = data.map(row =>
        Object.fromEntries(
            Object.entries(row).map(([key, value]) => [key, value && value.toString().substring(0, 32767)])
        )
    );

    const worksheet = XLSX.utils.json_to_sheet(truncatedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
}

// Do NOT delete this code, this code is for unfiltered tables:
// function downloadExcelFile(data) {
//     const link = document.createElement('a');
//     const blob = createExcelFile(data);
//     const url = URL.createObjectURL(blob);
//     link.href = url;
//     link.download = 'Measurement_Report.xlsx';
//     link.click();
//     setTimeout(() => {
//         URL.revokeObjectURL(url);
//     }, 100);
// }
function downloadExcelFile(data, fileName, columns) {
    const transformedData = transformData(data, columns);
    const link = document.createElement('a');
    const blob = createExcelFile(transformedData);
    //const blob = createExcelFile(data);
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;
    link.click();
    setTimeout(() => {
        URL.revokeObjectURL(url);
    }, 100);
}

const ExportExcel = ({ data, columns, fileName, className }) => {
    const actionsMemo = React.useMemo(() => {
        return (
            <button
                className={className || "btn btn-primary"}
                onClick={() => { downloadExcelFile(data, fileName, columns); }}
            >
                <img src={excel} alt="Export Excel Report" />
                Export
            </button>
        );
    }, [data, fileName, className, columns]);

    return actionsMemo;
};

export default ExportExcel;
