import DataTable from 'react-data-table-component';

import customStyles from './Table-Styles';

function TablePriceCost({ selectedPriceArea }) {

	const columns = [
		{
			name: 'Issue Date',
			selector: row => new Date(row.ISSUE_DATE).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'Value',
			selector: row => row.PRICE,
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'Est/Act',
			selector: row => row.BEST_PRICE_CDE,
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'Updated By',
			selector: row => row.USERSTAMP,
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'Updated On',
			selector: row => new Date(row.DATESTAMP).toLocaleDateString('en-US', {
				day: 'numeric',
				month: 'long',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric'
			}),
			sortable: true,
			reorder: true,
			wrap: true
		}
	];

	return (
		<div className=''>
			<DataTable
				highlightOnHover
				columns={columns}
				data={selectedPriceArea?.ENT_PRICE_AREA_DTL}
				customStyles={customStyles}
				dense={true}
				// fixedHeader={true}
				fixedHeaderScrollHeight='200px'
				className="pb-1"
			/>
		</div >
	);
}


export default TablePriceCost;