import { useRef } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import addNew from '../../assets/images/add.gif'
import excel from '../../assets/images/excel.gif'
import imbList from '../../assets/images/imb-list.jpg'
import imbStatus from '../../assets/images/imb-status.png'


//Module Imports
import MarketerSupplierImbalance from './Modals/MarketerSupplierImbalance'
import MarketPoolImabalanceUi from './Modals/MarketPoolImbalanceUi'

import ImbalanceTradeList from './Modals/TradeListUi'

function TradeImbalance() {

    // tableRef for Excel Export:
    const tableRef = useRef(null);

    //Handling the Excel Download:
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Imbalance-Trades-Report',
        sheet: 'Imbalance-Trades-Report'
    });

    return (
        <div className='container-fluid'>
            <div className="text-black table-responsive">
                <div className='card mt-2'>
                    <div className='card-header text-center'><h4>Imbalance Trades</h4></div>
                    <div className='card-body'>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 p-2">
                                <button className='btn btn-md btn-primary mb-2 me-2' data-bs-toggle="modal" data-bs-target="#MarketPoolImabalanceUi"><img src={addNew} alt='Add New Imbalance Trade' /> New Imbalance Trade</button>
                                <button className='btn btn-md btn-primary mb-2 me-2' onClick={onDownload}><img src={excel} alt='Export Excel Report'
                                /> Excel Report</button>
                                <button className='btn btn-md btn-primary mb-2 me-2' data-bs-toggle="modal" data-bs-target="#ImbalanceTradeList"><img src={imbList} alt='Imbalance Trade List' /> Imbalance Trade List</button>
                                <button className='btn btn-md btn-primary mb-2 me-2' data-bs-toggle="modal" data-bs-target="#MarketerSupplierImbalance"><img src={imbStatus} alt='Imbalance Status' /> Imbalance Status</button>
                            </div>
                        </div>
                        <div className='row mb-1 bg-light-blue rounded p-1 rounded'>
                            <h6>Display Filters</h6>
                            <div className="col-lg-2 col-md-2 col-sm-12 text-start">
                                <label className='form-label'>Gas Flow Month</label>
                                <input className="form-control form-control-sm" type="month" value={`${new Date().getFullYear()}-${(new Date().getMonth() > 8 ? "" : "0") + (new Date().getMonth() + 1)}`} onChange={() => { }}
                                    style={{ maxWidth: '180px' }} />
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12 text-start">
                                <label className='form-label'>Pipeline</label>
                                <select className='form-select form-select-sm' aria-label="select public or private">
                                    <option>All Pipelines</option>
                                </select>
                            </div>

                            <div className="col-lg-1 col-md-1 col-sm-12 text-start">
                                <label className='form-label'>Pool ID</label>
                                <select className='form-select form-select-sm' aria-label="select public or private" style={{ maxWidth: '150px' }}>
                                    <option>All Rec Points</option>
                                </select>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12 text-start">
                                <label className='form-label'>Trade Period</label>
                                <select className='form-select form-select-sm' aria-label="select public or private" style={{ maxWidth: '150px' }}>
                                    <option>Daily</option>
                                </select>
                            </div>
                        </div>
                        {/* DCQ Table */}
                        <div className='table-responsive'>
                            <div className="d-flex"><caption className='pe-5'>Imbalance Details</caption></div>
                            <table className="bg-white table table-sm table-hover table-striped-columns table-bordered border-primary rounded pb-1"
                                ref={tableRef}
                            >
                                <thead>
                                    <tr>
                                        <th>View</th>
                                        <th>Pool ID</th>
                                        <th>Pool Owner</th>
                                        <th>IMBALANCE</th>
                                        <th>Total Trade QTY</th>
                                        <th>Net Imbalance</th>
                                        <th>Trade Qty</th>
                                        <th>Originator</th>
                                        <th>Trade User Id</th>
                                        <th>Status</th>
                                        <th>Cntr. Party</th>
                                        <th>Cntr. Pool ID</th>
                                        <th>Trade ID</th>
                                        <th>Trade Date</th>
                                        <th>Confirm Trade</th>
                                        <th>Trade Exp</th>
                                        <th>Confirm Date</th>
                                        <th>Confirm User ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><button className='btn btn-md btn-primary' data-bs-toggle="modal" data-bs-target="#MarketPoolImabalanceUi">View</button></td>
                                        <td>920_FT_S</td>
                                        <td>PG_HESSEN</td>
                                        <td>115,031.2</td>
                                        <td>50,000</td>
                                        <td>65,031.2</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>2021-01-01</td>
                                        <td>
                                            {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" readOnly={true}>
                                                </input> */}
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <ImbalanceTradeList />
                <MarketerSupplierImbalance />
                <MarketPoolImabalanceUi />
            </div >
        </div>
    )
}

export default TradeImbalance;