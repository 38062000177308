import { formatDate, formatDateTime } from '../../../utils/DateUtils';
import NonPathNomsTableButtons from '../../CustomComponents/NonPathNomUI/Tables/NonPathNomsTable/NonPathNomsTableButtons'

const GANomsTableColumns = [
	{
		name: 'View Details',
		button: true,
		width: 'auto',
		cell: row => <NonPathNomsTableButtons row={row} />
	},
	{
		name: 'Dn K',
		selector: row => row.UP_CNTR,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	},
	{
		name: 'Cycle',
		selector: row => row.CYCLE,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	},
	{
		name: 'Pipeline',
		selector: row => row.CNTR_NUM?.CNTR_PARTY2?.SHORTNAME,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	},
	{
		name: 'Activity',
		selector: row => row.ACTIV_NUM?.ACTIV_ALT_NUM,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	},
	{
		name: 'Trans Contract',
		selector: row => row.CNTR_NUM?.CNTR_ALT_NUM1,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	},
	{
		name: 'Rec Qty',
		selector: row => row.RCV_QTY || 0,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	},
	{
		name: 'Del Qty',
		selector: row => row.DLV_QTY || 0,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	},
	{
		name: 'Start Date',
		selector: row => formatDate(row.STARTDATE),
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	},
	{
		name: 'End Date',
		selector: row => formatDate(row.ENDDATE),
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	},
	{
		name: 'Trans Type',
		selector: row => row.TT,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	},
	{
		name: 'Sys Pkg ID',
		selector: row => row.PACKAGE_NUM,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	},
	{
		name: 'Updated By',
		selector: row => row.USERSTAMP,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	},
	{
		name: 'Updated On',
		selector: row => formatDateTime(row.DATESTAMP),
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	}
];

export default GANomsTableColumns;