import { useSelector } from "react-redux";

function FootNote() {
    const customStyles = {
        fontSize: '9pt'
    }

    const userContact = useSelector(state => state.auth_slice.userContact);

    return (
        <>
            <div className="container-fluid" style={customStyles}>
                <div className="row">
                    <div className="col-10">&nbsp;</div>
                    <div className="col-2">User ID: {userContact?.FIRST_NAME} {userContact?.NAME} </div>
                </div>
            </div>
        </>
    )
}

export default FootNote;