import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { non_path_noms_actions } from '../../../../../store/non-path-noms-slice';
import './SpecifyLocationQuantityModalStyles.css';
import Modal from 'react-modal';
import { useForm, useWatch } from 'react-hook-form';
import { calcDlvAndFuel, calcRcvAndFuel } from '../../../../../utils/GeneralNomUtils';
import useErrorStyling from '../../../../../hooks/useErrorStyling';

// Modal styling
const customStyles = {
	overlay: {
		backgroundColor: 'rgb(0,0,0,.60'
	}
};

const SpecifyLocationQuantity = () => {



	// Redux
	const { numberFormat } = useSelector(state => state.non_path_noms_slice.options);
	const { shouldRound, decimalPlace } = numberFormat;
	const modalStates = useSelector(state => state.non_path_noms_slice.modalStates);
	const selectedPkgPathInfoObj = useSelector(state => state.non_path_noms_slice.selectedPkgPathInfoObj);
	const { specifyLocationQuantityModalState } = modalStates;
	const { open, previousModalStateName, previousModalState } = specifyLocationQuantityModalState;
	const dispatch = useDispatch();

	// React Hook Form
	const form = useForm();
	const { reset, register, control, setValue, handleSubmit, formState } = form;
	const { isSubmitted } = formState;

	// React Hook Form Watches
	const PT_DIR_FLOW = useWatch({ control: control, name: 'PT_DIR_FLOW' });
	const FUEL_PERCENT_DB = useWatch({ control: control, name: 'FUEL_PERCENT_DB' });

	// Any time the package path info object changes, update form state.
	useEffect(() => { reset(selectedPkgPathInfoObj); }, [selectedPkgPathInfoObj, reset]);

	/**
	 * onChange method for NOM_RCV_VOL input, also handles auto calculation for
	 * fuel and deliver volumes.
	 * @param {any} e
	 */
	const rcvVolChange = (e) => {
		// Get new value.
		const newValue = e.target.value;
		if (!newValue || isNaN(newValue)) return;

		// Auto calculate fuel and deliver volumes.
		const { fuel, rcvVol, dlvVol } = calcDlvAndFuel(newValue, FUEL_PERCENT_DB, numberFormat);
		if (!isNaN(fuel))
			setValue('FUEL', fuel);
		if (!isNaN(rcvVol))
			setValue('NOM_RCV_VOL', rcvVol, { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });
		if (!isNaN(dlvVol))
			setValue('NOM_DLV_VOL', dlvVol);
	};

	/**
	 * onChange method for NOM_DLV_VOL input, also handles auto calculation for
	 * fuel and receive volumes.
	 * @param {any} e
	 */
	const dlvVolChange = (e) => {
		// Get new value.
		const newValue = e.target.value;
		if (!newValue || isNaN(newValue)) return;

		// Auto calculate fuel and receive volumes.
		const { fuel, dlvVol, rcvVol } = calcRcvAndFuel(newValue, FUEL_PERCENT_DB);
		if (!isNaN(fuel))
			setValue('FUEL', fuel);
		if (!isNaN(dlvVol))
			setValue('NOM_DLV_VOL', dlvVol, { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });
		if (!isNaN(rcvVol))
			setValue('NOM_RCV_VOL', rcvVol);
	};

	/** Close modal without saving information. */
	const closeModal = async () => {
		// Close "SpecifyLocationQuantity" modal.
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'specifyLocationQuantityModalState',
			newModalState: {
				...specifyLocationQuantityModalState,
				open: false,
				previousModalStateName: '',
				previousModalState: {}
			}
		}));

		// Open previous modal.
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: previousModalStateName,
			newModalState: { ...previousModalState, open: true }
		}));
	};

	/** 
	 * Close modal while saving information.
	 * 
	 * @param {any} formData
	 */
	const acceptChanges = async (formData) => {
		// Update selectedPkgPathInfoObj with form data.
		await dispatch(non_path_noms_actions.setSelectedPkgPathInfoObj(formData));

		// Close "SpecifyLocationQuantity" modal.
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'specifyLocationQuantityModalState',
			newModalState: {
				...specifyLocationQuantityModalState,
				open: false,
				previousModalStateName: '',
				previousModalState: {}
			}
		}));

		// Open previous modal and set formData in previous modal's state.
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: previousModalStateName,
			newModalState: { ...previousModalState, open: true }
		}));
	};

	useErrorStyling('specify-location-quantity-form', form);

	return (
		<Modal
			isOpen={open}
			onRequestClose={closeModal}
			className={`specify-location-quantity-modal container-fluid bg-white text-black`}
			style={customStyles}
		>
			<form onSubmit={handleSubmit(acceptChanges)} className="modal-content specify-location-quantity-form">
				<div className="modal-header bg-primary text-white">
					<div className='col text-start mb-1 ms-1'>
						<h4 className="modal-title text-white">Specify Location Quantity</h4>
					</div>
					<div className='col text-end me-1'>
						<button
							type='button'
							className="btn-close btn btn-md mt-2"
							aria-label="Close"
							onClick={closeModal}
						>
						</button>
					</div>
				</div>
				<div className="modal-body">
					<div className="container-fluid">

						{/*Begin Row 1 */}
						<div className='row'>
							<label className="form-label col-2 text-end">Location:</label>
							<div className='col-10'>
								<input disabled className="form-control" type="text" { ...register('PT_NUM_OBJ.PT_NAME') } />
							</div>
						</div>

						{/* Begin Row 2 */}
						<div className='row mt-2'>
							<label className="form-label col-2 text-end">Dn K</label>
							<div className='col-4'>
								{ PT_DIR_FLOW === 'R' && <input className="form-control" type="text" {...register('UP_CNTR', { required: true })} /> }
								{ PT_DIR_FLOW === 'D' && <input className="form-control" type="text" {...register('DN_CNTR')} /> }
							</div>
							<label className="form-label col-2 text-end">
								{(PT_DIR_FLOW === 'R') ? <>Up Shipper:</>:<>Up Shipper:</>}
							</label>
							<div className='col-4'>
								{/*DevOps 4044 */}
								{ (PT_DIR_FLOW === 'R') && <input className="form-control" type="text" {...register('UP_SHIPPER_DUNS', { required: true }) } />}
								{(PT_DIR_FLOW === 'D') && <input className="form-control" type="text" {...register('DN_SHIPPER_DUNS')} />}
							</div>
						</div>

						{/* Begin Row 3 */}
						<div className='row mt-2'>
							<label className="form-label col-2 text-end">ADDQ:</label>
							<div className="col-4">
								<input disabled className="form-control" type="text" />
							</div>

							<label className="form-label col-2 text-end">Fuel %:</label>
							<div className="col-4">
								<input disabled className="form-control" type="text" { ...register('FUEL_PERCENT_DB') } />
							</div>
						</div>

						{/* Begin Row 4 */}
						<div className='row mt-2'>
							<label className="form-label col-2 text-end">{PT_DIR_FLOW === 'R' ? 'Rec Qty:' : 'Del Qty'}</label>
							<div className="col-4">
								{PT_DIR_FLOW === 'R' &&
									<input className="form-control" type="number" {...register('NOM_RCV_VOL', { valueAsNumber: true, onChange: rcvVolChange })} />}
								{PT_DIR_FLOW === 'D' &&
									<input className="form-control" type="number" {...register('NOM_DLV_VOL', { valueAsNumber: true, onChange: dlvVolChange })} />}
							</div>

							<label className="form-label col-2 text-end">Fuel</label>
							<div className="col-4">
								<input disabled className="form-control" type="text" { ...register('FUEL') } />
							</div>
						</div>

						{/* Begin Row 5 */}
						<div className='row mt-2'>
							<label className="form-label col-2 text-end">Pool Qty:</label>
							<div className="col-4">
								<input disabled className="form-control" type="text" />
							</div>


							<label className="form-label col-2 text-end">Nom Pkg:</label>
							<div className="col-4">
								<input className="form-control" type="text" />
							</div>
						</div>

						{/* Begin Row 6 */}
						<div className='row mt-2'>
							<label className="form-label col-2 text-end">Rank:</label>
							<div className="col-4">
								<input className="form-control" type="text" />
							</div>

							<label className="form-label col-2 text-end">Updated By:</label>
							<div className="col-4">
								<input disabled className="form-control" type="text" { ...register('USERSTAMP') } />
							</div>
						</div>

						{/* Begin Row 7 */}
						<div className='row mt-2'>
							<label className="form-label col-2 text-end">Updated On:</label>
							<div className="col-4">
								<input disabled className="form-control" type="text" { ...register('DATESTAMP') } />
							</div>
						</div>

					</div>
				</div>
				<div className="modal-footer bg-light">
					<button
						className="btn btn-lg btn-primary me-2"
					>
						Accept Changes
					</button>
					<button
						type='button'
						className="btn btn-lg btn-primary"
						aria-label="Close"
						onClick={closeModal}
					>
						Close
					</button>
				</div>
				</form>
		</Modal>
	)
}

export default SpecifyLocationQuantity