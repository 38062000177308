import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { formatDate } from "../../../../utils/DateUtils";
import CustomStyles from "./Table-Styles";
import { useDispatch } from 'react-redux';
import { measurement_actions } from "../../../../store/measurement-slice"
import { dailyMeasurement_actions } from "../../../../store/daily-measurement-slice";

function MeasurementTable({ data }) {

    const dispatch = useDispatch();

    //Functions for calculating totals:
    const totalNtMcf = data.reduce((total, row) => total + (row?.NT_VOLUME || 0), 0);

    const totalGrMcfs = data.reduce((total, row) => total + (row?.GR_VOLUME || 0), 0);

    const totalGrEnergy = data.reduce((total, row) => total + (row?.GR_ENERGY || 0), 0);

    const totalNtDth = data.reduce((total, row) => total + (row?.NT_ENERGY || 0), 0);

    const totalAmount = data.reduce((total, row) => total + (row?.GrRev || 0), 0);

    const totalCapac = data.reduce((total, row) => total + (row?.CAPAC || 0), 0);

    const totalGather = data.reduce((total, row) => total + (row?.GATHER || 0), 0);

    const totalExtract = data.reduce((total, row) => total + (row?.EXTRACT || 0), 0);

    const totalDeducts = data.reduce((total, row) => total + (row?.TotalDeducts || 0), 0);

    //Create a total row:

    const totalRow = {
        isTotalRow: true,
        NT_VOLUME: totalNtMcf,
        GR_VOLUME: totalGrMcfs,
        GR_ENERGY: totalGrEnergy,
        NT_ENERGY: totalNtDth,
        GrRev: totalAmount,
        CAPAC: totalCapac,
        GATHER: totalGather,
        EXTRACT: totalExtract,
        TotalDeducts: totalDeducts,
        // ... add other totals here
    };

    const allData = [...data, totalRow];


    //columns for table:
    const columns = [{
        name: "Agmt",
        selector: row => row?.AgreementKey
        ,
        sortable: true,
        compact: true,
        wrap: true,
        width: "50px",

    },
    {
        name: "Supplier",
        selector: row => row?.SupplierName,
        sortable: true,
        compact: true,
        wrap: true,
        width: "140px",

    },
    {
        name: "Producer",
        selector: row => row?.ProducerName,
        sortable: true,
        compact: true,
        wrap: true,
        width: "170px",

    },
    {
        name: "Prod Date",
        selector: row => {
            if (!row?.TransDate) return "";
            let date = new Date(row.TransDate);
            return `${date.getDate()}-${date.toLocaleDateString('en-US', { month: 'long' }) }-${date.getFullYear()}`;
        },
        sortable: true,
        compact: true,
        wrap: true,
        width: "100px",
    },
    {
        name: "Location ID",
        selector: row => row?.PointShortName,
        sortable: true,
        compact: true,
        wrap: true,
        width: "95px",
    },
    {
        name: 'Location Name',
        selector: row => row?.PT_NAME,
        sortable: true,
        compact: true,
        wrap: true,
        width: "150px",

    },
    {
        name: "Pod",
        selector: row => row?.Pod,
        sortable: true,
        compact: true,
        wrap: true,
        width: "61px",
    },
    {
        name: "Bubble",
        selector: row => row?.Bubble,
        sortable: true,
        compact: true,
        wrap: true,
        width: "61px",
    },
    {
        name: "OnDate",
        selector: row => row?.OnDate ? formatDate(row.OnDate) : "",
        sortable: true,
        compact: true,
        wrap: true,
        width: "61px",
        cell: row => row.isTotalRow
            ? <div style={{ fontWeight: 'bold' }}>Total:</div>
            : row.OnDate
    },
    /*{ // DevOps 3919 needs to be hidden
        name: "OffDate",
        selector: row => row?.OffDate,
        sortable: true,
        compact: true,
        wrap: true,
        width: "61px",
        cell: row => row.isTotalRow
            ? <div style={{ fontWeight: 'bold' }}>Total:</div>
            : row.OffDate

    },*/
    {
        name: "Gr.Mcfs",
        selector: row => row?.GR_VOLUME,
        sortable: true,
        compact: true,
        wrap: true,
        width: "60px",
        // cell: row => row.isTotalRow
        //     ? <div style={{ fontWeight: 'bold' }}>{row.GR_VOLUME}</div>
        //     : row.GR_VOLUME,
    },
    {
        name: "Nt Mcf",
        selector: row => row?.NT_VOLUME,
        sortable: true,
        compact: true,
        wrap: true,
        width: "60px",
        // cell: row => row.isTotalRow
        //     ? <div style={{ fontWeight: 'bold' }}>{row.NT_VOLUME}</div>
        //     : row.NT_VOLUME,
    },
    {
        name: "Gr Dth",
        selector: row => row?.GR_ENERGY,
        sortable: true,
        compact: true,
        wrap: true,
        width: "60px",
        // cell: row => row.isTotalRow
        //     ? <div style={{ fontWeight: 'bold' }}>{row.GR_ENERGY}</div>
        //     : row.GR_ENERGY,
    },
    {
        name: "Nt Dth",
        selector: row => row?.NT_ENERGY,
        sortable: true,
        compact: true,
        wrap: true,
        width: "60px",
        // cell: row => row.isTotalRow
        //     ? <div style={{ fontWeight: 'bold' }}>{row.NT_ENERGY}</div>
        //     : row.NT_ENERGY,

    },
    {
        name: "G/PREP",
        selector: row => row?.DEHY,
        sortable: true,
        compact: true,
        wrap: true,
        width: "60px",
    },
    {
        name: "G/PGAS",
        selector: row => row?.DEHYW,
        sortable: true,
        compact: true,
        wrap: true,
        width: "62px",
    },
    {
        name: "Partic",
        selector: row => row?.Partic ? row.Partic : "No Data",
        sortable: true,
        compact: true,
        wrap: true,
        width: "60px",
        cell: row => row.isTotalRow
            ? <div style={{ fontWeight: 'bold' }}>Total:</div>
            : row.OffDateString


    },
    {
        name: "Amount",
        selector: row => row?.GrRev,
        sortable: true,
        compact: true,
        wrap: true,
        width: "64px",
        // cell: row => row.isTotalRow
        //     ? <div style={{ fontWeight: 'bold' }}>{row.GrRev}</div>
        //     : row.GrRev,
    },
    {
        name: "Capac",
        selector: row => row?.CAPAC,
        sortable: true,
        compact: true,
        wrap: true,
        width: "60px",
    },
    {
        name: "Gather",
        selector: row => row?.GATHER,
        sortable: true,
        compact: true,
        wrap: true,
        width: "60px",
    },
    {
        name: "Extract",
        selector: row => row?.EXTRACT,
        sortable: true,
        compact: true,
        wrap: true,
        width: "60px",
    },
    {
        name: "TotalDed.",
        selector: row => row?.TotalDeducts?.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: true,
        width: "70px",
    },
    {
        name: "UpdatedBy",
        selector: row => row?.UpdatedBy,
        sortable: true,
        compact: true,
        wrap: true,
        width: "90px",
    },
    {
        name: "UpdatedOn",
        selector: row => row?.UpdatedOn ? formatDate(row.UpdatedOn) : "",
        sortable: true,
        compact: true,
        wrap: true,
    }
    ]

    const rowClick = (row) => {
        dispatch(dailyMeasurement_actions.setShowDailyModal(false));
        dispatch(measurement_actions.setSelectedContractsData(row));
        dispatch(dailyMeasurement_actions.setShowDailyModal(true));
    }

    return (
        <div className="container-fluid">
            <DataTable
                // title="Agent Marketer Details"
                columns={columns}
                data={allData}
                dense={true}
                striped={true}
                fixedHeader={false}
                fixedHeaderScrollHeight="450px"
                customStyles={CustomStyles}
                highlightOnHover={true}
                className="pb-1 pt-1"
                onRowClicked={rowClick}
            // pagination={true}
            // paginationServerOptions={true} // enable pagination
            // paginationPerPage={10} // Number of rows per page
            // paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
            // onPrevPag={10}// Dropdown for number of rows per page
            //Sums up totals:


            />
        </div>
    )
}

export default MeasurementTable;