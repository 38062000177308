import Selector from "../../CustomComponents/Selectors/Selector";

const ContractSelector = ({ value, setValue, url, showValidation, defaultToFirstItem, shouldLog }) => {
	return (
		<Selector
			className='form-select form-select-sm'
			value={value}
			setValue={setValue}
			valueType='object'
			url={url}
			optionKey='CNTR_NUM'
			optionText='CNTR_ALT_NUM1'
			defaultToFirstItem={defaultToFirstItem}
			showValidation={showValidation}
		/>
	);
};

export default ContractSelector;