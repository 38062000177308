// import React, { Component } from 'react';
import { Container } from 'reactstrap';
/*import { NavMenu } from './NavMenu';*/
import Nav from './Nav';


function Layout(props) {

	return (
		<div>

			{/* pass state down to Nav */}
			<Nav
				state={props.state}
				setState={props.setState}
			/>

			<Container style={{ padding: '0px', minWidth: '100%', margin: '0px' }}>
				{props.children}
			</Container>
		</div>
	);

}

export default Layout;
