import { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import CustomStyles from './SumTable-Styles';
import './SumTableStyle.css'
import ExportExcel from './ExportExcel';
import { physical_trade_deals_actions } from '../../../store/physical-trade-deals-slice';
import Spinner from '../../Spinner';


function SumTable() {

	// Redux:
	const dispatch = useDispatch();
	const selectedBidPkg = useSelector(state => state.physical_trade_deals_slice.selectedBidPkg);
	const pkgPathsFetchLoading = useSelector(state => state.physical_trade_deals_slice.pkgPathsFetchLoading);

	// State:
	const pkgVolDtls = useMemo(() => {
		return selectedBidPkg?.PACKAGE_NUM?.ENT_PKG_PATH?.[0].ENT_PKG_VOL_DTL || [];
	}, [selectedBidPkg?.PACKAGE_NUM]);

	//Edit table functionality:
	const [editingId, setEditingId] = useState({});
	const [editValue, setEditValue] = useState(0);

	const handleRowClick = (row) => {
		if (selectedBidPkg.STATUS === "AP" || selectedBidPkg.STATUS === "CF") {
			row.className = "disabled";
		} else {
			setEditingId(row.PackageVolumeDetailKey);
			setEditValue(row.ACT_RCV_VOL || row.ACT_DLV_VOL || 0);
		}
	}

	const handleEditValueChange = (e) => {
		const newValue = e.target.value;
		setEditValue(newValue);

		if (!pkgVolDtls)
			return;

		const newPkgVolDtls = pkgVolDtls.map(
			prevPkgVolDtl => {
				if (prevPkgVolDtl.PackageVolumeDetailKey === editingId) {
					return {
						...prevPkgVolDtl,
						NOM_RCV_VOL: parseFloat(newValue), // DevOps 3043, Updated 06/01/23 - Daily Quantity Issue
						NOM_DLV_VOL: parseFloat(newValue), // DevOps 3043, Updated 06/01/23 - Daily Quantity Issue
						ACT_RCV_VOL: parseFloat(newValue),
						ACT_DLV_VOL: parseFloat(newValue),
						// DevOps 3429 ensured that values are read as numbers so it can be mapped
						// in C#  by adding parse float method - Roy Moran 8/9/2023
					};
				}
				return prevPkgVolDtl;
			}
		);
		dispatch(physical_trade_deals_actions.updateBidPkgDtls(newPkgVolDtls));
	}

	const handleBlur = () => {
		setEditingId(null);
	};

	//Export Excel function:
	const actionsMemo = useMemo(() => <ExportExcel data={pkgVolDtls} />, [pkgVolDtls]);

	//Table columns:
	const columns = [
		{
			name: 'Flow Date',
			selector: row => new Date(row.GASFLOW_DATE).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Day',
			selector: row => new Date(row.GASFLOW_DATE).toLocaleDateString('en-US', { weekday: 'long' }),
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Deal QTY',
			selector: row => row.NOM_DLV_VOL || row.NOM_RCV_VOL || 0,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true

		},
		{
			name: 'Sch QTY',
			selector: row => row.SCH_RCV_VOL || row.SCH_DLV_VOL || 0,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Avail QTY',
			selector: row => (row.NOM_DLV_VOL || row.NOM_RCV_VOL) - (row.SCH_DLV_VOL || row.SCH_RCV_VOL) || 0,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Act Qty', 
			selector: row => editingId === row.PackageVolumeDetailKey ? (
				<input
					className='form-control form-control-sm'
					type="text"
					value={editValue}
					onChange={handleEditValueChange}
					onBlur={handleBlur}
				/>
			) : (
				<div
					onClick={() => { handleRowClick(row) }}
				>
					{row.ACT_RCV_VOL || row.ACT_DLV_VOL || 0}
				</div>
			),
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Deal Price',
			selector: row => row.DEAL_PRICE || 0,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Amount $',
			selector: row => (row.DEAL_PRICE ? row.DEAL_PRICE * (row.NOM_DLV_VOL || row.NOM_RCV_VOL || 0) : 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Updated By',
			selector: row => row.USERSTAMP,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Updated On',
			selector: row => new Date(row.DATESTAMP).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},

	];

	return (
		<div className="tableFixHead">
			<DataTable
				progressPending={pkgPathsFetchLoading}
				progressComponent={<Spinner />}
				title='New Daily Volumes'
				fixedHeader={true}
				fixedHeaderScrollHeight="50vh"
				columns={columns}
				data={pkgVolDtls}
				data-tag="allowRowEvents"
				type='button'
				onRowClicked={handleRowClick}
				striped={true}
				highlightOnHover={true}
				actions={actionsMemo}
				customStyles={CustomStyles}
				className="pb-1"
				dense={true}
			/>
		</div>
	);
};

export default SumTable;