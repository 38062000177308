import { useState, useEffect } from 'react';
import { notification_actions } from '../../../store/notification-slice';

//Image imports
import Clear from '../../../assets/images/clear.png';
import Cancel from '../../../assets/images/cancel.png';

//Import Modules:
import Table from './Table';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { physical_trade_deals_actions } from '../../../store/physical-trade-deals-slice';

import './ContractModalStyling.css';
import SearchBar from '../../CustomComponents/SearchBar';

// Modal styling
const customStyles = {
	overlay: {
		backgroundColor: 'rgb(0,0,0,.60'
	},
};

function ContractModal() {

	// Redux:
	const openContractModal = useSelector(state => state.physical_trade_deals_slice.openContractModal);
	const selectedBidPkg = useSelector(state => state.physical_trade_deals_slice.selectedBidPkg);
	const dispatch = useDispatch();

	// State
	const [counterPartyContracts, setCounterPartyContracts] = useState();
	const [filteredData, setFilteredData] = useState();
	const [loading, setLoading] = useState();

	// Fetch Counter Party Contracts.
	useEffect(() => {
		const handleAsync = async () => {
			const requestData = async () => {
				const cntr_party1 = selectedBidPkg?.BIDTYPE === 'B' ? selectedBidPkg?.SUPPLIER_BAID_OBJ?.BA_ID : selectedBidPkg?.BUYERID_OBJ?.BA_ID;
				const response = await fetch(`GeneralData/GetContracts?cntr_party1=${cntr_party1}&cntr_type=MC&status=AC`);
				if (!response.ok) {
					const message = await response.text();
					throw new Error(message);
				}
				return await response.json();
			};
			try {
				setLoading(true);
				const data = await requestData();
				setCounterPartyContracts(data);
			}
			catch (error) {
				dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
			}
			finally {
				setLoading(false);
			}
		};
		handleAsync();
	},
		[
			dispatch,
			selectedBidPkg?.BIDTYPE,
			selectedBidPkg?.SUPPLIER_BAID_OBJ,
			selectedBidPkg?.BUYERID_OBJ
		]
	);

	// If there is only one counterPartyContract, set it to the selected one.
	useEffect(() => {
		if (counterPartyContracts?.length === 1) {
			dispatch(physical_trade_deals_actions.setSelectedBidPkg({
				...selectedBidPkg,
				CNTR_NUM: counterPartyContracts[0]
			}));
		}
	},
		[
			counterPartyContracts,
			dispatch,
		]
	);

	// Modal State:
	const closeContractModal = () => {
		dispatch(physical_trade_deals_actions.setOpenContractModal(false));
	};

	return (
		<>
			<Modal
				isOpen={openContractModal}
				onRequestClose={closeContractModal}
				className="contract-modal container-fluid bg-white text-black"
				style={customStyles}
			>
				<div id="SelectContractModal" tabIndex='-1'>
					<div className='modal-dialog '>
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title mb-2">Select a Contract</h4>
								<button
									type="button"
									className="btn-close fs-6"
									onClick={closeContractModal}
								>
								</button>
							</div>
							<div className="modal-body">

								{/* // BUTTONS */}
								<div className="row text-start bg-light-blue rounded-top m-0 p-2">
									<div className="col-lg-3 col-md-12 col-md-12 col-sm-12">
										<button
											type="button"
											className="btn btn-primary m-1"
											title="New Deal"
											data-bs-dismiss="modal"
											onClick={closeContractModal}
										>
											<img src={Clear} alt="Clear" /> Clear
										</button>
										<button
											type="button"
											className="btn btn-primary m-1"
											title="Cancel"
											data-bs-dismiss="modal"
											onClick={closeContractModal}
										>
											<img src={Cancel} alt="AddImage" /> Cancel
										</button>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-12">
										{/* Search Bar */}
										<SearchBar
											data={counterPartyContracts}
											setFilteredData={setFilteredData}
										/>
									</div>
								</div>
								{/* SELECT A DEAL TABLE */}
								<div className='row text-start'>
									<Table
										counterPartyContracts={filteredData}
										loading={loading}
									/>
								</div>
							</div>
						</div>
					</div>
				</div >
			</Modal>
		</>
	)
}

export default ContractModal