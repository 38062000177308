
import { useRef } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import excel from '../../../assets/images/excel.gif';
// custom date utils
import {
	toDatePickerMonthString, toShortDate
} from '../../../utils/DateUtils';

import Restricted from '../../Restricted';






function DailyBrokerReport({
	componentState,
	setComponentState
}) {

	// tableRef for Excel Export:
	const tableRef = useRef();

	//Handling the Excel Download:
	const { onDownload } = useDownloadExcel({
		// currentTableRef: tableRef.current,
		currentTableRef: tableRef.current,
		filename: 'Broker-Delivery-Report',
		sheet: 'Broker-Delivery-Report-Table'
	});

	// Handlers
	const dateChange = (event) => {

		const newGasFlowDate = new Date(event.target.value);
		setComponentState(
			prevState => {
				return {
					...prevState,
					gasFlowDate: newGasFlowDate
				}
			}
		)

	};

	const supplierChange = (event) => {

		const newSelectedSupplierID = event.target.value;

		const newSelectedSupplier = componentState.suppliers.find(supplier => supplier.BA_ID === newSelectedSupplierID);

		setComponentState(
			prevState => {
				return {
					...prevState,
					selectedSupplier: newSelectedSupplier
				};
			}
		);

	};

	const contractChange = (event) => {

		const newSelectedContractID = event.target.value;

		const newSelectedContract = componentState.contracts.find(contract => contract.CNTR_NUM === newSelectedContractID);

		setComponentState(
			prevState => {
				return {
					...prevState,
					selectedContract: newSelectedContract
				};
			}
		);

	};


	return (
		<>
			<div className="modal modal-xl " id="BrokerReport" aria-hidden="true" aria-labelledby="BrokerReport Report" tabIndex="-1">
				<div className="modal-dialog modal-dialog-centered ">
					<div className="modal-content text-center">

						<div className="modal-header text-center bg-primary text-white justify-content-center">
							<h1 className="fs-5" id="BrokerReport">Broker Delivery Report</h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="row text-start">
							<div className="col-sm-12 col-md-12 col-lg-12 p-2">
								<button className='btn btn-sm btn-primary text-end mb-2 me-2' onClick={onDownload}><img src={excel} alt='Export to excel' /> Export to Excel</button>
							</div>
						</div>

						{/* // Filter Section */}
						<div className="container-fluid p-3">
							<div className='row mb-3 bg-light-blue rounded p-2 rounded'>
								<h6>Display Filters</h6>
								{/* Display Month */}
								<div className="col-lg-2 col-md-2 col-sm-12 text-start">
									<label className='form-label pe-2'>Display Month</label>
									<input
										className="form-control form-control-sm"
										type="month"
										value={toDatePickerMonthString(componentState.gasFlowDate)}
										onChange={dateChange}
										style={{ maxWidth: '180px' }}
									/>
								</div>
								{/* Supplier */}
								<div className="col-lg-4 col-md-4 col-sm-12 text-start">
									<label className='form-label pe-2'>Supplier Name</label>
									<Restricted
										restrictedTo={['SYS_ADMIN-ACCESS']}
										action='disable'
									>
										<select
											className='form-select form-select-sm'
											aria-label="select public or private"
											value={componentState.selectedSupplier.BA_ID}
											onChange={supplierChange}
										>
											{
												componentState.suppliers.map(
													supplier => {
														return (

															<option
																key={supplier.BA_ID}
																value={supplier.BA_ID}
															>
																{supplier.NAME}
															</option>

														);
													}
												)
											}
										</select>
									</Restricted>
								</div>
								{/* Contract */}
								<div className="col-lg-2 col-md-2 col-sm-12 text-start">
									<label className='form-label pe-2'>Contract</label>
									<select
										className='form-select form-select-sm'
										aria-label="select public or private"
										value={componentState.selectedContract.CNTR_NUM}
										onChange={contractChange}
									>
										{
											componentState.contracts.map(
												contract => {
													return (

														<option
															key={contract.CNTR_NUM}
															value={contract.CNTR_NUM}
														>
															{contract.CNTR_ALT_NUM1}
														</option>

													);
												}
											)
										}
									</select>
								</div>
							</div>
						</div>

						{/* // Daily Pool Summary Table */}
						{componentState.dailySummaryDetails.length > 0
							?
							<div className="table-responsive mt-2 tableContainer">
								<table className="bg-white table table-sm table-hover table-striped-columns table-bordered border-primary rounded pb-1"
									ref={tableRef}
									id='BrokerDeliveryReportTable'
								>
									<thead>
										<tr className="bg-white">
											<th>Date</th>
											<th>Delivery</th>
											<th>Usage</th>
											<th>Difference</th>
											<th>Traded</th>
											<th>Net Delivery</th>
										</tr>
									</thead>
									<tbody>

										{/* // Data */}
										{componentState.dailySummaryDetails.map(
											detail => {
												return (
													<tr key={detail.GASFLOW_DATE + detail.PT_MKPOOL_IG_KEY}>
														<td>{toShortDate(detail.GASFLOW_DATE)}</td>
														<td>{detail.ACT_DEL_QTY}</td>
														<td>{detail.ENERGY}</td>
														<td>{detail.IMBAL}</td>
														<td>{detail.TRADE_QTY_NULL}</td>
														<td>{detail.NET_NOM}</td>
													</tr>
												)
											}
										)
										}

										{/* // Totals Row */}
										<tr className='fw-bold'>
											<td>Total:</td>
											<td>{componentState.dailySummaryDtlTotals.ACT_DEL_QTY}</td>
											<td>{componentState.dailySummaryDtlTotals.ENERGY}</td>
											<td>{componentState.dailySummaryDtlTotals.IMBAL}</td>
											<td>{componentState.dailySummaryDtlTotals.TRADE_QTY_NULL}</td>
											<td>{componentState.dailySummaryDtlTotals.NET_NOM}</td>
										</tr>


										{/* Gross Short Fall Row */}
										<tr>
											<td>Gross Short Fall:</td>
											<td>{componentState.dailySummaryDtlTotals.IMBAL}</td>
											<td></td>
											<td></td>
											<td>{componentState.dailySummaryDtlTotals.TRADE_QTY_NULL}</td>
											<td>{componentState.dailySummaryDtlTotals.NET_IMBAL}</td>
										</tr>

									</tbody>
								</table>
							</div>
							:
							<p className="text-center">No Records Found.</p>
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default DailyBrokerReport