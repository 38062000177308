
const CustomStyles = {
	table: {
		style: {
			position: 'sticky',
			top: '0px',
			// fontSize: "1.2rem",
			border: '1px solid blue',
			// striped: 'default',
		},
	},
	headRow: {
		style: {
			position: 'sticky',
			top: '0px',
			fontSize: ".9rem",
			paddingLeft: '0px'
			// backgroundColor: 'blue'
		},
	},
	cells: {
		style: {
			fontSize: '.9rem',

			paddingLeft: '1px'
		}
	}
}

export default CustomStyles