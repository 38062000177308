import DataTable from "react-data-table-component";

function HourlyMeasurementTable({ data, isDTH }) {

    const columns = [
        {
            name: "Date",
            selector: row => new Date(row?.METERDATE).toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                hour: 'numeric'
            }),
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
        },
        {
            name: "Act Vol",
            selector: row => isDTH && row.DailyVolume_unt ? (row.DailyVolume_unt / 10).toLocaleString() : row.DailyVolume_unt?.toLocaleString(),
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
        },
        {
            name: "BTU Factor",
            selector: row => row.BTU_FACTOR,
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
            omit: true,
        },
        {
            name: "Act Energy",
            selector: row => row.ActEnergy_unt?.toLocaleString(),
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
        },
        {
            name: "Cum Vol",
            selector: row => isDTH && row.CUMDAILYVOL ? (row.CUMDAILYVOL / 10).toLocaleString() : row.CUMDAILYVOL?.toLocaleString(),
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
        },
        {
            name: "Cum Energy",
            selector: row => row.CUMENERGY?.toLocaleString(),
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
        },
        {
            name: "Cum BTU Factor",
            selector: row => isDTH && row.BTUFactorCum.toLocaleString() ? (row.BTUFactorCum * 10).toLocaleString() : row.BTUFactorCum,
            sortable: true,
            compact: false,
            wrap: true,
            reorder: true,
            omit: true,
        },
        {
            name: "PPA Date",
            selector: row => row.PPADATE == null ? "" : new Date(row.PPADATE).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
            }),
            sortable: true,
            compact: true,
            wrap: true,
        },
        {
            name: "Updated By",
            selector: row => row.USERSTAMP,
            sortable: true,
            compact: true,
            wrap: true,

        },
        {
            name: "Updated On",
            selector: row => new Date(row?.DATESTAMP).toLocaleString('en-US', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            }),
            sortable: true,
            compact: true,
            wrap: true,
            width: "200px",
        },
    ];

    //const selectorArray = [{key:"DailyVolume_unt", name: "Act Vol"}, {key: "ActEnergy_unt", name: "Act Energy"}, {key: "CUMDAILYVOL", name: "Cum Vol"}, {key: "CUMENERGY", name: "Cum Energy"},{key: "BTUFactorCum", name: "Cum BTU Factor"}]

    const rowClick = () => {

    }

    return (
        <>
        <DataTable
            className="pb-1"
            columns={columns}
            data={data}
            striped={true}
            fixedHeader={true}
            highlightOnHover={true}
            responsive={true}
            dense={true}
            onRowClicked={rowClick}
        />
            {/*<table style={{width: '100%'} }>
            //    <thead>
            //        {
            //            selectorArray.map((x, i) => {
            //                return <th>{i === 0 ? "" : x.name}</th>
            //            })
            //        }
            //    </thead>
            //    <tbody>
            //    {
            //            data && data.length > 0 && selectorArray.map((x, i) => {
            //                const sum = data?.map(d => d[x.key])?.reduce((partialSum, a) => {
            //                    const ps = isNaN(Number(partialSum)) ? 0 : Number(partialSum);
            //                    const aItem = isNaN(Number(a)) ? 0 : Number(a);

            //                    if(x.key === 'DailyVolume_unt'){
            //                        return ps + Number(isDTH ? aItem * 10 : aItem);
            //                    }else if(x.key === 'CUMDAILYVOL'){
            //                        return ps + Number(isDTH ? aItem / 10 : aItem);
            //                    }else if(x.key === 'BTUFactorCum'){
            //                        return ps + Number(isDTH ? aItem * 10 : aItem);
            //                    }else{
            //                        return ps + aItem;
            //                    }                             
            //                },0);
            //                return <td>
            //                    {i === 0 ? "Totals:" : sum?.toLocaleString() ?? sum}
            //                </td>
            //            })
            //    }
            //    </tbody>
            //</table>*/}
        </>
    );
}

export default HourlyMeasurementTable;