import React from 'react';
import Modal from 'react-modal';

import './EOMStyles.css';
// import { fetchFuturePriceDetails } from
//     '../../../../store/financial-position-slice';
import Table from './EndOfMonthTable/index';

const EOMReport = ({ isOpen, onClose }) => {


    // Modal styling
    const customStyles = {
        overlay: {
            backgroundColor: 'rgb(0,0,0,.60)'
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} onRequestClose={onClose} className='eom-modal modal-XL container-fluid rounded' style={customStyles}>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 p-0">
                        <div className="card border-0">
                            <div className="card-header text-center">
                                <h4 className="card-title m-0">End of Month PnL</h4>
                                <button className="btn btn-close float-end" onClick={onClose} />

                            </div>
                            <div className="card-body">
                                {/* BUTTONS */}
                                {/* <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-4 p-1 pb-2 d-flex">

                                        <button className="btn btn-primary me-2 h-100">
                                            New</button>

                                        <button className="btn btn-primary me-2"> Financial Contract</button>
                                        {/* <ExportExcel
                                                columns={FinPositionsTableColumns}
                                                fileName='Financial_Positions_Data.xlsx'
                                                data={FinPositions}
                                                className='btn btn-primary h-100'
                                            /> 
                            </div>
                        </div> */}

                                <Table />
                            </div>
                        </div>
                    </div>
                </div >

            </Modal >
        </>
    );
};

export default EOMReport;
