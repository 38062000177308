/**
 * Creator: Cameron
 * Date: 9/15/2023
 * Purpose: Single place to keep utility functions for
 * "Non-Path" nominations.
 * 
 */
import {
	toISOStringForDatabase,
	getDayStart,
	getNextDay,
	toDatePickerString,
	getMonthEnd,
	getNextMonthStart
} from './DateUtils';
import { getFuelPercent } from './GeneralNomUtils';

export const createPackage = (formData) => {
	const {
		REQUESTOR,
		TRANSPORTER,
		CNTR_NUM,
		TT,
		PACKAGE_NUM,
		STATUS,
		STARTDATE,
		ENDDATE,
		CYCLE,
		COMMENTS,
		USERSTAMP,
		DATESTAMP
	} = formData;

	const newPkgPaths = createPkgPaths(formData);

	const newPackage = {
		PACKAGE_NUM: PACKAGE_NUM,
		CNTR_NUM: CNTR_NUM,
		SUPPLIER_BAID: REQUESTOR?.BA_ID,
		INTER_SHIPPER_BAID: TRANSPORTER?.BA_ID,
		STATUS: STATUS,
		STARTDATE: toISOStringForDatabase(STARTDATE),
		ENDDATE: toISOStringForDatabase(ENDDATE),
		ENT_PKG_PATH: newPkgPaths,
		DAILY_COMM_FLAG: "N",
		MD_FLAG: "D",
		TT: TT,
		ACCT_PROCESS_FLAG: "Y",
		CYCLE: CYCLE,
		COMMENTS: COMMENTS,
		USERSTAMP: USERSTAMP,
		DATESTAMP: DATESTAMP
	};

	return newPackage;
};

const createPkgPaths = (formData) => {
	const { CNTR_NUM, pkgPathInfoObjs, PACKAGE_NUM } = formData;

	return pkgPathInfoObjs?.map(pkgPathInfoObj => {
		const { PT_DIR_FLOW, PT_NUM_OBJ, PATH_NUM } = pkgPathInfoObj;
		const { pkgPathDetails, pkgVolDetails } = createPkgPathAndVolDtls(formData, pkgPathInfoObj);
		return {
			PackagePathKey: {
				PACKAGE_NUM: PACKAGE_NUM,
				PATH_NUM: PATH_NUM
			},
			PACKAGE_NUM: PACKAGE_NUM,
			PATH_NUM: PATH_NUM,
			CNTR_NUM: CNTR_NUM?.CNTR_NUM,
			DLV_PT_NUM: PT_DIR_FLOW === 'D' ? PT_NUM_OBJ : null,
			RCV_PT_NUM: PT_DIR_FLOW === 'R' ? PT_NUM_OBJ : null,
			SEQ_NUM: 1,
			ENT_PKG_PATH_DTL: pkgPathDetails,
			ENT_PKG_VOL_DTL: pkgVolDetails
		};
	});
};

const createPkgPathAndVolDtls = (formData, pkgPathInfoObj) => {
	const { STARTDATE, ENDDATE } = formData;

	// Convert to date object for no. of days calculation.
	const endDate = getDayStart(ENDDATE);
	const startDate = getDayStart(STARTDATE);

	let pkgVolDetails = [];
	let pkgPathDetails = [];

	let currentDay = startDate;
	while (currentDay.getTime() <= endDate.getTime()) {

		// Create package volume detail.
		const newPkgVolDtl = createPkgVolDtl(currentDay, endDate);
		mapFormDataToVolDtl(formData, newPkgVolDtl);
		mapPkgPathInfoObjToVolDtl(pkgPathInfoObj, newPkgVolDtl);
		pkgVolDetails.push(newPkgVolDtl);

		// Create package path detail.
		const newPkgPathDtl = createPkgPathDtl(currentDay);
		mapFormDataToPathDtl(formData, newPkgPathDtl);
		mapPkgPathInfoObjToPathDtl(pkgPathInfoObj, newPkgPathDtl);
		pkgPathDetails.push(newPkgPathDtl);

		currentDay = getNextDay(currentDay);
	}

	return { pkgPathDetails, pkgVolDetails };
};

const createPkgVolDtl = (startDate, endDate) => {
	return {
		PackageVolumeDetailKey: {
			GASFLOW_DATE: toISOStringForDatabase(startDate)
		},
		GASFLOW_DATE: toISOStringForDatabase(startDate),
		NOM_PRICE: 0,
		PATH_COST: 0,
		CUM_COST: 0,
		FEE: 0,
		GASFLOW_ENDDATE: toISOStringForDatabase(endDate),
		SEQ_NUM: 1,
		UNIT_MEAS: 'MC',
	};
};

const createPkgPathDtl = (day) => {
	return {
		PackagePathDetailKey: {
			EFFDATE: toISOStringForDatabase(day)
		},
		EFFDATE: toISOStringForDatabase(day),
		ENDDATE: toISOStringForDatabase(day),
		SEQ_NUM: 1,
	};
};

const mapFormDataToVolDtl = (formData, pkgVolDtl) => {
	const { PACKAGE_NUM, STATUS, CYCLE, RANK } = formData;
	pkgVolDtl.PACKAGE_NUMSTR = PACKAGE_NUM;
	pkgVolDtl.PackageVolumeDetailKey = { ...pkgVolDtl.PackageVolumeDetailKey, PACKAGE_NUMSTR: PACKAGE_NUM };
	pkgVolDtl.BEST_VOL_CODE = STATUS;
	pkgVolDtl.STATUS = STATUS;
	pkgVolDtl.CYCLE = CYCLE;
	pkgVolDtl.RANK = RANK;
};

const mapFormDataToPathDtl = (formData, pkgPathDtl) => {
	const { PACKAGE_NUM, ACTIV_NUM } = formData;
	pkgPathDtl.PACKAGE_NUM = PACKAGE_NUM;
	pkgPathDtl.PackagePathDetailKey = { ...pkgPathDtl.PackagePathDetailKey, PACKAGE_NUM: PACKAGE_NUM };
	pkgPathDtl.PIPELINE_ID = ACTIV_NUM?.UP_PIPELINE_ID;
	pkgPathDtl.UP_PIPELINE_IDBAOBJ = ACTIV_NUM?.UP_PIPELINE_IDBAOBJ;
	pkgPathDtl.ACTIV_NUM = ACTIV_NUM;
};

export const mapPkgPathInfoObjToVolDtl = (pkgPathInfoObj, pkgVolDtl) => {
	const {
		UP_SHIPPER,
		UP_CNTR,
		PATH_NUM,
		NOM_RCV_VOL,
		NOM_DLV_VOL,
		FUEL,
		FUEL_PERCENT_DB
	} = pkgPathInfoObj;
	pkgVolDtl.PackageVolumeDetailKey = { ...pkgVolDtl.PackageVolumeDetailKey, PATH_NUM: PATH_NUM };
	pkgVolDtl.UP_CNTR = UP_CNTR;
	pkgVolDtl.UP_SHIPPER = UP_SHIPPER;
	pkgVolDtl.PATH_NUM = PATH_NUM;
	pkgVolDtl.NOM_RCV_VOL = NOM_RCV_VOL;
	pkgVolDtl.SCH_RCV_VOL = NOM_RCV_VOL;
	pkgVolDtl.ACT_RCV_VOL = NOM_RCV_VOL;
	pkgVolDtl.NOM_DLV_VOL = NOM_DLV_VOL;
	pkgVolDtl.SCH_DLV_VOL = NOM_DLV_VOL;
	pkgVolDtl.ACT_DLV_VOL = NOM_DLV_VOL;
	pkgVolDtl.FUEL = FUEL;
	pkgVolDtl.FUEL_PERCENT_DB = FUEL_PERCENT_DB;
};

export const mapPkgPathInfoObjToPathDtl = (pkgPathInfoObj, pkgPathDtl) => {
	const {
		PT_DIR_FLOW,
		UP_CNTR,
		DN_CNTR,
		PATH_NUM,
		NOM_RCV_VOL,
		NOM_DLV_VOL,
		FUEL_PERCENT_DB,
		UP_SHIPPER_DUNS,
		DN_SHIPPER_DUNS
	} = pkgPathInfoObj;
	pkgPathDtl.PATH_NUM = PATH_NUM;
	pkgPathDtl.PackagePathDetailKey = { ...pkgPathDtl.PackagePathDetailKey, PATH_NUM: PATH_NUM };
	pkgPathDtl.FUEL_PERCENT_DB = FUEL_PERCENT_DB;
	pkgPathDtl.NOM_RCV_VOL = NOM_RCV_VOL;
	pkgPathDtl.NOM_DLV_VOL = NOM_DLV_VOL;
	pkgPathDtl.SCH_RCV_VOL = NOM_RCV_VOL;
	pkgPathDtl.SCH_DLV_VOL = NOM_DLV_VOL;
	pkgPathDtl.DN_CNTR = PT_DIR_FLOW === 'D' ? DN_CNTR : null;
	pkgPathDtl.UP_CNTR = PT_DIR_FLOW === 'R' ? UP_CNTR : null;
	pkgPathDtl.UP_SHIPPER_DUNS = UP_SHIPPER_DUNS;
	pkgPathDtl.DN_SHIPPER_DUNS = DN_SHIPPER_DUNS;
};

/**
 * Used for balancing out non-path details for a given day.
 * Calculates total receive, deliver, and fuel quantities. Then uses them
 * to calculate the available quantity.
 * @param {any} pkgPathInfoObjs
 */
export const getBalanceInfo = (pkgPathInfoObjs) => {
	const rcvPkgPathInfoObjs = pkgPathInfoObjs?.filter(pkgPathInfoObj => pkgPathInfoObj.PT_DIR_FLOW === 'R');
	const dlvPkgPathInfoObjs = pkgPathInfoObjs?.filter(pkgPathInfoObj => pkgPathInfoObj.PT_DIR_FLOW === 'D');
	let totalRcvQty = 0;
	let totalFuel = 0;
	let totalDlvQty = 0;
	rcvPkgPathInfoObjs?.forEach(pkgPathInfoObj => {
		if (!isNaN(pkgPathInfoObj.NOM_RCV_VOL) && !isNaN(pkgPathInfoObj.FUEL)) {
			totalRcvQty += pkgPathInfoObj.NOM_RCV_VOL;
			totalFuel += pkgPathInfoObj.FUEL;
		}
	});
	dlvPkgPathInfoObjs?.forEach(pkgPathInfoObj => {
		if (!isNaN(pkgPathInfoObj.NOM_DLV_VOL))
			totalDlvQty += pkgPathInfoObj.NOM_DLV_VOL;
	});
	let availQty = totalRcvQty - totalFuel - totalDlvQty;
	return {
		totalRcvQty,
		totalDlvQty,
		totalFuel,
		availQty
	};
};

/**
 * Creates objects that contain only the essential information about a package
 * path and it's details. I chose to call these objects "pkgPathInfoObjs".
 * @param {any} pkgPath
 * @param {any} gasFlowDate
 */
export const createPkgPathInfoObjs = (selectedPackage, gasFlowDate) => {
	if (!selectedPackage) return;
	return selectedPackage?.ENT_PKG_PATH?.map(pkgPath => {

		// Get flow direction and point object.
		let PT_DIR_FLOW;
		let PT_NUM_OBJ;
		if (pkgPath.RCV_PT_NUM) {
			PT_DIR_FLOW = 'R';
			PT_NUM_OBJ = pkgPath.RCV_PT_NUM;
		}
		else if (pkgPath.DLV_PT_NUM) {
			PT_DIR_FLOW = 'D';
			PT_NUM_OBJ = pkgPath.DLV_PT_NUM;
		}
		else
			throw new Error("Package path's receive point and deliver point were both null.");

		// Grab volume and path details for selected gas flow date
		const pkgPathDtl = pkgPath?.ENT_PKG_PATH_DTL.find(pkgPathDtl => {
			const effdate = toDatePickerString(new Date(pkgPathDtl.EFFDATE));
			const gasflowdate = toDatePickerString(new Date(gasFlowDate));
			return effdate === gasflowdate;
		});
		const pkgVolDtl = pkgPath?.ENT_PKG_VOL_DTL.find(pkgVolDtl => {
			const gasflow_date = toDatePickerString(new Date(pkgVolDtl.GASFLOW_DATE));
			const gasflowdate = toDatePickerString(new Date(gasFlowDate));
			return gasflow_date === gasflowdate;
		});

		const nom_rcv_vol = pkgVolDtl?.NOM_RCV_VOL;
		const nom_dlv_vol = pkgVolDtl?.NOM_DLV_VOL;
		const fuel = pkgVolDtl?.FUEL;
		const up_cntr = pkgPathDtl?.UP_CNTR;
		const dn_cntr = pkgPathDtl?.DN_CNTR;
		const up_shipper_duns = pkgPathDtl?.UP_SHIPPER_DUNS;
		const dn_shipper_duns = pkgPathDtl?.DN_SHIPPER_DUNS;

		return {
			PACKAGE_NUM: pkgPath?.PACKAGE_NUM,
			PATH_NUM: pkgPath?.PATH_NUM,
			USERSTAMP: pkgPath?.USERSTAMP,
			DATESTAMP: pkgPath?.DATESTAMP,
			PT_DIR_FLOW: PT_DIR_FLOW,
			PT_NUM_OBJ: PT_NUM_OBJ,
			UP_CNTR: up_cntr,
			DN_CNTR: dn_cntr,
			UP_SHIPPER_DUNS: up_shipper_duns,
			DN_SHIPPER_DUNS: dn_shipper_duns,
			NOM_RCV_VOL: nom_rcv_vol,
			NOM_DLV_VOL: nom_dlv_vol,
			FUEL_PERCENT_DB: getFuelPercent(selectedPackage?.CNTR_NUM), // I believe this should always come from the contract.
			FUEL: fuel
		};
	});
};

export const getFormDataFromPackage = (selectedPackage, gasFlowDate) => {
	const newPkgPathInfoObjs = createPkgPathInfoObjs(selectedPackage, gasFlowDate);
	return {
		...selectedPackage,
		ACTIV_ALT_NUM: selectedPackage?.ACTIV_NUM?.ACTIV_ALT_NUM,
		STARTDATE: toDatePickerString(selectedPackage?.STARTDATE),
		ENDDATE: toDatePickerString(selectedPackage?.ENDDATE),
		REQUESTOR: selectedPackage?.CNTR_NUM?.CNTR_PARTY1,
		TRANSPORTER: selectedPackage?.CNTR_NUM?.CNTR_PARTY2,
		pkgPathInfoObjs: newPkgPathInfoObjs,
		...getBalanceInfo(newPkgPathInfoObjs)
	};
};

export const getFormDataFromContract = (selectedContract) => {
	return {
		PACKAGE_NUM: '',
		CNTR_NUM: selectedContract,
		REQUESTOR: selectedContract?.CNTR_PARTY1,
		TRANSPORTER: selectedContract?.CNTR_PARTY2,
		STARTDATE: toDatePickerString(getNextDay(new Date())),
		ENDDATE: toDatePickerString(getDayStart(getMonthEnd(new Date()))),
		STATUS: 'NM',
		CYCLE: '',
		TT: 'PP',
		ACTIV_NUM: {},
		ACTIV_ALT_NUM: '',
		totalRcvQty: 0,
		totalDlvQty: 0,
		totalFuel: 0,
		availQty: 0
	};
};

export const fetchWhGaApprovals = async () => {
	try {
		const url = 'WhGaApproval/GetWhGaApprovals?approval_type_IN=T&approval_type_IN=P&order_by=GAS_FLOW_MONTH:D,DATESTAMP:D';
		const response = await fetch(url);
		if (!response.ok) {
			const message = await response.text();
			throw new Error(message);
		}
		const whGaApprovals = await response.json();

		if (!whGaApprovals || !Array.isArray(whGaApprovals))
			throw new Error('Deserializing the response resulted in null or something other than an array.');

		return whGaApprovals;
	}
	catch (error) {
		console.log(`Error while processing HTTP request: ${error.message}`);
	}
};

export const getLatestApprovalDate = async () => {
	try {
		const whGaApprovals = await fetchWhGaApprovals();

		// If there are none, allow the user to edit/create for any month.
		if (whGaApprovals.length < 1)
			return null;

		// Else, check the approvals.
		let revokedMonth;
		let thisApproval;
		let thisMonth;
		let thisTransType;
		let isApproved;
		let isRevoked;

		for (let i = 0; i < whGaApprovals.length; i++) {
			thisApproval = whGaApprovals[i];
			thisMonth = thisApproval?.GAS_FLOW_MONTH;
			thisTransType = thisApproval?.TRANS_TYPE;

			// If the month we are checking is the same as the revoked month,
			// then this month has already been determined to be revoked, so continue.
			if (getDayStart(thisMonth)?.getTime() === getDayStart(revokedMonth)?.getTime())
				continue;

			// Else, this month's status has not yet been determined, so check it.
			isApproved = thisTransType === 'AP';

			// If this month is approved, then it is the latest month to be approved, so return it.
			if (isApproved)
				return getNextMonthStart(thisMonth);

			// Else, check if revoked.
			isRevoked = thisTransType === 'RV';

			// Just in case the status is something other than 'AP' or 'RV'.
			if (!isRevoked)
				throw new Error('Transaction type was neither approved nor revoked.');

			// This month is now determined to be revoked, check the next whGaApproval object.
			revokedMonth = thisMonth;
		}

		// If we exit the for loop and nothing was returned yet,
		// then we found no approved months, so the user should be allowed
		// to edit/create GA noms for any month.
		return null;
	}
	catch (error) {
		console.log(`Error while attempting to get latest approval month: ${error.message}`);
	}
};