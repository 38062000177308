function Signature({name, date, title}) {
    const customStyles = {
        height: '0px',
        border: 'none',
        borderTop: '2px solid black'
    };


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-6">&nbsp;</div>
                    <div className="col-3">
                        <div className="container">
                            <div className="row">
                                <>Name:</>
                                <hr style={customStyles}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="container">
                            <div className="row">
                                <>Date:</>
                                <hr style={customStyles}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row relaxHeight">
                    <div className="col-6">&nbsp;</div>
                    <div className="col-6">
                        <div className="container">
                            <div className="row">
                                <>Title:</>
                                <hr style={customStyles}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Signature