import { useEffect, useRef, useState } from "react";
import useCreateOptions from "./useCreateOptions";

const Selector = ({
	disabled, // Disables the selector
	className, // Class name for selector input
	value, // Selected value.
	setValue, // What should we do with the new value? Ex: (newValue) => { ** do something with new value ** }
	valueType, // Lets the Selector component know how to set the value.
	url, // Url for API fetch
	optionKey, // Name of the ID or key for the object
	optionText, // Property of the object that you wish to be displayed for each option
	defaultToFirstItem, // Should the selector default to first item or not.
	showValidation, // Should validation be shown or not?
	shouldLog, // For testing purposes.
	afterSetValue, // Function that runs after value is changed.
}) => {

	if (!valueType) throw new Error('Please supply the value type to the Selector component.');
	if (value && valueType !== typeof (value)) throw new Error('Supplied value was not of the same type as supplied value type.');
	if (!url) throw new Error('Please provide a url to the Selector component.');

	// Used for preventing an infinite loop.
	const prevValueRef = useRef();
	const prevDataRef = useRef();

	// This is what keeps track of what is selected.
	const [valueID, setValueID] = useState();

	// Hook for creating options.
	const { options, data, loading } = useCreateOptions({ url, optionKey, optionText, shouldLog });

	// Custom onChange event handler.
	const onChange = (event) => {
		const newValueID = event.target.value
		const newValue = data?.find(item => item[optionKey] === newValueID);

		// Set ID.
		setValueID(newValueID);

		// If object, set value to object.
		if (valueType === 'object') {
			setValue(newValue);
		}
		// Else if string, set value to optionKey property.
		else if (valueType === 'string') {
			setValue(newValueID);
		}

		// Run "afterSetValue" function.
		if (afterSetValue && typeof (afterSetValue) === 'function')
			afterSetValue(newValue);
	};

	useEffect(() => {
		// Get new and previous values.
		let newValue = value;
		const prevValue = prevValueRef.current;

		if (shouldLog && JSON.stringify(prevDataRef.current) !== JSON.stringify(data))
			console.log('Data changed, new data: ', data);

		if (shouldLog) {
			console.log('value: ', value);
			console.log('valueType: ', valueType);
		}

		switch (valueType) {
			case 'string': {
				if (shouldLog) console.log('prevValue: ', prevValue);

				// Else if value is null and defaultToFirstItem is true, set value to first item in list.
				if (!prevValue && defaultToFirstItem) newValue = data[0]?.[optionKey];

				if (prevValue === newValue) return;

				if (shouldLog) console.log('newValue: ', newValue);

				// Set ID.
				setValueID(newValue || '');

				// Set value.
				setValue(newValue);
				break;
			}
			case 'object': {
				// Get IDs.
				let newValueID = newValue?.[optionKey];
				const prevValueID = prevValue?.[optionKey];

				if (shouldLog) {
					console.log('prevValueID: ', prevValueID);
					console.log('newValueID: ', newValueID);
				}

				// Else if value is null and defaultToFirstItem is true, set value to first item in list.
				if (!prevValueID && defaultToFirstItem) {
					newValue = data[0];
					newValueID = newValue?.[optionKey];
				}

				if (prevValueID === newValueID) return;

				if (shouldLog) {
					console.log('prevValue: ', prevValue);
					console.log('newValue: ', newValue);
				}

				// Set ID.
				setValueID(newValueID || '');

				// Set value.
				setValue(newValue);
				break;
			}
			default: {
				break;
			}
		}

		// Update previous value.
		prevValueRef.current = newValue;
		prevDataRef.current = data;
	}, [data, value, valueType, setValueID, defaultToFirstItem, optionKey, shouldLog]);

	// If loading, return spinner.
	if (loading)
		return <select disabled={disabled} className={className} value=''><option>Loading...</option></select>;

	// Else return default selector.
	return (
		<select
			disabled={disabled}
			className={`${className} ${showValidation && 'is-invalid'}`}
			value={valueID}
			onChange={onChange}
		>
			{options}
		</select>
	);
};

export default Selector;