import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-modal';
import { Margin, Resolution, usePDF } from 'react-to-pdf';

import { non_path_noms_actions, fetchMonthlyPackage } from "../../../../../store/non-path-noms-slice";

import "./style.css"
import SchedQtyDetailReport from "./SchedQtyDetailReport";
import DailyQtyReport from "./DailyQtyReport";
import FootNote from "./Footnote";
import Spinner2 from "../../../Spinner2";

const customStyles = { overlay: { backgroundColor: 'rgb(0,0,0,.60' }, };

function SchedQtyDetailModal () {
    // Redux
    const dispatch = useDispatch()
    
    // States
    const schedQtyDetailModalState = useSelector(state => state.non_path_noms_slice.modalStates.schedQtyDetailModalState);
    const selectedSupplier = useSelector(store => store.business_selector_slice.selectedSupplier);
    const NonPathNomsFilters = useSelector(state => state.non_path_noms_slice.NonPathNomsFilters);
    
    // Export to PDF
    const { toPDF, targetRef } = usePDF({
        filename: 'ScheduledQtyDetail_' + (new Date().toJSON().slice(0,10)) + '.pdf',
        page: {
            margin: Margin.SMALL,
            format: "A4",
            orientation: "landscape"
        },
        resolution: Resolution.MEDIUM,
        options: {
            FitWindow: 'true'
        },
        canvas: {
            mimeType: "image/jpeg",
            qualityRatio: 1
        },
        overrides: {
            pdf: {
                compress: true
            },
            canvas: {
                useCORS: true
            }
        }
    });

    const closeModal = () => {
        dispatch(non_path_noms_actions.changeModalState({
            modalStateName: 'schedQtyDetailModalState',
            newModalState: { ...schedQtyDetailModalState, open: false},
        }));
    };
    
    useEffect(()=> {
        if (!schedQtyDetailModalState?.open) return; // only fetch data when opening modal
        dispatch(fetchMonthlyPackage(schedQtyDetailModalState, NonPathNomsFilters?.selectedContract, NonPathNomsFilters?.gasFlowDate));
    }, [dispatch, schedQtyDetailModalState.open, NonPathNomsFilters]);

    return (
        <>
            <Modal
                isOpen={schedQtyDetailModalState?.open}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel='Scheduled Quantity Detail Modal'
                className="sched-qty-detail-modal container-fluid bg-white text-black"
            >
                <div id="SchedQtyDetailModal" tabIndex='-1'>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title mb-2">Scheduled Quantity Detail</h4>
                                <button
                                    type="button"
                                    className="btn-close fs-6"
                                    onClick={closeModal}
                                />
                            </div>
                            <div className="modal-toolbar">
                                <div className="text-start bg-light-blue rounded m-0 p-2">
                                    <button disabled={schedQtyDetailModalState?.loading} className= "btn btn-md btn-primary me-2 mb-1" onClick={() => toPDF() }>Save PDF</button>
                                </div>
                            </div>
                            <div id="report" className="modal-body">
                                <div ref={targetRef}>
                                    {
                                        schedQtyDetailModalState?.loading
                                            ? <Spinner2/>
                                            : <>
                                                <SchedQtyDetailReport
                                                    gasFlowDate = {NonPathNomsFilters?.gasFlowDate}
                                                    selectedContract = {schedQtyDetailModalState?.selectedContract}
                                                    selectedSupplier = {selectedSupplier}
                                                />
                                                <DailyQtyReport 
                                                    gasFlowDate = {NonPathNomsFilters?.gasFlowDate}
                                                    selectedContract = {schedQtyDetailModalState?.selectedContract}
                                                />
                                                <FootNote/>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default SchedQtyDetailModal;