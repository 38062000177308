import Modal from 'react-modal';
import './modal-styles.css';


// Modal styling
const customStyles = {
	overlay: {
		backgroundColor: 'rgb(0,0,0,.60'
	}
};

const ConfirmActionModal = ({ message, header, action, isOpen, closeModal, acceptActionText }) => {
	// Checks
	if (!action) throw new Error('You must provide an action to the ConfirmActionModal.');
	if (typeof (action) !== 'function') throw new Error('Action type must be of type function.');

	// Handlers
	const acceptClick = () => {
		action();
		closeModal();
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={closeModal}
			className='confirm-action-modal container-fluid bg-white text-black p-0'
			style={customStyles}
		>
			<div className="modal-content">
				<div className="modal-header bg-primary p-2">
					<h5 className="text-white">{header}</h5>
					<button type="button" className="btn-close" onClick={closeModal} ></button>
				</div>
				<div className="modal-body p-2">
					<h5 className='fs-5'>{message}</h5>
				</div>
				<div className='modal-footer p-2'>
					<button className='btn btn-secondary me-2' onClick={closeModal}>Cancel</button>
					<button className='btn btn-danger' onClick={acceptClick}>{acceptActionText || 'Accept'}</button>
				</div>
			</div>
		</Modal>
	);
};

export default ConfirmActionModal;