import { useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { non_path_noms_actions } from "../../../store/non-path-noms-slice";
import ContractSelector from "../Selectors/ContractSelector";

const NPNomsContractSelector = ({ showValidation }) => {
	const dispatch = useDispatch();
	const { contractTypes } = useSelector(state => state.non_path_noms_slice.options);
	const NonPathNomsFilters = useSelector(state => state.non_path_noms_slice.NonPathNomsFilters);
	const selectedSupplier = useSelector(state => state.business_selector_slice.selectedSupplier);

	// Contract types for contract API fetch URL.
	const cntr_types_IN = useMemo(() => {
		return contractTypes?.map(contractType => `&cntr_types_IN=${contractType}`).join('') || '';
	}, [contractTypes]);

	return (
		<ContractSelector
			url={`Contract/GetContracts?$cntr_types_IN=${cntr_types_IN}&cntr_party1=${selectedSupplier?.BA_ID}`}
			value={NonPathNomsFilters?.selectedContract}
			setValue={(newValue) => { dispatch(non_path_noms_actions.setNonPathNomsFilters({ ...NonPathNomsFilters, selectedContract: newValue })) }}
			defaultToFirstItem
			showValidation={showValidation}
		/>
	);
};

export default NPNomsContractSelector;