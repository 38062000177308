import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPointBalances } from "../../../store/point-balance-thunks";
// import { physical_trade_deals_actions } from "../../../store/physical-trade-deals-slice";
// import custom hooks

//import components:
import StorageTable from "./Tables/StorageTable";

// custom components:
import { getMonthEnd, getMonthStart, toDatePickerString } from "../../../utils/DateUtils";
import SupplierSelect from "../../BusinessSelector/SupplierSelect";

//Hooks:
import { useDateFilter } from "./Hooks/dateHook";
import useBusinessSelector from "../../../hooks/useBusinessSelector";


function StorageInventory() {

	//Redux
	const dispatch = useDispatch();
	const selectedSupplier = useSelector(store => store.business_selector_slice.selectedSupplier.BA_ID);
	const storageData = useSelector(store => store.pointBalance_slice.storageData);
	const contracts = useSelector(store => store.general_data_slice.selectedContract);

	// State
	const [filters, setFilters] = useState({
		startDate: getMonthStart(new Date()),
		endDate: getMonthEnd(new Date()),
		displayBy: '0'
	});

	useBusinessSelector(setFilters);

	//Import custom hook for Date management and filtering:
	const {
		//startDate,
		//endDate,
		//handleStartDateChange,
		//handleEndDateChange,
		//displayBy,
		//setDisplayBy,
		filteredStorageData
	} = useDateFilter(storageData, contracts);

	//Get everything from storage
	useEffect(() => {
		dispatch(getPointBalances({
			ba_id: selectedSupplier,
			//contracts: contracts,
		}));
	}, [dispatch, selectedSupplier,
		///contracts
	]);

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-sm-12 col-md-12 col-lg-12 p-1">

					{/* Card */}
					<div className="card border-0 pb-1 p-0 pt-0">

						{/* Card Header */}
						<div className="card-header bg-primary text-white text-center p-1">
							<h4 className="card-title m-0 pt-0 pb-0">Storage Inventory</h4>
						</div>

						{/* Card Body */}
						<div className="card-body">
							<div className="row p-0">
								<h6>Filters</h6>
							</div>

							{/* Fields and selectors */}
							<div className="row bg-light-blue rounded pb-1">
								<div className="row">
									<div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
										<label className='form-label m-0'>Period From:</label>
										<input
											value={toDatePickerString(filters?.startDate)}
											name='STARTDATE'
											className="form-control form-control-sm"
											type="date"
											onChange={(e) => { setFilters(prevFilters => { return { ...prevFilters, startDate: e.target.value }; }) }}
										/>

									</div>
									<div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
										<label className='form-label m-0'>Period To:</label>
										<input
											value={toDatePickerString(filters.endDate)}
											name='ENDDATE'
											className="form-control form-control-sm"
											type="date"
											onChange={(e) => { setFilters(prevFilters => { return { ...prevFilters, endDate: e.target.value }; }) }}
										/>
									</div>
									{/* <div className="col-lg-1 col-md-1 col-sm-1 p-1 text-start">
                                        <label className='form-label m-0'>Show all:</label>
                                        <br />
                                        <input className="form-check-input" type="checkbox" value="1"
                                            id="flexCheckDefault" />
                                    </div> */}
									<div className="col-lg-1 col-md-2 col-sm-2 p-1 me-2 text-start">
										<label className='form-label m-0'>Display by:</label>
										<select
											className="form-select form-select-sm"
											type="text"
											value={filters.displayBy}
											onChange={(e) => { setFilters(prevFilters => { return { ...prevFilters, displayBy: e.target.value }; }) }}
										>
											<option value="0">Day</option>
											<option value="1">Month</option>
										</select>
									</div>

									<div className="col-lg-5 col-md-4 col-sm-12 p-1 text-start">
										<label className='form-label m-0'>Storage Customer:</label>
										<SupplierSelect />
									</div>
								</div>
							</div>

							{/* Table section: */}
							<div className="row">
								<div className="col-sm-12 col-md-12 col-lg-12">
									<StorageTable
										// storageData={storageData}
										filteredStorageData={filteredStorageData}
										getPointBalances={getPointBalances}
										displayBy={filters.displayBy}
									/>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

	);
}

export default StorageInventory;