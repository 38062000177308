import { useSelector, useDispatch } from 'react-redux';
import { setPackageAndFetchPkgPaths, non_path_noms_actions } from '../../../../../store/non-path-noms-slice';

const NonPathNomsTableButtons = ({ row }) => {
	// Redux
	const { nomDetailModalState, addNomModalState } = useSelector(state => state.non_path_noms_slice.modalStates);
	const dispatch = useDispatch();

	const viewDetailsClick = async (row) => {
		// Open nom details modal.
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'nomDetailModalState',
			newModalState: { ...nomDetailModalState, open: true }
		}));

		// Fetch package paths for selected package, this also sets the selected package.
		await dispatch(setPackageAndFetchPkgPaths(row));

		// Allow form to reset.
		await dispatch(non_path_noms_actions.setShouldResetForm(true));
	};

	const copyClick = async (row) => {
		// Open add nom modal.
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'addNomModalState',
			newModalState: { ...addNomModalState, open: true }
		}));

		// Fetch package paths for selected package, this also sets the selected package.
		await dispatch(setPackageAndFetchPkgPaths(row, true));

		// Allow form to reset.
		await dispatch(non_path_noms_actions.setShouldResetForm(true));
	};

	return (
		<div className='d-flex m-1'>
			<button className='btn btn-md btn-primary text-nowrap' onClick={() => { copyClick(row) }}>Copy</button>
			<button className='btn btn-md btn-primary text-nowrap' onClick={() => { viewDetailsClick(row) }}>Daily</button>
		</div>
	);
};

export default NonPathNomsTableButtons;