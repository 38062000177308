import { createSlice } from '@reduxjs/toolkit';
import { fetchPkgPaths } from '../utils/GeneralNomUtils';
import { notification_actions } from './notification-slice';

const initialState = {
	pkgPathsFetchLoading: false,
	bidPackages: [],
	totalBidPkgRows: 0,
	selectedBidPkg: {},
	openModal: false,
	openUiModal: false,
	openContractModal: false,
	openDealModal: false,
	openMtsContractModal: false,
	openDealTypeModal: false,
	openPointModal: false,
	openSupplierModal: false,
	openTransactionModal: false,
	openTransportModal: false,
	openFinancialDealModal: false,
	openChargeTypeModal: false,
	openPortfolioModal: false,
	openNymexModal: false,
	fercCodes: [],
	priceRefIndices: [],
	cycleCodes: [],
	mtsContracts: [],
	statusCodes: [],
	loading: {
		state: false,
		processes: 0
	},
	units: [],
	futurePrice: [],
	futurePriceDetails: [],
	selectedPortfolio: {},
	portfolios: [], 
};


const physical_trade_deals_slice = createSlice({
	name: 'physical_trade_deals_slice',
	initialState,
	reducers: {
		setPkgPathsFetchLoading(state, action) {
			state.pkgPathsFetchLoading = action.payload;
		},
		setLoading(state, action) {
			const newState = action.payload;
			if (newState === true) {
				state.loading.processes++;
				state.loading.state = true;
			}
			else if (newState === false) {
				state.loading.processes--;
				if (state.loading.processes < 1)
					state.loading.state = false;
			}
		},
		setBidPackages(state, action) {
			state.bidPackages = action.payload;
		},
		setTotalBidPkgRows(state, action) {
			state.totalBidPkgRows = action.payload;
		},
		setFilters(state, action) {
			state.filters = action.payload;
		},
		updateBidPkgDtls(state, action) {
			const newPkgVolDtls = action.payload;
			const prevBidPkg = state.selectedBidPkg;
			const prevPkg = prevBidPkg?.PACKAGE_NUM;
			const prevPkgPath = prevPkg?.ENT_PKG_PATH?.[0];
			if (newPkgVolDtls && prevBidPkg && prevPkg && prevPkgPath) {
				const newPkgPath = {
					...prevPkgPath,
					ENT_PKG_VOL_DTL: newPkgVolDtls
				};
				const newPkg = {
					...prevPkg,
					ENT_PKG_PATH: [newPkgPath]
				};
				const newBidPkg = {
					...prevBidPkg,
					PACKAGE_NUM: newPkg
				}
				state.selectedBidPkg = newBidPkg;
			}
		},
		setSelectedBidPkg(state, action) {
			state.selectedBidPkg = action.payload;
		},
		setOpenModal(state, action) {
			state.openModal = action.payload;
		},
		setFercCodes(state, action) {
			state.fercCodes = action.payload;
		},
		setPriceRefIndices(state, action) {
			state.priceRefIndices = action.payload;
		},
		setAllFuturePrice(state, action) {
			state.futurePrice = action.payload;
		},
		resetBidPkg(state, action) {
			const {
				businessUnit
			} = action.payload;

			state.selectedBidPkg = {
				SUPPLIER_BAID_OBJ: {},
				BUYERID_OBJ: businessUnit,
				BID_NUM: '',
				PACKAGE_NUM: {},
				BIDDATE: new Date().toISOString(),
				BIDTYPE: 'B',
				QUANTITY: 0,
				UNIT_MEAS: 'DT',
				PRICE_REF_CDE: {},
				CNTR_NUM: {},
				MTS_CNTR_NUM: {},
				START_PT_NUM: {},
				STARTDATE: new Date().toISOString(),
				ENDDATE: new Date().toISOString(),
				BLOCK: '',
				STATUS: '',
				CHG_REF_CDE: 'CO',
				VOLUME_TYPE: '',
				USERSTAMP: '',
				DATESTAMP: new Date().toISOString(),
				COMP_CONTACT: '',
				PRICE_OFFSET: 0,
				BID_SYSTEM: 'T',
				DISPOSITION: '',
				DEAL_NOTE: '',
				DEAL_TYPE: '',
				AMEND_NUM: '',
				AP_USERSTAMP: '',
				AP_APPROVE_DATE: new Date().toISOString(),
				FERC_552_CODE: '',
				PIPELINE_ID: '',
				PriceOffSet: '',
				DailyPriceStringFormat: '',
				MD_FLAG: '',
				FIXED_INDEX_IND: 'F',
				FIXED_PRICE: 0,
				PRICE_AREA_ID: '',
			};
		},
		setOpenUiModal(state, action) {
			state.openUiModal = action.payload;
		},
		setOpenFinancialDealModal(state, action) {
			state.openFinancialDealModal = action.payload;
		},

		setOpenContractModal(state, action) {
			state.openContractModal = action.payload;
		},
		setOpenDealModal(state, action) {
			state.openDealModal = action.payload;
		},
		setOpenMtsContractModal(state, action) {
			state.openMtsContractModal = action.payload;
		},
		setOpenDealTypeModal(state, action) {
			state.openDealTypeModal = action.payload;
		},
		setOpenPointModal(state, action) {
			state.openPointModal = action.payload;
		},
		setOpenSupplierModal(state, action) {
			state.openSupplierModal = action.payload;
		},
		setOpenTransactionModal(state, action) {
			state.openTransactionModal = action.payload;
		},
		setOpenTransportModal(state, action) {
			state.openTransportModal = action.payload;
		},
		setOpenChargeTypeModal(state, action) {
			state.openChargeTypeModal = action.payload;
		},
		setOpenPortfolioModal(state, action) {
			state.openPortfolioModal = action.payload;
		},
		setOpenNymexModal(state, action) {
			state.openNymexModal = action.payload;
		},

		copyBidPkg(state) {
			state.selectedBidPkg.BIDTYPE === 'B' ?
				state.selectedBidPkg = {
					...state.selectedBidPkg,
					BID_NUM: '',
					BIDDATE: new Date().toISOString(),
					BIDTYPE: 'B',
					//STARTDATE: new Date().toISOString(),
					//ENDDATE: new Date().toISOString(),
					STATUS: 'PN',
					USERSTAMP: '',
					DATESTAMP: new Date().toISOString(),
					DEAL_NOTE: '',
					AMEND_NUM: '',
					AP_USERSTAMP: '',
					//AP_APPROVE_DATE: new Date().toISOString(),
					PACKAGE_NUM: {},
				}
				:
				state.selectedBidPkg = {
					...state.selectedBidPkg,
					BID_NUM: '',
					BIDDATE: new Date().toISOString(),
					BIDTYPE: 'S',
					//STARTDATE: new Date().toISOString(),
					//ENDDATE: new Date().toISOString(),
					STATUS: 'PN',
					USERSTAMP: '',
					DATESTAMP: new Date().toISOString(),
					DEAL_NOTE: '',
					AMEND_NUM: '',
					AP_USERSTAMP: '',
					//AP_APPROVE_DATE: new Date().toISOString(),
					PACKAGE_NUM: {},
				};
		},
		setCycleCodes(state, action) {
			state.cycleCodes = action.payload;
		},
		setMtsContracts(state, action) {
			state.mtsContracts = action.payload;
		},
		setStatusCodes(state, action) {
			state.statusCodes = action.payload;
		},
		setUnits(state, action) {
			state.units = action.payload;
		},
		setFuturePriceDetails(state, action) {
			state.futurePriceDetails = action.payload;
		},
		setSelectedPortfolio(state, action) {
			state.selectedPortfolio = action.payload;
		},
		setPortfolios(state, action) {
			state.portfolios = action.payload;
		},
	},
},
);

export const setBidPackageAndFetchPkgPaths = (selectedBidPkg) => {
	return async (dispatch, getState) => {
		try {
			await dispatch(physical_trade_deals_actions.setPkgPathsFetchLoading(true));

			// Fetch package paths for package.
			const selectedPackage = selectedBidPkg?.PACKAGE_NUM;
			const package_num = selectedPackage?.PACKAGE_NUM;
			const pkgPaths = await fetchPkgPaths(package_num);

			// Update package with new package paths and set bid package.
			const newSelectedPackage = { ...selectedPackage, ENT_PKG_PATH: pkgPaths };
			await dispatch(physical_trade_deals_actions.setSelectedBidPkg({ ...selectedBidPkg, PACKAGE_NUM: newSelectedPackage }));
			
			const portfolios = getState().physical_trade_deals_slice?.portfolios;
			const selectedPortfolio = portfolios?.find((portfolio) => portfolio.CDE === selectedBidPkg?.VOLUME_TYPE);
			await dispatch(physical_trade_deals_actions.setSelectedPortfolio(selectedPortfolio));
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
		finally {
			await dispatch(physical_trade_deals_actions.setPkgPathsFetchLoading(false));
		}
	};
};

export const physical_trade_deals_actions = physical_trade_deals_slice.actions;
export default physical_trade_deals_slice.reducer;

