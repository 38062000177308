import { useRef } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';

// redux
import { useSelector } from 'react-redux';

//import custom date functions
import { toShortDate } from '../../../utils/DateUtils'

import excel from '../../../assets/images/excel.gif';

import Restricted from '../../Restricted';


function NonDailyBrokerReport({
	componentState,
	setComponentState
}) {

	// redux
	const suppliers = useSelector(state => state.business_selector_slice.suppliers);
	const contracts = useSelector(state => state.general_data_slice.contracts);

	// tableRef for Excel Export:
	const tableRef = useRef();

	//Handling the Excel Download:
	const { onDownload } = useDownloadExcel({
		// currentTableRef: tableRef.current,
		currentTableRef: tableRef.current,
		filename: 'Non-Daily-Broker-Report',
		sheet: 'Non-Daily-Broker-Table'
	});

	return (
		<>
			<div className="modal modal-xl " id="BrokerReport" aria-hidden="true" aria-labelledby="BrokerReport Report" tabIndex="-1">
				<div className="modal-dialog modal-dialog-centered ">
					<div className="modal-content text-center">

						<div className="modal-header text-center bg-primary text-white justify-content-center">
							<h1 className="fs-5" id="BrokerReport">Broker Delivery Report</h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="row text-start">
							<div className="col-sm-12 col-md-12 col-lg-12 p-2">
								<button className='btn btn-md btn-primary m-2' onClick={onDownload}><img src={excel} alt='Excel Report' /> Excel Report</button>
							</div>
						</div>
						{
							// Filter Section
							<div className="container-fluid p-3">
								<div className='row mb-3 bg-light-blue rounded p-2 rounded'>
									<h6>Display Filters</h6>
									<div className="col-lg-2 col-md-2 col-sm-12 text-start">
										<label className='form-label ps-4 pe-2'>Display Month</label>
										<input className="form-control form-control-sm" type="month" value={`${new Date().getFullYear()}-${(new Date().getMonth() > 8 ? "" : "0") + (new Date().getMonth() + 1)}`} onChange={() => { }}
											style={{ maxWidth: '180px' }} />
									</div>
									<div className="col-lg-4 col-md-4 col-sm-12 text-start">
										<label className='form-label pe-2'>Supplier Name</label>
										<Restricted
											restrictedTo={['SYS_ADMIN-ACCESS']}
											action='disable'
										>
											<select
												className='form-select form-select-sm'
												aria-label="select public or private"
												onChange={(event) => {
													const newSelectedSupplier = suppliers.find(supplier => supplier.BA_ID === event.target.value);
													setComponentState(
														prevState => {
															return {
																...prevState,
																selectedSupplier: newSelectedSupplier
															};
														}
													);
												}}
												value={componentState.selectedSupplier.BA_ID}
											>
												{
													suppliers &&
													suppliers.map(
														supplier => {

															return (

																<option
																	key={supplier.BA_ID}
																	value={supplier.BA_ID}
																>
																	{supplier.NAME}
																</option>

															);

														}
													)
												}
											</select>
										</Restricted>
									</div>
									<div className="col-lg-2 col-md-2 col-sm-12 text-start">
										<label className='form-label pe-2'>Supplier#</label>
										<select
											className='form-select form-select-sm'
											aria-label="select public or private"
											value={componentState.selectedContract}
											onChange={(event) => {
												setComponentState(
													prevState => {

														const newSelectedContract = contracts.find(contract => contract.CNTR_NUM === event.targe.value);

														return {
															...prevState,
															selectedContract: newSelectedContract
														};
													}
												);
											}}
										>
											{
												contracts.map(
													contract => {
														return (

															<option
																key={contract.CNTR_NUM}
																value={contract.CNTR_NUM}
															>
																{contract.CNTR_ALT_NUM1}
															</option>

														);
													}
												)
											}
										</select>
									</div>
								</div>
							</div>
						}
						{
							// Daily Pool Summary Table
							componentState.dailySummaryDetails.length > 0
								?
								<div className="table-responsive mt-2">
									<table className="bg-white table table-sm table-hover table-striped-columns table-bordered border-primary rounded pb-1"
										ref={tableRef}>
										<thead>
											<tr>
												<th>Date</th>
												<th>Delivery</th>
												<th>Usage</th>
												<th>Difference</th>
												<th>Traded</th>
												<th>Net Delivery</th>
											</tr>
										</thead>
										<tbody>
											{
												// Data
												componentState.dailySummaryDetails.map(
													detail => {
														return (
															<tr key={detail.GASFLOW_DATE + detail.PT_MKPOOL_CHOICE_KEY}>
																<td>{toShortDate(detail.GASFLOW_DATE)}</td>
																<td>{detail.CG_NOM_QTY}</td>
																<td>{detail.F_ENERGY}</td>
																<td>{detail.F_CG_IMBAL}</td>
																<td>{detail.TRADE_QTY}</td>
																<td>{detail.F_CG_IMBAL_NET}</td>
															</tr>
														)
													}
												)
											}
											{
												// Totals Row
												<tr className='fw-bold'>
													<td>Total:</td>
													<td>{componentState.dailySummaryDtlTotals.CG_NOM_QTY}</td>
													<td>{componentState.dailySummaryDtlTotals.F_ENERGY}</td>
													<td>{componentState.dailySummaryDtlTotals.F_CG_IMBAL}</td>
													<td>{componentState.dailySummaryDtlTotals.TRADE_QTY}</td>
													<td>{componentState.dailySummaryDtlTotals.F_CG_IMBAL_NET}</td>
												</tr>
											}
											{
												// Gross Short Fall Row
												<tr className='fw-bold'>
													<td>Gross Short Fall:</td>
													<td>{componentState.dailySummaryDtlTotals.F_CG_IMBAL}</td>
													<td></td>
													<td></td>
													<td>{componentState.dailySummaryDtlTotals.TRADE_QTY}</td>
													<td>{componentState.dailySummaryDtlTotals.F_CG_IMBAL}</td>
												</tr>
											}
										</tbody>
									</table>
								</div>
								:
								<p className="text-center">No Records Found.</p>
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default NonDailyBrokerReport