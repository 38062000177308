
const CustomStyles = {
	table: {
		style: {
			position: 'sticky',
			top: '0px',
			border: '1px solid blue',
			// striped: 'default',
		},
	},
	headRow: {
		style: {
			position: 'sticky',
			top: '0px',
			fontSize: ".9rem",
			fontWeight: "normal"
			// backgroundColor: 'blue'
		},
	},
	cells: {
		style: {
			fontSize: '.9rem',
			// border: '1px solid blue',
			// paddingLeft: '1px'
		}
	}
}

export default CustomStyles