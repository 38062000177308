import { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDayStart, toDatePickerString, toISOStringForDatabase } from '../../../utils/DateUtils';
import { Link } from "react-router-dom";

import useBusinessSelectorRedux from '../../../hooks/useBusinessSelectorRedux';
import { non_path_noms_actions } from '../../../store/non-path-noms-slice';

import add from '../../../assets/images/add.gif';
import efsReport from '../../../assets/images/efs-report.gif';

import Restricted from '../../Restricted';

import AddNom from "../../CustomComponents/NonPathNomUI/Modals/AddNomModal";
import SpecifyLocationQuantity from '../../CustomComponents/NonPathNomUI/Modals/SpecifyLocationQuantityModal';
import NonPathNomDtlModal from '../../CustomComponents/NonPathNomUI/Modals/NonPathNomDtlModal';
import ViewDetailsModal from '../../CustomComponents/NonPathNomUI/Modals/ViewDetailsModal';
import EstimatedFlowModal from '../../CustomComponents/NonPathNomUI/Modals/EstimatedFlowModal';
import OBAStatementModal from '../../CustomComponents/NonPathNomUI/Modals/OBAStatementModal';
import SchedQtyDetailModal from './Modals/SchedQtyDetailModal';
import NonPathNomsTable from './Tables/NonPathNomsTable';
import NPNomsContractSelector from './NPNomsContractSelector';

const NonPathNomUI = ({ options, CustomNonPathNomsTableColumns }) => {

	// Redux
	const { 
		addNomModalState, 
		estimatedFlowModalState, 
		obaStatementModalState,
		schedQtyDetailModalState,
	} = useSelector(state => state.non_path_noms_slice.modalStates);
	const NonPathNomsFilters = useSelector(state => state.non_path_noms_slice.NonPathNomsFilters);
	const { title, buttons } = useSelector(state => state.non_path_noms_slice.options);
	const dispatch = useDispatch();

	// Validation
	const [showValidation, setShowValidation] = useState(false);
	const isValid = useMemo(() => { return NonPathNomsFilters?.selectedContract; }, [NonPathNomsFilters]);
	useEffect(() => { if (isValid) setShowValidation(false); }, [isValid]);

	// Initialize redux state.
	useEffect(() => {
		const initialize = async () => {
			await dispatch(non_path_noms_actions.reset());
			await dispatch(non_path_noms_actions.setOptions(options));
		};
		initialize();
	}, [dispatch, options]);

	const createNominationClick = async () => {
		// Validation
		if (!isValid) {
			setShowValidation(true);
			return;
		}

		// Blank out selected package
		await dispatch(non_path_noms_actions.setSelectedPackage(null));

		// Allow the form to reset.
		await dispatch(non_path_noms_actions.setShouldResetForm(true));

		// Open Add Nom Modal
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'addNomModalState',
			newModalState: { ...addNomModalState, open: true }
		}));
	};

	const openEstimatedFlowModal = () => {
		dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'estimatedFlowModalState',
			newModalState: { ...estimatedFlowModalState, open: true }
		}));
	};

	const openOBAStatementModal = () => {
		dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'obaStatementModalState',
			newModalState: { ...obaStatementModalState, open: true }
		}));
	};

	const openSchedQtyDetailModal = () => {
		const curGasFlowDate = NonPathNomsFilters?.gasFlowDate;
		
		dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'schedQtyDetailModalState',
			newModalState: { 
				...schedQtyDetailModalState,
				open: true,
				gasFlowDate: curGasFlowDate,
				selectedContract: NonPathNomsFilters?.selectedContract,
			}
		}))
	}

	const setGasFlowDate = (event) => {
		const newGasFlowDate = toISOStringForDatabase(getDayStart(event.target.value))
		dispatch(non_path_noms_actions.setNonPathNomsFilters({ ...NonPathNomsFilters, gasFlowDate: newGasFlowDate }));
	};

	// Business Selector logic
	useBusinessSelectorRedux(NonPathNomsFilters, non_path_noms_actions.setNonPathNomsFilters, true);

	return (
		<div className="container-fluid main-bg text-white" >
			<div className='card mt-3'>
				<div className='card-header text-center bg-primary text-white p-0'><h4>{title}</h4></div>
				<div className='card-body mt-0'>
					{/* // BUTTONS */}
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 d-flex pb-2">
							<Restricted
								restrictedTo={['WRITE-ACCESS']}
							>
								{
									buttons?.find(button => button === 'AddNom') &&
									<button
										className=" btn btn-primary btn-sm me-2"
										onClick={createNominationClick}
									>
										<img
											src={add}
											alt="Add"
											className="p-1"
										/>
										Add Nomination
									</button>
								}

								{
									buttons?.find(button => button === 'EstimatedFlow') &&
									<button className="btn btn-primary btn-sm me-2" onClick={openEstimatedFlowModal}>
										<img
											src={efsReport}
											alt="efs-report"
											className="p-1"
										/>
										EFS Screen
									</button>
								}

							</Restricted>
							{
								buttons?.find(button => button === 'GasAllocation') &&
								<Link to="/GasAllocation">
									<button className="btn btn-primary btn-sm h-100 me-2">
										Gas Allocation
									</button>
								</Link>


							}
							{
								buttons?.find(button => button === 'OBAStatement') &&
								<button className="btn btn-primary btn-sm h-100 me-2" onClick={openOBAStatementModal}>
									OBA Statement
								</button>
							}
							{
								buttons?.find(button => button === 'SchedQtyDetail') &&
								<button className="btn btn-primary btn-sm h-100 me-2" onClick={openSchedQtyDetailModal}>
									Scheduled Qty Detail
								</button>
							}
						</div>
					</div>
					{/* // FILTERS, AND NOM SPLIT PERCENTAGE TABLE */}
					<div className="row bg-light-blue rounded text-dark p-2">
						<h5>Display Filters:</h5>
						{/* // FILTERS */}
						<div className="col-lg-1 col-md-2 col-sm-12 me-5">

							<form>
								<label>
									Gas Flow Date:
									<br />
									<input
										className="form-control form-control-sm"
										type="date"
										value={toDatePickerString(NonPathNomsFilters?.gasFlowDate)}
										onChange={setGasFlowDate}
										name='gasFlowDate'
										min="2023-11-01" // november 2023 devops 4666
									/>
								</label>
							</form>
						</div>
						<div className="col-lg-1 col-md-2 col-sm-12">
							<form>
								<label>
									Contract:
									<br />
									<NPNomsContractSelector showValidation={showValidation} />
								</label>
							</form>
						</div>
					</div>
					<div className='row mt-3'>
						<NonPathNomsTable customColumns={CustomNonPathNomsTableColumns} />
					</div>
				</div>
			</div>

			{/* // MODALS */}
			<AddNom />
			<SpecifyLocationQuantity />
			<NonPathNomDtlModal />
			<ViewDetailsModal />
			<EstimatedFlowModal />
			<OBAStatementModal />
			<SchedQtyDetailModal />
		</div>
	);
};

export default NonPathNomUI;