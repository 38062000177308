import DataTable from "react-data-table-component";

import CustomStyles from "./Table-Styles";

function StorageTable({ filteredStorageData, displayBy }) {


    let columns = [{
        name: "Storage",
        selector: row => row.ContractObj.CNTR_PARTY1.SHORTNAME,
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "Contract ID",
        selector: row => row.CNTR_ALT_NUM1,
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "Storage Pool",
        selector: row => row.PT_NAME,
        sortable: true,
        compact: true,
        wrap: true,

    },
    {
        name: "BegBal",
        selector: row => row.BEG_IMBAL.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "BegValue",
        selector: row => row.BEG_VALUE == null ? "0" : Math.ceil(row.BEG_VALUE).toString().toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,
    },

    {
        name: "InjQty",
        selector: row => row.VOLUMEIN == null ? "No Data" : row.VOLUMEIN.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "InjValue",
        selector: row => row.VOLUMEIN_VALUE == null ? "No Data" : row.VOLUMEIN_VALUE.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "WD Qty",
        selector: row => row.VOLUMEOUT == null ? "No Data" : row.VOLUMEOUT.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "WD Value",
        selector: row => row.VOLUMEOUT_VALUE == null ? "No Data" : row.VOLUMEOUT_VALUE.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,
    },
    {
        name: "EndBal",
        selector: row => row.END_IMBAL == null ? "No Data" : row.END_IMBAL.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "EndValue",
        selector: row => row.END_VALUE == null ? "No Data" : row.END_VALUE.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,


    },
    {
        name: "AdjQty",
        selector: row => row.ADJ_QTY == null ? "No Data" : row.ADJ_QTY.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "AdjValue",
        selector: row => row.ADJ_VALUE == null ? "0.00" : row.ADJ_VALUE.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "NetEndBal",
        selector: row => row.NET_END_IMBAL == null ? "No Data" : row.NET_END_IMBAL.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "NetEndValue",
        selector: row => row.NET_END_VALUE == null ? "No Data" : row.NET_END_VALUE.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "MSQ",
        selector: row => row.MSQ == null ? "0" : row.MSQ
        ,
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "%Fill",
        selector: row => row.netMSQPercent4Binding == null ? "No Data" : row.netMSQPercent4Binding,
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "IMDQ",
        selector: row => row.IMDQ == null ? "0" : row.IMDQ,
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "WMDQ",
        selector: row => row.WMDQ == null ? "No Data" : row.WMDQ,
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "Comments",
        selector: row => row.AdjComments == null ? "No Data" : row.AdjComments,
        sortable: true,
        compact: true,
        wrap: false,

    }]

    // Add Gas Flow Date column when "Display by" is "day"
    if (displayBy === "0") {
        const gasFlowDate = {
            name: "Gas Flow Date",
            selector: row => row.GASFLOW_DATE == null ? "No Data" : new Date(row.GASFLOW_DATE).toLocaleDateString('en-US', {
				day: 'numeric',
				month: 'numeric',
				year: 'numeric'
			}),
            sortable: true,
            compact: true,
            wrap: false,
        };
        const dayColumns = [
            ...columns.slice(0, 3),
            gasFlowDate,
            ...columns.slice(3)
        ];
        columns = dayColumns;
    }

    return (
        <div className="container-fluid">
            <DataTable
                title="Storage Inventory"
                columns={columns}
                // data={dataWithTotals}
                data={filteredStorageData}
                dense={true}
                striped={true}
                fixedHeader={true}
                fixedHeaderScrollHeight="450px"
                customStyles={CustomStyles}
                highlightOnHover={true}
                className="pb-1"
            />
        </div>
    )
}

export default StorageTable