import AgreementBalanceTable from "./AgreementBalanceTable"

function DataView({isReceipt, cntr_num, subject, gasAllocationData}) {
    const filteredGasAllocationData = gasAllocationData.filter( e => {
        return (e.isReceipt === isReceipt && e.isPPA === false && e.cntr_num === cntr_num)
    });
    
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-4 col-sm-4">
                        <div className="subject">{subject}</div>
                    </div>
                    <div className="col-8 col-sm-8">
                        <AgreementBalanceTable className="table" gasAllocationData={filteredGasAllocationData}></AgreementBalanceTable>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DataView;