import { useState, useEffect } from 'react';
import { notification_actions } from '../../../store/notification-slice';

//Image imports
import Clear from '../../../assets/images/clear.png';
import Cancel from '../../../assets/images/cancel.png';

//Import Modules:
import Table from './Table';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { physical_trade_deals_actions } from '../../../store/physical-trade-deals-slice';

import './SelectSupplierModalStyling.css';
import SearchBar from '../../CustomComponents/SearchBar';

// Modal styling
const customStyles = {
	overlay: {
		backgroundColor: 'rgb(0,0,0,.60'
	},
};

function SelectSupplierModal() {

	// Redux:
	const selectedBidPkg = useSelector(state => state.physical_trade_deals_slice.selectedBidPkg);
	const openSupplierModal = useSelector(state => state.physical_trade_deals_slice.openSupplierModal);
	const dispatch = useDispatch();

	// State
	const [counterParties, setCounterParties] = useState();
	const [loading, setLoading] = useState();
	const [filteredData, setFilteredData] = useState();

	// API fetch
	useEffect(() => {
		const handleAsync = async () => {
			const requestData = async () => {
				const response = await fetch(`GeneralData/GetBAs?ba_role=BS&cntr_type=MC`);
				if (!response.ok) {
					const message = await response.text();
					throw new Error(message);
				}
				return await response.json();
			};
			try {
				setLoading(true);
				const data = await requestData();
				setCounterParties(data);
			}
			catch (error) {
				dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
			}
			finally {
				setLoading(false);
			}
		};
		handleAsync();
	}, [dispatch]);

	// Modal State:
	const closeSupplierModal = () => {
		dispatch(physical_trade_deals_actions.setOpenSupplierModal(false));
	};

	return (
		<>
			<Modal
				isOpen={openSupplierModal}
				onRequestClose={closeSupplierModal}
				className="select-supplier-modal container-fluid bg-white text-black"
				style={customStyles}
			>
				<div id="SelectSupplierModal" tabIndex='-1'>
					<div className='modal-dialog '>
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title mb-2">{selectedBidPkg?.BIDTYPE === 'B' ? 'Select a Supplier' : 'Select a Buyer'}</h4>
								<button
									type="button"
									className="btn-close fs-6"
									onClick={closeSupplierModal}
								>
								</button>
							</div>
							<div className="modal-body">

								{/* // BUTTONS */}
								<div className="row text-start bg-light-blue rounded-top m-0 p-2">
									<div className="col-lg-3 col-md-12 col-md-12 col-sm-12">
										<button
											type="button"
											className="btn btn-primary m-1"
											title="New Deal"
											onClick={closeSupplierModal}
										>
											<img src={Clear} alt="Clear" /> Clear
										</button>
										<button
											type="button"
											className="btn btn-primary m-1"
											title="Cancel"
											onClick={closeSupplierModal}
										>
											<img src={Cancel} alt="AddImage" /> Cancel
										</button>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-12">
										{/* Search Bar */}
										<SearchBar
											data={counterParties}
											setFilteredData={setFilteredData}
										/>
									</div>
								</div>
								{/* SELECT A DEAL TABLE */}
								<div className='row text-start'>
									<Table
										counterParties={filteredData}
										loading={loading}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default SelectSupplierModal