import Selector from './Selector';

const CodeSelector = ({ value, setValue, url, valueType }) => {
	return (
		<Selector
			className='form-select form-select-sm'
			value={value}
			setValue={setValue}
			valueType={valueType || 'string'}
			url={url}
			optionKey='CDE'
			optionText='CDE_TXT'
		/>
	);
};

export default CodeSelector;