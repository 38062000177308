import OBAStatementTable from '../../Tables/OBAStatementTable';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toDatePickerMonthString, getNextDay, setTimeToNine, getMonthStart } from '../../../../../utils/DateUtils';
function OBAReport({ date, title, table: Table}) {
    const obaStatementModalState = useSelector(state => state.non_path_noms_slice.modalStates.obaStatementModalState);
    const dispatch = useDispatch();
    const dateObject = new Date(date);
    const month = dateObject.getUTCMonth() + 1;
    const year = dateObject.getUTCFullYear();
    const formattedDate = new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format(new Date(year, month - 1));

    const businessUnitName = useSelector(state => state.business_selector_slice.businessUnit.NAME);
    const cntr_party1 = useSelector(state => state.business_selector_slice.selectedSupplier.NAME);
    const cntr_alt_num1 = obaStatementModalState?.selectedContract?.CNTR_ALT_NUM1;
    const cntr_title = obaStatementModalState?.selectedContract?.CNTR_TITLE;
    const curTime = new Date().toLocaleString("en-us")
    const userContact = useSelector(state => state.auth_slice.userContact);
   // console.log(cntr_alt_num1);

    return (
        <div>
            <div className='row'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                    <h6 className="mb-4">Run Date/Time: {curTime}</h6>
                    <h5 className="mb-1 d-flex justify-content-between">
                        <span className="text-start">{businessUnitName}</span>
                        <span className="text-end">For Business Period: {formattedDate}</span>
                    </h5>
                    <h3 className='mb-1'>{title}</h3>
                    <h5 className='mb-1'>{cntr_party1}</h5>
                    <h5 className='mb-1'>Contract No: {cntr_alt_num1} {cntr_title}</h5> 
                    <hr className='mt-1 mb-1' />
                </div>
            </div>
            <Table date={date} />
            <div className="container-fluid">
                <div className="row mt-4">
                    <div className="col-10">&nbsp;</div>
                    <div className="col-2">User ID: {userContact?.FIRST_NAME} {userContact?.NAME} </div>
                </div>
            </div>
        </div>
    )
}

export default OBAReport;