// react
import { useState, useRef } from 'react';

// redux
import { useDispatch } from 'react-redux';
import { signIn } from '../../store/auth-slice';

//recaptcha
import ReCAPTCHA from "react-google-recaptcha";


// images
import logo1 from '../../assets/images/gastar-logo.png'

// custom components
import LoginForm from './LoginForm';
//import Modal from 'react-modal';

// Modals
import ResetPasswordModal from './Modals/ResetPasswordModal';
import ForgotPasswordModal from './Modals/ForgotPasswordModal';
import PasswordResetSuccessModal from './Modals/PasswordResetSuccessModal';
import PassFailureModal from '../CustomComponents/PassFailureModal';
import ChangePasswordModal from './Modals/ChangePasswordModal';

// Styles
import './LoginModalStyling.css';

function Login() {

	const dispatch = useDispatch();

	// react state
	const [loginState, setLoginState] = useState({
		isLoading: false,
		password: '',
		user_id: ''
	});

	const [passwordVisible, setPasswordVisible] = useState(false);
	const [recaptchaClicked, setRecaptchaClicked] = useState(false)
	const [recaptchaChecked, setRecaptchaChecked] = useState(false);
	// New state variable to manage the visibility of the reCAPTCHA error modal
	const [showRecaptchaErrorModal, setShowRecaptchaErrorModal] = useState(false);

	//login function
	const logUserIn = async (event) => {
		event.preventDefault();

		if (process.env.NODE_ENV === 'production') { // This ensures that captcha is only required in a production environment.
			if (!loginState.token) {
				setRecaptchaClicked(true);
				return;
			}

			if (!recaptchaChecked) {
				setShowRecaptchaErrorModal(false);
				return;
			}
		}

		setLoginState((prevState) => ({
			...prevState,
			isLoading: true,
		}));

		await dispatch(
			signIn({
				user_id: loginState.user_id,
				password: loginState.password,
			})
		);

		setLoginState(prevState => ({
			...prevState,
			isLoading: false
		}));

		clearPassword();
	};


	const clearPassword = () => {
		setLoginState(prevState => ({
			...prevState,
			password: '',
			//user_id: ''
		}));
		setPasswordVisible(false);
	};

	const togglePassword = () => {
		setPasswordVisible(!passwordVisible);
	};

	// recaptcha functions
	const handleToken = (token) => {
		setLoginState(prevState => ({
			...prevState,
			token,
		}));
		setRecaptchaChecked(true);
	};

	const recaptchaRef = useRef();

	const handleExpire = () => {
		setLoginState(prevState => ({
			...prevState,
			token: null,
		}));

		setRecaptchaClicked(true);

		// Reset reCAPTCHA here
		recaptchaRef.current.reset();
	};

	return (
		<div>
			{/* Login Modal below */}
			<div className="modal fade login-modal" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header text-center p-3 pb-2">
							<h1 className="fs-5 text-center" id="exampleModalToggleLabel">GASTAR Login</h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearPassword}></button>
						</div>
						<form onSubmit={logUserIn}>
							<div className="modal-body justify-content-center text-center fs-6 pb-0 pt-2">
								<img src={logo1} alt='Gastar Login' className='img-fluid' style={{ maxWidth: '120px' }} />
								<br />

								<LoginForm
									loginState={loginState}
									setLoginState={setLoginState}
									passwordVisible={passwordVisible}
								/>
								<ReCAPTCHA
									ref={recaptchaRef}
									sitekey={process.env.REACT_APP_SITE_KEY}
									onChange={handleToken}
									onExpired={handleExpire}
									className='mt-3'
									required />
								{(!recaptchaChecked && recaptchaClicked) && <p>Please complete the reCAPTCHA!</p>}


								{/* Forgot password and Change password */}
								<div className='row mt-1'>
									<div className='col-6 text-start'>
										<p
											className='fs-6 text-start mt-2 hover-link'
											type='button'
											onClick={clearPassword}
											data-bs-toggle="modal"
											data-bs-target="#forgot-password"
											aria-controls="change-password"
										>
											Forgot Password?
										</p>
									</div>
								</div>
							</div>
							<div className="modal-footer p-1">
								<label className="me-auto">
									<input className="form-check-input ms-1 me-1" id="checkbox" type="checkbox" onChange={togglePassword} />
									Show Password
								</label>
								{
									!loginState.isLoading
									&&
									<button
										className='btn btn-lg btn-primary'
										data-bs-dismiss="modal"
									// type="submit" // Modified: Added type="submit"
									>
										Login
									</button>
								}
							</div>
						</form>
					</div>
				</div>
			</div >

			{/* Modals */}
			<ResetPasswordModal />
			<ForgotPasswordModal />
			<PasswordResetSuccessModal />
			<PassFailureModal />
			<ChangePasswordModal />



			{/* Password Request Sent Modal */}
			<div className="modal" id="pass-reset-complete" aria-hidden="true" aria-labelledby="pass-reset-complete" tabIndex="-1">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header text-center">
							<h1 className="fs-5 text-center" id="pass-reset-complete">Password Reset Request Sent!</h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<form>
							<div className="modal-body justify-content-center text-center fs-5">
								<img src={logo1} alt='Gastar Logo' className='img-fluid mb-4' style={{ maxWidth: '200px' }} />
								<br />
								<h2 className='fs-3'>Password reset request sent!</h2>
							</div>
							<div className="modal-footer">
								<button type='button' className='btn btn-lg btn-primary' data-bs-dismiss="modal">Close</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div >);
};

export default Login;