import Selector from './Selector';

const BASelector = ({ className, value, setValue, url, valueType }) => {
	return (
		<Selector
			className={className || 'form-select form-select-sm'}
			value={value}
			setValue={setValue}
			valueType={valueType || 'object'}
			url={url}
			optionKey='BA_ID'
			optionText='SHORTNAME'
		/>
	);
};

export default BASelector;