import { formatDateTime } from "../../../../utils/DateUtils";

export const columns = [
	{
		name: 'Activity #',
		selector: row => row?.ACTIV_ALT_NUM,
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true
	},
	{
		name: 'Contract ID',
		selector: row => row?.Contract?.CNTR_ALT_NUM1,
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
		center: true
	},
	{
		name: 'Transporter',
		selector: row => row?.Transporter?.SHORTNAME,
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
		center: true
	},
	{
		name: 'Rec Point(s)',
		cell: row => (
			<ul className="list-group">
				{
					row?.ENT_ACTIVITY_NONPATH?.map(
						nonPathActivity => {
							return (
								nonPathActivity?.PT_DIR_FLOW === "R" &&
								<li
									key={nonPathActivity?.PT_NUM_OBJ?.PT_NUM}
									className="no-bullets"
								>
									{nonPathActivity?.PT_NUM_OBJ?.POINT_ID + "\n"}
								</li>
							);
						}
					) || ''
				}
			</ul>
		),
		compact: true,
		wrap: true,
		reorder: true,
		center: true
	},
	{
		name: 'Del Point(s)',
		cell: row => (
			<ul className="list-group">
				{
					row?.ENT_ACTIVITY_NONPATH?.map(
						nonPathActivity => {
							return (
								nonPathActivity?.PT_DIR_FLOW === "D" &&
								<li
									key={nonPathActivity?.PT_NUM_OBJ?.PT_NUM}
									className="no-bullets"
								>
										{nonPathActivity?.PT_NUM_OBJ?.POINT_ID + "\n"}
								</li>
							);
						}
					) || ''
				}
			</ul>
		),
		compact: true,
		wrap: true,
		reorder: true,
		center: true
	},
	{
		name: 'Status',
		selector: row => row?.STATUS,
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
		center: true
	},
	{
		name: 'Updated By',
		selector: row => row.USERSTAMP,
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true
	},
	{
		name: 'Updated On',
		selector: row => formatDateTime(row.DATESTAMP),
		sortable: true,
		compact: true,
		wrap: false,
		reorder: true
	}
];

export default columns;