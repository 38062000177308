//Image imports

import Clear from '../../../assets/images/clear.png';
import Cancel from '../../../assets/images/cancel.png';


//Import Modules:
import Table from './Table';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { physical_trade_deals_actions } from '../../../store/physical-trade-deals-slice';

import './MtsContractModalStyling.css';

// Modal styling
const customStyles = {
	overlay: {
		backgroundColor: 'rgb(0,0,0,.60'
	},
};

function MtsContractModal() {

	// Redux:
	const openMtsContractModal = useSelector(state => state.physical_trade_deals_slice.openMtsContractModal);
	const dispatch = useDispatch();

	// Modal State:
	const closeMtsContractModal = () => {
		dispatch(physical_trade_deals_actions.setOpenMtsContractModal(false));
	};

	return (

		<>
			<Modal
				isOpen={openMtsContractModal}
				onRequestClose={closeMtsContractModal}
				className="mts-contract-modal container-fluid bg-white text-black"
				style={customStyles}
			>
				<div id="SelectMtsContractModal" tabIndex='-1'>
					<div className='modal-dialog '>
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title mb-2">Select a Mkt Tr Svc Contract</h4>
								<button
									type="button"
									className="btn-close fs-6"
									onClick={closeMtsContractModal}
								>
								</button>
							</div>
							<div className="modal-body">

								{/* // BUTTONS */}
								<div className="row text-start bg-light-blue rounded-top m-0 p-2">
									<div className="col-lg-3 col-md-12 col-md-12 col-sm-12">
										<button
											type="button"
											className="btn btn-primary m-1"
											title="New Deal"
											data-bs-dismiss="modal"
											onClick={closeMtsContractModal}
										>
											<img src={Clear} alt="Clear" /> Clear
										</button>
										<button
											type="button"
											className="btn btn-primary m-1"
											title="Cancel"
											data-bs-dismiss="modal"
											onClick={closeMtsContractModal}
										>
											<img src={Cancel} alt="AddImage" /> Cancel
										</button>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-12">
										{/* Search Bar */}
										{/* <div className="col-lg-2 col-md-2 col-sm-12 text-start"> */}
										{/* <label className='form-label'>Search</label> */}
										<input className="form-control form-control-sm m-1" type="text" placeholder="Search..." aria-label="Search"
										// onChange={searchbarChangeHandler}
										/>
										{/* </div> */}

									</div>
								</div>
								{/* SELECT A DEAL TABLE */}
								<div className='row text-start'>
									<Table />
								</div>
							</div>
						</div>
					</div>
				</div >
			</Modal>
		</>
	)
}

export default MtsContractModal