import ReactHookFormSelector from './ReactHookFormSelector';

const CodeSelector = ({
	form,
	propertyName,
	valueType,
	url,
	disabled,
	className,
	shouldLog,
	required
}) => {

	return (
		<ReactHookFormSelector
			disabled={disabled}
			className={className || 'form-select form-select-sm'}
			form={form}
			valueType={valueType || 'string'}
			url={url}
			propertyName={propertyName}
			optionKey='CDE'
			optionText='CDE_TXT'
			shouldLog={shouldLog}
			required={required}
		/>
	);
};

export default CodeSelector;