import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPackages, setPackageAndFetchPkgPaths, non_path_noms_actions } from '../../../../../store/non-path-noms-slice';
import DataTable from 'react-data-table-component';
import CustomStyles from './Table-Styles';
import Spinner from '../../../../Spinner';
import { formatDate, formatDateTime } from '../../../../../utils/DateUtils';
import NonPathNomsTableButtons from './NonPathNomsTableButtons';

const NonPathNomsTable = ({ customColumns }) => {

	const NonPathNoms = useSelector(state => state.non_path_noms_slice.NonPathNoms);
	const NonPathNomsFetchLoading = useSelector(state => state.non_path_noms_slice.NonPathNomsFetchLoading);
	const NonPathNomsFilters = useSelector(state => state.non_path_noms_slice.NonPathNomsFilters);
	const { addNomModalState } = useSelector(state => state.non_path_noms_slice.modalStates);
	const dispatch = useDispatch();

	useEffect(() => { dispatch(fetchPackages(NonPathNomsFilters)); }, [NonPathNomsFilters, dispatch]);

	const columns = [
		{
			name: 'View Details',
			button: true,
			cell: row => <NonPathNomsTableButtons row={row} />
		},
		{
			name: 'Pkg #',
			selector: row => row.PACKAGE_NUM,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Cycle',
			selector: row => row.CYCLE,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Pipeline',
			selector: row => row.CNTR_NUM?.CNTR_PARTY2?.SHORTNAME,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Activity',
			selector: row => row.ACTIV_NUM?.ACTIV_ALT_NUM,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Trans Contract',
			selector: row => row.CNTR_NUM?.CNTR_ALT_NUM1,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Rec Qty',
			selector: row => row.RCV_QTY || 0,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Del Qty',
			selector: row => row.DLV_QTY || 0,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Start Date',
			selector: row => formatDate(row.STARTDATE),
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'End Date',
			selector: row => formatDate(row.ENDDATE),
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Trans Type',
			selector: row => row.TT,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Updated By',
			selector: row => row.USERSTAMP,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Updated On',
			selector: row => formatDateTime(row.DATESTAMP),
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		}
	];

	const handleRowClicked = async (row) => {
		// Open add nom modal.
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'addNomModalState',
			newModalState: { ...addNomModalState, open: true }
		}));

		// Fetch package paths for selected package, this also sets the selected package.
		await dispatch(setPackageAndFetchPkgPaths(row));

		// Allow form to reset.
		await dispatch(non_path_noms_actions.setShouldResetForm(true));
	};

	return (
		<DataTable
			progressPending={NonPathNomsFetchLoading}
			progressComponent={<Spinner status='loading' />}
			className='pb-1'
			fixedHeader={true}
			fixedHeaderScrollHeight="700px"
			customStyles={CustomStyles}
			columns={customColumns || columns}
			data={NonPathNoms}
			striped={true}
			highlightOnHover={true}
			responsive={true}
			dense={true}
			onRowClicked={handleRowClicked}
		/>
	);
};

export default NonPathNomsTable;