import addNew from '../../../assets/images/add.gif'
import TradeDetailUi from './TradeDetailUi';



function MarketPoolImabalanceUi() {
    return (
        <>

            <div className="modal modal-xl" id="MarketPoolImabalanceUi" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true" aria-labelledby="MarketPoolImabalanceUi Report">
                <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content text-center">
                        <div className="modal-header text-center bg-primary text-white justify-content-center">
                            <h1 className="fs-5" id="MarketPoolImabalanceUi">Market Pool Imbalance UI</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" data-bs-target="#MarketPoolImabalanceUi" aria-label="Close"></button>
                        </div>
                        <div className='modal-body'>
                            <div className="container-fluid">
                                <div className="row text-start mb-2">

                                    <div className="col-lg-4 col-md-4 col-sm-12 text-start">
                                        <label>Requestor: </label>
                                        <input className="form-control form-control-sm" type="text" placeholder="Direct Energy Business Marketing" aria-label="Requestor" />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 text-start">
                                        <label>Pool ID: </label>
                                        <input className="form-control form-control-sm" type="text" placeholder="Show all pool IDs" aria-label="Show All Pool IDs" />
                                    </div>
                                </div>

                                {/* Imbalance Trade Section*/}
                                <div className="row text-start mb-2 bg-light-blue rounded p-1">
                                    <h6>Imbalance Trade</h6>
                                    <div className="col-lg-4 col-md-4 col-sm-12 text-start">
                                        <label>Trade Date: </label>
                                        <input className="form-control form-control-sm" type="month" value={`${new Date().getFullYear()}-${(new Date().getMonth() > 8 ? "" : "0") + (new Date().getMonth() + 1)}`} onChange={() => { }}
                                            style={{ maxWidth: '250px' }} />
                                        <label>Trade Month: </label>
                                        <input className="form-control form-control-sm" type="text" placeholder="Jan-2022" aria-label="Imbalance" />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 text-start">
                                        <label>Imbalance Trade Period: </label>
                                        <input className="form-control form-control-sm mb-1" type="text" aria-label="Total Traded:" />
                                        <label>By 5:00 PM</label>
                                        <input className="form-control form-control-sm" type="text" aria-label="Total Traded:" />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 text-start">
                                        <label>Trade ID: </label>
                                        <input className="form-control form-control-sm" type="text" placeholder="0000000057" aria-label="Total Traded:" />
                                    </div>
                                </div>


                                {/* Totals Section*/}
                                <div className="row text-start mb-2 bg-light-blue rounded p-1">
                                    <h6>Totals</h6>
                                    <div className="col-lg-4 col-md-4 col-sm-12 text-start">
                                        <label>Imbalance: </label>
                                        <input className="form-control form-control-sm mb-2" type="text" placeholder="-115000" aria-label="Imbalance" />
                                        <input className="form-control form-control-sm" type="text" placeholder="dekat" aria-label="Imbalance" />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 text-start">
                                        <label>Total Traded: </label>
                                        <input className="form-control form-control-sm mb-2" type="text" placeholder="500000" aria-label="Total Traded:" />
                                        <input className="form-control form-control-sm" type="text" placeholder="dekat" aria-label="Total Traded:" />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 text-start">
                                        <label>Net Imbalance: </label>
                                        <input className="form-control form-control-sm mb-2" type="text" placeholder="-65300" aria-label="Total Traded:" />
                                        <input className="form-control form-control-sm" type="text" placeholder="dekat" aria-label="Total Traded:" />
                                    </div>
                                </div>

                                {/* Imbalance Trade Details Section*/}
                                <div className="row text-start bg-light-blue rounded">
                                    <div className="col-sm-12 col-md-12 col-lg-12 p-2">
                                        <h6 className='text-start'>Imbalance Trade Details</h6>
                                        <button className='btn btn-sm btn-primary m-1' data-bs-toggle="modal" data-bs-target="#TradeDetailUi"><img src={addNew} alt='Add New Customer' /> Add</button>
                                    </div>
                                </div>

                                {/* Outgoing Trade Deals Table */}
                                <div className='table-responsive'>
                                    <div className="d-flex"><p>Outgoing Trade Deals</p></div>

                                    <table className="bg-white table table-sm table-hover table-striped-columns table-bordered border-primary rounded pb-1"
                                    >
                                        <thead>
                                            <tr>
                                                <th>View</th>
                                                <th>Trade Partners</th>
                                                <th>Pool ID</th>
                                                <th>Trade Contact</th>
                                                <th>Trade Amount</th>
                                                <th>UNIT</th>
                                                <th>Trade ID</th>
                                                <th>Confirmed</th>
                                                <th>User ID</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                {/* Incoming Trade Deals Table */}
                                <div className='table-responsive'>
                                    <div className="d-flex"><p>Incoming Trade Deals</p></div>

                                    <table className="bg-white table table-sm table-hover table-striped-columns table-bordered border-primary rounded pb-1"
                                    >
                                        <thead>
                                            <tr>
                                                <th>View</th>
                                                <th>Trade Partners</th>
                                                <th>Pool ID</th>
                                                <th>Trade Contact</th>
                                                <th>Trade Amount</th>
                                                <th>UNIT</th>
                                                <th>Trade ID</th>
                                                <th>Confirmed</th>
                                                <th>User ID</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <>
                <TradeDetailUi />
            </>
        </>
    )
}

export default MarketPoolImabalanceUi