import DataTable from "react-data-table-component";
import CustomStyles from "./Styles/AgreementBalanceTable.js";

function AgreementBalanceTable({gasAllocationData}) {    
    const columns = [{
        name: "Location",
        selector: row => row?.location,
        compact: true,
        wrap: true,
        width: "300px",
        cell: row => row?.isTotalRow
            ? <div style={{ fontWeight: 'bold', align: 'right'}}>{row.location}</div>
            : row.location
    },
    {
        name: "Alloc Dth",
        selector: row => row?.alloc.toLocaleString(),
        compact: true,
        wrap: true,
        width: "100px",
        cell: row => row?.isTotalRow
            ? <div style={{ fontWeight: 'bold' }}>{Math.trunc(row.alloc).toLocaleString()}</div>
            : row.alloc.toLocaleString()
    },
    {
        name: "Comments",
        selector: row => row?.comments,
        compact: true,
        wrap: true,
        width: "219px",
        cell: row => row?.isTotalRow
            ? <div style={{ fontWeight: 'bold' }}>{row.comments}</div>
            : row.comments
    }

    ]

    return (
        <div className="container-fluid">
            <DataTable
                columns={columns}
                data={gasAllocationData}
                dense={true}
                striped={false}
                // fixedHeaderScrollHeight="450px"
                customStyles={CustomStyles}
                highlightOnHover={true}
                className="pb-1 pt-1"
            />
        </div>
    )
}

export default AgreementBalanceTable;