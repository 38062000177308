
function BusinessInfo({ month, contract, ba, bu }) {
    const curTime = new Date().toLocaleString("en-us")
    const accountMonth = month.toLocaleDateString('en-GB', { month: 'numeric', year: 'numeric' })

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        Run Date/Time: {curTime}
                    </div>
                </div>
                <div className="row compactHeight">
                    <div className="col">
                        <p className="text-center bold">
                            Agreement Balancing Statement
                        </p>
                    </div>
                </div>
                <div className="row compactHeight">
                    <div className="col">
                        <p className="text-center bold">
                            {bu?.NAME} {/* &lt;Business Unit&gt; */}
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        TSP: {bu?.NAME}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        Account Month: {accountMonth}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        Contract No: {contract?.CNTR_ALT_NUM1}
                    </div>
                </div>
                <div className="row relaxHeight">
                    <div className="col">
                        Business Party: All
                    </div>
                </div>

                <div className="row bold">
                    <div className="col">
                        Contract No: {contract?.CNTR_ALT_NUM1}
                    </div>
                </div>
                <div className="row bold">
                    <div className="col">
                        Shipper: {ba?.ACCT_ID} - {ba?.NAME}
                    </div>
                </div>
                <div className="row bold">
                    <div className="col">
                        Contact:
                    </div>
                </div>
                <div className="row bold">
                    <div className="col">
                        Phone:
                    </div>
                </div>
                <div className="row bold relaxHeight">
                    <div className="col">
                        Fax:
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessInfo;