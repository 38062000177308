import React from 'react';
import excel from '../../../../assets/images/excel.gif';
import * as XLSX from 'xlsx';

function transformData(data) {
    return data.map(row => ({
        Month: row?.DELIVERY_DATE ? new Date(row.DELIVERY_DATE).toLocaleString('default', { month: 'long' }) + ', ' + new Date(row.DELIVERY_DATE).getFullYear() : '',
        Price: row?.PRICE ? parseFloat(row.PRICE.toFixed(3)) : undefined,
        Basis: row?.BASIS ? parseFloat(row.BASIS.toFixed(4)) : 0,
        'Index': calculate(row),
        'Updated By': row?.USERSTAMP,
        'Updated On': row?.DATESTAMP ? new Date(row.DATESTAMP).toLocaleString() : '',

    }));
}

function calculate(row) {
    if (row?.PRICE) {
        const price = parseFloat(row.PRICE);
        if (row?.BASIS) {
            const basis = parseFloat(row.BASIS);
            const result = price + basis;
            return parseFloat(result.toFixed(4));
        }
        return parseFloat(price.toFixed(4));
    }
}

function createExcelFile(data) {
    const truncatedData = data.map(row =>
        Object.fromEntries(
            Object.entries(row).map(([key, value]) => [key, value && value.toString().substring(0, 32767)])
        )
    );

    const worksheet = XLSX.utils.json_to_sheet(truncatedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
}

function downloadExcelFile(data) {
    const transformedData = transformData(data);
    const link = document.createElement('a');
    const blob = createExcelFile(transformedData);
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = 'Forward_Pricing_Report.xlsx';
    link.click();
    setTimeout(() => {
        URL.revokeObjectURL(url);
    }, 100);
}

const Export = ({ onExport }) => (
    <button className="btn btn-md btn-primary me-2 mb-1" onClick={() => onExport()}>
        <img src={excel} alt="Export Excel Report" /> Export
    </button>
);

const ExportExcel = ({ data }) => {
    const actionsMemo = React.useMemo(() => <Export onExport={() => downloadExcelFile(data)} />, [data]);

    return actionsMemo;
};

export default ExportExcel;
