import { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { physical_trade_deals_actions, setBidPackageAndFetchPkgPaths } from '../../store/physical-trade-deals-slice';
import { toShortDate } from '../../utils/DateUtils';
import CustomStyles from './Table-Styles';
import DailySumUiModal from './DailySumUiModal';
import Spinner from '../Spinner';
import { fetchBidPackages } from '../../store/physical-trade-deals-thunks';
import ExportExcel from '../CustomComponents/ExportExcel';


function Table({ filters, onDealMakersChange }) {



	const columns = [
		{
			name: 'View Details',
			button: true,
			cell: (row) => (
				<button onClick={() => openUiModal(row)}
					className='btn btn-md btn-primary'>
					Daily
				</button>
			),
			width: "85px",
			compact: true,
		},
		{
			name: 'BidType',
			selector: row => row?.BIDTYPE === 'B' ? 'Purchase' : 'Sale',
			sortable: true,
			reorder: true,
			compact: true,
			wrap: true,
			width: "75px",
		},
		{
			name: 'Supplier',
			selector: row => row?.BIDTYPE === 'B' ? row?.SUPPLIER_BAID_OBJ?.SHORTNAME : row?.BUYERID_OBJ?.SHORTNAME,
			sortable: true,
			reorder: true,
			compact: true,
			wrap: true,
			width: "95px",
		},
		{
			name: 'Contract',
			selector: row => row?.CNTR_NUM?.CNTR_ALT_NUM1,
			sortable: true,
			compact: true,
			reorder: true,
			wrap: true,
			width: "85px",
		},
		{
			name: 'Pipeline',
			selector: row => row?.START_PT_NUM_PipeLineShortname,
			sortable: true,
			compact: true,
			reorder: true,
			wrap: true,
			width: "68px",
		},
		{
			name: 'PP',
			// name: 'Purchase Point',
			selector: row => row?.START_PT_NUM?.POINT_ID,
			sortable: true,
			compact: true,
			reorder: true,
			wrap: true,
			width: "95px",
		},
		{
			name: 'TradeDate',
			selector: row => toShortDate(row?.BIDDATE),
			sortable: true,
			compact: true,
			reorder: true,
			wrap: true,
			width: "85px",
		},
		{
			name: 'StartDate',
			selector: row => toShortDate(row?.STARTDATE),
			sortable: true,
			compact: true,
			reorder: true,
			wrap: true,
			width: "80px",
		},
		{
			name: 'EndDate',
			selector: row => toShortDate(row?.ENDDATE),
			sortable: true,
			compact: true,
			reorder: true,
			wrap: true,
			width: "85px",
		},
		{
			name: 'DailyVol',
			selector: row => row?.QUANTITY,
			sortable: true,
			compact: true,
			reorder: true,
			wrap: true,
			width: "70px",
		},
		{
			name: 'DealQty',
			selector: row => row?.DAILY_QUANTITY,
			sortable: true,
			compact: true,
			reorder: true,
			wrap: true,
			width: "70px",
		},
		{
			name: 'ActQty',
			selector: row => row?.DAILY_ACT_QUANTITY,
			sortable: true,
			compact: true,
			reorder: true,
			wrap: true,
			width: "70px",
		},
		{
			name: 'SchQty',
			selector: row => row?.Total_SCH_RCV_VOL,
			sortable: true,
			compact: true,
			reorder: true,
			wrap: true,
			width: "70px",
		},
		{
			name: 'AvailSchQty',
			selector: row => row?.Total_SCH_AVAIL_RCV_VOL,
			sortable: true,
			compact: true,
			reorder: true,
			wrap: true,
			width: "92px",
		},
		{
			name: 'Price',
			selector: row => row?.FixedPriceString,
			sortable: true,
			compact: true,
			reorder: true,
			wrap: true,
			width: "60px",
		},
		{
			name: 'Adj/Factor',
			selector: row => row?.PriceOffSet || (0).toLocaleString('en-US', { minimumFractionDigits: 4 }),
			sortable: true,
			compact: true,
			reorder: true,
			wrap: true,
			width: "85px",
		},
		{
			name: '$Amount',
			selector: row => row?.DAILY_AMOUNT.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
			sortable: true,
			compact: true,
			reorder: true,
			wrap: true,
			width: "90px",
		},
		{
			name: 'Status',
			selector: row => statusCodes(row),
			sortable: true,
			compact: true,
			reorder: true,
			wrap: true,
			width: "85px",
		},
		{
			name: 'Deal Maker',
			selector: row => row?.COMP_CONTACT_OBJ?.FIRST_NAME,
			sortable: true,
			compact: true,
			reorder: true,
			wrap: true,
			width: "100px",
		},
		{
			name: 'UpdatedBy',
			selector: row => row?.USERSTAMP,
			sortable: true,
			compact: true,
			reorder: true,
			wrap: true,
			width: "90px",
		},
		{
			name: 'UpdatedOn',
			selector: row => new Date(row?.DATESTAMP).toLocaleString('en-US', {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric'
			}),
			sortable: true,
			compact: true,
			reorder: true,
			wrap: true,
			width: "145px",
		}
	];


	// Redux
	const dispatch = useDispatch();
	const bidPackages = useSelector(state => state.physical_trade_deals_slice.bidPackages);
	const loading = useSelector(state => state.physical_trade_deals_slice.loading.state);
	const totalBidPkgRows = useSelector(state => state.physical_trade_deals_slice.totalBidPkgRows);
	const selectedSupplier = useSelector(state => state.business_selector_slice.selectedSupplier);
	const businessUnit = useSelector(state => state.business_selector_slice.businessUnit);
	const isAdmin = useSelector(state => state.auth_slice.isAdmin);

	// State
	const [pageNumber, setPageNumber] = useState(1);
	const pageSize = 8;
	const [filteredData, setFilteredData] = useState([]);

	const handlePageChange = (newPageNumber) => {
		setPageNumber(newPageNumber);
	};

	useEffect(() => {
		dispatch(fetchBidPackages({
			pageNumber,
			pageSize,
			selectedSupplier,
			filters,
			businessUnit,
			isAdmin
		}));
	},
		[
			pageNumber,
			pageSize,
			dispatch,
			selectedSupplier,
			filters,
			businessUnit,
			isAdmin
		]);

	const rowClick = (row) => {
		dispatch(physical_trade_deals_actions.setOpenModal(true));
		dispatch(setBidPackageAndFetchPkgPaths(row));
	};
	const openUiModal = (row) => {
		dispatch(physical_trade_deals_actions.setOpenUiModal(true));
		dispatch(setBidPackageAndFetchPkgPaths(row));
	};

	useEffect(() => {
		dispatch(fetchBidPackages({
			dealMaker: filters.dealMaker,
		}));
	}, [filters, dispatch]);


	const statusCodes = (row) => {
		const code = row?.STATUS
		switch (code) {
			case 'AP':
				return 'Approved';
			case 'CF':
				return 'Confirm';
			case 'AC':
				return 'Accepted';
			case 'PN':
				return 'Pending';
			case 'AM':
				return 'Amend';
			case 'RC':
				return 'Reconcile';
			case 'DL':
				return 'Deleted';
			case 'RJ':
				return 'Rejected';
			case 'SC':
				return 'Scheduled';
			default:
				break;
		};
	};


	useEffect(() => {
		const dealMakersSet = new Set(bidPackages.map(pkg => pkg.COMP_CONTACT_OBJ?.FIRST_NAME));
		const uniqueDealMakers = Array.from(dealMakersSet).filter(Boolean); // Remove falsy values

		if (typeof onDealMakersChange === 'function') {
			onDealMakersChange(uniqueDealMakers);
		}
	}, [bidPackages, onDealMakersChange]);


	useEffect(() => {
		if (!filters.dealMaker) {
			setFilteredData(bidPackages);
		} else {
			const filtered = bidPackages.filter(pkg => pkg.COMP_CONTACT_OBJ?.FIRST_NAME === filters.dealMaker);
			setFilteredData(filtered);
		}
	}, [bidPackages, filters.dealMaker]);


	console.log('filteredData on Physical Deals:', filteredData);

	return (
		<>
			<ExportExcel
				columns={columns}
				fileName='Physical_Deals_Report.xlsx'
				data={filteredData}
				className='btn btn-primary mb-1'
			/>
			<DataTable
				progressPending={loading}
				progressComponent={<Spinner status='loading' />}
				className='pb-1'
				fixedHeader={true}
				fixedHeaderScrollHeight="700px"
				customStyles={CustomStyles}
				columns={columns}
				data={filteredData}
				striped={true}
				highlightOnHover={true}
				onRowClicked={rowClick}
				pagination
				paginationServer
				paginationTotalRows={totalBidPkgRows}
				paginationPerPage={pageSize}
				paginationComponentOptions={{
					noRowsPerPage: true
				}}
				onChangePage={handlePageChange}
				responsive={true}
				dense={true}
			/>
			<DailySumUiModal />
		</>
	);
};

export default Table;