import { useEffect } from 'react';
import { downloadExcel } from 'react-export-table-to-excel';

// redux
import { contact_actions } from '../../store/contact-slice';
import {
	fetchContactRoleCodes,
	fetchEmailGroupCodes,
	fetchCountries,
	fetchStates,
	fetchContacts,
	fetchPasswordExpirationDays,
} from '../../store/contact-thunks';
import { useSelector, useDispatch } from 'react-redux';

// import images
import addNew from '../../assets/images/add.gif';
import excel from '../../assets/images/excel.gif';

// import custom styling for Tables:
import './supplier-table-style.css';

import EditContact from './EditContact';

// import custom components
import Spinner from '../CustomComponents/Spinner';

import Restricted from '../Restricted';


function SupplierContactList() {

	// redux
	const selectedSupplier = useSelector(state => state.business_selector_slice.selectedSupplier);
	const expirationDays = useSelector(state => state.contact_slice.codedExpirationDays);
	const loading = useSelector(state => state.contact_slice.loading);
	const contacts = useSelector(state => state.contact_slice.contacts);
	const dispatch = useDispatch();

	/**
	 * Fetch Contact roles.
	 */
	useEffect(() => {

		dispatch(fetchContactRoleCodes());
		dispatch(fetchEmailGroupCodes());
		dispatch(fetchCountries());
		dispatch(fetchContacts());

	}, [dispatch]);

	/**
	 * Perform updates when selected supplier changes.
	 */
	useEffect(() => {

		const ba_id = selectedSupplier.BA_ID;

		if (ba_id) {

			dispatch(contact_actions.setBA(selectedSupplier));

		}

	},
		[
			selectedSupplier,
			dispatch
		]);

	useEffect(() => {
		dispatch(contact_actions.resetContact({ selectedSupplier, expirationDays }));
	}, [expirationDays, dispatch, selectedSupplier])


	//Export to Excel functionality:
	const excelHeader = ["Contact", "Last Name", "First Name", "Status", "O-Phone", "Title", "Fax #", "Email", "Street Address", "City", "State", "Zip"];
	const excelBody = contacts.map(contact => [
		contact.CONTACT_NUM,
		contact.NAME,
		contact.FIRST_NAME,
		contact.STATUS,
		contact.PHONE_NUM,
		contact.TITLE,
		contact.FAX_NUM,
		contact.EMAIL_ID,
		contact.ADDR_LINE1,
		contact.CITY,
		contact.STATE,
		contact.POSTAL_CDE
	]);

	const handleDownloadExcel = () => {
		downloadExcel({
			fileName: "Supplier-Marketer-Contact-List-Report",
			sheet: "Supplier-Marketer-Contact-List-Table",
			tablePayload: {
				header: excelHeader,
				body: excelBody,
			},
		});
	};


	const createNewContact = () => {
		const handleAsync = async () => {
			await dispatch(fetchPasswordExpirationDays());
		}
		handleAsync();

		dispatch(contact_actions.resetContact({ selectedSupplier, expirationDays }));
		dispatch(contact_actions.showEditContactModal(true));
	};

	const setSelectedContact = (selectedContact) => {
		dispatch(contact_actions.setSelectedContact(selectedContact));
		dispatch(contact_actions.showEditContactModal(true));
		if (selectedContact.ADDR_NUM &&
			selectedContact.ADDR_NUM.COUNTRY_OBJ &&
			selectedContact.ADDR_NUM.COUNTRY_OBJ.COUNTRY_ABBR) {
			dispatch(fetchStates(selectedContact.ADDR_NUM.COUNTRY_OBJ.COUNTRY_ABBR));
		}
	};

	return (
		<div className="container-fluid">
			<div className="text-white">
				<div className='card mt-1'>
					<div className='card-header text-center bg-primary'><h4>Supplier/Marketer Contact List Page</h4>
					</div>
					<div className="card-body">
						<div className="row">

							{/* // Buttons */}
							<div className="col-sm-12 col-md-12 col-lg-12 p-1">
								<Restricted
									restrictedTo={['WRITE-ACCESS']}
								>
									<button
										className='btn btn-sm btn-primary mb-2 me-2'
										onClick={createNewContact}
									>
										<img src={addNew} alt='Add New Contact' /> Add Contact
									</button>

									<button
										className='btn btn-sm btn-primary mb-2 me-2'
										onClick={handleDownloadExcel}
									>
										<img src={excel} alt='Export Excel Report' />
										Excel Report
									</button>
								</Restricted>
							</div>

							<p className='caption'>Invoice Details</p>
						</div>
						{
							// Contacts Table
							loading
								?
								<div className='text-dark'>
									<Spinner status="loading" />
								</div>
								:
								(
									contacts?.length > 0
										?
										<div className='table-responsive supplierTableContainer'>

											<table
												// ref={supplierTableRef}
												className="bg-white table table-sm table-hover table-striped-columns table-bordered border-primary rounded pb-1"
											>
												<thead>
													<tr className='bg-white'>
														<th>Contact Details</th>
														<th>Contact</th>
														<th>Last Name</th>
														<th>First Name</th>
														<th>Status</th>
														<th>O-Phone</th>
														<th>Title</th>
														<th>Fax #</th>
														<th>Email</th>
														<th>Street Address</th>
														<th>City</th>
														<th>State</th>
														<th>Zip</th>
													</tr>
												</thead>
												<tbody>
													{
														contacts.map(
															contact => {
																return (
																	<tr key={contact.CONTACT_NUM}>
																		<td>
																			<button
																				className="btn btn-primary btn-small"
																				onClick={() => { setSelectedContact(contact) }}
																			>
																				View
																			</button>
																		</td>
																		<td>{contact.CONTACT_NUM}</td>
																		<td>{contact.NAME}</td>
																		<td>{contact.FIRST_NAME}</td>
																		<td>{contact.STATUS}</td>
																		<td>{contact.PHONE_NUM}</td>
																		<td>{contact.TITLE}</td>
																		<td>{contact.FAX_NUM}</td>
																		<td>{contact.EMAIL_ID}</td>
																		<td>{contact.ADDR_LINE1}</td>
																		<td>{contact.CITY}</td>
																		<td>{contact.STATE}</td>
																		<td>{contact.POSTAL_CDE}</td>
																	</tr>
																)
															}
														)
													}
												</tbody>
											</table>
										</div>
										:
										<p className="text-center">No Records Found.</p>
								)
						}
					</div>
				</div>
			</div>

			{/* Modals */}
			<EditContact />
		</div>
	);
};

export default SupplierContactList;