import DataTable from 'react-data-table-component';
import CustomStyles from './Table-Styles';
import Spinner from '../../../../Spinner';


const EstimatedFlowTable = ({ tableData }) => {

    //Handles case if rows diaply 0s it will not display the table at all.
    const filteredTableData = tableData.filter(row => !(row.LastMonthly === 0 && row.MDQ === 0 && row.Retention === 0 && row.MQD === 0));

    const columns = [

        {
            name: 'Pod ID',
            selector: row => row?.PodId,
            cell: row => row.PodId && row.PodId.trim() !== "" ? row.PodId : null,
            sortable: true,
            compact: true,
            wrap: true,
            reorder: true,
            width: '100px'
        },
        {
            name: 'Pod Name',
            selector: row => row?.PtName,
            cell: row => row.PtName && row.PtName.trim() !== "" ? row.PtName : null,
            sortable: true,
            compact: true,
            wrap: true,
            reorder: true
        },
        {
            name: 'Last Monthly Act',
            selector: row => row?.LastMonthly,
            sortable: true,
            compact: true,
            wrap: true,
            reorder: true
        },
        {
            name: 'Eff MDQ, Daily',
            selector: row => row?.MDQ,
            sortable: true,
            compact: true,
            wrap: true,
            reorder: true
        },
        {
            name: 'Gathering Retention (Daily)',
            selector: row => row?.Retention,
            sortable: true,
            compact: true,
            wrap: true,
            reorder: true
        },
        {
            name: 'Market Quantity, Daily',
            selector: row => row?.FinalMqd,
            sortable: true,
            compact: true,
            wrap: true,
            reorder: true
        },

    ];


    return (
        <DataTable
            progressComponent={< Spinner status='loading' />}
            className='pb-1'
            fixedHeader={true}
            fixedHeaderScrollHeight="700px"
            customStyles={CustomStyles}
            columns={columns}
            data={filteredTableData}
            striped={true}
            highlightOnHover={true}
            responsive={true}
            dense={true}
        />
    );
};

export default EstimatedFlowTable;