// react
import { useState } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { auth_actions } from '../../../store/auth-slice';
import { notification_actions } from '../../../store/notification-slice';

// react modal
import Modal from 'react-modal';

// images
import logo1 from '../../../assets/images/gastar-logo.png'

//Stylesheet:
import '../modal-style-2.css'

//Custom Spinner
import Spinner2 from '../../CustomComponents/Spinner2'

Modal.setAppElement('#root');

const ResetPasswordModal = () => {

	// React state
	const [changePasswordState, setChangePasswordState] = useState({
		NewPassword: '',
		ConfirmNewPassword: ''
	});

	const [passwordVisible, setPasswordVisible] = useState(false);

	// Redux
	const showResetPassword = useSelector(state => state.auth_slice.showResetPassword);
	// Spinner:
	const setShowSpinner = useSelector(state => state.auth_slice.showSpinner)
	//Dispatch:
	const dispatch = useDispatch();

	// Handlers
	const resetPassword = async (event) => {

		event.preventDefault();

		const requestResetPassword = async () => {

			const response = await fetch('Auth/ResetPassword', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(changePasswordState)
			});

			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

		};

		try {

			console.log('Changing password...');
			dispatch(auth_actions.setShowSpinner(true));
			await requestResetPassword();
			console.log('Password changed successfully.');
			dispatch(auth_actions.showResetPasswordModal(false));
			dispatch(notification_actions.showModal({
				message: 'Password has been reset!',
				header: 'Password has been reset!'
			}));
			dispatch(auth_actions.setShowSpinner(false));
			setPasswordVisible(false);
			clearPassword();

		}
		catch (error) {
			dispatch(notification_actions.showModal({
				message: error.message,
				header: 'Validation Message'
			}))
			dispatch(auth_actions.setShowSpinner(false));
		}

	};

	const changeState = (event) => {

		const newValue = event.target.value;
		const property = event.target.getAttribute('property');
		const newChangePasswordState = { ...changePasswordState };

		newChangePasswordState[property] = newValue;

		setChangePasswordState(newChangePasswordState);

	};

	const togglePassword = () => {
		setPasswordVisible(!passwordVisible);
	};

	const closeModal = () => {
		dispatch(auth_actions.showResetPasswordModal(false));
		setPasswordVisible(false);
		clearPassword();
	};

	const clearPassword = () => {
		setChangePasswordState(prevState => ({
			...prevState,
			NewPassword: '',
			ConfirmNewPassword: ''
		}));
	};

	// This code can be used to open the modal for testing and/or Styling
	// const openModal = () => {
	// 	dispatch(auth_actions.setShowResetPassword(true));
	// }

	const customStyles = {
		overlay: {
			backgroundColor: 'rgb(0,0,0,.60'
		},
	};

	return (
		<div>
			<Modal
				isOpen={showResetPassword}
				contentLabel='change-password'
				style={customStyles}
				className="Modal-2 bg-white"
				// overlayClassName="Overlay"
				data-bs='reset-password'
			>
				<div className="modal-header text-center m-2">
					<h1 className="fs-5 text-center modal-title" id="change-password">GASTAR Change Password</h1>
					<button onClick={closeModal} type="button" className="btn-close text-end" aria-label="Close"></button>
				</div>
				<form onSubmit={resetPassword}>
					<div className="modal-body justify-content-center text-center fs-5">
						<img src={logo1} alt='Gastar Login' className='img-fluid mb-4' style={{ maxWidth: '200px' }} />
						<br />
						<label htmlFor='text'>Your new password</label>
						<input
							className="form-control mb-3"
							type={passwordVisible ? "text" : "password"}
							id='newpass'
							required
							value={changePasswordState.NewPassword}
							onChange={changeState}
							property={'NewPassword'}
						/>
						<label htmlFor='text'>Confirm new password</label>
						<input
							className="form-control mb-3"
							type={passwordVisible ? "text" : "password"}
							id='confirmpass'
							required
							value={changePasswordState.ConfirmNewPassword}
							onChange={changeState}
							property={'ConfirmNewPassword'}
						/>
					</div>
					<div className="modal-footer d-flex">
						<label className="me-auto">
							<input className="form-check-input ms-1 me-1" type="checkbox" onChange={togglePassword} />
							Show Password
						</label>
						{
							setShowSpinner ?
								<Spinner2 />
								:

								<button
									className='btn btn-lg btn-primary m-2 fs-6'>
									Reset Password
								</button>
						}
					</div>
				</form>
			</Modal>
		</div >
	);

};

export default ResetPasswordModal;