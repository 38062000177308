import React from 'react';
import DataTable from 'react-data-table-component';

const columns = [
    {
        name: 'Month',
        selector: row => {
            if (row?.DELIVERY_DATE) {
                const date = new Date(row.DELIVERY_DATE);
                const month = date.toLocaleString('default', { month: 'long' });
                const year = date.getFullYear();
                return `${month}, ${year}`;
            }
            return '';
        },
        sortable: true,
        wrap: true,
        compact: true,
        width: '15%'

    },
    {
        name: 'Price',
        // selector: row => row?.PRICE,
        selector: row => row?.PRICE ? parseFloat(row.PRICE.toFixed(3)) : undefined,

        sortable: true,
        wrap: true,
        compact: true,
        width: '10%'

    },
    {
        name: 'Basis',
        selector: row => row?.BASIS ? parseFloat(row.BASIS.toFixed(4)) : 0,
        sortable: true,
        wrap: true,
        compact: true,
        width: '10%',
    },
    {
        name: 'Index',
        selector: row => {
            // Ensure Price exists and is a number
            if (row?.PRICE) {
                const price = parseFloat(row.PRICE);
                // Check if BASIS exists and is a number
                if (row?.BASIS) {
                    const basis = parseFloat(row.BASIS);
                    // Perform the calculation with BASIS
                    const result = price + basis;
                    // Return the result formatted to 4 decimal places as a number
                    return parseFloat(result.toFixed(4));
                }
                // Return PRICE formatted to 4 decimal places as a number if BASIS is not available
                return parseFloat(price.toFixed(4));
            }
            // Return 0 if Price is not available
            return 0;
        },
        sortable: true,
        wrap: true,
        compact: true,
        width: '10%',

    },
    {
        name: 'Updated By',
        selector: row => row?.USERSTAMP,
        sortable: true,
        wrap: true,
        compact: true,
        width: '12%'

    },
    {
        name: 'Updated On',
        selector: row => {
            if (row?.DATESTAMP) {
                const date = new Date(row.DATESTAMP);
                return date.toLocaleString();
            }
            return '';
        },
        sortable: true,
        wrap: true,
        compact: true,
        width: '20%'

    },

];

const NymexTable = ({ data }) => {

    // console.log("data going to table:", data);

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            highlightOnHover
            fixedHeader
            fixedHeaderScrollHeight='275px'
            striped={true}
            dense={true}
            className='rounded'
            paginationPerPage={10} // Number of rows per page
            paginationRowsPerPageOptions={[10, 15, 25, 50, 500]}

        />
    );
};

export default NymexTable;
