// react
import { useState } from 'react';

// redux
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../../store/auth-slice';

// images
import logo1 from '../../../assets/images/gastar-logo.png';

const ForgotPasswordModal = () => {
	// React state
	const [forgotPasswordState, setForgotPasswordState] = useState({
		user_id: '',
		email: ''
	});

	const dispatch = useDispatch();

	// handlers
	const changeState = (event) => {

		const newValue = event.target.value;
		const property = event.target.getAttribute('property');
		const newforgotPasswordState = { ...forgotPasswordState };

		newforgotPasswordState[property] = newValue;

		setForgotPasswordState(newforgotPasswordState);

	};

	const sendResetPasswordRequest = async (event) => {
		event.preventDefault();

		setForgotPasswordState(
			prevState => {
				return {
					...prevState,
				}
			}
		);

		await dispatch(resetPassword({
			user_id: forgotPasswordState.user_id,
			email: forgotPasswordState.email
		}));

		setForgotPasswordState(
			prevState => {
				return {
					...prevState,
				}
			}
		);
	};

	return (

		<div className="modal" id="forgot-password" aria-hidden="true" aria-labelledby="forgot-password" tabIndex="-1">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header text-center">
						<h1 className="fs-5 text-center" id="forgot-password">GASTAR Forgot Password</h1>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<form onSubmit={sendResetPasswordRequest}>
						<div className="modal-body justify-content-center text-center fs-5">
							<img src={logo1} alt='Gastar Login' className='img-fluid mb-4' style={{ maxWidth: '200px' }} />
							<br />
							<label htmlFor='ForgotPasswordModalUser'>Your User ID</label>
							<input
								className="form-control mb-3"
								type='text'
								id='ForgotPasswordModalUser'
								required
								onChange={changeState}
								value={forgotPasswordState.user_id}
								property={'user_id'}
							/>
							<label htmlFor='ForgotPasswordModalEmail'>Your Email</label>
							<input
								className="form-control mb-3"
								type='email'
								id='ForgotPasswordModalEmail'
								required
								onChange={changeState}
								value={forgotPasswordState.email}
								property={'email'}
							/>
						</div>
						{/* <div className="modal-footer">
							<button className='btn btn-lg btn-primary' data-bs-toggle='modal' data-bs-target="#pass-reset-complete">Reset Password</button>
						</div> */}
						<div className="modal-footer">
							<button className='btn btn-lg btn-primary'>Reset Password</button>
						</div>
					</form>
				</div>
			</div>
		</div>

	);

};

export default ForgotPasswordModal;
