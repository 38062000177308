/*
 * Date: 7/31/2023
 * Creator: Cameron
 * Purpose: A custom hook that, when given a form classname and the
 * "formState" object from react-hook-form, will loop through the form
 * elements and if the errors object within "formState" contains the
 * form element's name, it will add bootstrap error styling to the form element.
 */
import { useEffect } from 'react';

/**
 * Creates an error message element.
 * @param {any} error
 */
const createErrorElement = (error) => {
	const newElement = document.createElement('p');
	newElement.textContent = error.message;
	newElement.setAttribute('class', 'error w-100'); // Need the element to take up the full width of the container.
	return newElement;
};

/**
 * Adds error styling.
 * @param {any} formClassname Classname of the form you wish to add error styling to.
 * @param {any} formState "formState" object that you receive from registering your form inputs into react-hook-form
 */
const useErrorStyling = (formClassname, reactHookForm) => {
	const { formState } = reactHookForm;
	useEffect(() => {
		const { errors } = formState;
		const formElement = document.querySelector(`.${formClassname}`);

		if (!formElement)
			return;

		// Gather form elements.
		let formInputElements = [];
		Array.from(formElement.getElementsByTagName('input')).forEach(input => formInputElements.push(input));
		Array.from(formElement.getElementsByTagName('select')).forEach(select => formInputElements.push(select));

		formInputElements?.forEach(formElement => {
			// Dig down into the errors object to find the specific property.
			// For example: supplier.BA_USER_ID is a nested object. We want to
			// see if BA_USER_ID has an error or not.
			const propertyNames = formElement.name?.split('.');
			const error = propertyNames?.reduce((obj, prop) => obj?.[prop], errors);
			const parentElement = formElement.parentElement;

			// Add 'is-invalid' classname and error element if needed.
			if (error && !formElement.classList.contains('is-invalid')) {
				formElement.classList.add('is-invalid');
				formElement.classList.add('error-styling-active');
				if (error.message) {
					const newErrorElement = createErrorElement(error);
					parentElement.appendChild(newErrorElement);
				}
			}
			if (!error && formElement.classList.contains('error-styling-active')) {
			//else if (formElement.classList.contains('is-invalid')) {
				formElement.classList.remove('is-invalid');
				formElement.classList.remove('error-styling-active');
				Array.from(parentElement.getElementsByClassName('error')).forEach(element => parentElement.removeChild(element));
			}
		});
	}, [formClassname, formState]);
};

export default useErrorStyling;