/**
 * Creator: Cameron
 * Date: 8/8/2023
 * Purpose: Single place to keep utility functions for
 * "Path" nominations.
 * 
 */
import { toISOStringForDatabase, getDayStart, getNextDay } from './DateUtils';

export const createPackage = (formData) => {
	const {
		PKGTYPE,
		CNTR_NUM,
		TT,
		PACKAGE_NUM,
		STATUS,
		STARTDATE,
		ENDDATE,
		VOLUME_TYPE,
		START_PT_NUM,
		END_PT_NUM,
		IsQtyApplyFullDuration,
		CYCLE,
		COMMENTS,
		USERSTAMP,
		DATESTAMP,
		FUEL
	} = formData;

	const newPkgPath = createPkgPath(formData);

	const newPackage = {
		PACKAGE_NUM: PACKAGE_NUM,
		CNTR_NUM: CNTR_NUM,
		SUPPLIER_BAID: CNTR_NUM?.CNTR_PARTY2?.BA_ID,
		INTER_SHIPPER_BAID: CNTR_NUM?.CNTR_PARTY1?.BA_ID,
		STATUS: STATUS,
		STARTDATE: toISOStringForDatabase(STARTDATE),
		ENDDATE: toISOStringForDatabase(ENDDATE),
		ENT_PKG_PATH: [newPkgPath],
		FUEL: FUEL,
		DAILY_COMM_FLAG: "N",
		MD_FLAG: "D",
		TT: TT,
		ACCT_PROCESS_FLAG: "Y",
		CYCLE: CYCLE,
		COMMENTS: COMMENTS,
		USERSTAMP: USERSTAMP,
		DATESTAMP: DATESTAMP,
		START_PT_NUM: START_PT_NUM,
		END_PT_NUM: END_PT_NUM,
		PKGTYPE: PKGTYPE,
		IsQtyApplyFullDuration: IsQtyApplyFullDuration,
		VOLUME_TYPE: VOLUME_TYPE
	};

	return newPackage;
};

/**
 * For path nominations, only one package path is needed.
 * @param {any} formData
 */
const createPkgPath = (formData) => {
	const pkgPathDtl = createPkgPathDtl(formData);
	const pkgVolDtls = createPkgVolDtls(formData);

	const {
		CNTR_NUM,
		END_PT_NUM,
		START_PT_NUM,
		PATH_NUM,
		PACKAGE_NUM
	} = formData;

	return {
		PackagePathKey: {
			PACKAGE_NUM: PACKAGE_NUM,
			PATH_NUM: PATH_NUM
		},
		PACKAGE_NUM: PACKAGE_NUM,
		PATH_NUM: PATH_NUM,
		CNTR_NUM: CNTR_NUM?.CNTR_NUM,
		DLV_PT_NUM: END_PT_NUM,
		RCV_PT_NUM: START_PT_NUM,
		SEQ_NUM: 1,
		Contract: CNTR_NUM,
		ENT_PKG_PATH_DTL: [pkgPathDtl],
		ENT_PKG_VOL_DTL: pkgVolDtls
	};
};

/**
 * For path nominations, a list of package volume details is needed.
 * @param {any} formData
 */
const createPkgVolDtls = (formData) => {
	const {
		PACKAGE_NUM,
		PATH_NUM,
		STATUS,
		STARTDATE,
		ENDDATE,
		NOM_RCV_VOL,
		NOM_DLV_VOL,
		CYCLE,
		FUEL_PERCENT,
		FUEL,
		RANK
	} = formData;

	// Convert to date object for comparing dates.
	const endDate = getDayStart(ENDDATE);
	const startDate = getDayStart(STARTDATE);

	let pkgVolDetails = [];

	let currentDay = startDate;
	while (currentDay.getTime() <= endDate.getTime()) {
		const pkgVolumeDtl = {
			PackageVolumeDetailKey: {
				PACKAGE_NUMSTR: PACKAGE_NUM,
				PATH_NUM: PATH_NUM,
				GASFLOW_DATE: toISOStringForDatabase(currentDay)
			},
			PACKAGE_NUMSTR: PACKAGE_NUM,
			PATH_NUM: PATH_NUM,
			GASFLOW_DATE: toISOStringForDatabase(currentDay),
			BEST_VOL_CODE: STATUS,
			NOM_RCV_VOL: NOM_RCV_VOL,
			NOM_DLV_VOL: NOM_DLV_VOL,
			SCH_RCV_VOL: NOM_RCV_VOL,
			SCH_DLV_VOL: NOM_DLV_VOL,
			ACT_RCV_VOL: NOM_RCV_VOL,
			ACT_DLV_VOL: NOM_DLV_VOL,
			STATUS: STATUS,
			FUEL_PERCENT_DB: FUEL_PERCENT,
			FUEL: FUEL,
			NOM_PRICE: 0,
			PATH_COST: 0,
			CUM_COST: 0,
			FEE: 0,
			GASFLOW_ENDDATE: toISOStringForDatabase(endDate),
			SEQ_NUM: 1,
			CYCLE: CYCLE,
			RANK: RANK,
			UNIT_MEAS: 'MC'
		};
		pkgVolDetails.push(pkgVolumeDtl);
		currentDay = getNextDay(currentDay);
	}

	return pkgVolDetails;
};

/**
 * For path nominations, only one package path detail is needed.
 * @param {any} formData
 */
const createPkgPathDtl = (formData) => {
	const {
		PATH_NUM,
		PACKAGE_NUM,
		CNTR_NUM,
		ACTIV_NUM,
		NOM_RCV_VOL,
		NOM_DLV_VOL,
		FUEL_PERCENT,
		STARTDATE,
		ENDDATE
	} = formData;

	const pkgPathDtl = {
		PackagePathDetailKey: {
			PACKAGE_NUM: PACKAGE_NUM,
			PATH_NUM: PATH_NUM,
			EFFDATE: toISOStringForDatabase(STARTDATE)
		},
		PACKAGE_NUM: PACKAGE_NUM,
		PATH_NUM: PATH_NUM,
		EFFDATE: toISOStringForDatabase(STARTDATE),
		ENDDATE: toISOStringForDatabase(ENDDATE),
		SEQ_NUM: 1,
		PIPELINE_ID: ACTIV_NUM?.UP_PIPELINE_ID,
		ACTIV_NUM: ACTIV_NUM,
		FUEL_PERCENT_DB: FUEL_PERCENT,
		NOM_RCV_VOL: NOM_RCV_VOL,
		NOM_DLV_VOL: NOM_DLV_VOL,
		SCH_RCV_VOL: NOM_RCV_VOL,
		SCH_DLV_VOL: NOM_DLV_VOL,
		DN_CNTR: CNTR_NUM?.CNTR_ALT_NUM1,
		DN_RANK: '1',
		UP_CNTR: ACTIV_NUM?.UP_CNTR_NUM,
		UP_SHIPPER_DUNS: ACTIV_NUM?.UP_SHIPPER_DUNS,
		DN_SHIPPER_DUNS: ACTIV_NUM?.DN_SHIPPER_DUNS,
		UP_PIPELINE_IDBAOBJ: ACTIV_NUM?.UP_PIPELINE_IDBAOBJ
	};

	return pkgPathDtl;
};