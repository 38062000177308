import { useState, useEffect } from "react";

const SearchBar = ({ data, setFilteredData }) => {

	const [searchValue, setSearchValue] = useState();

	// Update filtered data any time the search value changes.
	useEffect(() => {
		const newFilteredData = data?.filter(record => {
			let contains = false;
			Object.keys(record).forEach(key => {
				if (!record[key])
					return;
				if (!searchValue)
					contains = true;
				else if (record[key].toString().toLowerCase().includes(searchValue.toLowerCase())) {
					contains = true;
				}
			});
			if (contains) {
				return record;
			}
			return null;
		});
		setFilteredData(newFilteredData);
	}, [searchValue, data, setFilteredData]);

	return (
		<input
			value={searchValue}
			onChange={(e) => { setSearchValue(e.target.value) }}
			className="form-control form-control-sm m-1"
			type="text"
			placeholder="Search..."
			aria-label="Search"
		/>
	);
};

export default SearchBar;