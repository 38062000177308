import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMonthStart, getMonthEnd } from '../utils/DateUtils';

/**
 * Pass in the component state set function. This hook will
 * update the component state any time the business selector
 * state changes.
 * @param bool restrictDate
 */
const useBusinessSelectorRedux = (filters, setFilters, restrictDate) => {

	if (!isActionCreator(setFilters))
		throw new Error('"setFilters" function must be an action creator, it cannot be a regular function.');

	// Keep track of previous filters object. This is done to prevent infinite loops within the useEffects.
	const prevFilters = useRef();
	useEffect(() => { prevFilters.current = filters }, [filters]);

	// Keep track of renders. I do not want the gas flow date to be set on first render. I want the component
	// to be able to set its own initial gas flow date.
	//const renders = useRef(0);
	//useEffect(() => { renders.current++; });

	// Redux business selector state.
	const gasFlowDate = useSelector(state => state.business_selector_slice.gasFlowDate);
	const businessUnit = useSelector(state => state.business_selector_slice.businessUnit);
	const selectedSupplier = useSelector(state => state.business_selector_slice.selectedSupplier);
	const dispatch = useDispatch();

	// Update gas flow date.
	useEffect(() => {
		//if (renders.current === 1) return; // Gas flow date should not get set on first render.

		//console.log('prevFilters: ', prevFilters.current);

		let newFilters = { ...prevFilters.current };

		// Only modify values that are actually in state.
		if (newFilters?.gasFlowDate && restrictDate) {
			if (new Date(gasFlowDate) > new Date(2023, 9, 1)) { // devops 4666 restrict dates prior to november 2023
				newFilters.gasFlowDate = gasFlowDate;
			}
		}
		else if (newFilters?.gasFlowDate)
			newFilters.gasFlowDate = gasFlowDate;

		if (newFilters?.startDate)
			newFilters.startDate = getMonthStart(gasFlowDate)?.toISOString();

		if (newFilters?.endDate)
			newFilters.endDate = getMonthEnd(gasFlowDate)?.toISOString();

		if (newFilters?.businessUnit)
			newFilters.businessUnit = businessUnit;

		if (newFilters?.selectedSupplier)
			newFilters.selectedSupplier = selectedSupplier;

		// Blank out contract.
		if (newFilters?.selectedContract)
			newFilters.selectedContract = null;

		//console.log('newFilters: ', newFilters);

		// Dispatch setFilters function.
		dispatch(setFilters(newFilters));
	}, [gasFlowDate, businessUnit, selectedSupplier, setFilters, dispatch]);

	/*
	// Update business unit.
	useEffect(() => {
		let newFilters = { ...prevFilters.current };

		// Only modify values that are actually in state.
		if (newFilters?.businessUnit)
			newFilters.businessUnit = businessUnit;

		// Dispatch setFilters function.
		dispatch(setFilters(newFilters));
	}, [businessUnit, setFilters, dispatch]);

	// Update selected supplier.
	useEffect(() => {
		let newFilters = { ...prevFilters.current };

		// Only modify values that are actually in state.
		if (newFilters?.selectedSupplier)
			newFilters.selectedSupplier = selectedSupplier;
		// Blank out contract.
		if (newFilters?.selectedContract)
			newFilters.selectedContract = null;

		// Dispatch setFilters function.
		dispatch(setFilters(newFilters));
	}, [selectedSupplier, setFilters, dispatch]);
	*/
};

// Make sure before dispatching that the function passed in is a valid action creator.
function isActionCreator(func) {
	// Check if the function is a function
	if (typeof func !== 'function') {
		return false;
	}

	// Call the function to get the action object
	const action = func();

	// Check if the returned value is an object with a 'type' property
	return action && typeof action === 'object' && action.type !== undefined;
	};

export default useBusinessSelectorRedux;