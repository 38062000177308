/*
 * Creator: Cameron
 * Date: 5/10/2023
 * Purpose:
 * Any general utility functions related to fetching to the API
 * that need to be accessed anywhere in the app should go here.
 * 
 */

/**
 * Handles the fetch request and error logging for you
 * when you want to fetch some data. 
 * @param {any} url
 */
export const getData = async (url) => {
	const response = await fetch(url);
	if (!response.ok) {
		const message = await response.text();
		throw new Error(message);
	}
	const data = await response.json();
	return data;
};

export const postNewData = async (url, newData) => {
	const response = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(newData)
	});
	if (!response.ok) {
		const message = await response.text();
		throw new Error(message);
	}
	return response;
}

export const deleteRecord = async (url) => {
	const response = await fetch(url, { method: 'DELETE' });
	return response;
}