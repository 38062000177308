import { createSlice } from '@reduxjs/toolkit';
import { notification_actions } from './notification-slice';
import { getData } from '../utils/APIUtils';


const initialState = {
	points: [],
	selectedPoint: {},
	contracts: [],
	selectedContract: {},
	filteredContracts: []
};

const general_data_slice = createSlice({
	name: 'general_data_slice',
	initialState,
	reducers: {
		setPoints(state, action) {
			const newPoints = action.payload;
			state.points = newPoints;
		},
		setContracts(state, action) {
			const newContracts = action.payload;
			state.contracts = newContracts;
		},
		setSelectedContract(state, action) {
			const newSelectedContract = action.payload;
			state.selectedContract = newSelectedContract;
		},
		setSelectedPoint(state, action) {
			const newSelectedPoint = action.payload;
			state.selectedPoint = newSelectedPoint;
		},
		setFilteredContracts(state, action) {
			state.filteredContracts = action.payload;
		}
	}
});

export const fetchPoints = (ba_id) => {

	return async (dispatch) => {

		const requestPoints = async () => {

			const response = await fetch('GeneralData/GetPoints?ba_id=' + ba_id);

			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			const newPoints = await response.json();

			dispatch(general_data_actions.setPoints(newPoints));
			dispatch(general_data_actions.setSelectedPoint(newPoints[0]));

		};

		try {
			console.log('Fetching supplier points...');
			await requestPoints();
			console.log('Points fetched.');
		}
		catch (error) {
			console.log(error.message);
		}

	};

};

export const fetchContracts = () => {
	return async (dispatch, getState) => {
		try {
			const ba_id = getState().business_selector_slice.selectedSupplier;
			if (ba_id) {
				const url = `GeneralData/GetContracts?ba_id=${ba_id}&status=AC`;
				const data = await getData(url);
				await dispatch(general_data_actions.setContracts(data));
				await dispatch(general_data_actions.setSelectedContract(data?.[0]));
			}
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
	}
};

export const getMatchingContract = ({ba_id, cntr_type}) => {
	return async (dispatch, getState) => {
		try {
			if (ba_id) {
				const url = `GeneralData/GetContracts?cntr_party1=${ba_id}&cntr_type=${cntr_type}`;
				const data = await getData(url);
				await dispatch(general_data_actions.setFilteredContracts(data));
			}
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
	}
}



export const general_data_actions = general_data_slice.actions;
export default general_data_slice.reducer;