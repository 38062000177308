import { createSlice } from "@reduxjs/toolkit";
import { getPoolBalance } from './pool-balance-thunks';

const poolBalance_slice = createSlice({
    name: "PoolBalance",
    initialState: {
        poolBalanceData: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        setPoolBalanceData: (state, action) => {
            state.poolBalanceData = action.payload;
        },
        resetPoolBalanceData: (state) => { // Adding a reducer to reset the state
            state.poolBalanceData = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPoolBalance.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPoolBalance.fulfilled, (state, action) => {
                state.poolBalanceData = action.payload;
                state.status = 'succeeded';
            })
            .addCase(getPoolBalance.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});


export const poolBalanceActions = poolBalance_slice.actions;
export default poolBalance_slice.reducer;