
export const columns = [
	{
		name: 'Point Name',
		selector: row => row.PT_NAME,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	},
	{
		name: 'Point ID',
		selector: row => row.POINT_ID,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	},
	{
		name: 'Point Type',
		selector: row => row.PT_TYPE,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true

	}
];

export default columns;