const CustomStyles = {
    table: {
        style: {
            top: '0px',
            right: '0px',
            border: '1px solid lightGray',
        },
    },
    headRow: {
        style: {
            fontSize: ".8rem",
            // paddingLeft: '1px', // override the cell padding for head cells
            // paddingRight: '1px',
            textAlign: 'center',
        },
    },
    cells: {
        style: {
            fontSize: '.8rem',
            border: '1px solid lightGray',
            // paddingLeft: '1px', // override the cell padding for head cells
            // paddingRight: '1px',
            textAlign: 'center',
            justifyContent: 'center',
        }
    },
    critical: {
        style: {
            color: 'red',
        }
    },
    nonCritical: {
        style: {
            color: 'black',
        }
    },
}

export default CustomStyles