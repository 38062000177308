import DataTable from "react-data-table-component";

import CustomStyles from "./Table-Styles";

function PoolBalanceTable({ filteredData }) {
    let columns = [{
        name: "Shipper",
        selector: row => row.ContractObj.CNTR_PARTY1.SHORTNAME,
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "Contract ID",
        selector: row => row.CNTR_ALT_NUM1,
        sortable: true,
        compact: true,
        wrap: false,
        width: '100px'

    },
    {
        name: "Shipper Pool",
        selector: row => row.POINT_ID,
        sortable: true,
        compact: true,
        wrap: true,

    },
    {
        name: "Beg Balance (DTH)",
        selector: row => row.BEG_VALUE == null ? "0" : Math.round(row.BEG_VALUE).toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "Gross Receipt Qty (DTH)",
        selector: row => row.GrossReceiptQty == null ? "0" : row.GrossReceiptQty.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,
    },

    {
        name: "Receipt Fuel Qty (DTH)",
        selector: row => row.ReceiptFuelQty == null ? "0" : Math.round(row.ReceiptFuelQty).toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "Alloc Rec Net of Fuel (DTH)",
        selector: row => row.AllocRecNetOfFuel == null ? "0" :  Math.round(row.AllocRecNetOfFuel).toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "Alloc Del (DTH)",
        selector: row => row.AllocDel == null ? "0" : row.AllocDel.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "PPA Rec (DTH)",
        selector: row => row.PPARec == null ? "0" : row.PPARec.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,
    },
    {
        name: "PPA Del (DTH)",
        selector: row => row.PPADel == null ? "0" : row.PPADel.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "Delta PPA Qty (DTH)",
        selector: row => row.DeltaPPAQty == null ? "0" : row.DeltaPPAQty.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,
    },
    {
        name: "Swing Receipt (DTH)",
        selector: row => row.SwingReceipt == null ? "0" : row.SwingReceipt.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false
    },
    {
        name: "Original Imbalance (DTH)",
        selector: row => row.OriginalImbalance == null ? "0" : row.OriginalImbalance.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,
        omit: true
    },
    {
        name: "Adjustments (DTH)",
        selector: row => row.ADJ_QTY == null ? "0" : row.ADJ_QTY.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,

    },
    {
        name: "Payback Qty (DTH)",
        selector: row => row.PaybackQty == null ? "0" : row.PaybackQty.toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,
        omit: true
    },
    {
        name: "Net Ending Balance(DTH)",
        selector: row => row.NET_END_IMBAL == null ? "0" : Math.round(row.NET_END_IMBAL).toLocaleString(),
        sortable: true,
        compact: true,
        wrap: false,
    }]

    return (
        <div className="container-fluid">
            <DataTable
                title="Shipper Pool Balance"
                columns={columns}
                // data={dataWithTotals}
                data={filteredData}
                dense={true}
                striped={true}
                fixedHeader={true}
                fixedHeaderScrollHeight="450px"
                customStyles={CustomStyles}
                highlightOnHover={true}
                className="pb-1"
            />
        </div>
    )
}

export default PoolBalanceTable