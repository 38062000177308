function FootNote({user}) {
    const customStyles = {
        fontSize: '9pt'
    }

    return (
        <>
            <div className="container-fluid" style={customStyles}>
                <div className="row">
                    <div className="col-10">&nbsp;</div>
                    <div className="col-2">User ID: {user?.FIRST_NAME} {user?.NAME} </div>
                </div>
            </div>
        </>
    )
}

export default FootNote;