import DataTable from 'react-data-table-component';

import customStyles from './Table-Styles';

function TableIndex2({
	selectedPriceRefIndex,
	selectedPriceArea,
	setSelectedPriceArea
}) {

	const columns = [
		{
			cell: row => <input type="checkbox" checked={row.PRICE_AREA_ID === selectedPriceArea.PRICE_AREA_ID} onChange={() => { handleRowClicked(row) }} />,
			allowOverflow: true,
			button: true,
		},
		{
			name: 'Area ID',
			selector: row => row.PRICE_AREA_ID,
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'Area Name',
			selector: row => row.PRICE_AREA_NAME,
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'Apply',
			selector: row => row.ApplyText,
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'Uom',
			selector: row => row.UnitText,
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'Start Date',
			selector: row => new Date(row.EFF_DATE).toLocaleDateString('en-US', {
				day: 'numeric',
				month: 'long',
				year: 'numeric'
			}),
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'End Date',
			selector: row => new Date(row.TERM_DATE).toLocaleDateString('en-US', {
				day: 'numeric',
				month: 'long',
				year: 'numeric'
			}),
			sortable: true,
			reorder: true,
			wrap: true
		},

		{
			name: 'Updated By',
			selector: row => row.USERSTAMP,
			sortable: true,
			reorder: true,
			wrap: true
		},
		{
			name: 'Updated On',
			selector: row => new Date(row.DATESTAMP).toLocaleDateString('en-US', {
				day: 'numeric',
				month: 'long',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric'
			}),
			sortable: true,
			reorder: true,
			wrap: true
		}
	];

	// Handlers
	const handleRowClicked = (row) => {
		setSelectedPriceArea(row);
	};

	return (
		<div className=''>
			<DataTable
				highlightOnHover
				onRowClicked={handleRowClicked}
				columns={columns}
				data={selectedPriceRefIndex?.ENT_PRICE_AREA}
				customStyles={customStyles}
				dense={true}
				// fixedHeader={true}
				fixedHeaderScrollHeight='200px'
				className="pb-1"
			/>
		</div >
	)
}


export default TableIndex2