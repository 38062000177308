
// redux stuff
import { useSelector } from 'react-redux';

import Logo from './assets/images/gastar-logo.png';

const Intro = () => {

	// redux stuff
	const isLoggedIn = useSelector(state => state.auth_slice.isLoggedIn);

	return (
		<div className="container-fluid">
			<div className="row text-center text-white">
				<div className='col-12 text-center'>
					<img src={Logo} alt='Gastar logo' className='img-fluid gastar-logo-md' />
					<br />
					<h1 className="ensyte-style-h1">GASTAR Web Portal</h1>
				</div>
				{/* <div className='text-center mb-5'><h3 className='text-danger'>**Operational Alerts will go here**</h3>
                </div> */}
				{isLoggedIn && (<p className='fs-4'>Thank you for logging in.</p>)}
				{!isLoggedIn && (<p className='fs-4'>Login to continue...</p>)}
				<div className='text-center'><p>©1981-2024 ENSYTE Energy Software Int’l, Inc.</p></div>
			</div>
			{/* {
				// if connecting, show "connecting..."
				state.connecting ? (
					<div className="mt-4 container-fluid">
						<div className="row justify-content-center">
							<div className="alert alert-info col-auto" role="alert">
								<div className="row">
									<div className="col-auto">
										<p>Connecting to Database...</p>
									</div>
									<div className="col-auto">
										<div
											className="spinner-border spinner-border-sm"
											role="status"
											aria-hidden="true">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null
			}
			{
				// if connection was successful, show success
				state.showSuccess ? (
					<div className="mt-4 container-fluid">
						<div className="row justify-content-center">
							<div className="alert alert-success col-auto" role="alert">
								<div className="row">
									<div className="col-auto">
										<p>Success! You are now connected to the database.</p>
									</div>
									<div className="col-auto">
										<button
											type="button"
											className="btn-close"
											onClick={
												() => {
													setState(previousState => {
														return { ...previousState, showSuccess: false }
													})
												}
											}
										>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null
			}
			{
				// if there was an error with the connection setup, show error
				state.showError ? (
					<div className="mt-4 container-fluid">
						<div className="row justify-content-center">
							<div className="alert alert-danger col-auto" role="alert">
								<div className="row">
									<div className="col-auto">
										<p>Uh Oh, Something went wrong...</p>
									</div>
									<div className="col-auto">
										<button
											type="button"
											className="btn-close"
											onClick={
												() => {
													setState(previousState => {
														return { ...previousState, showError: false }
													})
												}
											}
										>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null
			} */}
		</div>
	);

};

export default Intro;