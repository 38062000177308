import ReactHookFormSelector from './ReactHookFormSelector';

const InstrumentSelector = ({
	form,
	propertyName,
	url,
	disabled,
	className,
	shouldLog,
	afterSetValue,
	required
}) => {
	return (
		<ReactHookFormSelector
			disabled={disabled}
			className={className || 'form-select form-select-sm'}
			form={form}
			valueType='object'
			url={url}
			propertyName={propertyName}
			optionKey='CntrNum'
			optionText='ContractName'
			shouldLog={shouldLog}
			afterSetValue={afterSetValue}
			required={required}
		/>
	);
};

export default InstrumentSelector;