
/**
 * Custom spinner component. Add this wherever you need 
 * a spinner.
 */
const Spinner = ({ status }) => {
	let text = ""
	switch (status) {
		case 'loading':
			text = "Loading...";
			break;
		case 'saving':
			text = "Saving...";
			break;
		case 'deleting':
			text = "Deleting...";
			break;
		default:
			text = 'Loading...';
			break;
	}
	return (
		<span className='p-2 text-center'>
				<span className='pe-2 m-0'>{text}</span>
				<span
					className="spinner-border spinner-border-sm"
					role="status"
					aria-hidden="true">
				</span>
		</span>
	)
}

export default Spinner;