import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { physical_trade_deals_actions } from '../../../store/physical-trade-deals-slice';
import Spinner from '../../Spinner';

import CustomStyles from './SSM-Table-Style';

function Table({ loading, chargeTypes }) {

	// Redux
	const selectedBidPkg = useSelector(state => state.physical_trade_deals_slice.selectedBidPkg);
	const dispatch = useDispatch();

	const columns = [
		{
			cell: row => <input type="checkbox" checked={row.CDE === selectedBidPkg?.CHG_REF_CDE?.CDE} onChange={() => { handleRowClicked(row) }} />,
			allowOverflow: true,
			button: true,
		},
		{
			name: 'Code Text',
			selector: row => row.CDE_TXT,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Code',
			selector: row => row.CDE,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		}
	];

	const handleRowClicked = (row) => {
		if (selectedBidPkg?.CHG_REF_CDE?.CDE === row?.CDE) {
			dispatch(physical_trade_deals_actions.setOpenChargeTypeModal(false));
			return;
		}
		// update charge type in selected bid package
		dispatch(physical_trade_deals_actions.setSelectedBidPkg({
			...selectedBidPkg,
			CHG_REF_CDE: row.CDE
		}));

		// hide modal after row is finished selecting
		dispatch(physical_trade_deals_actions.setOpenChargeTypeModal(false));
	};

	return (
		<DataTable
			progressPending={loading}
			progressComponent={<Spinner status='loading' />}
			className='table-striped pb-1'
			fixedHeader={true}
			fixedHeaderScrollHeight="700px"
			columns={columns}
			data={chargeTypes}
			striped={true}
			highlightOnHover={true}
			customStyles={CustomStyles}
			onRowClicked={handleRowClicked}
			dense={true}
		/>
	);
};

export default Table;