import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import AddImage from '../../../../../assets/images/add.gif';
import SaveImage from '../../../../../assets/images/save.png';
import NomDetailsImage from '../../../../../assets/images/nomdtl.gif';
import CancelImage from '../../../../../assets/images/cancel.png';
import DeleteImage from '../../../../../assets/images/delete.png';
import { getMonthEnd, toDatePickerString } from '../../../../../utils/DateUtils';
import Restricted from '../../../../Restricted';
import './AddNomModalStyles.css';
import { non_path_noms_actions, fetchPackages, setPackageAndFetchPkgPaths } from '../../../../../store/non-path-noms-slice';
import { useForm } from 'react-hook-form';
import useErrorStyling from '../../../../../hooks/useErrorStyling';
import ActivityLookupModal from '../../../../CustomComponents/ReactHookFormLookupModals/ActivityLookupModal';
import CodeSelector from '../../../../CustomComponents/ReactHookFormSelectors/CodeSelector';
import { getFuelPercent } from '../../../../../utils/GeneralNomUtils';
import { createPackage } from '../../../../../utils/NonPathModelNomUtils';
import { notification_actions } from '../../../../../store/notification-slice';
import Spinner from '../../../../Spinner';
import LocationQuantitiesTable from '../../Tables/LocationQuantitiesTable';
import useNonPathFormUpdate from '../../../../../hooks/useNonPathFormUpdate';
import BASelector from '../../../ReactHookFormSelectors/BASelector';
import useNomCycleUpdateRHF from '../../../../../hooks/useNomCycleUpdateRHF';


// Modal styling
const customStyles = {
	overlay: {
		backgroundColor: 'rgb(0,0,0,.60'
	}
};

const AddNom = () => {

	// Redux
	const { addNomModalState, nomDetailModalState } = useSelector(state => state.non_path_noms_slice.modalStates);
	const { open, loading } = addNomModalState;
	const latestApprovalDate = useSelector(state => state.non_path_noms_slice.latestApprovalDate);
	const pkgPathsFetchLoading = useSelector(state => state.non_path_noms_slice.pkgPathsFetchLoading);
	const selectedPackage = useSelector(state => state.non_path_noms_slice.selectedPackage);
	const NonPathNomsFilters = useSelector(state => state.non_path_noms_slice.NonPathNomsFilters);
	const canEditPackage = useSelector(state => state.non_path_noms_slice.canEditPackage);
	const selectedContract = NonPathNomsFilters?.selectedContract;
	const dispatch = useDispatch();

	// React Hook Form
	const form = useForm();
	const { reset, formState, setValue, getValues, register, handleSubmit } = form;
	const { dirtyFields, isSubmitted } = formState;

	// Updates the form when selected package, selected contract, or selectedPkgPathInfoObj changes.
	useNonPathFormUpdate(form);

	// Update nom cycle based on changing package start date.
	useNomCycleUpdateRHF(form);

	// Function to handle activity change.
	const afterActivityChange = (newActivity) => {
		const activNonPaths = newActivity?.ENT_ACTIVITY_NONPATH;
		if (!activNonPaths) return;
		setValue('ACTIV_ALT_NUM', newActivity?.ACTIV_ALT_NUM, { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });
		setValue('TT', newActivity?.ACTIV_CODE, { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });
		setValue('pkgPathInfoObjs', activNonPaths?.map(activNonPath => {
			return {
				PACKAGE_NUM: '',
				PATH_NUM: '',
				USERSTAMP: null,
				DATESTAMP: null,
				PT_DIR_FLOW: activNonPath.PT_DIR_FLOW,
				PT_NUM_OBJ: activNonPath.PT_NUM_OBJ,
				UP_CNTR: null,
				DN_CNTR: activNonPath.PT_DIR_FLOW === 'D' ? selectedContract.CNTR_ALT_NUM1 : null,
				//UP_SHIPPER_DUNS: newActivity?.UP_SHIPPER_DUNS, * DevOps-4128: Default Up Shipper Duns to 'Hope Gas' *
				//DN_SHIPPER_DUNS: newActivity?.DN_SHIPPER_DUNS,
				UP_SHIPPER_DUNS: 'Hope Gas',
				DN_SHIPPER_DUNS: 'Hope Gas',
				NOM_RCV_VOL: 0,
				NOM_DLV_VOL: 0,
				FUEL_PERCENT_DB: getFuelPercent(selectedContract),
				FUEL: 0
			};
		}));
	};

	// Custom error styling hook.
	useErrorStyling('non-path-noms-form', form);

	const submitForm = async (formData) => {
		if (dirtyFields && Object.keys(dirtyFields)?.length < 1) return; // If nothing has changed, just return.
		try {
			// Set loading to true
			await dispatch(non_path_noms_actions.changeModalState({
				modalStateName: 'addNomModalState',
				newModalState: { ...addNomModalState, loading: true }
			}));

			const url = formData?.PACKAGE_NUM ? 'Package/UpdatePackage' : 'Package/CreatePackage';
			const newPackage = createPackage(formData);

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(newPackage)
			});

			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			const finishedPackage = await response.json();
			await dispatch(setPackageAndFetchPkgPaths(finishedPackage));

			await dispatch(notification_actions.showModal({ header: 'Success!', message: 'Nomination saved successfully.' }));
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
		finally {
			// Set loading to false
			await dispatch(non_path_noms_actions.changeModalState({
				modalStateName: 'addNomModalState',
				newModalState: { ...addNomModalState, loading: false }
			}));
			await dispatch(fetchPackages(NonPathNomsFilters));
		}
	};

	const deleteNominationClick = async () => {
		const package_num = selectedPackage?.PACKAGE_NUM;
		if (!package_num) return;
		try {
			// Set loading to true
			await dispatch(non_path_noms_actions.changeModalState({
				modalStateName: 'addNomModalState',
				newModalState: { ...addNomModalState, loading: true }
			}));

			const response = await fetch(`Package/DeletePackage?package_num=${package_num}`, { method: 'DELETE' });

			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			await dispatch(notification_actions.showModal({ header: 'Success!', message: 'Nomination deleted successfully.' }));

			// Close Add Nom Modal
			closeModal();

			// Fetch packages
			await dispatch(fetchPackages(NonPathNomsFilters));
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));

			// Set loading to false
			await dispatch(non_path_noms_actions.changeModalState({
				modalStateName: 'addNomModalState',
				newModalState: { ...addNomModalState, loading: false }
			}));
		}
	};

	const newNominationsClick = async () => {
		// Blank out selected package.
		await dispatch(non_path_noms_actions.setSelectedPackage(null));

		// Allow form to reset.
		await dispatch(non_path_noms_actions.setShouldResetForm(true));
	};

	const closeModal = async () => {
		await dispatch(non_path_noms_actions.setSelectedPackage(null));

		reset({}); // Reset the form.

		// Close AddNom modal.
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'addNomModalState',
			newModalState: { ...addNomModalState, open: false }
		}));
	};

	const nomDetailsClick = async () => {
		// Close AddNom modal.
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'addNomModalState',
			newModalState: { ...addNomModalState, open: false }
		}));

		// Open NonPathDetails modal.
		await dispatch(non_path_noms_actions.changeModalState({
			modalStateName: 'nomDetailModalState',
			newModalState: { ...nomDetailModalState, open: true, previousModalState: addNomModalState, previousModalStateName: 'addNomModalState' }
		}));
	};

	/**
	 * Custom onChange event handler for STARTDATE property change.
	 * This is needed so that the ENDDATE value is set to the end of the month
	 * any time the STARTDATE changes.
	 * @param {any} e - onChange event
	 */
	const beginDateChange = (e) => {
		const newValue = e.target.value;

		// Set new value.
		setValue('STARTDATE', newValue, { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });

		// Set ENDDATE value to end of month.
		setValue('ENDDATE', toDatePickerString(getMonthEnd(newValue)));
	};

	return (
		<Modal
			isOpen={open}
			onRequestClose={closeModal}
			className='add-nom-modal container-fluid bg-white text-black'
			style={customStyles}
		>
			<div className="modal-content">
				<div className="modal-header bg-primary ps-2 pe-2">
					<h5 className="text-white">Add new Nomination</h5>
					<button type="button" className="btn-close" onClick={closeModal} ></button>
				</div>
				<form className="container-fluid non-path-noms-form modal-body pt-2 pb-2" onSubmit={handleSubmit(submitForm)}>
					{/* // BUTTONS */}
					<div className="row ">
						<div className="col-lg-12 col-md-12 col-md-12 col-sm-12">
							<div className="btn-group">
								<Restricted restrictedTo={['WRITE-ACCESS']}>
									<button
										type="button"
										className="btn btn-primary"
										title="New Nomination"
										onClick={newNominationsClick}
										disabled={loading || !canEditPackage || pkgPathsFetchLoading}
									>
										<img src={AddImage} alt="AddImage" /> Add
									</button>
									<button
										type="submit"
										className="btn btn-primary"
										title="Save Nomination"
										disabled={loading || !canEditPackage || pkgPathsFetchLoading}
									>
										<img src={SaveImage} alt="SaveImage" /> Save
									</button>
								</Restricted>
								<button
									type="button"
									className="btn btn-primary"
									title="Nomination Details"
									onClick={nomDetailsClick}
									disabled={loading}
								>
									<img
										src={NomDetailsImage}
										alt="NomDetailsImage"
									/>
									Nom Details
								</button>
								<button
									type="button"
									className="btn btn-primary"
									title="Cancel and Return"
									disabled={loading || pkgPathsFetchLoading}
									onClick={closeModal}
								>
									<img
										src={CancelImage}
										alt="CancelImage"
									/>
									Cancel
								</button>
								<Restricted restrictedTo={['WRITE-ACCESS']}>
									<button
										type="button"
										className="btn btn-primary"
										title="Delete Nomination"
										onClick={deleteNominationClick}
										disabled={loading || !canEditPackage || pkgPathsFetchLoading}
									>
										<img
											src={DeleteImage}
											alt="DeleteImage"
										/>
										Delete
									</button>
								</Restricted>
							</div>
							{(loading || pkgPathsFetchLoading) && <Spinner status='loading' />}
						</div>
					</div>
					{/* // REQUESTOR SECTION*/}
					<h5 className=" border-bottom border-lg-2 col-md-12 col-sm-12 border-primary">Requestor</h5>
					<div className="row mt-1">


						{/* Contract Title */}
						<div className='col-lg-3 col-md-4 col-sm-6'>
							<label className="form-label">Contract Title:</label>
							<div className='form-group mb-0'>
								<input
									disabled
									className="form-control form-control-sm"
									id="ContractTitle"
									type="text"
									{...register('CNTR_NUM.CNTR_TITLE')}
								/>
							</div>
						</div>

						{/* Contract */}
						<div className='col-lg-2 col-md-2 col-sm-6'>
							<label
								className="form-label"
							>
								Contract:
							</label>
							<input
								disabled
								className="form-control form-control-sm"
								type="text"
								{...register('CNTR_NUM.CNTR_ALT_NUM1', { required: true })}
							/>
						</div>

						{/* RQ Duns */}
						<div className='col-lg-3 col-md-2 col-sm-6'>
							<label className="form-label">RQ Duns:</label>
							<input
								disabled
								className="form-control form-control-sm text-muted"
								type="text"
								placeholder='--'
								{...register('REQUESTOR.BA_USER_ID')}
							/>
						</div>


						{/* Pipeline */}
						<div className="col-lg-4 col-md-4 col-sm-6">
							<label className="form-label" htmlFor="Pipeline">Pipeline:</label>
							<BASelector
								disabled
								form={form}
								propertyName='TRANSPORTER'
								url='BusinessAssociate/GetBusinessAssociates?ba_role=TR'
							/>
						</div>


					</div>
					<div className='row mt-1 mb-2'>

						{/* Requestor */}
						<div className='col-lg-3 col-md-3 col-sm-6'>
							<label
								className="form-label"
								htmlFor="Requestor"
							>
								Requestor:
							</label>
							<input
								disabled
								className="form-control form-control-sm"
								id="Requestor"
								type="text"
								{...register('REQUESTOR.SHORTNAME')}
							/>
						</div>


						{/* Provider */}
						<div className='col-lg-2 col-md-2 col-sm-6'>
							<label
								className="form-label"
								htmlFor="Provider"
							>
								Provider:
							</label>
							<input
								disabled
								className="form-control form-control-sm text-muted"
								id="Provider"
								type="text"
								placeholder='--'
							/>
						</div>

						{/* Activity */}
						<div className="col-lg-3 col-md-3 col-sm-6">
							<label className="form-label">Activity:</label>
							<input
								disabled
								className="form-control form-control-sm"
								type="text"
								placeholder='--Select an Activity--'
								{...register('ACTIV_ALT_NUM', { required: true })}
							/>

						</div>


						{/* Activity Lookup Button */}
						<Restricted restrictedTo={['WRITE-ACCESS']}>
							<div className='col-lg-2 col-md-2 col-sm-6'>
								<label className='form-label'>Activity Lookup:</label>
								<ActivityLookupModal
									className='btn btn-sm btn-primary d-block w-100'
									disabled={!canEditPackage}
									form={form}
									propertyName='ACTIV_NUM'
									url={`Activity/GetActivities?status=AC&cntr_num=${getValues('CNTR_NUM')?.CNTR_NUM}`}
									afterSetValue={afterActivityChange}
								/>
							</div>
						</Restricted>


						{/* Rate Scheduler */}
						<div className="col-lg-2 col-md-2 col-sm-6">
							<label className="form-label">Rate Schedule:</label>
							<input
								disabled
								className="form-control form-control-sm"
								type="text"
								{...register('CNTR_NUM.RATE_SCH_ID')}
							/>
						</div>



					</div>
					{/* // FLOWING GAS SECTION*/}
					<h5 className=" border-bottom border-lg-2 col-md-12 col-sm-12 border-primary">Flowing Gas</h5>
					<div className="row mb-1">

						<label className="form-label col-lg-1 col-sm-12 " htmlFor="BegDate">Beg Date:</label>
						<div className="col-lg-2 col-md-12 col-sm-12">
							<input
								disabled={!canEditPackage}
								className="form-control form-control-sm"
								type="date"
								min={toDatePickerString(latestApprovalDate)}
								{...register('STARTDATE', { required: true, onChange: beginDateChange })}
							/>

						</div>

						<label className="form-label col-lg-1 col-sm-12 " htmlFor="EndDate">End Date:</label>
						<div className="col-lg-2 col-md-12 col-sm-12">
							<input
								disabled={!canEditPackage}
								className="form-control form-control-sm"
								type="date"
								min={toDatePickerString(latestApprovalDate)}
								{...register('ENDDATE', { required: true })}
							/>
						</div>

						<label className="form-label col-lg-1 col-sm-12 " htmlFor="Status">Status:</label>
						<div className="col-lg-2 col-md-12 col-sm-12">
							<CodeSelector
								disabled={!canEditPackage}
								form={form}
								propertyName='STATUS'
								url='Codes/GetCodes?cde_type=NOMS'
							/>
						</div>

						<label className="form-label col-lg-1 col-sm-12 " htmlFor="UpPipeline">Up Pipeline</label>
						<div className="col-lg-2 col-md-12 col-sm-12">
							<input
								disabled
								placeholder='--'
								className='form-control form-control-sm'
								type='text'
								{...register('ACTIV_NUM.UP_PIPELINE_IDBAOBJ.SHORTNAME')}
							/>
						</div>
					</div>
					<div className="row mb-1">

						<label className="form-label col-lg-1 col-sm-12 " htmlFor="PackageNum">Nom ID:</label>
						<div className="col-lg-2 col-md-12 col-sm-12">
							<input
								disabled
								className="form-control form-control-sm text-muted"
								type='text'
								placeholder='--'
								{...register('PACKAGE_NUM')}
							/>
						</div>

						<label className="form-label col-lg-1 col-sm-12 " htmlFor="BegTime">Beg Time:</label>
						<div className="col-lg-2 col-md-12 col-sm-12">
							<input disabled id="BegTime" className="form-control form-control-sm text-muted" type="time" defaultValue={"09:00"} />
						</div>

						<label className="form-label col-lg-1 col-sm-12 " htmlFor="Cycle">Cycle:</label>
						<div className="col-lg-2 col-md-12 col-sm-12">
							<CodeSelector
								disabled
								form={form}
								propertyName='CYCLE'
								url='Codes/GetCodes?cde_type=NCYC'
							/>
						</div>

						<label className="form-label col-lg-1 col-sm-12 " htmlFor="TransType">Trans Type:</label>
						<div className="col-lg-2 col-md-12 col-sm-12">
							<CodeSelector
								disabled={!canEditPackage}
								form={form}
								propertyName='TT'
								url='Codes/GetCodes?cde_type=EBBTC'
							/>
						</div>
					</div>
					<div className="row mb-1">

						<label className="form-label col-lg-1 col-sm-12 " htmlFor="RecQty">Rec Qty:</label>
						<div className="col-lg-2 col-md-12 col-sm-12">
							<input
								disabled
								className="form-control form-control-sm text-muted"
								type="text"
								{...register('totalRcvQty')}
							/>
						</div>
						<label className="form-label col-lg-1 col-sm-12 " htmlFor="FuelQty">Fuel Qty:</label>
						<div className="col-lg-2 col-md-12 col-sm-12">
							<input
								disabled
								className="form-control form-control-sm text-muted"
								type="text"
								{...register('totalFuel')}
							/>
						</div>

						<label className="form-label col-lg-1 col-sm-12 " htmlFor="DelQty">Del Qty:</label>
						<div className="col-lg-2 col-md-12 col-sm-12">
							<div className="input-group">
								<input
									disabled
									className="form-control form-control-sm text-muted"
									type="text"
									{...register('totalDlvQty')}
								/>
							</div>
						</div>

						<label className="form-label col-lg-1 col-sm-12 " htmlFor="AvailableQty">Available Qty:</label>
						<div className="col-lg-2 col-md-12 col-sm-12">
							<div className="input-group">
								<input
									disabled
									className='form-control form-control-sm text-muted'
									type='text'
									{...register('availQty', { validate: value => value === 0 || 'Available Qty must be zero.' })}
								/>
							</div>
						</div>


					</div>
					{/* //LOCATION QUANTITIES: RECEIPT AND DELIVERY */}
					<div className="row mt-1">
						<div className='col'>
							<h6 className="text-start border-bottom border-primary border-lg-2 col-md-12 col-sm-12">Package Path Detail and Volume</h6>
							<h6 className="text-start">Receive</h6>
							<LocationQuantitiesTable form={form} flowDirection='R' currentModalStateName='addNomModalState' />
							<h6 className="text-start">Deliver</h6>
							<LocationQuantitiesTable form={form} flowDirection='D' currentModalStateName='addNomModalState' />
						</div>
					</div>

					<div className="col-lg-12 col-sm-12 ">
						<label className="form-label col-lg-1 col-sm-12 fs-6 mt-1 fw-bold">Comments:</label>
						<textarea className="form-control form-control-sm border border-primary" rows="1" {...register('COMMENTS')}></textarea>
					</div>
				</form>
			</div>
		</Modal>
	)
}

export default AddNom;