import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDownloadExcel } from 'react-export-table-to-excel';
// import addNew from '../../assets/images/add.gif';
import excel from '../../assets/images/excel.gif';
// import imbList from '../../assets/images/imb-list.jpg';
// import imbStatus from '../../assets/images/imb-status.png';

// import custom date functions
import {
	toDatePickerMonthString,
	toISOStringForDatabase,
	getMonthStart,
	toShortDate
} from '../../utils/DateUtils';

// import custom hooks
import useBusinessSelector from '../../hooks/useBusinessSelector';



const InvoiceSummary = () => {

	// local state
	const [componentState, setComponentState] = useState(
		{
			gasFlowDate: new Date(),
			contractType: "All Contracts",
			monthlyStatements: [],
			monthlyStatementsLoading: false,
			buID: '',
			selectedSupplier: {}
		}
	);

	// business selector hook
	useBusinessSelector(setComponentState);

	useEffect(() => {

		if (componentState.buID
			&& componentState.selectedSupplier.BA_ID
			&& componentState.gasFlowDate
			&& componentState.contractType) {

			console.log(componentState.gasFlowDate);

			const url = "InvoiceSummary/GetInvoiceSummaryList" +
				"?buId=" + componentState.buID +
				"&baId=" + componentState.selectedSupplier.BA_ID +
				"&gasFlowMonth=" + toISOStringForDatabase(getMonthStart(componentState.gasFlowDate)) +
				(componentState.contractType === "All Contracts" ? "" : "&contractType=" + componentState.contractType);

			const getMonthlyStatements = async () => {
				setComponentState(
					prevState => {
						return {
							...prevState,
							monthlyStatementsLoading: true
						}
					}
				)
				fetch(url).then(
					response => {
						return response.json()
					}
				).then(
					response => {
						setComponentState(
							prevState => {
								return {
									...prevState,
									monthlyStatements: response,
									monthlyStatementsLoading: false
								}
							}
						)
					}
				)
			};

			getMonthlyStatements();

		}

	},
		[
			componentState.gasFlowDate,
			componentState.contractType,
			componentState.selectedSupplier,
			componentState.buID
		]);

	// tableRef for Excel Export:
	const tableRef = useRef();

	//Handling the Excel Download:
	const { onDownload } = useDownloadExcel({
		// currentTableRef: tableRef.current,
		currentTableRef: tableRef.current,
		filename: 'Invoice-Summary-Report',
		sheet: 'Invoice-Summary-Table'
	});

	return (
		<div className='container-fluid'>
			<div className="text-black table-responsive">
				<div className='card mt-2'>
					<div className='card-header text-center'><h4>Invoice Summary</h4></div>
					<div className='card-body'>
						<div className="row">
							<div className="col-sm-12 col-md-12 col-lg-12 p-2">
								<button
									className='btn btn-md btn-primary mb-2 me-2'
									onClick={onDownload}
								>
									<img src={excel} alt='Export Excel Report' />
									Excel Report
								</button>
							</div>
						</div>
						{
							// Filters
							<div className='row mb-1 bg-light-blue rounded p-2 rounded'>
								<h6>Display Filters</h6>
								<div className="col-lg-2 col-md-2 col-sm-12 text-start">
									<label className='form-label'>Gas Flow Month</label>
									<input
										className="form-control form-control-sm"
										type="month"
										value={toDatePickerMonthString(componentState.gasFlowDate)}
										onChange={
											(event) => {
												setComponentState(
													prevState => {
														return {
															...prevState,
															gasFlowDate: event.target.value
														}
													}
												)
											}
										}
										style={{ maxWidth: '180px' }}
									/>
								</div>
								<div className="col-lg-2 col-md-2 col-sm-12 text-start">
									<label className='form-label'>Contract Types</label>
									<select
										className='form-select form-select-sm'
										aria-label="select public or private"
										value={componentState.contractType}
										onChange={
											(event) => {
												setComponentState(
													prevState => {
														return {
															...prevState,
															contractType: event.target.value
														}
													}
												)
											}
										}
									>
										<option>All Contracts</option>
									</select>
								</div>
							</div>
						}
						{
							// DCQ Table
							componentState.monthlyStatementsLoading
								?
								<div className="container-fluid">
									<div className="row justify-content-center">
										<div className="col-auto">
											<p>Loading...</p>
										</div>
										<div className="col-auto">
											<div
												className="spinner-border spinner-border-sm"
												role="status"
												aria-hidden="true">
											</div>
										</div>
									</div>
								</div>
								:
								(
									componentState.monthlyStatements.length > 0
										?
										<div className='table-responsive'>
											<div className="d-flex"><p className='pe-5'>Invoice Details</p></div>
											<table className="bg-white table table-sm table-hover table-striped-columns table-bordered border-primary rounded pb-1"
												ref={tableRef}>
												<thead>
													<tr>
														<th>Marketer</th>
														<th>CNTR_TITLE</th>
														<th>Pool ID</th>
														<th>CHG CD</th>
														<th>Description</th>
														<th>UNITS</th>
														<th>COST</th>
														<th>ADJ</th>
														<th>STMT</th>
														<th>Updated On</th>
														<th>Updated By</th>
													</tr>
												</thead>
												<tbody>
													{
														componentState.monthlyStatements.map(
															(statement,index) => {
																return (
																	<tr key={index}>
																		<td>{statement.Marketer}</td>
																		<td>{statement.CNTR_TITLE}</td>
																		<td>{statement.PoolId}</td>
																		<td>{statement.CHG_REF_CDE}</td>
																		<td>{statement.BUSINESS_SEGMENT}</td>
																		<td>{statement.UNIT_MEAS}</td>
																		<td>{statement.COST}</td>
																		<td>{statement.MANUAL_IND}</td>
																		<td>{statement.STATEMENT_NUM}</td>
																		<td>{toShortDate(statement.DATESTAMP)}</td>
																		<td>{statement.USERSTAMP}</td>
																	</tr>
																)
															}
														)
													}
												</tbody>
											</table>
										</div>
										:
										<p className="text-center">No Records Found.</p>
								)
						}
					</div>
				</div>
			</div >
		</div>
	);
};

export default InvoiceSummary;