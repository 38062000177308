import React from 'react';
import excel from '../../../../assets/images/excel.gif';
import * as XLSX from 'xlsx';
import { formatDate } from "../../../../utils/DateUtils";
import { isString, isNumber } from '../../../../utils/DateUtils';

function transformData(data) {
    return data.map(row => ({
        // ... Add all the Table's"Column" fields that you want to include in the export
        Agmt: row?.AgreementKey,
        Supplier: row?.SupplierName,
        Producer: row?.ProducerName,
        ProdDate: row?.TransDate ? `${new Date(row.TransDate).getDate()}-${new Date(row.TransDate).toLocaleDateString('en-US', { month: 'long' })}-${new Date(row.TransDate).getFullYear()}` : "",
        PointID: row?.PointShortName,
        'Location Name': row?.PT_NAME,
        Pod: row?.Pod,
        Bubble: row?.Bubble,
        OnDate: row?.OnDate ? formatDate(row.OnDate) : "",
        /*OffDate: row?.OffDateString,*/
        GrMcfs: row?.GR_VOLUME,
        NtMcfs: row?.NT_VOLUME,
        GrDth: row?.GR_ENERGY,
        NtDth: row?.NT_ENERGY,
        "G/PREP": row?.DEHY,
        "G/PGAS": row?.DEHYW,
        Partic: row?.Partic,
        Amount: row?.GrRev,
        Capac: row?.CAPAC,
        Gather: row && row.GATHER && isNumber(row.GATHER) ? Math.round(row.GATHER * 100) / 100 : 0,
        Extract: row?.EXTRACT,
        TotalDed: row?.TotalDeducts,
        UpdatedBy: row?.UpdatedBy,
        UpdatedOn: row?.UpdatedOn ? formatDate(row.UpdatedOn) : "",
    }));
}


function createExcelFile(data) {
    // Truncate the cell contents if they exceed the maximum allowed length
    const truncatedData = data.map(row =>
        Object.fromEntries(
            Object.entries(row).map(([key, value]) => [key, isString(value) ? value.toString().substring(0, 32767) : value])
        )
    );

    const worksheet = XLSX.utils.json_to_sheet(truncatedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
}

// Do NOT delete this code, this code is for unfiltered tables:
// function downloadExcelFile(data) {
//     const link = document.createElement('a');
//     const blob = createExcelFile(data);
//     const url = URL.createObjectURL(blob);
//     link.href = url;
//     link.download = 'Measurement_Report.xlsx';
//     link.click();
//     setTimeout(() => {
//         URL.revokeObjectURL(url);
//     }, 100);
// }
function downloadExcelFile(data) {
    //Functions for calculating totals:
    const totalNtMcf = data.reduce((total, row) => total + (row?.NT_VOLUME || 0), 0);
    const totalGrMcfs = data.reduce((total, row) => total + (row?.GR_VOLUME || 0), 0);
    const totalGrEnergy = data.reduce((total, row) => total + (row?.GR_ENERGY || 0), 0);
    const totalNtDth = data.reduce((total, row) => total + (row?.NT_ENERGY || 0), 0);
    const totalAmount = data.reduce((total, row) => total + (row?.GrRev || 0), 0);
    const totalCapac = data.reduce((total, row) => total + (row?.CAPAC || 0), 0);
    const totalGather = data.reduce((total, row) => total + (row?.GATHER || 0), 0);
    const totalExtract = data.reduce((total, row) => total + (row?.EXTRACT || 0), 0);
    const totalDeducts = data.reduce((total, row) => total + (row?.TotalDeducts || 0), 0);

    //Create a total row:
    const totalRow = {
        AgreementKey: 'Total:',
        NT_VOLUME: totalNtMcf,
        GR_VOLUME: totalGrMcfs,
        GR_ENERGY: totalGrEnergy,
        NT_ENERGY: totalNtDth,
        GrRev: totalAmount,
        CAPAC: totalCapac,
        GATHER: totalGather,
        EXTRACT: totalExtract,
        TotalDeducts: totalDeducts,
        // ... add other totals here
    };

    const allData = [...data, totalRow];
    const transformedData = transformData(allData);
    const link = document.createElement('a');
    const blob = createExcelFile(transformedData);
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = 'Measurement_Report.xlsx';
    link.click();
    setTimeout(() => {
        URL.revokeObjectURL(url);
    }, 100);
}

const Export = ({ onExport }) => (
    <button className="btn btn-md btn-primary me-2 mb-1" onClick={() => onExport()}>
        <img src={excel} alt="Export Excel Report" /> Export
    </button>
);

const ExportExcel = ({ data }) => {
    const actionsMemo = React.useMemo(() => <Export onExport={() => downloadExcelFile(data)} />, [data]);

    return actionsMemo;
};

export default ExportExcel;
