import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { physical_trade_deals_actions } from '../../../store/physical-trade-deals-slice';

import CustomStyles from './SSM-Table-Style';
import Spinner from '../../Spinner';

function Table({ points, loading, totalRows, pageSize, handlePageChange }) {

	// Redux
	const dispatch = useDispatch();
	const selectedBidPkg = useSelector(state => state.physical_trade_deals_slice.selectedBidPkg);

	const columns = [
		{
			cell: row => <input type="checkbox" checked={row.PT_NUM === selectedBidPkg?.START_PT_NUM?.PT_NUM} onChange={() => { handleRowClicked(row) }} />,
			allowOverflow: true,
			button: true,
		},
		{
			name: 'Point Name',
			selector: row => row.PT_NAME,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Point ID',
			selector: row => row.POINT_ID,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Point Type',
			selector: row => row.PT_TYPE,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true

		}
	];

	// #region Row Selection

	const handleRowClicked = (row) => {
		if (selectedBidPkg?.START_PT_NUM?.PT_NUM === row?.PT_NUM) {
			dispatch(physical_trade_deals_actions.setOpenPointModal(false));
			return;
		}
		// update START_PT_NUM in selected bid package
		dispatch(physical_trade_deals_actions.setSelectedBidPkg({
			...selectedBidPkg,
			START_PT_NUM: row,
			END_PT_NUM: row.PT_NUM, // all three of these properties must be set for the bid package to save - Cameron
			DeliveryPoint: row
		}));
		// hide modal after row is finished selecting
		dispatch(physical_trade_deals_actions.setOpenPointModal(false));
	};
	// #endregion

	return (
		<DataTable
			progressPending={loading}
			progressComponent={<Spinner status='loading' />}
			className='table-striped pb-1'
			fixedHeader={true}
			fixedHeaderScrollHeight="700px"
			columns={columns}
			data={points}
			striped={true}
			highlightOnHover={true}
			customStyles={CustomStyles}
			onRowClicked={handleRowClicked}
			pagination
			//paginationServer // not doing server side pagination so search bar can work with pagination, previously can only search the current page
			//paginationTotalRows={totalRows}
			//paginationPerPage={pageSize}
			paginationComponentOptions={{
				noRowsPerPage: true
			}}
			//onChangePage={handlePageChange}
			paginationTotalRows={points ? points.length : 0}
			paginationPerPage={50}
			dense={true}
		/>
	);
};

export default Table;