import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isOpen: false,
	message: '',
	header:''
};

const notification_slice = createSlice({
	name: 'notification_slice',
	initialState,
	reducers: {
		showModal(state, action) {
			const { message, header } = action.payload;
			state.message = message;
			state.header = header;
			state.isOpen = true;
		},
		closeModal(state) {
			state.isOpen = false;
		}
	}
});

export const displayError = (message) => {
	return async (dispatch) => {
		await dispatch(notification_actions.showModal({ header: 'Error', message }));
	}
};

export const displaySuccess = (message) => {
	return async (dispatch) => {
		await dispatch(notification_actions.showModal({ header: 'Success!', message }));
	}
};

export const notification_actions = notification_slice.actions;
export default notification_slice.reducer;