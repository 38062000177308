import React, { useState, useCallback, useEffect } from 'react';
import {
  toDatePickerMonthString,
  getDataInDateRange,
    getFromAndToData,
  endDateHandler
} from '../../../utils/DateUtils';
import { useSelector, useDispatch } from 'react-redux';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const ProductionReport = () => {
    const selectedSupplier = useSelector(state => state.business_selector_slice.selectedSupplier);
    const [toDate, setToDate] = useState(new Date(`${new Date().getMonth() + 1}/${endDateHandler(new Date().getMonth(), new Date().getFullYear())}/${new Date().getFullYear()}`));
    const [fromDate, setFromDate] = useState(
        new Date(`${new Date().getMonth() + 1}/01/${new Date().getFullYear()}`)
  );
  const [filesData, setFilesData] = useState([]);

  // Handlers
  const fromDateHandler = (event) => {
      const from_date = new Date(event.target.value);
      let dateString = new Date(
          Date.parse(from_date) + 1000 * 60 * 60 * 48
      );
      dateString = new Date(`${dateString.getMonth() + 1}/01/${dateString.getFullYear()}`).toISOString();
      setFromDate(dateString);
      getFiles(dateString, toDate);
  };

  const toDateHandler = (event) => {
    let to_date = new Date(event.target.value);
      to_date = new Date(
          Date.parse(to_date) + 1000 * 60 * 60 * 48
      );
      const dateString = new Date(`${to_date.getMonth() + 1}/${endDateHandler(to_date.getMonth(), to_date.getFullYear())}/${to_date.getFullYear()}`).toISOString();
      setToDate(dateString);
      getFiles(fromDate, dateString);
  };

    const getFiles = async (fromDateString, toDateString) => {
        const response = await fetch(`InvoiceSummary/GetProductionReports?startDate=${typeof fromDateString === 'string' ? fromDateString : fromDate.toISOString()}&endDate=${typeof toDateString === 'string' ? toDateString : toDateString.toISOString() }&producerName=${encodeURI(selectedSupplier?.NAME ?? '')}`, {
            method: 'GET',
        });

        if (!response.ok) {
            const message = await response.text();
            throw new Error(message);
        }

        const files = await response.json();
        setFilesData(
            files?.sort((a, b) => {
                const dateKeyA = a?.date_key ?? '';
                const dateKeyB = b?.date_key ?? '';

                if (Date.parse(dateKeyA) > Date.parse(dateKeyB)) {
                    return 1;
                } else if (Date.parse(dateKeyA) < Date.parse(dateKeyB)) {
                    return -1;
                }
                return 0;
            })
        );
    }

    useEffect(() => {
        getFiles(fromDate, toDate);
    }, []);

  return (
    <div className="container-fluid" style={{ marginBottom: '20px' }}>
      <div className="text-black">
        <div className="card mt-1">
          <div className="card-header text-center">
            <h4>Production Reports</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-2">
                <label className="ms-1 me-1">From</label>
                <input
                  className="form-control form-control-sm"
                  type="month"
                  value={toDatePickerMonthString(fromDate)}
                  onChange={fromDateHandler}
                />
              </div>
              <div className="col-sm-2">
                <label className="ms-1 me-1">To</label>
                <input
                  className="form-control form-control-sm"
                  type="month"
                  value={toDatePickerMonthString(toDate)}
                  onChange={toDateHandler}
                />
              </div>
            </div>
            <div className="row mt-4">
              {getFromAndToData(filesData, fromDate, toDate).map(
                (file) => {
                  return (
                    <div className="row py-2">
                      <h4>
                        {`${
                          months[new Date(file?.date_key ?? '').getMonth()]
                            ? months[new Date(file?.date_key ?? '').getMonth()]
                            : 'Unknown'
                        } ${new Date(file?.date_key ?? '').getFullYear()}`}
                      </h4>
                      {getDataInDateRange(
                        filesData,
                        file
                      ).map((item) => {
                        return (
                          <div
                            className="d-inline-flex w-100 pb-2"
                            style={{
                              borderBottom: 'solid #e1e1e1 1px',
                              fontSize: '18px',
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="50px"
                              height="50px"
                              viewBox="0 0 32 32"
                            >
                              <title>file_type_excel2</title>
                              <path
                                d="M28.781,4.405H18.651V2.018L2,4.588V27.115l16.651,2.868V26.445H28.781A1.162,1.162,0,0,0,30,25.349V5.5A1.162,1.162,0,0,0,28.781,4.405Zm.16,21.126H18.617L18.6,23.642h2.487v-2.2H18.581l-.012-1.3h2.518v-2.2H18.55l-.012-1.3h2.549v-2.2H18.53v-1.3h2.557v-2.2H18.53v-1.3h2.557v-2.2H18.53v-2H28.941Z"
                                style={{
                                  fill: '#20744a',
                                  fillRule: 'evenodd',
                                }}
                              />
                              <rect
                                x="22.487"
                                y="7.439"
                                width="4.323"
                                height="2.2"
                                style={{ fill: '#20744a' }}
                              />
                              <rect
                                x="22.487"
                                y="10.94"
                                width="4.323"
                                height="2.2"
                                style={{ fill: '#20744a' }}
                              />
                              <rect
                                x="22.487"
                                y="14.441"
                                width="4.323"
                                height="2.2"
                                style={{ fill: '#20744a' }}
                              />
                              <rect
                                x="22.487"
                                y="17.942"
                                width="4.323"
                                height="2.2"
                                style={{ fill: '#20744a' }}
                              />
                              <rect
                                x="22.487"
                                y="21.443"
                                width="4.323"
                                height="2.2"
                                style={{ fill: '#20744a' }}
                              />
                              <polygon
                                points="6.347 10.673 8.493 10.55 9.842 14.259 11.436 10.397 13.582 10.274 10.976 15.54 13.582 20.819 11.313 20.666 9.781 16.642 8.248 20.513 6.163 20.329 8.585 15.666 6.347 10.673"
                                style={{
                                  fill: '#ffffff',
                                  fillRule: 'evenodd',
                                }}
                              />
                            </svg>
                            <div className="d-flex justify-content-between w-100 align-items-center">
                              <div>{item?.str_key1 ?? 'Unknown Name'}</div>
                              <div>
                                <a
                                  style={{ marginRight: '25px' }}
                                  href={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${item?.str_data1}`}
                                  target="_blank"
                                  download={item?.str_key1}
                                >
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductionReport;
