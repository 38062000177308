
// redux
import { useDispatch } from 'react-redux';
import { revokeToken } from '../../store/auth-slice';

// react router dom
import { useNavigate } from 'react-router-dom';

const Logout = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutHandler = async () => {
    await dispatch(revokeToken());
    navigate('/');
  };

  return (
    <div className="container-lg">
      <div className="row justify-content-center">
        <div className="col-12 text-white text-center">
          <button
            className='btn btn-sm btn-danger fs-6 '
            onClick={logoutHandler}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Logout;