import { Margin, usePDF } from 'react-to-pdf';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import useBusinessSelectorRedux from '../../../../../hooks/useBusinessSelectorRedux';
//import { fetchEfsReport } from '../../../../../store/efsReportSlice';
import { non_path_noms_actions } from '../../../../../store/non-path-noms-slice';

import { getDayStart, toISOStringForDatabase, toDatePickerMonthString, getMonthStart } from '../../../../../utils/DateUtils';

import OBAStatementTable from '../../Tables/OBAStatementTable';
import OBAByMidTable from '../../Tables/OBAByMidTable';
import OBAInventoryTable from '../../Tables/OBAInventoryTable';
import OBAReport from './OBAReport';
import ContractSelector from '../../../Selectors/ContractSelector';

//Modal Styling:
import './OBAStatementModalStyle.css';

const customStyles = { overlay: { backgroundColor: 'rgb(0,0,0,.60' } };


function OBAStatementModal() {

    // Redux
    const dispatch = useDispatch();
    const NonPathNomsFilters = useSelector(state => state.non_path_noms_slice.NonPathNomsFilters);
    const OBAStatementModalState = useSelector(state => state.non_path_noms_slice.modalStates.obaStatementModalState);
    const selectedSupplier = useSelector(store => store.business_selector_slice.selectedSupplier.BA_ID);
    const { open, selectedContract } = OBAStatementModalState;

    // local state
    const [showStatementReport, setShowStatementReport] = useState(true); // default OBA Statement Report to display
    const [showByMidReport, setShowByMidReport] = useState(false);
    const [showInventoryReport, setShowInventoryReport] = useState(false);
    const [localGasFlowDate, setLocalGasFlowDate] = useState(NonPathNomsFilters?.gasFlowDate);

    // pdf logic
    const dateObject = new Date(localGasFlowDate);
    const month = dateObject.getUTCMonth() + 1;
    const year = dateObject.getUTCFullYear();
    const monthYear = new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format(new Date(year, month - 1));
    const formattedDate = monthYear.replaceAll(' ', '');
    const { toPDF, targetRef } = usePDF({
        filename:
            showStatementReport ? 'OBAStatementReport' + formattedDate + '.pdf'
                : showByMidReport ? 'OBAByMidReport' + formattedDate + '.pdf'
                    : showInventoryReport ? "OBAInventoryReport" + formattedDate + ".pdf"
                        : "",
        page: { margin: Margin.MEDIUM },
    });

    // selectors
    const setContract = (newValue) => {
        dispatch(non_path_noms_actions.changeModalState({
            modalStateName: 'obaStatementModalState',
            newModalState: { ...OBAStatementModalState, selectedContract: newValue }
        }));
    }

    // Date picker logic

    useEffect(() => {
        if (open) {
            setLocalGasFlowDate(NonPathNomsFilters?.gasFlowDate);
            setShowStatementReport(true); // default OBA Statement Report to display
            setShowByMidReport(false);
            setShowInventoryReport(false);
        }
    }, [open, NonPathNomsFilters?.gasFlowDate]);

    const setGasFlowDate = (event) => {
        const newGasFlowDate = getMonthStart(event.target.value).toISOString();
        setLocalGasFlowDate(newGasFlowDate);
    };
    /*const setGasFlowDate = (event) => {
        const newGasFlowDate = toISOStringForDatabase(getMonthStart(event.target.value));
        dispatch(non_path_noms_actions.changeModalState({
            modalStateName: 'obaStatementModalState',
            newModalState: { ...OBAStatementModalState, gasFlowDate: newGasFlowDate }
        }));
    };*/

    const closeModal = () => {
        dispatch(non_path_noms_actions.changeModalState({
            modalStateName: 'obaStatementModalState',
            newModalState: { ...OBAStatementModalState, open: false }
        }));
    };

    const handleReportChange = (reportName) => {
        // Reset all states to false
        setShowStatementReport(false);
        setShowByMidReport(false);
        setShowInventoryReport(false);

        // Set the state for the clicked report
        if (reportName === "Statement") {
            setShowStatementReport(true);
        } else if (reportName === "ByMid") {
            setShowByMidReport(true);
        } else if (reportName === "Inventory") {
            setShowInventoryReport(true);
        }
    };

    return (
        <div>
            <Modal
                isOpen={open}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="OBA Statement Modal"
                className='oba-statement-modal container-fluid'
            >
                <div>
                    <div className='modal-header mt-2'>
                        <h5>OBA Statement</h5>
                        <button className='btn-close' onClick={closeModal} />
                    </div>
                    <div className='modal-body'>
                        <button className="btn btn-md btn-primary me-2 mb-1" onClick={() => toPDF()}>Download PDF</button>
                        <button className={`btn btn-md me-2 mb-1 ${showStatementReport ? "btn-primary" : "btn-secondary"}`}
                            onClick={() => handleReportChange("Statement")}>
                            OBA Statement
                        </button>
                        <button className={`btn btn-md me-2 mb-1 ${showByMidReport ? "btn-primary" : "btn-secondary"}`}
                            onClick={() => handleReportChange("ByMid")}>
                            OBA By Mid
                        </button>
                        <button className={`btn btn-md me-2 mb-1 ${showInventoryReport ? "btn-primary" : "btn-secondary"}`}
                            onClick={() => handleReportChange("Inventory")}>
                            OBA Inventory
                        </button>
                        {/* Filters section */}
                        <div className="row bg-light-blue text-dark p-2">
                            <h5>Display Filters:</h5>
                            {/* // FILTERS */}
                            <div className="col-lg-2 col-md-2 col-sm-12 me-5">
                                <form>
                                    <label>
                                        Gas Flow Date:
                                        <br />
                                        <input
                                            className="form-control form-control-sm"
                                            type="month"
                                            value={toDatePickerMonthString(localGasFlowDate)}
                                            onChange={setGasFlowDate}
                                            min="2023-11" // november 2023 devops 4666
                                        />
                                    </label>
                                </form>
                            </div>
                            <div className="col-lg-3 col-md-2 col-sm-12 me-5">
                                <form>
                                    <label>
                                        Contract:
                                        <br />
                                        <ContractSelector
                                            url={`Contract/GetContracts?cntr_type=OB&cntr_party1=${selectedSupplier}`}
                                            value={selectedContract}
                                            setValue={ setContract }
                                        />
                                    </label>
                                </form>
                            </div>
                        </div>

                        <div class="d-flex">
                            <div ref={targetRef}>
                                <OBAReport
                                    date={ localGasFlowDate }
                                    title={
                                        showStatementReport ? "Statement of OBA Activity"
                                            : showByMidReport ? "OBA MID by GBA & Contract"
                                                : showInventoryReport ? "OBA Inventory"
                                                    : null}
                                    table={
                                        showStatementReport ? OBAStatementTable
                                            : showByMidReport ? OBAByMidTable :
                                                showInventoryReport ? OBAInventoryTable
                                                    : null}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        </div >
    );
}

export default OBAStatementModal;
