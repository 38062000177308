import { useEffect, useState } from 'react';
import logo1 from '../../assets/images/gastar-logo.png';
import './modal-style.css';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { revokeToken } from '../../store/auth-slice';
import { displayError } from '../../store/notification-slice';
import { useCallback } from 'react';


// Function to fetch idle timeout from code table in database.
const fetchIdleTimeout = () => {
	return async (dispatch) => {
		try {
			const response = await fetch('Codes/GetCode?cde_type=EXPR&cde=SESS');

			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			const code = await response.json();
			const minutes = code?.DEFAULT_PVOL;
			return minutes;
		}
		catch (error) {
			await dispatch(displayError(error.message));
		}
	}
};

const shouldLog = false; // Should this component log idle state or not?

const IdleTimeoutModal = () => {

	// Redux
	const dispatch = useDispatch();
	const isLoggedIn = useSelector(state => state.auth_slice.isLoggedIn);

	// State
	const [isOpen, setIsOpen] = useState(false);
	const [message, setMessage] = useState();
	const [timeout, setTimeout] = useState(1200000); // Default 20 minutes.

	// Idle timer hook
	const {
		getRemainingTime,
		isIdle,
		pause,
		activate,
		getLastActiveTime
	} = useIdleTimer({
		onIdle: () => {
			if (shouldLog) console.log('User has been logged out for inactivity.');
			dispatch(revokeToken());
			setMessage('You have been logged out due to inactivity.');
			if (!isOpen) setIsOpen(true);
		},
		onPrompt: () => {
			if (shouldLog) console.log('Prompting user...');
			updateMessage();
			if (!isOpen) setIsOpen(true);
		},
		onAction: (event) => {
			//if (shouldLog) console.log('Action detected: ', event.type);
			//const elapsedTime = Math.ceil(getTotalIdleTime() / 1000);
			//if (shouldLog) console.log(`Elapsed: ${elapsedTime} seconds.`);
			const now = new Date().getTime();
			const lastActiveTime = getLastActiveTime();
			const elapsedTime = Math.ceil((now - lastActiveTime) / 1000);
			//const elapsedTime = getLastActiveTime();
			const minutes = Math.floor(elapsedTime / 60);
			const seconds = elapsedTime % 60;
			if (shouldLog) console.log(`Action detected: ${event.type}. Elapsed Time: ${minutes} minutes ${seconds} seconds.`);
		},
		timeout,
		promptBeforeIdle: timeout > 30000 ? 30000 : timeout / 2,
		startManually: true,
		stopOnIdle: true
	});

	// Function to update message with remaining time. Must be in useCallback to prevent infinite loop.
	const updateMessage = useCallback(() => {
		const remainingTime = Math.ceil(getRemainingTime() / 1000);
		const newMessage = `Logging out in ${remainingTime} seconds.`;
		setMessage(newMessage);
		if (shouldLog) console.log(`Idle message has been updated to "${newMessage}"`);
	}, [getRemainingTime]);

	// Fetch the idle timeout from code table in database.
	useEffect(() => {
		if (isLoggedIn) {
			const handleAsync = async () => {
				const minutes = await dispatch(fetchIdleTimeout());
				if (minutes) {
					//setTimeout(1 * 60 * 1000);
					setTimeout(minutes * 60 * 1000);
					if (shouldLog) console.log(`Idle timeout time has been fetched. New timeout is ${minutes} minutes.`);
				}
			};
			handleAsync();
		}
	}, [isLoggedIn, dispatch]);

	// Start timer on login, stop it on logout.
	useEffect(() => {
		if (isLoggedIn) {
			if (shouldLog) console.log('Activating idle timer...');
			activate();
		}
		else {
			if (shouldLog) console.log('Pausing idle timer...');
			pause();
		}
	}, [isLoggedIn, activate, pause]);

	// useEffect for updating message
	useEffect(() => {
		// If the message modal is not open, it is not necessary to update the display message.
		if (!isOpen) return;

		// Else, create a new interval that will update the message every second to display the remaining time.
		const intervalId = setInterval(() => {
			if (isIdle()) clearInterval(intervalId); // If user is considered idle, stop the interval.
			else updateMessage(); // Else, update the message.
		}, 1000);

		// Clean up
		return () => { clearInterval(intervalId); };
	}, [getRemainingTime, isOpen, isIdle, updateMessage]);

	const closeModal = () => {
		setIsOpen(false);

		// If they close the message while they are logged in, re-activate the timer.
		if (isLoggedIn) activate();
	};

	return (
		<>
			<div
				className={`modal-backdrop ${isOpen ? 'show' : ''}`}
				style={{
					display: `${isOpen ? 'block' : 'none'}`,
					zIndex: 9999,
				}}
			>
			</div>
			<div
				className={`modal ${isOpen ? 'show' : ''}`}
				style={{
					display: `${isOpen ? 'block' : 'none'}`,
					zIndex: 9999
				}}
				aria-hidden="true"
				aria-labelledby="AddCustomer Report"
				data-backdrop='true'
				tabIndex="-1"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content"
					>
						<div className="modal-header bg-primary">
							<h1 className="fs-5 text-white w-100 text-center" >Are you still there?</h1>
						</div>
						<form>
							<div
								className="modal-body justify-content-center text-center fs-5"
								style={{
									wordWrap: 'break-word',
									maxHeight: '600px',
									overflowY: 'auto'
								}}
							>
								<img src={logo1} alt='Gastar Logo' className='img-fluid mb-4' style={{ maxWidth: '200px' }} />
								<br />
								<h2 className='fs-3'>{message}</h2>
							</div>
							<div className="modal-footer d-flex justify-content-center">
								{
									isIdle() ?
										<button
											type='button'
											className='btn btn-lg btn-primary fs-5'
											onClick={closeModal}
										>
											Close
										</button> :
										<button
											type='button'
											className='btn btn-lg btn-primary fs-5'
											onClick={closeModal}
										>
											I'm still here!
										</button>
								}
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);

};

export default IdleTimeoutModal;
