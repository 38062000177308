
const CustomStyles = {
	table: {
		style: {
			border: '1px solid blue',
			striped: 'default',
		},
	},
	headRow: {
		style: {
			fontSize: ".8rem",
			paddingLeft: '0px'
			// backgroundColor: 'blue'
		},
	},
	cells: {
		style: {
			fontSize: '.8rem',
			border: '1px solid blue',
			paddingLeft: '1px'
		}
	}
}

export default CustomStyles