import { useState } from 'react';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddImage from '../../../assets/images/add.gif';
import Cancel from '../../../assets/images/cancel.png';
import Copy1 from '../../../assets/images/copy1.gif';
import Delete from '../../../assets/images/delete.png';
import Save from '../../../assets/images/save.png';
import useErrorStyling from '../../../hooks/useErrorStyling';
import {
	createFinPosition,
	deleteFinPosition,
	fetchContract,
	financial_position_actions,
	updateFinPosition
} from '../../../store/financial-position-slice';
import {
	getDayStart,
	getMonthEnd,
	getMonthStart,
	getNextMonthEnd,
	getNextMonthStart,
	getNumberOfDays,
	setTimeToNine,
	toDatePickerDateTimeString,
	toDatePickerMonthString,
	toDatePickerString
} from '../../../utils/DateUtils';
import ConfirmActionModal from '../../CustomComponents/ConfirmActionModal';
import BASelector from '../../CustomComponents/ReactHookFormSelectors/BASelector';
import CodeSelector from '../../CustomComponents/ReactHookFormSelectors/CodeSelector';
import ContactSelector from '../../CustomComponents/ReactHookFormSelectors/ContactSelector';
import InstrumentSelector from '../../CustomComponents/ReactHookFormSelectors/InstrumentSelector';
import Spinner from '../../Spinner';



Modal.setAppElement('#root');

const calculateNumOfPositions = (dayVol, mdFlag, startdate, enddate) => {
	if ((!dayVol && dayVol !== 0) || !startdate || !enddate) return;
	let numOfPositions;
	if (mdFlag === 'D') {
		const numberOfDays = getNumberOfDays(startdate, enddate);
		numOfPositions = dayVol * numberOfDays / 10000;
	}
	else if (mdFlag === 'M') {
		numOfPositions = dayVol / 10000;
	}
	else {
		return;
	}
	const numOfPositionsRounded = Math.round(numOfPositions * 1000000) / 1000000;// Round to six decimals.
	if (!isNaN(numOfPositionsRounded)) return numOfPositionsRounded;
};


function NewFinancialPosition() {

	// State
	const [loading, setLoading] = useState(false);
	const [confirmActionModalState, setConfirmActionModalState] = useState({
		isOpen: false,
		header: '',
		message: '',
		action: () => { },
		acceptActionText: '',
		closeModal: () => { setConfirmActionModalState(prevState => ({ ...prevState, isOpen: false })); }
	});

	// Redux
	const businessUnit = useSelector(state => state.business_selector_slice.businessUnit);
	const dispatch = useDispatch();
	const userContact = useSelector(state => state.auth_slice.userContact);
	const selectedFinPosition = useSelector(state => state.financial_position_slice.selectedFinPosition);

	// React Hook Form:
	const form = useForm();
	const { register, reset, setValue, watch, handleSubmit, formState, getValues } = form;
	const { isSubmitted } = formState;

	// Navigation
	const navigate = useNavigate();

	// Form error styling:
	useErrorStyling('new-trade-form', form);

	// Control what the default values for the form should be.
	const defaultValues = useMemo(() => {
		// If there is a selected position, populate it's information.
		if (selectedFinPosition && Object.keys(selectedFinPosition)?.length > 0) {
			return {
				...selectedFinPosition,
				dealType: selectedFinPosition?.Instrument?.ContractType,
				DealMaker: selectedFinPosition?.USERSTAMP,
				STARTDATE: toDatePickerString(selectedFinPosition?.STARTDATE),
				ENDDATE: toDatePickerString(selectedFinPosition?.ENDDATE),
				TRADE_DATE: toDatePickerDateTimeString(selectedFinPosition?.TRADE_DATE),
				DELIVERY_MONTH: toDatePickerMonthString(selectedFinPosition?.DELIVERY_MONTH)
			};
		}
		// Else, populate new information.
		return {
			Instrument: {},
			dealType: '',
			Company: businessUnit,
			DealMaker: userContact?.EbbUserLogin?.USER_ID,
			ClearedCode: {},
			USERSTAMP: '',
			DATESTAMP: null,
			CounterParty: {},
			Contract: {},
			Trader: {},
			BROKER_FEE: 0,
			ConfirmParty: {},
			PortfolioCode: {},
			FIN_POSITION_NUM: '',
			CONTRACT_BOUGHT: 0,
			DirectionCode: {},
			PUT_CALL: '',
			DAY_VOL: null,
			STARTDATE: toDatePickerString(getNextMonthStart(new Date())),
			ENDDATE: toDatePickerString(getNextMonthEnd(new Date())),
			DELIVERY_MONTH: toDatePickerMonthString(getNextMonthStart(new Date())),
			INSTR_PRICE: null,
			SPRICE_PRICE: 0,
			Contact_NAME: '',
			BROKER: {},
			MD_FLAG: 'M',
			TRADE_DATE: toDatePickerDateTimeString(setTimeToNine(new Date()))
		};
	}, [businessUnit, userContact, selectedFinPosition]);

	// Reset the form based on certain dependencies changing.
	useEffect(() => { reset(defaultValues); }, [reset, defaultValues]);

	// React Hook Form watches.
	const counterPartyWatch = watch('CounterParty');
	const dealTypeWatch = watch('dealType');
	const dayVolWatch = watch('DAY_VOL');
	const startDateWatch = watch('STARTDATE');
	const endDateWatch = watch('ENDDATE');
	const mdFlagWatch = watch('MD_FLAG');

	// Calculate number of positions
	useEffect(() => {
		const newNumOfPositions = calculateNumOfPositions(dayVolWatch, mdFlagWatch, startDateWatch, endDateWatch);
		if (!newNumOfPositions || isNaN(newNumOfPositions)) return;
		setValue('CONTRACT_BOUGHT', newNumOfPositions, { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });
	}, [dayVolWatch, mdFlagWatch, startDateWatch, endDateWatch, isSubmitted]);

	// Find FT contract for new counter party.
	useEffect(() => {
		const handleAsync = async () => {
			const newContract = await dispatch(fetchContract(counterPartyWatch));
			setValue('Contract', newContract, { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });
		};
		handleAsync();
	}, [counterPartyWatch, isSubmitted, setValue, dispatch]);

	// Handlers
	const submitData = async (formData) => {
		setLoading(true);
		if (formData?.FIN_POSITION_NUM)
			await dispatch(updateFinPosition(formData));
		else
			await dispatch(createFinPosition(formData));
		setLoading(false);
	};

	const cancelClick = () => { window.history.back(); };

	// Set selected fin position to blank object. This will run the useMemo above that creates the default values.
	const newFinPositionClick = () => { dispatch(financial_position_actions.setSelectedFinPosition({})); };

	const deleteClick = async () => {
		try {
			setLoading(true);
			const fin_position_num = getValues('FIN_POSITION_NUM');
			if (!fin_position_num) throw new Error('FIN_POSITION_NUM was not defined.');
			await dispatch(deleteFinPosition(fin_position_num));
			navigate('/PositionInventory');
		}
		catch (error) {
			console.log(error);
		}
		finally {
			setLoading(false);
		}
	};

	const copyClick = () => { dispatch(financial_position_actions.setSelectedFinPosition({ ...selectedFinPosition, FIN_POSITION_NUM: '' })); };

	const startDateChange = (e) => {
		const newValue = e.target.value;
		setValue('STARTDATE', newValue, { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });
		setValue('ENDDATE', toDatePickerString(getMonthEnd(newValue)), { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });
		setValue('DELIVERY_MONTH', toDatePickerMonthString(getMonthStart(newValue)), { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });
	};

	const endDateChange = (e) => {
		const newValue = e.target.value;
		setValue('ENDDATE', newValue, { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });

		// If the new value of the end date comes before the existing start date value, set start date to new end date value.
		if (getDayStart(newValue).getTime() < getDayStart(startDateWatch).getTime()) {
			setValue('STARTDATE', newValue, { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });
			setValue('DELIVERY_MONTH', toDatePickerMonthString(getMonthStart(newValue)), { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });
		}
	};

	const deliveryMonthChange = (e) => {
		const newValue = e.target.value;
		const newStartdate = toDatePickerString(getMonthStart(newValue));
		const newEnddate = toDatePickerString(getMonthEnd(newValue));
		setValue('STARTDATE', newStartdate, { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });
		setValue('ENDDATE', newEnddate, { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });
	};

	return (
		<div className='container-fluid bg-white p-2'>
			<form className="modal-content new-trade-form" onSubmit={handleSubmit(submitData)}>
				<div className="modal-header text-center">
					<div className='col text-center mb-1'>
						<h5 className="modal-title">Create/Edit Financial Deals</h5>
					</div>
				</div>
				<div className="modal-body">
					{/* // BUTTONS */}
					<div className="row text-start">
						<div className="col-lg-12 col-md-12 col-md-12 col-sm-12">
							<div className="">
								<button
									type="button"
									className="btn btn-primary mt-1 m-1"
									title="New Deal"
									onClick={newFinPositionClick}
									disabled={loading}
								>
									<img src={AddImage} alt="AddImage" /> Add
								</button>
								<button
									className="btn btn-primary mt-1 m-1"
									title="New Deal"
									disabled={loading}
								>
									<img src={Save} alt="SaveImage" /> Save
								</button>
								<button
									type="button"
									className="btn btn-primary mt-1 m-1"
									title="New Deal"
									onClick={() => {
										setConfirmActionModalState({
											...confirmActionModalState,
											isOpen: true,
											header: 'Delete Confirmation',
											message: 'Are you sure you want to delete this item?',
											action: deleteClick,
											acceptActionText: 'Delete'
										});
									}}
									disabled={loading}
								>
									<img src={Delete} alt="DeleteImage" /> Delete
								</button>
								<button
									type="button"
									className="btn btn-primary mt-1 m-1"
									title="New Deal"
									onClick={cancelClick}
									disabled={loading}
								>
									<img src={Cancel} alt="AddImage" /> Cancel
								</button>
								<button
									type="button"
									className="btn btn-primary mt-1 m-1"
									title="New Deal"
									disabled={loading}
									onClick={copyClick}
								>
									<img src={Copy1} alt="AddImage" /> Copy
								</button>

								{loading && <Spinner />}

							</div>
						</div>
					</div>
					{/* START ROW 1 */}
					<div className='row bg-light-blue text-start p-1 m-0'>
						<div className="col-lg-2 col-md-2 col-sm-3 text-start ">

							<label className='form-label form-label text-start m-0'>Instrument ID</label>

							<InstrumentSelector
								className="form-select form-select-sm col-lg-1 col-sm-3 text-start"
								form={form}
								propertyName='Instrument'
								url='Hedging/RetrieveAllHedgeContracts'
								required
								afterSetValue={(newInstrument) => {
									if (newInstrument)
										setValue('dealType', newInstrument.ContractType, { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });
								}}
							/>

						</div>
						<div className="col-lg-2 col-md-2 col-sm-3 text-start">
							<label className="form-check-label text-start">Deal Cntr ID:</label>

							<CodeSelector
								className="form-select form-select-sm text-start"
								form={form}
								propertyName='dealType'
								url='Codes/GetCodes?cde_type=HEDGE'
								disabled
							/>

						</div>
						<div className="col-lg-2 col-md-2 col-sm-3 text-start">
							<label className='form-label m-0 text-start'>Company:</label>

							<input
								{...register('Company.SHORTNAME', { required: true })}
								type='text'
								className="form-control form-control-sm col-lg-1 col-md-1 col-sm-3 text-start"
								disabled
							/>

						</div>
						<div className="col-lg-2 col-md-2 col-sm-3 text-start">
							<label className='form-label m-0 text-start'>Deal Maker: </label>
							<input
								{ ...register('DealMaker') }
								type='text'
								className='form-control form-control-sm text-start'
								disabled
							/>
						</div>

						<div className="col-lg-2 col-md-2 col-sm-3 text-start">
							<label className='form-label m-0 text-start'>Cleared: </label>

							<CodeSelector
								form={form}
								propertyName='ClearedCode'
								valueType='object'
								url='Codes/GetCodes?cde_type=XCHG'
								required
							/>

						</div>

						<div className="col-lg-2 col-md-2 col-sm-3 text-start">
							<label className='form-label m-0  text-start'>Trade Date & Time:</label>
							<input
								{...register('TRADE_DATE')}
								type='datetime-local'
								className="form-control form-control-sm"
							/>
						</div>

						{
							/*
						<div className="col-lg-1 col-md-1 col-sm-3 text-start">
							<label className='form-label m-0  text-start'>Userstamp:</label>
							<input
								placeholder='--Userstamp--'
								{...register('USERSTAMP')}
								type='text'
								className="form-control form-control-sm col-lg-1 col-md-1 col-sm-3 text-start"
								aria-label="Default select example"
								disabled
							/>
						</div>

						<div className="col-lg-2 col-md-2 col-sm-3 text-start">
							<label className='form-label m-0 text-start'>Datestamp: </label>
							<input
								placeholder='--Datestamp--'
								disabled
								{...register('DATESTAMP')}
								type='text'
								className="form-control form-control-sm"
							/>
						</div>
						*/
						}

					</div>

					{/* END OF ROW 1 */}
					{/* BEGIN ROW 2 */}
					<div className='row p-1 m-0 bg-light-blue text-start'>
						<div className="col-lg-2 col-md-3 col-sm-3 text-start">
							<label className='form-label m-0 text-start'>Counter Party: </label>

							<BASelector
								className='form-select form-select-sm col-lg-1 col-md-2 col-sm-3 text-start'
								form={form}
								propertyName='CounterParty'
								url='BusinessAssociate/GetBusinessAssociates?ba_role=BR'
								required
							/>

						</div>

						<div className="col-lg-1 col-md-3 col-sm-3 text-start">
							<label className='form-label m-0 text-start'>Trader</label>

							<ContactSelector
								className='form-select form-select-sm'
								form={form}
								propertyName='Trader'
								url={`ContactList/GetContactListData?ba_id=${counterPartyWatch?.BA_ID}&contact_role=FT`}
							/>

						</div>

						<div className="col-lg-1 col-md-3 col-sm-3 text-start">
							<label className="form-label m-0 text-start">Portfolio</label>

							<CodeSelector
								className='form-select form-select-sm'
								form={form}
								propertyName='PortfolioCode'
								valueType='object'
								url='Codes/GetCodes?cde_type=DSPC'
								required
							/>

						</div>

						<div className="col-lg-2 col-md-3 col-sm-3 text-start">
							<label className='form-label m-0 text-start'>Contract ID</label>
							<input
								placeholder='--Counter Party Contract--'
								{...register('Contract.CNTR_TITLE')}
								type='text'
								className='form-control form-control-sm'
								disabled
							/>
						</div>

						<div className="col-lg-2 col-md-3 col-sm-3 text-start">
							<label className='form-label m-0 text-start'>Confirm Party</label>

							<select
								className='form-select form-select-sm'
								{...register('ConfirmParty.BA_ID', {
									required: true,
									onChange: (event) => {
										const newValueID = event.target.value;
										const newValue = newValueID === businessUnit?.BA_ID ? businessUnit : (newValueID === counterPartyWatch?.BA_ID ? counterPartyWatch : null);
										setValue('ConfirmParty', newValue, { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });
									}
								})}
							>
								<option value=''>--Select a Confirm Party--</option>
								{businessUnit?.BA_ID && <option value={businessUnit.BA_ID}>{businessUnit.SHORTNAME}</option>}
								{counterPartyWatch?.BA_ID && <option value={counterPartyWatch.BA_ID}>{counterPartyWatch.SHORTNAME}</option>}
							</select>

						</div>

						<div className="col-lg-1 col-md-3 col-sm-3 text-start">
							<label className='form-label m-0 text-start'>Broker:</label>

							<BASelector
								form={form}
								propertyName='BROKER'
								url='BusinessAssociate/GetBusinessAssociates?ba_role=BR'
							/>

						</div>

						<div className="col-lg-1 col-md-3 col-sm-3 text-start">
							<label className='form-label m-0 text-start'>BR Fee</label>
							<input
								type='number'
								className="form-control form-control-sm"
								{...register('BROKER_FEE', { valueAsNumber: true })}
								step='any'
							/>
						</div>


						<div className="col-lg-2 col-md-3 col-sm-3 text-start">
							<label className='form-label m-0 text-start'>Contact</label>
							<input
								placeholder='--Contact--'
								type='text'
								className="form-control form-control-sm"
							/>
						</div>

					</div>
					{/* END OF ROW 2 */}
					{/* START ROW 3 */}
					<div className='row p-1 m-0 bg-light-blue text-start pb-2'>

						<div className="col-lg-1 col-md-2 col-sm-3">
							<label className="form-label m-0 text-start">Direction</label>

							<CodeSelector
								form={form}
								propertyName='DirectionCode'
								valueType='object'
								url='Codes/GetCodes?cde_type=DIR'
								required
							/>

						</div>

						<div className="col-lg-1 col-md-2 col-sm-3 d-flex flex-wrap align-items-end justify-content-lg-center">

							<div>
								<div className='form-check form-check-inline'>
									<label className="form-check-label">Daily</label>
									<input className="form-check-input" {...register('MD_FLAG')} type='radio' value='D' />
								</div>

								<div className='form-check form-check-inline'>
									<label className="form-check-label">Monthly</label>
									<input className="form-check-input" {...register('MD_FLAG')} type='radio' value='M' />
								</div>
							</div>

						</div>



						<div className="col-lg-1 col-md-2 col-sm-3 text-start">
							<label className='form-label m-0 text-start'>Quantity: </label>
							<input
								{...register('DAY_VOL', { valueAsNumber: true, required: true })}
								className="form-control form-control-sm text-start"
								type='number'
							/>
						</div>

						<div className="col-lg-1 col-md-2 col-sm-3">
							<label className="form-label m-0 text-start">Deliver Month</label>
							<input
								disabled={mdFlagWatch === 'D'}
								{...register('DELIVERY_MONTH', { required: true, onChange: deliveryMonthChange })}
								className="form-control form-control-sm"
								type="month"
							/>
						</div>


						<div className="col-lg-1 col-md-2 col-sm-3">
							<label className="form-label m-0 text-start">Start Date</label>
							<input
								disabled={mdFlagWatch === 'M'}
								{...register('STARTDATE', { required: true, onChange: startDateChange })}
								className="form-control form-control-sm"
								type="date"
							/>
						</div>

						<div className="col-lg-1 col-md-2 col-sm-3">
							<label className="form-label m-0 text-start">End Date</label>
							<input
								disabled={mdFlagWatch === 'M'}
								{...register('ENDDATE', { required: true, onChange: endDateChange })}
								className="form-control form-control-sm"
								type="date"
							/>
						</div>


						<div className="col-lg-1 col-md-2 col-sm-3 text-start">
							<label className='form-label m-0 text-start'># Positions</label>
							<input
								{...register('CONTRACT_BOUGHT', { valueAsNumber: true, required: true })}
								disabled
								className="form-control form-control-sm"
								type='number'
							/>
						</div>


						<div className="col-lg-1 col-md-2 col-sm-3">
							<label className="form-label m-0 text-start">Price/Unit</label>
							<input
								{...register('INSTR_PRICE', { valueAsNumber: true, required: true })}
								type='number'
								className="form-control form-control-sm"
								step='any'
							/>
						</div>

						<div className="col-lg-1 col-md-2 col-sm-3">
							<label className="form-label m-0 text-start">Put/Call</label>
							<select
								{...register('PUT_CALL', { required: dealTypeWatch === 'OPTA' })}
								className="form-select form-select-sm"
								disabled={dealTypeWatch !== 'OPTA'} // disabled if deal type is Option-American Type
							>
								<option value=''>--Select Put or Call--</option>
								<option value="P">Put</option>
								<option value="C">Call</option>
							</select>
						</div>

						<div className="col-lg-1 col-md-2 col-sm-3">
							<label className="form-label m-0 text-start">Strike Price</label>
							<input
								{...register('SPRICE_PRICE', { valueAsNumber: true, required: true })}
								className="form-control form-control-sm"
								type='number'
								disabled={dealTypeWatch !== 'OPTA'} // disabled if deal type is Option-American Type
								step='any'
							/>
						</div>


						<div className="col-lg-2 col-md-4 col-sm-6 text-start">
							<label className='form-label m-0 text-start'>Fin Pos ID</label>
							<input
								disabled
								className="form-control form-control-sm"
								type='text'
								{...register('FIN_POSITION_NUM')}
								placeholder='--Financial Position ID--'
							/>
						</div>

					</div>
					{/* END OF ROW 3 */}
				</div>
			</form>
			<ConfirmActionModal { ...confirmActionModalState } />
		</div >
	);
};

export default NewFinancialPosition;