import { createSlice } from "@reduxjs/toolkit";

import {
    getDayStart,
    getNextDay
} from '../utils/DateUtils';
import { updatePkgPathDtl } from "../utils/RetailNomsUtils";

const initialState = {
    weatherr: [],

    propertyInformation: [
        {
            headerName: "Weather Area",
            propertyName: "WEATHER_AREA",
            editable: "false",
            displayOnce: "true"
        },
        {
            headerName: "User",
            propertyName: "USER_STAMP",
            editable: false,
            displayOnce: true
        },
        {
            headerName: "Temperature",
            propertyName: "TMP",
            editable: false,
            displayOnce: true
        },
        {
            headerName: "HDD",
            propertyName: "HDD",
            editable: false,
            displayOnce: true
        },
        {
            headerName: "EHDD",
            propertyName: "EHDD",
            editable: false,
            displayOnce: true
        },
    ]
};

const weather_slice = createSlice({
    name: 'weather_slice',
    initialState,
    reducers: {
        businessSelectorChange(state, action) {
            let newGasDate = getDayStart(getNextDay(action.payload.gasFlowDate)).toISOString();
            state.gasFlowDate = newGasDate;
            state.selectedContract = action.payload.selectedContract;
        },
        updateStatus(state, action) {
            state.status = action.payload;
        },
        getWeather(state, action) {
            state.weather = action.payload;
        }
    }

});

// const getWeather = updatePkgPathDtl

export const weather_actions = weather_slice.actions;
export default weather_slice.reducer