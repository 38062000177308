import { formatDate, getDayStart } from "../../../../../utils/DateUtils"

// EndOfMonthTableColumns.js

function calculateUnits(contractBought) {
	const num = parseFloat(contractBought);
	return isNaN(num) ? 0 : num * 10000;
}

const EndOfMonthTableColumns = [


	{
		name: 'Portfolio',
		selector: row => row.PortfolioCode?.CDE_TXT,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true,
		width: '120px'
	},
	{
		name: 'Fin Pos ID',
		selector: row => row.FIN_POSITION_NUM,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true,
		//width: '115px'
	},
	{
		name: 'Trade Date & Time',
		selector: row => row.TRADE_DATE,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true,
		width: '175px'
	},
	{
		name: 'Del Month',
		selector: row => getDayStart(row.DELIVERY_MONTH)?.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric' }),
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true,
		width: '120px'
	},
	{
		name: 'Units',
		selector: row => {
			if (row.isTotalRow) {
				return row.units;
			}
			const units = calculateUnits(row?.CONTRACT_BOUGHT);
			return units.toFixed(0);
		},
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
		width: '80px'
	},
	{
		name: 'Instr Price',
		selector: row => row.INSTR_PRICE?.toFixed(3),
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
	},
	{
		name: 'Nymex FS',
		selector: row => row?.MTM_EOM?.toFixed(3),
		// selector: row => 50,
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
		//width: '120px'
	},
	{
		name: 'IFERC',
		selector: row => {
			if (row.isTotalRow) {
				return '';
			}
			const priceForContract = row?.priceForContract || 0;
			return priceForContract !== 0 ? priceForContract.toFixed(4) : 0;
		},
		sortable: true,
		compact: true,
		reorder: true,
		wrap: true,
		width: "85px",
	},
	{
		name: 'Final Gain/Loss',
		selector: row => {

			if (row.isTotalRow) {
				return row.finalGainLoss;
			} else {

				return row.finalGainLoss ? row.finalGainLoss : 0;
			}
		},
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
		width: '140px'
	},
	{
		name: 'Instr ID',
		selector: row => row.Instrument?.ContractName,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true,
		width: '125px'
	},
	{
		name: 'Counter Party',
		selector: row => row.CounterParty?.SHORTNAME,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true,
		width: '125px'
	},
	{
		name: 'Portfolio',
		selector: row => row.PortfolioCode?.CDE_TXT,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true,
		width: '135px'
	},
	{
		name: 'Direction',
		selector: row => row.DirectionCode?.CDE_TXT,
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
		width: '70px'

	},
	{
		name: 'Start Date',
		selector: row => formatDate(row.STARTDATE),
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
		//width: '120px'

	},
	{
		name: 'End Date',
		selector: row => formatDate(row.ENDDATE),
		sortable: true,
		compact: true,
		wrap: true,
		reorder: true,
		//width: '120px'

	},
];


export default EndOfMonthTableColumns;