import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification_actions } from './notification-slice';
import { physical_trade_deals_actions, setBidPackageAndFetchPkgPaths } from './physical-trade-deals-slice';
import { getData } from '../utils/APIUtils';
import { toISOStringForDatabase } from '../utils/DateUtils';

export const fetchBidPackages = ({
	pageNumber,
	pageSize,
	selectedSupplier,
	filters,
	businessUnit,
	isAdmin
}) => {
	return async (dispatch) => {

		// Grab filters.
		const buID = businessUnit?.BA_ID;
		const ba_id = selectedSupplier?.BA_ID;
		if (!buID || !ba_id)
			return;

		const {
			startDate,
			endDate,
			bidType,
			pipeline_id,
			showAllDeals
		} = filters;

		// Request function.
		const requestData = async () => {
			let url = 'PhysicalTradeDeals/GetBidPackages?' +
				`startDate=${toISOStringForDatabase(startDate)}` +
				`&endDate=${toISOStringForDatabase(endDate)}` +
				`&cntr_status=AC&cntr_type=MC`;

			if (isAdmin && showAllDeals)
				url += `&supplier_baid_or_buyerid=${buID}`;
			else
				url += `&supplier_baid_or_buyerid=${ba_id}`;
			if (bidType)
				url += `&bidType=${bidType}`;
			if (pipeline_id)
				url += `&pipeline_id=${pipeline_id}`;
			if (pageNumber)
				url += `&pageNumber=${pageNumber}`;
			if (pageSize)
				url += `&pageSize=${pageSize}`;

			const response = await fetch(url);
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}
			return await response.json();
		};

		try {
			await dispatch(physical_trade_deals_actions.setLoading(true));
			const {
				bidPackages,
				totalRows
			} = await requestData();
			await dispatch(physical_trade_deals_actions.setBidPackages(bidPackages));
			await dispatch(physical_trade_deals_actions.setTotalBidPkgRows(totalRows));
		}
		catch (error) {
			dispatch(notification_actions.showModal({
				header: 'Error',
				message: error.message
			}));
		}
		finally {
			await dispatch(physical_trade_deals_actions.setLoading(false));
		}
	};
};

export const fetchUnits = () => {
	return async (dispatch) => {
		const requestUnits = async () => {
			const response = await fetch('GeneralData/GetUnits');
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}
			const units = await response.json();
			await dispatch(physical_trade_deals_actions.setUnits(units));
		};
		try {
			// console.log('Fetching units...');
			await requestUnits();
			// console.log('Units fetched.');
		}
		catch (error) {
			dispatch(notification_actions.showModal({
				header: 'Error',
				message: error.message
			}));
		}
	};
};

export const fetchFercCodes = () => {
	return async (dispatch) => {
		const requestFercCodes = async () => {
			const response = await fetch('PhysicalTradeDeals/GetFercCodes');
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}
			const fercCodes = await response.json();
			await dispatch(physical_trade_deals_actions.setFercCodes(fercCodes));
		};
		try {
			await requestFercCodes();
		}
		catch (error) {
			dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
	};
};

export const fetchPriceRefIndices = () => {
	return async (dispatch) => {
		const requestPriceRefIndices = async () => {
			const response = await fetch('PhysicalTradeDeals/GetPriceRefIndices');
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			const priceRefIndices = await response.json();
			// console.log("priceRefIndices", priceRefIndices)
			await dispatch(physical_trade_deals_actions.setPriceRefIndices(priceRefIndices));
		};
		try {
			await requestPriceRefIndices();
		}
		catch (error) {
			dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
	};
};



export const fetchAllFuturePrice = () => {
	return async (dispatch) => {
		try {
			const allFuturePrice = await getData('PhysicalTradeDeals/GetAllFuturePrices');

			if (allFuturePrice.length === 0) {
				throw new Error('No data returned');
			}

			// console.log('allFuturePrice in Thunk: ', allFuturePrice);
			await dispatch(physical_trade_deals_actions.setAllFuturePrice(allFuturePrice));
		} catch (error) {
			dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
	};
};

export const fetchFuturePriceDetails = (selectedPriceId) => {
	return async (dispatch) => {
		try {
			const response = await fetch(`PhysicalTradeDeals/GetAllFuturePriceDetails?futurePriceId=${selectedPriceId}`);
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const data = await response.json();
			dispatch(physical_trade_deals_actions.setFuturePriceDetails(data));
		} catch (error) {
			console.error("There was an error in your fetch operation", error);
		}
	};
};





export const fetchCycleCodes = () => {
	return async (dispatch) => {
		try {
			const url = `GeneralData/GetCodes?module=NOMS&cde_type=CYCLE`;
			const cycleCodes = await getData(url);
			await dispatch(physical_trade_deals_actions.setCycleCodes(cycleCodes));
			// console.log('Cycle codes fetched.', cycleCodes);
		}
		catch (error) {
			dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
	};
};

export const fetchMtsContracts = () => {
	return async (dispatch, getState) => {
		try {
			const state = getState();
			const buID = state?.business_selector_slice?.businessUnit?.BA_ID;
			const url = `GeneralData/GetContracts?cntr_party2=${buID}&cntr_type=MT&status=AC`;
			const mtsContracts = await getData(url);
			await dispatch(physical_trade_deals_actions.setMtsContracts(mtsContracts));
		}
		catch (error) {
			dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
	};
};

export const fetchStatusCodes = () => {
	return async (dispatch) => {
		try {
			const url = `GeneralData/GetCodes?cde_type=BIDS&module=BIDS`;
			const statusCodes = await getData(url);
			await dispatch(physical_trade_deals_actions.setStatusCodes(statusCodes));
		}
		catch (error) {
			dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
	};
};

// Original Update Bid Package code, do NOT delete:
export const updateBidPkg = () => {
	return async (dispatch, getState) => {
		const selectedBidPkg = getState().physical_trade_deals_slice.selectedBidPkg;
		const requestUpdate = async () => {
			const response = await fetch('PhysicalTradeDeals/UpdateBidPackage', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: JSON.stringify(selectedBidPkg)
			});
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}
			const updatedBidPkg = await response.json();
			return updatedBidPkg;
		};
		try {
			const updatedBidPkg = await requestUpdate();
			await dispatch(physical_trade_deals_actions.setSelectedBidPkg(updatedBidPkg));
			await dispatch(notification_actions.showModal({
				header: 'Success!',
				message: 'Bid Package updated successfully.'
			}));
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
	};
};

/**
 * For updating bid package volume details in details screen.
 */
export const updateBidPkgDtls = ({ selectedBidPkg }) => {
	return async (dispatch) => {
		const makeRequest = async () => {
			const response = await fetch('PhysicalTradeDeals/UpdateBidPkgDtls', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(selectedBidPkg)
			});
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}
			const newBidPkg = await response.json();
			await dispatch(setBidPackageAndFetchPkgPaths(newBidPkg));
		};
		try {
			await makeRequest();
			await dispatch(notification_actions.showModal({
				header: 'Success!',
				message: 'Bid Package Details saved successfully.'
			}));
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
	};
};

export const createBidPkg = () => {
	return async (dispatch, getState) => {
		const selectedBidPkg = getState().physical_trade_deals_slice.selectedBidPkg;
		const makeRequest = async () => {
			const response = await fetch('PhysicalTradeDeals/CreateBidPackage', {
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: JSON.stringify(selectedBidPkg)
			});
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}
			const newBidPkg = await response.json();
			return newBidPkg;
		};
		try {
			const newBidPkg = await makeRequest();
			await dispatch(physical_trade_deals_actions.setSelectedBidPkg(newBidPkg));
			await dispatch(notification_actions.showModal({
				header: 'Success!',
				message: 'Bid Package created successfully.'
			}));
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
			console.log(error.message, { ...selectedBidPkg })
		}
	};
};

export const deleteBidPkg = () => {
	return async (dispatch, getState) => {
		const bid_num = getState().physical_trade_deals_slice?.selectedBidPkg?.BID_NUM;
		if (!bid_num)
			return;
		const requestDelete = async () => {
			const response = await fetch(`PhysicalTradeDeals/DeleteBidPackage?bid_num=${bid_num}`, {
				method: 'DELETE'
			});
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}
		};
		try {
			await requestDelete();
			await dispatch(notification_actions.showModal({
				header: 'Success!',
				message: 'Bid Package was deleted successfully.'
			}));
			await dispatch(physical_trade_deals_actions.setOpenModal(false));
		}
		catch (error) {
			await dispatch(notification_actions.showModal({
				header: 'Error',
				message: error.message
			}));
		}
	};
};

export const fetchPortfolios = () => {
	return async (dispatch, getState) => {
		const portfolios = getState().physical_trade_deals_slice?.portfolios;
		if (portfolios.length > 0) return;
		
		const requestData = async () => {
			const response = await fetch('Codes/GetCodes?cde_type=DSPC');
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}
			return await response.json();
		};
		try {
			const data = await requestData();
			dispatch(physical_trade_deals_actions.setPortfolios(data));
		}
		catch (error) {
			dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
	}
};
