import { useState, useMemo } from 'react';
//Redux:
import { useSelector, useDispatch } from 'react-redux';
import { physical_trade_deals_actions } from '../../../store/physical-trade-deals-slice';
import { updateBidPkgDtls, fetchBidPackages } from '../../../store/physical-trade-deals-thunks';
import { toDatePickerString, getDayStart } from '../../../utils/DateUtils';

//Import Modal:
import Modal from 'react-modal';
// import Table:
// import SumTable from './SumTable';

//Import table parent component:
import SumTable from './SumTable';

// Import icons for buttons:

import saveBtn from '../../../assets/images/save.png';
import linkBtn from '../../../assets/images/link.gif';
// import excelBtn from '../../../assets/images/excel.gif';
//import copyBtn from '../../../assets/images/copy.gif';
//import acceptBtn from '../../../assets/images/accept.png';
import Cancel from '../../../assets/images/cancel.png';


// Import styles:
import './UiModalStyling.css';

import Spinner from '../../Spinner';
import { useEffect } from 'react';
import { Fragment } from 'react';

// Modal styling
const customStyles = {
	overlay: {
		backgroundColor: 'rgb(0,0,0,.60'
	}
};

function DailySumUiModal({ filters }) {

	// Redux:
	const openUiModal = useSelector(state => state.physical_trade_deals_slice.openUiModal);
	const selectedBidPkg = useSelector(state => state.physical_trade_deals_slice.selectedBidPkg);
	const selectedSupplier = useSelector(state => state.business_selector_slice.selectedSupplier);
	const businessUnit = useSelector(state => state.business_selector_slice.businessUnit);
	const isAdmin = useSelector(state => state.auth_slice.isAdmin);
	const dispatch = useDispatch();

	// State for updating package path details.
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [quantity, setQuantity] = useState(0);
	const [saving, setSaving] = useState();

	const pkgVolDtls = useMemo(() => {
		return selectedBidPkg?.PACKAGE_NUM?.ENT_PKG_PATH?.[0].ENT_PKG_VOL_DTL || [];
	}, [selectedBidPkg?.PACKAGE_NUM]);

	// Update start and end date any time the selectedBidPkg changes.
	useEffect(() => {
		if (selectedBidPkg?.STARTDATE) {
			setStartDate(selectedBidPkg?.STARTDATE);
			setEndDate(selectedBidPkg?.STARTDATE);
		}
	}, [selectedBidPkg?.STARTDATE]);

	// Modal State:
	const closeUiModal = () => {
		dispatch(physical_trade_deals_actions.setOpenUiModal(false));
	};

	// Handler for Quantity input changes.
	/*
	const [rows, setRows] = useState();
	const [editedRows, setEditedRows] = useState([]);
	
	const updateRowHandler = (updatedRow) => {
		console.log("Updated Row", updatedRow);
		const updatedRows = rows.map(row => row.id === updatedRow.id ? updatedRow : row);
		setRows(updatedRows);
		setEditedRows(prevEditedRows => [...prevEditedRows, updatedRow]);
	};
	*/

	// Code for updating Bid Pkg Dtls.
	const handleApplyQuantities = () => {
		console.log('Apply QTY clicked', {...pkgVolDtls});
		if (!pkgVolDtls)
			return;

		// Update quantities.
		const newPkgVolDtls = pkgVolDtls?.map(
			prevPkgVolDtl => {
				const gasFlowDate = prevPkgVolDtl.GASFLOW_DATE;
				if (getDayStart(gasFlowDate) >= getDayStart(startDate) && getDayStart(gasFlowDate) <= getDayStart(endDate)) {
					return {
						...prevPkgVolDtl,
						// SCH_RCV_VOL: quantity,
						// SCH_DLV_VOL: quantity,
						NOM_RCV_VOL: quantity, // DevOps 3043, Updated 06/01/23 - Daily Quantity Issue
						NOM_DLV_VOL: quantity, // DevOps 3043, Updated 06/01/23 - Daily Quantity Issue
						ACT_RCV_VOL: quantity,
						ACT_DLV_VOL: quantity
					};
				}
				return prevPkgVolDtl;
			}
		);

		console.log('prev package mapped', {...newPkgVolDtls})

		dispatch(physical_trade_deals_actions.updateBidPkgDtls(newPkgVolDtls));

		/*
		for (const row of editedRows) {
			if (row.BID_NUM) {
				await dispatch(updateBidPkg(row));
			} else {

				return
			}
		}

		setEditedRows([]);*/
	};

	// Original, do not delete:
	// const handleApplyQuantities = () => {
	//     console.log("Edited Rows: ", editedRows);
	//     if (selectedBidPkg.BID_NUM) {
	//         dispatch(updateTableBidPkg());
	//     }
	//     else {
	//         dispatch(createBidPkg());
	//     }

	//     setEditedRows([]);
	// };

	// Updates bid pkg dtls to database.
	const saveClick = async () => {
		console.log('saving', {...selectedBidPkg});
			setSaving(true);
		await dispatch(updateBidPkgDtls({ selectedBidPkg: selectedBidPkg }, Number(selectedBidPkg.NOM_RCV_VOL)));
			setSaving(false);
			dispatch(fetchBidPackages({
				selectedSupplier,
				filters,
				businessUnit,
				isAdmin
			}));
	};

	return (

		<>
			<Modal
				isOpen={openUiModal}
				contentLabel='change-password'
				className='summary-ui-modal modal-XL container-fluid bg-white'
				style={customStyles}
				onRequestClose={closeUiModal}
			>
				<div className="modal-content text-center">
					<div className="modal-header mb-1">
						<h5 className="modal-title">Daily Summary UI</h5>
						<button
							type="button"
							className="btn-close"
							onClick={closeUiModal}
						>
						</button>
					</div>
					<div className="modal-body">

						{/* // BUTTONS */}
						<div className="row text-start bg-light-blue rounded-top m-0 p-1">
							<div className="col-lg-12 col-md-12 col-md-12 col-sm-12">
								{
									saving
										?
										<Spinner status='saving' />
										:
										<button
											type="button"
											className="btn btn-primary m-1"
											title="New Deal"
											onClick={saveClick}
										>
											<img src={saveBtn} alt="SaveImage" /> Save
										</button>
								}
								<button type="button" className="btn btn-primary m-1" title="Link">
									<img src={linkBtn} alt="Link" /> Link
								</button>
								{/*<button type="button" className="btn btn-primary m-1" title="Copy">*/}
								{/*	<img src={copyBtn} alt="Copy" /> Copy*/}
								{/*</button>*/} 
								{
									/*
									<button type="button" className="btn btn-primary m-1" title="Accept">
										<img src={acceptBtn} alt="Accept" /> Accept
									</button>
									*/
								}
								<button type="button" className="btn btn-primary m-1" onClick={closeUiModal} title="Cancel">
									<img src={Cancel} alt="Cancel" /> Cancel
								</button>
							</div>
						</div>
						<div className="row text-start bg-light-blue m-0 pb-1">
							<label className='label fw-bold'>Daily Volume Details</label>

							<div className="col-lg-3 col-md-12 col-sm-12">
								<label>Counter Party:</label>
								<input type="text" className="form-control form-control-sm" value={
									selectedBidPkg?.BIDTYPE === 'B'
										?
										(selectedBidPkg?.SUPPLIER_BAID_OBJ?.NAME || '')
										:
										(selectedBidPkg?.BUYERID_OBJ?.NAME || '')
								}
									readOnly={true}
								/>
							</div>

							<div className="col-lg-3 col-md-12 col-sm-12">
								<label>Point:</label>
								<input type="text" className="form-control form-control-sm" value={selectedBidPkg?.START_PT_NUM?.PT_NAME || ''}
									readOnly={true}
								/>
							</div>
						</div>
						<div className="row text-start bg-light-blue m-0 pb-1">
							<div className="col-lg-3 col-md-12 col-sm-12">
								<label>Package #:</label>
								<input type="text" className="form-control form-control-sm"
									value={selectedBidPkg?.PACKAGE_NUM?.PACKAGE_NUM || ''}
									readOnly={true}
								/>
							</div>

							{
								// This only needs to show if the bid package is indexed. - Cameron
								selectedBidPkg?.FIXED_INDEX_IND === 'I' &&
								<Fragment>
									<div className="col-lg-3 col-md-12 col-sm-12">
										<label>Index Area:</label>
										<input type="text" className="form-control form-control-sm" value={selectedBidPkg.PRICE_AREA_ID}
											readOnly={true}
										/>
									</div>
									<div className='col-lg-3 col-md-12 cold col-sm-12'>
										<label>Adjuster:</label>
										<input type="text" className="form-control form-control-sm"
											value={selectedBidPkg?.PRICE_OFFSET || ''}
											readOnly={true}
										/>
									</div>
								</Fragment>
							}

						</div>
						{selectedBidPkg.STATUS === "AP" || selectedBidPkg.STATUS === "CF" ? <div className="row text-start bg-light-blue rounded-bottom m-0 pb-1 disabled">
							<label className='label fw-bold'>Apply Quantity to Date Range:</label>
							<div className="col-lg-2 col-md-12 col-sm-12" style={{ opacity: .5 }}>
								<label className='form-label pe-2'>Display Date: </label>
								<input
									value={toDatePickerString(startDate)}
									onChange={(e) => { setStartDate(toDatePickerString(e.target.value)) }}
									name='startDate'
									className="form-control form-control-sm bg-gray"
									type="date"
									readOnly={true}
									max={toDatePickerString(selectedBidPkg.ENDDATE)}
									min={toDatePickerString(selectedBidPkg.STARTDATE)}
								/>
							</div>
							<div className="col-lg-2 col-md-2 col-sm-12 text-start" style={{ opacity: .5 }}>
								<label className='form-label pe-2'>Thru Date: </label>
								<input
									value={toDatePickerString(endDate)}
									onChange={(e) => { setEndDate(toDatePickerString(e.target.value)) }}
									name='endDate'
									className="form-control form-control-sm"
									type="date"
									readOnly={true}
									max={toDatePickerString(selectedBidPkg.ENDDATE)}
									min={toDatePickerString(selectedBidPkg.STARTDATE)}
								/>
							</div>
							<div className="col-lg-2 col-md-2 col-sm-12 text-start" style={{ opacity: .5 }}>
								<label className='form-label pe-2'>Input Quantity: </label>
								<input
									type='number'
									className="form-control form-control-sm"
									value={quantity}
									readOnly={true}
									onChange={(e) => { setQuantity(parseFloat(e.target.value)) }}
								/>
							</div>
							<div className="col-lg-2 col-md-1 col-sm-12 text-start">

								<button className='btn btn-sm btn-primary mt-4 disabled' style={{ opacity: .5 }}>
									Apply Quantity
								</button>
							</div>
						</div>
							:
							<div className="row text-start bg-light-blue rounded-bottom m-0 pb-1">
								<label className='label fw-bold'>Apply Quantity to Date Range:</label>
								<div className="col-lg-2 col-md-12 col-sm-12">
									<label className='form-label pe-2'>Display Date: </label>
									<input
										value={toDatePickerString(startDate)}
										onChange={(e) => { setStartDate(toDatePickerString(e.target.value)) }}
										name='startDate'
										className="form-control form-control-sm"
										type="date"
										readOnly={false}
										max={toDatePickerString(selectedBidPkg.ENDDATE)}
										min={toDatePickerString(selectedBidPkg.STARTDATE)}
									/>
								</div>
								<div className="col-lg-2 col-md-2 col-sm-12 text-start">
									<label className='form-label pe-2'>Thru Date: </label>
									<input
										value={toDatePickerString(endDate)}
										onChange={(e) => { setEndDate(toDatePickerString(e.target.value)) }}
										name='endDate'
										className="form-control form-control-sm"
										type="date"
										max={toDatePickerString(selectedBidPkg.ENDDATE)}
										min={toDatePickerString(selectedBidPkg.STARTDATE)}
									/>
								</div>
								<div className="col-lg-2 col-md-2 col-sm-12 text-start">
									<label className='form-label pe-2'>Input Quantity: </label>
									<input
										type='number'
										className="form-control form-control-sm"
										value={quantity}
										onChange={(e) => { setQuantity(parseFloat(e.target.value)) }}
									/>
								</div>
								<div className="col-lg-2 col-md-1 col-sm-12 text-start">

									<button className='btn btn-sm btn-primary mt-4' onClick={handleApplyQuantities}>
										Apply Quantity
									</button>
								</div>
							</div>
						}

						{/* SELECT A DEAL TABLE */}
						<div className='row text-start'>
							<SumTable />
							{/*<SumTable rows={rows} updateRow={updateRowHandler} />*/}
						</div>
					</div>
				</div>
			</Modal >
			<></>
		</>
	);
};

export default DailySumUiModal;