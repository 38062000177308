import { combineReducers, configureStore } from "@reduxjs/toolkit";
import business_selector_reducer from "./business-selector-slice";
import auth_reducer from './auth-slice';
import nominations_reducer from './nominations-slice';
import contact_reducer from './contact-slice';
import customer_list_view_reducer from './customer-list-view-slice'
import notification_reducer from './notification-slice';
import physical_trade_deals_reducer from './physical-trade-deals-slice';
import general_data_reducer from './general-data-slice';
import version_reducer from "./version-slice"; // DevOps 2742: Track login/logout - Created 05/17/23
import interstate_noms_reducer from './interstate-noms-slice';
import pointBalanceSlice from "./point-balance-slice";
import hedgingReducer from './hedging-slice';
import non_path_noms_reducer from './non-path-noms-slice';
import measurementReducer from "./measurement-slice";
import poolBalanceReducer from "./pool-balance-slice";
import financial_position_reducer from './financial-position-slice';
import efsReportReducer from './efsReportSlice';
import whPurschaseReducer from './whpurschase-slice';
import dailyMeasurementReducer from "./daily-measurement-slice";
import eom_slice_reducer from "./eom_slice";

// Combine reducers. This combines all of the slice reducers into one reducer.
const combinedReducer = combineReducers({
	business_selector_slice: business_selector_reducer,
	auth_slice: auth_reducer,
	nominations_slice: nominations_reducer,
	contact_slice: contact_reducer,
	customer_list_view_slice: customer_list_view_reducer,
	notification_slice: notification_reducer,
	physical_trade_deals_slice: physical_trade_deals_reducer,
	general_data_slice: general_data_reducer,
	version_slice: version_reducer,
	interstate_noms_slice: interstate_noms_reducer,
	pointBalance_slice: pointBalanceSlice,
	hedging: hedgingReducer,
	poolBalance_slice: poolBalanceReducer,
	non_path_noms_slice: non_path_noms_reducer,
	measurement: measurementReducer,
	financial_position_slice: financial_position_reducer,
	efsReport: efsReportReducer,
	whPurschase: whPurschaseReducer,
	dailyMeasurement: dailyMeasurementReducer,
	eom_slice: eom_slice_reducer
});

// Create root reducer. This just adds a condition to the reducer that listens for
// the auth_slice/logout action. If this action is dispatched, the entire store's state
// will be reset.
const rootReducer = (state, action) => {
	// If action type is auth_slice/logout, reset store.
	if (action.type === 'auth_slice/logout') {
		console.log('Logout was dispatched. Resetting store state...');
		state = {};
	}
	return combinedReducer(state, action);
};

// Note: I had to make some changes to this file because the redux state was not being reset on logout. - Cameron
// Configure store.
const store = configureStore({ reducer: rootReducer });

export default store;