import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

import { fetchAllFuturePrice, fetchFuturePriceDetails } from "../../../../../store/physical-trade-deals-thunks";

import { useDispatch, useSelector } from "react-redux";
import { fetchFinPositions } from "../../../../../store/financial-position-slice";
// import { physical_trade_deals_actions } from "../../../../../store/physical-trade-deals-slice";
import { fetchPriceRefIndices } from "../../../../../store/physical-trade-deals-thunks";


import Spinner from '../../../../Spinner';
import ExportExcel from '../../../../CustomComponents/ExportExcel';

import EndOfMonthTableColumns from './EndOfMonthTableColumns';


function EndOfMonthTable() {

	// Redux
	const FinPositionFilters = useSelector(state => state.financial_position_slice.FinPositionFilters);
	const FinPositions = useSelector(state => state.financial_position_slice.FinPositions);
	const FinPositionsFetchLoading = useSelector(state => state.financial_position_slice.FinPositionsFetchLoading);
	const futurePrices = useSelector(state => state.physical_trade_deals_slice.futurePrice);
	const futurePriceDetails = useSelector(state => state.physical_trade_deals_slice.futurePriceDetails);
	const dispatch = useDispatch();

	// Local state
	const [combinedData, setCombinedData] = useState([]);


	// API fetch financial positions any time filters change.
	useEffect(() => {
		dispatch(fetchFinPositions(FinPositionFilters));
		dispatch(fetchAllFuturePrice());
	}, [FinPositionFilters, dispatch]);

	useEffect(() => {
		dispatch(fetchPriceRefIndices());
	}, [dispatch]);

	useEffect(() => {
		if (futurePrices.length > 0) {
			futurePrices.forEach(price => {
				dispatch(fetchFuturePriceDetails(price.FUTURE_PRICE_ID));
			});
		}
	}, [futurePrices, dispatch]);

	// Function to calculate units
	function calculateUnits(contractBought) {
		if (contractBought === undefined || isNaN(Number(contractBought))) {
			return 0;
		}
		return parseFloat(contractBought) * 10000;
	}

	// Combine FinPositions with futurePrices and futurePriceDetails and calculate the finalGainLoss
	useEffect(() => {
		if (FinPositions.length > 0 && futurePrices.length > 0) {
			let totalUnits = 0;
			let totalFinalGainLoss = 0;

			const updatedData = FinPositions.map(finPos => {
				const fixedPrice = Number(finPos?.INSTR_PRICE) || 0;
				const nymexFS = Number(finPos?.MTM_EOM) || 0;
				let iferc = 0;
				let finalGainLoss;
				const units = calculateUnits(finPos?.CONTRACT_BOUGHT);
				const contractName = finPos.Instrument.ContractName;
				const direction = finPos.DirectionCode.CDE_TXT;

				// Logic specific for Instrument ID for "NGAS:BASI:ESP" and "NGAS:BASI:TCO"
				if (["NGAS:BASI:ESP", "NGAS:BASI:TCO"].includes(finPos.Instrument?.ContractName)) {
					const deliveryMonthDate = new Date(finPos.DELIVERY_MONTH);
					const deliveryYearMonth = deliveryMonthDate.getFullYear() + '-' + String(deliveryMonthDate.getMonth() + 1).padStart(2, '0');
					const priceAreaID = finPos.Instrument?.ContractName === "NGAS:BASI:ESP" ? "IGBDC03u" : "IGBDE03u";

					// Find entry where ISSUE_DATE's month and year matches DELIVERY_MONTH's month and year
					let matchedEntry = finPos.PriceAreaDetails.find(detail => {
						const issueDate = new Date(detail.PriceAreaDetailCompositeKey?.ISSUE_DATE);
						const issueYearMonth = issueDate.getFullYear() + '-' + String(issueDate.getMonth() + 1).padStart(2, '0');
						return issueYearMonth === deliveryYearMonth &&
							detail.PriceAreaDetailCompositeKey?.PRICE_AREA_ID === priceAreaID;
					});

					finalGainLoss = parseFloat(finalGainLoss);
					// If no exact month-year match is found, matchedEntry remains undefined
					iferc = matchedEntry && !isNaN(matchedEntry.PRICE) ? +matchedEntry.PRICE : 0;
					finalGainLoss = (iferc - nymexFS - fixedPrice) * calculateUnits(finPos?.CONTRACT_BOUGHT);
				}

				else if (["NGAS:OPTA:ESP", "NGAS:OPTA:HH"].includes(finPos.Instrument?.ContractName)) {
					// Specific calculation for "NGAS:OPTA:ESP" and "NGAS:OPTA:HH"
					finalGainLoss = (fixedPrice * calculateUnits(finPos?.CONTRACT_BOUGHT) * -1)
				}
				else {
					// Default finalGainLoss calculation for other contract types like "NGAS:FU:HH"
					finalGainLoss = (nymexFS - fixedPrice) * calculateUnits(finPos?.CONTRACT_BOUGHT);
				}
				//Logic for calculating the deals and the totals:
				// Calculate finalGainLoss based on contract type
				if (["NGAS:BASI:ESP", "NGAS:BASI:TCO"].includes(contractName)) {
					finalGainLoss = (iferc - nymexFS - fixedPrice) * units;
				} else if (["NGAS:OPTA:ESP", "NGAS:OPTA:HH"].includes(contractName)) {
					finalGainLoss = (fixedPrice * units) * -1;
				} else {
					finalGainLoss = (nymexFS - fixedPrice) * units;
				}
				// Negate the finalGainLoss if the direction is "SELL"
				if (direction === "SELL") {
					finalGainLoss *= -1;
				}
				// Parse finalGainLoss as a number and accumulate totals
				const parsedFinalGainLoss = parseFloat(finalGainLoss);
				totalUnits += units;
				totalFinalGainLoss += isNaN(parsedFinalGainLoss) ? 0 : parsedFinalGainLoss;

				return {
					...finPos,
					priceForContract: iferc,
					finalGainLoss: isNaN(parsedFinalGainLoss) ? '0.00' : parsedFinalGainLoss.toFixed(2),
				};
			});

			// Construct the total row
			const totalRow = {
				isTotalRow: true,
				units: totalUnits,
				finalGainLoss: totalFinalGainLoss.toFixed(2),
			};

			// Append the total row to the dataset
			setCombinedData([...updatedData, totalRow]);
		}
	}, [FinPositions, futurePrices, futurePriceDetails]);

	console.log('combinedData', combinedData);

	return (
		<>
			<ExportExcel
				columns={EndOfMonthTableColumns}
				fileName='Financial_Positions_Data.xlsx'
				data={combinedData}
				className='btn btn-primary h-100'
			/>
			<DataTable
				progressPending={FinPositionsFetchLoading}
				progressComponent={<Spinner />}
				fixedHeader={true}
				fixedHeaderScrollHeight="700px"
				columns={EndOfMonthTableColumns}
				data={combinedData}
				futurePrices={futurePrices}
				futurePriceDetails={futurePriceDetails}
				data-tag="allowRowEvents"
				type='button'
				striped={true}
				highlightOnHover={true}
				dense={true}
				className="pb-1"
			/>
		</>
	);
};

export default EndOfMonthTable;