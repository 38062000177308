import React, { useEffect, useState } from 'react';

import DataTable from 'react-data-table-component';
// import { useSelector } from 'react-redux';
import CustomStyles from '../Table-Styles';

const columns = [
    {
        name: 'Code Type',
        selector: row => row?.ContractName,
        sortable: true,
        compact: true,
        wrap: true,
        reorder: true,
        width: '100px'
    },
    {
        name: 'Contract Title',
        selector: row => row?.Description,
        sortable: true,
        compact: true,
        wrap: true,
        reorder: true,
        width: '210px'
    },
    {
        name: 'Contract Type',
        selector: row => row?.ContractTypeCode?.CDE_TXT,
        sortable: true,
        compact: true,
        wrap: true,
        reorder: true,
        width: '160px'
    },
    {
        name: 'Asset Type',
        // selector: row => row?.Asset1,
        selector: row => row?.Asset1Code?.CDE_TXT,
        sortable: true,
        compact: true,
        wrap: true,
        reorder: true,
        width: '160px'
    },
    {
        name: 'Location Reference',
        selector: row => row?.Location1Code?.CDE_TXT,
        sortable: true,
        compact: true,
        wrap: true,
        reorder: true,
        width: '100px'
    },
    {
        name: 'Valuation Type',
        selector: row => row?.PriceExpression,
        sortable: true,
        compact: true,
        wrap: true,
        reorder: true,
        width: '120px'
    },
    // {
    //     name: 'M2M Type',
    //     selector: row => row?.Asset1ForwardPriceExpression,
    //     sortable: true,
    //     compact: true,
    //     wrap: true,
    //     reorder: true,
    //     width: '100px'
    // },
    {
        name: 'Units Selected',
        selector: row => row?.Units1,
        sortable: true,
        compact: true,
        wrap: true,
        reorder: true,
        width: '100px'
    },
    {
        name: 'Default PVOL',
        selector: row => row?.Asset1SpotPriceExpression,
        sortable: true,
        compact: true,
        wrap: true,
        reorder: true,
        width: '110px'
    },

    {
        name: 'Units',
        selector: row => row?.UnitsAmount1,
        sortable: true,
        compact: true,
        wrap: true,
        reorder: true,
        width: '100px'
    },
    {
        name: 'SysId',
        selector: row => row?.CntrNum,
        sortable: true,
        compact: true,
        wrap: true,
        reorder: true,
        width: '100px'
    },
    {
        name: 'Status',
        selector: row => row?.StatusCode?.CDE_TXT,
        sortable: true,
        compact: true,
        wrap: true,
        reorder: true,
        width: '100px'
    },

    {
        name: 'Updated By',
        selector: row => row?.UserStamp,
        sortable: true,
        compact: true,
        wrap: true,
        reorder: true,
        width: '100px'
    },
    {
        name: 'Updated On',
        selector: row => row?.DateStamp,
        sortable: true,
        compact: true,
        wrap: true,
        reorder: true,
        width: '140px'
    },

];

function Table({ hedgeContracts, filter, onRowClick }) {

    const [filteredData, setFilteredData] = useState([]);
    // console.log('filtered data:', filteredData);

    //Version 3: which shows everything
    useEffect(() => {
        if (hedgeContracts) {
            let newFilteredData = hedgeContracts.filter((item) => {
                return (
                    // (filter.ContractName === "Select All" || (item?.ContractName ?? '').includes(filter?.ContractName ?? '')) &&
                    (filter.ContractType === "Select All" || (item?.ContractType ?? '').includes(filter?.ContractType ?? '')) &&
                    (filter.Asset1 === "Select All" || (item?.Asset1 ?? '').includes(filter?.Asset1 ?? '')) && // Added Asset1 filter
                    (filter.Location1 === "Select All" || (item?.Location1 ?? '').includes(filter?.Location1 ?? '')) &&// Added Location1 filter
                    (filter.Asset1ForwardPriceExpression === "Select MTM Valuation" || (item?.Asset1ForwardPriceExpression ?? '').includes(filter?.Asset1ForwardPriceExpression ?? '')) &&// Added Asset1ForwardPriceExpression filter
                    (filter.PriceExpression === "Select M2M" || (item?.PriceExpression ?? '').includes(filter?.PriceExpression ?? '')) &&// Added PriceExpression filter
                    (filter.Units1 === "Select Unit Type" || (item?.Units1 ?? '').includes(filter?.Units1 ?? '')) &// Added Units1 filter
                    (filter.Status === "Select Status" || (item?.Status ?? '').includes(filter?.Status ?? '')) // Added Status filter

                );
            });
            // console.log('New Filtered Data:', newFilteredData);
            setFilteredData(newFilteredData);
        }
    }, [hedgeContracts, filter]);


    return (
        <DataTable
            // conditionalRowStyles={conditionalRowStyles}
            fixedHeader={true}
            fixedHeaderScrollHeight="700px"
            columns={columns}
            data={filteredData}
            data-tag="allowRowEvents"
            type='button'
            onRowClicked={row => onRowClick(row)}
            striped={true}
            highlightOnHover={true}
            customStyles={CustomStyles}
            className="pb-1"
            dense={true}
        />
    );
};

export default Table;