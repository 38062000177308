import { useState, useEffect } from 'react';

//Image imports
import Clear from '../../../assets/images/clear.png';
import Cancel from '../../../assets/images/cancel.png';

//Import Modules:
import Table from './Table';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { physical_trade_deals_actions } from '../../../store/physical-trade-deals-slice';

import './DealModalStyling.css';
import SearchBar from '../../CustomComponents/SearchBar';
import { notification_actions } from '../../../store/notification-slice';

// Modal styling
const customStyles = {
	overlay: {
		backgroundColor: 'rgb(0,0,0,.60'
	},
};

function DealModal() {

	// Redux:
	const openDealModal = useSelector(state => state.physical_trade_deals_slice.openDealModal);
	const selectedBidPkg = useSelector(state => state.physical_trade_deals_slice.selectedBidPkg);
	const dispatch = useDispatch();
	const businessUnit = useSelector(state => state.business_selector_slice.businessUnit);

	// State
	const [dealMakers, setDealMakers] = useState();
	const [loading, setLoading] = useState();
	const [filteredData, setFilteredData] = useState();

	// Modal State:
	const closeDealModal = () => {
		dispatch(physical_trade_deals_actions.setOpenDealModal(false));
	};

	// Fetch Deal Makers API call.
	useEffect(() => {
		const buID = businessUnit?.BA_ID;
		if (!buID)
			return;
		const handleAsync = async () => {
			const requestData = async () => {
				const response = await fetch(`GeneralData/GetContacts?ba_id=${buID}&contact_role=MK`);
				if (!response.ok) {
					const message = await response.text();
					throw new Error(message);
				}
				return await response.json();
			};
			try {
				setLoading(true);
				const data = await requestData();
				setDealMakers(data);
			}
			catch (error) {
				dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
			}
			finally {
				setLoading(false);
			}
		};
		handleAsync();
	}, [dispatch, businessUnit]);

	// If there is only one deal maker, set it to the selected one.
	useEffect(() => {
		if (dealMakers?.length === 1) {
			dispatch(physical_trade_deals_actions.setSelectedBidPkg({
				...selectedBidPkg,
				COMP_CONTACT_OBJ: dealMakers[0]
			}))
		}
	}, [dealMakers]);

	return (
		<>
			<Modal
				isOpen={openDealModal}
				onRequestClose={closeDealModal}
				className="deal-modal container-fluid bg-white text-black"
				style={customStyles}
			>
				<div id="SelectDealModal" tabIndex='-1'>
					<div className='modal-dialog '>
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title mb-2">Select Deal Maker</h4>
								<button
									type="button"
									className="btn-close fs-6"
									onClick={closeDealModal}
								>
								</button>
							</div>
							<div className="modal-body">

								{/* // BUTTONS */}
								<div className="row text-start bg-light-blue rounded-top m-0 p-2">
									<div className="col-lg-3 col-md-12 col-md-12 col-sm-12">
										<button
											type="button"
											className="btn btn-primary m-1"
											title="New Deal"
											onClick={closeDealModal}
										>
											<img src={Clear} alt="Clear" /> Clear
										</button>
										<button
											type="button"
											className="btn btn-primary m-1"
											title="Cancel"
											onClick={closeDealModal}
										>
											<img src={Cancel} alt="AddImage" /> Cancel
										</button>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-12">
										{/* Search Bar */}
										{/* <div className="col-lg-2 col-md-2 col-sm-12 text-start"> */}
										{/* <label className='form-label'>Search</label> */}
										{
											/*
											 <input className="form-control form-control-sm m-1" type="text" placeholder="Search..." aria-label="Search"
										// onChange={searchbarChangeHandler}
										/>
											 */
										}
										<SearchBar
											data={dealMakers}
											setFilteredData={setFilteredData}
										/>
										{/* </div> */}

									</div>
								</div>
								{/* SELECT A DEAL TABLE */}
								<div className='row text-start'>
									<Table
										dealMakers={filteredData}
										loading={loading}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default DealModal