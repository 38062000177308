import ReactHookFormLookupModal from './ReactHookFormLookupModal';
import ActivityLookupColumns from './Columns/ActivityLookupColumns';
import { useState } from 'react';

const ActivityLookupModal = ({
	form,
	propertyName,
	url,
	afterSetValue,
	disabled,
	className
}) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			<button
				disabled={disabled}
				type='button'
				className={className || 'btn btn-sm btn-primary'}
				onClick={() => { setIsOpen(true) }}
			>
				Select Activity
			</button>
			<ReactHookFormLookupModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				url={url}
				columns={ActivityLookupColumns}
				modalTitle='Activity Lookup'
				form={form}
				propertyName={propertyName}
				propertyIdName='ACTIV_NUM'
				usePagination={false}
				afterSetValue={afterSetValue}
			/>
		</>
	);
};

export default ActivityLookupModal;