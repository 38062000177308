const CustomStyles = {
    table: {
        style: {
            top: '0px',
            right: '0px',
            borderTop: 'thick solid black',
            overflow: 'hidden',
        },
    },
    headRow: {
        style: {
            fontWeight: 'bold'
        },
    },
}

export default CustomStyles