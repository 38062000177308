import { useEffect, useState } from 'react'

// import custom components
import Spinner from '../../CustomComponents/Spinner';

// import custom date functions
import { toShortDate } from '../../../utils/DateUtils'

/* DCQ Table */
export default function NonDailyDCQTable({ componentState }) {

	const [totals, setTotals] = useState({})

	// Gets totals and formats numbers anytime monthly summary details change
	useEffect(() => {
		let newTotals = {}
		componentState.monthlySummaryDetails.forEach(
			detail => {
				Object.keys(detail).forEach(
					key => {
						if (typeof (detail[key]) === 'number') {
							if (!newTotals[key]) {
								newTotals[key] = detail[key] // set to total if not defined
							}
							else {
								newTotals[key] += detail[key] // else it is defined so just add to it
							}
							detail[key] = detail[key].toLocaleString("en-US", { minimumFractionDigits: 2 }) // format number
						}
					}
				)
			}
		)

		// format totals
		Object.keys(newTotals).forEach(
			key => {
				newTotals[key] = newTotals[key].toLocaleString("en-US", { minimumFractionDigits: 2 })
			}
		)

		// set state
		setTotals(newTotals)
	}, [componentState.monthlySummaryDetails])

	return (
		<div>
			<div className="d-flex"><caption>ADCQ Monthly Summary Details</caption></div>
			{
				componentState.monthlyTableLoading
					?
					<Spinner status="loading" />
					:
					componentState.monthlySummaryDetails.length > 0
						?
						<div className='table-responsive'>
							<table className="bg-white table table-sm table-hover table-striped-columns table-bordered border-primary rounded pb-1">
								<thead>
									<tr>
										<th>Pool ID</th>
										<th>Supplier</th>
										<th>Pool Status</th>
										<th>CG Nom</th>
										<th>ADCQ</th>
										<th>Sched Imbal</th>
										<th>Trade Qty</th>
										<th>Net Imbalance</th>
										<th>Tier 1</th>
										<th>Tier 2</th>
										<th>Tier 3</th>
										<th>Tier 4</th>
										<th>CASH OUT</th>
										<th>Updated By</th>
										<th>Updated On</th>
									</tr>
								</thead>
								<tbody>
									{
										componentState.monthlySummaryDetails.map(
											detail => {
												return (
													<tr key={detail.PT_MKPOOL_CHOICE_KEY}>
														<td>{detail.PointId}</td>
														<td>{detail.SupplierName}</td>
														<td>{detail.APPROVE_FLAG}</td>
														<td>{detail.CG_NOM_QTY}</td>
														<td>{detail.F_ENERGY_MO}</td>
														<td>{detail.F_CG_IMBAL}</td>
														<td>{detail.TRADE_QTY}</td>
														<td>{detail.F_CG_IMBAL_NET}</td>
														<td>{detail.TIER1_IMBAL}</td>
														<td>{detail.TIER2_IMBAL}</td>
														<td>{detail.TIER3_IMBAL}</td>
														<td>{detail.TIER4_IMBAL}</td>
														<td>{detail.CASHOUT}</td>
														<td>{detail.USERSTAMP}</td>
														<td>{toShortDate(detail.DATESTAMP)}</td>
													</tr>
												)
											}
										)
									}
									{
										// Totals
										<tr>
											<td></td>
											<td></td>
											<td>Totals:</td>
											<td>{totals.CG_NOM_QTY}</td>
											<td>{totals.F_ENERGY_MO}</td>
											<td>{totals.F_CG_IMBAL}</td>
											<td>{totals.TRADE_QTY}</td>
											<td>{totals.F_CG_IMBAL_NET}</td>
											<td>{totals.TIER1_IMBAL}</td>
											<td>{totals.TIER2_IMBAL}</td>
											<td>{totals.TIER3_IMBAL}</td>
											<td>{totals.TIER4_IMBAL}</td>
											<td>{totals.CASHOUT}</td>
											<td></td>
											<td></td>
										</tr>
									}
								</tbody>
							</table>
						</div>
						:
						<p className="text-center">No Records Found.</p>
			}
		</div>
	)
}