
export const columns = [
	{
		name: 'Name',
		selector: row => row.NAME,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	},
	{
		name: 'Duns #',
		selector: row => row.BA_USER_ID,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true
	},
	{
		name: 'BA ID',
		selector: row => row.BA_ID,
		sortable: true,
		compact: false,
		wrap: true,
		reorder: true

	}
];

export default columns;