import { useRef } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';

import excel from '../../../assets/images/excel.gif'
import './nondaily-table-style.css';
// import custom date functions
import { toShortDate } from '../../../utils/DateUtils'

/* Daily Pool Summary Table */
export default function NonDailyDailyPoolDetailsTable({ componentState }) {

	// tableRef for Excel Export:
	const tableRef = useRef();

	//Handling the Excel Download:
	const { onDownload } = useDownloadExcel({
		// currentTableRef: tableRef.current,
		currentTableRef: tableRef.current,
		filename: 'Non-Daily-Pool-Report-Invoice',
		sheet: 'Non-Daily-Pool-Report-Invoice'
	});

	return (
		<div className="bg-light-blue rounded table-responsive">
			<h6 className="p-2">Daily Pool Summary Details</h6>
			<button className='btn btn-sm btn-primary text-end m-1' onClick={onDownload}><img src={excel} alt='Export to excel' /> Export to Excel</button>
			{
				componentState.dailyTableLoading
					?
					<div className="container-fluid">
						<div className="row justify-content-center">
							<div className="col-auto">
								<p>Loading...</p>
							</div>
							<div className="col-auto">
								<div
									className="spinner-border spinner-border-sm"
									role="status"
									aria-hidden="true">
								</div>
							</div>
						</div>
					</div>
					:
					componentState.dailySummaryDetails.length > 0
						?
						<div className='nonDailyTableContainer'>
							<table className="bg-white table table-sm table-hover table-striped-columns table-bordered border-primary rounded pb-1"
								ref={tableRef}>
								<thead>
									<tr className='bg-white'>
										<th>Supplier</th>
										<th>Date</th>
										<th>Critical Period</th>
										<th>Nom/Sch</th>
										<th>Usage</th>
										<th>Imbalance</th>
										<th>Imbalance %</th>
										<th>Imbal Trade</th>
										<th>Net Imbalance</th>
										<th>Cum Net Imbalance</th>
										<th>Tier 1</th>
										<th>Tier 2</th>
										<th>Tier 3</th>
										<th>Tier 4</th>
										<th>CASH OUT</th>
										<th>M3-Min</th>
										<th>M3-Max</th>
										<th>Z6-Min</th>
										<th>Z6-Max</th>
										<th>Updated By</th>
										<th>Updated On</th>
									</tr>
								</thead>
								<tbody>
									{
										// Data
										componentState.dailySummaryDetails.map(
											detail => {
												// Row is green if net imbalance is positive, red if it is negative.
												let F_CG_IMBAL_NET = parseFloat(detail.F_CG_IMBAL_NET)
												let className = (detail.CRITICAL_DAY === "C" ? "fw-bold " : "") +
													(F_CG_IMBAL_NET > 0 ? "table-success" : F_CG_IMBAL_NET < 0 ? "table-danger" : "")
												return (
													<tr className={className}
														key={detail.GASFLOW_DATE + detail.PT_MKPOOL_CHOICE_KEY}
													>
														<td>{detail.CustomerName}</td>
														<td>{toShortDate(new Date(detail.GASFLOW_DATE))}</td>
														<td>{detail.CRITICAL_DAY}</td>
														<td>{detail.CG_NOM_QTY}</td>
														<td>{detail.F_ENERGY}</td>
														<td>{detail.F_CG_IMBAL}</td>
														<td>{detail.IMBAL_PCT}</td>
														<td>{detail.TRADE_QTY}</td>
														<td>{detail.F_CG_IMBAL_NET}</td>
														<td>{detail.cumImbal}</td>
														<td>{detail.TIER1_IMBAL}</td>
														<td>{detail.TIER2_IMBAL}</td>
														<td>{detail.TIER3_IMBAL}</td>
														<td>{detail.TIER4_IMBAL}</td>
														<td>{detail.CASHOUT}</td>
														<td>{detail.PLATT_M3MIN}</td>
														<td>{detail.PLATT_M3MAX}</td>
														<td>{detail.PLATT_Z6MIN}</td>
														<td>{detail.PLATT_Z6MAX}</td>
														<td>{detail.USERSTAMP}</td>
														<td>{toShortDate(new Date(detail.DATESTAMP))}</td>
													</tr>
												)
											}
										)
									}
									{
										// Totals row
										<tr className="border-top border-2 border-primary fw-bold">
											<td></td>
											<td></td>
											<td>Totals:</td>
											<td>{componentState.dailySummaryDtlTotals.CG_NOM_QTY}</td>
											<td>{componentState.dailySummaryDtlTotals.F_ENERGY}</td>
											<td>{componentState.dailySummaryDtlTotals.F_CG_IMBAL}</td>
											<td></td>
											<td>{componentState.dailySummaryDtlTotals.TRADE_QTY}</td>
											<td>{componentState.dailySummaryDtlTotals.F_CG_IMBAL_NET}</td>
											<td>{componentState.dailySummaryDtlTotals.cumImbal}</td>
											<td>{componentState.dailySummaryDtlTotals.TIER1_IMBAL}</td>
											<td>{componentState.dailySummaryDtlTotals.TIER2_IMBAL}</td>
											<td>{componentState.dailySummaryDtlTotals.TIER3_IMBAL}</td>
											<td>{componentState.dailySummaryDtlTotals.TIER4_IMBAL}</td>
											<td>{componentState.dailySummaryDtlTotals.CASHOUT}</td>
											<td>{componentState.dailySummaryDtlTotals.PLATT_M3MIN}</td>
											<td>{componentState.dailySummaryDtlTotals.PLATT_M3MAX}</td>
											<td>{componentState.dailySummaryDtlTotals.PLATT_Z6MIN}</td>
											<td>{componentState.dailySummaryDtlTotals.PLATT_Z6MAX}</td>
											<td></td>
											<td></td>
										</tr>
									}
								</tbody>
							</table>
						</div>
						:
						<p className="text-center">No Records Found.</p>
			}
		</div>
	)
}