import { createSlice } from "@reduxjs/toolkit";
import { deleteRecord, getData, postNewData } from "../utils/APIUtils";
import { notification_actions } from "./notification-slice";
import { getDayStart, toISOStringForDatabase } from '../utils/DateUtils';
import { fetchPkgPaths } from "../utils/GeneralNomUtils";

// Local Storage items
const filters = sessionStorage.getItem('intNoms_filters');
const selectedPackage = sessionStorage.getItem('intNoms_selectedPackage');

const initialState = {
	filters: filters ? JSON.parse(filters) : { // These have to be in a filters object so that "useBusinessSelector" can be used in the main interstate noms screen.
		gasFlowDate: getDayStart(new Date()).toISOString(),
		selectedSupplier: {},
		displayUnits: '',
		selectedContract: {},
	},
	selectedPkgPathDtl: {},
	selectedPackage: selectedPackage ? JSON.parse(selectedPackage) : {},
	pkgPathDtls: [],
	packages: [],
	intNomsTableLoading: false,
	pkgPathsFetchLoading: false,
	modalStates: {
		activityLookupModalState: {
			loading: false,
			open: false
		},
		openNomUiModal: {
			loading: false,
			open: false
		},
		nomDetailModalState: {
			loading: false,
			open: false
		},
		requestorLookupModalState: {
			loading: false,
			open: false
		}
	},
	recPaths: [],
	delPaths: [],
	fuelPercent: 0
};



const interstateNomsSlice = createSlice({
	name: 'interstateNomsSlice',
	initialState,
	reducers: {
		setFilters(state, action) {
			const newFilters = action.payload;
			state.filters = newFilters;
			sessionStorage.setItem('intNoms_filters', JSON.stringify(newFilters)); // For when user refreshes "addIntNom" page.
		},
		setSelectedPkgPathDtl(state, action) {
			state.selectedPkgPathDtl = action.payload;
		},
		setSelectedPackage(state, action) {
			const newSelectedPackage = action.payload;
			state.selectedPackage = newSelectedPackage;
			sessionStorage.setItem('intNoms_selectedPackage', JSON.stringify(newSelectedPackage)); // For when user refreshes "addIntNom" page.
		},
		setSelectedContract(state, action) {
			state.selectedContract = action.payload;
		},
		changeModalState(state, action) {
			const {
				modalStateName,
				newModalState
			} = action.payload;
			state.modalStates = {
				...state.modalStates,
				[modalStateName]: newModalState
			};
		},
		setPkgPathDtls(state, action) {
			state.pkgPathDtls = action.payload;
		},
		setIntNomsTableLoading(state, action) {
			state.intNomsTableLoading = action.payload;
		},
		setPkgPathsFetchLoading(state, action) {
			state.pkgPathsFetchLoading = action.payload;
		},
		setRecPaths(state, action) {
			state.recPaths = action.payload;
		},
		setDelPaths(state, action) {
			state.delPaths = action.payload;
		},
		openAddNomModal(state) {
			state.modalStates.addNomModalState.open = true;
		},
		openNomUiModal(state) {
			state.modalStates.openNomUiModal.open = true;
		},
		setPackages(state, action) {
			state.packages = action.payload;
		}
	}
});

export const setPackageAndFetchPkgPaths = (selectedPackage) => {
	return async (dispatch) => {
		try {
			await dispatch(interstate_noms_actions.setPkgPathsFetchLoading(true));

			// Fetch package paths for package.
			const package_num = selectedPackage?.PACKAGE_NUM;
			const pkgPaths = await fetchPkgPaths(package_num);

			// Update package with new package paths.
			const newSelectedPackage = { ...selectedPackage, ENT_PKG_PATH: pkgPaths };
			await dispatch(interstate_noms_actions.setSelectedPackage(newSelectedPackage));
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
		finally {
			await dispatch(interstate_noms_actions.setPkgPathsFetchLoading(false));
		}
	};
};

/**
 * I wanted to try to keep all API fetches within the actual components themselves. However, I ran
 * into an issue where when Nom details were finished saving, I need to re-fetch the packages in
 * the main int nom table. After trying various things I decided it would just be simpler to 
 * create a thunk that I can just call from anywhere. - Cameron
 */
let fetchPkgsAbrtCntlr = null; // Used to store abort controller, which is used to abort previous requests.
const fetchPkgsCleanup = async (dispatch) => {
	if (fetchPkgsAbrtCntlr?.signal && !fetchPkgsAbrtCntlr.signal.aborted)
		fetchPkgsAbrtCntlr.abort();
	fetchPkgsAbrtCntlr = null;
	await dispatch(interstate_noms_actions.setIntNomsTableLoading(false));
};
export const fetchPackages = (filters) => {
	return async (dispatch) => {
		if (!filters?.selectedContract?.CNTR_NUM) {
			// This code must be run in the event the contract is set to blank
			// but a request has already been made with the previous selected contract.
			await fetchPkgsCleanup(dispatch);
			await dispatch(interstate_noms_actions.setPackages([]));
			return;
		}
		if (filters?.gasFlowDate && filters?.selectedContract?.CNTR_NUM) {

			// Abort the previous request if there is one.
			if (fetchPkgsAbrtCntlr) {
				fetchPkgsAbrtCntlr.abort();
			}

			// Set new abort controller.
			fetchPkgsAbrtCntlr = new AbortController();
			const { signal } = fetchPkgsAbrtCntlr;

			try {
				await dispatch(interstate_noms_actions.setIntNomsTableLoading(true));

				const url = `Package/GetPackages` +
					`?gasFlowDate=${toISOStringForDatabase(filters?.gasFlowDate)}` +
					`&cntr_num=${filters?.selectedContract?.CNTR_NUM}`;

				// Fetch data.
				const response = await fetch(url, { signal: signal });

				if (signal.aborted)
					return;

				if (!response.ok) {
					const message = await response.text();
					throw new Error(message);
				}

				// Set data.
				const newPackages = await response.json();
				await dispatch(interstate_noms_actions.setPackages(newPackages));

				// Run clean up.
				fetchPkgsCleanup(dispatch);
			}
			catch (error) {
				if (error.name === 'AbortError') {
					// Do nothing if previous request was aborted. The second request might
					// still be loading when the first request is aborted, so setting loading
					// to false after aborting would be a mistake.
					//console.log('Request was aborted.');
				}
				else {
					// If regular error, display it then run cleanup.
					await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
					fetchPkgsCleanup(dispatch);
				}
			}
		}
	};
};

export const fetchPkgPathDtls = (url) => {
	return async (dispatch) => {
		try {
			await dispatch(interstate_noms_actions.setInterstateNomsTableLoading(true));
			const newInterstateNoms = await getData(url);
			await dispatch(interstate_noms_actions.setPkgPathDtls(newInterstateNoms));
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
		finally {
			await dispatch(interstate_noms_actions.setInterstateNomsTableLoading(false));
		}
	};
};

export const createPackage = ({ selectedPackage, editPkgPathDtlModalState }) => {
	return async (dispatch) => {
		try {
			await dispatch(interstate_noms_actions.changeModalState({
				modalStateName: 'editPkgPathDtlModalState',
				newModalState: { ...editPkgPathDtlModalState, loading: true }
			}));
			const newPackage = await postNewData('Package/CreatePackage', selectedPackage);
			await dispatch(interstate_noms_actions.setSelectedPackage(newPackage));
			await dispatch(notification_actions.showModal({ header: 'Success!', message: 'Nomination was created successfully.' }));
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
		finally {
			await dispatch(interstate_noms_actions.changeModalState({
				modalStateName: 'editPkgPathDtlModalState',
				newModalState: { ...editPkgPathDtlModalState, loading: false }
			}));
		}
	};
};

export const deletePackage = ({ package_num, editPkgPathDtlModalState }) => {
	return async (dispatch) => {
		if (!package_num)
			return;
		try {
			await dispatch(interstate_noms_actions.changeModalState({
				modalStateName: 'editPkgPathDtlModalState',
				newModalState: { ...editPkgPathDtlModalState, loading: true }
			}));
			await deleteRecord(`Package/DeletePackage?package_num=${package_num}`);
			await dispatch(notification_actions.showModal({ header: 'Success!', message: 'Nomination was deleted successfully.' }));
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
		finally {
			await dispatch(interstate_noms_actions.changeModalState({
				modalStateName: 'editPkgPathDtlModalState',
				newModalState: { ...editPkgPathDtlModalState, loading: false }
			}));
		}
	};
};

export const interstate_noms_actions = interstateNomsSlice.actions;
export const { openAddNomModal, closeAddNomModal, openNomUiModal, closeNomUiModal, openNomDetailModal } = interstateNomsSlice.actions;

export default interstateNomsSlice.reducer;