import { createSlice } from "@reduxjs/toolkit";

const whpurschase_slice = createSlice({
    name: "WHPurschase",
    initialState: {
        whContractStls: [],
        selectedContractWHStl: {},
        loading: true,
    },
    reducers: {
        setWHContractStls: (state, action) => {
            state.whContractStls = action.payload;
        },
        setSelectedContractWHStl: (state, action) => {
            state.selectedContractWHStl = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        }
    },
});

export const whpurschase_actions = whpurschase_slice.actions;
export default whpurschase_slice.reducer;