import React from 'react';
import excel from '../../../../assets/images/excel.gif';
import * as XLSX from 'xlsx';

function transformData(data, isDTH) {
    return data.map(row => ({
        // ... Add all the Table's"Column" fields that you want to include in the export
        Date: new Date(row?.METERDATE).toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        }),
        "Act Vol": isDTH && row.DailyVolume_unt ? (row.DailyVolume_unt / 10).toLocaleString() : row.DailyVolume_unt?.toLocaleString(),
        //"BTU Factor": row.BTU_FACTOR,
        "Act Energy": row.ActEnergy_unt?.toLocaleString(),
        "Cum Vol": isDTH && row.CUMDAILYVOL ? (row.CUMDAILYVOL / 10).toLocaleString() : row.CUMDAILYVOL?.toLocaleString(),
        "Cum Energy": row.CUMENERGY?.toLocaleString(),
        //"Cum BTU Factor": isDTH && row.BTUFactorCum.toLocaleString() ? (row.BTUFactorCum * 10).toLocaleString() : row.BTUFactorCum,
        "PPA Date": row.PPADATE == null ? "" : new Date(row.PPADATE).toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
        }),
        "Updated By": row.USERSTAMP,
        "Updated On": new Date(row?.DATESTAMP).toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }),
    }));
}

function createExcelFile(data) {
    // Truncate the cell contents if they exceed the maximum allowed length
    const truncatedData = data.map(row =>
        Object.fromEntries(
            Object.entries(row).map(([key, value]) => [key, value && value.toString().substring(0, 32767)])
        )
    );

    const worksheet = XLSX.utils.json_to_sheet(truncatedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
}

// Do NOT delete this code, this code is for unfiltered tables:
// function downloadExcelFile(data) {
//     const link = document.createElement('a');
//     const blob = createExcelFile(data);
//     const url = URL.createObjectURL(blob);
//     link.href = url;
//     link.download = 'Measurement_Report.xlsx';
//     link.click();
//     setTimeout(() => {
//         URL.revokeObjectURL(url);
//     }, 100);
// }
function downloadExcelFile(data, isDTH) {
    const transformedData = transformData(data, isDTH);
    const link = document.createElement('a');
    const blob = createExcelFile(transformedData);
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = 'Daily_Measurement_Report.xlsx';
    link.click();
    setTimeout(() => {
        URL.revokeObjectURL(url);
    }, 100);
}

const Export = ({ onExport }) => (
    <button className="btn btn-md btn-primary me-2 mb-1" onClick={() => onExport()}>
        <img src={excel} alt="Export Excel Report" /> Export
    </button>
);

const ExportExcel = ({ data, isDTH }) => {
    const actionsMemo = React.useMemo(() => <Export onExport={() => downloadExcelFile(data, isDTH)} />, [data]);

    return actionsMemo;
};

export default ExportExcel;
