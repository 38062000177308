import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';

import "./style.css";

import Spinner2 from "../../../CustomComponents/Spinner2";

import { fetchDisplayUnits, fetchHourlyPMMeasurement } from "../../../../store/daily-measurement-thunks";
import { toDatePickerString, getMonthEnd } from "../../../../utils/DateUtils";
import ExportExcel from "./ExportExcel";
import { dailyMeasurement_actions } from "../../../../store/daily-measurement-slice";
import HourlyMeasurementTable from "./HourlyMeasurementTable";

const customStyles = {
    overlay: {
        backgroundColor: 'rgb(0,0,0,.60)'
    },
};

function HourlyMeasurementModal({ passingParams }) {
    // Redux and States
    const dispatch = useDispatch();

    const selectedContractsData = useSelector(state => state.measurement.selectedContractsData);

    const openModal = useSelector(state => state.dailyMeasurement.showHourlyModal);
    const displayUnits = useSelector(state => state.dailyMeasurement.pmDisplayUnits);
    const loading = useSelector(state => state.dailyMeasurement.loading);
    const ptNum = useSelector(state => state.dailyMeasurement.ptNum);
    const hourlyPMMeasurementDataTable = useSelector(state => state.dailyMeasurement.hourlyPMMeasurementDataTable);
    const selectedDateHourly = useSelector(state => state.dailyMeasurement.selectedDateHourly);

    const [fromGasFlowDate, setFromGasFlowDate] = useState(new Date(passingParams?.fromGasFlowDate));
    const [toGasFlowDate, setToGasFlowDate] = useState(getMonthEnd(passingParams?.toGasFlowDate));
    const [displayUnit, setDisplayUnit] = useState(displayUnits.length > 0 ? displayUnits[0] : { Key: '', Value: '' });
    const [lastMeasurementItem, setLastMeasurementItem] = useState({});
    const [isDTH, setIsDTH] = useState(true);

    // Use Effects
    useEffect(() => {
        dispatch(fetchDisplayUnits({ IsPMMeasurement: true }));
    }, [dispatch])

    useEffect(() => {
        setFromGasFlowDate(selectedDateHourly ? new Date(selectedDateHourly) : new Date());
        setToGasFlowDate(selectedDateHourly ? new Date(selectedDateHourly) : new Date());
    }, [selectedDateHourly])

    useEffect(() => {
        if (displayUnits.length > 0) setDisplayUnit(displayUnits[0]);
    }, [displayUnits])

    useEffect(() => {
        if (hourlyPMMeasurementDataTable.length > 0) {
            setLastMeasurementItem(hourlyPMMeasurementDataTable[hourlyPMMeasurementDataTable.length - 1]);
        }
        else {
            setLastMeasurementItem({})
        }
    }, [hourlyPMMeasurementDataTable])

    /*useEffect(() => { // get data for table
        dispatch(fetchHourlyPMMeasurement(
            {
                selectedDate: selectedDateHourly,
                pointID: selectedContractsData?.PointShortName
            }
        )); 
    }, [selectedDateHourly])*/

    // Handlers
    const selectedFromGasFlowDateChange = (event) => {
        if (!event.target.value) return;
        setFromGasFlowDate(event.target.value);
        setToGasFlowDate(getMonthEnd(event.target.value));
    }

    const selectedToGasFlowDateChange = (event) => {
        if (!event.target.value) return;
        setToGasFlowDate(event.target.value);
    }

    const selectedDisplayUnitChange = (event) => {
        if (!event.target.value) return;

        let selectedDisplayUnitValue = event.target.value;
        let selectedDisplayUnit = displayUnits.find(u => u.Value === selectedDisplayUnitValue);
        setDisplayUnit(selectedDisplayUnit);

        if (selectedDisplayUnit?.Key === "MC") setIsDTH(true);
        else setIsDTH(false);
    }

    return (
        <>
            <Modal
                isOpen={openModal}
                onRequestClose={() => dispatch(dailyMeasurement_actions.setShowHourlyModal(false))}
                className="daily-modal container-fluid bg-white text-black"
                style={customStyles}
            >
                <div id="DailyMeasurementModal" tabIndex='-1'>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title mb-2">Hourly Measurement UI</h4>
                                <button
                                    type="button"
                                    className="btn-close fs-6"
                                    onClick={() => dispatch(dailyMeasurement_actions.setShowHourlyModal(false))}
                                />
                            </div>
                            <div className="row ">
                                <div className="col-sm-12 col-md-12 col-lg-12 mt-0">
                                    <ExportExcel data={hourlyPMMeasurementDataTable} isDTH={isDTH} />
                                </div>
                            </div>
                            <div className="modal-toolbar">
                                <div className="text-start bg-light-blue rounded m-0 p-2">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">Pipeline</label>
                                            <input
                                                className="form-control form-control-sm"
                                                aria-label="select public or private"
                                                value={selectedContractsData.ProducerName}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">Business Unit</label>
                                            <input
                                                className="form-control form-control-sm"
                                                value={passingParams.businessUnit?.NAME}
                                                aria-label="select public or private"
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">Point Name</label>
                                            <input
                                                className="form-control form-control-sm"
                                                value={selectedContractsData.PT_NAME}
                                                aria-label="select public or private"
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">Point Number</label>
                                            <input
                                                className="form-control form-control-sm"
                                                aria-label="select public or private"
                                                value={loading === true ? 'Loading...' : ptNum}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">MIDNO #</label>
                                            <input
                                                className="form-control form-control-sm"
                                                aria-label="select public or private"
                                                value={selectedContractsData?.PointShortName}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">Start Date</label>
                                            <input
                                                name="startDate"
                                                className="form-control form-control-sm"
                                                type="date"
                                                onChange={selectedFromGasFlowDateChange}
                                                value={toDatePickerString(fromGasFlowDate)}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">End Date</label>
                                            <input
                                                name="endDate"
                                                className="form-control form-control-sm"
                                                type="date"
                                                onChange={selectedToGasFlowDateChange}
                                                value={toDatePickerString(toGasFlowDate)}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">Account #</label>
                                            <input
                                                className="form-control form-control-sm"
                                                aria-label="select public or private"
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">Display Units</label>
                                            <select
                                                className="form-select form-select-sm"
                                                type="form-select"
                                                onChange={selectedDisplayUnitChange}
                                                value={displayUnit.Value}
                                            >
                                                {
                                                    displayUnits?.map(displayUnit => {
                                                        return (
                                                            <option
                                                                key={displayUnit.Key}
                                                                value={displayUnit.value}
                                                            >
                                                                {displayUnit.Value}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">Total Energy</label>
                                            <input
                                                className="form-control form-control-sm"
                                                aria-label="select public or private"
                                                value={loading === true ? "" : lastMeasurementItem?.CUMENERGY?.toLocaleString()}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <label className="form-label">Total Vol</label>
                                            <input
                                                className="form-control form-control-sm"
                                                aria-label="select public or private"
                                                value={
                                                    loading === true ? "" :
                                                        isDTH && lastMeasurementItem.CUMDAILYVOL
                                                            ? (lastMeasurementItem.CUMDAILYVOL / 10).toLocaleString()
                                                            : lastMeasurementItem.CUMDAILYVOL?.toLocaleString()
                                                }
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                {loading === true ? <Spinner2 /> : <HourlyMeasurementTable data={hourlyPMMeasurementDataTable} isDTH={isDTH} />}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default HourlyMeasurementModal;