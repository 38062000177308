
const GasAllocationTableColumns = [
  {
    name: "Svc Req K",
    selector: row => row.SvcReqK,
    sortable: false,
    compact: true,
    wrap: true,
  },
  {
    name: "PPA",
    selector: row => row.PPA,
    sortable: false,
    compact: true,
    wrap: false,
  },
  {
    name: "Svc Req Name",
    selector: row => row.SvcReqName,
    sortable: false,
    compact: true,
    wrap: true,
  },
  {
    name: "Production Month",
    selector: row => row.ProductionMonth,
    sortable: false,
    compact: true,
    wrap: true,
  },
  {
    name: "Flow Dir",
    selector: row => row.FlowDirection,
    sortable: false,
    compact: true,
    wrap: false,
  },
  {
    name: "Loc ID",
    selector: row => row.LocationID,
    sortable: false,
    compact: true,
    wrap: true,
  },
  {
    name: "Location Name",
    selector: row => row.LocationName,
    sortable: false,
    compact: true,
    wrap: true,
  },
  {
    name: "Package ID",
    selector: row => row.PackageID,
    sortable: false,
    compact: true,
    wrap: true,
  },
  {
    name: "Alloc DTH",
    selector: row => row.AllocDth.toLocaleString(),
    sortable: false,
    compact: true,
    wrap: true,
  },
  {
    name: "Sched DTH",
    selector: row => row.SchedDth.toLocaleString(),
    sortable: false,
    compact: true,
    wrap: true,
  },
  {
    name: "Nom DTH",
    selector: row => row.NomDth.toLocaleString(),
    sortable: false,
    compact: true,
    wrap: true,
  },
  {
    name: "Diff Qty DTH",
    selector: row => row.DiffQtyDth.toLocaleString(),
    sortable: false,
    compact: true,
    wrap: true,
  }];

export default GasAllocationTableColumns;
