import { createSlice } from '@reduxjs/toolkit';
import { getDayStart, getNextDay, setTimeToNine, getMonthStart } from '../utils/DateUtils';
import { fetchPkgPaths } from '../utils/GeneralNomUtils';
import { notification_actions, displayError, displaySuccess } from './notification-slice';

const initialState = {
	selectedPackage: {},
	selectedPkgPathInfoObj: {},
	latestApprovalDate: null,
	NonPathNoms: [],
	NonPathNomsFilters: {
		selectedContract: {},
		gasFlowDate: getDayStart(getNextDay(new Date())).toISOString()
	},
	shouldResetForm: false,
	canEditPackage: false,
	options: {
		title: '', // What should be the title for the main screen?
		contractTypes: [], // What contract types should we use for the contract drop down?
		numberFormat: { // Should we round? If so, to what decimal place?
			shouldRound: false,
			decimalPlace: 0
		},
		buttons: [ // What buttons will be displayed at the top?
			'AddNom',
			'EstimatedFlow',
			'GasAllocation',
			'OBAStatement',
			'SchedQtyDetail',
		]
	},
	NonPathNomsFetchLoading: false,
	pkgPathsFetchLoading: false,
	GasAllocationData: [],
	GasAllocationDataFetchLoading: false,
	GasAllocationFilters: {
		selectedContract: {},
		gasFlowDate: getMonthStart(new Date()).toISOString(),
		mode: ""
	},
	modalStates: {
		addNomModalState: {
			loading: false,
			open: false
		},
		specifyLocationQuantityModalState: {
			loading: false,
			open: false,
			previousModalState: {}, // Storing previous modal information so that it is possible to return to previous modal.
			previousModalStateName: ''
		},
		nomDetailModalState: {
			loading: false,
			open: false
		},
		viewDetailsModalState: {
			loading: false,
			open: false,
			selectedPkgPath: {},
			previousModalState: {},
			previousModalStateName: '',
			selectedDirection: "",
			canEditTable: false,
		},
		estimatedFlowModalState: {
			loading: false,
			open: false
		},
		obaStatementModalState: {
			loading: false,
			open: false,
			obaStatementData: [],
			selectedContract: {},
		},
		schedQtyDetailModalState: {
			loading: false,
			open: false,
			selectedContract: {},
			monthlyNoms: [],
			gasFlowDate: getMonthStart(new Date()).toISOString(),
		}
	}
};

const non_path_noms_slice = createSlice({
	name: 'non_path_noms_slice',
	initialState,
	reducers: {
		setSelectedPackage(state, action) {
			state.selectedPackage = action.payload;
		},
		setSelectedPkgPathInfoObj(state, action) {
			state.selectedPkgPathInfoObj = action.payload;
		},
		setLatestApprovalDate(state, action) {
			state.latestApprovalDate = action.payload;
		},
		setNonPathNoms(state, action) {
			state.NonPathNoms = action.payload;
		},
		setNonPathNomsFetchLoading(state, action) {
			state.NonPathNomsFetchLoading = action.payload;
		},
		setPkgPathsFetchLoading(state, action) {
			state.pkgPathsFetchLoading = action.payload;
		},
		setNonPathNomsFilters(state, action) {
			const newFilters = action.payload;
			state.NonPathNomsFilters = newFilters;
		},
		setShouldResetForm(state, action) { // This controls whether the add nom form will be reset if the selected contract or package changes.
			state.shouldResetForm = action.payload;
		},
		setCanEditPackage(state, action) {
			state.canEditPackage = action.payload;
		},
		setSelectedContract(state, action) {
			state.selectedContract = action.payload;
		},
		changeModalState(state, action) {
			const { newModalState, modalStateName } = action.payload;
			state.modalStates[modalStateName] = newModalState;
		},
		setOptions(state, action) {
			const newOptions = action.payload;
			state.options = newOptions;
		},
		setGasAllocationData(state, action) {
			state.GasAllocationData = action.payload;
		},
		setGasAllocationDataFetchLoading(state, action) {
			state.GasAllocationDataFetchLoading = action.payload;
		},
		setSelectedGAContract(state, action) {
			state.GasAllocationFilters.selectedGAContract = action.payload;
		},
		setGasAllocationFilters(state, action) {
			state.GasAllocationFilters = action.payload;
		},
		reset() {
			return initialState;
		},
		updatePkgDtls(state, action) {
			const newPkgVolDtls = action.payload;
			const prevPkg = state.selectedPkgPath;
			const prevPkgNum = prevPkg?.PACKAGE_NUM;
			const prevPkgPath = prevPkgNum?.ENT_PKG_PATH?.[0];
			if (newPkgVolDtls && prevPkg && prevPkgNum && prevPkgPath) {
				const newPkgVols = {
					...prevPkgPath,
					ENT_PKG_VOL_DTL: newPkgVolDtls
				};
				const newPkgPath = {
					...prevPkg,
					ENT_PKG_PATH: [newPkgVols]
				};
				const newPkg = {
					...prevPkg,
					PACKAGE_NUM: newPkgPath
				}
				state.selectedPkg = newPkg;
			}
		},
	}
});

let packagesFetchAbortController = null; // To be used to abort previous requests.
const packagesFetchCleanup = async (dispatch) => {
	// Abort if not aborted.
	if (packagesFetchAbortController?.signal && !packagesFetchAbortController.signal.aborted)
		packagesFetchAbortController.abort();
	packagesFetchAbortController = null;
	await dispatch(non_path_noms_actions.setNonPathNomsFetchLoading(false));
};
export const fetchPackages = (NonPathNomsFilters) => {
	return async (dispatch) => {
		try {
			// Clear old data when a new request comes in. This way if the selected contract
			// or gas flow date is null, the old data doesn't persist.
			await dispatch(non_path_noms_actions.setNonPathNoms([]));

			// URL variables
			const cntr_num = NonPathNomsFilters?.selectedContract?.CNTR_NUM;
			const gasFlowDate = setTimeToNine(NonPathNomsFilters?.gasFlowDate).toISOString();

			// Check for nulls
			if (!cntr_num || !gasFlowDate) {
				// Need to run clean up in case there was any previous requests still ongoing.
				packagesFetchCleanup(dispatch);
				return;
			}
			const url = `Package/GetPackages?cntr_num=${cntr_num}&gasFlowDate=${gasFlowDate}`;

			// Abort previous request
			if (packagesFetchAbortController) packagesFetchAbortController.abort();

			// Create new abort controller and grab new signal.
			packagesFetchAbortController = new AbortController();
			const { signal } = packagesFetchAbortController;

			// Make request.
			await dispatch(non_path_noms_actions.setNonPathNomsFetchLoading(true));
			const response = await fetch(url, { signal: signal });

			// Handle errors.
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			// Update data.
			const newGANoms = await response.json();
			await dispatch(non_path_noms_actions.setNonPathNoms(newGANoms));

			// Perform cleanup.
			packagesFetchCleanup(dispatch);
		}
		catch (error) {
			if (error.name === 'AbortError') {
				// If the error is from an aborted request, just log to console.
				// A cleanup should not be performed here because another request is
				// still on-going.
				console.log(error.message);
			}
			else {
				// Display error and perform cleanup.
				await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
				packagesFetchCleanup(dispatch);
			}
		}
	};
};

export const fetchMonthlyPackage = (schedQtyDetailModalState, contract, gasFlowDate) => {
	return async (dispatch) => {
		try {
			// reset data
			dispatch(non_path_noms_actions.changeModalState({
				modalStateName: 'schedQtyDetailModalState',
				newModalState: { ...schedQtyDetailModalState, monthlyNoms: []},
			}));

			// check params
			const cntr_num = contract?.CNTR_NUM;
			const _gasFlowDate = setTimeToNine(gasFlowDate).toISOString();
			if (!cntr_num || !_gasFlowDate) return;

			// fetch data
			await dispatch(non_path_noms_actions.changeModalState({
				modalStateName: 'schedQtyDetailModalState',
				newModalState: { ...schedQtyDetailModalState, loading: true},
			}));
			
			const queryString = new URLSearchParams({
				cntr_num: cntr_num,
				gasFlowDate: _gasFlowDate,
			}).toString();

			const url = `Package/GetMonthlyPackages?${queryString}`
			const res = await fetch(url);

			if (!res.ok) {
				const message = await res.text();
				throw new Error(message);
			}

			// set result to monthly noms
			const monthlyNoms = await res.json();
			if (!Array.isArray(monthlyNoms) || monthlyNoms.length === 0) 
			{
				await dispatch(non_path_noms_actions.changeModalState({
					modalStateName: 'schedQtyDetailModalState',
					newModalState: { ...schedQtyDetailModalState, monthlyNoms: [], loading: false},
				}));
				return;
			}
			
			const getPckgWithPaths = async () => {
				let result = [];

				for (const nom of monthlyNoms) {
					const pkgPaths = await fetchPkgPaths(nom?.PACKAGE_NUM);
					const nomWithPkgPath = { ...nom, ENT_PKG_PATH: pkgPaths };
					result.push(nomWithPkgPath)
				}

				return result
			}
			const pckgWithPaths = await getPckgWithPaths();

			await dispatch(non_path_noms_actions.changeModalState({
				modalStateName: 'schedQtyDetailModalState',
				newModalState: { ...schedQtyDetailModalState, monthlyNoms: pckgWithPaths, loading: false},
			}));
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
			await dispatch(non_path_noms_actions.changeModalState({
				modalStateName: 'schedQtyDetailModalState',
				newModalState: { ...schedQtyDetailModalState, loading: false},
			}));
		}
	}
}

export const setPackageAndFetchPkgPaths = (selectedPackage, isCopy) => {
	return async (dispatch) => {
		try {
			await dispatch(non_path_noms_actions.setPkgPathsFetchLoading(true));

			// Fetch package paths for package.
			const package_num = selectedPackage?.PACKAGE_NUM;
			const pkgPaths = await fetchPkgPaths(package_num);

			// Update package with new package paths.
			let newSelectedPackage;
			if (isCopy) {
				newSelectedPackage = {
					...selectedPackage,
					ENT_PKG_PATH: pkgPaths.map(pkgPath => { // null out package and path nums if this is a copy
						return {
							...pkgPath,
							PATH_NUM: null,
							PACKAGE_NUM: null
						};
					}),
					PACKAGE_NUM: null,
					isCopy: true // Tells "useNonPathFormUpdate" to update the form based on the package even though the PACKAGE_NUM is null.
				};
			}
			else {
				newSelectedPackage = {
					...selectedPackage,
					ENT_PKG_PATH: pkgPaths,
					isCopy: false // Tells "useNonPathFormUpdate" to update the form based on the package even though the PACKAGE_NUM is null.
				};
			}
			await dispatch(non_path_noms_actions.setSelectedPackage(newSelectedPackage));

			// Allow the form to reset.
			await dispatch(non_path_noms_actions.setShouldResetForm(true));
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
		finally {
			await dispatch(non_path_noms_actions.setPkgPathsFetchLoading(false));
		}
	};
};


let fetchGADataAbrtCntlr = null;
const GasAllocationFetchCleanup = async (dispatch) => {
	// Abort if not aborted.
	if (fetchGADataAbrtCntlr?.signal && !fetchGADataAbrtCntlr.signal.aborted)
		fetchGADataAbrtCntlr.abort();
	fetchGADataAbrtCntlr = null;
	await dispatch(non_path_noms_actions.setGasAllocationDataFetchLoading(false));
};

export const fetchGasAllocationData = (GasAllocationFilters, selectedSupplier) => {
	return async (dispatch) => {
		try {
			await dispatch(non_path_noms_actions.setGasAllocationDataFetchLoading(true));
			await dispatch(non_path_noms_actions.setGasAllocationData([]));
			//console.log(selectedSupplier);

			// URL variables
			var baID = selectedSupplier.BA_ID;
			var month = GasAllocationFilters?.gasFlowDate;
			var cntr_num = GasAllocationFilters?.selectedContract?.CNTR_NUM; // can be "N/A" and converts to empty string in controller
			var mode = GasAllocationFilters?.mode;
			//console.log("month:", month);
			// Check for nulls
			if (!cntr_num || !month || !baID || !mode) {
				// Need to run clean up in case there was any previous requests still ongoing.
				await dispatch(non_path_noms_actions.setGasAllocationDataFetchLoading(false));
				return;
			}
			const url = `GasAllocation/GetGasAllocationData` +
				`?baID=${baID}` +
				`&month=${month}` +
				`&cntrNum=${cntr_num}` +
				`&mode=${mode}`;
			// Abort the previous request if there is one.
			if (fetchGADataAbrtCntlr) {
				fetchGADataAbrtCntlr.abort();
			}
			// Set new abort controller.
			fetchGADataAbrtCntlr = new AbortController();
			const signal = fetchGADataAbrtCntlr.signal;

			// Fetch data.
			const response = await fetch(url, { signal: signal });
			console.log("response:", response);

			// Handle errors.
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			// Update data.
			const newGasAllocationData = await response.json();
			await dispatch(non_path_noms_actions.setGasAllocationData(newGasAllocationData));
			GasAllocationFetchCleanup(dispatch);
		}
		catch (error) {
			if (error.name === 'AbortError') {
				// Do nothing if previous request was aborted. The second request might
				// still be loading when the first request is aborted
				console.log('Request was aborted.');
				return;
			}
			await dispatch(displayError(`Server response came back with an error: ${error.message}`));
			GasAllocationFetchCleanup(dispatch);
		}
	}
}

/**
 * Fetches nom cycle based on package start date.
 * @param {any} pkg_start_date
 */
export const determineCycle = (pkg_start_date) => {
	return async (dispatch) => {
		try {
			if (!pkg_start_date) return;

			// Abort previous request:
			if (determineCycle.abortController)
				determineCycle.abortController.abort();

			// Create new abort controller:
			determineCycle.abortController = new AbortController();

			// Put new signal in response so that previous request can be aborted if another comes in.
			const { signal } = determineCycle.abortController;

			const response = await fetch(`Package/DetermineCycle?pkg_start_date=${pkg_start_date}`, { signal });

			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			const newNomCycle = await response.text();
			return newNomCycle;
		}
		catch (error) {
			if (error.name === 'AbortError') return;
			await dispatch(displayError(error.message));
		}
	};
};

export const updatePackage = (modifiedPackage) => {
	return async (dispatch) => {
		try {
			// remove a front-end only property before sending it to the back-end
			if (modifiedPackage.hasOwnProperty('isCopy')) delete modifiedPackage.isCopy; 

			// Send new package to database to be saved.
			const response = await fetch('Package/UpdatePackage', {
				method: 'POST',
				headers: { 'Content-type': 'application/json' },
				body: JSON.stringify(modifiedPackage)
			});

			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}
			// Deserialize package from server and set it as selected package.
			const newPackage = await response.json();
			await dispatch(setPackageAndFetchPkgPaths(newPackage));
			await dispatch(displaySuccess("Package updated successfully!"));
		}
		catch (error) {
			await dispatch(displayError(error.message));
		}
	}
}


export const non_path_noms_actions = non_path_noms_slice.actions;
export default non_path_noms_slice.reducer;