import DataTable from "react-data-table-component";
import Spinner from "../../Spinner";
import DailyReadTableColumns from "./DailyReadTableColumns";
import DailyReadTableStyles from './DailyReadTableStyles';

function DailyReadTable({ dailyData, loading }) {
  // Custom style for totals row
  /*const conditionalRowStyles = [
    {
      when: row => row.Company === 'Totals:',
      classNames: ['border border-dark border-top border-2']
		}
  ];*/

  return (
    <DataTable
      //conditionalRowStyles={conditionalRowStyles}
      customStyles={DailyReadTableStyles}
      progressPending={loading}
      progressComponent={<Spinner />}
      className="pt-2 pb-2"
      columns={DailyReadTableColumns}
      data={dailyData}
      striped={true}
      highlightOnHover={true}
      responsive={true}
      dense={true}
    />
  );
}
export default DailyReadTable;
