import { useEffect, useState, useRef } from 'react';
import './add-nom-style.css'
import addNom from '../../../../assets/images/add.gif';
import saveNom from '../../../../assets/images/save.png';
import deleteNomImg from '../../../../assets/images/delete.png';
import cancelNom from '../../../../assets/images/cancel.png';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../Spinner';
import useErrorStyling from '../../../../hooks/useErrorStyling';
import {
	calcDlvAndFuel,
	calcRcvAndFuel,
	formValuesFromContract,
	formValuesFromPackage
} from '../../../../utils/GeneralNomUtils';
import { createPackage } from '../../../../utils/PathModelNomUtils';
import { postNewData, deleteRecord } from '../../../../utils/APIUtils';
import BALookupModal from '../../../CustomComponents/ReactHookFormLookupModals/BALookupModal';
import ActivityLookupModal from '../../../CustomComponents/ReactHookFormLookupModals/ActivityLookupModal';
import ContractLookupModal from '../../../CustomComponents/ReactHookFormLookupModals/ContractLookupModal';
import PointLookupModal from '../../../CustomComponents/ReactHookFormLookupModals/PointLookupModal';
import CodeSelector from '../../../CustomComponents/ReactHookFormSelectors/CodeSelector';
import { notification_actions } from '../../../../store/notification-slice';
import { interstate_noms_actions, setPackageAndFetchPkgPaths } from '../../../../store/interstate-noms-slice';
import { Link, useNavigate } from 'react-router-dom';
import useNomCycleUpdateRHF from '../../../../hooks/useNomCycleUpdateRHF';


function AddIntNom() {

	// React Router Dom
	const navigate = useNavigate();

	// Redux
	const selectedSupplier = useSelector(state => state.interstate_noms_slice.filters.selectedSupplier);
	const selectedContract = useSelector(state => state.interstate_noms_slice.filters.selectedContract);
	const selectedPackage = useSelector(state => state.interstate_noms_slice.selectedPackage);
	const dispatch = useDispatch();

	// State
	const [deletingNom, setDeletingNom] = useState(false);

	// React Hook Form
	const form = useForm();
	const { register, handleSubmit, formState, setValue, control, reset, getValues } = form;
	const { errors, isSubmitting, dirtyFields, isSubmitted } = formState;

	// Set default form values
	useEffect(() => {
		let defaultFormValues = {};

		// If selected package has a package number, we are updating a package, so get form values from package.
		if (selectedPackage?.PACKAGE_NUM)
			defaultFormValues = formValuesFromPackage(selectedPackage);
		// Else we are creating a new package, so get form values from contract.
		else
			defaultFormValues = formValuesFromContract(selectedContract);

		reset(defaultFormValues); // Reset the form with the new form values.
	}, [selectedPackage, selectedContract, reset]);

	// Update nom cycle based on changing package start date.
	useNomCycleUpdateRHF(form);

	// Submit Handler
	const onSubmit = async (formData) => {
		try {
			// If package number is defined, we are updating an existing package.
			if (formData.PACKAGE_NUM) {
				// If nothing has changed, just return.
				if (dirtyFields && Object.keys(dirtyFields)?.length < 1)
					return;

				// API request to update package.
				const newPackage = createPackage(formData); // Creating a new package is simpler than updating existing package and it shouldn't effect performance.

				const response = await postNewData('Package/UpdatePackage', newPackage);
				if (!response.ok) {
					const message = await response.text();
					throw new Error(message);
				}
				const updatedPackage = await response.json();

				// Set selected package.
				await dispatch(setPackageAndFetchPkgPaths(updatedPackage));

				await dispatch(notification_actions.showModal({ header: 'Success', message: 'Nomination updated successfully.' }));
			}
			// Else, this is a new package.
			else {
				// API request to create package.
				const newPackage = createPackage(formData);

				const response = await postNewData('Package/CreatePackage', newPackage);
				if (!response.ok) {
					const message = await response.text();
					throw new Error(message);
				}
				const updatedPackage = await response.json();

				// Set selected package.
				await dispatch(setPackageAndFetchPkgPaths(updatedPackage));

				await dispatch(notification_actions.showModal({ header: 'Success', message: 'Nomination created successfully.' }));
			}
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
	};

	// Custom error styling hook.
	useErrorStyling('interstate-noms-form', form);

	// Adding this in to prevent infinite loop.
	const dlvQtyWasAutoSet = useRef(false);
	const rcvQtyWasAutoSet = useRef(false);

	// Update fuel and deliver quantity any time rec qty changes.
	const rcvQtyWatch = useWatch({ control: control, name: 'NOM_RCV_VOL' });
	useEffect(() => {
		if (!rcvQtyWasAutoSet.current) {
			var unroundedRcvVol = rcvQtyWatch;
			var fuel_percent = getValues('FUEL_PERCENT');
			const { rcvVol, dlvVol, fuel } = calcDlvAndFuel(unroundedRcvVol, fuel_percent);
			dlvQtyWasAutoSet.current = true;

			if (!isNaN(fuel) && !isNaN(dlvVol) && !isNaN(rcvVol)) {
				setValue('FUEL', fuel);
				setValue('NOM_DLV_VOL', dlvVol);
				setValue('NOM_RCV_VOL', rcvVol);
			}
		}
		else {
			rcvQtyWasAutoSet.current = false;
		}
	}, [rcvQtyWatch, getValues, setValue]);

	// Update fuel and receive quantity any time del qty changes.
	const dlvQtyWatch = useWatch({ control: control, name: 'NOM_DLV_VOL' });
	useEffect(() => {
		if (!dlvQtyWasAutoSet.current) {
			const unroundedDlvVol = dlvQtyWatch;
			const fuel_percent = getValues('FUEL_PERCENT');
			const { fuel, rcvVol, dlvVol } = calcRcvAndFuel(unroundedDlvVol, fuel_percent);
			rcvQtyWasAutoSet.current = true;

			if (!isNaN(fuel) && !isNaN(rcvVol) && !isNaN(dlvVol)) {
				setValue('FUEL', fuel);
				setValue('NOM_RCV_VOL', rcvVol);
				setValue('NOM_DLV_VOL', dlvVol);
			}
		}
		else {
			dlvQtyWasAutoSet.current = false;
		}
	}, [dlvQtyWatch, getValues, setValue]);

	const deleteNom = async () => {
		try {
			setDeletingNom(true);
			const package_num = getValues('PACKAGE_NUM');
			if (!package_num)
				throw new Error('Package num was null');
			const response = await deleteRecord(`Package/DeletePackage?package_num=${package_num}`);
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}
			await dispatch(notification_actions.showModal({ header: 'Success!', message: 'Nomination deleted successfully.' }));

			// Auto navigate to interstate noms home page.
			navigate('/interstateNoms');
		}
		catch (error) {
			await dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
		}
		finally {
			setDeletingNom(false);
		}
	};

	const addNomClick = () => {
		// If selectedPackage is already null, we will have to manually reset the form.
		if (!selectedPackage)
			reset(formValuesFromContract(selectedContract));
		// Else, null out selectedPackage. This will cause the useEffect to trigger and the form to be reset.
		else
			dispatch(interstate_noms_actions.setSelectedPackage(null));
	};

	const updatePoints = (newActivity) => {
		const start_pt_num = newActivity?.RCV_PT_NUM;
		const end_pt_num = newActivity?.DLV_PT_NUM;
		setValue('START_PT_NUM', start_pt_num, { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });
		setValue('END_PT_NUM', end_pt_num, { shouldValidate: isSubmitted, shouldDirty: true, shouldTouch: true });
	};
	
	return (
		<form onSubmit={handleSubmit(onSubmit)} noValidate className='interstate-noms-form'>
			<div className='text-black bg-white container-lg rounded'>
				<div className='row justify-content-center'>
					<div className='col-sm-12 col-md-12 col-lg-12'>
						<div className='card-content'>
							{/* <div className='card'> */}
							<div className='card-header'>
								<h5 className='mb-0 text-start'>Add Off System Nomination</h5>
							</div>
							<div className='card-body bg-light-blue mb-0 p-1 rounded'>
								{/* Buttons */}
								<div className='row'>
									<div className='col-sm-12 col-md-12 col-lg-12'>
										<button
											type='button'
											className='btn btn-ss btn-primary me-2 mb-1'
											disabled={isSubmitting || deletingNom}
											onClick={addNomClick}
										>
											<img src={addNom} alt='Add Nomination' /> Add Nomination
										</button>
										<button type='submit' className='btn btn-ss btn-primary me-2 mb-1' disabled={isSubmitting || deletingNom}>
											<img src={saveNom} alt='Save Nomination' /> Save Nomination
										</button>
										<button type='button' className='btn btn-ss btn-primary me-2 mb-1' disabled={isSubmitting || deletingNom} onClick={deleteNom}>
											<img src={deleteNomImg} alt='Delete Nomination' /> Delete Nomination
										</button>
										<Link to='/interstateNoms'>
											<button type='button' className='btn btn-ss btn-primary me-2 mb-1' disabled={isSubmitting || deletingNom}>
												<img src={cancelNom} alt='Cancel Nomination' /> Cancel Nomination
											</button>
										</Link>
									</div>
								</div>

								<div className='row justify-content-center'>
									<div className='col-auto'>
										{isSubmitting && <Spinner status='loading' />}
										{deletingNom && <Spinner status='deleting' />}
										{Object.keys(errors).length > 0 && <p className='error'>Please fill in the required fields.</p>}
									</div>
								</div>


								{/* First row: */}
								<div className='row mb-0'>
									<div className='col-sm-12 col-md-12 col-lg-12'>
										<p className='form-label text-start mb-0 fw-bold'>Requestor</p>
										<hr className='mb-1 mt-0' />
									</div>
									<div className='col-sm-12 col-md-2 col-lg-1 form-label'>
										<p className='mb-0'>Package Type</p>
										<div className='mb-0'>
											<input type='text' className='form-control form-control-sm' {...register('PKGTYPE')} />
										</div>
									</div>
									<div className='col-sm-12 col-md-2 col-lg-1 form-label'>
										<p className='mb-0 me-0'>Supp Duns#:</p>
										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' {...register('CNTR_NUM.CNTR_PARTY2.BA_USER_ID')} />
										</div>
									</div>
									<div className='col-sm-12 col-md-2 col-lg-1 form-label'>

										<p className='mb-0'>Pipeline:</p>
										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' {...register('CNTR_NUM.CNTR_PARTY1.SHORTNAME', { required: true })} />
										</div>
									</div>


									<div className='col-sm-12 col-md-3 col-lg-1'>
										<p className='mb-0'>Supplier:</p>
										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' {...register('CNTR_NUM.CNTR_PARTY2.BA_ID')} />
										</div>
										<div className='form-group mb-0 mt-0'>
											<input type='text' className='form-control form-control-sm' {...register('CNTR_NUM.CNTR_PARTY2.SHORTNAME', { required: true })} />
										</div>
									</div>
									<div className='col-sm-12 col-md-2 col-lg-2'>
										<div className='mt-0'>
											<BALookupModal
												form={form}
												propertyName='requestor'
												url='BusinessAssociate/GetBusinessAssociates?ba_role=BS&cntr_type=MC'
											/>
										</div>
									</div>

									<div className='col-sm-12 col-md-3 col-lg-1'>
										<p className='mb-0'>Contract #:</p>
										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' {...register('CNTR_NUM.CNTR_ALT_NUM1')} />
										</div>
										<div className='form-group mb-0 mt-0'>
											<input type='text' className='form-control form-control-sm' {...register('CNTR_NUM.CNTR_TITLE', { required: true })} />
										</div>
									</div>
									<div className='col-sm-12 col-md-2 col-lg-2'>
										<ContractLookupModal
											form={form}
											propertyName='contract'
											url={`GeneralData/GetContracts?cntr_type=MC&status=AC`}
										/>
									</div>
									<div className='col-sm-12 col-md-1 col-lg-1 '>
										<p className='mb-0'>Activity:</p>

										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' {...register('ACTIV_NUM.ACTIV_ALT_NUM', { required: true })} />
										</div>

									</div>
									<div className='col-sm-12 col-md-2 col-lg-2 '>
										<div className='d-flex align-items-center h-100'>
											<ActivityLookupModal
												form={form}
												propertyName='ACTIV_NUM'
												url={`Activity/GetActivities?cntr_num=${selectedContract?.CNTR_NUM}`}
												afterSetValue={updatePoints}
											/>
										</div>
									</div>



								</div>

								<div className='row mb-0'>
									<div className='col-sm-12 col-md-2 col-lg-2'>

										<p className='mb-0'>Rate Schedule:</p>

										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' {...register('CNTR_NUM.RateSchObj.RATE_SCH_ID', { required: true })} />
										</div>

									</div>

								</div >
								{/* Second Row */}

								<div className='row mb-0 mt-0'>

									<div className='col-sm-12 col-md-12 col-lg-12'>
										<p className='form-label text-start mb-0'>Upstream Party</p>
										<hr className='mb-1 mt-0' />
									</div>
									<div className='col-sm-12 col-md-2 col-lg-2'>
										<p className='mb-0'>Up Link Pkg #:</p>

										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' />
										</div>
									</div>
									<div className='col-sm-12 col-md-2 col-lg-2'>
										<p className='mb-0'>Supplier:</p>
										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' />
										</div>
									</div>
									<div className='col-sm-12 col-md-2 col-lg-2'>
										<div className='mt-0'>
											<button type='button' className='btn btn-ss btn-primary margin-flat'>Select Supplier</button>
										</div>
									</div >
									<div className='col-sm-12 col-md-2 col-lg-1'>
										<p className='mb-0'>Up Pkg Type:</p>
										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' />
										</div>
									</div>
									<div className='col-sm-12 col-md-2 col-lg-1'>
										<p className='mb-0'>Transporter:</p>
										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' />
										</div>
									</div>
									<div className='col-sm-12 col-md-1 col-lg-1'>
										<div className='mt-0'>
											<button type='button' className='btn btn-ss btn-primary margin-flat'>Trans</button>
										</div>
									</div >
									<div className='col-sm-12 col-md-4 col-lg-1'>
										<p className='mb-0'>Up Rank:</p>

										<input type='text' className='form-control form-control-sm' />

									</div>
									<div className='col-sm-12 col-md-2 col-lg-2'>

										<p className='mb-0'>Contract #:</p>

										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' />
										</div>

									</div >

									{/* Third Row */}
									<div className='row mt-0' >
										<p className='form-label text-start mb-0'>Nomination Information</p>
										<hr className='mb-1' />
										<div className='col-sm-12 col-md-3 col-lg-3'>
											<div>
												<p className='mb-0'>Nom Trk ID:</p>
												<div className='form-group mb-0'>
													<input type='text' className='form-control form-control-sm' {...register('PACKAGE_NUM')} />
												</div>
											</div>
										</div>
										<div className='col-sm-12 col-md-2 col-lg-2'>
											<p className='mb-0'>Nom Status:</p>
											<div className='form-group mb-0'>
												<CodeSelector
													form={form}
													url='Codes/GetCodes?cde_type=NOMS'
													propertyName='STATUS'
												/>
											</div>
										</div>
									</div >
									{/* Fourth Row */}
									<div className='row mt-0' >
										<div className='col-sm-12 col-md-12 col-lg-12'>
											<p className='form-label text-start mb-0'>Flowing Gas</p>
											<hr className='mb-1 mt-0' />
										</div>
										<div className='col-sm-12 col-md-2 col-lg-2'>
											<div>
												<p className='mb-0'>Beg Date:</p>
												<div className='form-group mb-0'>
													<input
														type='date'
														className='form-control form-control-sm'
														{...register('STARTDATE', { required: true })} />
												</div>
											</div>
											<div>
												<p className='mb-0'>Beg Time:</p>
												<div className='form-group mb-0'>
													<input type='time' className='form-control form-control-sm' {...register('BEGINTIME')} />
												</div>

											</div>

										</div>
										<div className='col-sm-12 col-md-2 col-lg-2'>
											<div>
												<p className='mb-0'>End Date:</p>
												<div className='form-group mb-0'>
													<input
														type='date'
														className='form-control form-control-sm'
														{...register('ENDDATE', { required: true})} />
												</div>
												<p className='mb-0'>End Time:</p>
												<div className='form-group mb-0'>
													<input type='time' className='form-control form-control-sm' {...register('ENDTIME')} />
												</div>
											</div>
										</div>
										<div className='col-sm-12 col-md-2 col-lg-1'>
											<p className='mb-0'>Flow Type:</p>
											<div className='form-group mb-0'>
												<CodeSelector
													form={form}
													url='Codes/GetCodes?cde_type=DSPC'
													propertyName='VOLUME_TYPE'
												/>
											</div>
											<p className='mb-0'>Trans type:</p>
											<div className='form-group mb-0'>
												<CodeSelector
													form={form}
													url='Codes/GetCodes?cde_type=TRANT'
													propertyName='TT'
												/>
											</div>

										</div>
										<div className='col-sm-12 col-md-3 col-lg-1'>
											<p className='mb-0'>Rec Loc:</p>
											<div className='form-group mb-0'>
												<input type='text' className='form-control form-control-sm' {...register('START_PT_NUM.POINT_ID', { required: true })} />
												<input type='text' className='form-control form-control-sm' {...register('START_PT_NUM.PT_NAME', {required: true})} />
											</div>
										</div>
										<div className='col-sm-12 col-md-2 col-lg-1 margin-flat'>
											<PointLookupModal
												form={form}
												propertyName='START_PT_NUM'
												url={`Point/GetPoints?pipeline_id=${selectedSupplier?.BA_ID}`}
											/>
										</div>

										<div className='col-sm-12 col-md-3 col-lg-1'>
											<p className='mb-0'>Del Loc:</p>
											<div className='form-group mb-0'>
												<input type='text' className='form-control form-control-sm' {...register('END_PT_NUM.POINT_ID', { required: true })} />
												<input type='text' className='form-control form-control-sm' {...register('END_PT_NUM.PT_NAME', {required: true})} />
											</div>
										</div>
										<div className='col-sm-12 col-md-2 col-lg-1 margin-flat'>
											<PointLookupModal
												form={form}
												propertyName='END_PT_NUM'
												url={`Point/GetPoints?pipeline_id=${selectedSupplier?.BA_ID}`}
											/>
										</div>


										<div className='col-sm-12 col-md-2 col-lg-1'>
											<div className="form-check">
												<input
													className="form-check-input"
													type="checkbox"
													defaultValue="1"
													id="flexCheckDefault"
													{...register('IsQtyApplyFullDuration')}
												/>
												<label className="form-check-label" htmlFor="flexCheckDefault">
													Check for total Nom QTY
												</label>
											</div>
										</div>
										<div className='col-sm-12 col-md-2 col-lg-1'>
											<p className='mb-0'>Rec Qty:</p>
											<div className='form-group mb-0'>
												<input type='number' className='form-control form-control-sm' {...register('NOM_RCV_VOL', { required: true, valueAsNumber: true })} />
											</div>
											<p className='mb-0'>Del Qty:</p>
											<div className='form-group mb-0'>
												<input type='number' className='form-control form-control-sm' {...register('NOM_DLV_VOL', { required: true, valueAsNumber: true })} />
											</div>
										</div>
										<div className='col-sm-12 col-md-2 col-lg-1'>
											<div>
												<p className='mb-0'>Cycle:</p>
												<div className='form-group mb-0'>
													<CodeSelector
														form={form}
														propertyName='CYCLE'
														url='Codes/GetCodes?cde_type=NCYC'
													/>
												</div>
											</div>
											<div>
												<p className='mb-0'>Rec Rank:</p>
												<div className='form-group mb-0'>
													<CodeSelector
														form={form}
														propertyName='RANK'
														url='Codes/GetCodes?cde_type=RANK'
													/>
												</div>
											</div>
										</div>


									</div>
								</div >
								<div className='row' >

									<div className='col-sm-12 col-md-8 col-lg-6'>
										<p htmlFor="textArea" className="form-label mb-0">Comments:</p>
										<textarea className="form-control" id="exampleFormControlTextarea1" rows="1" {...register('COMMENTS')} ></textarea>
									</div>

									{/* Fifth Row */}
									{/* <div className='row mt-0' > */}

									<div className='col-sm-12 col-md-2 col-lg-2'><p className='mb-0'>Updated By</p>
										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' {...register('USERSTAMP')} />
										</div>
									</div>
									<div className='col-sm-12 col-md-2 col-lg-2'><p className='mb-0'>Updated On</p>
										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' {...register('DATESTAMP', { valueAsDate: true })} />
										</div>
									</div>
									<div className='col-sm-12 col-md-2 col-lg 1'>

										<p className='mb-0'>Fuel %:</p>
										<div className='form-group mb-0'>
											<input type='number' className='form-control form-control-sm' {...register('FUEL_PERCENT', { required: true, valueAsNumber: true })} />
										</div>

									</div>
									<div className='col-sm-12 col-md-2 col-lg-1'>
										<div>
											<p className='mb-0'>Fuel QTY:</p>
											<div className='form-group mb-0'>
												<input type='number' className='form-control form-control-sm' {...register('FUEL', { required: true, valueAsNumber: true })} />
											</div>
										</div>


									</div>
								</div>
								{/* </div> */}
								{/* Sixth Row */}
								<div className='row mt-0' >
									<div className='col-sm-12 col-md-12 col-lg-12'>
										<p className='form-label text-start mb-0'>Downstream</p>
										<hr className='mb-1 mt-0' />
									</div>
									<div className='col-sm-12 col-md-2 col-lg-2'>

										<p className='mb-0'>Dn Link Pkg# :</p>
										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' />
										</div>

									</div>
									<div className='col-sm-12 col-md-2 col-lg-1'>
										<p className='mb-0'>Customer:</p>

										<input type='text' className='form-control form-control-sm' />
									</div>
									<div className='col-sm-12 col-md-2 col-lg-2'>
										<div className='mt-0'>
											<button type='button' className='btn btn-ss btn-primary margin-flat'>Select Customer</button>
										</div>
									</div>

									<div className='col-sm-12 col-md-2 col-lg-1'>

										<p className='mb-0'>Dn Pkg Type:</p>
										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' />
										</div>
									</div>
									<div className='col-sm-12 col-md-2 col-lg-2'>


										<p className='mb-0'>Transporter:</p>

										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' />
										</div>
									</div>
									<div className='col-sm-12 col-md-2 col-lg-2'>
										<div className='mt-0'>
											<button type='button' className='btn btn-ss btn-primary margin-flat'>Select Transporter</button>
										</div>
									</div>
									<div className='col-sm-12 col-md-2 col-lg-1'>

										<p className='mb-0'>Dn Rank:</p>
										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' />
										</div>

									</div>

									<div className='col-sm-12 col-md-2 col-lg-1'>
										<p className='mb-0'>Contract #:</p>
										<div className='form-group mb-0'>
											<input type='text' className='form-control form-control-sm' />
										</div>
									</div>


								</div>
							</div >
						</div>
						{/* </div> */}
					</div>
				</div>
			</div>
		</form>
	);
};

export default AddIntNom;