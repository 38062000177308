import { createSlice } from '@reduxjs/toolkit';
import { displayError, displaySuccess } from './notification-slice';

import { fetchFinPositions, fetchContract } from './financial-position-slice';
import { fetchPriceRefIndices, fetchFercCodes, fetchAllFuturePrice, fetchCycleCodes, fetchMtsContracts, fetchStatusCodes } from './physical-trade-deals-thunks';

const initialState = {
    eomData: [],
    loading: false,
    error: null,
};

const eom_slice = createSlice({
    name: 'eom_slice',
    initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
        setEomData(state, action) {
            state.eomData = action.payload;
        },
    },
});


// Thunk combining data from both slices
export const fetchEomData = () => {
    return async (dispatch, getState) => {
        try {
            dispatch(eom_slice.actions.setLoading(true));

            // Get current filter states from slices
            const finPosFilters = getState().financial_position_slice.FinPositionFilters;
            const tradeDealFilters = getState().physical_trade_deals_slice.YourTradeDealFilter; // Replace with your actual filter name

            // Fetch financial positions with filters
            await dispatch(fetchFinPositions(finPosFilters));

            // Fetch price reference indices (assuming it also needs filters)
            await dispatch(fetchPriceRefIndices(tradeDealFilters));


            await dispatch(fetchContract());

            // Fetch physical deal thunks
            await dispatch(fetchFercCodes());

            await dispatch(fetchAllFuturePrice());


            await dispatch(fetchCycleCodes());

            await dispatch(fetchMtsContracts());

            await dispatch(fetchStatusCodes());

            // Combine data
            const combinedData = [];

            dispatch(eom_slice.actions.setEomData(combinedData));
            dispatch(displaySuccess('Data fetched successfully'));
        } catch (error) {
            dispatch(eom_slice.actions.setError(error.message));
            dispatch(displayError(error.message));
        } finally {
            dispatch(eom_slice.actions.setLoading(false));
        }
    };

};

export const eom_actions = eom_slice.actions;
export default eom_slice.reducer;
