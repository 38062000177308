
/**
 * Custom spinner component. Add this wherever you need 
 * a spinner.
 */
const Spinner = ({
	status
}) => {
	let text = ""
	switch (status) {
		case 'loading':
			text = "Loading...";
			break;
		case 'saving':
			text = "Saving...";
			break;
		case 'deleting':
			text = "Deleting...";
			break;
		default:
			break;
	}
	return (
		<div className="container-fluid">
			<div className="row justify-content-center align-items-center">
				<div className="col-auto p-0 pe-2">
					<p className='p-0 m-0 fs-3'>{text}</p>
				</div>
				<div className="col-auto p-0">
					<div
						className="spinner-border spinner-border-sm"
						role="status"
						aria-hidden="true">
					</div>
				</div>
			</div>
		</div>
	)
}

export default Spinner;