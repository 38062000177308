import { useEffect, useState, useRef } from 'react';
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../Spinner";
import CustomStyles from './Table-Styles';
import { formatDate } from '../../../../utils/DateUtils';
import { useNavigate } from 'react-router-dom';
import { getData } from '../../../../utils/APIUtils';
import { notification_actions } from '../../../../store/notification-slice';
import { fetchPackages, interstate_noms_actions, setPackageAndFetchPkgPaths } from '../../../../store/interstate-noms-slice';


const InterstateNomsTable = () => {

	const filters = useSelector(state => state.interstate_noms_slice.filters);
	const intNomsTableLoading = useSelector(state => state.interstate_noms_slice.intNomsTableLoading);
	const packages = useSelector(state => state.interstate_noms_slice.packages);
	const { nomDetailModalState } = useSelector(state => state.interstate_noms_slice.modalStates);

	const navigate = useNavigate();

	const columns = [
		{
			name: 'View Details',
			button: true,
			cell: (row) => (
				<button onClick={() => viewDetailsClick(row)}
					className='btn btn-md btn-primary'>
					Daily
				</button>
			)
		},
		{
			name: 'Pkg #',
			selector: row => row.PACKAGE_NUM,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Pipeline',
			selector: row => row.CNTR_NUM?.CNTR_PARTY1?.SHORTNAME,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Activity',
			selector: row => row.ACTIV_NUM?.ACTIV_ALT_NUM,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Trans Contract',
			selector: row => row.CNTR_NUM?.CNTR_ALT_NUM1,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Rec Meter ID',
			selector: row => row.DLV_POINT_ID,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Rec Qty',
			selector: row => row.RCV_QTY,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Del Meter ID',
			selector: row => row.DLV_POINT_ID,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Del Qty',
			selector: row => row.DLV_QTY,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Start Date',
			selector: row => formatDate(row.STARTDATE),
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'End Date',
			selector: row => formatDate(row.ENDDATE),
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Obligation',
			selector: row => row.VOLUME_TYPE || 'N/A',
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Trans Type',
			selector: row => row.TT,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Package Type',
			selector: row => row.PKGTYPE || 'N/A',
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Updated By',
			selector: row => row.USERSTAMP,
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		},
		{
			name: 'Updated On',
			selector: row => formatDate(row.DATESTAMP),
			sortable: true,
			compact: false,
			wrap: true,
			reorder: true
		}
	];

	const dispatch = useDispatch();

	const handleRowClicked = async (row) => {
		// Set selected package and navigate to add interstate nom page.
		await dispatch(setPackageAndFetchPkgPaths(row));
		navigate('/addIntNom');
	};

	const viewDetailsClick = async (row) => {
		// Set selected package to selected row and open nom detail modal.
		await dispatch(setPackageAndFetchPkgPaths(row));

		// Open Nom Details modal
		await dispatch(interstate_noms_actions.changeModalState({
			modalStateName: 'nomDetailModalState',
			newModalState: { ...nomDetailModalState, open: true }
		}));
	};

	// API fetch
	useEffect(() => { dispatch(fetchPackages(filters)); }, [filters]);

	return (
		<DataTable
			progressPending={intNomsTableLoading}
			progressComponent={<Spinner status='loading' />}
			columns={columns}
			data={packages}
			className='table-striped pb-1'
			fixedHeader={true}
			fixedHeaderScrollHeight="700px"
			striped={true}
			highlightOnHover={true}
			customStyles={CustomStyles}
			onRowClicked={handleRowClicked}
			dense={true}
		/>
	);
};

export default InterstateNomsTable;