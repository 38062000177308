import
React
//,{ useEffect }
	from "react";
import { useDispatch, useSelector } from "react-redux";
import useBusinessSelectorRedux from '../../../../hooks/useBusinessSelectorRedux';

// custom components:
import {
	getDayStart,
	toDatePickerMonthString,
	toISOStringForDatabase
} from "../../../../utils/DateUtils";

import BackButton from "../../../CustomComponents/BackButton";
import ContractSelector from "../../../CustomComponents/Selectors/ContractSelector";
import { non_path_noms_actions } from '../../../../store/non-path-noms-slice';
import ExportExcel from "../../../CustomComponents/ExportExcel";
import GasAllocationTableColumns from './GasAllocationTable/GasAllocationTableColumns';
import GasAllocationTable from './GasAllocationTable';
import { useState } from "react";


function GasAllocation() {

	const dispatch = useDispatch();
	const selectedSupplier = useSelector(store => store.business_selector_slice.selectedSupplier);
	//const storageData = useSelector(store => store.pointBalance_slice.storageData);

	//const contracts = useSelector(store => store.general_data_slice.selectedContract);
	const non_path_noms_slice = useSelector(state => state.non_path_noms_slice);
	//const business_selector_slice = useSelector(state => state.business_selector_slice);

	//const GasAllocationData = useSelector(store => store.non_path_noms_slice.GasAllocationData)
	const { GasAllocationFilters, GasAllocationData } = non_path_noms_slice;
	//const { gasFlowDate } = business_selector_slice;

	//console.log("gasflow date state", gasFlowDate);
	//const setGasAllocationFilters = (newFilters) => {
	//	dispatch(non_path_noms_actions.setGasAllocationFilters(newFilters));
	//};

	const setGasFlowDate = (event) => {
		const newGasFlowDate = toISOStringForDatabase(getDayStart(event.target.value))
		dispatch(non_path_noms_actions.setGasAllocationFilters({ ...GasAllocationFilters, gasFlowDate: newGasFlowDate }));
	};
	useBusinessSelectorRedux(GasAllocationFilters, non_path_noms_actions.setGasAllocationFilters, true);
	//console.log("SelectedSuppliers:", selectedSupplier);
	//console.log("Contracts Object:", contracts);
	//console.log("GasAllocationFilters:", GasAllocationFilters);
	//console.log("GasAllocationData:", GasAllocationData);

	const [selectedCheckbox, setSelectedCheckbox] = useState(null);

	const emptyContract = { CNTR_NUM: "N/A" } // passing N/A will convert to empty string in controller

	const handleCheckboxChange = (value) => {
		setSelectedCheckbox(value === selectedCheckbox ? null : value);

		if (value === "NONE" || value === selectedCheckbox) {
			dispatch(non_path_noms_actions.setGasAllocationFilters({ ...GasAllocationFilters, mode: "A", selectedContract: {} }));
			//console.log("None");
		}
		else if (value === "ALL") {
			dispatch(non_path_noms_actions.setGasAllocationFilters({ ...GasAllocationFilters, mode: "A", selectedContract: emptyContract }));
			//console.log("all");
		}
		else if (value === "ETRN") {
			dispatch(non_path_noms_actions.setGasAllocationFilters({ ...GasAllocationFilters, mode: "E", selectedContract: emptyContract }));
			//console.log("etrn");
		}
		else if (value === "DGP") {
			dispatch(non_path_noms_actions.setGasAllocationFilters({ ...GasAllocationFilters, mode: "N", selectedContract: emptyContract }));
			//console.log("non etrn");
		}
	};

	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-sm-12 col-md-12 col-lg-12 p-1">

					{/* Card */}
					<div className="card border-0 pb-1 p-0 pt-0">

						{/* Card Header */}
						<div className="card-header bg-primary text-white text-center p-1">
							<h4 className="card-title m-0 pt-0 pb-0">Gas Allocation</h4>
						</div>

						{/* Card Body */}
						<div className="card-body">
							<div className="row p-0">
								<h6>Filters</h6>
							</div>
							<div className="d-flex pb-2">
								<ExportExcel
									data={GasAllocationData}
									columns={GasAllocationTableColumns}
									className='btn btn-md btn-primary me-2'
									fileName='Gas_Allocation_Data.xlsx'
								/>
								<BackButton className='btn btn-md btn-primary' />
							</div>
							{/* Fields and selectors */}
							<div className="row bg-light-blue rounded pb-1">
								<div className="row">
									<div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
										<label className='form-label m-0'>Display Month:</label>
										<input
											className="form-control form-control-sm"
											type="month"
											value={toDatePickerMonthString(GasAllocationFilters?.gasFlowDate)/*toDatePickerString(GasAllocationFilters?.month)*/}
											onChange={setGasFlowDate}
											min="2023-11" // november 2023 devops 4666
										/>
									</div>

									{/*<div className="col-lg-2 col-md-4 col-sm-12 p-1 text-start">
                                        <label className='form-label m-0'>Shipper:</label>
                                        <SupplierSelect />
                                    </div>*/}
									<div className="col-lg-2 col-md-4 col-sm-12 p-1 text-start">
										<label className='form-label m-0'>Contracts:</label>
										<ContractSelector
											url={`Contract/GetContracts?cntr_type=GA&cntr_party1=${selectedSupplier?.BA_ID}`}
											value={GasAllocationFilters?.selectedContract}
											setValue={(newValue) => {
												dispatch(non_path_noms_actions.setGasAllocationFilters({ ...GasAllocationFilters, selectedContract: newValue}));
												//console.log("contract selector dispatch");
											}}
										/>
									</div>

									{/* check boxes */}
									<div className="col-lg-2 col-md-4 col-sm-12 p-1 align-self-end">
										<label className="form-label m-0">
											<input
												className="me-2 form-check-input"
												type="checkbox"
												checked={selectedCheckbox === 'NONE'}
												onChange={() => handleCheckboxChange('NONE')}
											/>
											None
										</label>
									</div>
									<div className="col-lg-2 col-md-4 col-sm-12 p-1 align-self-end">
										<label className="form-label m-0">
											<input
												className="me-2 form-check-input"
												type="checkbox"
												checked={selectedCheckbox === 'ALL'}
												onChange={() => handleCheckboxChange('ALL')}
											/>
											All contracts
										</label>
									</div>
									<div className="col-lg-2 col-md-4 col-sm-12 p-1 align-self-end">
										<label className="form-label m-0">
											<input
												className="me-2 form-check-input"
												type="checkbox"
												checked={selectedCheckbox === 'ETRN'}
												onChange={() => handleCheckboxChange('ETRN')}
											/>
											ETRN Contracts
										</label>
									</div>
									<div className="col-lg-2 col-md-4 col-sm-12 p-1 align-self-end">
										<label className="form-label m-0">
											<input
												className="me-2 form-check-input"
												type="checkbox"
												checked={selectedCheckbox === 'DGP'}
												onChange={() => handleCheckboxChange('DGP')}
											/>
											DGP Contracts
										</label>
									</div>
								</div>
							</div>

							{/* Table section: */}
							<div className="row">
								<div className="col-sm-12 col-md-12 col-lg-12">
									<GasAllocationTable />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	);
}

export default GasAllocation;