import ReactHookFormSelector from './ReactHookFormSelector';

const ContactSelector = ({
	form,
	propertyName,
	url,
	disabled,
	className,
	shouldLog,
	required
}) => {
	return (
		<ReactHookFormSelector
			disabled={disabled}
			className={className || 'form-select form-select-sm'}
			form={form}
			valueType='object'
			url={url}
			propertyName={propertyName}
			optionKey='CONTACT_NUM'
			optionText={['FIRST_NAME', 'NAME']}
			shouldLog={shouldLog}
			required={required}
		/>
	);
};

export default ContactSelector;