import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { physical_trade_deals_actions } from "../../store/physical-trade-deals-slice";
import { fetchBidPackages } from '../../store/physical-trade-deals-thunks'

//Modal Import:
import NewDealModal from "./NewDealModal";
import Table from "./Table";
import DailySumUiModal from "./DailySumUiModal";

//Images:
import newBtn from "../../assets/images/add.gif";
import bars from "../../assets/images/bars.gif";

import {
  toDatePickerString,
  getMonthStart,
  getMonthEnd,
} from "../../utils/DateUtils";
import { notification_actions } from "../../store/notification-slice";
import { Link } from "react-router-dom";
import useBusinessSelector from "../../hooks/useBusinessSelector";

function PhysicalDealsAndTrades() {
  // Redux
  const dispatch = useDispatch();
  const businessUnit = useSelector(
    (state) => state.business_selector_slice.businessUnit
  );

  // State
  const [pipelines, setPipelines] = useState();
  const [dealMakers, setDealMakers] = useState([]);


  /* Decided to keep the filters in state instead of redux so that
   * the filters will reset if you navigate away from the page. - Cameron */
  const [filters, setFilters] = useState({
    startDate: getMonthStart(new Date()).toISOString(),
    endDate: getMonthEnd(new Date()).toISOString(),
    bidType: "",
    pipeline_id: "",
    dealMaker: "",
    showAllDeals: true,
  });

  // Business Selector logic
  useBusinessSelector(setFilters);

  // Fetch Pipelines for dropdown.
  useEffect(() => {
    const handleAsync = async () => {
      const requestData = async () => {
        const response = await fetch("PhysicalTradeDeals/GetPipelines");
        if (!response.ok) {
          const message = await response.text();
          throw new Error(message);
        }
        return await response.json();
      };
      try {
        const newPipelines = await requestData();
        setPipelines(newPipelines);
      } catch (error) {
        dispatch(
          notification_actions.showModal({
            header: "Error",
            message: error.message,
          })
        );
      }
    };
    handleAsync();
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchBidPackages({
      ...filters,
      dealMaker: filters.dealMaker,
    }));
  }, [filters, dispatch]);


  // Handler for filter input changes.
  const filterChange = (event) => {
    const { name, value, type, } = event.target;

    let newValue = value;

    // If this is a checkbox, set it to a boolean.
    if (type === "checkbox") {
      newValue = !filters[name];
    }

    // Create new filters object.
    let newFilters = {
      ...filters,
      [name]: newValue,
    };

    // Special cases
    switch (name) {
      case "endDate": {
        // Make sure end date is equal to or after start date.
        let dt1 = new Date(newFilters.startDate);
        let dt2 = new Date(newFilters.endDate);
        if (dt2 < dt1) {
          dt1 = getMonthStart(dt2);
          newFilters.startDate = dt1;
          newFilters.endDate = dt2;
        }
        break;
      }
      case "startDate": {
        // Make sure that start date is equal to or before end date.
        let dt1 = new Date(newFilters.startDate);
        let dt2 = new Date(newFilters.endDate);
        if (dt1 > dt2) {
          dt2 = getMonthEnd(dt1);
          newFilters.startDate = dt1;
          newFilters.endDate = dt2;
        }
        break;
      }
      default: {
        break;
      }
    }

    // Update in state.
    setFilters(newFilters);
  };



  const openModal = () => {
    dispatch(
      physical_trade_deals_actions.resetBidPkg({
        businessUnit,
      })
    );
    dispatch(physical_trade_deals_actions.setOpenModal(true));
  };

  return (
    <div className="container-fluid">
      <div className="text-black table-responsive">
        <div className="card mt-5">
          <div className="card-header text-center bg-primary text-white">
            <h4>Physical Trade Deals</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 p-2">
                <button
                  className="btn btn-md btn-primary me-2 mb-1"
                  onClick={openModal}
                >
                  <img src={newBtn} alt="New Trade" /> New Trade
                </button>

                <button className="btn btn-md btn-primary me-2 mb-1">
                  <img src={bars} alt="New Trade" /> Reprocess Prices
                </button>

                <Link to="/PriceIndicies">
                  <button className="btn btn-md btn-primary me-2 mb-1">
                    <img src={newBtn} alt="Price Indices" /> Price Indices
                  </button>
                </Link>
              </div>
            </div>
            {/* FILTERS section */}
            <div className="row bg-light-blue rounded p-1 rounded">
              <div className="row p-1">
                <h6>Display Filters</h6>
                {/* // Gas Flow Date */}
                <div className="col-lg-2 col-md-2 col-sm-12 text-start">
                  <label className="form-label pe-2">Display Date: </label>
                  <input
                    value={toDatePickerString(filters.startDate)}
                    onChange={filterChange}
                    name="startDate"
                    className="form-control form-control-sm"
                    type="date"
                  />
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 text-start">
                  <label className="form-label pe-2">Thru Date: </label>
                  <input
                    value={toDatePickerString(filters.endDate)}
                    onChange={filterChange}
                    name="endDate"
                    className="form-control form-control-sm"
                    type="date"
                  />
                </div>

                <div className="col-lg-2 col-md-2 col-sm-12 text-start">
                  <label className="form-label pe-2">Bid Type: </label>
                  <select
                    value={filters.bidType}
                    onChange={filterChange}
                    name="bidType"
                    className="form-select form-select-sm col-lg-1 col-md-2 col-sm-12 text-start"
                    aria-label="Default select example"
                  >
                    <option value="">--Select a Bid Type--</option>
                    <option value="B">Purchase</option>
                    <option value="S">Sale</option>
                  </select>
                </div>
                <div className="col-lg-2 col-sm-12 text-start">
                  <label className="form-label pe-2">Pipeline: </label>
                  <select
                    value={filters.pipeline_id}
                    onChange={filterChange}
                    name="pipeline_id"
                    className="form-select form-select-sm col-lg-1 col-sm-12 text-start"
                    aria-label="Default select example"
                  >
                    <option value="">--Select a Pipeline--</option>
                    {pipelines?.map((pipeline) => {
                      return (
                        <option key={pipeline.BA_ID} value={pipeline.BA_ID}>
                          {pipeline.SHORTNAME}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-lg-2 col-sm-12 text-start">
                  <label className="form-label pe-2">Deal Maker: </label>
                  <select
                    value={filters.dealMaker}
                    onChange={filterChange}
                    name="dealMaker"
                    className="form-select form-select-sm col-lg-1 col-sm-12 text-start"
                  >
                    <option value="">--Select Deal Maker--</option>
                    {dealMakers.map((dealMaker) => (
                      <option key={dealMaker} value={dealMaker}>
                        {dealMaker}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row">
                <label className="form-check-label col-lg-1 col-sm-12 text-start">
                  Show all Deals:
                </label>
                <div className="col-lg-1 col-md-12 col-sm-12">
                  <input
                    onChange={filterChange}
                    name="showAllDeals"
                    checked={filters.showAllDeals}
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                  />
                  <div className="form-check"></div>
                </div>
              </div>
            </div>
            {/* Table Section */}
            <div className="row">
              <h6>
                <span>Gas Purchases</span>
              </h6>
              <div className="col-sm-12 col-md-12 col-lg-12 p-2">
                <Table filters={filters} onDealMakersChange={setDealMakers} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Imported Modals */}
      <NewDealModal filters={filters} />
      <DailySumUiModal filters={filters} />
    </div>
  );
}

export default PhysicalDealsAndTrades;
