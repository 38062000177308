import { useEffect, useState } from "react";
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { notification_actions } from "../../../store/notification-slice";
import { fetchPriceRefIndices } from "../../../store/physical-trade-deals-thunks";

// Components:
import TableIndex1 from "./Tables/TableIndex1";
import TableIndex2 from "./Tables/TableIndex2";
import TablePriceCost from "./Tables/TablePriceCost";
import BackButton from "../../CustomComponents/BackButton";
import NymexModal from "./Nymex/NymexModal";

//Images:
import newBtn from '../../../assets/images/add.gif';
import bars from '../../../assets/images/bars.gif';
import ExcelExport from "../../../assets/images/excel.gif"
import PriceUpdate from "../../../assets/images/price-update.gif"
import NymexPic from "../../../assets/images/nymex-price.gif"
import { physical_trade_deals_actions } from "../../../store/physical-trade-deals-slice";


function PriceIndicies() {

	// Redux
	const dispatch = useDispatch();
	const priceRefIndices = useSelector(state => state.physical_trade_deals_slice.priceRefIndices);

	// State
	const [selectedPriceRefIndex, setSelectedPriceRefIndex] = useState({});
	const [selectedPriceArea, setSelectedPriceArea] = useState({});
	const [loading, setLoading] = useState(false);

	// Reset selected price area when selected price ref index changes.
	useEffect(() => {
		setSelectedPriceArea({});
	}, [selectedPriceRefIndex]);

	// Reset selected price ref index when price indices change.
	useEffect(() => {
		setSelectedPriceRefIndex({});
	}, [priceRefIndices]);

	// Fetch price ref indices.
	useEffect(() => {
		const handleAsync = async () => {
			try {
				setLoading(true);
				await dispatch(fetchPriceRefIndices());
				setLoading(false);
			}
			catch (error) {
				dispatch(notification_actions.showModal({ header: 'Error', message: error.message }));
			}
		};
		handleAsync();
	}, [dispatch]);

	// Nymex Modal
	const openNymexModal = () => {
		console.log("Opening Nymex Modal");
		dispatch(physical_trade_deals_actions.setOpenNymexModal(true));
	};


	return (
		<>

			<div className="container-fluid">
				<div className="row justify-content-center">
					<div className="col-lg-12 col-md-12 col-sm-12 p-0">
						<div className="card">
							<div className="modal-header">
								<h5 className="card-title ms-2">Price Indicies</h5>
								{/* <button className='btn btn-primary btn-sm me-4'>Back</button> */}
								<BackButton />
							</div>
							<div className="card-body pt-0 p-1">

								{/* Tables section */}
								{/* Table 1 Price/Cost Ref Index 1 */}
								<div className="container-fluid bg-light-blue rounded pt-0 mt-0 pb-2">
									<div className="row">
										<h6 className='fs-6 mb-0'>Price/Cost Ref Index</h6>
										<div className="col-sm-12 col-md-12 col-lg-12">
											<button className='btn btn-sm btn-primary me-2 mb-1'><img src={newBtn} alt='New Trade' /> New</button>
											<button className='btn btn-sm btn-primary me-2 mb-1'><img src={ExcelExport} alt='Excel Export' /> Excel Export</button>
											<button className='btn btn-sm btn-primary me-2 mb-1'><img src={bars} alt='Reprocess Prices' /> Reprocess Prices</button>
											<button className='btn btn-sm btn-primary me-2 mb-1' onClick={openNymexModal} ><img src={NymexPic} alt='Nymex Price' /> Nymex Price</button>
										</div>
									</div>
									<div className="row pt-0 mt-0">
										<div className="col-sm-12 col-md-12 col-lg-12">
											<TableIndex1
												selectedPriceRefIndex={selectedPriceRefIndex}
												setSelectedPriceRefIndex={setSelectedPriceRefIndex}
												loading={loading}
											/>
										</div>
									</div>
									<hr className="mt-1 mb-0" />
									{/* Table 2 Price/Cost Ref Index 2 */}
									<div className="row">
										<h6 className='fs-6 mb-0'>Price/Cost Ref Area</h6>
										<div className="col-sm-12 col-md-12 col-lg-12">
											<button className='btn btn-sm btn-primary me-2 mb-1'><img src={newBtn} alt='New Trade' /> New</button>
											<button className='btn btn-sm btn-primary me-2 mb-1'><img src={ExcelExport} alt='Price Indices' /> Excel Export</button>

											<button className='btn btn-sm btn-primary me-2 mb-1'><img src={PriceUpdate} alt='Price Update' /> Price Update</button>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-12 col-md-12 col-lg-12">
											<TableIndex2
												selectedPriceRefIndex={selectedPriceRefIndex}
												selectedPriceArea={selectedPriceArea}
												setSelectedPriceArea={setSelectedPriceArea}
											/>
										</div>
									</div>
									<hr className="mt-1 mb-0" />
									{/* Table 3 - Price Cost Area Detail */}

									<div className="row ">
										<h6 className='fs-6 mb-0'>Price/Cost Area Detail</h6>
										<div className="col-sm-12 col-md-12 col-lg-12">
											<button className='btn btn-sm btn-primary me-2 mb-1'><img src={newBtn} alt='New Trade' /> New</button>
											<button className='btn btn-sm btn-primary me-2 mb-1'><img src={ExcelExport} alt='Excel Export' /> Excel Export</button>

										</div>
									</div>
									<div className="row">
										<div className="col-sm-12 col-md-12 col-lg-12">
											<TablePriceCost selectedPriceArea={selectedPriceArea} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
				<NymexModal />
			</div>

		</>
	);

}

export default PriceIndicies;