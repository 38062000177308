import React, { useState, useEffect } from 'react';

import DataTable from 'react-data-table-component';

import CustomStyles from '../../Table-Styles';

const columns = [

    {
        name: 'Contract',
        selector: row => row.contract,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },
    {
        name: 'Trade ID',
        selector: row => row.tradeId,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },

    {
        name: 'Trade Date',
        selector: row => row.tradeDate,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },
    {
        name: 'Delivery Date',
        selector: row => row.del_date,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true

    },
    {
        name: 'Pay/Buy',
        selector: row => row.payBuy,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true

    },

    {
        name: 'F/I',
        selector: row => row.fi,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },
    {
        name: 'Index',
        selector: row => row.pIndex,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },
    {
        name: '$Amount',
        selector: row => row.amount,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },

    {
        name: 'Quantity',
        selector: row => row.quantity,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },
    {
        name: '$ Total',
        selector: row => row.total,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },
    {
        name: '@Book/Portfolio',
        selector: row => row.book,
        sortable: true,
        compact: false,
        wrap: true,
        reorder: true
    },

];


function Table2() {
    const initialData = [
        {
            contract: 'NGAS:HH:FU',
            tradeId: 'tradeId',
            tradeDate: 'tradeDate',
            del_date: 'del_date',
            payBuy: 'payBuy',
            fi: 'fi',
            pIndex: 'Index',
            amount: '$Amount',
            quantity: 'quantity',
            total: '$ Total',
            book: '@Book/Portfolio',
        },
    ];

    //Code for handling the initial data
    useEffect(() => {
        setTableData(
            initialData.map((row, index) => ({ ...row, key: String(index) }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [tableData, setTableData] = useState([]);
    const [initialTableData] = useState(initialData);
    const [newRow, setNewRow] = useState({
        contract: '',
        tradeId: '',
        tradeDate: '',
        del_date: '',
        payBuy: '',
        fi: '',
        pIndex: '',
        amount: '',
        quantity: '',
        total: '',
        book: '',
        editable: true,
    });

    //Code for handling the Row Click functionality
    const handleRowClick = (row) => {
        const updatedData = tableData.map((item) => {
            if (item === row) {
                return {
                    ...item,
                    editable: true,
                };
            }
            return item;
        });
        setTableData(updatedData);
    };

    //Code for handling the Cell Update functionality
    const handleCellUpdate = (newValue, rowKey, columnKey) => {
        const updatedData = [...tableData];
        const rowIndex = updatedData.findIndex((row) => row.key === rowKey);
        updatedData[rowIndex][columnKey] = newValue;
        setTableData(updatedData);
    };

    //Code for handling the Reset Data functionality
    const resetData = () => {
        setTableData(initialTableData);
    };

    //Code for handling the Add Row functionality
    const addRow = () => {
        const newRowData = { ...newRow };
        setTableData([...tableData, newRowData]);
        setNewRow({
            contract: '',
            tradeId: '',
            tradeDate: '',
            del_date: '',
            payBuy: '',
            fi: '',
            pIndex: '',
            amount: '',
            quantity: '',
            total: '',
            book: '',
            editable: true,
        });
    };

    //Code for handling the Edit functionality
    const editableColumns = columns.map((column) => {
        if (column.name === 'Contract') {
            return {
                ...column,
                cell: (row) =>
                    row.editable ? (
                        <input
                            type="text"
                            value={row.contract}
                            onChange={(e) =>
                                handleCellUpdate(e.target.value, row.key, column.selector)
                            }
                        />
                    ) : (
                        column.selector(row)
                    ),
            };
        } else {
            return {
                ...column,
                cell: (row) =>
                    row.editable ? (
                        <input
                            type="text"
                            value={row[column.selector]}
                            onChange={(e) =>
                                handleCellUpdate(e.target.value, row.key, column.selector)
                            }
                        />
                    ) : (
                        column.selector(row)
                    ),
            };
        }
    });

    return (
        <div>
            <button className="btn btn-sm btn-primary me-1 mb-1" onClick={addRow}>
                Add Row
            </button>
            <button onClick={resetData} className="btn btn-sm btn-primary me-1 mb-1">
                Reset Data
            </button>
            <DataTable
                columns={editableColumns}
                data={tableData}
                onRowClicked={handleRowClick}
                striped={true}
                highlightOnHover={true}
                customStyles={CustomStyles}
                dense={true}
                className="pb-1"
            />
        </div>
    );
};

export default Table2;