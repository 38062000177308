/**
 * 
 * Date: 3/20/2023
 * Creator: Cameron
 * Description:
 * 
 * This component is for restricting items on the front end. To use it, wrap 
 * the items that you want to be restricted with this component.
 * 
 * You must supply the component with the "restrictedTo" prop to tell the component 
 * what access these restricted items are supposed to be restricted to.
 * You can optionally add the "action" prop to tell the component what action to
 * take if the user is not allowed access. Ex: hide or disable.
 * 
 * The component will then use the roles array defined within this file to determine if 
 * the logged in user  can access the restricted items or not.
 * 
 * Example:
 * 
 * <Restricted
 *	restrictedTo={['WRITE-ACCESS']}
 *	action='hide'
 * >
 *	<div>READ ONLY USERS CANNOT ACCESS THIS</div>
 * </Restricted>
 * 
 * Props: 
 * 
 * restrictedTo 
 * - An array of strings. Define what the 
 * restricted items are restricted to. Ex: ['ADMIN-ACCESS']
 * 
 * action 
 * - The action you wish to do to the items if the
 * user is not allowed access. (Ex: hide, disable)
 * 
 */

import { useSelector } from 'react-redux';
import { HasPermission, Disable } from '../utils/PermissionUtils';

const Restricted = ({ children, restrictedTo, action }) => {

	const userRoles = useSelector(state => state.auth_slice.userRoles);
	const permitted = HasPermission(userRoles, restrictedTo);

	if (permitted)
		return children;

	if (action === 'disable')
		return Disable(children);

	return null;

};

export default Restricted;