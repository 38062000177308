const BackButton = ({ className }) => {

	const handleGoBack = () => {
		window.history.back();
	};

	return (
		<button onClick={handleGoBack} className={className || 'btn btn-md btn-primary me-2'}>
			Back
		</button>
	);
}

export default BackButton;