
import { useState } from 'react';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

// custom date utils
import {
	getMonthStart,
	getMonthEnd
} from '../utils/DateUtils';


/**
 * Pass in the component state set function. This hook will
 * update the component state any time the business selector
 * state changes.
 * @param {any} setComponentState
 * @param bool restrictDate
 */
const useBusinessSelector = (setComponentState, restrictDate) => {

	// Keeps track of renders. I do not want the gas flow date to be set on first render.
	//const renders = useRef(0);
	//useEffect(() => { renders.current++; });

	const gasFlowDate = useSelector(state => state.business_selector_slice.gasFlowDate);
	const businessUnit = useSelector(state => state.business_selector_slice.businessUnit);
	const selectedSupplier = useSelector(state => state.business_selector_slice.selectedSupplier);

	// Update gas flow date.
	useEffect(() => {
		// Gas flow date should not get set on first render.
		//if (renders.current === 1)
		//	return;

		setComponentState(prevState => {
			let newState = { ...prevState };

			if (newState?.gasFlowDate)
				newState.gasFlowDate = gasFlowDate;

			if (newState?.startDate && restrictDate) {
				if (new Date(gasFlowDate) > new Date(2023, 9, 1)) { // devops 4666 restrict dates prior to november 2023
					newState.startDate = getMonthStart(gasFlowDate);
				}
			}
			else if (newState?.startDate)
				newState.startDate = getMonthStart(gasFlowDate);

			if (newState?.endDate && restrictDate) {
				if (new Date(gasFlowDate) > new Date(2023, 9, 1)) { // devops 4666 restrict dates prior to november 2023
					newState.endDate = getMonthEnd(gasFlowDate);
				}
			}
			else if (newState?.endDate)
				newState.endDate = getMonthEnd(gasFlowDate);

			if (newState?.buID)
				newState.buID = businessUnit?.BA_ID;

			if (newState?.selectedSupplier)
				newState.selectedSupplier = selectedSupplier;

			return newState;
		});
	}, [gasFlowDate, businessUnit, selectedSupplier, setComponentState]);

	/*
	// Update business unit.
	useEffect(() => {
		if (!componentState?.buID)
			return;
		setComponentState(prevState => {
			return {
				...prevState,
				buID: businessUnit?.BA_ID
			};
		});
	}, [businessUnit, setComponentState]);

	// Update selected supplier.
	useEffect(() => {
		if (!componentState?.selectedSupplier)
			return;
		setComponentState(prevState => {
			return {
				...prevState,
				selectedSupplier: selectedSupplier
			};
		});
	}, [selectedSupplier, setComponentState]);
	*/
};

export default useBusinessSelector;