import { notification_actions } from './notification-slice';
import { whpurschase_actions } from './whpurschase-slice';

export const fetchWHContractStls = ({
        buId = '',
        baId = '',
        withPriorPPA = false, 
        withLaterPPA = false,
        fromGasFlowDate = '',
        toGasFlowDate = '',
    }) => {
    
    return async (dispatch) => {
        const getWHContractStls = async () => {
            const queryString = new URLSearchParams({
                buId: buId,
                baId: baId,
                withPriorPPA: withPriorPPA,
                withLaterPPA: withLaterPPA,
                fromGasFlowDate: fromGasFlowDate?.toISOString(),
                toGasFlowDate: toGasFlowDate?.toISOString(),
            }).toString();
            const url = `WHPurschase/GetWHContractStls?${queryString}`
            const res = await fetch(url);
            if (!res.ok) {
                const message = await res.text();
                throw new Error(message);
            }

            const whContractStls = await res.json();
            await dispatch(whpurschase_actions.setWHContractStls(whContractStls));
        }

        try {
            await dispatch(whpurschase_actions.setLoading(true));
            await getWHContractStls();
        }
        catch (error) {
            dispatch(notification_actions.showModal({ headers: 'Error', message: error.message }));
        }
        finally {
            await dispatch(whpurschase_actions.setLoading(false));
        }
    }
}