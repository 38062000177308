import { useState } from 'react';
//import { Link } from 'react-router-dom';

// Components:
import OptionsTable from './Tables/OptionsTable';
import NewOption from './Modals/NewOptionDealModal';

// images
import addIcon from '../../../assets/images/add.gif';
import supplier from '../../../assets/images/Supplier.gif';

//Styling
import './OptionStyling.css';

// Utils:
import { toDatePickerString } from '../../../utils/DateUtils';


function Options() {

    const [openOptionsModal, setOpenOptionsModal] = useState(false);

    const openModal = () => {
        setOpenOptionsModal(true);
    };

    const closeModal = () => {
        setOpenOptionsModal(false);
    };

    return (

        <div className="container-fluid">
            <div className="row">
                {/* Modal for new Option */}
                <NewOption isOpen={openOptionsModal} closeModal={closeModal} />

                <div className="col-sm-12 col-md-12 col-lg-12 p-1">

                    <div className="card border-0">
                        <div className="card-header bg-primary text-white text-center">
                            <h4 className="card-title m-0">Options Positions</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-4 p-1">
                                    {/* <Link to="/newFutureDeal"> */}
                                    {/* <Link to="/newOptionDeal"> */}
                                    <button className="btn btn-primary me-2" onClick={openModal}>
                                        {/* onClick={openModal} */}
                                        <img src={addIcon} alt="Add new record" /> New</button>
                                    {/* </Link> */}
                                    <button className="btn btn-primary me-2"><img src={supplier} alt="Financial Contract" /> Financial Contract</button>
                                </div>
                            </div>
                            {/* Fields and selectors */}
                            <div className="row bg-light-blue rounded mb-2 pb-1">
                                <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
                                        <label className='form-label m-0'>Period From:</label>
                                        <input
                                            defaultValue={toDatePickerString(new Date().toISOString().substr(0, 10))}
                                            name='STARTDATE'
                                            className="form-control form-control-sm"
                                            type="date"
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
                                        <label className='form-label m-0'>Period To:</label>
                                        <input
                                            defaultValue={toDatePickerString(new Date().toISOString().substr(0, 10))}
                                            name='ENDDATE'
                                            className="form-control form-control-sm"
                                            type="date"
                                        />
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 p-1 text-start">
                                        <label className='form-label m-0'>Trade Month:</label>
                                        <br />
                                        <input
                                            type="radio"
                                            defaultChecked={true}
                                        />
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 p-1 text-start">
                                        <label className='form-label m-0'>Dlv Month:</label>
                                        <br />
                                        <input
                                            type="radio"
                                        />
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 p-1 text-start">
                                        <label className='form-label m-0'>Filter:</label>
                                        <select className="form-select form-select-sm">
                                            <option defaultValue="all">All</option>
                                            <option defaultValue="open">Open</option>
                                            <option defaultValue="closed">Closed</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-3 p-1 text-center">
                                        <br />
                                        <button className="btn btn-sm btn-primary">Value Positions</button>
                                    </div>
                                </div>
                                {/* End Row 1 */}
                                {/* Start Row 2 */}
                                <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
                                        <label className='form-label m-0'>Book/Portfolio:</label>
                                        <select className="form-select form-select-sm">
                                            <option defaultValue="bcfPool">2000 BCF Pool</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
                                        <label className='form-label m-0'>Broker:</label>
                                        <select className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
                                        <label className='form-label m-0'>Contract:</label>
                                        <select className="form-select form-select-sm">
                                            <option defaultValue="Contract1">NGAS:HH:OPTA</option>
                                            <option defaultValue="Contract1">NGAS:DPS:OPTA</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-3 p-1 text-start">
                                        <label className='form-label m-0'>Last MTM/Valuation:</label>
                                        <input
                                            defaultValue={new Date().toISOString().substr(0, 10)}
                                            name='MTMDATE'
                                            className="form-control form-control-sm"
                                            type="date"
                                        />
                                    </div>


                                </div>
                            </div>
                            {/* Table section */}
                            <div className="row">
                                <div className='col-12'>
                                    <OptionsTable />
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

            </div >

        </div >






    )
}

export default Options;