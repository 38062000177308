import { createSlice } from '@reduxjs/toolkit';
import { business_selector_actions } from './business-selector-slice';
import { notification_actions } from './notification-slice';
import { history } from '../utils/HistoryUtils';

/**
 * Calculates the remaining time of the json web token.
 * @param {any} JWTExpires
 */
const calculateRemainingTime = (JWTExpires) => {

	// Calculate remaining time.
	const currentTime = new Date().getTime();
	const expirationTime = new Date(JWTExpires).getTime();
	// I want it to refresh the token three seconds before it expires.
	const remainingTime = expirationTime - currentTime - 3000;

	return remainingTime;

};

const initialState = {
	isLoggedIn: false,
	JWTExpires: sessionStorage.getItem('JWTExpires'),
	timerID: sessionStorage.getItem('timerID') || null,
	userRoles: sessionStorage.getItem('userRoles') ? JSON.parse(sessionStorage.getItem('userRoles')) : [],
	userBARoles: sessionStorage.getItem('userBARoles') && sessionStorage.getItem('userBARoles') !== 'undefined' ? JSON.parse(sessionStorage.getItem('userBARoles')) : [],
	isAdmin: sessionStorage.getItem('isAdmin') ? sessionStorage.getItem('isAdmin') : false,
	showResetPassword: false,
	showPasswordResetSuccess: false,
	loginSuccess: false,
	isLoading: false,
	showLoginSuccess: false,
	showPasswordFailure: false,
	showMustResetPassword: false,
	showSpinner: false,
	recaptchaError: null,
	showLoginModal: false,
	userContact: sessionStorage.getItem('userContact') ? JSON.parse(sessionStorage.getItem('userContact')) : {}
};

const auth_slice = createSlice({
	name: 'auth_slice',
	initialState,
	reducers: {
		login(state, action) {

			const {
				newTimerID,
				loginInfo
			} = action.payload;

			const {
				userContact,
				JWTExpires
			} = loginInfo;

			const isAdmin = userContact.EbbUserLogin.ADMIN_IND === 'Y';
			const userRoles = userContact.ENT_CONTACT_ROLES.map(role => role.CDE);

			state.userContact = userContact;
			state.timerID = newTimerID;
			state.isLoggedIn = true;
			state.JWTExpires = JWTExpires;
			state.userRoles = userRoles;
			state.isAdmin = isAdmin;

			sessionStorage.setItem('userContact', JSON.stringify(userContact));
			sessionStorage.setItem('timerID', newTimerID);
			sessionStorage.setItem('isAdmin', isAdmin);
			sessionStorage.setItem('userRoles', JSON.stringify(userRoles));
			sessionStorage.setItem('JWTExpires', JWTExpires);

		},
		logout(state) {
			state.isLoggedIn = false;
			state.JWTExpires = null;
			state.timerID = null;

			sessionStorage.clear();
		},
		showResetPasswordModal(state, action) {
			state.showResetPassword = action.payload;
		},
		showLoginModal(state, action) {
			state.showLoginModal = action.payload;
		},
		setIsLoading(state, action) {
			state.isLoading = action.payload;
		},
		setShowPasswordFailure(state, action) {
			state.showPasswordFailure = action.payload
		},
		setShowSpinner(state, action) {
			state.showSpinner = action.payload;
		},
		setUserBARoles(state, action) {
			state.userBARoles = action.payload;
			sessionStorage.setItem('userBARoles', JSON.stringify(action.payload));
		},


	}
});

export const showLoginModal = () => {
	return async (dispatch) => {
		dispatch(auth_actions.showLoginModal(true));
	}
};

/**
 * If a user logs in using http rather than https, they won't be able
 * to get the authentication tokens since they are stored in https-only cookies.
 * This method will ensure that the user has the authentication token by
 * testing an authorization endpoint. If this passes then they have the token
 * and we can log them in. However, if this fails then we should not log them in
 * and ask them to make sure they are using https. */
const validateToken = async () => {
	const response = await fetch('Auth/ValidateToken');

	if (!response.ok) // Token is not valid if the request failed.
		return false;

	return true;
};

export const signIn = ({
	user_id,
	password
}) => {

	return async (dispatch) => {

		const requestLogin = async () => {

			const response = await fetch('Auth/Login', {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					UserName: user_id,
					Password: password
				})
			});

			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			const loginInfo = await response.json();
			return JSON.parse(loginInfo); // For some reason this is not deserializing.

		};

		try {

			dispatch(auth_actions.setIsLoading(true));

			const loginInfo = await requestLogin();
			const { JWTExpires, userContact, passwordReminder } = loginInfo;

			// Validate authentication tokens. User should not be logged in on front end
			// if these tokens are not present or valid.
			//const tokenIsValid = await validateToken();
			//console.log(tokenIsValid);
			//if (!tokenIsValid) {
			//	dispatch(auth_actions.setIsLoading(false));
			//	history.navigate('/httpWarning');
			//	return;
			//}

			const mustChangePassword = userContact.EbbUserLogin.RESET_PASSWORD === '1';

			if (mustChangePassword) {
				dispatch(auth_actions.setIsLoading(false));
				await dispatch(auth_actions.showResetPasswordModal(true));
				dispatch(notification_actions.showModal({
					message: "You must change your password after it has been regenerated.",
					header: 'Validation Message'
				}));
				dispatch(auth_actions.setIsLoading(false));
				return;
			}

			// Timer for when to refresh the token.
			const remainingTime = calculateRemainingTime(JWTExpires);
			//console.log('Setting timer...');
			const newTimerID = setTimeout(() => {

				//console.log("Timer went off.");

				dispatch(refreshToken());

			}, remainingTime);

			// log the user in
			dispatch(auth_actions.login({ newTimerID: newTimerID, loginInfo: loginInfo, }));

			// set the supplier
			const selectedSupplier = userContact.BA_ID;
			dispatch(business_selector_actions.setSelectedSupplier(selectedSupplier));

			// set user ba roles
			const userBARoles = selectedSupplier.ENT_BA_ROLES;
			dispatch(auth_actions.setUserBARoles(userBARoles));
			dispatch(business_selector_actions.changeBARole(userBARoles?.[0]));

			// Set session to active. This code assists with logging user out on browser close.
			sessionStorage.setItem('session', 'active');

			dispatch(auth_actions.setIsLoading(false));
			dispatch(notification_actions.showModal({
				message: (passwordReminder && typeof passwordReminder === "string" && passwordReminder.trim() !== "") ? 'Login was Successful! ' + passwordReminder : 'Login was Successful!',
				header: 'Notice'
			}));

			history.navigate('/');

		}
		catch (error) {
			if (error.message.includes('reCAPTCHA')) {
				console.log(error.message)
				dispatch(auth_actions.setIsLoading(false));
				dispatch(auth_actions.setRecaptchaError('reCAPTCHA error'));
			} else {
				dispatch(auth_actions.setIsLoading(false));
				dispatch(notification_actions.showModal({
					message: error.message,
					header: 'Validation Message'
				}));
			}
		}

	};

};

export const refreshToken = () => {

	return async (dispatch, getState) => {

		// Check if session is still active. If not, revoke the token.
		if (!sessionStorage.getItem('session')) {
			await dispatch(revokeToken());
			return;
		}

		const requestToken = async () => {

			const response = await fetch('Auth/RefreshToken');

			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

			const loginInfo = await response.json();

			return loginInfo;

		};

		try {

			//console.log('Refreshing JWT...');
			await dispatch(auth_actions.setIsLoading(true));

			const loginInfo = await requestToken();

			const newJWTExpires = loginInfo.JWTExpires;
			const remainingTime = calculateRemainingTime(newJWTExpires);

			// Clear previous timer.
			const prevTimerID = getState()?.auth_slice?.timerID;
			if (prevTimerID)
				clearTimeout(prevTimerID);

			const newTimerID = setTimeout(() => {

				//console.log("Timer went off.");

				dispatch(refreshToken());

			}, remainingTime);

			await dispatch(auth_actions.login({
				newTimerID: newTimerID,
				loginInfo: loginInfo
			}));

			console.log('JWT was refreshed successfully.');
			await dispatch(auth_actions.setIsLoading(false));

		}
		catch (error) {
			console.log(error.message);
			await dispatch(revokeToken());
			await dispatch(auth_actions.setIsLoading(false));

			console.log('Redirecting to home page...');
			history.navigate('/');
		}
	};
};

export const revokeToken = () => {

	return async (dispatch, getState) => {

		const fetchRequest = async () => {

			const response = await fetch('Auth/RevokeToken', {
				method: 'DELETE'
			});
			if (!response.ok) {
				const message = await response.text();
				throw new Error(message);
			}

		};

		try {
			console.log('Logging user out...');
			await fetchRequest();
			console.log('Successfully logged out.');
		}
		catch (error) {
			console.log(error.message);
		}

		// Clear timer before dispatching logout action.
		const timerID = getState().auth_slice.timerID;
		if (timerID) {
			console.log('Clearing refresh token timer...');
			clearTimeout(timerID);
		}

		dispatch(auth_actions.logout());

	};

};

export const resetPassword = ({
	user_id,
	email
}) => {
	return async (dispatch) => {
		const requestResetPassword = async () => {

			const response = await fetch('Auth/FogotPassword', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					UserName: user_id,
					Email: email
				})
			})

			if (!response.ok) {
				const message = await response.text();
				console.log(message);
				throw new Error(message);
			}
		};

		try {
			dispatch(auth_actions.setShowSpinner(true));
			dispatch(auth_actions.setIsLoading(true));
			await requestResetPassword();

			dispatch(auth_actions.setIsLoading(false));
			dispatch(notification_actions.showModal({
				message: 'Password reset request sent!',
				header: 'Password Reset Request Sent!'
			}));
			dispatch(auth_actions.setShowSpinner(false));
		}
		catch (error) {
			dispatch(auth_actions.setShowSpinner(false));
			dispatch(auth_actions.setIsLoading(false));
			dispatch(notification_actions.showModal({
				message: error.message,
				header: 'Validation Message'
			}));
		}
	};
};

export const auth_actions = auth_slice.actions;
export default auth_slice.reducer;