import { useState, useEffect } from 'react'

import cancel from '../../../assets/images/cancel.png';

// import custom date functions
import {
	toDatePickerMonthString,
	toISOStringForDatabase,
	toShortDate
} from '../../../utils/DateUtils'

function DailyImblTradeUi({ componentState }) {

	// daily intolerance details
	const [intolDtls, setIntolDtls] = useState([])

	// make api call to grab daily imbalanace tolerance details
	useEffect(() => {
		if (componentState.buID && componentState.gasFlowDate) {
			const url = "DailyImbalanceSummary/GetDailyImbalanceToleranceList" +
				"?pipelineId=" + componentState.buID +
				"&buID=" + componentState.buID +
				"&gasFlowdate=" + toISOStringForDatabase(componentState.gasFlowDate)
			const getDailyImbalToleranceDtls = async () => {
				fetch(url).then(
					response => {
						return response.json()
					}
				).then(
					response => {
						setIntolDtls(response)
					}
				)
			}
			getDailyImbalToleranceDtls()
		}
	},
		[
			componentState.buID,
			componentState.gasFlowDate
		])

	return (
		<>
			<div className="modal modal-xl " id="DailyImblTradeUi" aria-hidden="true" aria-labelledby="DailyImblTradeUi Report" tabIndex="-1">
				<div className="modal-dialog modal-dialog-centered ">
					<div className="modal-content text-center">

						<div className="modal-header text-center bg-primary text-white justify-content-center">
							<h1 className="fs-5" id="DailyImblTradeUi">Daily Imbalance Trade UI</h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="row text-start">
							<div className="col-sm-12 col-md-12 col-lg-12 p-2">
								<button className='btn btn-md btn-primary m-2' data-bs-dismiss="modal" aria-label="Close"><img src={cancel} alt='Cancel' /> Cancel</button>
							</div>
						</div>
						{
							// Filter Section
							<div className="container-fluid p-3">
								<div className='row mb-3 bg-light-blue rounded p-2 rounded'>
									<h6>Display Filters</h6>
									<div className="col-lg-2 col-md-2 col-sm-12 text-start">
										<label className='form-label'>Gas Flow Month</label>
										<input
											className="form-control form-control-sm"
											type="month"
											defaultValue={toDatePickerMonthString(componentState.gasFlowDate)}
											style={{ maxWidth: '180px' }} />
									</div>
									<div className="col-lg-4 col-md-4 col-sm-12 text-start">
										<label className='form-label pe-2'>Critical Day Tolerance</label>
										<select className='form-select form-select-sm' aria-label="select public or private">
											<option>2</option>
										</select>
									</div>
									<div className="col-lg-2 col-md-2 col-sm-12 text-start">
										<label className='form-label pe-2'>Pipeline</label>
										<select className='form-select form-select-sm' aria-label="select public or private">
											<option>PSE&G</option>
										</select>
									</div>
								</div>
							</div>
						}
						{
							// Daily Pool Summary Table
							intolDtls.length > 0
								?
								<div className="table-responsive mt-1 tableContainer">
									<table className="bg-white table table-sm table-hover table-striped-columns table-bordered border-primary rounded pb-1"
									>
										<thead>
											<tr className='bg-white'>
												<th>Date</th>
												<th>Critical Day</th>
												<th>Over</th>
												<th>Under</th>
												<th>Updated By</th>
												<th>Updated On</th>
											</tr>
										</thead>
										<tbody>
											{
												intolDtls.map(
													detail => {
														return (
															<tr key={detail.PL_OFO_SEQNUM + detail.PL_OFO_DATE}>
																<td>{toShortDate(new Date(detail.PL_OFO_DATE))}</td>
																<td>{detail.CRITICAL_DAY}</td>
																<td>{detail.UPPER_LIMIT}</td>
																<td>{detail.LOWER_LIMIT}</td>
																<td>{detail.USERSTAMP}</td>
																<td>{detail.DATESTAMP ? toShortDate(new Date(detail.DATESTAMP)) : ""}</td>
															</tr>
														)
													}
												)
											}
										</tbody>
									</table>
								</div>
								:
								<p className="text-center">No Records Found.</p>
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default DailyImblTradeUi