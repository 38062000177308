import { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Fragment, useEffect } from 'react';
import { physical_trade_deals_actions } from '../../../store/physical-trade-deals-slice';
import {
	updateBidPkg,
	createBidPkg,
	deleteBidPkg,
	fetchBidPackages
} from '../../../store/physical-trade-deals-thunks';
import { notification_actions } from '../../../store/notification-slice';

import {
	fetchFercCodes,
	fetchPriceRefIndices,
	fetchCycleCodes,
	fetchMtsContracts,
	fetchStatusCodes,
	fetchUnits
} from '../../../store/physical-trade-deals-thunks';

import {
	toDatePickerString,
	getDayStart,
	getMonthStart,
	getMonthEnd
} from '../../../utils/DateUtils';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';

//Image imports
import AddImage from '../../../assets/images/add.gif'
import Save from '../../../assets/images/save.png';
import Delete from '../../../assets/images/delete.png';
import Cancel from '../../../assets/images/cancel.png';
import Copy1 from '../../../assets/images/copy1.gif';
//import Accept3 from '../../../assets/images/accept3.gif';
//import Reject from '../../../assets/images/reject.gif';

//Imported Modals:
import DealModal from '../DealModal';
import SelectSupplierModal from '../SelectSupplierModal';
import ContractModal from '../ContractModal';
import SelectTransportModal from '../SelectTransportModal';
import SelectPointModal from '../SelectPointModal';
import SelectDealTypeModal from '../SelectDealTypeModal';
import SelectTransactionModal from '../SelectTransactionModal';
import MtsContractModal from '../MtsContractModal';
import SelectChargeTypeModal from '../SelectChargeTypeModal';
import SelectPortfolioModal from '../SelectPortfolio';

//Import styling for  Deals Modal
import './NewDealModalStyling.css';
import Spinner from '../../Spinner';

Modal.setAppElement('#root');

function NewDealModal({ filters }) {

	// Redux
	const selectedBidPkg = useSelector(state => state.physical_trade_deals_slice.selectedBidPkg);
	const businessUnit = useSelector(state => state.business_selector_slice.businessUnit);
	const selectedSupplier = useSelector(state => state.business_selector_slice.selectedSupplier);
	const dispatch = useDispatch();
	const units = useSelector(state => state.physical_trade_deals_slice.units);
	const openModal = useSelector(state => state.physical_trade_deals_slice.openModal);
	const fercCodes = useSelector(state => state.physical_trade_deals_slice.fercCodes);
	const priceRefIndices = useSelector(state => state.physical_trade_deals_slice.priceRefIndices);
	const cycleCodes = useSelector(state => state.physical_trade_deals_slice.cycleCodes);
	const statusCodes = useSelector(state => state.physical_trade_deals_slice.statusCodes);
	const isAdmin = useSelector(state => state.auth_slice.isAdmin);
	const selectedPortfolio = useSelector(state => state.physical_trade_deals_slice.selectedPortfolio);

	// State
	const [saving, setSaving] = useState();
	const [deleting, setDeleting] = useState();

	// Memos
	// This variable is used for controlling whether or not inputs are disabled.
	const isApprovedOrConfirmed = useMemo(() => { return selectedBidPkg.STATUS === 'AP' || selectedBidPkg.STATUS === 'CF' }, [selectedBidPkg]);

	// Fetch data on page load.
	useEffect(() => {
		dispatch(fetchFercCodes());
		dispatch(fetchPriceRefIndices());
		dispatch(fetchCycleCodes());
		dispatch(fetchMtsContracts());
		dispatch(fetchStatusCodes());
		dispatch(fetchUnits());
	}, [dispatch]);

	// Validation
	const {
		register,
		handleSubmit,
		formState: {
			errors
		},
		trigger,
		reset
	} = useForm();

	// Re-trigger validation when selectedBidPkg changes.
	useEffect(() => {
		if (errors) {
			Object.keys(errors).forEach((fieldName) => {
				trigger(fieldName);
			});
		}
	}, [trigger, errors, selectedBidPkg]);

	const validations = useMemo(() => {
		return {
			CounterParty: () => {
				if (selectedBidPkg?.BIDTYPE === 'B' && !selectedBidPkg?.BUYERID_OBJ?.BA_ID) {
					return 'Counter party is required.';
				}
				if (selectedBidPkg?.BIDTYPE === 'S' && !selectedBidPkg?.SUPPLIER_BAID_OBJ?.BA_ID) {
					return 'Counter party is required.';
				}
				return null;
			},
			COMP_CONTACT_OBJ: () => {
				if (selectedBidPkg?.COMP_CONTACT_OBJ?.CONTACT_NUM)
					return true;
				return 'Deal maker is required.';
			},
			CNTR_NUM: () => {
				if (selectedBidPkg?.CNTR_NUM?.CNTR_NUM)
					return true;
				return 'Contract is required.';
			},
			STARTDATE: () => {
				if (selectedBidPkg?.STARTDATE <= selectedBidPkg?.ENDDATE)
					return true;
				return 'Start date must come before end date.'
			},
			MD_FLAG: () => {
				if (selectedBidPkg?.MD_FLAG !== 'M') return true;
				const isFirstOfMonth = getDayStart(selectedBidPkg.STARTDATE).getDate() === 1;
				if (isFirstOfMonth) {
					const isLastOfMonth = getDayStart(selectedBidPkg?.ENDDATE).toISOString() === getMonthEnd(selectedBidPkg.ENDDATE).toISOString();
					if (isLastOfMonth) return true;
				};
				return 'For a monthly bid, start and end date must be the start and end of the month.';
			},
			QUANTITY: () => {
				if (!selectedBidPkg?.QUANTITY)
					return 'Quantity is required';
				if (isNaN(selectedBidPkg?.QUANTITY))
					return 'Quantity must be a valid number.';
				return true;
			},
			FIXED_PRICE: () => {
				if (selectedBidPkg?.FIXED_INDEX_IND !== 'F')
					return true;
				if (!selectedBidPkg?.FIXED_PRICE)
					return 'Fixed price is required.'
				if (isNaN(selectedBidPkg?.FIXED_PRICE))
					return 'Fixed price must be a valid number.';
				return true;
			},
			PIPELINE_ID_OBJ: () => {
				if (!selectedBidPkg?.PIPELINE_ID_OBJ?.BA_ID)
					return 'Please choose a transporter.'
				return true;
			},
			START_PT_NUM: () => {
				if (!selectedBidPkg?.START_PT_NUM?.PT_NUM)
					return 'Point is required.';
				return true;
			},
			DISPOSITION_OBJ: () => {
				if (!selectedBidPkg?.DISPOSITION_OBJ?.CDE)
					return 'Trans Type is required.';
				return true;
			},
			DEAL_TYPE_OBJ: () => {
				if (!selectedBidPkg?.DEAL_TYPE_OBJ?.CDE)
					return 'Deal type is required.';
				return true;
			},
			FERC_552_CODE: () => {
				if (!selectedBidPkg?.FERC_552_CODE)
					return 'FERC 552 code is required.';
				return true;
			},
			PRICE_REF_CDE: () => {
				if (selectedBidPkg?.FIXED_INDEX_IND !== 'I')
					return true;
				if (!selectedBidPkg?.PRICE_REF_CDE?.PRICE_REF_CDE)
					return 'Index Type is required.';
				return true;
			},
			PRICE_AREA_ID: () => {
				if (selectedBidPkg?.FIXED_INDEX_IND !== 'I')
					return true;
				if (!selectedBidPkg?.PRICE_AREA_ID)
					return 'Index Area is required.';
				return true;
			},
			CHG_REF_CDE: () => {
				if (!selectedBidPkg?.CHG_REF_CDE)
					return 'Charge Type is required.';
				return true;
			},
			VOLUME_TYPE: () => {
				if (!selectedBidPkg?.VOLUME_TYPE)
					return 'Portfolio is required.'
			}
		};
	}, [selectedBidPkg]);

	//#region Modal stuff
	const openContractModal = () => {
		dispatch(physical_trade_deals_actions.setOpenContractModal(true));
	};
	const openDealModal = () => {
		dispatch(physical_trade_deals_actions.setOpenDealModal(true));
	};
	const openMtsContractModal = () => {
		dispatch(physical_trade_deals_actions.setOpenMtsContractModal(true));
	};
	const openDealTypeModal = () => {
		dispatch(physical_trade_deals_actions.setOpenDealTypeModal(true));
	};
	const openPointModal = () => {
		dispatch(physical_trade_deals_actions.setOpenPointModal(true));
	};
	const openSupplierModal = () => {
		dispatch(physical_trade_deals_actions.setOpenSupplierModal(true));
	};
	const openTransactionModal = () => {
		dispatch(physical_trade_deals_actions.setOpenTransactionModal(true));
	};
	const openTransportModal = () => {
		dispatch(physical_trade_deals_actions.setOpenTransportModal(true));
	};
	const openChargeTypeModal = () => {
		dispatch(physical_trade_deals_actions.setOpenChargeTypeModal(true));
	};
	const openPortfolioModal = () => {
		dispatch(physical_trade_deals_actions.setOpenPortfolioModal(true));
	};
	const closeModal = () => {
		dispatch(physical_trade_deals_actions.setOpenModal(false));
		reset(); // reset the form validation
	};

	// Modal styling
	const customStyles = {
		overlay: {
			backgroundColor: 'rgb(0,0,0,.60'
		}
	};
	//#endregion

	// Code for updating Bid Pkg.
	const bidPkgChange = (event) => {
		const name = event.target.name;
		let value = event.target.value;

		let newSelectedBidPkg = { ...selectedBidPkg };

		// Special cases
		switch (name) {
			case 'PRICE_REF_CDE': {
				value = priceRefIndices.find(index => index.PRICE_REF_CDE === value);
				break;
			}
			case 'BIDTYPE': {
				// Need to swap the supplier and buyer IDs when the BIDTYPE changes.
				let supplier = newSelectedBidPkg.SUPPLIER_BAID_OBJ;
				let buyer = newSelectedBidPkg.BUYERID_OBJ;
				newSelectedBidPkg.SUPPLIER_BAID_OBJ = buyer;
				newSelectedBidPkg.BUYERID_OBJ = supplier;
				break;
			}
			case 'QUANTITY': {
				// Need to parse the double from the string.
				value = parseFloat(value);
				break;
			}
			case 'FIXED_PRICE': {
				value = parseFloat(value);
				break;
			}
			default: {
				break;
			}
		}

		// Update with new value.
		newSelectedBidPkg[name] = value;

		// Update in redux.
		dispatch(physical_trade_deals_actions.setSelectedBidPkg(newSelectedBidPkg));
	};

	//#region Ferc 552 stuff
	// Ferc 552 defaults to certain codes under specified conditions
	const defaultedFERC552 = () => {
		const lowerLimitDaily = 0;
		const upperLimitDaily = 29;
		const diffDays = dateDifference();

		if (selectedBidPkg?.FIXED_INDEX_IND === 'F' && (lowerLimitDaily <= diffDays && diffDays <= upperLimitDaily)) { // fixed, if diffDays is between 0 (both dates same) or 29 days (0 <= diffDays <= 29)
			dispatch(physical_trade_deals_actions.setSelectedBidPkg({
				...selectedBidPkg,
				FERC_552_CODE: '002' // Fixed Price Next Day Delivery
			}));
		}
		if (selectedBidPkg?.FIXED_INDEX_IND === 'F' && (diffDays > upperLimitDaily)) { // fixed, if diffDays is 30 or higher
			dispatch(physical_trade_deals_actions.setSelectedBidPkg({
				...selectedBidPkg,
				FERC_552_CODE: '004' // Fixed Price Next Month Delivery
			}));
		}
		if (selectedBidPkg?.FIXED_INDEX_IND === 'I' && (lowerLimitDaily <= diffDays && diffDays <= upperLimitDaily)) { // index
			dispatch(physical_trade_deals_actions.setSelectedBidPkg({
				...selectedBidPkg,
				FERC_552_CODE: '003' // Published Index Next Day Delivery
			}));
		}
		if (selectedBidPkg?.FIXED_INDEX_IND === 'I' && (diffDays > upperLimitDaily)) { // index
			dispatch(physical_trade_deals_actions.setSelectedBidPkg({
				...selectedBidPkg,
				FERC_552_CODE: '005' // Published Index Next Month Delivery
			}));
		}
	}

	// Gets the difference of the End Date minus the Start Date
	const dateDifference = () => {
		const date1 = new Date(toDatePickerString(selectedBidPkg?.STARTDATE)); // toDatePickerString removes time zone from startdate string
		const date2 = new Date(toDatePickerString(selectedBidPkg?.ENDDATE));
		const diffTime = Math.abs(date2 - date1); // ms
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // ms to days

		return diffDays;
	}

	// when these dependencies are changed, run defaultedFERC552
	useEffect(() => {
		defaultedFERC552();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedBidPkg?.STARTDATE, selectedBidPkg?.ENDDATE, selectedBidPkg?.FIXED_INDEX_IND]);
	//#endregion

	const saveSelectedBidPkg = async () => {

		//const bidStatus = selectedBidPkg?.STATUS;

		//switch (bidStatus) {
		//	case 'AP':
		//		dispatch(notification_actions.showModal({
		//			header: 'Record can not be edited.',
		//			message: 'Record cannot be edited as Deal is in Approved status.'
		//		}));
		//		return;
		//	case 'CF':
		//		dispatch(notification_actions.showModal({
		//			header: 'Record can not be edited.',
		//			message: 'Record cannot be edited as Deal is in Confirmed status.'
		//		}));
		//		return;
		//	default:
		//		break;
		//}	from dev ops 3421, commented out in case we want to reuse this filtering option for approved and confirmed deals to NOT be updated/edited.

		if (selectedBidPkg?.STATUS === "AP") {
			return;
		} else {
			setSaving(true);
			if (selectedBidPkg.BID_NUM) {
				console.log('updating pkg', { ...selectedBidPkg })
				await dispatch(updateBidPkg());
				console.log('updated', { ...selectedBidPkg })
			}
			else {
				console.log('creating bid pkg', { ...selectedBidPkg })
				await dispatch(createBidPkg());
				console.log('created', { ...selectedBidPkg })
			}
			setSaving(false);
			dispatch(fetchBidPackages({
				selectedSupplier,
				filters,
				businessUnit,
				isAdmin
			}));
		}
	};

	const resetBidPkg = () => {
		dispatch(physical_trade_deals_actions.resetBidPkg({
			businessUnit
		}));
	};

	const copyClick = async () => {
		console.log('copy clicked', { ...selectedBidPkg })
		await dispatch(physical_trade_deals_actions.copyBidPkg());
		console.log('copied', { ...selectedBidPkg })
	};

	const deleteClick = async () => {
		const bidStatus = selectedBidPkg?.STATUS;

		if (!bidStatus)
			return;

		switch (bidStatus) {
			case 'SC':
				dispatch(notification_actions.showModal({
					header: 'Record can not be deleted.',
					message: 'Record cannot be deleted as Deal is in Schedule status.'
				}));
				return;
			case 'RC':
				dispatch(notification_actions.showModal({
					header: 'Record can not be deleted.',
					message: 'Record cannot be deleted as Deal is in Reconcile status.'
				}));
				return;
			case 'CF':
				dispatch(notification_actions.showModal({
					header: 'Record can not be deleted.',
					message: 'Record cannot be deleted as Deal is in Confirm status.'
				}));
				return;
			default:
				break;
		}

		setDeleting(true);
		await dispatch(deleteBidPkg());
		setDeleting(false);

		dispatch(fetchBidPackages({
			selectedSupplier,
			filters,
			businessUnit,
			isAdmin
		}));
	};

	const enterFastInputMode = () => {
		openSupplierModal();
		openDealModal();
		openContractModal();
		openTransportModal();
		openPointModal();
		openDealTypeModal();
		openTransactionModal();
		openChargeTypeModal();
		openPortfolioModal();
	};

	// switch case to display code text - removed, input for chg ref code removed
	//const codeText = () => {
	//	const code = selectedBidPkg?.CHG_REF_CDE

	//	switch (code) {
	//		case '00':
	//			return 'Commodity';
	//		case 'AF':
	//			return 'Asset Mgmt Fee';
	//		case 'BH':
	//			return 'Back Haul';
	//		case 'CI':
	//			return 'Cash In';
	//		case 'CT':
	//			return 'Cash Out';
	//		case 'CO':
	//			return 'Commodity';
	//		case 'CR':
	//			return 'CR-Capacity Release';
	//		case 'DE':
	//			return 'DCQ Demand Charge';
	//		case 'DM':
	//			return 'Demand';
	//		case 'DR':
	//			return 'Demand Reversal';
	//		case 'FI':
	//			return 'Financial';
	//		case 'FR':
	//			return 'Fixed Rate';
	//		case 'NIC':
	//			return 'Neg Imbal Cashout';
	//		case 'PIC':
	//			return 'Pos Imbal Cashout';
	//		case 'MS':
	//			return 'Revenue - Miscellenous';
	//		case 'RF':
	//			return 'Revenue - Reservation Fee';
	//		case 'CA':
	//			return 'ST Capacity Fee';
	//		case 'SI':
	//			return 'Storage Injection';
	//		case 'SW':
	//			return 'Storage Withdrawal';
	//		case 'TF':
	//			return 'Trading Fee';
	//		case 'CX':
	//			return 'Transp. Reversal';
	//		case 'TR':
	//			return 'Transportation';
	//		case 'FU':
	//			return 'Gathering Fuel Charge';
	//		case 'GRV':
	//			return 'Gross and Tier Revenue';
	//		case 'GRD':
	//			return 'LP Deducts';
	//		case 'OS':
	//			return 'Off System Transaction';
	//		case 'AG':
	//			return 'Pool Aggregation Fee';
	//		case 'PF':
	//			return 'Pooling Fee';
	//		default:
	//			break;
	//	};
	//};

	//Custom button style for smaller buttons:
	const buttonStyle = {
		"--bs-btn-padding-y": ".01rem",
		"--bs-btn-padding-x": ".01rem",
		"--bs-btn-font-size": ".5rem",
	};

	return (
		<>
			<Modal
				isOpen={openModal}
				contentLabel='create-edit-deals'
				className='new-deal-modal modal-XL container-fluid bg-white'
				style={customStyles}
				onRequestClose={closeModal}
			>
				<form className="modal-content" onSubmit={handleSubmit(saveSelectedBidPkg)}>
					<div className="modal-header text-center">
						<div className='col text-start mb-1'>
							<h5 className="modal-title">Create/Edit deals</h5>
						</div>
						<div className='col text-end'>
							<button
								type="button"
								className="btn-close btn btn-md mt-2"
								onClick={closeModal}
								aria-label="Close"
							>
							</button>
						</div>
					</div>
					<div className="modal-body">
						{/* // BUTTONS */}
						<div className="row text-start">
							<div className="col-lg-12 col-md-12 col-md-12 col-sm-12">
								<div className="">
									<button
										type="button"
										className="btn btn-primary mt-1 m-1"
										title="New Deal"
										onClick={resetBidPkg}
									>
										<img src={AddImage} alt="AddImage" /> Add
									</button>
									{
										saving
											?
											<Spinner status='saving' />
											:
											<button
												className="btn btn-primary mt-1 m-1"
												title="New Deal"
											>
												<img src={Save} alt="SaveImage" /> Save
											</button>
									}
									{
										deleting
											?
											<Spinner status='deleting' />
											:
											<button
												type="button"
												className="btn btn-primary mt-1 m-1"
												title="New Deal"
												onClick={deleteClick}
											>
												<img src={Delete} alt="DeleteImage" /> Delete
											</button>
									}
									<button
										type="button"
										className="btn btn-primary mt-1 m-1"
										title="New Deal"
										onClick={closeModal}
									>
										<img src={Cancel} alt="AddImage" /> Cancel
									</button>
									<button
										type="button"
										className="btn btn-primary mt-1 m-1"
										title="New Deal"
										onClick={copyClick}
									>
										<img src={Copy1} alt="AddImage" /> Copy
									</button>
									{/* <button
										type="button"
										className="btn btn-primary mt-1 m-1"
										title="New Deal"
									>
										<img src={Accept3} alt="AddImage" /> Accept
									</button>
									<button
										type="button"
										className="btn btn-primary mt-1 m-1"
										title="New Deal"
									>
										<img src={Reject} alt="AddImage" /> Reject
									</button> */}
									<button
										type="button"
										className="btn btn-primary mt-1 m-1"
										onClick={enterFastInputMode}
									>
										Fast Input
									</button>
								</div>
							</div>
						</div>
						<div className='row bg-light-blue text-start'>

							<div className='row p-1 m-0'>
								<div className="col-lg-2 col-md-2 col-sm-3 text-start disabled">

									<label className='form-label form-label text-start m-0'>Bid Type: </label>

									<select
										disabled={isApprovedOrConfirmed}
										value={selectedBidPkg?.BIDTYPE || ''}
										onChange={bidPkgChange}
										name='BIDTYPE'
										className="form-select form-select-sm col-lg-1 col-sm-3 text-start disabled"
										aria-label="Default select example"
									>
										<option value='S'>Sale</option>
										<option value='B'>Purchase</option>
									</select>

									<label className='form-label text-start m-0'>Trade Date:</label>
									<input
										// value={toDatePickerMonthString(selectedBidPkg?.BIDDATE)}
										disabled={isApprovedOrConfirmed}
										value={toDatePickerString(selectedBidPkg.BIDDATE)}
										onChange={bidPkgChange}
										name='BIDDATE'
										className="form-control form-control-sm disabled"
										type="date"
									/>

									<label className='form-label m-0 text-start'>Status: </label>

									<input
										disabled
										value={statusCodes?.find(code => code.CDE === selectedBidPkg?.STATUS)?.CDE_TXT || ''}
										name='STATUS'
										className='form-control form-control-sm col-lg-1 col-md-2 col-sm-3 text-start'
									/>
									<label className='form-label m-0 text-start'>Units: </label>

									<select
										value={selectedBidPkg?.UNIT_MEAS || ''}
										onChange={bidPkgChange}
										name='UNIT_MEAS'
										className="form-select form-select-sm text-start"
										aria-label="Default select example"
										placeholder='Product'
										disabled={isApprovedOrConfirmed}
									>
										{
											units.map(
												unit => {
													return (
														<option
															key={unit.CDE}
															value={unit.CDE}
														>
															{unit.CDE_TXT}
														</option>
													);
												}
											)
										}
									</select>
								</div>
								{/* End of Column 1 */}

								{/* Column 2 Start */}
								<div className="col-lg-2 col-md-2 col-sm-3 text-start">
									<label className='form-label m-0 text-start'>Company:</label>

									<input
										value={selectedBidPkg?.BIDTYPE === 'B' ? selectedBidPkg?.BUYERID_OBJ?.NAME : selectedBidPkg?.SUPPLIER_BAID_OBJ?.NAME || ''}
										className="form-control form-control-sm col-lg-1 col-md-1 col-sm-3 text-start"
										aria-label="Default select example"
										placeholder='--Please select a Company--'
										disabled
									/>

									<label className='form-label m-0 mt-4 text-start'>Counter Party:</label>
									<input
										{...register("CounterParty", { validate: validations?.CounterParty })}
										value={(selectedBidPkg?.BIDTYPE === 'B' ? selectedBidPkg?.SUPPLIER_BAID_OBJ?.NAME : selectedBidPkg?.BUYERID_OBJ?.NAME) || ''}
										name='CounterParty'
										className={`form-control form-control-sm text-start ${errors.CounterParty && 'border-danger'}`}
										aria-label="Default select example"
										placeholder='--Please select a Counter Party--'
										disabled
									/>
									{errors.CounterParty && <p className='p-0 m-0 text-danger fw-bold text-center'>{errors?.CounterParty?.message}</p>}
									<button
										type="button"
										className='btn btn-primary mt-1 btn-sm col-lg-12'
										onClick={openSupplierModal}
										style={buttonStyle}
										disabled={isApprovedOrConfirmed}
									>
										Sel Party
									</button>


								</div>
								{/* End of Column 2 */}

								{/* Column 3 Start */}
								<div className="col-lg-2 col-md-2 col-sm-3 text-start">
									<label className='form-label m-0 text-start'>Deal Maker: </label>
									<input
										{...register('COMP_CONTACT_OBJ', { validate: validations.COMP_CONTACT_OBJ })}
										value={selectedBidPkg?.COMP_CONTACT_OBJ?.FIRST_NAME && selectedBidPkg.COMP_CONTACT_OBJ?.NAME &&
											(selectedBidPkg?.COMP_CONTACT_OBJ?.FIRST_NAME || '') + ' ' + (selectedBidPkg?.COMP_CONTACT_OBJ?.NAME || '')}
										className={`form-control form-control-sm text-start ${errors.COMP_CONTACT_OBJ && 'border-danger'}`}
										aria-label="Default select example"
										placeholder='--Please select a Deal Maker--'
										disabled
									/>
									{errors.COMP_CONTACT_OBJ && <p className='p-0 m-0 text-danger fw-bold text-center'>{errors?.COMP_CONTACT_OBJ?.message}</p>}
									<button
										type="button"
										className='btn btn-primary mt-1 btn-sm col-lg-12'
										onClick={openDealModal}
										style={buttonStyle}
										disabled={isApprovedOrConfirmed}
									>
										Sel Deal
									</button>
									<br />
									<label disabled className='form-label m-0 text-start'>Select Contract ID: </label>
									<input
										{...register('CNTR_NUM', { validate: validations.CNTR_NUM })}
										value={selectedBidPkg?.CNTR_NUM?.CNTR_TITLE || ''}
										className={`form-control form-control-sm col-lg-1 col-md-2 col-sm-3 text-start ${errors.CNTR_NUM && 'border-danger'}`}
										aria-label="Default select example"
										placeholder='--Please select a Contract--'
										disabled
									/>
									{errors.CNTR_NUM && <p className='p-0 m-0 text-danger fw-bold text-center'>{errors?.CNTR_NUM?.message}</p>}
									<button
										type="button"
										className='btn btn-primary mt-1 btn-sm col-12'
										style={buttonStyle}
										onClick={openContractModal}
										disabled={isApprovedOrConfirmed}
									>
										Sel Contract
									</button>
								</div>
								{/* End of Column 3 */}

								{/* Column 4 Start */}
								<div className="col-lg-2 col-md-2 col-sm-3 text-start">
									<label className='form-label m-0 col-lg-auto col-md-auto col-sm-12 text-start'>Amend #: </label>

									<input
										disabled
										value={selectedBidPkg?.AMEND_NUM || ''}
										onChange={bidPkgChange}
										name='AMEND_NUM'
										className="form-control form-control-sm col-lg-1 col-md-2 col-sm-3 text-start"
										aria-label="Default select example"
									/>
									<label className='form-label m-0 text-start'>Phone:</label>
									<input
										value={selectedBidPkg?.COMP_CONTACT_OBJ?.PHONE_NUM_raw || ''}
										className="form-control form-control-sm col-lg-1 col-md-2 col-sm-3 text-start"
										aria-label="Default select example"
										placeholder='-'
										disabled
									/>
									<label className='form-label m-0  text-start'>User ID:</label>

									<input
										value={selectedBidPkg?.COMP_CONTACT_OBJ?.EbbUserLogin?.USER_ID || ''}
										className="form-control form-control-sm col-lg-1 col-md-1 col-sm-3 text-start"
										aria-label="Default select example"
										placeholder='-'
										disabled
									/>
									<label className='form-label m-0 text-start'>Package #:</label>

									<input
										value={selectedBidPkg?.PACKAGE_NUM?.PACKAGE_NUM || ''}
										onChange={bidPkgChange}
										name='PACKAGE_NUM'
										className="form-control form-control-sm col-lg-1 col-md-2 col-sm-3 text-start"
										aria-label="Default select example"
										disabled
									/>
								</div>
								{/* End of Col 4 */}
								{/* End of Row */}
							</div>
							{/* FIELDS - ROW 2 */}
							<div className='row text-start p-1 m-0 border border-opacity-75'>
								<label htmlFor="exampleFormControlTextarea1" className="form-label m-0 fw-bold m-0 p-1">Transaction Detail:</label>
								{/* Col 1 Start */}
								<div className="col-lg-2 col-md-2 col-sm-3 text-start">
									<label className='form-label m-0 text-start'>From Date:</label>

									<input
										{...register('STARTDATE', { validate: validations.STARTDATE })}
										value={toDatePickerString(selectedBidPkg?.STARTDATE)}
										onChange={bidPkgChange}
										name='STARTDATE'
										className={`form-control form-control-sm ${errors.STARTDATE && 'border-danger'}`}
										type="date"
										disabled={isApprovedOrConfirmed}
									/>
									{errors.STARTDATE && <p className='p-0 m-0 text-danger fw-bold text-center'>{errors?.STARTDATE?.message}</p>}
									<label className='form-label m-0 text-start'>To Date:</label>

									<input
										value={toDatePickerString(selectedBidPkg?.ENDDATE)}
										onChange={bidPkgChange}
										name='ENDDATE'
										className={`form-control form-control-sm ${errors.MD_FLAG && 'border-danger'}`}
										type="date"
										disabled={isApprovedOrConfirmed}
									/>
									<label className='form-label m-0 text-start'>Quantity:</label>
									<input
										{...register('QUANTITY', { validate: validations.QUANTITY })}
										disabled={isApprovedOrConfirmed}
										//disabled={selectedBidPkg?.PACKAGE_NUM?.PACKAGE_NUM}
										value={selectedBidPkg?.QUANTITY || ''}
										type='number'
										name='QUANTITY'
										onChange={bidPkgChange}
										className={`form-control form-control-sm col-lg-2 col-md-2 col-sm-3 text-start ${errors.QUANTITY && 'border-danger'}`}
										aria-label="Default select example"
									/>
									{errors.QUANTITY && <p className='p-0 m-0 text-danger fw-bold text-center'>{errors?.QUANTITY?.message}</p>}
									<label className='form-label m-0  text-start'>Daily/Monthly:</label>

									<select
										{...register('MD_FLAG', { validate: validations.MD_FLAG })}
										disabled={isApprovedOrConfirmed}
										//disabled={selectedBidPkg?.PACKAGE_NUM?.PACKAGE_NUM}
										value={selectedBidPkg?.MD_FLAG || ''}
										onChange={bidPkgChange}
										name='MD_FLAG'
										className={`form-select form-select-sm col-lg-1 col-md-2 col-sm-3 text-start ${errors.MD_FLAG && 'border-danger'}`}
										aria-label="Default select example"
									>
										<option value='D'>Daily</option>
										<option value='M'>Monthly</option>
									</select>
									{errors.MD_FLAG && <p className='p-0 m-0 text-danger fw-bold text-center'>{errors?.MD_FLAG?.message}</p>}


								</div>
								{/* Col 2 Start */}
								<div className="col-lg-2 col-md-2 col-sm-3 text-start">
									<label className='form-label m-0 text-start'>Pricing Method: </label>

									<select
										value={selectedBidPkg?.FIXED_INDEX_IND || ''}
										onChange={bidPkgChange}
										name='FIXED_INDEX_IND'
										className="form-select form-select-sm col-lg-auto col-md-2 col-sm-3 text-start"
										aria-label="Default select example"
										disabled={isApprovedOrConfirmed}
									>
										<option value='F'>Fixed</option>
										<option value='I'>Indexed</option>
									</select>
									{
										selectedBidPkg?.FIXED_INDEX_IND === 'F'
											?
											<Fragment>
												<label className='form-label m-0 text-start'>Fixed Price: </label>
												<input
													{...register('FIXED_PRICE', { validate: validations.FIXED_PRICE })}
													value={selectedBidPkg?.FIXED_PRICE || ''}
													type='number'
													onChange={bidPkgChange}
													name='FIXED_PRICE'
													className={`form-control form-control-sm col-lg-1 col-md-2 col-sm-3 text-start ${errors.FIXED_PRICE && 'border-danger'}`}
													aria-label="Default select example"
													disabled={isApprovedOrConfirmed}
												/>
												{errors.FIXED_PRICE && <p className='p-0 m-0 text-danger fw-bold text-center'>{errors?.FIXED_PRICE?.message}</p>}

											</Fragment>

											:
											<Fragment>

												<label className='form-label m-0 text-start'>Index Type: </label>

												<select
													{...register('PRICE_REF_CDE', { validate: validations.PRICE_REF_CDE })}
													value={selectedBidPkg?.PRICE_REF_CDE?.PRICE_REF_CDE || ''}
													onChange={bidPkgChange}
													name='PRICE_REF_CDE'
													className={`form-select form-select-sm col-lg-1 col-md-2 col-sm-3 text-start ${errors.PRICE_REF_CDE && 'border-danger'}`}
													aria-label="Default select example"
													disabled={isApprovedOrConfirmed}
												>
													<option value=''>--Select an Index Type--</option>
													{
														priceRefIndices?.map(
															index => {
																return (
																	<option
																		key={index.PRICE_REF_CDE}
																		value={index.PRICE_REF_CDE}
																	>
																		{index.PRICE_REF_NAME}
																	</option>
																);
															}
														)
													}
												</select>
												{errors.PRICE_REF_CDE && <p className='p-0 m-0 text-danger fw-bold text-center'>{errors?.PRICE_REF_CDE?.message}</p>}


												<label className='form-label m-0 text-start'>Index Area: </label>

												<select
													{...register('PRICE_AREA_ID', { validate: validations.PRICE_AREA_ID })}
													value={selectedBidPkg?.PRICE_AREA_ID || ''}
													onChange={bidPkgChange}
													name='PRICE_AREA_ID'
													className={`form-select form-select-sm col-lg-1 col-md-2 col-sm-3 text-start ${errors.PRICE_AREA_ID && 'border-danger'}`}
													aria-label="Default select example"
													disabled={isApprovedOrConfirmed}
												>
													<option value=''>--Select an Index Area--</option>
													{
														selectedBidPkg?.PRICE_REF_CDE?.ENT_PRICE_AREA?.map(
															priceArea => {
																return (
																	<option
																		key={priceArea.PRICE_AREA_ID}
																		value={priceArea.PRICE_AREA_ID}
																	>
																		{priceArea.PRICE_AREA_NAME}
																	</option>
																);
															}
														)
													}
												</select>
												{errors.PRICE_AREA_ID && <p className='p-0 m-0 text-danger fw-bold text-center'>{errors?.PRICE_AREA_ID?.message}</p>}

												<label className='form-label m-0 text-start'>Adjust:</label>


												<input
													value={selectedBidPkg?.PRICE_OFFSET || ''}
													onChange={bidPkgChange}
													name='PRICE_OFFSET'
													className="form-control form-control-sm text-start"
													aria-label="Default select example"
													disabled={isApprovedOrConfirmed}
												/>


												<button
													type="button"
													className='btn btn-success btn-sm'
												>
													+
												</button>


												<button
													type="button"
													className='btn btn-danger btn-sm'
												>
													-
												</button>


											</Fragment>
									}
								</div>

								{/* Col 3 start */}
								{ // COMMENTING THIS OUT FOR NOW, APPARENTLY THESE INPUTS ARE NOT NEEDED FOR NOW. - CAMERON
									/*
									<div className="col-lg-2 col-md-2 col-sm-3 text-start">

										<label className='form-label m-0 text-start'>Mkt Tr Svc: </label>

										<input
											disabled
											value={selectedBidPkg?.MTS_CNTR_NUM?.CNTR_TITLE || ''}
											name='MTS_CNTR_NUM'
											className="form-control form-control-sm col-lg-1 col-sm-12 text-start"
											aria-label="Default select example"
											placeholder='--Please select a Contract--'
										/>
										<button
											type="button"
											className='btn btn-primary mt-1 btn-sm col-12'
											onClick={openMtsContractModal}
											style={buttonStyle}
											disabled={isApprovedOrConfirmed}
										>
											Mkt Tr SvC
										</button>
										<label className='form-label m-0 text-start'>Fee: </label>
										<input
											value={selectedBidPkg?.fee || 0}
											onChange={bidPkgChange}
											name='fee'
											className="form-control form-control-sm col-lg-1 col-sm-3 text-start"
											aria-label="Default select example"
											disabled={isApprovedOrConfirmed}
										/>
									</div>
									*/
								}
								{/* Col 3 end */}

								{/* Col 4 Start */}
								<div className="col-lg-2 col-md-2 col-sm-3 text-start">

									<label className='form-label m-0 text-start'>Cycle: </label>
									<select
										value={selectedBidPkg?.BID_SYSTEM || ''}
										onChange={bidPkgChange}
										name='BID_SYSTEM'
										className="form-select form-select-sm col-lg-1 col-sm-3 text-start"
										aria-label="Default select example"
										disabled={isApprovedOrConfirmed}
									>
										<option value=''>--Please select a Cycle--</option>
										{
											cycleCodes?.map(
												code => {
													return (
														<option
															key={code.CDE}
															value={code.CDE}
														>
															{code.CDE_TXT}
														</option>
													);
												}
											)
										}
									</select>

									<label className='form-label m-0 text-start'>Transporter: </label>
									<input
										{...register('PIPELINE_ID_OBJ', { validate: validations.PIPELINE_ID_OBJ })}
										disabled
										value={selectedBidPkg?.START_PT_NUM?.PIPELINE_OBJ?.NAME || selectedBidPkg?.PIPELINE_ID_OBJ?.NAME || selectedBidPkg?.START_PT_NUM_PipeLineName || ''}
										className={`form-control form-control-sm col-lg-1 col-md-2 col-sm-12 text-start ${errors.PIPELINE_ID_OBJ && 'border-danger'}`}
										aria-label="Default select example"
										placeholder='--Select a Transporter--'
									/>
									{errors.PIPELINE_ID_OBJ && <p className='p-0 m-0 text-danger fw-bold text-center'>{errors?.PIPELINE_ID_OBJ?.message}</p>}
									<button
										type="button"
										className='btn btn-primary mt-1 btn-sm col-12'
										onClick={openTransportModal}
										style={buttonStyle}
										disabled={isApprovedOrConfirmed}
									>
										Select Trans
									</button>
									<label className='form-label m-0 text-start'>{selectedBidPkg?.BIDTYPE === 'B' ? "P/U point:" : "S/A point:"}</label>

									<input
										{...register('START_PT_NUM', { validate: validations.START_PT_NUM })}
										disabled
										value={selectedBidPkg?.START_PT_NUM?.PT_NAME || ''}
										className={`form-control form-control-sm col-lg-1 col-sm-12 text-start ${errors.START_PT_NUM && 'border-danger'}`}
										aria-label="Default select example"
										placeholder='--Please select a Point--'
									/>
									{errors.START_PT_NUM && <p className='p-0 m-0 text-danger fw-bold text-center'>{errors?.START_PT_NUM?.message}</p>}
									<button
										type="button"
										className='btn btn-primary mt-1 btn-sm col-12'
										onClick={openPointModal}
										style={buttonStyle}
										disabled={isApprovedOrConfirmed}
									>
										Sel Point
									</button>
								</div>
								{/* Column 5 Start */}
								<div className="col-lg-2 col-md-2 col-sm-3 text-start">
									<label className='form-label m-0 text-start'>Activity: </label>

									<input
										value={selectedBidPkg?.PACKAGE_NUM?.ACTIV_ALT_NUM || ''}
										className="form-control form-control-sm text-start"
										aria-label="Default select example"
										disabled
										placeholder='--Please select an Activity--'
									/>
									<button
										type="button"
										className='btn btn-primary mt-1 btn-sm col-12'
										style={buttonStyle}
										disabled={isApprovedOrConfirmed}
									>
										Select Activity
									</button>
									<label className='form-label m-0 text-start'>Deal Type: </label>
									<input
										{...register('DEAL_TYPE_OBJ', { validate: validations.DEAL_TYPE_OBJ })}
										disabled
										value={selectedBidPkg?.DEAL_TYPE_OBJ?.CDE_TXT || ''}
										onChange={bidPkgChange}
										name='DEAL_TYPE'
										className={`form-control form-control-sm col-lg-1 col-sm-12 text-start ${errors.DEAL_TYPE_OBJ && 'border-danger'}`}
										aria-label="Default select example"
										placeholder='--Please select a Deal Type--'
									/>
									{errors.DEAL_TYPE_OBJ && <p className='p-0 m-0 text-danger fw-bold text-center'>{errors?.DEAL_TYPE_OBJ?.message}</p>}
									<button
										type="button"
										className='btn btn-primary mt-1 btn-sm col-12'
										onClick={openDealTypeModal}
										style={buttonStyle}
										disabled={isApprovedOrConfirmed}
									>
										Select Deal Type
									</button>
									<label className='form-label m-0 text-start'>Portfolio: </label>
									<input
										{...register('VOLUME_TYPE', { validate: validations.VOLUME_TYPE })}
										disabled
										value={selectedPortfolio?.CDE_TXT || ''}
										onChange={bidPkgChange}
										name='PORTFOLIO'
										className={`form-control form-control-sm col-lg-1 col-sm-12 text-start ${errors.VOLUME_TYPE && 'border-danger'}`}
										aria-label="Default select example"
										placeholder='--Please select a Portfolio Type--'
									/>
									{errors.VOLUME_TYPE && <p className='p-0 m-0 text-danger fw-bold text-center'>{errors?.VOLUME_TYPE?.message}</p>}
									<button
										type="button"
										className='btn btn-primary mt-1 btn-sm col-12'
										onClick={openPortfolioModal}
										style={buttonStyle}
										disabled={isApprovedOrConfirmed}
									>
										Select Portfolio
									</button>
								</div>
								{/* Column 6 Start */}
								<div className="col-lg-2 col-md-2 col-sm-3 text-start">
									<label className='form-label m-0 text-start'>Trans Type: </label>

									<input
										{...register('DISPOSITION_OBJ', { validate: validations.DISPOSITION_OBJ })}
										disabled
										value={selectedBidPkg?.DISPOSITION_OBJ?.CDE_TXT || ''}
										className={`form-control form-control-sm col-sm-12 text-start ${errors.DISPOSITION_OBJ && 'border-danger'}`}
										aria-label="Default select example"
										placeholder='--Please select a Trans Type--'
									/>
									{errors.DISPOSITION_OBJ && <p className='p-0 m-0 text-danger fw-bold text-center'>{errors?.DISPOSITION_OBJ?.message}</p>}
									<button
										type="button"
										className='btn btn-primary mt-1 btn-sm col-12'
										onClick={openTransactionModal}
										style={buttonStyle}
										disabled={isApprovedOrConfirmed}
									>
										Select Trans Type
									</button>

									<label className='form-label col-lg-auto col-sm-12 m-0 text-start'>FERC 552:</label>

									<select
										{...register('FERC_552_CODE', { validate: validations.FERC_552_CODE })}
										value={selectedBidPkg?.FERC_552_CODE || ''}
										onChange={bidPkgChange}
										name='FERC_552_CODE'
										className={`form-select form-select-sm col-lg-auto col-sm-12 text-start ${errors.FERC_552_CODE && 'border-danger'}`}
										aria-label="Default select example"
										disabled={isApprovedOrConfirmed}
									>
										<option value=''>--Please select a FERC code--</option>
										{
											fercCodes.map(
												code => {
													return (
														<option
															key={code.CDE}
															value={code.CDE}
														>
															{code.CDE_TXT}
														</option>
													);
												}
											)
										}
									</select>
									{errors.FERC_552_CODE && <p className='p-0 m-0 text-danger fw-bold text-center'>{errors?.FERC_552_CODE?.message}</p>}
									{/* Removed on 10/10/2023 per Michael Smith's request, all Deals have a charge type = Commodity-Default. - JAL

									<label className='form-label m-0 text-start'>Transaction Type:</label>
									<input
										{...register('CHG_REF_CDE', { validate: validations?.CHG_REF_CDE })}
										disabled
										value={codeText() || ''}
										className={`form-control form-control-sm col-lg-1 col-sm-12 text-start ${errors.CHG_REF_CDE && 'border-danger'}`}
										aria-label="Default select example"
										placeholder='--Please select a Charge Type--'
									/>
									{errors.CHG_REF_CDE && <p className='p-0 m-0 text-danger fw-bold text-center'>{errors?.CHG_REF_CDE?.message}</p>}
									<button
										type='button'
										className='btn btn-primary mt-1 btn-sm col-12'
										onClick={openChargeTypeModal}
										style={buttonStyle}
										disabled={isApprovedOrConfirmed}
									>
										Select Charge Type
									</button> */}
								</div>
							</div>
						</div>
					</div>
					{/* FIELDS - ROW 6 - COMMENTS */}
					<div className='row text-start fw-bold '>
						<div className="mb-3">
							<label htmlFor="exampleFormControlTextarea1" className="form-label m-0 text-start">Special Previsions or Comments:</label>
							<textarea
								value={selectedBidPkg?.DEAL_NOTE || ''}
								onChange={bidPkgChange}
								name='DEAL_NOTE'
								className="form-control border border-2"
								id="exampleFormControlTextarea1"
								rows="2"
								placeholder='Enter Special Provisions or Comments here....'
							>
							</textarea>
						</div>
					</div>
				</form>
			</Modal >

			{/* All Modals go here
			 * Please leave them in this order. 
			 * They must be in this exact order
			 * for the fast input functionality.
			 * - Cameron */}
			<SelectTransactionModal />
			<SelectDealTypeModal />
			<SelectPointModal />
			<SelectTransportModal />
			<ContractModal />
			<DealModal />
			<SelectSupplierModal />
			<MtsContractModal />
			<SelectChargeTypeModal />
			<SelectPortfolioModal />
		</>
	);
};

export default NewDealModal;