import NonPathNomUI from '../../CustomComponents/NonPathNomUI';
import GANomsTableColumns from './GANomsTableColumns';

const options = {
	title: 'GA Nominations',
	contractTypes: ['GA'],
	numberFormat: {
		shouldRound: true,
		decimalPlace: 0
	},
	buttons: [
		'AddNom',
		'EstimatedFlow',
		'GasAllocation',
		'OBAStatement',
		'SchedQtyDetail',
	]
};

const GANominations = () => {
	return <NonPathNomUI options={options} CustomNonPathNomsTableColumns={GANomsTableColumns} />;
};

export default GANominations;