import React from 'react';
import excel from '../../../assets/images/excel.gif';
import * as XLSX from 'xlsx';


function preprocessData(data) {
    return data.map(row => ({
        ...row,
        'Flow Date': new Date(row.BIDDATE).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
        'Day': new Date(row.BIDDATE).toLocaleDateString('en-US', { weekday: 'long' }),
        'Updated On': new Date(row.DATESTAMP).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
        'DailyPriceStringFormat': 'Deal Price'
    }));
}

function createExcelFile(data) {
    // Define the keys you want to include in the Excel file
    const keysToInclude = ['Flow Date', 'Day', 'DAILY_QUANTITY', 'Total_SCH_AVAIL_RCV_VOL', 'DailyPriceStringFormat', 'STATUS', 'USERSTAMP', 'Updated On', 'DailyPriceStringFormat'];

    // Preprocess the data
    const preprocessedData = preprocessData(data);

    // Truncate the cell contents if they exceed the maximum allowed length
    const truncatedData = preprocessedData.map(row =>
        Object.fromEntries(
            Object.entries(row).filter(([key, value]) => keysToInclude.includes(key)).map(([key, value]) => [key, value && value.toString().substring(0, 32767)])
        )
    );

    const worksheet = XLSX.utils.json_to_sheet(truncatedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
}





// function createExcelFile(data) {
//     // Truncate the cell contents if they exceed the maximum allowed length
//     const truncatedData = data.map(row =>
//         Object.fromEntries(
//             Object.entries(row).map(([key, value]) => [key, value && value.toString().substring(0, 32767)])
//         )
//     );

//     const worksheet = XLSX.utils.json_to_sheet(truncatedData);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
//     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//     return new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
// }


function downloadExcelFile(data) {
    const link = document.createElement('a');
    const blob = createExcelFile(data);
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = 'export.xlsx';
    link.click();
    setTimeout(() => {
        URL.revokeObjectURL(url);
    }, 100);
}

const Export = ({ onExport }) => (
    <button className="btn btn-sm btn-primary" onClick={() => onExport()}>
        <img src={excel} alt="Export Excel Report" /> Export
    </button>
);

const ExportExcel = ({ data }) => {
    const actionsMemo = React.useMemo(() => <Export onExport={() => downloadExcelFile(data)} />, [data]);

    return actionsMemo;
};

export default ExportExcel;
