import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// import images
//import excel from '../../assets/images/excel.gif';
import add from '../../../assets/images/add.gif';
import imbTrade from '../../../assets/images/imb-list.jpg';
import dmbtui from '../../../assets/images/alert-2.gif';

// import custom date functions
import { toDatePickerString, getDayStart } from '../../../utils/DateUtils';

// import custom hooks
import useBusinessSelectorRedux from '../../../hooks/useBusinessSelectorRedux';

import Restricted from '../../Restricted';
import { useDispatch, useSelector } from 'react-redux';
import InterstateNomsTable from './Tables/InterstateNomsTable';
import { interstate_noms_actions } from '../../../store/interstate-noms-slice';

import NominationDetailModal from './NominationDetailModal';
import { useMemo } from 'react';
import ContractSelector from '../../CustomComponents/Selectors/ContractSelector';
import CodeSelector from '../../CustomComponents/Selectors/CodeSelector';


const InterstateNoms = () => {

	// React router dom
	const navigate = useNavigate();

	// Redux
	const suppliers = useSelector(state => state.business_selector_slice.suppliers);
	const dispatch = useDispatch();
	const filters = useSelector(state => state.interstate_noms_slice.filters);

	// Validation for contract
	const [showValidation, setShowValidation] = useState(false);
	const isValid = useMemo(() => filters?.selectedContract?.CNTR_NUM, [filters]);
	useEffect(() => { if (isValid) setShowValidation(false); }, [isValid]);

	// Filter changes
	const setFilters = (newFilters) => {
		dispatch(interstate_noms_actions.setFilters(newFilters));
	};

	const gasFlowDateChange = (event) => {
		setFilters({ ...filters, gasFlowDate: getDayStart(event.target.value).toISOString() });
	};

	const supplierChange = (event) => {
		setFilters({ ...filters, selectedSupplier: suppliers.filter(supplier => supplier.BA_ID === event.target.value) });
	};

	// Business Selector Logic
	useBusinessSelectorRedux(filters, interstate_noms_actions.setFilters);


	const newNomClick = async () => {
		// Validation for contract
		if (!isValid) {
			setShowValidation(true);
			return;
		}
		else if (showValidation)
			setShowValidation(false);

		await dispatch(interstate_noms_actions.setSelectedPackage({}));
		navigate('/addIntNom');
	}

	const nomDetailUiClick = () => {
		dispatch(interstate_noms_actions.openNomUiModal());
	};

	return (
		<div className='container-fluid'>
			<div className="row">
				<div className="col-sm-12 col-md-12 col-lg-12 p-1">
					<div className='card mt-2'>
						<div className='card-header bg-primary text-white text-center p-1'>
							<h4 className="card-title m-0 pt-0 pb-0 ">Interstate Nominations</h4>
						</div>
						<div className='card-body'>
							<div className="row">
								<div className="col-sm-12 col-md-12 col-lg-12 p-2">
									{/* BUTTONS */}
									<button className='btn btn-md btn-primary me-2 mb-2' onClick={newNomClick} >
										<img src={add} alt='Add Nomination' /> Add Nomination
									</button>

									<button
										className='btn btn-md btn-primary me-2 mb-2'
										onClick={nomDetailUiClick}
									>
										<img src={dmbtui} alt='Daily Imbalance Ui' /> Nom Detail Modal
									</button>

									<button
										className='btn btn-md btn-primary me-2 mb-2'
									>
										<img src={dmbtui} alt='Daily Imbalance Ui' /> Daily Imbalance Tollerance UI
									</button>
									<button
										className='btn btn-md btn-primary me-2 mb-2'
									>
										<img src={imbTrade} alt='Broker-Report' /> Broker Delivery Reports
									</button>
								</div>
							</div>

							{/* /* FILTERS */}
							< div className='row mb-3 bg-light-blue rounded p-2 rounded'>
								<h6>Display Filters</h6>

								{/* // Gas Flow Date */}
								<div className="col-lg-2 col-md-2 col-sm-12 text-start">
									<label className='form-label pe-2'>Gas Flow Date</label>
									<input
										className="form-control form-control-sm"
										type="date"
										style={{ maxWidth: '180px' }}
										value={toDatePickerString(filters?.gasFlowDate)}
										onChange={gasFlowDateChange}
									/>
								</div>

								{/* Supplier */}
								<div className="col-lg-2 col-md-2 col-sm-12">
									<label className='form-label pe-2'>Supplier</label>
									<Restricted
										restrictedTo={['SYS_ADMIN-ACCESS']}
										action='disable'
									>
										<select
											className='form-select form-select-sm'
											aria-label="select public or private"
											value={filters?.selectedSupplier?.BA_ID}
											onChange={supplierChange}
										>
											<option value=''>--Select a Supplier--</option>
											{
												suppliers?.map(
													supplier => {
														return (
															<option
																key={supplier.BA_ID}
																value={supplier.BA_ID}
															>
																{supplier.NAME}
															</option>
														);
													}
												)
											}
										</select>
									</Restricted>
								</div>

								{/* // Contract */}
								<div className="col-lg-1 col-md-1 col-sm-12">
									<label className='form-label pe-2'>Contract</label>
									<ContractSelector
										url={`Contract/GetContracts?cntr_party1=${filters?.selectedSupplier?.BA_ID}&cntr_types_IN=ST&cntr_types_IN=TB`}
										value={filters?.selectedContract}
										setValue={(newValue) => { dispatch(interstate_noms_actions.setFilters({ ...filters, selectedContract: newValue })); }}
										showValidation={showValidation}
									/>
								</div>

								{/* // Display Units */}
								<div className="col-lg-2 col-md-2 col-sm-12">
									<label className='form-label pe-2 text-start'>Display Units</label>
									<CodeSelector
										value={filters?.displayUnits}
										setValue={(newValue) => { dispatch(interstate_noms_actions.setFilters({...filters, displayUnits: newValue}))}}
										url='Codes/GetCodes?cde_type=UNIT'
									/>
								</div>
							</div>
							<InterstateNomsTable filters={filters} />
						</div>
					</div>
					<NominationDetailModal />
				</div>
			</div>
		</div>
	);
};

export default InterstateNoms;
