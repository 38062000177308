import Logo from './assets/images/gastar-logo.png';

const HttpWarning = () => {
	return (
		<div className="container-fluid">
			<div className="row text-white justify-content-center">
				<div className='col-6 text-center'>
					<img src={Logo} alt='Gastar logo' className='img-fluid gastar-logo-md' />
					<br />
					<h2>AUTHORIZATION FAILED</h2>
					<h4>Login was successful but authorization still failed. Please make sure you are using https rather than http.</h4>
				</div>
			</div>
		</div>
	);
};

export default HttpWarning;